<template>
  <div class="coin_input">
    <input
      type="text"
      class="form-control input-coin-number"
      :placeholder="placeholder"
      v-bind:class="{ 'border-bottom': component === OrderForm }"
      :value="value"
      :disabled="disabled"
      v-if="inputText"
    />
    <currency-input
      :class="getClasses()"
      :precision="getPrecision()"
      :maxLengthCus="16"
      v-model="internalValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :ref="refCurrencyInput"
      :max="max"
      v-else
    />

    <span :class="{ r10i: !visibleButton }" v-if="visibleUnit">{{
      unit | uppercase
    }}</span>
    <!-- <button
      tabindex="32766"
      class="plus"
      v-if="visibleButton"
      @click="onIncreaseClicked()"
    > -->
    <img
      v-if="visibleButton"
      @click="onIncreaseClicked()"
      src="/images/up-arrow2.svg"
      class="img plus"
      alt="icon"
    />
    <!-- </button> -->
    <!-- <button
      tabindex="32767"
      class="minus"
      v-if="visibleButton"
      @click="onDecreaseClicked()"
    > -->
    <img
      v-if="visibleButton"
      @click="onDecreaseClicked()"
      src="/images/down-arrow2.svg"
      class="img minus"
      alt="icon"
    />
    <!-- </button> -->
  </div>
</template>
<script>
import BigNumber from "bignumber.js";
import CurrencyInput from "./CurrencyInput";

export default {
  components: {
    CurrencyInput,
  },
  props: {
    value: {},
    precision: { type: Number },
    unit: { type: String },
    disabled: { type: Boolean, default: false },
    inputText: { type: Boolean, default: false },
    visibleButton: { type: Boolean, default: false },
    classes: { type: String },
    placeholder: { type: String },
    component: { type: String },
    min: { type: Number },
    max: { type: Number },
  },
  data() {
    return {
      internalValue: "",
      refCurrencyInput: this.getRefUniqueId(),
      OrderForm: "order-form",
    };
  },
  watch: {
    value(newValue) {
      this.updateValue();
    },
    precision(newPrecision) {
      this.updatePrecision(newPrecision);
    },
    internalValue(val) {
      if (this.max && Number(val) > Number(this.max)) {
        this.internalValue = String(this.max);
        this.$emit("input", this.internalValue);
      } else {
        this.$emit("input", val);
      }
    },
  },
  computed: {
    visibleUnit() {
      return !window._.isEmpty(this.unit);
    },
  },
  methods: {
    onIncreaseClicked() {
      if (this.max && this.internalValue >= this.max) {
        return;
      }
      if (this.unit === "%") {
        const valStr = this.internalValue?.toString() || "0";
        if (
          (valStr.split(".").length === 2 &&
            valStr.split(".")[1].length == 1) ||
          valStr.split(".").length === 1
        ) {
          const newVal = this.newBigNumber(this.internalValue)
            .plus("0.1")
            .toString();
          if (newVal < this.max) {
            this.internalValue = newVal;
            return;
          }
          return;
        }
      }
      this.internalValue = this.newBigNumber(this.internalValue)
        .plus(`${this.precision}`)
        .toString();
    },

    onDecreaseClicked() {
      if ((this.min && this.internalValue <= this.min) || !this.internalValue) {
        return;
      }
      if (this.unit === "%") {
        const valStr = this.internalValue?.toString() || "0";
        if (
          (valStr.split(".").length === 2 &&
            valStr.split(".")[1].length == 1) ||
          valStr.split(".").length === 1
        ) {
          this.internalValue = this.newBigNumber(this.internalValue)
            .sub("0.1")
            .toString();
          return;
        }
      }
      const result = this.newBigNumber(this.internalValue).sub(
        `${this.precision}`
      );
      if (result.gte(this.precision)) {
        this.internalValue = result.toString();
      }
    },

    getClasses() {
      if (!this.classes) {
        return "input-coin-number";
      }
      return `input-coin-number ${this.classes}`;
    },

    getPrecision() {
      return this.unit === "idr" || this.unit === "%" ? 2 : 8;
    },

    getRefUniqueId() {
      return window._.uniqueId("input-coin-number-");
    },

    newBigNumber(value) {
      if (window._.isEmpty(value)) {
        value = 0;
      }
      return new BigNumber(value);
    },

    updateValue() {
      if (this.value instanceof BigNumber) {
        this.internalValue = this.value.toString();
        return;
      }
      this.internalValue = this.value;
    },

    updatePrecision(newPrecision) {
      const precision = Math.round(Math.log(1 / newPrecision) / Math.log(10));
      this.$refs[this.refCurrencyInput].setPrecision(precision);
    },
  },
  mounted() {
    this.updateValue();
  },
};
</script>
<style lang="scss" scoped>
.coin_input {
  position: relative;

  input[type="text"] {
    height: 40px;
    border: 1px solid $grey-2;
    border-radius: 10px;
    width: 100%;
    padding: 10px 70px 10px 20px;
    font-family: $helvetica;
    font-size: 16px;
    color: $dark-1;
    background-color: $color-white;

    &.input-total {
      padding-right: 60px;
    }

    &:focus {
      border-color: $primary-2;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-size: 16px;
      color: $dark-4;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      font-size: 16px;
      color: $dark-4;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      font-size: 16px;
      color: $dark-4;
    }
  }

  & > span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 42px;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    color: $dark-1;
    text-transform: uppercase;

    &.r10i {
      right: 15px;
    }
  }

  .img {
    position: absolute;
    right: 16px;
    width: auto;

    &.plus {
      top: 11px;
      border-top: none;
    }

    &.minus {
      bottom: 11px;
    }
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }
}
</style>
