<template>
  <span id="clock-timer">
    <span>{{ date }}</span
    >&nbsp;
    <span>{{ time }}</span>
  </span>
</template>

<script>
import moment from "moment";

const timeID = 1000;

export default {
  components: {},
  data() {
    return {
      time: "",
      date: "",
    };
  },
  methods: {
    update() {
      setInterval(() => {
        this.create();
      }, timeID);
    },

    create() {
      const date = moment().toDate();
      this.time = `${this.zeroPadding(date.getHours(), 2)}:${this.zeroPadding(
        date.getMinutes(),
        2
      )}:${this.zeroPadding(date.getSeconds(), 2)}`;
      this.date = `${this.zeroPadding(
        date.getFullYear(),
        4
      )}-${this.zeroPadding(date.getMonth() + 1, 2)}-${this.zeroPadding(
        date.getDate(),
        2
      )}`;
    },

    zeroPadding(num, digit) {
      return window._.padStart(num, digit, 0);
    },
  },

  mounted() {
    this.create();
    this.update();
  },
};
</script>

<style lang="scss" scoped>
#clock-timer {
  color: $text-white;
}
</style>
