export default {
  MASTERDATA: {
    KEY: "masterdata",
    VERSION_KEY: "masterdata_version",
    EVENT_CHANGED: "MasterdataChanged",
  },
  KEYS_GLOBAL: {
    APP_NAME: "NVX",
    APP_SHORT_NAME: "NVX",
    COIN_HOLDING: "AMAL",
  },
  MIXPANEL_TOKEN: "45865afc8f2a3bd0bd9ccb3c346e7d32",
  SPOT_EXCHANGE: {
    ORDER_TYPE_ALL: "all",
    ORDER_TYPE_LIMIT: "limit",
    ORDER_TYPE_MARKET: "market",
    ORDER_TYPE_STOP_LIMIT: "stop_limit",
    ORDER_TYPE_STOP_MARKET: "stop_market",
  },
  OPEN_ORDER: "open_order",
  ORDER_HISTORY: "order_history",
  TRADE_HISTORY: "trade_history",
  SUB_ACCOUNTS: "sub_accounts",
  FUNDS: "funds",
  ONE_DAY: "one_day",
  ONE_WEEK: "one_week",
  ONE_MONTH: "one_month",
  THREE_MONTHS: "three_months",
  PER_PAGE: 10,
  DESC: "desc",
  ASC: "asc",
  USD: "usd",
  IDR: "idr",
  USDT: "usdt",
  BTC: "btc",
  AML: "amal",
  ETH: "eth",
  DEFAULT: "default",
  CURRENT_SUBJECT_SEARCH_DATE_TAB: "CURRENT_SUBJECT_SEARCH_DATE_TAB",
  CURRENT_SUBJECT_SEARCH_DATE_PICKER: "CURRENT_SUBJECT_SEARCH_DATE_PICKER",
  ACTION_SORT: "action_sort",
  ACTION_CANCEL: "action_cancel",
  ACTION_CHANGE_ORDER_TAB: "action_change_order_tab",
  ACTION_HIDE_OTHER_PAIR: "action_hide_other_pair",
  OPEN_ORDER_NAVI: [
    {
      id: "open_order",
      name: "menu.open_orders",
      isActive: true,
    },
    {
      id: "order_history",
      name: "menu.order_history",
      isActive: false,
    },
    {
      id: "trade_history",
      name: "menu.trade_history",
      isActive: false,
    },
    {
      id: "funds",
      name: "menu.funds",
      isActive: false,
    },
    // {
    //   id: 'sub_accounts',
    //   name: 'menu.sub_accounts',
    //   isActive: false,
    // }
  ],
  OPEN_ORDER_DATE_TAB: [
    {
      id: "one_day",
      name: "exchange.basic.time.day",
      isActive: false,
    },
    {
      id: "one_week",
      name: "exchange.basic.time.week",
      isActive: false,
    },
    {
      id: "one_month",
      name: "exchange.basic.time.month",
      isActive: false,
    },
    {
      id: "three_month",
      name: "exchange.basic.time.three_month",
      isActive: false,
    },
  ],
  MARKETS: ["all", "btc", "eth", "idr", "usdt"],
  MARKETS_2: ["btc", "eth", "idr", "usdt"],
  DEFAULT_MARKET: "idr",
  DEFAULT_COIN: "btc",
  DEFAULT_CURRENCY: "idr",
  DEFAULT_MARGIN_SYMBOL: "BTCUSD",
  FUNDING: "funding",
  FAVORITES_TAB: "favorites tab",
  SELECT_SIDE: [
    {
      id: "buy",
      name: "order.order_form.buy",
    },
    {
      id: "sell",
      name: "order.order_form.sell",
    },
  ],
  TOTAL_AMAL: 126000000,
  TRADING_VIEW_CHART: {
    OBJECT: {
      SALES_POINT: "trandingview.object.salespoint",
      SPOT_EXCHANGE: "trandingview.object.spotexchange",
      MARGIN_EXCHANGE: "trandingview.object.marginexchange",
      PROFIT: "trandingview.object.profit",
    },
    BAR_STYLE: {
      STYLE_BARS: 0,
      STYLE_CANDLES: 1,
      STYLE_LINE: 2,
      STYLE_AREA: 3,
      STYLE_HEIKEN_ASHI: 8,
      STYLE_HOLLOW_CANDLES: 9,
    },
  },
  MINUTES: ["1", "3", "5", "15", "30"],
  HOURS: ["60", "120", "240", "360", "720"],
  CHARTING_RESOLUTION_MULTIPLICITY: { D: 1440, W: 10080, M: 43200 },
  REFERRAL_KEY: "ref",
  LANGUAGES: ["en", "ja", "vis", "il", "tl"],
  FIAT_LIKE_LIST: ["idr", "usdt"],
  NUMBER_OF_DECIMAL_DIGITS: 8,
  NUMBER_OF_DECIMAL_DIGITS_FIAT: 2,
  TYPE_MAIN_BALANCE: "main",
  TYPE_EXCHANGE_BALANCE: "spot",
  TYPE_MARGIN_BALANCE: "margin",
  TYPE_MAM_BALANCE: "mam",
  PERPETUAL_DIVIDEND_BALANCE: "perpetual_dividend_balance",
  DIVIDEND_BALANCE: "dividend_balance",
  TYPE_AIRDROP_BALANCE: "airdrop",
  CIRCUIT_BREAKER: {
    ENABLE_TRADING: "enable",
    DISABLE_TRADING: "disable",
  },
  ENABLE_TRADING: {
    ENABLE: "enable",
    DISABLE: "disable",
    WAITING: "waiting",
    IGNORE: "ignore",
  },
  BETA_TESTER: {
    ACTIVE: "active",
    WAITING: "waiting",
    INACTIVE: "inactive",
  },
  TIME_IN_FORCE: [
    {
      id: "gtc",
      name: "time_in_force.good_till_cancel",
      isActive: true,
    },
    {
      id: "ioc",
      name: "time_in_force.immediate_or_cancel",
      isActive: false,
    },
    {
      id: "fok",
      name: "time_in_force.fill_or_kill",
      isActive: false,
    },
  ],
  MAX_LENGTH_INPUT: 190,
  MAX_LENGTH_20: 20,
  COMMISSION_PERCENT: "20%",
  MARGIN_CONTRACTS: {
    BTC: "btc",
    BTC7D: "btc7d",
    XBJ: "xbj",
    ADA: "ada",
    BCH: "bch",
    ETH: "eth",
    TRX: "trx",
  },
  MARGIN_ORDER_TAB_LIST: [
    "limit",
    "market",
    "stop_limit",
    "stop_market",
    "trailing_stop",
    "take_profit_market",
    "take_profit_limit",
  ],
  MARGIN_ORDER_TAB_EXCLUDE: {
    //[contract_symbol]: [...MARGIN_ORDER_TAB_LIST]
  },
  MARGIN_INDICE_TYPE: {
    PREMIUM: "premium",
    FREQUENCY: "frequency",
    AMI: "ami",
    CONSTANCE: "constance",
  },
  MARGIN_DEFAULT_SYMBOL: "BTCUSD",
  MARGIN_DEFAULT_TICK_SIZE: 0.5,
  MAM_ALLOCATION_LIST: ["lot", "percent"],
  LOGIN_ACCEPT_AGREEMENT: "ACCEPT_AGREEMENT",
};
