<template>
  <!-- NEW BALANCE LAYOUT -->
  <div id="funds" class="page_container" :class="{ is_no_single_page: !isSinglePage }">
    <template v-if="isSpotMobile">
      <div class="funds__mobile-wrapper">
        <div class="funds__mobile-header">
          <div class="checkbox-function">
            <label for="checkbox_open_order_id">
              <input
                type="checkbox"
                name="checkbox"
                v-model="hideBalances"
                value="check"
                id="checkbox_open_order_id"
              />
              <div>{{ $t('order.open_order.hide_small_assets') }}</div>
            </label>
          </div>
        </div>
        <div class="funds__mobile-body">
          <data-list-mobile
            ref="datatable"
            :msgEmptyData="noFunds"
            :get-data="loadData"
            :limit="10"
            :height="30"
          >
            <template slot="table-empty-header">
              <thead>
                <tr>
                  <th>
                    <span>{{ $t('funds.balances.coin') }}</span>
                  </th>
                  <th v-if="isSinglePage">
                    <span>{{ $t('funds.balances.name') }}</span>
                  </th>
                  <th>
                    <span>{{ $t('funds.balances.total_balance') }}</span>
                  </th>
                  <th>
                    <span>{{ $t('funds.balances.avaliable_balance') }}</span>
                  </th>
                  <th data-sort-field="order">
                    <span>{{ $t('funds.balances.in_order') }}</span>
                  </th>
                  <th data-sort-field="btcValue">
                    <span>{{ $t('funds.balances.btc_value') }}</span>
                  </th>
                </tr>
              </thead>
            </template>
            <template slot="body" slot-scope="props">
              <div class="funds__list-item">
                <div class="funds__item-title">
                  <div class="item-title__wrapper">
                    <img
                      class="coin"
                      width="20"
                      height="20"
                      :src="STORAGE_URL + '/icons/' + props.item.coin + '.png'"
                    />
                    <div>{{ props.item.coin | uppercase }}</div>
                  </div>
                </div>
                <div class="funds__item-desc">
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{
                        props.item.totalBalance
                          | toNumber
                          | formatCurrencyAmount(props.item.coin, '0')
                      }}
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="totalBalance"
                      @click="props.onSort"
                    >
                      {{ $t('funds.balances.total_balance')
                      }}<i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper text-end">
                    <div class="item-desc__value">
                      {{
                        props.item.availableBalance
                          | toNumber
                          | formatCurrencyAmount(props.item.coin, '0')
                      }}
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="availableBalance"
                      @click="props.onSort"
                    >
                      {{ $t('funds.balances.avaliable_balance')
                      }}<i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{ props.item.order | toNumber | formatCurrencyAmount(props.item.coin, '0') }}
                    </div>
                    <div class="item-desc__title" data-sort-field="order" @click="props.onSort">
                      {{ $t('funds.balances.in_order')
                      }}<i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper text-end">
                    <div class="item-desc__value">
                      {{ props.item.btcValue | toNumber | formatCurrencyAmount('btc', '0') }}
                    </div>
                    <div class="item-desc__title" data-sort-field="btcValue" @click="props.onSort">
                      {{ $t('funds.balances.btc_value')
                      }}<i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </data-list-mobile>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="balance-container">
        <div class="table-container table-history">
          <data-table3 :getData="loadData" ref="datatable" :msgEmptyData="noFunds" :height="20">
            <template slot-scope="props">
              <th data-sort-field="coin" @click="props.echoclick">
                <span>{{ $t('funds.balances.coin') }}</span>
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th data-sort-field="name" v-if="isSinglePage" @click="props.echoclick">
                <span>{{ $t('funds.balances.name') }}</span>
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th data-sort-field="totalBalance" @click="props.echoclick">
                <span>{{ $t('funds.balances.total_balance') }}</span>
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th data-sort-field="availableBalance" @click="props.echoclick">
                <span>{{ $t('funds.balances.avaliable_balance') }}</span>
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th data-sort-field="order" @click="props.echoclick">
                <span>{{ $t('funds.balances.in_order') }}</span>
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th data-sort-field="btcValue" @click="props.echoclick">
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span>{{ $t('funds.balances.btc_value') }}</span>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
              <th v-if="isSinglePage"></th>
            </template>
            <template slot="body" slot-scope="props">
              <tr>
                <td class="coin">
                  <img
                    class="coin"
                    width="20"
                    height="20"
                    :src="STORAGE_URL + '/icons/' + props.item.coin + '.png'"
                  />
                  <span>{{ props.item.coin | uppercase }}</span>
                </td>
                <td v-if="isSinglePage">{{ props.item.coin | fullName }}</td>
                <td>
                  {{
                    props.item.totalBalance | toNumber | formatCurrencyAmount(props.item.coin, '0')
                  }}
                </td>
                <td>
                  {{
                    props.item.availableBalance
                      | toNumber
                      | formatCurrencyAmount(props.item.coin, '0')
                  }}
                </td>
                <td>
                  {{ props.item.order | toNumber | formatCurrencyAmount(props.item.coin, '0') }}
                </td>
                <td>
                  {{ props.item.btcValue | toNumber | formatCurrencyAmount('btc', '0') }}
                </td>
              </tr>
            </template>
          </data-table3>
          <!-- end pagination-container -->
        </div>
        <!-- end table container -->
      </div>
    </template>
    <!-- end-balance-container -->
  </div>
  <!-- END NEW BALANCE LAYOUT -->
</template>

<script>
import rf from '@/request/RequestFactory';
import BigNumber from 'bignumber.js';
import Const from '@/common/Const';
import { funds } from '@/components/spotexchange_pages/order/mockupData';
const MIN_BTC = 0.00000001;

export default {
  data() {
    return {
      isLoading: false,
      hideBalances: false,
      isShowNotification: false,
      search: '',
      limit: undefined,

      accountBalances: undefined,
      prices: undefined,
      balances: [],

      totalPendingWithdraws: [],

      selectedCoin: '',
      tradeCoin: [],
      displayPairCoin: false,
      tradeUsd: [],

      isAuthenticated: window.isAuthenticated,
      coinSetting: {},
      noFunds: this.$i18n.t('funds.balances.no_data'),
    };
  },
  props: {
    isSpotMobile: Boolean,
    isSinglePage: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    accountBalances(newAccBalances) {
      const currencies = Object.keys(newAccBalances);
      // this.getTotalPendingWithdraw(currencies, this.refresh);
    },
    prices(newPrices) {
      this.refresh();
    },
    totalPendingWithdraws(newVal) {
      this.refresh();
    },
    search(newValue) {
      this.refreshTable();
    },
    hideBalances() {
      this.refreshTable();
    },
  },
  computed: {
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === 'storage_url').value;
    },
    totalBtcBalance() {
      return this.sumBy('btcValue');
    },
    totalUsdBalance() {
      return this.sumBy('usdValue');
    },
    totalOrder() {
      return this.sumBy('orderBtcValue');
    },
    isSpotExchangePage() {
      return this.$route.path === '/spot-exchange/basic';
    },
  },
  methods: {
    addPricesInBtc() {
      this.prices['usdt_usdt'] = { price: 1 };
      for (const fiat of Const.FIAT_LIKE_LIST) {
        const fiatPrice = this.prices[`${fiat}_usdt`];
        const price = fiatPrice > 0 ? new BigNumber('1').div(fiatPrice.price).toString() : '0';
        this.prices[`usdt_${fiat}`] = { price };
      }
    },
    isShowcoin(coin) {
      return coin === this.selectedCoin;
    },
    showCoin(coin) {
      this.selectedCoin = coin;
      this.tradeCoin = this.currencyCoins.filter((item) => {
        return item.coin.indexOf(coin) > -1;
      });
      this.tradeUsd = this.currencyCoins.filter((item) => {
        return item.currency === 'idr';
      });
    },
    getMinBtc() {
      return MIN_BTC;
    },
    showNotification() {
      this.isShowNotification = !this.isShowNotification;
    },
    hideNotification() {
      this.isShowNotification = false;
    },
    async loadData(params) {
      this.$refs.datatable.fetching = !this.$refs.datatable?.fetching;
      return new Promise((resolve) => {
        let data = this.balances;
        data = data
          .map((item) => {
            if (_.isNaN(item.btcValue) || item.btcValue === 'NaN') {
              item.btcValue = '0';
            }
            if (_.isNaN(item.usdValue) || item.usdValue === 'NaN') {
              item.usdValue = '0';
            }
            return item;
          })
          .filter((item) =>
            `${item.coin}${item.name}`.toLowerCase().includes(this.search.toLowerCase())
          );

        if (this.hideBalances) {
          data = _.filter(
            data,
            (item) => new BigNumber(item.availableBalance).comparedTo(MIN_BTC) >= 0
          );
        }
        if (params.sort && params.sort_type) {
          let temp = data.map((obj) => {
            if (params.sort === 'coin') {
              return {
                ...obj,
              };
            }
            return {
              ...obj,
              [params.sort]: new BigNumber(obj[params.sort]).toNumber() || 0,
            };
          });
          data = _.orderBy(temp, [params.sort], [params.sort_type]);
        }
        return resolve({ data: { data } });
      });
    },
    refreshTable() {
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    refresh() {
      const isNoData = window._.isEmpty(this.accountBalances) || window._.isEmpty(this.prices);
      if (isNoData) {
        return;
      }
      this.transferData();
      this.refreshTable();
    },
    transferData() {
      let data = [];
      for (let coin in this.accountBalances) {
        const coinInfor = this.accountBalances[coin];
        if (coinInfor) {
          const item = {};
          item.coin = coin;
          item.name = this.getCoinName(coin);
          item.totalBalance = coinInfor.balance || 0;
          item.availableBalance = coinInfor.available_balance || 0;

          item.order = this.calculateInOrder(item);
          item.orderBtcValue = this.getOrderBtcBalance(item);

          item.btcValue = this.getBalance(coin, 'usdt');

          let usdValue = this.getBalance(coin, 'idr');
          // if do not have price of usd_$coin pair, transform to btc_$coin pair then transform to usd_btc pair.
          if (!usdValue) {
            const pair = 'usd_btc';
            const price = this.prices[pair] ? this.prices[pair].price : '0';
            usdValue = new BigNumber(item.btcValue).mul(price).toString();
          }
          item.usdValue = usdValue;

          item.isDeposit = this.coinSetting[item.coin] && this.coinSetting[item.coin].is_deposit;
          item.isWithdraw = this.coinSetting[item.coin] && this.coinSetting[item.coin].is_withdraw;

          data.push(item);
        }
      }
      // sort by coin name asc
      this.balances = window._.sortBy(data, 'name');
    },
    getCoinName(coin) {
      const key = `currency.${coin}.fullname`;
      return this.$i18n.t(key);
    },
    getBalance(coin, currency) {
      if (!this.accountBalances[coin]) return 0;

      if (coin === currency) {
        return `${this.accountBalances[coin].balance}`;
      }
      const pair = `${currency}_${coin}`;
      const pairRevert = `${coin}_${currency}`;

      if (this.prices[pairRevert]) {
        if (!this.prices[pairRevert].price) return 0;
        return new BigNumber(`${this.accountBalances[coin].balance}`)
          .div(this.prices[pairRevert].price)
          .toString();
      } else if (this.prices[pair]) {
        return new BigNumber(`${this.accountBalances[coin].balance}`)
          .mul(this.prices[pair].price)
          .toString();
      } else {
        return 0;
      }
    },
    getOrderBtcBalance(item) {
      if (!this.prices[`usdt_${item.coin}`]) {
        return 0;
      }
      return new BigNumber(item.order).mul(this.prices[`usdt_${item.coin}`].price).toString();
    },
    calculateInOrder(item) {
      // const result =  window._.find(this.totalPendingWithdraws, (row) => { return row && row.currency === item.coin});
      // const totalPendingWithdraw = result ? result.total : '0';
      // return new BigNumber(item.totalBalance).sub(item.availableBalance).sub(totalPendingWithdraw).toString();
      return new BigNumber(`${item.totalBalance}`).sub(item.availableBalance).toString();
    },
    sumBy(attr) {
      let total = new BigNumber(0);
      for (let item of this.balances) {
        total = total.add(new BigNumber(item[attr]));
      }
      return total.toString();
    },
    onBalanceUpdated(newAccountBalances) {
      this.accountBalances = window._.merge({}, this.accountBalances, newAccountBalances);
    },
    updateTable(newAccountBalances) {
      this.refresh();
    },
    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      this.addPricesInBtc();
    },
    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
        SpotBalanceUpdated: this.onBalanceUpdated,
        BalanceUpdated: this.updateTable,
      };
    },
    getTotalPendingWithdraw(currencies, callback) {
      if (window._.isEmpty(currencies)) {
        return;
      }

      const isContainUsdCurrency = window._.includes(currencies, 'idr');

      if (!isContainUsdCurrency || currencies.length > 1) {
        rf.getRequest('TransactionRequest')
          .getTotalPendingWithdraw()
          .then((res) => {
            window._.each(res.data, (item) => {
              this.updateCurrencyPendingWithdraw(item.currency, item.total);
            });
            if (callback) {
              callback();
            }
          });
      }
      // usd currency
      if (isContainUsdCurrency) {
        rf.getRequest('TransactionRequest')
          .getTotalUsdPendingWithdraw()
          .then((res) => {
            if (!res.data.total) {
              return;
            }
            this.updateCurrencyPendingWithdraw('idr', res.data.total);
            if (callback) {
              callback();
            }
          });
      }
    },
    updateCurrencyPendingWithdraw(currency, totalPendingWithdraw) {
      const item = window._.find(this.totalPendingWithdraws, (item) => item.currency === currency);
      if (item) {
        item.total = totalPendingWithdraw || '0';
      } else {
        this.totalPendingWithdraws.push(item);
      }
    },
  },
  created() {
    rf.getRequest('MasterdataRequest')
      .getAll()
      .then((res) => {
        this.currencyCoins = res.coin_settings;
      });

    if (!this.isAuthenticated) {
      return;
    }

    rf.getRequest('UserRequest')
      .getCurrentUser()
      .then((res) => {
        let securityLevel = res.data.security_level;

        rf.getRequest('MasterdataRequest')
          .getAll()
          .then((res) => {
            this.limit = window._.find(res.withdrawal_limits, (wl) => {
              return wl.security_level === securityLevel && wl.currency === 'btc';
            });
          });
      });

    rf.getRequest('UserRequest')
      .getBalance(true, Const.TYPE_EXCHANGE_BALANCE)
      .then((res) => {
        this.onBalanceUpdated(res.data);
      });
    rf.getRequest('PriceRequest')
      .getPrices()
      .then((res) => {
        this.onPricesUpdated(res.data);
      });

    document.title = this.$t('menu.balances') + ` – ${this.tabTitle}`;
  },
  mounted() {
    rf.getRequest('MasterdataRequest')
      .getAll()
      .then((res) => {
        this.coinSetting = window._.keyBy(res.coins_confirmation, (item) => item.coin);
      });
  },
};
</script>

<style lang="scss" scoped>
@include tablet;

@include mobile {
  .funds__mobile-wrapper {
    padding: 0 16px 24px;

    .funds__mobile-header {
      display: flex;
      justify-content: space-between;

      .checkbox-function {
        display: flex;

        label {
          display: flex;
          margin: unset;
          position: relative;
          margin-top: auto;
          margin-bottom: auto;

          & input {
            margin: unset;
            width: 24px;
            height: 24px;
            border: 1px solid $bg-main2 !important;
            border-radius: 4px;

            &[type='checkbox']:checked + div::before {
              content: '';
              display: block;
              position: absolute;
              text-align: center;
              height: 24px;
              width: 24px;
              left: 0;
              background-color: $bg-main1;
              border-radius: 4px;
            }

            &[type='checkbox']:checked + div::after {
              content: url('@/assets/images/spot_exchange/checkbox-tick.svg');
              display: block;
              position: absolute;
              top: 2px;
              left: 2px;
            }
          }

          & div {
            color: $text-dark-1;
            margin-left: 16px;
            font-size: 14px;
            font-weight: 300;
            line-height: 22px;
          }
        }
      }
    }

    .funds__mobile-body {
      margin-top: 32px;
      overflow: auto;

      & .data-list__wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .funds__list-item {
        padding: 16px;
        border-radius: 10px;
        background-color: $bg-main4;

        .funds__item-title {
          display: flex;
          justify-content: space-between;

          .item-title__wrapper {
            & img {
              margin-top: auto;
              margin-bottom: auto;
            }

            & > div {
              font-size: 14px;
              font-weight: 300;
              line-height: 22px;
            }

            display: flex;

            & > div:nth-child(2) {
              width: 70px;
              color: $text-main;
              margin-left: 6px;
            }

            & > div:nth-child(3) {
              margin-left: 4px;
            }

            & > div:nth-child(4) {
              margin-left: 11px;
            }

            & .item-title__date {
              margin-top: auto;
              margin-bottom: auto;
              font-size: 12px;
              font-style: normal;
              font-weight: 300;
              line-height: 16px;
              color: $text-main-blur;
            }
          }
        }

        .funds__item-desc {
          margin-top: 16px;
          display: grid;
          gap: 8px;
          row-gap: 16px;
          grid-template-columns: repeat(2, 1fr);

          .item-desc__wrapper {
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;

            &.text-end {
              text-align: end;
            }

            .item-desc__title {
              margin-top: 3px;
              font-size: 12px;
              font-style: normal;
              font-weight: 300;
              line-height: 16px;
              color: $text-main-blur;
            }

            .item-desc__value {
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 22px;
              color: $text-main;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

#funds {
  .table-history {
    padding: 10px 20px 0 20px;

    th {
      padding: 8px;
      @include text-font($helvetica, $text-secondary, $font-root, 700, 24px);
      border-bottom: none;
      white-space: nowrap;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
        text-align: end;
      }
    }

    td {
      padding: 10px 10px 12px;
      white-space: nowrap;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      border-bottom: 1px solid #dddddd;
      color: $text-main;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
        text-align: end;
      }

      &.coin {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    :deep() {
      thead {
        background-color: #f8f8f8;
      }

      .empty-data {
        .text-wrapper {
          gap: 0;
        }
      }
    }
  }
}
</style>
