<template>
  <div class="table-card" v-if="fromAccount && isMobile">
    <div class="loader" v-if="fetching === true || loading === true"></div>
    <slot name="body-card" v-for="(row, index) in rows" :item="row" :index="index" />
    <table v-if="this.rows.length === 0">
      <tbody>
        <template>
          <tr class="empty-data">
            <td :colspan="column" v-if="fetching === false && loading === false">
              <div class="empty-noti">
                <img class="icon-nodata" src="/images/table-nodata.png" alt="no_fund_icon" />
                <span class="msg-empty">{{
                  msgEmptyData || $t('common.datatable.data_empty')
                }}</span>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <template v-if="lastPage > 1">
      <pagination2
        :pageCount="lastPage"
        @Pagination:page="onPageChange"
        :isMobile="true"
      ></pagination2>
    </template>
  </div>
  <div v-else class="tableContainer" :style="{ width: widthTable }">
    <table>
      <thead>
        <tr :class="defaultSort ? 'default-sort' : 'custom-sort'" @click="onSort">
          <slot />
        </tr>
      </thead>
      <tbody>
        <slot name="first_row" />
        <slot name="body" v-for="(row, index) in rows" :item="row" :index="index" />
        <template v-if="this.rows.length === 0">
          <tr class="empty-data">
            <td :colspan="column" v-if="fetching === false && loading === false">
              <div class="empty-noti">
                <img class="icon-nodata" src="/images/table-nodata.png" alt="no_fund_icon" />
                <span class="msg-empty">{{
                  msgEmptyData || $t('common.datatable.data_empty')
                }}</span>
              </div>
            </td>
            <td :colspan="column" v-if="fetching === true || loading === true">
              <div class="loader"></div>
            </td>
          </tr>
        </template>
        <slot name="end_row" />
      </tbody>
    </table>
    <template v-if="lastPage > 1">
      <pagination2
        :pageCount="lastPage"
        @Pagination:page="onPageChange"
        :isMobile="false"
      ></pagination2>
    </template>
  </div>
</template>

<script>
import Pagination from './Pagination'
import Paginate from 'vuejs-paginate'
import Pagination2 from './Pagination2'

export default {
  components: {
    Pagination,
    Paginate,
    Pagination2,
  },
  props: {
    getData: {
      type: Function,
    },
    limit: {
      type: Number,
      default: 10,
    },
    chunk: {
      type: Number,
      default: 5,
    },
    widthTable: {
      type: String,
      default: '100%',
    },
    msgEmptyData: {
      type: String,
    },
    defaultSort: {
      type: Boolean,
      default: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    fromAccount: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    autoScrollToClassName: { default: '' },
  },
  data() {
    return {
      maxPageWidth: 10,
      totalRecord: 0,
      lastPage: 0,
      page: 1,
      perPage: 10,
      column: 0,
      fetching: false,
      rows: [],
      params: {},
      loading: false,
      orderBy: null,
      sortedBy: null,

      emptyData: false,
    }
  },
  watch: {
    'rows.length': {
      handler(newValue, oldValue) {
        if (newValue !== 0) this.fetching = false
      },
    },
    isLoading(newvalue) {
      this.loading = newvalue
    },
  },
  computed: {
    emptyRow() {
      let emptyRowCount = Math.max(this.limit - _.size(this.rows), 0)
      return Math.min(emptyRowCount, this.limit)
    },
  },
  methods: {
    onPageChange(page) {
      this.page = page
      this.fetch()
      if (this.autoScrollToClassName) this.scrollItemToTop(this.autoScrollToClassName)
    },

    getTarget(target) {
      let node = target
      while (node.parentNode.nodeName !== 'TR') {
        node = node.parentNode
      }
      return node
    },

    getSortOrder(target) {
      let sortOrder = target.dataset.sortOrder
      switch (sortOrder) {
        case 'asc':
          sortOrder = ''
          break
        case 'desc':
          sortOrder = 'asc'
          break
        default:
          sortOrder = 'desc'
      }
      return sortOrder
    },

    setSortOrders(target, sortOrder) {
      let iterator = target.parentNode.firstChild
      while (iterator) {
        iterator.dataset.sortOrder = ''
        iterator = iterator.nextElementSibling
      }
      target.dataset.sortOrder = sortOrder
    },

    onSort(event) {
      const target = this.getTarget(event.target)
      const orderBy = target.dataset.sortField
      if (!orderBy) {
        return
      }
      this.sortedBy = this.getSortOrder(target)
      this.orderBy = this.sortedBy ? orderBy : ''
      Object.assign(this.params, {
        sort: this.orderBy,
        sort_type: this.sortedBy,
      })
      this.setSortOrders(target, this.sortedBy)
      this.fetch()
    },

    fetch() {
      const meta = {
        page: this.page,
        limit: this.limit,
      }

      if (this.sortedBy?.length && this.orderBy?.length) {
        Object.assign(this.params, {
          sort: this.orderBy,
          sort_type: this.sortedBy,
        })
      }

      this.fetching = true
      this.loading = true
      this.getData(Object.assign(meta, this.params))
        .then((res) => {
          const data = res.data || res

          if (!data) {
            return
          }
          this.column = _.chain(this.$slots.default)
            .filter((el) => {
              return el.tag === 'th'
            })
            .value().length

          if (!data.data) {
            this.fetching = false
            this.rows = data
            this.$emit('DataTable:finish')
            return
          }
          this.page = parseInt(data.current_page)
          this.totalRecord = parseInt(data.total)
          this.lastPage = parseInt(data.last_page)
          this.perPage = parseInt(data.per_page)
          this.rows = data.data
          this.emptyData = window._.isEmpty(this.rows)
          this.fetching = false
          this.$emit('DataTable:finish')
        })
        .then((res) => {
          setTimeout(() => {
            this.fetching = false
            this.loading = false
          }, 5000)
        })
    },
    refresh() {
      this.page = 1
      this.params = {}
      this.fetch()
    },

    filter(params) {
      this.page = 1
      this.params = params
      this.fetch()
    },
  },
  created() {
    this.fetch()
    this.$on('DataTable:filter', (params) => {
      this.filter(params)
    })
  },
  mounted() {
    this.column = _.chain(this.$slots.default)
      .filter((el) => {
        return el.tag === 'th'
      })
      .value().length
  },
}
</script>
<style lang="scss" scoped>
.tableContainer3 {
  th {
    .group-sort-tabl3 {
      // display: inline-table;
      // width: 13px;
      // height: 20px;
      vertical-align: middle;
      position: relative;
      .icon-sort-tabl3 {
        display: none;
        height: 20px;
        width: 13px;
        line-height: 16px;
        // float: left;
        font-size: $font-smaller;
        text-align: center;
      }
    }
    &[data-sort-order='desc'] {
      .group-sort-tabl3 {
        .icon-arrow6 {
          display: inline-table;
        }
      }
    }
    &[data-sort-order='asc'] {
      .group-sort-tabl3 {
        .icon-arrow5 {
          display: inline-table;
        }
      }
    }
    &:hover {
      color: $color-orange;
    }
  }
}
</style>

<style lang="scss" scoped>
.table-card {
  background-color: $color-alabaster;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.loader {
  display: block;
  margin: auto;
  border: 5px solid $color-grey-concrete;
  border-radius: 50%;
  border-top: 5px solid $bg-main1;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

tr.default-sort {
  th {
    &:last-child {
      text-align: end;
    }

    i {
      display: none;
    }
  }
}

tr.custom-sort {
  th {
    &:last-child {
      text-align: end;
    }
    i {
      display: none;
      float: left;
      margin-left: 5px;
      margin-top: 2px;
    }
    span {
      float: left;
    }
  }
  th[data-sort-order='desc'] {
    i.icon-arrow6 {
      display: block;
    }
    &:after {
      display: none;
    }
  }
  th[data-sort-order='asc'] {
    i.icon-arrow5 {
      display: block;
    }
    &:after {
      display: none;
    }
  }
}

// th[data-sort-field]::after {
//   font-family: "Glyphicons Halflings", sans-serif;
//   margin-left: 5px;
//   vertical-align: middle;
//   font-size: 9px;
//   color: $color-green;
// }

tr.default-sort th[data-sort-order='asc'] {
  color: $color-green;
  &:after {
    font-family: 'icomoon' !important;
    color: $color-green;
    content: '\e906';
    font-size: $font-root;
  }
}

tr.default-sort th[data-sort-order='desc'] {
  color: $color-green;
  &:after {
    font-family: 'icomoon' !important;
    color: $color-green;
    content: '\e907';
    font-size: $font-root;
  }
}
.tableContainer {
  height: 100%;
}
table {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  thead {
    text-align: center;
    color: $color_black;
    border-radius: 10px 10px 0px 0px;
    background: $bg-main4;
    border: none;
    line-height: 17px;
    overflow: hidden;
    th {
      user-select: none;
      font-weight: normal;
      font-size: $font_small;
      border: none;
      // padding: 8px 0px 8px 20px;
      line-height: 20px;
      color: $color-grey;
      cursor: pointer;
      // &::after {
      //   margin-left: 5px;
      //   position: relative;
      //   top: 0px;
      //   width: 14px;
      //   height: 15px;
      //   display: inline-block;
      //   line-height: 17px;
      //   overflow: hidden;
      //   position: relative;
      //   top: -2px;
      //   content: "";
      // }
      // &:last-child {
      //   padding: 8px 20px 8px 8px;
      // }
    }
  }
  tbody {
    text-align: center;
    color: #333333;
    tr {
      vertical-align: top;
      overflow-y: hidden;
      transition-property: height;
      transition-duration: 0.3s, 0.3s;
      transition-timing-function: ease, ease-in;
      & + tr {
        border-top: 1px solid rgba(221, 221, 221, 1);
      }
      .icon-arrow_2_2 {
        color: $color-green;
      }
      &.active {
        max-height: 300px;
        background-color: $color-bright-gray;
        transition-property: height;
        transition-duration: 0.3s, 0.3s;
        transition-timing-function: ease, ease-in;
      }
      &.empty-data {
        width: 100%;
        height: 100px;
        text-align: center;
        color: $color-grey-dusty;
        font-size: $font-root;
        text-align: center;
        td {
          width: 100%;
          text-align: center !important;
          color: $color-grey-dusty !important;
          padding: 35px 0;
          .empty-noti {
            // height: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            align-items: center;
            .msg-empty {
              color: $text-secondary;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
            .icon-nodata {
              width: 137px;
              height: auto;
            }
            @include mobile {
              .text-wrapper {
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                width: fit-content;
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                  margin-top: 20px;
                  margin-bottom: 10px;
                }
              }
            }
          }
          :last-child {
            text-align: end;
          }
        }

        &:hover {
          background-color: $color-white !important;
        }
        p {
          padding-top: 22px;
          span {
            vertical-align: middle;
          }
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
    td {
      font-weight: normal;
      font-size: $font_root;
      color: $color-grey-dusty;
      border: none;
      padding: 12px 0px 12px 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 24px;
      &:last-child {
        padding: 8px 0px 8px 0px;
        text-align: right;
      }
      &.info {
        background-color: $color-bright-gray;
        color: $color-grey-dusty;
        font-size: 13px;
        text-align: left;
        padding: 8px 20px 8px 38px;
        .label-text {
          color: $color-grey;
        }
      }
    }
  }
}
</style>
