<template>
  <div id="history" class="page_container">
    <div class="history_container">
      <div class="title-medium-small">
        {{ $t('funds.history.transaction_history') }}
      </div>
      <div class="content">
        <div class="toolbar">
          <div class="tab-container">
            <div
              class="tab-element"
              @click="changeTab('deposit')"
              v-bind:class="{ active: selectedTab === 'deposit' }"
            >
              {{ $t('funds.history.deposit') }}
            </div>
            <div
              class="tab-element"
              @click="changeTab('withdraw')"
              v-bind:class="{ active: selectedTab === 'withdraw' }"
            >
              {{ $t('funds.history.withdrawal') }}
            </div>
          </div>
          <span class="export-file">
            <button @click="downloadReport()" class="excel_export">
              <img class="export-icon" src="/images/wallet/export_icon.svg" />
              <span v-if="selectedTab === 'deposit'">{{
                $t('funds.history.export_complete_deposit_history')
              }}</span>
              <span v-else>{{ $t('funds.history.export_complete_withdraw_history') }}</span>
            </button>
          </span>
        </div>
        <div class="history_table">
          <template v-if="isMobile">
            <div class="transaction-history__mobile-wrapper">
              <data-list-mobile
                ref="datatable"
                :typeHistory="selectedTab"
                :get-data="getData"
                :isExpand="true"
                :limit="10"
                :height="30"
                @DataTable:finish="onDatatableFinish"
              >
                <template slot="table-empty-header">
                  <thead>
                    <tr>
                      <th class="cl1">
                        {{ $t('funds.history.status') }}
                      </th>
                      <th class="cl2">
                        {{ $t('funds.history.coin') }}
                      </th>
                      <th class="cl3">
                        {{ $t('funds.history.recevied_amount') }}
                      </th>
                      <th class="cl5 t-right after_none"></th>
                    </tr>
                  </thead>
                </template>
                <template slot="header" slot-scope="props">
                  <div class="transaction-history__mobile-title">
                    <div class="mobile-title__item" data-sort-field="status" @click="props.onSort">
                      {{ $t('funds.history.status') }}
                      <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                    <div
                      class="mobile-title__item"
                      data-sort-field="currency"
                      @click="props.onSort"
                    >
                      {{ $t('funds.history.coin') }}
                      <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                    <div class="mobile-title__item" data-sort-field="amount" @click="props.onSort">
                      {{ $t('funds.history.recevied_amount') }}
                      <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                  </div>
                </template>
                <template slot="body" slot-scope="props">
                  <div class="transaction-history__mobile-item">
                    <div class="mobile-item__wrapper">
                      <div class="item-wrapper__title">
                        <div
                          v-if="props.item.status === 'failed' && props.item.remarks"
                          class="item group-tooltip"
                          :class="`status-${props.item.status}`"
                        >
                          <span style="font-size: 16px">{{
                            $t(`common.transaction_status.${props.item.status}`)
                          }}</span>
                          <span class="help-box">
                            <img src="/images/icon/Question.png" alt="tolltip" />
                            <div class="box-tooltip">
                              <div class="bubble top" id="still-not-receive">
                                <div>
                                  {{ $t(`common.transaction_message.${props.item.remarks}`) }}
                                </div>
                                <a @click="openPopupContactUs()">{{
                                  $t('menu_bottom.contact_us')
                                }}</a>
                              </div>
                            </div>
                          </span>
                        </div>
                        <div v-else :class="`status-${props.item.status}`">
                          <strong>{{
                            $t(`common.transaction_status.${props.item.status}`)
                          }}</strong>
                        </div>
                      </div>

                      <div class="item-wrapper__title">
                        {{ props.item.currency | uppercase }}
                      </div>
                      <div class="item-wrapper__title">
                        {{ props.item.amount | abs | formatCurrencyAmount(null, '0') }}
                      </div>
                      <div
                        class="item-wrapper__dropdown-icon"
                        @click="props.onClickExpandItem(props)"
                      >
                        <img
                          src="/images/instant-convert/icon-dropdown.svg"
                          alt=""
                          v-if="!props.isActive"
                        />
                        <img src="/images/instant-convert/icon-dropdown2.svg" alt="" v-else />
                      </div>
                    </div>
                    <div class="mobile-item__expand-content" :class="{ active: props.isActive }">
                      <div class="expand-content__wrapper" v-if="selectedTab === 'withdraw'">
                        <div class="expand-content__title">
                          {{ $t('funds.history.fee') }}
                        </div>
                        <div class="expand-content__desc">
                          {{ props.item.fee | abs | formatCurrencyAmount('idr', '0') }}
                        </div>
                      </div>
                      <div
                        class="expand-content__wrapper"
                        v-if="selectedTab === 'withdraw' && isUserIndoAndKyc"
                      >
                        <div class="expand-content__title">
                          {{ $t('funds.history.tax') }}
                        </div>
                        <div class="expand-content__desc">
                          {{ props.item.tax | abs | formatCurrencyAmount('idr', '0') }}
                        </div>
                      </div>
                      <div class="expand-content__wrapper">
                        <div class="expand-content__title">
                          {{ $t('funds.history.date') }}
                        </div>
                        <div class="expand-content__desc">
                          <span>{{ props.item.created_at | orderDate }} </span>
                          <span>{{ props.item.created_at | orderTime }}</span>
                        </div>
                      </div>
                      <hr />
                      <div v-if="props.item.transaction_id" class="info-container">
                        <div v-if="configs.type === 'withdraw'" class="expand-content__wrapper">
                          <div class="expand-content__title">
                            {{ $t('funds.history.unique_code') }}:&nbsp;
                          </div>
                          <div class="expand-content__desc">
                            {{ props.item.unique_code }}
                          </div>
                        </div>
                        <div class="expand-content__wrapper">
                          <div v-if="selectedTab == 'deposit'" class="expand-content__title">
                            {{ $t('funds.history.from') }} {{ $t('funds.history.address') }}:&nbsp;
                          </div>
                          <div v-else class="expand-content__title">
                            {{ $t('funds.history.to') }} {{ $t('funds.history.address') }}:&nbsp;
                          </div>
                          <div v-if="selectedTab == 'deposit'" class="expand-content__desc">
                            {{ removeTag(props.item.from_address) }}
                          </div>
                          <div v-else class="expand-content__desc">
                            {{ removeTag(props.item.to_address) }}
                          </div>
                        </div>
                        <div
                          class="expand-content__wrapper"
                          v-if="props.item.currency === 'xrp' || props.item.currency === 'eos'"
                        >
                          <div class="expand-content__title">
                            {{ $t('funds.history.tag') }}:&nbsp;
                          </div>
                          <div class="expand-content__desc">
                            {{ props.item.blockchain_sub_address }}
                          </div>
                        </div>
                        <div class="expand-content__wrapper">
                          <div class="expand-content__title">
                            {{ $t('funds.history.txhash') }}:&nbsp;
                          </div>
                          <div
                            class="expand-content__desc link"
                            v-if="props.item.tx_hash"
                            @click="gotoTransactionUrl(props.item)"
                          >
                            {{ props.item.tx_hash }}
                          </div>
                          <div class="expand-content__desc" v-else>
                            {{ props.item.transaction_id }}
                          </div>
                        </div>

                        <div class="expand-content__wrapper">
                          <div class="expand-content__title">
                            {{ $t('funds.history.number_of_confirmations') }}:&nbsp;
                          </div>
                          <div class="expand-content__desc">
                            {{ props.item.confirmation_number }}
                          </div>
                          <div
                            v-if="props.item.confirmation_number && props.item.status === 'success'"
                            class="td-strong success"
                          >
                            {{ $t('funds.history.confirmed') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </data-list-mobile>
            </div>
          </template>
          <template v-else>
            <data-table3
              :getData="getData"
              :typeHistory="selectedTab"
              :limit="10"
              :widthTable="'100%'"
              ref="datatable"
              @DataTable:finish="onDatatableFinish"
            >
              <template slot-scope="props">
                <th class="cl1" data-sort-field="status" @click="props.echoclick">
                  {{ $t('funds.history.status') }}
                  <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                  <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                </th>
                <th class="cl2" data-sort-field="currency" @click="props.echoclick">
                  {{ $t('funds.history.coin') }}
                  <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                  <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                </th>
                <th class="cl3" data-sort-field="amount" @click="props.echoclick">
                  {{ $t('funds.history.recevied_amount') }}
                  <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                  <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                </th>
                <template v-if="selectedTab === 'withdraw'">
                  <th class="cl3" data-sort-field="fee" @click="props.echoclick">
                    {{ $t('funds.history.fee') }}
                    <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                  </th>
                  <th
                    class="cl3"
                    data-sort-field="tax"
                    @click="props.echoclick"
                    v-if="isUserIndoAndKyc"
                  >
                    {{ $t('funds.history.tax') }}
                    <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                  </th>
                </template>
                <th class="cl4" data-sort-field="created_at" @click="props.echoclick">
                  {{ $t('funds.history.time') }}
                  <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                  <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                </th>
                <th class="cl5 t-right after_none"></th>
              </template>
              <template slot="body" slot-scope="props">
                <tr
                  :class="{
                    active: props.index === isActiveRow,
                    white: (props.index + 1) % 2 !== 0 || props.index === 0,
                    white: (props.index + 1) % 2 === 0 && props.index !== 0,
                  }"
                >
                  <td class="cl1 t-left fs14 cl-transaction-status item group-tooltip">
                    <div
                      v-if="props.item.status === 'failed' && props.item.remarks"
                      :class="`status-${props.item.status}`"
                    >
                      {{ $t(`common.transaction_status.${props.item.status}`) }}
                      <span class="help-box">
                        <img src="/images/icon/Question.png" alt="tolltip" />
                        <div class="box-tooltip">
                          <div class="bubble top" id="still-not-receive">
                            <div>{{ $t(`common.transaction_message.${props.item.remarks}`) }}</div>
                            <a @click="openPopupContactUs()">{{ $t('menu_bottom.contact_us') }}</a>
                          </div>
                        </div>
                      </span>
                    </div>
                    <div v-else :class="`status-${props.item.status}`">
                      {{ $t(`common.transaction_status.${props.item.status}`) }}
                    </div>
                  </td>
                  <td class="cl2 t-left fs14">
                    {{ props.item.currency | uppercase }}
                  </td>
                  <td class="cl3 t-left fs14">
                    {{ props.item.amount | abs | formatCurrencyAmount(null, '0') }}
                  </td>
                  <template v-if="selectedTab === 'withdraw'">
                    <td class="cl3 t-left fs14">
                      {{ formatFeeTax(props.item.fee, null) }}
                    </td>
                    <td class="cl3 t-left fs14" v-if="isUserIndoAndKyc">
                      {{ formatFeeTax(props.item.tax, null) }}
                    </td>
                  </template>
                  <td class="cl4 t-left fs14">
                    {{ props.item.created_at | timestampToDate }}
                  </td>
                  <td class="cl5" @click="activeRow(props.index)">
                    <!-- <span
                    class="icon"
                    :class="isActiveRow === props.index ? 'icon-arrow2' : 'icon-arrow1'"
                      aria-hidden="true"
                    ></span> -->
                    <div class="icon-arrow">
                      <img
                        src="/images/instant-convert/icon-dropdown2.svg"
                        alt=""
                        v-if="isActiveRow === props.index"
                      />
                      <img src="/images/instant-convert/icon-dropdown.svg" alt="" v-else />
                    </div>
                  </td>
                </tr>
                <tr v-if="isActiveRow === props.index" :class="{ even: props.index % 2 === 1 }">
                  <td class="info" :colspan="isUserIndoAndKyc ? 7 : 6">
                    <div v-if="props.item.transaction_id" class="info-container">
                      <div v-if="configs.type === 'withdraw'" class="info-flex">
                        <span class="t-right label-text row-transaction-info"
                          >{{ $t('funds.history.unique_code') }}:&nbsp;</span
                        >
                        <span>{{ props.item.unique_code }}</span>
                      </div>

                      <div class="info-flex">
                        <span
                          v-if="selectedTab == 'deposit'"
                          class="t-right label-text row-transaction-info"
                          >{{ $t('funds.history.from') }}
                          {{ $t('funds.history.address') }}:&nbsp;</span
                        >
                        <span v-else class="t-right label-text row-transaction-info"
                          >{{ $t('funds.history.to') }}
                          {{ $t('funds.history.address') }}:&nbsp;</span
                        >
                        <span v-if="selectedTab == 'deposit'">{{
                          removeTag(props.item.from_address)
                        }}</span>
                        <span v-else>
                          {{ removeTag(props.item.to_address) }}
                        </span>
                      </div>
                      <div
                        class="info-flex"
                        v-if="props.item.currency === 'xrp' || props.item.currency === 'eos'"
                      >
                        <span class="t-right label-text row-transaction-info"
                          >{{ $t('funds.history.tag') }}:&nbsp;</span
                        >
                        <span>{{ props.item.blockchain_sub_address }}</span>
                      </div>
                      <div class="info-flex">
                        <span class="label-text row-transaction-info"
                          >{{ $t('funds.history.txhash') }}:</span
                        >
                        <span
                          v-if="props.item.tx_hash"
                          class="link"
                          @click="gotoTransactionUrl(props.item)"
                          >{{ props.item.tx_hash }}</span
                        >
                        <span v-else>{{ props.item.transaction_id }}</span>
                      </div>
                      <div class="info-flex">
                        <span class="t-right label-text row-transaction-info"
                          >{{ $t('funds.history.number_of_confirmations') }}:&nbsp;</span
                        >
                        <span>{{ props.item.confirmation_number }}</span>
                        <span
                          v-if="props.item.confirmation_number && props.item.status === 'success'"
                          class="td-strong success"
                        >
                          {{ $t('funds.history.confirmed') }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
            </data-table3>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TransactionHistory from '@/components/shared_components/funds/TransactionHistory';
import Utils from '@/common/Utils';
import rf from '@/request/RequestFactory';
import moment from 'moment';
import BigNumber from 'bignumber.js';

export default {
  name: 'TransactionHistory',
  components: {
    TransactionHistory,
  },
  data() {
    let selectedTab = this.$route.query.type ? this.$route.query.type : 'deposit';
    return {
      windowWidth: window.innerWidth,
      selectedTab: selectedTab,
      configs: {
        type: this.$route.query.type ? this.$route.query.type : 'deposit',
      },
      isActiveRow: -1,
      noFunds: this.getNoFunsText(selectedTab),
      dataLength: 1,
      kyc: {
        status: '',
        country: '',
      },
      blockExplorers: {},
    };
  },
  computed: {
    isUserIndoAndKyc() {
      return this.kyc.country === 'Indonesia' && this.kyc.status === 'verified';
    },
    isMobile() {
      if (!this.windowWidth) return;
      return this.windowWidth <= 768;
    },
  },
  methods: {
    openPopupContactUs() {
      window.FreshworksWidget('open');
    },
    removeTag(address) {
      if (address) {
        return address.split('|')[0];
      }
    },
    formatFeeTax(amount, currency) {
      if (
        amount != 0 &&
        new BigNumber(amount).lt(0.00000001) &&
        currency !== 'idr' &&
        currency !== 'usdt' &&
        currency !== 'busd'
      )
        return '0.00000001';
      return Utils.formatCurrencyAmount(amount, currency, '0');
    },
    onDatatableFinish() {
      // Only sort 'amount' field a again. Because amount field can be negative (withdraw) or positive (deposit).
      this.dataLength = this.$refs.datatable?.rows?.length;
      const datatable = this.$refs.datatable;
      if (datatable.params.sort !== 'amount') {
        return;
      }
      const result = window._.chain(datatable.rows)
        .map((item) => {
          item.amount = Math.abs(parseFloat(item.amount));
          return item;
        })
        .orderBy([datatable.params.sort], [datatable.params.sort_type])
        .value();
      // console.log(result, datatable.params.sort_type);
      this.$refs.datatable.rows = result;
    },
    activeRow(rowIndex) {
      this.isActiveRow = this.isActiveRow === rowIndex ? -1 : rowIndex;
    },
    getNoFunsText(selectedTab) {
      return selectedTab == 'deposit'
        ? this.$i18n.t('funds.transaction_history.no_deposit_data')
        : this.$i18n.t('funds.transaction_history.no_withdraw_data');
    },
    changeTab(tab) {
      if (this.selectedTab === tab) return;
      this.$router.push({ query: { type: tab } });
      this.selectedTab = tab;
      this.configs = {
        type: this.selectedTab,
      };
      this.noFunds = this.getNoFunsText(this.selectedTab);
      this.refresh();
    },
    refresh() {
      this.$refs.datatable.rows = [];
      this.$refs.datatable.totalRecord = 0;
      this.$refs.datatable.fetch();
    },
    getData(params) {
      this.isActiveRow = -1;
      let meta = {
        type: this.configs.type,
      };
      const txData = rf
        .getRequest('TransactionRequest')
        .getHistory(Object.assign({}, params, meta));
      return txData;
    },
    gotoTransactionUrl(tx) {
      // window.open(Utils.getTransactionUrl(tx.currency, tx.tx_hash), '_blank')

      let blockExplorer = this.blockExplorers.find((item) => item.coin == tx.currency);

      let url = blockExplorer.transaction_tx_path.replace('{$transaction_id}', tx.tx_hash);

      if (tx.network === 'trc20' && tx.currency == 'usdt') {
        url = blockExplorer.transaction_tx_path_trc20.replace('{$transaction_id}', tx.tx_hash);
      }

      window.open(url, '_blank');
    },
    downloadReport() {
      const fileName =
        this.selectedTab === 'deposit'
          ? 'DepositHistory_' + moment().format('YYYYMMDDHHmmss')
          : 'WithdrawalHistory_' + moment().format('YYYYMMDDHHmmss');
      rf.getRequest('TransactionRequest').downloadTransactions(
        {
          timezone_offset: Utils.getTimzoneOffset(),
          type: this.selectedTab,
        },
        fileName
      );
    },
    getUserKyc() {
      rf.getRequest('UserRequest')
        .getUserKyc()
        .then((res) => {
          this.kyc.status = res.data.status;
          this.kyc.country = res.data.country;
        });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.blockExplorers = this.$store.state.masterdata.coins;
    this.getUserKyc();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  created() {
    document.title = this.$t('menu.history_title') + ` – ${this.tabTitle}`;
  },
};
</script>

<style lang="scss">
.cl4 {
  width: 166px;
}

.group-tooltip {
  color: $bg-main6;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 5px;

  .help-box {
    position: relative !important;

    img {
      cursor: pointer;
    }

    + span {
      white-space: nowrap;
    }
  }

  .box-tooltip {
    &::before {
      content: '';
      position: absolute;
      left: -314% !important;
      bottom: 100%;
      translate: unset !important;
      border: 5px solid transparent;
      border-bottom-color: $tool-tip-color;
    }

    width: 200px !important;
    position: absolute;
    display: none;
    z-index: 999999;
    text-align: center;
    background: $tool-tip-color;
    left: -314% !important;
    top: calc(100% + 0.75rem);
    translate: unset !important;
  }

  &:hover .box-tooltip {
    display: block;
  }
}

.bubble {
  color: $color-white;
  font-family: $helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  padding: 15px;
  width: 100% !important;

  a {
    &:hover {
      color: $color-white;
      font-family: $helvetica;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      text-decoration: none;
      color: $color-blue !important;
    }
  }

  /*triangle side*/

  /*top*/
  &.top:after {
    border-color: $color-white transparent;
    border-width: 0 13px 12px 13px;
    top: -12px;
    left: 45%;
  }

  &.top:before {
    border-color: #d0cfd2 transparent;
    border-width: 0 13px 11px 13px;
    top: -13px;
    left: 45%;
  }
}

#history {
  margin: 0 auto;
  padding: 26px 15px 49px;
  max-width: 1370px;

  .history_table {
    .transaction-history__mobile-wrapper {
      .data-list__wrapper {
        .transaction-history__mobile-title {
          display: grid;
          border-radius: 10px 10px 0px 0px;
          background-color: $bg-main4;
          padding: 8px 16px;
          grid-template-columns: 90px 60px 1fr;

          .mobile-title__item {
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            color: $text-secondary;
          }
        }

        .transaction-history__mobile-item {
          background-color: $color-white;
          padding: 10px 16px;
          border-bottom: 1px solid $bg-main2;

          .mobile-item__wrapper {
            display: grid;
            position: relative;
            grid-template-columns: 90px 60px 1fr;

            .item-wrapper__title {
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 22px;
              color: $text-main;

              .status-success {
                color: $color-green !important;
              }

              .status-pending {
                color: $color-yellow !important;
              }

              .status-submitted {
                color: $color-dark-green;
              }

              .status-cancel {
                color: $text-main-blur !important;
              }

              .status-failed {
                color: $text-red !important;
              }

              .status-error,
              .status-cancel,
              .status-rejected,
              .status-failed {
                color: $color-denim;
              }
            }

            .item-wrapper__dropdown-icon {
              position: absolute;
              right: 0px;
            }
          }

          .mobile-item__expand-content {
            display: none;

            &.active {
              display: flex;
            }

            margin-top: 12px;
            background-color: $bg-main4;
            padding: 16px;
            flex-direction: column;
            border-radius: 8px;
            gap: 8px;

            .expand-content__wrapper {
              display: flex;
              justify-content: space-between;

              .expand-content__title {
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                color: $text-main;
              }

              .expand-content__desc {
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 22px;
                color: $text-main;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                word-wrap: break-word;

                &.link {
                  cursor: pointer;
                  color: $color-blue;
                }
              }
            }

            hr {
              margin: 10px 0px;
              border-color: #ddd;
            }
          }
        }
      }
    }

    .tableContainer {
      overflow: auto;

      table {
        background-color: $color-white;
        border-top: 0px;
        min-width: 700px;

        thead {
          height: 40px;
          background: $bg-main4;

          @include mobile {
            height: 32px;
          }

          tr {
            th {
              font-family: $helvetica;
              color: $text-secondary;
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              text-align: left;
              border-bottom: none;

              &:first-child {
                padding-left: 24px;
              }

              &:last-child {
                padding-right: 24px;
                width: 50px;
              }

              @include mobile {
                font-size: 12px;

                &:first-child {
                  padding-left: 16px;
                }

                &:last-child {
                  padding-right: 16px;
                }
              }
            }
          }
        }

        tbody {
          tr {
            vertical-align: top;
            height: 40px;
            overflow-y: hidden;
            transition-property: height;
            transition-duration: 0.3s, 0.3s;
            transition-timing-function: ease, ease-in;
            padding: 0 24px;
            border-bottom: 1px solid $bg-main2;

            &.empty-data {
              border-bottom: none;
            }

            &.active {
              max-height: 300px;
              transition-property: height;
              transition-duration: 0.3s, 0.3s;
              transition-timing-function: ease, ease-in;
              background-color: #fff;
              border-bottom: none;

              .glyphicon-menu-down {
                transition-duration: 0.5s;
                transform: rotate(180deg);
              }
            }

            td {
              font-family: $helvetica;
              color: $text-main;
              font-size: 16px;
              font-weight: 300;
              line-height: 24px;

              &:first-child {
                padding-left: 24px;
              }

              &:last-child {
                padding-right: 24px;
              }

              @include mobile {
                font-size: 14px;

                &:first-child {
                  padding-left: 16px;
                }

                &:last-child {
                  padding-right: 16px;
                }
              }

              &.info {
                .info-container {
                  padding: 16px 24px;
                  background-color: $bg-main4;
                  border-radius: 8px;

                  .info-flex {
                    display: flex;

                    @include mobile {
                      flex-direction: column;
                    }
                  }

                  @include mobile {
                    padding: 16px;
                  }
                }

                padding: 10px 24px;
                font-weight: 500;
                color: $dark-1;
                background-color: #fff;

                .label-text {
                  color: $text-main;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 24px;
                  margin-right: 4px;

                  @include mobile {
                    font-size: 14px;
                  }
                }

                span {
                  color: $text-secondary;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;

                  @include mobile {
                    font-size: 14px;
                  }
                }

                // .to {
                //   color: $color-grey;
                // }
                .link {
                  cursor: pointer;
                  color: $color-blue;
                }
              }

              .status-success {
                color: $color-green !important;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;

                @include mobile {
                  font-size: 14px;
                }
              }

              .status-pending {
                color: $color-yellow !important;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;

                @include mobile {
                  font-size: 14px;
                }
              }

              .status-submitted {
                color: $color-dark-green;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;

                @include mobile {
                  font-size: 14px;
                }
              }

              .status-cancel {
                color: $text-main-blur !important;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;

                @include mobile {
                  font-size: 14px;
                }
              }

              .status-failed {
                color: $text-red !important;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;

                @include mobile {
                  font-size: 14px;
                }
              }

              .status-error,
              .status-cancel,
              .status-rejected,
              .status-failed {
                color: $color-denim;
              }
            }

            .glyphicon-menu-down {
              color: $dark-3;
            }

            .glyphicon-menu-down.active-icon {
              color: $dark-3 !important;
            }

            &.active {
              .glyphicon-menu-down {
                color: $color-grey-dusty;
              }
            }

            .cl-transaction-status {
              // div {
              //   width: 69px;
              // }
            }

            .row-transaction-info {
              // width: 69px;
              display: inline-block;
              text-align: left;
              color: $color-grey-dusty;
            }
          }
        }
      }

      @include mobile {
        position: relative;

        > :nth-child(2) {
          position: sticky;
          left: 0;
          width: fit-content;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.history_container {
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  width: 100%;

  @include mobile {
    gap: 16px;
  }
}

.title-medium-small {
  color: $text-main;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
}

.content {
  border-radius: 10px;
  background: $color-white;
  padding: 24px 0;
}

.color_white {
  color: $color_white;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.gray_color {
  color: $color-grey;
}

.toolbar {
  text-align: center;
  margin-bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;

  @include mobile {
    background: transparent;
  }

  .tab-container {
    display: flex;
    height: 40px;

    @include mobile {
      background: white;
      width: 100%;
      height: 55px;
      border-radius: 10px 10px 0 0;
    }
  }

  .tab-element {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0 20px 12px !important;
    gap: 10px;
    min-width: 120px;
    width: 10%;
    height: 40px !important;
    border-radius: 10px 10px 0px 0px;
    border: none !important;
    background: transparent !important;
    cursor: pointer;
    color: var(--text-disable, rgba(2, 0, 13, 0.38));
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;

    @include mobile {
      height: 55px !important;
    }

    &.active {
      color: $text-main !important;
      border-bottom: 4px solid $bg-main1 !important;
    }
  }
}

.icon-export {
  color: $color_yellow;
}

.history_table {
  background-color: $color_white;
  width: 100%;
  border-radius: 0px 10px 10px 10px;
  min-height: 450px;
  // :deep .empty-data .empty-noti .text-wrapper {
  //   span {
  //     @include text-font($helvetica, $dark-3, $font-root, 700, 18px);
  //     @include mobile {
  //       font-size: 10px;
  //       line-height: 13px;
  //     }
  //   }
  // }
}

.active_sort {
  color: $color_yellow;
}

.t-left {
  text-align: left;
}

.icon-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.white {
  background-color: $color_white;
}

.gray {
  background-color: $color-grey-white !important;
}

.icon-arrow1 {
  padding-left: 10px;
  color: $dark-3;

  &.icon {
    cursor: pointer;
  }
}

.icon-arrow2 {
  padding-left: 10px;
  color: $dark-3;

  &.icon {
    cursor: pointer;
  }
}

.info {
  text-align: left;
  overflow: initial;
  font-size: $font-small;

  span {
    color: $color-grey-dark;

    .label-text {
      color: $color-grey;
    }

    a {
      color: $color-grey-dark;
    }
  }
}

.txid-link {
  float: left;

  &:last-child {
    width: 300px;
    word-break: break-all;
  }
}

tr {
  th {
    text-align: center;
  }
}

.sort,
.glyphicon {
  cursor: pointer;

  &::before {
    font-size: 20px;
  }
}

#history {
  .history_container {
    .export-file {
      color: $text-dark-1;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      .excel_export {
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        background: transparent;
        // color: $primary-0;
      }

      button:disabled,
      button[disabled] {
        cursor: not-allowed;
      }
    }
  }

  @include mobile {
    padding: 16px;

    .title-medium-small {
      font-size: 18px;
      line-height: 32px;
    }

    .content {
      padding: 0;
      background: transparent;

      .toolbar {
        .export-file {
          font-size: 14px;
        }
      }
    }

    .toolbar {
      flex-direction: column-reverse;
      gap: 16px;
      align-items: flex-start;
      padding: 16px 0 0;

      .tab-element {
        font-size: 14px;
        min-width: 100px;
        padding-top: 10px !important;
        height: 55px !important;
      }
    }

    .history_table {
      border-radius: 0px 7px 7px 7px;
    }
  }
}
</style>
