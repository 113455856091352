<template>
  <div
    id="trade-history"
    :class="{ no_padding: options && options.isNoPadding }"
  >
    <template v-if="isSpotMobile">
      <div class="trade-history__mobile-wrapper">
        <div class="group-control" v-if="!options">
          <date-tab @change-select-date="changeDate" ref="selectDateTab" />
          <div class="group-date">
            <div class="group-date__item">
              <span class="date-title">{{ $t("funds.history.from") }}</span>
              <datepicker
                :language="datePickerLang"
                :value="dateFromDefault"
                class="date-from"
                @selected="selectDateFrom"
                :format="customFormatter"
              ></datepicker>
            </div>
            <div class="group-date__item">
              <span class="date-title">{{ $t("funds.history.to") }}</span>
              <datepicker
                :language="datePickerLang"
                :value="dateToDefault"
                class="date-to"
                @selected="selectDateTo"
                :format="customFormatter"
              ></datepicker>
            </div>
          </div>
          <button class="button-search" @click="actionSearch">
            {{ $t("order.order_history.search") }}
          </button>
          <div class="checkbox-function">
            <label for="checkbox_open_order_id">
              <input
                type="checkbox"
                name="checkbox"
                v-model="isHideOtherPair"
                value="check"
                id="checkbox_open_order_id"
                @change="onOrderListUpdated(null, isHideOtherPair)"
              />
              <div>{{ $t("order.open_order.hide_other_pairs") }}</div>
            </label>
          </div>
        </div>
        <div
          class="group-control"
          v-if="options"
          :class="{ is_single_page: options && options.isNoPadding }"
        >
          <div class="group_1">
            <div class="group-date-picker">
              <div class="group-date">
                <div class="label-text">
                  {{ $t("order.order_history.from") }}
                </div>
                <datepicker
                  :language="datePickerLang"
                  :value="dateFromDefault"
                  class="date-from"
                  @selected="selectDateFrom"
                  :format="customFormatter"
                  input-class="date-picker-input"
                ></datepicker>
              </div>
              <div class="group-date">
                <div class="label-text">{{ $t("order.order_history.to") }}</div>
                <datepicker
                  :language="datePickerLang"
                  :value="dateToDefault"
                  class="date-to"
                  @selected="selectDateTo"
                  :format="customFormatter"
                  input-class="date-picker-input"
                ></datepicker>
              </div>
            </div>

            <div class="group-pair">
              <div class="content-search">
                <div class="label-text">
                  {{ $t("order.order_history.pair") }}
                </div>
                <div class="toolbar">
                  <div class="toolbar-element2">
                    <select-box
                      v-model="filters.coin"
                      :placeholder="$t('order.order_history.all')"
                      :options="getCoins()"
                    />
                  </div>
                  <span class="bw-element">/</span>
                  <div class="toolbar-element2">
                    <select-box
                      v-model="filters.currency"
                      :placeholder="$t('order.order_history.all')"
                      :options="getMarkets()"
                    />
                  </div>
                </div>
              </div>
              <div class="toolbar-element3">
                <!-- <div class="label-text">{{ $t("order.order_
                  history.side") }}</div> -->
                <div class="label-text">
                  {{ $t("order.trade_history.side") }}
                </div>
                <select-box
                  v-model="filters.tradeType"
                  :placeholder="$t('order.order_history.all')"
                  :options="sideOptions"
                />
              </div>
            </div>

            <div class="group-search">
              <button class="button-search" @click="actionSearch">
                {{ $t("common.search") }}
              </button>
              <button class="button-reset" @click="actionReset">
                {{ $t("button.reset") }}
              </button>
            </div>
          </div>

          <div class="export-file">
            <div class="excel_export">
              <a
                @click.prevent="downloadReport()"
                @mouseenter="mouseOver()"
                @mouseleave="mouseOver()"
              >
                <!-- <span class="icon-export" /> -->
                <img class="icon-export" alt="" src="/images/icon/export.svg" />
                &nbsp;
                {{ $t("order.order_history.export_complete_trade_history") }}
              </a>
            </div>
          </div>
        </div>
        <div class="trade-history__mobile-body">
          <data-list-mobile
            ref="datatable"
            :msg-empty-data="$t('order.trade_history.no_data')"
            :get-data="getData"
            :limit="10"
            :height="30"
          >
            <template slot="table-empty-header">
              <thead>
                <tr>
                  <th
                    v-for="(item, index) in headerTable"
                    :key="item.id"
                    :title="$t(item.name)"
                    :class="[$t(item.name), `cl-${index + 1}`]"
                    :style="
                      !isUserIndoAndKyc && item.id === 'tax'
                        ? 'display: none'
                        : ''
                    "
                  >
                    <i
                      v-if="index === headerTable.length - 1"
                      class="group-sort-tabl3"
                    ></i>
                    <span>{{ $t(item.name) }}</span>
                  </th>
                </tr>
              </thead>
            </template>
            <template slot="body" slot-scope="props">
              <div class="trade-history__list-item">
                <div class="trade-history__item-title">
                  <div class="item-title__wrapper">
                    <div
                      v-if="!hiddenLogoCrypto"
                      class="item-title__logo-wrapper"
                    >
                      <img
                        class="coin"
                        width="20"
                        height="20"
                        :src="
                          STORAGE_URL + '/icons/' + props.item.coin + '.png'
                        "
                      />
                      <img
                        class="currency"
                        width="20"
                        height="20"
                        :src="
                          STORAGE_URL + '/icons/' + props.item.currency + '.png'
                        "
                      />
                    </div>
                    <div>
                      {{ props.item.coin | uppercase }}/{{
                        props.item.currency | uppercase
                      }}
                    </div>
                    <div
                      v-if="!hiddenLogoCrypto"
                      :class="{
                        blue: props.item.trade_type === 'buy',
                        red: props.item.trade_type === 'sell',
                      }"
                    >
                      {{
                        props.item.trade_type === "buy"
                          ? $t("common.trade_type.buy")
                          : $t("common.trade_type.sell")
                      }}
                    </div>
                    <div class="item-title__date">
                      {{ props.item.created_at | timestampToDate }}
                    </div>
                  </div>
                </div>
                <div class="trade-history__item-desc">
                  <div class="item-desc__wrapper" v-if="hiddenLogoCrypto">
                    <div class="item-desc__value">
                      <div
                        :class="{
                          blue: props.item.trade_type === 'buy',
                          red: props.item.trade_type === 'sell',
                        }"
                      >
                        {{
                          props.item.trade_type === "buy"
                            ? $t("common.trade_type.buy")
                            : $t("common.trade_type.sell")
                        }}
                      </div>
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="type"
                      @click="props.onSort"
                    >
                      {{ $t("order.trade_history.side") }}
                      <i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      <span
                        v-if="
                          props.item.type == 'market' ||
                          props.item.type == 'stop_market'
                        "
                        >{{ $t("order.trade_history.market") }}</span
                      >
                      <span v-else>{{
                        props.item.price
                          | formatCurrencyAmount(props.item.currency, "0")
                      }}</span>
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="price"
                      @click="props.onSort"
                    >
                      {{ $t("order.trade_history.price")
                      }}<i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>

                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{
                        props.item.quantity
                          | toNumber
                          | formatCurrencyAmount(null, "0")
                      }}
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="quantity"
                      @click="props.onSort"
                    >
                      {{ $t("order.trade_history.filled")
                      }}<i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      <template v-if="props.item.trade_type === 'buy'">
                        {{
                          props.item.fee
                            | toNumber
                            | formatCurrencyAmount(props.item.coin, "0")
                        }}
                        {{ props.item.currency | uppercase }}
                      </template>
                      <template v-else
                        >{{
                          props.item.fee
                            | toNumber
                            | formatCurrencyAmount(props.item.currency, "0")
                        }}
                        {{ props.item.currency | uppercase }}</template
                      >
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="fee"
                      @click="props.onSort"
                    >
                      {{ $t("funds.history.fee")
                      }}<i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper" v-if="isUserIndoAndKyc">
                    <div class="item-desc__value">
                      <span
                        v-if="props.item.trade_type === 'buy'"
                        :title="
                          props.item.tax
                            | toNumber
                            | formatCurrencyAmount(props.item.coin, '0')
                        "
                      >
                        {{
                          props.item.tax
                            | toNumber
                            | formatCurrencyAmount(props.item.coin, "0")
                        }}
                        {{ props.item.currency | uppercase }}
                      </span>
                      <span
                        v-if="props.item.trade_type !== 'buy'"
                        :title="
                          props.item.tax
                            | toNumber
                            | formatCurrencyAmount(props.item.currency, '0')
                        "
                      >
                        {{
                          props.item.tax
                            | toNumber
                            | formatCurrencyAmount(props.item.currency, "0")
                        }}
                        {{ props.item.currency | uppercase }}
                      </span>
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="tax"
                      @click="props.onSort"
                    >
                      {{ $t("funds.history.tax")
                      }}<i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{
                        props.item.amount
                          | toNumber
                          | formatCurrencyAmount(null, "0")
                      }}
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="amount"
                      @click="props.onSort"
                    >
                      Total<i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </data-list-mobile>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="group-control" v-if="!options">
        <date-tab @change-select-date="changeDate" ref="selectDateTab" />
        <div class="group-date">
          <span class="title-date">{{ $t("funds.history.from") }}</span>
          <datepicker
            :language="datePickerLang"
            @selected="selectDateFrom"
            :value="dateFromDefault"
            class="date-from"
            :format="customFormatter"
          >
          </datepicker>
          <span class="title-date">{{ $t("funds.history.to") }}</span>
          <datepicker
            :language="datePickerLang"
            :value="dateToDefault"
            class="date-to"
            @selected="selectDateTo"
            :format="customFormatter"
          ></datepicker>
        </div>
        <button class="btn-search" @click="actionSearch">
          {{ $t("order.order_history.search") }}
        </button>
      </div>
      <div
        class="group-control"
        v-if="options"
        :class="{ is_single_page: options && options.isNoPadding }"
      >
        <div class="group_1">
          <div class="group-date-picker">
            <div class="group-date">
              <div class="label-text">{{ $t("order.order_history.from") }}</div>
              <datepicker
                :language="datePickerLang"
                :value="dateFromDefault"
                class="date-from"
                @selected="selectDateFrom"
                :format="customFormatter"
                input-class="date-picker-input"
              ></datepicker>
            </div>
            <div class="group-date">
              <div class="label-text">{{ $t("order.order_history.to") }}</div>
              <datepicker
                :language="datePickerLang"
                :value="dateToDefault"
                class="date-to"
                @selected="selectDateTo"
                :format="customFormatter"
                input-class="date-picker-input"
              ></datepicker>
            </div>
          </div>

          <div class="group-pair">
            <div class="content-search">
              <div class="label-text">{{ $t("order.order_history.pair") }}</div>
              <div class="toolbar">
                <div class="toolbar-element2">
                  <select-box
                    v-model="filters.coin"
                    :placeholder="$t('order.order_history.all')"
                    :options="getCoins()"
                  />
                </div>
                <span class="bw-element">/</span>
                <div class="toolbar-element2">
                  <select-box
                    v-model="filters.currency"
                    :placeholder="$t('order.order_history.all')"
                    :options="getMarkets()"
                  />
                </div>
              </div>
            </div>
            <div class="toolbar-element3">
              <!-- <div class="label-text">{{ $t("order.order_history.side") }}</div> -->
              <div class="label-text">Side</div>
              <select-box
                v-model="filters.tradeType"
                :placeholder="$t('order.order_history.all')"
                :options="sideOptions"
              />
            </div>
          </div>

          <div class="group-search">
            <button class="button-search" @click="actionSearch">
              {{ $t("common.search") }}
            </button>
            <button class="button-reset" @click="actionReset">
              {{ $t("button.reset") }}
            </button>
          </div>
        </div>

        <div class="export-file">
          <div class="excel_export">
            <a
              @click.prevent="downloadReport()"
              @mouseenter="mouseOver()"
              @mouseleave="mouseOver()"
            >
              <!-- <span class="icon-export" /> -->
              <img class="icon-export" alt="" src="/images/icon/export.svg" />
              &nbsp;
              {{ $t("order.order_history.export_complete_trade_history") }}
            </a>
          </div>
        </div>
      </div>
      <div class="wrap_table">
        <data-table3
          ref="datatable"
          :getData="getData"
          :msg-empty-data="$t('order.trade_history.no_data')"
          class="datatable"
          id="tbl-trade-history"
          :limit="10"
          :height="20"
        >
          <template slot-scope="props">
            <th
              v-for="(item, index) in headerTable"
              :key="item.id"
              :title="$t(item.name)"
              :data-sort-field="item.id"
              @click="props.echoclick"
              :class="[$t(item.name), `cl-${index + 1}`]"
              :style="
                !isUserIndoAndKyc && item.id === 'tax' ? 'display: none' : ''
              "
            >
              <i
                v-if="index === headerTable.length - 1"
                class="group-sort-tabl3"
                v-html="props.innerHtmlSort"
              ></i>
              <span>{{ $t(item.name) }}</span>
              <i
                v-if="index !== headerTable.length - 1"
                class="group-sort-tabl3"
                v-html="props.innerHtmlSort"
              ></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
          </template>
          <template slot="body" slot-scope="props">
            <tr>
              <td
                width="190px"
                class="cl_date cl-1"
                :title="props.item.created_at | timestampToDate"
              >
                {{ props.item.created_at | timestampToDate }}
              </td>
              <td
                class="cl-2"
                :title="
                  `${props.item.coin} / ${props.item.currency}` | uppercase
                "
              >
                <span v-if="!hiddenLogoCrypto">
                  <img
                    class="coin"
                    width="20"
                    height="20"
                    :src="STORAGE_URL + '/icons/' + props.item.coin + '.png'"
                  />
                  <img
                    class="currency"
                    width="20"
                    height="20"
                    :src="
                      STORAGE_URL + '/icons/' + props.item.currency + '.png'
                    "
                  />
                </span>
                <span>
                  {{ props.item.coin | uppercase }}/{{
                    props.item.currency | uppercase
                  }}
                </span>
              </td>
              <td
                :class="{
                  'cl-3': true,
                  blue: props.item.trade_type === 'buy',
                  red: props.item.trade_type === 'sell',
                }"
              >
                {{
                  props.item.trade_type === "buy"
                    ? $t("common.trade_type.buy")
                    : $t("common.trade_type.sell")
                }}
              </td>
              <td
                class="cl-4"
                :title="
                  props.item.price
                    | formatCurrencyAmount(props.item.currency, '0')
                "
              >
                <span
                  v-if="
                    props.item.type == 'market' ||
                    props.item.type == 'stop_market'
                  "
                  >{{ $t("order.trade_history.market") }}</span
                >
                <span v-else>{{
                  props.item.price
                    | formatCurrencyAmount(props.item.currency, "0")
                }}</span>
              </td>
              <td
                class="cl-5"
                :title="
                  props.item.quantity
                    | toNumber
                    | formatCurrencyAmount(null, '0')
                "
              >
                {{
                  props.item.quantity
                    | toNumber
                    | formatCurrencyAmount(null, "0")
                }}
              </td>

              <td
                class="cl-6 t-left"
                v-if="props.item.trade_type === 'buy'"
                :title="
                  props.item.fee
                    | toNumber
                    | formatCurrencyAmount(props.item.coin, '0')
                "
              >
                {{
                  props.item.fee
                    | toNumber
                    | formatCurrencyAmount(props.item.coin, "0")
                }}
                {{ props.item.currency | uppercase }}
              </td>
              <td
                class="cl-6 t-left"
                v-if="props.item.trade_type !== 'buy'"
                :title="
                  props.item.fee
                    | toNumber
                    | formatCurrencyAmount(props.item.currency, '0')
                "
              >
                {{
                  props.item.fee
                    | toNumber
                    | formatCurrencyAmount(props.item.currency, "0")
                }}
                {{ props.item.currency | uppercase }}
              </td>
              <template v-if="isUserIndoAndKyc">
                <td
                  class="cl-7 t-left"
                  v-if="props.item.trade_type === 'buy'"
                  :title="
                    props.item.tax
                      | toNumber
                      | formatCurrencyAmount(props.item.coin, '0')
                  "
                >
                  {{
                    props.item.tax
                      | toNumber
                      | formatCurrencyAmount(props.item.coin, "0")
                  }}
                  {{ props.item.currency | uppercase }}
                </td>
                <td
                  class="cl-7 t-left"
                  v-if="props.item.trade_type !== 'buy'"
                  :title="
                    props.item.tax
                      | toNumber
                      | formatCurrencyAmount(props.item.currency, '0')
                  "
                >
                  {{
                    props.item.tax
                      | toNumber
                      | formatCurrencyAmount(props.item.currency, "0")
                  }}
                  {{ props.item.currency | uppercase }}
                </td>
              </template>
              <td
                class="cl-8 t-left"
                :title="
                  props.item.amount | toNumber | formatCurrencyAmount(null, '0')
                "
              >
                {{
                  props.item.amount | toNumber | formatCurrencyAmount(null, "0")
                }}
              </td>
            </tr>
          </template>
        </data-table3>
      </div>
    </template>
  </div>
</template>

<script>
import { en, id } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import DateTab from "../common/DateTab.vue";
import OPEN_ORDER_HEADER_CONST from "./DataTableInit.js";
import COMMON_CONST from "@/common/Const";
import rf from "@/request/RequestFactory";
import moment from "moment";
import MasterdataUtils from "@/common/MasterdataUtils";
import Utils from "@/common/Utils";
import SelectBox from "@/components/shared_components/common/SelectBox";

export default {
  components: {
    DateTab,
    Datepicker,
    SelectBox,
  },
  props: {
    isSpotMobile: Boolean,
    options: {
      type: Object,
      default: null,
    },
    coinCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_COIN,
    },
    currencyCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_CURRENCY,
    },
    hiddenLogoCrypto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datePickerLang: this.$i18n.locale === "id" ? id : en,
      isHideOtherPair: false,
      headerTable: OPEN_ORDER_HEADER_CONST.TRADE_HISTORY_HEADER,
      sideOptions: [],
      commonConst: COMMON_CONST,
      dateFromDefault: moment().subtract(6, "days").toDate(),
      dateToDefault: moment().toDate(),
      dateFrom: moment().subtract(6, "days").startOf("day").format("x"),
      dateTo: moment().endOf("day").format("x"),
      disableFromDate: {
        from: new Date(),
      },
      disableToDate: {
        from: new Date(),
      },
      coins: [],
      coinMasters: [],
      isShowCoinOption: false,
      filters: {
        currency: "",
        tradeType: "",
      },
      durationDateFrom: null,
      isHide: false,
      isShowToolTip: false,
      coin: this.coinCode,
      currency: this.currencyCode,
      fetchingTradeHistory: false,
      markets: [],
      coinAMAL: COMMON_CONST.AML,
      kyc: {
        status: "",
        country: "",
      },
    };
  },
  computed: {
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === "storage_url")
        .value;
    },
    isUserIndoAndKyc() {
      return this.kyc.country === "Indonesia" && this.kyc.status === "verified";
    },
  },
  created() {
    rf.getRequest("UserRequest")
      .getUserKyc()
      .then((res) => {
        this.kyc.status = res.data.status;
        this.kyc.country = res.data.country;
      });
  },
  methods: {
    localizeType(type) {
      if (type == "limit") return this.$t("order.order_form.limit");
      if (type == "market") return this.$t("orders.open_order.market");
      if (type == "stop_market") return this.$t("order.order_form.stop_market");
      if (type == "stop_limit") return this.$t("order.order_form.stop_limit");
    },
    refreshTable() {
      this.$refs.datatable.refresh();
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        SpotOrderListUpdated: this.onOrderListUpdated,
      };
    },
    onSpotSelectedPairMarket(pair) {
      this.isHideOtherPair = false;
      this.coin = pair.coin;
      this.currency = pair.currency;
    },
    onOrderListUpdated(data, isHideOtherPair) {
      if (!this.fetchingTradeHistory) {
        this.fetchingTradeHistory = true;
        if (isHideOtherPair) {
          this.$refs.datatable.filter({
            coin: this.coin,
            currency: this.currency,
          });
        } else {
          this.$refs.datatable.refresh();
        }
      }
    },
    changeDate(currentDateTab) {
      switch (currentDateTab.id) {
        case this.commonConst.ONE_DAY:
          this.durationDateFrom = moment().startOf("day").format("x");
          break;
        case this.commonConst.ONE_WEEK:
          this.durationDateFrom = moment()
            .subtract(6, "days")
            .startOf("day")
            .format("x");
          break;
        case this.commonConst.ONE_MONTH:
          this.durationDateFrom = moment()
            .subtract(1, "months")
            .add("days", 1)
            .startOf("day")
            .format("x");
          break;
        default:
          this.durationDateFrom = moment()
            .subtract(3, "months")
            .add("days", 1)
            .startOf("day")
            .format("x");
      }
      const params = {
        start_date: this.durationDateFrom,
        end_date: moment().endOf("day").format("x"),
      };
      this.$refs.datatable.filter(params);
    },
    getData(params) {
      if (!isAuthenticated) {
        return new Promise((resolve) => {
          return resolve({});
        });
      }
      const dateRange = this.getDefaultDateRange();
      params = Object.assign({}, dateRange, params);
      return rf
        .getRequest("OrderRequest")
        .getTradeHistory(params)
        .then((res) => {
          this.fetchingTradeHistory = false;
          // return tradeHistory;
          return res;
        });
    },

    getDefaultDateRange() {
      return {
        start_date: this.dateFrom,
        end_date: moment().endOf("day").format("x"),
      };
    },
    showError(message) {
      Message.error(message, {}, { position: "top_right" });
    },
    actionSearch() {
      if (this.dateFrom > this.dateTo) {
        this.showError(this.$t("common.date.warning"));
        return;
      }
      this.$refs.datatable.filter(this.filterParams());
      if (!this.options) {
        this.$refs.selectDateTab.resetSelectDate();
      }
    },
    filterParams() {
      const filterParams = {
        start_date: this.dateFrom,
        end_date: this.dateTo,
      };
      if (this.filters.coin) {
        filterParams.coin =
          this.filters.coin.toLowerCase() === "all"
            ? undefined
            : this.filters.coin.toLowerCase();
      }
      if (this.filters.currency) {
        filterParams.currency =
          this.filters.currency.toLowerCase() === "all"
            ? undefined
            : this.filters.currency;
      }
      if (this.filters.tradeType) {
        filterParams.trade_type =
          this.filters.tradeType.toLowerCase() === "all"
            ? undefined
            : this.filters.tradeType;
      }
      return filterParams;
    },
    actionReset() {
      this.filters.coin = "";
      this.filters.currency = "";
      this.filters.tradeType = "";
      this.dateFromDefault = moment().subtract(6, "days").toDate();
      this.dateToDefault = moment().toDate();
      this.dateFrom = moment().subtract(6, "days").startOf("day").format("x");
      this.dateTo = moment().endOf("day").format("x");
      this.$refs.datatable.refresh();
      $(".datatable").find("th").attr("data-sort-order", "");
    },
    async selectDateFrom(dateFrom) {
      this.dateFrom = moment(dateFrom).startOf("day").format("x");
    },
    async selectDateTo(dateTo) {
      this.dateTo = moment(dateTo).endOf("day").format("x");
      if (dateTo) {
        this.disableToDate = {
          from: new Date(dateTo),
        };
      }
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    requestCoinList() {
      MasterdataUtils.getCoins((coins) => {
        this.coinMasters = coins;
        this.coinMasters.unshift(this.$t("order.order_history.all"));
      });
    },
    onSelectCoin(coin) {
      this.isShowCoinOption = false;
      this.filters.coin =
        coin.toLowerCase() === "all" ? "All" : coin.toUpperCase();
    },
    clickOut() {
      this.isShowCoinOption = false;
    },
    showCoinOption() {
      this.isShowCoinOption = true;
      this.coins = this.coinMasters;
    },
    async downloadReport() {
      const params = {
        ...this.filterParams(),
        timezone_offset: Utils.getTimzoneOffset(),
        lang: this.$i18n.locale,
      };
      rf.getRequest("OrderRequest").downloadTradeHistory(
        params,
        "TradeHistory_" + moment().format("YYYYMMDDHHmmss")
      );
    },
    mouseOver() {
      this.isShowToolTip = !this.isShowToolTip;
    },

    onSearchCoin() {
      this.coins = _.filter(this.coinMasters, (item) => {
        return _.isMatch(item.toUpperCase(), this.filters.coin.toUpperCase());
      });
    },
    getCoins() {
      let coins = [];
      coins = this.$store.state.masterdata.coins.map((coin) => {
        return {
          id: coin.coin,
          name: coin.coin.toUpperCase(),
        };
      });
      coins.unshift({
        id: "all",
        name: this.$t("order.order_history.all"),
      });
      return coins;
    },
    getMarkets() {
      let markets = [];
      markets = this.$store.state.masterdata.market_settings.map((market) => {
        return {
          id: market.currency,
          name: market.currency.toUpperCase(),
        };
      });
      markets.unshift({
        id: "all",
        name: this.$t("order.order_history.all"),
      });
      return markets;
    },
    initSideOptions() {
      this.sideOptions = this.commonConst.SELECT_SIDE.map((option) => {
        return {
          id: option.id,
          name: this.$t(option.name),
        };
      });
      this.sideOptions.unshift({
        id: "all",
        name: this.$t("order.order_history.all"),
      });
    },
  },
  mounted() {
    this.initSideOptions();
    this.$on("UPDATED_LOCALE", () => {
      this.initSideOptions();
    });
  },
};
</script>

<style lang="scss" scoped>
#SpotExchange_page
  #order-group-block
  #order-group
  #trade-history
  .trade-history__mobile-wrapper
  .group-control {
  #date-tab {
    margin-left: unset;
    :deep ul {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      li a {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
#trade-history {
  .is_single_page {
    :deep .vdp-datepicker input {
      height: 40px !important;
      letter-spacing: normal !important;
      background-position: top 12px right 16px !important;
    }
  }
  .group-date {
    min-width: 140px;
  }

  .trade-history__mobile-wrapper {
    padding: 8px 16px 16px;
    & .group-control {
      flex-wrap: nowrap;
      flex-direction: column;
      .group-date {
        justify-content: space-between;
        gap: 16px;
        .group-date__item {
          display: flex;
          flex-direction: column;
          gap: 4px;
          .date-title {
            color: $text-secondary;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
          }
          :deep .vdp-datepicker input {
            width: 100% !important;
          }
        }
      }
      .checkbox-function {
        display: flex;
        label {
          display: flex;
          margin: unset;
          margin-top: auto;
          position: relative;
          margin-bottom: auto;
          & input {
            margin: unset;
            width: 24px;
            height: 24px;
            border: 1px solid $bg-main2 !important;
            border-radius: 4px;
            &[type="checkbox"]:checked + div::before {
              content: "";
              display: block;
              position: absolute;
              text-align: center;
              height: 24px;
              width: 24px;
              left: 0;
              background-color: $bg-main1;
              border-radius: 4px;
            }
            &[type="checkbox"]:checked + div::after {
              content: url("@/assets/images/spot_exchange/checkbox-tick.svg");
              display: block;
              position: absolute;
              top: 2px;
              left: 2px;
            }
          }
          & div {
            color: $text-dark-1;
            margin-left: 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }
    }
    & .trade-history__mobile-body {
      margin-top: 32px;
      & .data-list__wrapper {
        overflow: auto;
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
      & .trade-history__list-item {
        padding: 16px;
        border-radius: 10px;
        background-color: $bg-main4;
        .trade-history__item-title {
          display: flex;
          justify-content: space-between;
          .item-title__wrapper {
            .item-title__logo-wrapper {
              img:nth-child(2) {
                margin-left: -8px;
              }
            }
            & > div {
              font-size: 14px;
              font-weight: 300;
              line-height: 24px;
            }
            display: flex;
            & > div:nth-child(2) {
              color: $text-main;
              width: 70px;
              margin-left: 6px;
            }
            & > div:nth-child(3) {
              margin-left: 4px;
            }
            & > div:nth-child(4) {
              margin-left: 11px;
            }
            & .item-title__date {
              margin-top: auto;
              margin-bottom: auto;
              font-size: 12px;
              font-style: normal;
              font-weight: 300;
              line-height: 16px;
              color: $text-main-blur;
            }
            & div.blue {
              color: $color-green;
              font-weight: 700;
            }
            & div.red {
              color: $color-red;
              font-weight: 700;
            }
          }
        }
        .trade-history__item-desc {
          margin-top: 16px;
          display: grid;
          gap: 8px;
          row-gap: 16px;
          grid-template-columns: repeat(3, 1fr);
          .item-desc__wrapper {
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            &:nth-child(3n + 3) {
              text-align: end;
            }
            .item-desc__title {
              margin-top: 3px;
              white-space: nowrap;
              font-size: 12px;
              font-style: normal;
              font-weight: 300;
              line-height: 16px;
              color: $text-main-blur;
            }
            .item-desc__value {
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              min-height: 22px;
              line-height: 22px;
              color: $text-main;
            }
          }
        }
      }
    }
  }
}

.cl-1 {
  min-width: 179px;
}
.cl-2 {
  min-width: 167px;
}
.cl-3 {
  min-width: 147px;
}
.cl-4 {
  min-width: 160px;
}
.cl-5 {
  min-width: 160px;
}
.cl-6 {
  min-width: 160px;
}
.cl-7 {
  min-width: 160px;
}
.cl-8 {
  min-width: 160px;
}
</style>

<style lang="scss">
#trade-history {
  .group-control {
    display: flex;
    margin-top: 8px;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 11px;
    .title-date {
      color: $text-secondary;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-top: auto;
      margin-bottom: auto;
    }
    .btn-search {
      display: flex;
      padding: 7px 24px;
      height: 38px;
      border-radius: 60px;
      background: $bg-main1;
      color: $color-white;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
    }
    .group_1 {
      display: flex;
      gap: 15px;

      // Datepickers
      .group-date-picker {
        display: flex;
        gap: 15px;

        .label-text {
          @include text-font($helvetica, $text-secondary, 14px, 400, 24px);
          margin-bottom: 7px;
        }
        .date-picker-input {
          border-radius: 5px;
          height: 38px !important;
          font-weight: 300;
          border: none;
          padding: 7px 50px 7px 16px !important;
          background-position: top 7px right 16px !important;
          background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEOSURBVHgB7ZWxFYIwEIYvamHpBsbOESyBSRxBO63Uzk5HcBLASYwbuAH+5wNeQCB5QdDC772Q5HK55C4hR/QtPM+7cWmrN6obEEJIssCkJ6i4mx0mrNCckBuPJEkucRyvM8FQM36C8Q2aY3JnDBsLKSUppa4syEOEgWVayzAM777vJ9yPokg0WdT1sEmJ+bc0CgeWDzTdV1jYeNpXaTGR6yE0SrdV8KAMdjQjC0x6A+qYNw+ymH6K/j0w3RoT5Qh07kHtAlVvjK1Mp+ktcpZZLVB1v21lOv8z+K0zePAnCIIpOcLPtW6LyT1AJjrD3T1qhb+R2sBZLWvnGY0zEDLRBIvMyT2rcco8Ii9sqS+e01R1Zs58D0cAAAAASUVORK5CYII=") !important;
          color: $text-main;
          line-height: 22px;
          background-size: 24px 24px;
        }
      }

      // Pair & Side dropdowns
      .group-pair {
        display: flex;
        gap: 15px;

        .label-text {
          @include text-font($helvetica, $text-secondary, 14px, 400, 24px);
          margin-bottom: 7px;
        }
        .sc_search_select .group_search_select .box_list_search_select {
          min-width: 100px;
          @include mobile {
            min-width: 90px;
          }

          width: 100%;
        }
        // Pair
        .content-search {
          .toolbar {
            display: flex;
            align-items: center;
            gap: 7px;
            .bw-element {
              font-size: 14px;
              font-weight: 300;
              line-height: 22px;
              color: $text-main;
            }
          }
          // Left coin
          .toolbar-element {
            .coin-input {
              position: relative;
              input {
                padding: 0 10px;
                width: 82px;
                height: 32px;
                border: 1px solid $grey-1;
                border-radius: 10px;
              }
              .box-coin-option {
                position: absolute;
                top: calc(100% + 2px);
                width: 100%;
                left: 0;
                z-index: 2;

                .coin-option {
                  display: flex;
                  flex-direction: column;
                  border-radius: 3px;
                  list-style-type: none;
                  padding: 10px 0px;
                  box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
                  background: $color-white;

                  border-radius: 10px;
                  .option {
                    padding: 7px 14px;
                    cursor: pointer;
                    @include text-font($helvetica, $dark-1, 14px, 500, 20px);
                    &:hover {
                      background: $grey-3;
                    }
                  }
                }
              }
            }
          }
          // Right coin
          .toolbar-element2 {
            .group_search_select {
              .button_drop_search {
                min-width: 100px;
                background-color: $color-white;
                padding: 7px 16px;
                height: 38px;
                border-radius: 5px;
                border: none;
                & span,
                i {
                  font-size: 14px;
                  font-weight: 300;
                  line-height: 22px;
                }
                span {
                  font-size: 14px;
                  &.isPlaceholder {
                    color: $text-main;
                  }
                }
              }
              .box_list_search_select {
                span.full_name_coin_select {
                  color: $text-main !important;
                  font-family: $helvetica;
                  font-size: 14px;
                  font-weight: 300;
                  line-height: 22px;
                }
              }
            }
          }
        }
        // Side
        .toolbar-element3 {
          .group_search_select {
            .button_drop_search {
              padding: 7px 16px;
              width: 140px;
              min-width: 100px;
              height: 38px;
              background-color: $color-white;
              border-radius: 5px;
              border: none;
              & span,
              i {
                font-weight: 300;
                line-height: 22px;
              }
              span {
                font-size: 14px;
                color: $text-main;
                // &.isPlaceholder {
                //   color: $text-main;
                // }
              }
            }
            .box_list_search_select {
              span.full_name_coin_select {
                color: $text-main !important;
                font-family: $helvetica;
                font-size: 14px;
                font-weight: 300;
                line-height: 22px;
              }
            }
          }
          .label-text {
            @include text-font($helvetica, $text-secondary, 14px, 400, 24px);
            margin-bottom: 7px;
          }
        }
      }

      // Search * Reset buttons
      .group-search {
        display: flex;
        align-items: flex-end;
        gap: 15px;

        .button-search,
        .button-reset {
          padding: 7px 24px;
          border: none;
          border-radius: 60px;
          font-size: 14px;
          color: $text-main;
          font-weight: 700;
          line-height: 24px;
        }
        .button-search {
          background-color: $bg-main1;
          color: $text-white;
          // &:hover {
          //   background-color: $primary-1;
          // }
        }
        .button-reset {
          padding: 6px 24px;
          border: 1px solid $bg-main1;
          background-color: transparent;
          // &:hover {
          //   color: $color-white;
          //   background-color: $primary-1;
          // }
        }
      }
    }

    // Export file
    .export-file {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      white-space: nowrap;

      .excel_export {
        position: relative;
        margin-top: auto;
        font-size: 16px;
      }
      a {
        display: flex;
        @include text-font($helvetica, $text-dark-1, 16px, 300, 24px);
      }
      .export-file-tooltip {
        position: absolute;
        padding: 6px 8px;
        top: -50px;
        right: 0;
        @include text-font($helvetica, $color-white, 14px, 400, 25px);
        white-space: nowrap;
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 2px;

        &::before {
          content: "";
          position: absolute;
          bottom: -4px;
          right: 42px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid rgba(0, 0, 0, 0.75);
        }

        @include tablet-d {
          left: 0;
          right: auto;
          &::before {
            left: 42px;
            right: auto;
          }
        }
      }
    }
  }

  .wrap_table {
    min-height: 270px;
    border-radius: 10px;
    background-color: $color-white;
    overflow-x: auto;

    .empty-data {
      .empty-noti {
        min-height: auto !important;
      }
      .text-wrapper {
        gap: 0;
      }
      td {
        padding-top: 90px !important;
        padding-bottom: 113px !important;
        border-bottom: 0 !important;
      }
    }

    .tableContainer table thead {
      background-color: $bg-main4;
      th {
        padding: 8px 20px;
        @include text-font($helvetica, $text-secondary, 14px, 700, 24px);

        &:last-child {
          text-align: right;
        }

        // &:hover {
        //   color: $color-orange;
        // }
      }
    }
    #tbl-trade-history {
      // padding: 10px 21px;
      min-width: fit-content !important;
      max-width: 100% !important;
      min-height: 270px;

      th,
      td {
        font-family: $helvetica;
      }

      thead tr th {
        border: unset;
        padding: 8px 20px;
        white-space: nowrap;

        &:first-child {
          padding-left: 22px;
        }
        &:last-child {
          padding-right: 22px;
        }
      }

      tbody {
        td {
          vertical-align: bottom;
          padding: 12px 20px;
          white-space: nowrap;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          border-bottom: 1px solid $bg-main2;
          color: $text-main;
          &:first-child {
            padding-left: 22px;
          }
          &:last-child {
            padding-right: 22px;
          }
          &:nth-child(2) {
            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin-top: -5px;
              &.currency {
                margin-left: -5px;
              }
            }
          }
          &.blue,
          &.red {
            font-weight: 700;
          }
          &.blue {
            color: $color-green;
          }
          &.red {
            color: $color-red;
          }
        }
      }
    }
  }
}

@include tablet-d {
  #trade-history {
    min-height: 260px;
    .group-control {
      flex-wrap: wrap;
      margin-bottom: 15px;

      .group_1 {
        flex-wrap: wrap;
        width: 100%;

        // Datepickers
        .group-date-picker {
          width: 100%;
        }

        // Pair & Side dropdowns
        .group-pair,
        .group-type {
          // Pair
          .content-search {
            gap: 10px;
          }
        }
      }

      // Export file
      .export-file {
        justify-content: flex-start;

        .export-file-tooltip {
          left: 0;
          right: auto;
          &::before {
            left: 42px;
            right: auto;
          }
        }
      }
    }
  }

  #tbl-trade-history {
    width: max-content !important;
    .text-right {
      // position: sticky;
      // left: 20px;
      // width: fit-content;

      .VuePagination {
        text-align: right;
      }
    }
  }
}

@include mobile {
  #trade-history {
    .group-control {
      .group_1 {
        // Datepickers
        .group-date-picker {
          .group-date {
            flex: 1;

            .vdp-datepicker.date-to .vdp-datepicker__calendar {
              right: 0%;
              left: auto;
              &::before {
                right: 5%;
                left: auto;
              }
            }
          }

          .vdp-datepicker input {
            width: 100% !important;
          }
        }

        // Search * Reset buttons
        .group-search {
          gap: 10px;
        }
      }
    }
  }
}

@include mobile-small {
  #trade-history {
    .group-control {
      .group_1 {
        // Datepickers
        .group-date-picker {
          flex-wrap: wrap;

          .group-date {
            flex: unset;
            width: 100%;

            .vdp-datepicker.date-to .vdp-datepicker__calendar {
              right: auto;
              left: 0%;
              &::before {
                right: auto;
                left: 5%;
              }
            }
          }
        }
        // Pair & Side dropdowns
        .group-pair {
          flex-wrap: wrap;
          width: 100%;

          .content-search,
          .toolbar-element3 {
            width: 100%;
          }

          .content-search {
            .toolbar {
              .toolbar-element,
              .toolbar-element2 {
                flex: 1;
              }
              .coin-input input {
                width: 100%;
              }
            }
          }

          .button_drop_search {
            width: 100% !important;
          }
        }

        // Search * Reset buttons
        .group-search {
          flex-wrap: wrap;
          width: 100%;
          .button-search,
          .button-reset {
            flex: 1;
          }
        }
      }

      // Export file
      .export-file {
        white-space: normal;
        .export-file-tooltip {
          white-space: normal;
        }
      }
    }
  }
}
</style>
