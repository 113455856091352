export default {
  saveItem(key, value) {
    try {
      const zipData = JSON.stringify(value);
      window.localStorage.setItem(key, zipData);
    } catch (err) {
      window.app.$broadcast("EVENT_COMMON_ERROR", err);
    }
  },

  getItem(key) {
    const zipData = window.localStorage.getItem(key);
    if (!zipData) {
      return zipData;
    }
    return JSON.parse(zipData);
  },

  removeItem(key) {
    window.localStorage.removeItem(key);
  },
};
