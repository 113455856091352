<template>
  <div id="SpotExchange_page">
    <div
      id="home"
      class="page_container full-page"
      :class="{ 'has-auth': isAuthenticated, unAuth: !isAuthenticated }"
    >
      <div class="page-container-right">
        <div class="order-trade-block">
          <OrderTradeForm
            :latestPrices="latestPrice"
            @refreshOderGroup="refreshOderGroup"
            :responsePairCoinSettings="responsePairCoinSettings"
          />
        </div>
      </div>
      <div class="page-container-left">
        <ExchangeHeader :latestPrices="latestPrice" class="coin_info_exchange clearfix" />
        <div class="chart-block">
          <ChartIndex :responsePairCoinSettings="responsePairCoinSettings" />
        </div>
      </div>

      <div class="page-container-mid">
        <div class="order-book-block">
          <OrderBook
            :latestPrices="latestPrice"
            :responsePairCoinSettings="responsePairCoinSettings"
          />
        </div>
      </div>
    </div>
    <div id="order-group-block" v-if="this.isAuthenticated">
      <OrderGroup ref="OrderGroup" :windowWidth="windowWidth" />
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import Const from '@/common/Const';
import OrderBook from './OrderBook.vue';
import RecentTrades from './RecentTrades.vue';
import OrderTradeForm from './OrderTradeForm.vue';
import OrderGroup from '../../order/OrderGroup.vue';
import ChartIndex from './chart/ChartIndex.vue';
import ExchangeHeader from './Header';
import ExchangeFooter from '../../../salespoint_pages/exchange/basic/ExchangeFooter.vue';
import rf from '@/request/RequestFactory';

export default {
  components: {
    OrderTradeForm,
    OrderBook,
    RecentTrades,
    OrderGroup,
    ChartIndex,
    ExchangeHeader,
    ExchangeFooter,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      coin: this.$route.query.coin || Const.DEFAULT_COIN,
      currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
      isAuthenticated: window.isAuthenticated,
      config: {
        displayAction: 'thead',
        showHideOtherPairsCheckbox: true,
      },
      Trade_popup: false,
      latestPrice: {},
      responsePairCoinSettings: {},
    };
  },
  computed: {
    dateNow() {
      return Date.now();
    },
  },
  watch: {
    $route(to, from) {
      this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
      this.coin = this.$route.query.coin || Const.DEFAULT_COIN;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.getPrices();
    this.getPairCoinSettings();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    document.title = this.$t('menu.exchange') + ` – ${this.tabTitle}`;
    if (localStorage && localStorage.spotEx_coin && localStorage.spotEx_currency) {
      const pair = {
        currency: localStorage.spotEx_currency,
        coin: localStorage.spotEx_coin,
        zone: localStorage.spotEx_zone || 0,
      };
      setTimeout(() => {
        this.$broadcast('SpotSelectedPairMarket', pair);
      }, 1500);
    }
    this.Trade_popup = window.localStorage.getItem('Trade_popup') ? false : true;
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    closePop() {
      window.localStorage.setItem('Trade_popup', false);
      this.Trade_popup = false;
    },
    popClick(flag) {
      if (flag) {
        this.closePop();
      }
    },
    refreshOderGroup() {
      this.$refs.OrderGroup.refresh();
    },
    getPrices() {
      rf.getRequest('PriceRequest')
        .getPrices()
        .then((res) => {
          this.latestPrice = res.data;
        });
    },
    getPairCoinSettings() {
      let coin = this.$route.query.coin || Const.DEFAULT_COIN;
      let currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;

      rf.getRequest('SettingRequest')
        .getPairCoinSettings({ coin, currency })
        .then((res) => {
          if (!res && !res.data) {
            return;
          }
          this.responsePairCoinSettings = res.data;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.group-date) {
  display: flex;
  gap: 10px;
}
</style>

<style lang="scss">
@import '@/assets/sass/common.scss';

.page-container {
  overflow-x: auto;
}
#home {
  .VuePagination {
    border-top: 1px solid $grey-2;
    margin-top: 20px;
    ul {
      margin: 10px 0 0 0 !important;
    }
  }
}

#SpotExchange_page {
  #order-group-block {
    #order-group {
      // border: solid 1px $grey-2;
      border-radius: 10px;
      overflow: hidden;
      #trade-history,
      #open-history {
        thead tr th {
          border-bottom: unset;
        }
        .group-control {
          display: flex;
          gap: 16px;
          .button-search {
            padding: 7px 24px;
            // border: 1px solid $bg-main1;
            border-radius: 60px;
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            background-color: $bg-main1;
            color: $text-white;
            height: auto;
            // @include btn-blue(nor, 88px, 32px, 14px, 18px);
          }
          #date-tab {
            margin-left: 20px;
            ul {
              li {
                a {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  padding: 8px 24px;
                  gap: 10px;
                  border: unset;
                  height: auto;
                  width: fit-content;
                  min-width: 70px;
                  background: $color-white;
                  border-radius: 60px;
                  height: 38px;
                  @include text-font($helvetica, $text-main, $font-root, 700, 24px);
                  &.active {
                    background: $bg-main1;
                    color: $color-white;
                  }
                }
              }
            }
          }
          .group-date {
            font-size: 14px;
            line-height: 30px;
            color: $dark-1;
            .date-title {
              color: $text-secondary;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 24px;
              margin-top: auto;
              margin-bottom: auto;
            }
            .vdp-datepicker {
              background-color: transparent;
              input {
                width: 135px;
                background-color: #f8f8f8 !important;
                background-image: url('@/assets/images/spot_exchange/calendar-icon.svg') !important;
                background-size: 24px 24px;
                background-repeat: no-repeat;
                font-size: 14px;
                color: $text-main;
                padding-top: 1px;
                border-radius: 5px;
                border: none;
                padding: 7px 50px 7px 16px !important;
                height: 38px !important;
                background-position: top 7px right 16px !important;
              }
            }
          }
        }
      }
      #trade-history {
        #order-table {
          table {
            thead {
              th {
                &:last-child {
                  span {
                    float: right;
                  }
                }
              }
            }
          }
        }
      }
      #balance.page_container {
        .balance-container {
          .table-container {
            background-color: transparent;
            th {
              color: $color-grey-dusty;
              font-family: $font-family-roboto-medium;
              border: none;
              border-bottom: 0;
              cursor: pointer;
              font-weight: normal;
              padding: 8px 0px 8px 20px;
              line-height: 20px;
              font-size: $font-small;
              height: 48px;
              &:last-child {
                padding-right: 20px;
                text-align: right !important;
                span {
                  float: right;
                }
              }
            }
            tbody {
              tr {
                td {
                  color: $color-gray-dark;
                  line-height: 20px;
                  padding: 10px 0px 10px 20px;
                  &.table_drop_balances {
                    padding: 15px 0px 16px 20px;
                  }
                  .btn-mam {
                    top: 14px;
                  }
                  .btn-transfer {
                    top: 8px;
                  }
                  &:last-child {
                    padding-right: 20px;
                    text-align: right !important;
                  }
                }
                &:hover {
                  background-color: transparent;
                }
                &.empty-data {
                  &:hover {
                    background-color: transparent !important;
                  }
                  td {
                    &:last-child {
                      text-align: center !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
      #sub-accounts {
        #order-table {
          th {
            color: $color-grey-dusty;
            font-family: $font-family-roboto-medium;
            border: none;
            border-bottom: 0;
            cursor: pointer;
            font-weight: normal;
            padding: 8px 0px 8px 0px;
            line-height: 20px;
            font-size: $font-small;
            height: 48px;
            &:last-child {
              padding-right: 20px;
              text-align: right !important;
            }
          }
        }
      }
      @include mobile {
        overflow: auto;
      }
      @include tablet {
        overflow: auto;
      }
    }
  }
}
#SpotExchange_page {
  #order-group-block {
    .VuePagination {
      background-color: transparent;
      @include mobile() {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/sass/common.scss';

$spaceMargin: 5px;
$widthOrderBook: 20%;
$widthOrderForm: 18%;
$minHeightContent: 550px;
$headerHeight: 74px;

.full-page {
  display: inline-block;
  // min-width: 1250px;
  // height: calc(100vh - 50px);
  box-sizing: border-box;
}
.clearfix-20 {
  display: block;
  width: 100%;
  height: 20px;
}
#SpotExchange_page {
  background-color: $color-white;
  // min-width: 1200px;

  @include mobile {
    min-width: unset;
    width: 100%;

    #home {
      height: auto;
      min-height: unset;
      display: flex;
      flex-direction: column;
      min-width: unset;
      width: 100%;

      .page-container-left {
        width: 100%;
        height: 100%;

        .coin_info_exchange {
          padding: 16px;
          height: auto;
          border-top: unset;
          gap: 16px;
          background-color: $color-white;
          &::after,
          &::before {
            content: none;
          }
          :deep #top-info {
            margin-left: 0;
            & span.nb_basic_tn {
              font-weight: 300;
              font-size: 12px;
            }
          }
        }

        // :deep .exchange-header {
        //   margin-bottom: 20px;
        //   width: 100%;
        //   max-width: 100%;
        //   background-color: transparent;
        // }
        // :deep .exchange-market {
        //   width: 160px;
        //   background-color: $primary-2;
        //   border-radius: 5px;
        // }
        :deep() {
          #markets-dark {
            width: 100%;

            .box-table {
              overflow: auto;
              width: 100%;
              .table-content {
                // min-width: 500px;
              }
            }
            #container-tab {
              // min-width: 500px;
            }
          }
        }

        .chart-block {
          :deep .chart-content {
            height: 500px;
          }
          .chart-option {
            background-color: $bg-main2;
          }
        }
      }

      .page-container-mid,
      .page-container-right {
        width: 100%;
      }

      .page-container-mid {
        border: unset;
      }

      .page-container-right {
        order: 1;
      }
    }

    #order-group-block {
      margin-top: 4px;
      min-width: unset;
      width: 100%;
      padding: 0;

      :deep .order-content {
        min-width: 1200px;

        table {
          td {
            padding: 12px 4px;
          }
        }
      }
    }
  }
  @include tablet {
    min-width: unset;
    width: 100%;
    padding: 21px 23px;

    #home {
      height: auto;
      min-height: unset;
      display: flex;
      flex-wrap: wrap;
      min-width: unset;
      width: 100%;
      gap: 20px;

      .page-container-left {
        width: 100%;
        height: 100%;

        .coin_info_exchange {
          height: auto;
          padding: 0;
          border-top: unset;
          margin-bottom: 20px;
          :deep #top-info {
            margin-left: 0;
          }
        }

        // :deep .exchange-header {
        //   margin-bottom: 20px;
        //   width: 100%;
        //   max-width: 100%;
        //   background-color: transparent;
        // }
        // :deep .exchange-market {
        //   width: 160px;
        //   background-color: $primary-2;
        //   border-radius: 5px;
        // }
        :deep() {
          #markets-dark {
            width: calc(100vw - 80px);

            .box-table {
              overflow: auto;
              width: 100%;
              .table-content {
                min-width: 500px;
              }
            }
            #container-tab {
              min-width: 500px;
            }
          }
        }

        .chart-block {
          :deep .chart-content {
            height: 500px;
          }
        }
      }

      .page-container-mid,
      .page-container-right {
        width: 100%;
      }

      .page-container-right {
        order: 1;
      }

      .page-container-mid {
        border: unset;
      }
    }

    #order-group-block {
      min-width: unset;
      width: 100%;
      padding: 0;
      margin-top: 4px;

      :deep .order-content {
        min-width: 1200px;

        table {
          td {
            padding: 12px 4px;
          }
        }
      }
    }
  }
}
#home {
  margin-bottom: -5px;
  height: 700px;
  &.unAuth {
    height: calc(100vh - 60px - 379px);
    overflow: hidden;
  }
  min-height: 550px;
  // border-bottom: 1px solid $grey-2;
  .page-container-left {
    display: block;
    float: left;
    height: 100%;
    width: calc(100% - #{$widthOrderBook} - #{$widthOrderForm});
    @media (max-width: 1440px) {
      width: calc(100% - 260px - 292px);
    }
    .coin_info_exchange {
      height: 56px;
      padding: 8px 16px;
      background: $bg-main4;
      border-top: 1px solid $grey-2;
    }
    .chart-block {
      width: 100%;
      height: calc(100% - 54px);
    }
  }
  .page-container-mid {
    display: block;
    float: left;
    height: 100%;
    width: #{$widthOrderBook};
    @media (max-width: 1440px) {
      width: 292px;
    }
    background-color: $color-light-grey;
    // border-left: 1px solid $grey-2;
    // border-right: 1px solid $grey-2;
    .order-book-block {
      width: 100%;
      height: 100%;
    }
  }
  .page-container-right {
    display: block;
    float: left;
    height: 100%;
    width: #{$widthOrderForm};
    @media (max-width: 1440px) {
      width: 260px;
    }
    .order-trade-block {
      width: 100%;
      height: 100%;
    }
  }
}
#order-group-block {
  padding-top: 8px;
  width: 100%;
}

#SpotExchange_page {
  position: relative;
}
.Trade_popup {
  position: absolute;
  top: -50px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  width: 100vw;
  height: calc(100% + 50px);
  cursor: pointer;
  .pop_con {
    width: 450px;
    height: 375px;
    position: fixed;
    left: 50%;
    top: 50%;
    cursor: default;
    transform: translate(-50%, -50%);
    .conimg {
      width: 100%;
      height: 100%;
    }
    .closeimg {
      width: 20px;
      height: 20px;
      position: absolute;
      right: -20px;
      top: -20px;
      cursor: pointer;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
