<template>
  <div
    class="transactions base_deposit_withdraw page-content"
    id="id_base_deposit_wd"
  >
    <div class="title title-medium-small">
      <slot name="title" />
    </div>
    <div class="transactions-content">
      <div class="top-content">
        <div class="left-content">
          <div class="left-title">
            {{
              showOtpBox
                ? selectedCoin.shortNameCurrency +
                  ' ' +
                  $t('funds.balances.withdrawal')
                : $t('funds.withdrawals.select_coin')
            }}
          </div>
          <div class="transaction-left-box">
            <div class="group-switch" v-show="!showOtpBox">
              <coin-select-box
                v-model="selectedCoin"
                :placeholder="
                  $t('funds.withdrawals.select_coin', {
                    action: $t(`funds.action.${configs.type}`),
                  })
                "
                :options="coins"
              ></coin-select-box>
            </div>
            <slot name="deposit-withdraw-header" v-if="isActive">
              <template>
                <div class="list-balance">
                  <div class="content-item-balance">
                    <div class="item-balance">
                      <span class="f-left">{{
                        $t('funds.withdrawals.total_balance')
                      }}</span>
                      <span class="f-right">
                        {{
                          balance.balance
                            | toNumber
                            | formatCurrencyAmount(selectedCoin.coin, '0')
                        }}
                        <span class="name_coin_dps">{{
                          selectedCoin.shortNameCurrency
                        }}</span>
                      </span>
                    </div>
                    <div class="text-center" v-if="!isShowDepositDetails && configs.type == 'deposit'">
                      <button 
                        @click="isShowDepositDetails = true"
                        style="background-color: #f8f8f8; margin: 8px 7px 0 0;"
                      >{{ $t('funds.balances.show_details') }}</button>
                      <img src="/images/arrow-down-lite.svg" alt="">
                    </div>
                  </div>
                  <div class="content-item-balance" v-if="isShowDepositDetails || configs.type == 'withdraw'">
                    <div class="item-balance">
                      <span class="f-left tooltip-container"
                        >{{ $t('funds.balances.locked_balance') }}
                        <div class="item group-tooltip">
                          <span class="help-box">
                            <img src="/images/icon/Question.png" alt="" />
                            <div class="box-tooltip">
                              <div class="bubble top locked-balance-text">
                                {{ $t('funds.balances.locked_balance_desc') }}
                              </div>
                            </div>
                          </span>
                        </div>
                      </span>
                    </div>
                    <div class="text-center" v-if="!isShowWithdrawDetails && configs.type == 'withdraw'">
                      <button 
                        @click="isShowWithdrawDetails = true"
                        style="background-color: #f8f8f8; margin: 8px 7px 0 0;"
                      >{{ $t('funds.balances.show_details') }}</button>
                      <img src="/images/arrow-down-lite.svg" alt="">
                    </div>
                    <div v-if="isShowWithdrawDetails || isShowDepositDetails">
                      <div class="item-balance" style="margin: 8px 0;">
                        <span class="f-left tooltip-container"
                          >{{ $t('funds.balances.in_transaction') }}
                          <div class="item group-tooltip">
                            <!-- <span class="help-box">
                              <img src="/images/icon/Question.png" alt="" />
                              <div class="box-tooltip">
                                <div class="bubble top locked-balance-text">
                                  {{ $t('funds.balances.in_transaction_desc') }}
                                </div>
                              </div>
                            </span> -->
                          </div>
                        </span>
                        <span class="f-right">
                          {{
                            balance.in_order
                              | toNumber
                              | formatCurrencyAmount(selectedCoin.coin, '0')
                          }}
                          <span class="name_coin_dps">{{
                            selectedCoin.shortNameCurrency
                          }}</span>
                        </span>
                      </div>
                      <div class="item-balance" style="margin-bottom: 8px;">
                        <span class="f-left tooltip-container"
                          >{{ $t('funds.balances.pending_withdrawal') }}
                          <div class="item group-tooltip">
                            <!-- <span class="help-box">
                              <img src="/images/icon/Question.png" alt="" />
                              <div class="box-tooltip">
                                <div class="bubble top locked-balance-text">
                                  {{ $t('funds.balances.pending_withdrawal_desc') }}
                                </div>
                              </div>
                            </span> -->
                          </div>
                        </span>
                        <span class="f-right">
                          {{
                            balance.pending_withdrawal
                              | toNumber
                              | formatCurrencyAmount(selectedCoin.coin, '0')
                          }}
                          <span class="name_coin_dps">{{
                            selectedCoin.shortNameCurrency
                          }}</span>
                        </span>
                      </div>
                      <div class="item-balance">
                        <span class="f-left tooltip-container"
                          >{{ $t('funds.balances.tradable_balance') }}
                          <div class="item group-tooltip">
                            <!-- <span class="help-box">
                              <img src="/images/icon/Question.png" alt="" />
                              <div class="box-tooltip">
                                <div class="bubble top locked-balance-text">
                                  {{ $t('funds.balances.in_transaction_desc') }}
                                </div>
                              </div>
                            </span> -->
                          </div>
                        </span>
                        <span class="f-right">
                          {{
                            balance.tradable_balance
                              | toNumber
                              | formatCurrencyAmount(selectedCoin.coin, '0')
                          }}
                          <span class="name_coin_dps">{{
                            selectedCoin.shortNameCurrency
                          }}</span>
                        </span>
                      </div>
                      <hr style="border-top: 2.5px solid #ddd; margin: 8px 0;"/>
                      <div class="item-balance">
                        <span class="f-left tooltip-container"
                          >{{ $t('funds.balances.total_locked_balance') }}
                          <div class="item group-tooltip">
                            <!-- <span class="help-box">
                              <img src="/images/icon/Question.png" alt="" />
                              <div class="box-tooltip">
                                <div class="bubble top locked-balance-text">
                                  {{ $t('funds.balances.in_transaction_desc') }}
                                </div>
                              </div>
                            </span> -->
                          </div>
                        </span>
                        <span class="f-right">
                          {{
                            balance.total_locked_balance
                              | toNumber
                              | formatCurrencyAmount(selectedCoin.coin, '0')
                          }}
                          <span class="name_coin_dps">{{
                            selectedCoin.shortNameCurrency
                          }}</span>
                        </span>
                      </div>
                      <div class="text-center" v-if="isShowWithdrawDetails">
                        <button 
                          @click="isShowWithdrawDetails = false"
                          style="background-color: #f8f8f8; margin: 8px 7px 0 0;"
                        >{{ $t('funds.balances.hide_details') }}</button>
                        <img src="/images/arrow-up-lite.svg" alt="">
                      </div>
                    </div>
                  </div>
                  <div class="content-item-balance" v-if="isShowDepositDetails">
                    <div class="item-balance">
                      <span class="f-left">{{
                        $t('funds.withdrawals.available_balance')
                      }}</span>
                      <span class="f-right">
                        {{
                          balance.available_balance
                            | toNumber
                            | formatCurrencyAmount(selectedCoin.coin, '0')
                        }}
                        <span class="name_coin_dps">{{
                          selectedCoin.shortNameCurrency
                        }}</span>
                      </span>
                    </div>
                  </div>
                  <div class="text-center" v-if="isShowDepositDetails">
                    <button 
                      @click="isShowDepositDetails = false"
                      style="background-color: #ffffff; margin-right: 7px;"
                    >{{ $t('funds.balances.hide_details') }}</button>
                    <img src="/images/arrow-up-lite.svg" alt="">
                  </div>
                </div>
              </template>
            </slot>
          </div>
          <template v-if="isActive && isDepositOrWithdraw && !isDisableDeposit">
            <slot name="actions" />
          </template>
          <div
            class="alert alert-danger text-center"
            role="alert"
            v-show="isActive && (!isDepositOrWithdraw || isDisableDeposit)"
          >
            {{ $t('funds.disable_coin_msg') }}
          </div>
        </div>
        <div class="right-content">
          <transaction-history
            :configs="configs"
            ref="transaction_history"
            :isUserIndo="isUserIndo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import TransactionHistory from '@/components/shared_components/funds/TransactionHistory.vue'
import CoinSelectBox from '@/components/shared_components/common/CoinSelectBox'

export default {
  inject: ['$validator'],
  components: {
    TransactionHistory,
    CoinSelectBox,
  },
  data() {
    return {
      coins: [],
      balance: {},
      selectedCoin: undefined,
      withdrawTransactions: [],
      minHeight: 430,

      isDepositOrWithdraw: false,
      isDisableDeposit: false, // Disable by User Withdrawal Setting
      userWidthdrawSettings: [],
      isShowDepositDetails: false,
      isShowWithdrawDetails: false,
    }
  },
  props: {
    configs: {
      type: Object,
      default: {},
    },
    showOtpBox: {
      type: Boolean,
      default: false,
    },
    isUserIndo: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route(to, from) {
      this.findSelectedCoin()
    },

    selectedCoin: function (newValue) {
      this.$broadcast(
        'selectedCoinChange',
        newValue && newValue.shortNameCurrency
      )
      if (window._.isEmpty(newValue)) {
        return
      }
      this.validateDepositWithdraw()
      this.getCurrencyBalance(newValue.coinName)

      this.getUserWithdrawalSetting().then((res) => {
        // Check Enable by User Withdrawal Setting
        if (this.configs.type !== 'withdraw') {
          return true
        }
        let coinName = newValue.coinName
        let record = window._.find(this.userWidthdrawSettings, (item) => {
          return item.coin == coinName
        })
        if (!record || record.enable_withdrawal == 'enable') {
          this.isDisableDeposit = false
        } else {
          this.isDisableDeposit = true
        }
      })
    },

    isDepositOrWithdraw(newState) {
      if (!newState) {
        return
      }
      this.$emit('CHANGE_SELECTED_COIN', this.selectedCoin)
    },
  },
  computed: {
    isActive() {
      return !window._.isEmpty(this.selectedCoin)
    },
  },
  methods: {
    updateTransactionSetting(data) {
      const result = data.find(
        (word) => word.coin === this.selectedCoin?.coinName
      )
      if (result) {
        if (result[this.getField()]) {
          this.isDepositOrWithdraw = true
        } else {
          this.isDepositOrWithdraw = false
        }
      }
    },
    onUserWithdrawalSettingEvent(data) {
      if (this.getField() == 'is_deposit') {
        return true
      }

      let res = JSON.parse(data)
      if (this.selectedCoin.coinName != res.coin) {
        return true
      }
      if (res.isDelete) {
        this.isDisableDeposit = false
      } else {
        if (res.enable_withdrawal == 'disable') {
          this.isDisableDeposit = true
        }
      }
    },

    getField() {
      if (this.configs.type === 'withdraw') {
        return 'is_withdraw'
      }

      return 'is_deposit'
    },

    findSelectedCoin() {
      const coinName = this.$route.query.coin || undefined
      if (coinName) {
        this.selectedCoin = _.find(this.coins, (coin) => {
          return coin.coinName === coinName
        })
      }
    },

    getCurrencyBalance(currency) {
      this.balance = {}
      rf.getRequest('BalanceRequest')
        .getBalanceTransactionMain(currency)
        .then((res) => {
          this.balance = res.data
          this.$emit('BALANCE_UPDATED', this.balance)
        })
    },

    getCoinList() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((res) => {
          let coinCodes = window._.flatMap(
            res.coins_confirmation,
            function (value) {
              return [value.coin]
            }
          )

          coinCodes = window._.uniq(coinCodes)

          let countCoins = coinCodes.length

          const coinNames = {}
          const coinImages = {}

          _.forEach(res.coins, (item, key) => {
            coinNames[item.coin] = item.name
            coinImages[item.coin] = item.icon_image
          })

          for (let i = 0; i < countCoins; i++) {
            let coinCode = coinCodes[i]
            let coinInfo = window._.findLast(
              res.coins,
              (item) => item.coin == coinCode
            )

            if (!coinInfo || coinCode == 'usd') {
              let coin = window._.findLast(
                res.coin_settings,
                (item) => item.coin == coinCode
              )
              if (!coin) {
                coin = {
                  coin: coinCode,
                  name: this.$i18n.t(`currency.${coinCode}.fullname`),
                }
              }
              if (!coin.name) {
                coin.name = this.$i18n.t(`currency.${coinCode}.fullname`)
              }

              coinInfo = coin
            } else {
              this.coins.push({
                coinName: coinCode,
                shortNameCurrency: coinCode.toUpperCase(),
                fullNameCurrency: coinInfo.name,
                iconCurrency: coinImages[coinCode],
                coinNetwork: coinInfo.network,
              })
            }
          }

          this.findSelectedCoin()
        })
    },

    onBalanceUpdated(newBalance) {
      if (
        !this.selectedCoin ||
        !newBalance ||
        this.selectedCoin.coinName !== newBalance.currency
      ) {
        return
      }

      this.balance = { ...newBalance }
      this.$emit('BALANCE_UPDATED', this.balance)
    },

    onTransactionCreated() {
      this.getCurrencyBalance(this.selectedCoin.coinName)
    },

    validateDepositWithdraw() {
      this.isDepositOrWithdraw = false
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((res) => {
          const setting = window._.filter(
            res.coins_confirmation,
            (item) => item.coin === this.selectedCoin.coinName
          )
          if (!window._.isEmpty(setting)) {
            this.isDepositOrWithdraw =
              this.configs.type === 'withdraw'
                ? setting[0].is_withdraw
                : setting[0].is_deposit
          }
        })
    },

    getSocketEventHandlers() {
      return {
        WithdrawDepositBalanceEvent: this.onBalanceUpdated,
        TransactionSettingEvent: this.updateTransactionSetting,
        UserWithdrawalSettingEvent: this.onUserWithdrawalSettingEvent,
        TransactionCreated: this.onTransactionCreated,
      }
    },

    getUserWithdrawalSetting() {
      return rf
        .getRequest('UserRequest')
        .getUserWithdrawalSetting()
        .then((res) => {
          this.userWidthdrawSettings = res.data
          return res
        })
    },
  },

  mounted() {
    this.getCoinList()
    const height = document.body.clientHeight - 373
    this.minHeight = height > 430 ? height : 430

    if (this.getField() == 'is_withdraw') {
      this.getUserWithdrawalSetting()
    }
  },
}
</script>

<style lang="scss">
#id_base_deposit_wd {
  margin: 0 auto;
  padding: 24px;
  max-width: 1370px;

  @include mobile {
    padding: 16px;
  }

  .multiselect__tags .multiselect__input {
    border: none !important;
  }

  .multiselect__input {
    height: 35px;
  }

  .multiselect__content-wrapper {
    overflow-x: hidden;
  }
}
</style>

<style lang="scss" scoped>
@import '@/assets/sass/common.scss';

.is-error {
  color: $color-green;
}

.f-left {
  float: left;
  &.tooltip-container {
    display: flex;
    column-gap: 5px;
  }
}

.f-right {
  float: right;
}

.txt-blue {
  color: $color-blue-cerulean-light;
}

.txt-bold {
  font-weight: bold;
}

.form-input {
  background: $background-catskill-white;
  border: 1px solid $color-alto;
}

.form-control {
  border-radius: 0;
}

.btn-submit {
  background: $color-blue-cerulean-light;
  color: $color-white;
  margin-top: 5px;
  margin-bottom: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;

  &:hover,
  &:active,
  &:focus {
    background: $color-corn;
    border-color: $color-corn;
    color: $color-white;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}

.transactions {
  min-height: 100%;
  height: auto;
  background: transparent;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include mobile {
    gap: 16px;
  }

  .title-medium-small {
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    color: $text-main;

    @include mobile {
      font-size: 18px;
      line-height: 32px;
    }
  }

  .transactions-content {
    background: transparent;

    .top-content {
      width: 100%;
      display: grid;
      grid-template-columns: 45% calc(55% - 24px);
      gap: 24px;

      @include tablet {
        display: flex;
        flex-direction: column;
      }

      @include mobile {
        display: flex;
        flex-direction: column;
      }

      .left-content {
        @include tablet {
          max-width: 100%;
          width: 100%;
          flex: 1;
        }

        @include mobile {
          max-width: 100%;
          width: 100%;
          flex: 1;
        }

        max-width: 600px;
        width: 100%;
        background: $color-white;
        padding: 24px 30px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        // gap: 15px;
        min-height: 520px;

        .left-title {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: $text-main;
          text-transform: capitalize;
          // @include mobile {
          //   font-size: 14px;
          //   line-height: 24px;
          //   font-weight: 300;
          // }
        }

        .transaction-left-box {
          $white: $color-white;
          $blueLight: $color-blue-cerulean-light;
          display: flex;
          flex-direction: column;
          gap: 16px;
          padding-top: 8px;

          .group-switch {
            :deep(.sc_search_select) {
              .button_drop_search {
                border-radius: 8px;
                border: 1px solid #ddd;
                background: white;

                .title-coin {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  color: $text-secondary;

                  @include mobile {
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 300;
                  }

                  .bold {
                    opacity: 0.6;
                  }

                  .code_coin_slect {
                    opacity: 1;
                  }

                  .full_name_coin_select {
                  }
                }
              }

              .input-group .form-input input {
                background-color: transparent;

                @include mobile {
                  font-size: 14px;
                }
              }
            }

            .icon-choice {
              width: 18px;
              margin-right: 15px;
            }

            .multiselect__tags {
              width: 535px;
              height: 35px;
              border: 1px solid $color-alto !important;
              border-radius: 2px;
            }

            .option__desc {
              display: inline-block;

              .multiselect__tags {
                width: 535px;
                height: 35px;
                border: 1px solid $color-alto !important;
                border-radius: 2px;
              }

              .title-choice {
                font-weight: bold;
              }
            }
          }
          
          .list-balance {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 8px;
            
            .content-item-balance {
                background-color: #f8f8f8;
                padding: 16px;
                border-radius: 7px;
            }
              
            .item-balance {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              color: $text-secondary;

              @include mobile {
                font-size: 14px;
              }

              .f-left {
                min-width: 30%;
                font-size: 16px;
                font-weight: 300;
                line-height: 24px;

                @include mobile {
                  font-size: 14px;
                  line-height: 22px;
                  font-weight: 300;
                }
              }

              .f-right {
                font-weight: 700;
                font-size: 16px;
                text-align: right;
                line-height: 24px;
                color: $text-main;
                opacity: 1;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                word-wrap: break-word;

                @include mobile {
                  font-size: 14px;
                  line-height: 22px;
                }
              }
            }
          }

          .group-button {
            .btn-wie {
              color: $color-white;
              background: $blueLight;
              border: none;
              box-shadow: none;
              width: 154px;
              height: 36px;
              text-align: left;
              font-size: $font-root;
              font-weight: bold;
              padding-left: 10px;
              padding-right: 10px;

              img {
                width: 18px;
                height: 18px;
              }
            }

            .speech-bubble {
              position: relative;
              outline: none;
            }

            .speech-bubble:after {
              content: '';
              position: absolute;
              right: 0;
              top: 50%;
              width: 0;
              height: 0;
              border: 18px solid transparent;
              border-left-color: $color-blue-cerulean-light;
              border-right: 0;
              margin-top: -18px;
              margin-right: -18px;
            }
          }
        }
      }

      .right-content {
        width: 100%;
        background: $color-white;
        border-radius: 10px;
        overflow: hidden;
        padding-bottom: 24px;

        @include tablet {
          width: 100%;
          flex: 1;
        }

        @include mobile {
          flex: 1;
          padding-bottom: 0;
          width: 100%;
        }
      }
    }
  }
}

#id_base_deposit_wd {
  :deep() .box_list_search_select {
    width: 100%;
  }
}

@include mobile {
  .transactions {
    .title-medium-small {
      font-size: 18px;
    }

    .transactions-content {
      .top-content {
        .left-content {
          padding: 24px 16px;

          .transaction-left-box {
            .list-balance {
              gap: 6px;

              .item-balance {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .deposit-content {
      padding-top: 16px;

      .address-box {
        .form-important {
          font-size: 12px;
        }
      }

      .btn-box {
        flex-direction: column-reverse;
        gap: 8px;

        .btn.box_left,
        .btn.box_right {
          width: 100%;
          height: 38px;
          font-size: 14px;
        }
      }
    }
  }

  .form-notice {
    li {
      font-size: 14px !important;
    }

    :deep() {
      .link-primary {
        font-size: 12px;
      }
    }
  }

  :deep() {
    .icon_coin_slect {
      margin: 0px;
    }

    .code_coin_slect {
      margin: 0px;

      @include mobile {
        font-size: 14px;
      }
    }
  }

  .group-tooltip {
    .box-tooltip {
      left: 0;
      top: calc(100% + 0.75rem);
      translate: none;

      &::before {
        left: 0.25rem;
        translate: none;
      }
    }
  }
}

@include mobile-small {
  .transactions
    .transactions-content
    .top-content
    .left-content
    .transaction-left-box
    .list-balance
    .item-balance {
    flex-direction: column;

    .f-right {
      text-align: left;
    }
  }
}

.group-tooltip {
  color: $color-red;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 5px;

  .help-box {
    position: relative;
    img {
      cursor: pointer;
    }
    + span {
      white-space: nowrap;
    }
  }
  .box-tooltip {
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 100%;
      translate: -50%;
      border: 5px solid transparent;
      border-bottom-color: #010304;
    }
    width: 230px;
    position: absolute;
    display: none;
    z-index: 999999;
    text-align: center;
    background: #010304;
    left: 50%;
    top: calc(100% + 0.75rem);
    translate: -50%;
    padding: 8px 10px;
    border-radius: 4px;

    .locked-balance-text {
      color: #fff;
      font-weight: 400;
      text-align: left;
      letter-spacing: 0.2px;
    }
  }
  &:hover .box-tooltip {
    display: block;
  }
}
</style>
