<template>
  <div id="markets-dark">
    <markets
      ref="markets"
      :columns="columns"
      :default-market="defaultMarket"
      :callback="closeModal"
      shortNameTab
      :zone="zone"
    ></markets>
  </div>
</template>

<script>
import Const from "@/common/Const";
import Markets from "./landing/Markets";

const VISIBLE_COLUMNS = ["pair", "last_price", "changed_percent", "volume"];

export default {
  components: {
    Markets,
  },
  props: {
    defaultMarket: { type: String, default: Const.DEFAULT_MARKET },
    zone: { default: 0 },
  },
  data() {
    return {
      columns: VISIBLE_COLUMNS,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeMarketModal", true);
    },
  },
  mounted() {
    this.$refs.markets.isShowSearchField = true;
  },
};
</script>

<style lang="scss">
$bgTabMarket: $color-grey-dark-blue;

#markets-dark {
  position: absolute;
  z-index: 1000;
  width: 948px;
  background-color: white;
  top: 100%;
  margin-top: 10px;
  left: 0px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
  border-radius: 16px;
  overflow: hidden;
  cursor: initial;
  @media (max-width: 1350px) {
    width: calc(100vw - 300px);
  }
}
</style>
