<template>
  <paginate
    :pageCount="pageCount"
    :clickHandler="paginate"
    :prevText="'&lang;'"
    :nextText="'&rang;'"
    :containerClass="'VuePaginate'"
    :pageClass="'page-item'"
    :page-link-class="'page-link-item'"
    :prev-class="'prev-item'"
    :prev-link-class="'prev-link-item'"
    :next-class="'next-item'"
    :next-link-class="'next-link-item'"
    :activeClass="'item-active'"
    :page-range="isMobile ? 2 : 3"
  >
  </paginate>
</template>

<script>
import Paginate from 'vuejs-paginate'

export default {
  components: {
    Paginate,
  },
  props: {
    pageCount: {
      type: Number,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    paginate(page) {
      this.page = page
      this.$emit('Pagination:page', page)
    },
  },
}
</script>

<style lang="scss">
.VuePaginate {
  display: inline-flex;
  justify-content: flex-end;
  width: 100%;
  column-gap: 10px;
  padding: 20px;
}

.VuePaginate .page-item:not(.disabled),
.VuePaginate .prev-item,
.VuePaginate .next-item {
  margin-left: 0px;
  margin-right: 0px;
}

.VuePaginate .page-item:not(.disabled) .page-link-item {
  color: $bg-main1;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 6px 12px;
  border-radius: 4px;
}

.VuePaginate .prev-item .prev-link-item,
.VuePaginate .next-item .next-link-item {
  color: $bg-main1;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 6px 12px;
  border-radius: 4px;
}

.VuePaginate .page-item:not(.disabled) .page-link-item:hover,
.VuePaginate .prev-item .prev-link-item:hover,
.VuePaginate .next-item .next-link-item:hover {
  color: #23527c;
  background-color: #eeeeee;
  border-color: #ddd;
  outline: none !important;
}
.VuePaginate .item-active .page-link-item {
  color: #fff !important;
  background-color: $bg-main1 !important;
  border-color: $bg-main1 !important;
  outline: none !important;
}
.VuePaginate .prev-item.disabled .prev-link-item,
.VuePaginate .next-item.disabled .next-link-item {
  opacity: 50%;
}
</style>
