<template>
  <div
    class="tableContainer tableContainer3"
    :style="{ width: widthTable }"
    :class="{ 'table-history': isTableHistory, 'table-friend': isTableFriend }"
  >
    <div class="box_table2">
      <table>
        <thead>
          <tr
            :class="defaultSort ? 'default-sort' : 'custom-sort'"
            @click="onSort"
          >
            <slot
              :resetIE="resetIE"
              :innerHtmlSort="innerHtmlSort"
              :echoclick="echoclick"
            />
          </tr>
        </thead>
        <tbody>
          <slot name="first_row" />
          <slot
            name="body"
            v-for="(row, index) in rows"
            :item="row"
            :index="index"
          />
          <template v-if="this.rows.length === 0">
            <tr class="empty-data">
              <td colspan="50" v-if="fetching">
                <div class="loader"></div>
              </td>
              <td colspan="50" v-else>
                <div
                  class="empty-noti"
                  :style="[height ? { 'min-height': height + 'vh' } : '']"
                >
                  <div class="text-wrapper">
                    <img
                      class="icon-nodata"
                      src="/images/table-nodata.png"
                      alt="no_fund_icon"
                    />
                    <span class="msg-empty">{{
                      msgEmptyData || $t("common.datatable.data_empty")
                    }}</span>
                  </div>
                </div>
              </td>
            </tr>
          </template>

          <!-- <div v-if="this.rows.length === 0" class="no-data" :style="[height ? {'height': height + 'vh'} : '']">
            <div class="wrap-no-data">
              <div class="image-no-data">
                <img src="/images/icon/icon-no-data.svg" alt="no data">
              </div>
              <span v-if="tableNoData === false" class="text-no-data">{{ msgEmptyData || $t('common.datatable.data_empty') }}</span>
              <span v-else class="text-no-data">{{ msgEmptyData || $t('common.datatable.no_data') }}</span>
            </div>
          </div> -->
          <slot name="end_row" />
        </tbody>
      </table>
    </div>
    <template v-if="lastPage > 1">
      <pagination
        ref="pagination"
        class="text-right"
        :per-page="perPage"
        :records="totalRecord"
        :chunk="chunk"
        @Pagination:page="onPageChange"
        :pageParent="page"
      ></pagination>
    </template>
    <div class="pagination_fake" v-if="lastPage <= 1"></div>
  </div>
</template>

<script>
import Pagination from "./Pagination";

export default {
  components: {
    Pagination,
  },
  props: {
    getData: {
      type: Function,
    },
    limit: {
      type: Number,
      default: 10,
    },
    chunk: {
      type: Number,
      default: 5,
    },
    widthTable: {
      type: String,
      default: "100%",
    },
    msgEmptyData: {
      type: String,
    },
    defaultSort: {
      type: Boolean,
      default: true,
    },
    tableNoData: {
      type: Boolean,
      default: false,
    },
    innerHtmlSort: {
      type: String,
      default:
        '<span class="icon-sort-tabl3 icon-arrow6"></span><span class="icon-sort-tabl3 icon-arrow5"></span>',
    },
    isTableHistory: {
      type: Boolean,
      default: false,
    },
    isTableFriend: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 0,
    },
    isMargin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maxPageWidth: 10,
      totalRecord: 0,
      lastPage: 0,
      page: 1,
      perPage: 10,
      fetching: false,
      rows: [],
      params: {},

      orderBy: null,
      sortedBy: null,

      emptyData: false,
      resetIE: false,
    };
  },
  computed: {
    emptyRow() {
      let emptyRowCount = Math.max(this.limit - _.size(this.rows), 0);
      return Math.min(emptyRowCount, this.limit);
    },
  },
  methods: {
    rawHtml() {
      document.getElementById("demo").innerHTML = "My First JavaScript";
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    echoclick() {
      this.resetIE = !this.resetIE;
    },
    getTarget(target) {
      let node = target;
      while (node.parentNode.nodeName !== "TR") {
        node = node.parentNode;
      }
      return node;
    },

    getSortOrder(target) {
      let sortOrder = target.dataset.sortOrder;
      switch (sortOrder) {
        case "asc":
          sortOrder = "";
          break;
        case "desc":
          sortOrder = "asc";
          break;
        default:
          sortOrder = "desc";
      }
      return sortOrder;
    },

    setSortOrders(target, sortOrder) {
      let iterator = target.parentNode.firstChild;
      while (iterator) {
        iterator.dataset.sortOrder = "";
        iterator = iterator.nextElementSibling;
      }
      target.dataset.sortOrder = sortOrder;
    },

    onSort(event) {
      const target = this.getTarget(event.target);
      const orderBy = target.dataset.sortField;
      if (!orderBy) {
        return;
      }
      this.sortedBy = this.getSortOrder(target);
      this.orderBy = this.sortedBy ? orderBy : "";
      Object.assign(this.params, {
        sort: this.orderBy,
        sort_type: this.sortedBy,
      });
      this.setSortOrders(target, this.sortedBy);
      this.fetch();
    },

    fetch() {
      const meta = {
        page: this.page,
        limit: this.limit,
      };

      this.fetching = true;
      this.getData(Object.assign(meta, this.params))
        .then((res) => {
          const data = res.data || res;
          if (!data) {
            return;
          }
          this.column = _.chain(this.$slots.default)
            .filter((el) => {
              return el.tag === "th";
            })
            .value().length;

          if (!data.data) {
            this.rows = data;
            this.$emit("DataTable:finish");
            return;
          }
          this.page = parseInt(data.current_page);
          this.totalRecord = parseInt(data.total);
          this.lastPage = parseInt(data.last_page);
          this.perPage = parseInt(data.per_page);
          this.rows = data.data;
          this.emptyData = window._.isEmpty(this.rows);
          this.$emit("DataTable:finish");
        })
        .then((res) => {
          this.fetching = false;
        });
    },
    refresh() {
      this.page = 1;
      this.params = {};
      this.fetch();
    },

    filter(params) {
      this.page = 1;
      this.params = params;
      this.fetch();
    },
  },
  created() {
    this.fetch();
    this.$on("DataTable:filter", (params) => {
      this.filter(params);
    });
  },
  mounted() {
    this.column = _.chain(this.$slots.default)
      .filter((el) => {
        return el.tag === "th";
      })
      .value().length;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/common.scss";

table {
  width: 100%;
  overflow-y: hidden;
  thead {
    text-align: center;
    color: $color_black;
    background-color: $color_grey_head;
    border: none;
    line-height: 17px;
    overflow: hidden;
    th {
      padding: 8px 0px 8px 0px;
      @include text-font($helvetica, $dark-3, $font-root, 500, 25px);
      border-bottom: 1px solid $grey-1;
      cursor: pointer;
      &:last-child {
        padding: 8px 0px 8px 0px;
      }
      &.text-right {
        text-align: right;
      }
    }
  }
  tbody {
    text-align: center;
    color: $color-grey-dark;
    tr {
      vertical-align: top;
      overflow-y: hidden;
      transition-property: height;
      transition-duration: 0.3s, 0.3s;
      transition-timing-function: ease, ease-in;
      .icon-arrow_2_2 {
        color: $color-green;
      }
      &.active {
        max-height: 300px;
        background-color: $color-bright-gray;
        transition-property: height;
        transition-duration: 0.3s, 0.3s;
        transition-timing-function: ease, ease-in;
      }
      &.empty-data {
        width: 100%;
        text-align: center;
        color: $dark-1;
        font-size: $font-root;
        text-align: center;
        td {
          width: 100%;
          text-align: center !important;
          color: $dark-1 !important;
          padding: 35px 0;
          border-bottom: unset !important;
          .empty-noti {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            align-items: center;
            min-height: 50vh;
            .text-wrapper {
              user-select: none;
              pointer-events: none;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .msg-empty {
              color: $text-secondary;
              font-size: 14px;
              font-weight: 300;
              line-height: 22px;
            }
            .text-wrapper {
              display: flex;
              flex-direction: column;
              gap: 16px;
              justify-content: center;
              align-items: center;
            }
            .icon-nodata {
              font-size: 40px;
              color: $dark-3;
              width: 137px;
              height: auto;
              @include mobile {
                width: 100px;
                height: 100px;
              }
              span {
                font-size: 14px;
                @include mobile {
                  font-size: 10px;
                }
              }
            }
            @include tablet-d {
              align-items: unset;
              height: 100px;
              .text-wrapper {
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                width: fit-content;

                img {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
        // &:hover {
        //   background-color: $color-white !important;
        // }
        p {
          padding-top: 22px;
          span {
            vertical-align: middle;
          }
        }
        .icon-nodata {
          font-size: 40px;
          color: $dark-3;
          @include mobile {
            width: 100px;
            height: 100px;
          }
          span {
            font-size: 14px;
            @include mobile {
              font-size: 10px;
            }
          }
        }
      }
    }
    td {
      text-align: left;
      padding: 8px 0px 8px 0px;
      vertical-align: middle;
      @include text-font($helvetica, $dark-1, $font-root, 600, 18px);
      &:last-child {
        padding: 8px 0px 8px 0px;
        text-align: right;
      }
      &.info {
        background-color: $color-bright-gray;
        color: $color-grey-dusty;
        font-size: $font-small;
        text-align: left;
        padding: 8px 20px 8px 38px;
        .label-text {
          color: $color-grey;
        }
      }
      &.time-column {
        text-align: right;
        line-height: 2;
      }
      &.action {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 6px;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.tableContainer {
  padding: 10px 21px;
  background: white;
  border-radius: 0px 10px 10px 10px;
  border: 1px solid $grey-1;
  height: 100%;

  &.table-friend {
    border-radius: 10px;
  }

  &.table-history {
    .no-data {
      // height: calc(50vh - 32px);
    }

    table {
      thead {
        tr {
          :last-child {
            text-align: right;
          }
          :not(:first-child):not(:last-child) {
            text-align: center;
          }
        }
      }
    }
  }

  table {
    thead {
      background: white;
      tr {
        th {
          padding: 0 0 10px 0 !important;
          font-family: $helvetica;
          font-size: 14px;
          line-height: 25px;
          color: $dark-3;
        }
      }
    }
  }

  &.empty-data {
    width: 100%;
    text-align: center;
    color: $dark-1;
    font-size: $font-root;
    text-align: center;
    td {
      width: 100%;
      text-align: center !important;
      color: $dark-1 !important;
      border-bottom: unset !important;
      padding: 35px 0;
      .empty-noti {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        min-height: 50vh;
        .text-wrapper {
          user-select: none;
          pointer-events: none;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .msg-empty {
          color: $text-secondary;
          font-size: 14px;
          font-weight: 300;
          line-height: 22px;
        }
        .text-wrapper {
          display: flex;
          flex-direction: column;
          gap: 16px;
          justify-content: center;
          align-items: center;
        }
        .icon-nodata {
          font-size: 40px;
          color: $dark-3;
          width: 137px;
          height: auto;
          @include mobile {
            width: 100px;
            height: 100px;
          }
          span {
            font-size: 14px;
            @include mobile {
              font-size: 10px;
            }
          }
        }
        @include tablet-d {
          align-items: unset;
          height: 100px;
          .text-wrapper {
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            width: fit-content;

            img {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    // &:hover {
    //   background-color: $color-white !important;
    // }
    p {
      padding-top: 22px;
      span {
        vertical-align: middle;
      }
    }
    .icon-nodata {
      font-size: 40px;
      color: $dark-3;
      @include mobile {
        width: 100px;
        height: 100px;
      }
      span {
        font-size: 14px;
        @include mobile {
          font-size: 10px;
        }
      }
    }
  }
}

.tableContainer3 {
  th {
    .group-sort-tabl3 {
      // display: inline-table;
      // width: 13px;
      // height: 20px;
      vertical-align: middle;
      position: relative;
      .icon-sort-tabl3 {
        display: none;
        height: 20px;
        width: 13px;
        line-height: 16px;
        // float: left;
        font-size: $font-smaller;
        text-align: center;
      }
    }
    &[data-sort-order="desc"] {
      .group-sort-tabl3 {
        .icon-arrow6 {
          display: inline-table;
        }
      }
    }
    &[data-sort-order="asc"] {
      .group-sort-tabl3 {
        .icon-arrow5 {
          display: inline-table;
        }
      }
    }
    &:hover {
      color: $color-orange;
    }
  }
}

@media screen and (max-width: 768px) {
  .tableContainer {
    padding: 10px;

    table {
      thead {
        tr {
          th {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
