if (
  ~window.navigator.userAgent.indexOf("MSIE") ||
  ~window.navigator.userAgent.indexOf("Trident/") ||
  ~window.navigator.userAgent.indexOf("Edge/")
) {
  window.Promise = require("es6-promise").Promise;
  require("es6-object-assign").polyfill();
}
import "babel-polyfill";
import Echo from "laravel-echo";
import VueI18n from "./VueI18n";
import VueSession from "vue-session";
import Vue from "vue";
import enLang from "../locales/en";
import idLang from "../locales/id";
import Utils from "./Utils";
import AuthenticationUtils from "./AuthenticationUtils";

window._ = require("lodash");

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
  window.$ = window.jQuery = require("jquery");
  require("bootstrap-sass");
} catch (e) {
  console.error("Cannot load jQuery and Boostrap jQuery plugin");
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.common["gscexweb"] = true;

let token = AuthenticationUtils.getAccessToken();

if (token) {
  window.axios.defaults.headers.common["Authorization"] =
    "Bearer " + AuthenticationUtils.getAccessToken();
}

window.isAuthenticated = AuthenticationUtils.isAuthenticated();

Vue.use(VueI18n);
Vue.use(VueSession, { persist: true });

window.io = require("socket.io-client");

window.Echo = new Echo({
  broadcaster: "socket.io",
  host: process.env.VUE_APP_SOCKET_URL,
  auth: {
    headers: {
      Authorization: "Bearer " + token,
    },
  },
});

const currentLocale = AuthenticationUtils.getLocale();
const allMessages = {
  en: enLang,
  id: idLang,
};
const i18n = new VueI18n({
  locale: currentLocale,
  fallbackLocale: "en",
  messages: { [currentLocale]: allMessages[currentLocale] },
});

const loadedLanguages = [currentLocale]; // our default language that is preloaded

loadLanguageAsync(currentLocale);
window.i18n = i18n;

function setI18nLanguage(lang) {
  i18n.locale = lang;
  window.axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export function loadLanguageAsync(lang) {
  if (!i18n) {
    return Promise.resolve(lang);
  }
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  let messages = allMessages[lang || currentLocale];
  i18n.setLocaleMessage(lang, messages);
  loadedLanguages.push(lang);

  return Promise.resolve(setI18nLanguage(lang));

  // // If the language hasn't been loaded yet
  // return import(/* webpackChunkName: "lang-[request]" */ `../locales/${lang}.js`).then(
  //   messages => {
  //     i18n.setLocaleMessage(lang, messages.default)
  //     loadedLanguages.push(lang)
  //     return Promise.resolve(setI18nLanguage(lang))
  //     // return setI18nLanguage(lang)
  //   }
  // )
}
