import Numeral from '../lib/numeral'
import moment from 'moment'
import COMMON_CONST from '@/common/Const'
import BigNumber from 'bignumber.js'

/**
 * Deep copy the given object considering circular structure.
 * This function caches all nested objects and its copies.
 * If it detects circular structure, use cached copy to avoid infinite loop.
 *
 * @param {*} obj
 * @param {Array<Object>} cache
 * @return {*}
 */
export function deepCopy(obj, cache = []) {
  // just return if obj is immutable value
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  // if obj is hit, it is in circular structure
  const hit = _.find(cache, (c) => c.original === obj)
  if (hit) {
    return hit.copy
  }

  const copy = Array.isArray(obj) ? [] : {}
  // put the copy into cache at first
  // because we want to refer it in recursive deepCopy
  cache.push({
    original: obj,
    copy,
  })

  Object.keys(obj).forEach((key) => {
    copy[key] = deepCopy(obj[key], cache)
  })

  return copy
}

export function mapFilters(filters) {
  return filters.reduce((result, filter) => {
    const key = Array.isArray(filter) ? filter[0] : filter
    const value = Array.isArray(filter) ? filter[1] : filter
    result[key] = function (...args) {
      return window.app.$options.filters[value](...args)
    }
    return result
  }, {})
}

export default {
  getUnit(ref, currentInstrument) {
    if (!currentInstrument) {
      console.error('currentInstrument is not defined')
      return
    }
    const { base_underlying = '', quote_currency = '', settle_currency = '' } = currentInstrument
    if (!['contract_value', 'quantity', 'price'].includes(ref)) {
      console.error('ref is not defined')
      return quote_currency ? quote_currency : base_underlying ? base_underlying : 'BTC'
    }
    const multiplier = Number(currentInstrument.multiplier)
    const dataEqOne = {
      contract_value: multiplier > 0 ? 1 + ' ' + base_underlying : 1 + ' ' + quote_currency,
      quantity: multiplier > 0 ? base_underlying : quote_currency,
      price: quote_currency,
    }
    const dataLtOne = {
      contract_value:
        multiplier > 0
          ? [Math.abs(multiplier), ' ', settle_currency, ' per ', base_underlying].join(' ')
          : [Math.abs(multiplier), ' ', settle_currency, ' per ', quote_currency].join(' '),
      quantity: window.i18n.t('margin.cont'),
      price: quote_currency,
    }
    return Math.abs(multiplier) == 1 ? dataEqOne[ref] : dataLtOne[ref]
  },

  shallowCompare(newObj, prevObj) {
    for (key in newObj) {
      if (newObj[key] !== prevObj[key]) return true
    }
    return false
  },

  qs: function (key) {
    key = key.replace(/[*+?^$.[]{}()|\\\/]/g, '\\$&') // escape RegEx meta chars
    const match = location.href.match(new RegExp('[?&]' + key + '=([^&]+)(&|$)'))
    return (match && decodeURIComponent(match[1].replace(/\+/g, ' '))) || null
  },

  setI18nLocale(locale) {
    window.i18n.locale = locale
    window.app.$broadcast('UPDATED_LOCALE', locale)
  },

  splitAmount(amount, currency) {
    let amtStr = amount?.toString() || ''
    const decimal =
      currency.toLowerCase() === 'idr' ||
      currency.toLowerCase() === 'usdt' ||
      currency.toLowerCase() === 'busd'
        ? COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS_FIAT
        : COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS
    if (amtStr.split('.').length === 1) {
      return amtStr
    } else {
      return amtStr.split('.')[0] + '.' + amtStr.split('.')[1].slice(0, decimal)
    }
  },

  formatCurrencyAmount(amount, currency, zeroValue) {
    let numberOfDecimalDigits =
      currency && currency.toLowerCase() === 'idr'
        ? //   ||
          // currency.toLowerCase() === 'usdt' ||
          // currency.toLowerCase() === 'busd')
          COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS_FIAT
        : COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS
    const digits = Math.floor(numberOfDecimalDigits)
    const amountArr = amount ? amount.toString().split('.') : ['0']
    let format =
      digits == 0
        ? '0'
        : amountArr.length === 2 && amountArr[1] != 0
        ? '0,0.0[' + Array(digits).join('0') + ']'
        : '0,0[' + Array(digits + 1).join('0') + ']'
    if (window._.isNil(zeroValue)) {
      zeroValue = '0'
    }

    if (amount && parseFloat(amount) != 0) {
      if (
        (amountArr.length === 1 && amountArr[0] != 0) ||
        (amountArr.length === 2 && amountArr[1] == 0)
      ) {
        return Numeral(amount).format(format)
      } else {
        return Numeral(amount).format(format)
      }
    }
    return zeroValue
  },

  getPrecisionNumber(a) {
    const b = Numeral(a).value()
    if (!isFinite(b)) return 0
    var e = 1,
      p = 0
    while (Math.round(b * e) / e !== b) {
      e *= 10
      p++
    }
    return p
  },

  convertToLocalTime(date) {
    if (date == null) {
      return '--'
    }
    var dateFormat = 'YYYY-MM-DD HH:mm:ss'
    var testDateUtc = moment.utc(date)
    var localDate = testDateUtc.local()
    return localDate.format(dateFormat)
  },

  formatMarginCost(amount, zeroValue = '0') {
    const cost = new BigNumber(new BigNumber(`${amount || 0}`).abs().toFixed(8, BigNumber.ROUND_UP))
    return this.formatMarginNumber(cost.toFixed(), 8, zeroValue)
  },

  formatMarginCapital(amount, zeroValue = '0') {
    const capital = new BigNumber(
      new BigNumber(`${amount || 0}`).abs().toFixed(8, BigNumber.ROUND_DOWN)
    )
    return this.formatMarginNumber(capital.toFixed(), 8, zeroValue)
  },

  formatMarginNumber(amount, numberOfDecimalDigits = 8, zeroValue = null, isSetPositive = false) {
    const digits = Math.floor(numberOfDecimalDigits)
    let format = digits == 0 ? '0,0' : '0,0.[' + Array(digits + 1).join('0') + ']'

    if (amount && parseFloat(amount) != 0 && isSetPositive) {
      amount = Math.abs(parseFloat(amount))
    }

    return amount && parseFloat(amount) != 0
      ? Numeral(amount).format(format)
      : zeroValue != null
      ? zeroValue
      : '--'
  },

  formatMarginValue(amount) {
    return Numeral(Math.abs(amount.toString().replace(',', ''))).value()
  },

  formatDigitsNumber(amount, numberOfDecimalDigits = 4, showZeroAtTheEnd = false) {
    const digits = Math.floor(numberOfDecimalDigits)
    let format =
      digits == 0
        ? '0,0'
        : showZeroAtTheEnd
        ? '0,0.' + Array(digits + 1).join('0')
        : '0,0.[' + Array(digits + 1).join('0') + ']'

    let valueReturn = '--'
    if (amount && parseFloat(amount) !== 0) {
      valueReturn = Numeral(amount).format(format)
      // if (valueReturn.indexOf('.') < 0) {
      //   valueReturn += '.0';
      // }
    }
    return valueReturn
  },

  formatMarginPrice(amount, numberOfDecimalDigits, customDefault = '--') {
    const digits = Math.floor(numberOfDecimalDigits)
    let format = digits == 0 ? '0,0' : '0,0.[' + Array(digits + 1).join('0') + ']'

    return amount && parseFloat(amount) != 0 ? Numeral(amount).format(format) : customDefault
  },

  roundCurrencyAmount(amount, currency, zeroValue) {
    let numberOfDecimalDigits =
      currency && currency.toLowerCase() === 'idr'
        ? COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS_FIAT
        : COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS //currency === 'idr' ? 2 : 8;
    const digits = Math.floor(numberOfDecimalDigits)
    let format = digits == 0 ? '0' : '0[.' + Array(digits + 1).join('0') + ']'
    if (window._.isNil(zeroValue)) {
      zeroValue = ''
    }

    return amount && parseFloat(amount) != 0 ? Numeral(amount).format(format) : zeroValue
  },

  getCurrencyName(value) {
    return value ? value.toUpperCase() : value
  },

  getTimzoneOffset() {
    let d = new Date()
    return d.getTimezoneOffset()
  },

  isWalletAddress(currency, address, blockchain_sub_address) {
    switch (currency) {
      case 'idr':
        //TO DO
        return /^.+$/.test(address)
      case 'xrp':
        return (
          /^r[rpshnaf39wBUDNEGHJKLM4PQRST7VWXYZ2bcdeCg65jkm8oFqi1tuvAxyz]{27,35}$/.test(address) &&
          Number(blockchain_sub_address) < Math.pow(2, 32)
        )
      case 'etc':
        return /^[0-9A-HJ-NP-Za-km-z]{26,35}$/.test(address)
      case 'eth':
        // returned true to prevent next error.
        //need more research.
        return true

      case 'amal':
        // returned true to prevent next error.
        //need more research.
        return true
      case 'tomo':
        // returned true to prevent next error.
        //need more research.
        return true
      case 'knc':
        // returned true to prevent next error.
        //need more research.
        return true
      case 'tusd':
        // returned true to prevent next error.
        //need more research.
        return true
      case 'bnb':
        // returned true to prevent next error.
        //need more research.
        return true
      case 'busd':
        if (/^(0x)?[0-9a-fA-F]{40}$/.test(address)) {
          return true
        }
        return false
      case 'btc':
        // returned true to prevent next error.
        //need more research.
        return true
      case 'bch':
        // returned true to prevent next error.
        //need more research.
        return true
      case 'wbc':
        return /^[123mn][1-9A-HJ-NP-Za-km-z]{26,35}$/.test(address)
      case 'neo':
        return /^[A][1-9A-HJ-NP-Za-km-z]{26,35}$/.test(address)
      case 'dash':
        return /^[0-9A-HJ-NP-Za-km-z]{26,35}$/.test(address)
      case 'ltc':
        return /[A-Za-z]+([0-9]+([A-Za-z]+[0-9]+)+)[A-Za-z]+/i.test(address)
      case 'eos':
        return /^[a-z0-9.]{1,12}$/.test(address)
    }

    return true
  },

  //delete if bot use anymore
  getTransactionUrl(currency, transactionId) {
    const erc20 = ['usdt', 'eth']
    const bsc = ['bnb', 'busd', 'ada']
    let network = currency
    if (erc20.indexOf(currency) >= 0) {
      network = 'erc20'
    }
    if (bsc.indexOf(currency) >= 0) {
      network = 'bsc'
    }
    switch (network) {
      case 'btc':
        return 'https://blockchain.info/tx/' + transactionId
      // return 'https://blockexplorer.one/bitcoin/testnet/tx/' + transactionId
      case 'erc20':
        return 'https://etherscan.io/tx/' + transactionId
      // return 'https://goerli.etherscan.io/tx/' + transactionId
      case 'etc':
        return 'https://gastracker.io/tx/' + transactionId
      // return 'https://blockexplorer.one/ethereum-classic/testnet/tx/' + transactionId
      case 'bch':
        return 'https://explorer.bitcoin.com/bch/tx/' + transactionId
      // return 'https://blockexplorer.one/bitcoin-cash/testnet/tx/' + transactionId
      case 'xrp':
        return 'https://xrpcharts.ripple.com/#/transactions/' + transactionId
      // return 'https://blockexplorer.one/xrp/testnet/tx/' + transactionId
      case 'ltc':
        return 'https://live.blockcypher.com/ltc/tx/' + transactionId
      // return 'https://blockexplorer.one/litecoin/testnet/tx/' + transactionId
      case 'dash':
        return 'https://explorer.dash.org/tx/' + transactionId
      // return 'https://blockexplorer.one/dash/testnet/tx/' + transactionId
      case 'bsc':
        return 'https://bscscan.com/tx/' + transactionId
      // return 'https://testnet.bscscan.com/tx/' + transactionId
      default:
        return ''
    }
  },

  trimEndZero(value) {
    const dot = '.'
    const strValue = `${value}`
    if (strValue.indexOf(dot) === -1) {
      return strValue
    }
    const trimEndZero = window._.trimEnd(strValue, '0')
    return window._.trimEnd(trimEndZero, dot)
  },

  //! fallback to below function if the browser doesn't support clipboard method
  // if (!navigator.clipboard) {
  //   fallbackCopyTextToClipboard(text);
  //   return;
  // }
  // navigator.clipboard.writeText(text)

  fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement('textarea')
    textArea.value = text
    textArea.style.position = 'fixed' //avoid scrolling to bottom
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      var successful = document.execCommand('copy')
      var msg = successful ? 'successful' : 'unsuccessful'
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err)
    }

    document.body.removeChild(textArea)
  },

  replaceTmpVar(lang, articleNum) {
    let obj = {
      Lang: lang,
      ArticleNum: articleNum,
    }
    let url = `https://amanpuri-exchange.zendesk.com/hc/{Lang}/articles/{ArticleNum}`
    Object.keys(obj).forEach((key) => {
      url = url.replace(new RegExp(`{${key}}`, 'g'), obj[key])
    })
    return url
  },

  checkHasWhiteSpace(string) {
    return /\s/g.test(string)
  },
  scientificToDecimal(num) {
    let nsign = Math.sign(num)
    //remove the sign
    num = Math.abs(num)
    //if the number is in scientific notation remove it
    if (/\d+\.?\d*e[\+\-]*\d+/i.test(num)) {
      let zero = '0',
        parts = String(num).toLowerCase().split('e'), //split into coeff and exponent
        e = parts.pop(), //store the exponential part
        l = Math.abs(e), //get the number of zeros
        sign = e / l,
        coeff_array = parts[0].split('.')
      if (sign === -1) {
        l = l - coeff_array[0].length
        if (l < 0) {
          num =
            coeff_array[0].slice(0, l) +
            '.' +
            coeff_array[0].slice(l) +
            (coeff_array.length === 2 ? coeff_array[1] : '')
        } else {
          num = zero + '.' + new Array(l + 1).join(zero) + coeff_array.join('')
        }
      } else {
        let dec = coeff_array[1]
        if (dec) l = l - dec.length
        if (l < 0) {
          num = coeff_array[0] + dec.slice(0, l) + '.' + dec.slice(l)
        } else {
          num = coeff_array.join('') + new Array(l + 1).join(zero)
        }
      }
    }

    return nsign < 0 ? '-' + num : num
  },
}

export function formartSecondToMinute(second) {
  const minutes = parseInt((second / 60).toString()) % 60
  const seconds = second % 60
  return `${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
}

export const visaRegex = /^4[0-9]{3}[*]{8}(?:[0-9]{4})?$/
export const masterCardRegex =
  /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[*]{8}[0-9]{4}$/
export const JCBRegex = /^(?:2123|1800)[*]{8}[0-9]{3}|^3[0-9]{3}[*]{8}[0-9]{4}$/
export const americanExpressRegex = /^3[47][0-9]{2}[*]{8}[0-9]{3}$/
export const Credit = {
  MasterCard: 'Master card',
  Visa: 'Visa',
  JCB: 'JCB',
  AMEX: 'AMEX',
  CreditCard: 'Credit card',
  Unknown: 'Unknown',
}
export function getTypeCreditCard(number) {
  const cardNumber = number.replace(/\s+/g, '')
  if (!cardNumber) return Credit.CreditCard
  if (cardNumber.match(visaRegex)) return Credit.Visa
  if (cardNumber.match(masterCardRegex)) return Credit.MasterCard
  if (cardNumber.match(JCBRegex)) return Credit.JCB
  if (cardNumber.match(americanExpressRegex)) return Credit.AMEX
  return Credit.CreditCard
}
