<template>
  <div id="notfound">
    <div class="notfound-content">
      <div class="title">{{ $t("not_found.title") }}</div>
      <div class="text">{{ $t("not_found.text") }}</div>
      <button class="btn-landing" @click="gotoRoute('/')">
        {{ $t("not_found.landing") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    gotoRoute(route) {
      this.$router.push(route, () => {});
    },
  },
};
</script>
<style lang="scss" scoped>
#notfound {
  background: white;

  .notfound-content {
    max-width: 888px;
    margin: 0 auto;
    padding: 200px 220px;
    text-align: center;
    background-color: white;
    background-image: url("@/assets/images/404.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @include mobile {
      width: 100vw;
      padding: 150px 24px;
      background-size: calc(100vw - 40px);
    }

    img {
      width: 100%;
      height: auto;
      max-width: 462px;
    }

    .title {
      margin: 0;
      font-size: 36px;
      line-height: 56px;
      font-weight: 700;
      color: #000;

      @include mobile {
        font-size: 24px;
        line-height: 40px;
      }
    }

    .text {
      margin-top: 8px;
      font-size: 14px;
      line-height: 22px;
      color: $text-secondary;
      font-weight: 400;
    }

    .btn-landing {
      margin-top: 40px;
      width: auto;
      padding: 10px 32px;
      background: $bg-main1;
      font-size: 18px;
      line-height: 32px;
      color: white;
      border-radius: 60px;

      @include mobile {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}
</style>
