<template>
  <div id="cancel-box">
    <div class="btn_container">
      <a
        class="btn-cancel"
        tabindex="0"
        @click="confirmCancelOrder(config.all)"
        >{{ $t("order.open_order.cancel_all") }}</a
      >
      <div class="divide"></div>
      <div class="dropdown" v-click-outside="hide" tabindex="0">
        <button
          class="dropdown-toggle"
          @click="visible = !visible"
          tabindex="0"
        >
          <!-- <span class="icon-arrow1"></span> -->
          <img
            src="/images/spot_exchange/icon-dropdown.svg"
            alt=""
            v-if="!visible"
          />
          <img src="/images/spot_exchange/icon-dropdown-up.svg" alt="" v-else />
        </button>
        <ul class="dropdown-menu" v-if="visible" tabindex="0">
          <li>
            <a @click="confirmCancelOrder(config.limit)">{{
              $t("order.open_order.cancel_limit_orders")
            }}</a>
          </li>
          <li>
            <a @click="confirmCancelOrder(config.market)">{{
              $t("order.open_order.cancel_market_orders")
            }}</a>
          </li>
          <li>
            <a @click="confirmCancelOrder(config.stopLimit)">{{
              $t("order.open_order.cancel_stop_limit_orders")
            }}</a>
          </li>
          <li>
            <a @click="confirmCancelOrder(config.stopMarket)">{{
              $t("order.open_order.cancel_stop_market_orders")
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    trigger: {
      type: Object,
      default: () => ({
        limit: true,
        market: true,
        stopLimit: true,
        stopMarket: true,
      }),
    },
  },
  data() {
    return {
      visible: false,
      config: {
        all: "all",
        limit: "limit",
        market: "market",
        stopLimit: "stop_limit",
        stopMarket: "stop_market",
      },
    };
  },
  methods: {
    confirmCancelOrder(type) {
      let isTrigger = false;
      switch (type) {
        case this.config.all: {
          isTrigger =
            this.trigger.limit ||
            this.trigger.market ||
            this.trigger.stopLimit ||
            this.trigger.stopMarket;
          break;
        }
        case this.config.limit: {
          isTrigger = this.trigger.limit;
          break;
        }
        case this.config.market: {
          isTrigger = this.trigger.market;
          break;
        }
        case this.config.stopLimit: {
          isTrigger = this.trigger.stopLimit;
          break;
        }
        case this.config.stopMarket: {
          isTrigger = this.trigger.stopMarket;
          break;
        }
      }
      if (!isTrigger) {
        return;
      }
      if (this.config.all !== type) {
        this.visible = !this.visible;
      }
      this.$emit("action-cancel-box", type);
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
#cancel-box {
  .btn_container {
    padding: 6px 10px;
    width: fit-content;
    float: right;
    border: 1px solid #c7cbd3;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }

  .divide {
    width: 1px;
    border: 1px solid #c7cbd3;
    height: 16px;
    margin-left: 8px;
    margin-right: 2px;
  }

  .btn {
    padding-right: 0px !important;
    border-radius: 0;
    font-size: 13px;
  }

  .btn-cancel {
    border-right: 1px solid $color-grey-border;
    color: $text-main;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    border: none;
    color: #001336;
    // &:hover {
    //   color: $color-eden;
    //   border: 1px solid $color-eden;
    // }
  }

  .dropdown {
    display: inline;

    & .dropdown-toggle {
      // img {
      //   display: none;
      // }
    }

    button {
      border: 1px solid $color-grey-border;
      background-color: transparent;
      font-size: $font-mini;
      line-height: 20px;
      padding: 2px 1px;
      text-align: center;
      width: 22px;
      height: 22px;
      border: none;

      span {
        color: $color-grey-dusty-dusty;

        &.icon-arrow1:before {
          color: #001336;
        }
      }

      &:hover {
        // border-color: $color-orange;
        // color: $color-orange;
        span {
          color: $color-orange;
        }
      }

      .caret {
        color: $color-yellow;
      }
    }

    .dropdown-menu {
      top: 22px;
      right: 0;
      left: auto;
      display: block;
      font-size: $font-root;
      color: $color-grey-dark;

      li a {
        &:hover {
          color: $color-orange;
          background-color: transparent;
        }
      }
    }
  }
}
</style>
