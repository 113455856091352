<template>
  <div>
    <template v-if="isMobile && data.length !== 0">
      <div id="detail-fee-tier">
        <div class="volume-table__mobile-wrapper">
          <div class="volume-table__mobile-header">
            <div class="volume-table__mobile-title">
              <div class="mobile-title__item">
                {{ $t("fee_tier.detail.tier") }}
              </div>
              <div class="mobile-title__item">
                {{ $t("fee_tier.detail.required_adv") }}
              </div>
            </div>
          </div>
          <div class="volume-table__mobile-body">
            <template>
              <div
                class="volume-table__mobile-item"
                v-for="item in data"
                :key="item.level"
                aria-expanded="false"
              >
                <div class="mobile-item__wrapper">
                  <div class="item-wrapper__title">
                    {{ $t("fee_tier.detail.tier") + " " + item.level }}
                  </div>
                  <div class="item-wrapper__title">
                    {{ fixedNumber(item.avg_daily_volume) + " USDT" }}
                  </div>
                  <div
                    class="item-wrapper__dropdown-icon"
                    @click.prevent="onClickExpandItem"
                  >
                    <img
                      src="/images/instant-convert/icon-dropdown.svg"
                      alt=""
                      class="dropdown-icon__down"
                    />
                    <img
                      src="/images/instant-convert/icon-dropdown2.svg"
                      class="dropdown-icon__up"
                      alt=""
                    />
                  </div>
                </div>
                <div class="mobile-item__expand-content">
                  <div class="expand-content__wrapper">
                    <div class="expand-content__title">
                      {{ $t("fee_tier.detail.taker_fee") }}
                    </div>
                    <div class="expand-content__desc">
                      {{ Number(item.taker_fee) + "%" }}
                    </div>
                  </div>
                  <div class="expand-content__wrapper">
                    <div class="expand-content__title">
                      {{ $t("fee_tier.detail.maker_fee") }}
                    </div>
                    <div class="expand-content__desc">
                      {{ Number(item.maker_fee) + "%" }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div id="detail-fee-tier">
        <div class="table-wrapper">
          <table :class="{ nodata: data.length === 0 }">
            <thead>
              <tr>
                <th class="cl-1">{{ $t("fee_tier.detail.tier") }}</th>
                <th class="cl-2">{{ $t("fee_tier.detail.required_adv") }}</th>
                <th class="cl-3">{{ $t("fee_tier.detail.taker_fee") }}</th>
                <th class="cl-4">{{ $t("fee_tier.detail.maker_fee") }}</th>
              </tr>
            </thead>
            <tbody>
              <div
                class="wrap-loader"
                v-if="nameDetails === 'Last Details' && loadingLastDetails"
              >
                <div class="loader"></div>
              </div>
              <div
                class="wrap-loader"
                v-else-if="
                  nameDetails === 'Current Details' && loadingCurrentDetails
                "
              >
                <div class="loader"></div>
              </div>
              <template v-else-if="data.length === 0">
                <tr class="empty-data">
                  <!-- <td colspan="4" v-if="fetching">
                    <div class="loader"></div>
                  </td> -->
                  <td colspan="50">
                    <div class="empty-noti">
                      <div class="text-wrapper">
                        <img
                          class="icon-nodata"
                          src="/images/table-nodata.png"
                          alt="no_fund_icon"
                        />
                        <span>
                          {{ $t("fee_tier.detail.nodata") }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr
                  v-for="item in data"
                  :key="item.level"
                  :class="{ active: item.level == currentLevel }"
                >
                  <td>{{ $t("fee_tier.detail.tier") + " " + item.level }}</td>
                  <td>{{ fixedNumber(item.avg_daily_volume) + " USDT" }}</td>
                  <td>{{ Number(item.taker_fee) + "%" }}</td>
                  <td>{{ Number(item.maker_fee) + "%" }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Utils from "@/common/Utils";
export default {
  name: "VolumeHistoryTable",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    currentLevel: {
      type: Number,
      default: 0,
    },
    nameDetails: { default: "" },
    loadingLastDetails: { default: true },
    loadingCurrentDetails: { default: true },
  },
  methods: {
    onClickExpandItem(e) {
      const closestWrapper = e.currentTarget.closest(
        ".volume-table__mobile-item"
      );
      closestWrapper.ariaExpanded = !JSON.parse(closestWrapper.ariaExpanded);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    fixedNumber(number) {
      if (!number || number == 0) {
        return "0";
      }
      if (number.split(".").length === 1) {
        return Utils.formatCurrencyAmount(Number(number).toFixed(0), "idr", 0);
      }
      return Utils.formatCurrencyAmount(
        Math.floor((Number(number) + Number.EPSILON) * 100) / 100,
        "idr",
        0
      );
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    isMobile() {
      if (!this.windowWidth) return;
      return this.windowWidth <= 768;
    },
  },
};
</script>

<style lang="scss" scoped>
.volume-table__mobile-wrapper {
  & .volume-table__mobile-title {
    display: grid;
    border-radius: 10px 10px 0px 0px;
    background-color: $bg-main4;
    padding: 8px 16px;
    grid-template-columns: repeat(2, 1fr);
    .mobile-title__item {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      color: $text-secondary;
    }
  }
  & .volume-table__mobile-item {
    background-color: $color-white;
    padding: 10px 16px;
    border-bottom: 1px solid $bg-main2;
    &[aria-expanded="true"] {
      .dropdown-icon__down {
        display: none;
      }
      .dropdown-icon__up {
        display: block;
      }
      .mobile-item__expand-content {
        display: flex;
      }
    }
    &[aria-expanded="false"] {
      .dropdown-icon__down {
        display: block;
      }
      .mobile-item__expand-content,
      .dropdown-icon__up {
        display: none;
      }
    }
    .mobile-item__wrapper {
      display: grid;
      position: relative;
      grid-template-columns: repeat(2, 1fr);
      .item-wrapper__title {
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
        color: $text-main;
      }
      .item-wrapper__dropdown-icon {
        position: absolute;
        right: 0px;
      }
    }
    .mobile-item__expand-content {
      margin-top: 12px;
      background-color: $bg-main4;
      padding: 16px;
      flex-direction: column;
      border-radius: 8px;
      gap: 8px;
      .expand-content__wrapper {
        display: flex;
        justify-content: space-between;
        .expand-content__title {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          color: $text-main;
        }
        .expand-content__desc {
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          color: $text-main;
          &.buy {
            text-transform: capitalize;
            font-weight: 700;
            color: $color-green;
          }
          &.sell {
            font-weight: 700;
            text-transform: capitalize;
            color: $color-red;
          }
        }
      }
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
.empty-data {
  width: 100%;
  text-align: center;
  td {
    width: 100%;
    text-align: center !important;
    padding: 35px 0;
    border-bottom: none !important;
    .empty-noti {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
      min-height: 400px;
      .text-wrapper {
        user-select: none;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .msg-empty {
        color: $text-secondary;
        font-family: Helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        @include mobile {
          font-family: Helvetica;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
        }
      }
      .text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
      }
      .icon-nodata {
        font-size: 40px;
        color: $dark-3;
        width: 137px;
        height: auto;
        span {
          font-size: 14px;
          @include mobile {
            font-size: 10px;
          }
        }
      }
      @include tablet-d {
        align-items: unset;
        height: 100px;
        .text-wrapper {
          position: sticky;
          left: 50%;
          transform: translate(-50%);
          width: fit-content;

          img {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  &:hover {
    background-color: $color-white !important;
  }
  p {
    padding-top: 22px;
    span {
      vertical-align: middle;
    }
  }
  .icon-nodata {
    font-size: 40px;
    color: $dark-3;
    span {
      font-size: 14px;
      @include mobile {
        font-size: 10px;
      }
    }
  }
}
#detail-fee-tier {
  margin-top: 10px;
  background: white;
  border: solid 1px $grey-1;
  border-radius: 10px;
  overflow: auto;
  .table-wrapper {
    width: 100%;
    overflow: auto;
    position: relative;
    table {
      width: 100%;
      &.nodata {
        min-height: 300px;
      }
      thead {
        tr {
          th {
            font-family: $helvetica;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            color: $text-secondary;
            padding: 10px;
            // border-bottom: solid 1px $bg-main2;
            background-color: $bg-main4;
            text-align: left;
            white-space: nowrap;
            &:first-child {
              padding-left: 20px;
            }
            &:last-child {
              text-align: right;
              padding-right: 20px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            font-family: $helvetica;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
            color: $text-main;
            padding: 10px;
            text-align: left;
            border-bottom: 1px solid $bg-main2;
            &:first-child {
              padding-left: 20px;
            }
            &:last-child {
              text-align: right;
              padding-right: 20px;
            }
            &.nodata {
              padding: 35px 0 10px;
              text-align: center;
              font-family: $helvetica;
              color: $dark-3;
              @include tablet {
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                width: -moz-fit-content;
                width: fit-content;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              @include mobile {
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                width: -moz-fit-content;
                width: fit-content;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
            }
          }
          &.active {
            td {
              // background: $grey-3;
            }
          }
          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}

.cl-1 {
  min-width: 180px;
}
.cl-2 {
  min-width: 180px;
}
.cl-3 {
  min-width: 180px;
}
.cl-4 {
  min-width: 120px;
}
.wrap-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .loader {
    display: block;
    margin: auto;
    border: 5px solid $color-grey-concrete;
    border-radius: 50%;
    border-top: 5px solid $bg-main1;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
}
</style>
