<template>
  <div class="input-group">
    <div :class="{ error: errors.has('blockchain_sub_address') }">
      <input-text-and-number
        :class="{ error: errors.has('blockchain_sub_address') }"
        type="text"
        class="form-input"
        v-model="tag"
        :disabled="disabled"
        @focus="resetErrorTag"
      />
    </div>
    <span v-show="errors.has('blockchain_sub_address')" class="f-left invalid-feedback">
      {{ errors.first('blockchain_sub_address') }}
    </span>
  </div>
</template>

<script>
import InputTextAndNumber from '../../common/InputTextAndNumber';
export default {
  inject: ['$validator'],
  components: {
    InputTextAndNumber,
  },
  data() {
    return {
      tag: '',
    };
  },
  props: {
    value: { required: true },
    coin: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  watch: {
    coin: function () {
      this.tag = '';
    },
    value: function (val) {
      this.tag = val || '';
    },
    tag: function (val) {
      if (val !== this.value) {
        this.$emit('input', val);
      }
    },
  },
  methods: {
    validate() {
      // if (_.isEmpty(this.tag)) {
      //   this.errors.add('blockchain_sub_address', this.$i18n.t('address.errors.tag_required'));
      // }
    },
    resetErrorTag() {
      this.errors.remove('blockchain_sub_address');
    },
  },
};
</script>

<style lang="scss" scoped>
.input-group {
  .error {
    margin-bottom: 4px;
  }
}
.form-input {
  background-color: transparent;
  &:focus {
    border-color: $color-jungle-green;
  }
  &.error {
    border: 1px solid $color-red;
    background: rgba(255, 91, 91, 0.1);
  }
}
.invalid-feedback {
  margin-top: 0 !important;
}
</style>
