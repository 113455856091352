<template>
  <div id="device-manager" class="datatable scroll-table">
    <data-table
      :getData="getDevices"
      :widthTable="'100%'"
      :classHeader="'header-css'"
      @DataTable:finish="onDatatableFinished"
      ref="datatable"
      class="table_min"
    >
      <tr class="double_tr">
        <th>{{ $t("account.device") }}</th>
        <th>{{ $t("account.date") }}</th>
        <th>{{ $t("account.ip_address") }}</th>
        <th></th>
        <th></th>
      </tr>
      <template slot="body" slot-scope="props">
        <!--
        <tr class="device-manager" :class="props.index % 2 === 0 ? 'odd' : 'active'">
        -->
        <tr class="device-manager">
          <td>
            {{ props.item.kind | uppercaseFirst }},
            {{ props.item.operating_system }}, {{ props.item.platform }}
          </td>
          <td>
            {{
              moment(props.item.updated_at + " UTC").format(
                "YYYY-MM-DD HH:mm:ss"
              )
            }}
          </td>
          <td>{{ props.item.latest_ip_address }}</td>
          <td
            class="actions"
            @click.prevent="confirmRemoveDevice(props.item.id)"
          >
            <span class="icon-close"></span>
          </td>
          <td class="actions" @click="activeRow(props.item)">
            <span
              class="glyphicon"
              :class="
                props.item.isActiveRow ? 'icon-arrow_2_2' : 'icon-arrow_2_1'
              "
            ></span>
          </td>
        </tr>
        <template v-if="props.item.isActiveRow">
          <tr
            class="device-manager"
            :class="{ odd: (props.index + 1) % 2 === 0 }"
            v-for="connection in props.item.user_connection_histories"
            :key="connection.created_at"
          >
            <td></td>
            <td>{{ connection.created_at | timestampToDate }}</td>
            <td>{{ connection.ip_address }}</td>
            <td></td>
            <td></td>
          </tr>
        </template>
      </template>
    </data-table>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import moment from "moment";

export default {
  data() {
    return {
      moment: moment,
    };
  },
  methods: {
    onDatatableFinished() {
      if (!this.$refs.datatable || !this.$refs.datatable.rows) {
        return;
      }
      window._.each(this.$refs.datatable.rows, (item) => {
        item.isActiveRow = false;
      });
    },
    activeRow(item) {
      item.isActiveRow = !item.isActiveRow;
      this.$forceUpdate();
    },
    getDevices(params) {
      this.isActiveRow = -1;
      return rf.getRequest("UserRequest").getDeviceRegister(params);
    },
    refresh() {
      this.$refs.datatable.refresh();
    },
    confirmRemoveDevice(deviceId) {
      window.ConfirmationModal.show({
        type: "primary",
        title: "",
        content: this.$t("account.modal.confirm_trusted_device"),
        btnCancelLabel: this.$i18n.t("common.action.no"),
        btnConfirmLabel: this.$i18n.t("common.action.yes"),
        onConfirm: () => {
          this.removeDevice(deviceId);
        },
      });
    },
    removeDevice(deviceId) {
      rf.getRequest("UserRequest")
        .deleteDeviceAccount(deviceId)
        .then((res) => {
          this.refresh();
          Message.success(this.$i18n.t("address.deleted_success"));
        });
    },
  },
};
</script>

<style lang="scss">
#device-manager {
  margin-top: 15px;

  .icon-close {
    font-weight: 600;
    font-size: 12px;
    color: $color-green;

    &:hover,
    &:focus {
      color: $color-eden;
    }
  }

  tr {
    height: auto;

    &.double_tr {
      padding: 0 25px;

      th {
        font-size: $font-root;
        color: $color-grey;
        padding: 15px 0px;
        line-height: 20px;
        width: 31%;

        &:nth-child(4) {
          width: 3.5%;
        }

        &:nth-child(5) {
          width: 3.5%;
        }
      }
    }

    &.device-manager {
      padding: 0 25px;

      &:hover {
        background-color: $color-bright-gray;
      }

      th {
        &.active {
          max-height: 300px;
          transition-property: height;
          transition-duration: 0.3s, 0.3s;
          transition-timing-function: ease, ease-in;

          .glyphicon-menu-down {
            transition-duration: 0.5s;
            transform: rotate(180deg);
          }
        }
      }

      td {
        text-align: left;
        width: 31%;
        font-family: $font-family-roboto;
        font-weight: 600;

        &:nth-child(4) {
          width: 3.5%;
        }

        &:nth-child(5) {
          width: 3.5%;
        }
      }
    }
  }

  .odd {
    background-color: $color-white;
  }

  .actions {
    float: right;
    cursor: pointer;
    color: $color-grey !important;
  }
}
</style>
