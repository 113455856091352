<template>
  <base-layout>
    <div
      class="auth-container background-login"
      :class="[shouldShowOtpForm ? 'background-login2' : '']"
    >
      <BlackPopup @blackPopClick="blackPopClick" v-if="showAcceptPop">
        <div class="pop_con law_tip">
          <div class="poptit">Confirm／確認</div>
          <div class="poptip">
            アマンプリは各国の法律に準じた営業をしています。
            一部の国の法律では利用を制限されることがあります。
            自国の法律をよく理解した上で利用を行って下さい。
            またアマンプリでは既にデポジットされている資金について口座凍結、出金禁止などの対応は一切おこなっておりません。
          </div>
          <div class="accept">
            <input
              type="checkbox"
              name="accept"
              value="true"
              @click="acceptClick"
              checked="checked"
            />
            理解しました。
          </div>
          <button
            :class="{
              chrome_only: isChrome && !errors.any(),
              disabledBtn: isSubmitting,
            }"
            tabindex="0"
            :disabled="!isAccept"
            type="button"
            class="btn-primary btn-primary-loading btn-primary"
            @click="acceptSubmit"
          >
            <img src="/images/loading.png" class="loading-img" />Confirm／確認
          </button>
        </div>
      </BlackPopup>
      <!-- end guest-logo -->

      <div
        :class="{ 'guest-page-form': locale, 'guest-page-form-lang': !locale }"
      >
        <!-- Begin OTP form -->
        <div
          class="form-container form-otp-container"
          v-show="shouldShowOtpForm"
        >
          <div class="guest-page-form-header-opt text-center">
            <div class="home">
              <router-link to="/">
                <img src="/images/nvx/nvx-banner-2.png" alt="logo" />
              </router-link>
              <h2>{{ $t('login.title_otp') }}</h2>
            </div>
          </div>
          <div class="guest-page-form-body">
            <!-- OTP -->
            <div class="input-group last">
              <currency-input-otp
                type="text"
                maxlength="6"
                id="otp-field"
                class="form-input"
                :class="{ error: errors.has('otp') }"
                v-model="otp"
                name="otp"
                :placeholder="$t('common.placeholders.otp')"
              />
              <!-- <div
                class="otp-input-fields"
                :class="{ error: errors.has('otp') }"
              >
                <input type="number" class="otp__digit otp__field__1" />
                <input type="number" class="otp__digit otp__field__2" />
                <input type="number" class="otp__digit otp__field__3" />
                <input type="number" class="otp__digit otp__field__4" />
                <input type="number" class="otp__digit otp__field__5" />
                <input type="number" class="otp__digit otp__field__6" />
              </div> -->
              <!-- <div class="otp-input-fields">
                <OtpInput
                  separator=""
                  input-classes="input-otp"
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  @on-change="handleOnCompleteOtp"
                  @on-complete="handleOnCompleteOtp"
                  input-type="number"
                />
              </div> -->

              <div class="invalid-feedback">
                <span v-show="errors.has('otp')">
                  {{ errors.first('otp') }}
                </span>
              </div>
            </div>
            <!-- <div class="message-validate-login-google-otp">
              <div class="invalid-feedback invalid-feedback-otp">
                <span v-show="errors.has('otp')">
                  {{ errors.first('otp') }}
                </span>
              </div>
            </div> -->

            <!-- Lost Google Authentication Redirect -->
            <div class="input-group final">
              <div class="lost-otp" @click.prevent="showModalLostOtp">
                {{
                  $t('shared_components.google_authentication_modal.lost_code')
                }}
              </div>
            </div>

            <!-- Submit Action -->
            <div class="form_actions">
              <button
                :class="{ chrome_only: isChrome }"
                :disabled="isSubmitting"
                type="button"
                class="btn-submit"
                @click="submit"
              >
                {{ getSubmitName($t('account.identity.submit')) }}
              </button>
            </div>
          </div>
        </div>
        <!-- End OTP form -->

        <!-- Begin login form -->
        <div class="form-container" v-show="!shouldShowOtpForm">
          <div class="home">
            <router-link to="/">
              <img src="/images/nvx/nvx-banner-2.png" alt="logo" />
            </router-link>
            <h2>{{ $t('menu.login') }}</h2>
          </div>

          <div class="guest-page-form-body">
            <!-- <div class="input-group">
              <div class=" verify-box">
                <a class="link-primary text-homepage-login" target="_blank" href="/">
                  <span class="icon-network"></span>
                  <span class="link-text-login ">{{ host }}</span>
                </a>
              </div>
            </div> -->

            <!-- Email -->
            <div class="input-group">
              <div class="custom-input">
                <div class="form-input" :class="{ error: errors.has('email') }">
                  <img src="/images/mail-login.svg" alt="mail" />
                  <input
                    v-model="email"
                    :placeholder="$t('common.placeholders.email')"
                    name="email"
                    :maxlength="maxlength"
                    data-vv-validate-on="none"
                    type="text"
                    @focus="resetError"
                  />
                </div>
              </div>

              <div class="invalid-feedback">
                <span v-show="errors.has('email')">
                  {{ errors.first('email') }}
                </span>
              </div>
            </div>

            <!-- Password -->
            <div class="input-group last">
              <div class="custom-input">
                <div
                  class="form-input"
                  :class="{ error: errors.has('password') }"
                >
                  <img src="@/assets/images/grey-lock.svg" alt="lock" />
                  <input
                    v-model="password"
                    :placeholder="$t('common.placeholders.password')"
                    name="password"
                    :maxlength="maxlength"
                    data-vv-validate-on="none"
                    :type="isEyeOpen ? 'text' : 'password'"
                    @focus="resetError"
                  />
                  <img
                    @click="isEyeOpen = !isEyeOpen"
                    :src="`/images/eye-${isEyeOpen ? 'open' : 'close'}.svg`"
                    alt="eye"
                  />
                </div>
              </div>
              <!--              <div class="input-group-prepend"><span class="icon-key2"></span></div>-->

              <div class="invalid-feedback">
                <span v-show="errors.has('password')">
                  {{ errors.first('password') }}
                </span>
                <span v-show="errors.has('login_error')">
                  {{ errors.first('login_error') }}
                </span>
              </div>
            </div>

            <!-- Forgot Password -->
            <div class="input-group forgot-password">
              <router-link class="link-primary" to="/reset-password">{{
                $t('login.forgot')
              }}</router-link>
            </div>

            <!-- Capcha -->
            <div class="input-group final">
              <VueRecaptcha
                id="login-captcha"
                ref="recaptcha"
                :sitekey="GOOGLE_CAPTCHA_KEY"
                @verify="onVerifyCapcha"
                @expired="onExpiredCapcha"
              />
              <div class="invalid-feedback">
                <span v-show="failCapcha === true">
                  {{ $t('common.geetest.fail') }}
                </span>
              </div>
            </div>
            <!-- Submit Action -->
            <div class="form_actions">
              <button
                :class="{
                  chrome_only: isChrome && !errors.any(),
                  disabledBtn: isSubmitting,
                }"
                :disabled="isSubmitting || isWaitingCountDown"
                type="button"
                class="btn-submit btn-primary-loading"
                @click="submit"
              >
                <div v-if="isCountDown">
                  {{ formartSecondToMinute(timeCountDown) }}
                </div>
                <div v-else>
                  {{ getSubmitName($t('login.title')) }}
                </div>
                <img src="/images/loading.png" class="loading-img" />
              </button>
            </div>

            <!-- Register Redirect -->
            <div class="not-account">
              <span>
                {{ $t('login.dont_has_account') }}
              </span>
              <router-link to="/register">{{ $t('menu.signup') }}</router-link>
            </div>
          </div>
        </div>
        <!-- End login form -->
      </div>

      <lost-otp-modal
        name="login_lostOtpForm"
        @DISABLE_OTP_SUCCESS="disableOtpSuccess"
        :contact_email="siteSetting.contact_email"
      />
      <succeed-modal name="login-succeed-modal" :message="modalMessage" />
      <ConfirmationModal
        name="login_confirm_modal"
        class="login_confirm_modal"
      />
      <message></message>
    </div>
    <div class="confirm-email-success" v-if="isConfirmEmailSuccess">
      <div class="content-success">
        <div class="form-container">
          <div class="home">
            <router-link to="/">
              <img src="/images/nvx/nvx-banner-2.png" alt="logo" />
            </router-link>
          </div>
          <img
            class="img-email-success"
            src="@/assets/images/confirm-email-success.svg"
            alt=""
          />
          <h1 class="title">{{ $t('register.congratulation') }}</h1>
          <button @click="isConfirmEmailSuccess = !isConfirmEmailSuccess">
            {{ $t('button.done') }}
          </button>
          <div class="to_login">
            {{ $t('reset_password.back_to') }}
            <router-link to="/">{{ $t('menu.homepage') }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </base-layout>
</template>

<script>
import rf from '@/request/RequestFactory'
import AuthenticationUtils from '@/common/AuthenticationUtils'
import LostOtpModal from '@/components/shared_components/otp/LostOtpModal'
import Message from '@/components/shared_components/common/Message'
import BaseLayout from './BaseLayout.vue'
import SucceedModal from './SucceedModal.vue'
import ConfirmationModal from '@/components/shared_components/common/ConfirmationModal'
import COMMON_CONST from '@/common/Const'
import CurrencyInputOtp from '@/components/shared_components/common/CurrencyInputOtp.vue'
import BlackPopup from '@/components/Popup.vue'
import CookiesUtils from '@/common/CookiesUtils'
import { VueRecaptcha } from 'vue-recaptcha'
import { mapState } from 'vuex'
import OtpInput from '@bachdgvn/vue-otp-input'
import { formartSecondToMinute } from '@/common/Utils'
import Const from '@/common/Const'
const OTP_ERROR = 'invalid_otp'

export default {
  data() {
    return {
      email: '',
      password: '',
      otp: '',
      modalMessage: '',
      shouldShowOtpForm: false,
      isConfirming: false,
      maxlength: COMMON_CONST.MAX_LENGTH_INPUT,
      host: window.location.origin,
      siteSetting: {},
      isChrome: window.navigator.userAgent.toLowerCase().indexOf('chrome') > 0,
      otpRequest: '',
      isOnline: true,
      redirectLink: null,
      locale: true,
      geetestData: '',
      showAcceptPop: false,
      isAccept: true,
      targetUrl: '',
      isEyeOpen: false,
      GOOGLE_CAPTCHA_KEY: process.env.VUE_APP_GOOGLE_CAPTCHA_KEY,
      is_ggcaptcha: 0,
      failCapcha: null,
      isConfirmEmailSuccess: false,

      isWaitingCountDown: false,
      isCountDown: false,
      timeCountDown: 30,
      funcCountDown: null,
      formartSecondToMinute: formartSecondToMinute,
    }
  },
  components: {
    BaseLayout,
    LostOtpModal,
    SucceedModal,
    Message,
    ConfirmationModal,
    CurrencyInputOtp,
    BlackPopup,
    VueRecaptcha,
    OtpInput,
  },
  props: {
    confirmationCode: { type: String, default: '' },
    deviceCode: { type: String, default: '' },
    customRedirect: { type: String, default: null },
  },
  watch: {
    otp: function (newVal) {
      if (window._.size(newVal) === 6) {
        this.submit()
      }
    },
    timeCountDown() {
      if (this.timeCountDown === 0) {
        this.isWaitingCountDown = false
        this.isCountDown = false
        // this.timeCountDown = countDown;
        clearInterval(this.funcCountDown)
      }
    },
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
    }),
    isSubmitable() {
      return (
        !this.isSubmitting &&
        !this.isConfirming &&
        (!this.shouldShowOtpForm || this.otp.length >= 6)
      )
    },
  },
  methods: {
    handleOnCompleteOtp(otp) {
      this.otp = otp
    },
    onVerifyCapcha(response) {
      this.failCapcha = false
      this.geetestData = response
      this.is_ggcaptcha = 1
    },
    onExpiredCapcha() {
      this.failCapcha = true
      this.geetestData = ''
      this.is_ggcaptcha = 1
    },
    resetError() {
      this.errors.clear()
      this.failCapcha = false
    },
    openGeetestLink() {
      const url = '/authorization'
      window.open(url, '_blank')
      return
    },
    confirm() {
      if (this.isSubmitting) {
        return
      }
      this.isConfirming = true
      this.startSubmit()
      rf.getRequest('UserRequest')
        .confirm(this.confirmationCode)
        .then((response) => {
          this.isConfirming = false
          this.isConfirmEmailSuccess = true
          this.endSubmit()
          // this.showConfirmedModal(true)
        })
        .catch((error) => {
          this.isConfirming = false
          this.endSubmit()
          if (this.isOnline) {
            this.showConfirmedModal(false)
          } else {
            $('#confirmationModal').modal('hide')
            this.showModalIsOffline()
          }
        })
    },
    activeDevice() {
      if (this.isSubmitting) {
        return
      }
      this.startSubmit()
      rf.getRequest('UserRequest')
        .grantAccessDevice(this.deviceCode)
        .then((response) => {
          this.endSubmit()
          this.showActiveDeviceModal(response.data.result || {})
        })
        .catch((error) => {
          this.endSubmit()
          if (this.isOnline) {
            this.showActiveDeviceModal(false)
          } else {
            $('#confirmationModal').modal('hide')
            this.showModalIsOffline()
          }
        })
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    async submit() {
      this.resetError()

      if (_.isEmpty(this.email)) {
        this.errors.add({
          field: 'email',
          msg: this.$t('validation.auth.email_required'),
        })
      }
      if (!this.validateEmail(this.email)) {
        this.errors.add({
          field: 'email',
          msg: this.$t('validation.auth.email_format'),
        })
      }
      if (_.isEmpty(this.password)) {
        this.errors.add({
          field: 'password',
          msg: this.$t('validation.auth.password_required'),
        })
      }
      if (this.password.length > 72) {
        this.errors.add({
          field: 'password',
          msg: this.$t('validation.auth.password_max'),
        })
      }

      if (!this.shouldShowOtpForm) {
        this.failCapcha = !this.geetestData
      } else {
        if (!this.otp.length) {
          this.errors.add(
            'otp',
            this.$t('funds.withdrawals.errors.otp_required')
          )
        } else if (this.otp.length < 6) {
          this.errors.add(
            'otp',
            this.$t('funds.withdrawals.errors.otp_length_6_digits')
          )
        }
      }

      if (!this.isSubmitable || this.failCapcha || this.errors.any()) {
        return
      }

      this.submitReq()
    },
    acceptSubmit() {
      this.showAcceptPop = false
      window.location.href = this.targetUrl
      CookiesUtils.saveItem(COMMON_CONST.LOGIN_ACCEPT_AGREEMENT, true)
    },
    blackPopClick() {
      this.acceptSubmit()
    },
    submitReq() {
      let acceptAgreement = !!CookiesUtils.getItem(
        COMMON_CONST.LOGIN_ACCEPT_AGREEMENT
      )
      this.resetError()
      this.startSubmit()
      this.otpRequest = this.otp

      // const redirectRouteName = this.$route.query.return_to;
      const redirectRouteName = this.redirectLink
      rf.getRequest('UserRequest')
        .login(
          this.email,
          this.password,
          this.otpRequest,
          this.geetestData,
          this.is_ggcaptcha,
          redirectRouteName
        )
        .then(async (response) => {
          this.endSubmit()
          let locale = response.locale
          response.locale = window.localStorage.getItem('locale') || locale
          AuthenticationUtils.saveAuthenticationData(response)
          const defaultRouteName = 'Landing Exchange'
          const destinationRouteName = this.$route.query.destination
          let urlSearch = new URLSearchParams(window.location.search)
          const returnTo = urlSearch.get('return_to')
          const redirectRouteName =
            response.redirectUrl || destinationRouteName || 'Landing Exchange'
          let targetUrl = ''
          if (returnTo == 'zendesk') {
            targetUrl = redirectRouteName
          } else {
            targetUrl = this.$router.resolve({ name: redirectRouteName }).href
          }
          if (this.customRedirect) {
            targetUrl = this.$router.resolve({
              name: this.customRedirect,
            }).href
          }
          if (targetUrl == '/') {
            // Default redirect to Sale Point
            targetUrl = this.$router.resolve({ name: defaultRouteName }).href
          }
          this.targetUrl = targetUrl
          window.localStorage.removeItem('Trade_popup')
          if (window.localStorage.locale === 'ja' && !acceptAgreement) {
            this.showAcceptPop = true
          } else {
            if (response.is_gauth_active) {
              window.location.href = this.$router.resolve({
                name: 'Spot Exchange',
                params: { coin: 'btc', currency: 'idr' },
              }).href
            } else {
              if (
                this.email.toLocaleLowerCase() === 'rofi.sudiyono@nvx.co.id' ||
                this.email.toLocaleLowerCase() === 'laila.tes22@gmail.com'
              ) {
                window.location.href = this.$router.resolve({
                  name: 'Spot Exchange',
                  params: { coin: 'btc', currency: 'idr' },
                }).href
              } else {
                window.location.href = this.$router.resolve({
                  name: 'RegisterGoogleAuth',
                }).href
              }
            }
          }
        })
        .catch((error) => {
          if (this.isOnline) {
            this.endSubmit()
            this.showAcceptPop = false
            const isOtpError = error.response.data?.error === OTP_ERROR

            if (error.response.data.errors == -1) {
              this.timeCountDown = error.response.data.data.waiting_time
              let attemptCount = error.response.data.data.attempt_count
              let maxAttempt = error.response.data.data.max_attempt

              if (attemptCount >= maxAttempt) {
                this.isCountDown = true
                this.isWaitingCountDown = true
                this.funcCountDown = setInterval(() => {
                  this.timeCountDown -= 1
                }, 1000)
              }
            }

            if (this.shouldShowOtpForm || !isOtpError) {
              if (error.response.data.error == 'geetest_failed') {
                this.errors.add('invalid_captcha', 'Captcha is invalid.')
              } else {
                this.errors.add(
                  isOtpError ? 'otp' : 'login_error',
                  this.$t(error.response.data.message)
                )
                switch (error.response.data.error) {
                  case 'invalid_grant':
                    this.errors.items[0].msg = this.$t('auth.failed_login')
                    break
                  case 'invalid_device':
                    this.errors.items[0].msg = this.$t('account.invalid_device')

                    break
                  case 'invalid_otp':
                    this.errors.items[0].msg = this.$t(
                      'validation.otp_incorrect'
                    )
                    break
                  default:
                    break
                }
              }
            }

            this.shouldShowOtpForm = isOtpError
          } else {
            $('#confirmationModal').modal('hide')
            this.showModalIsOffline()
          }
        })
    },
    acceptClick(e) {
      this.isAccept = e.target.checked
    },
    onGeetestUpdated(data) {
      this.geetestData = data
    },
    showModalLostOtp() {
      const data = {
        email: this.email,
        password: this.password,
      }
      window.CommonModal.show('login_lostOtpForm', { data: data })
    },
    showConfirmedModal(isSucceed) {
      this.modalMessage = isSucceed
        ? this.$t('login.confirm_success')
        : this.$t('login.confirm_failed')
      isSucceed
        ? this.showModal('confirm-succeed-modal2', this.modalMessage)
        : this.showModal('confirm-fail-modal', this.modalMessage)
    },
    showActiveDeviceModal(result) {
      this.modalMessage = result
        ? this.$t('login.active_device_success', result)
        : this.$t('login.active_device_failed')
      result
        ? this.showModal('confirm-succeed-modal', this.modalMessage)
        : this.showModal('confirm-succeed-modal2', this.modalMessage)
    },
    disableOtpSuccess(message) {
      this.shouldShowOtpForm = false
      this.otp = ''
    },
    showModal(type, message) {
      window.ConfirmationModal.show({
        type: type,
        title: message,
        content: '',
        btnCancelLabel: null,
        btnConfirmLabel: null,
        onConfirm: () => {},
        onCancel: () => {},
        widthModal: '381px',
      })
    },
    showModalIsOffline() {
      $('#confirmationModal').modal('hide')
      let type = 'connection-internet-fail',
        message = this.$t('client_connection.fail')
      this.showModal(type, message)
    },
    initData() {
      window._.each(this.masterdata.settings, (item) => {
        this.siteSetting[item.key] = item.value
      })
    },
    handleInternetConnection(status) {
      this.isOnline = status
      if (!status) {
        this.showModalIsOffline()
      }
    },
  },
  mounted() {
    let lang = window.localStorage.getItem('locale')
    if (lang == 'ja' || lang == 'ko') this.locale = false
    window.addEventListener('keyup', (event) => {
      if (event.key === 'Enter' && this.$route.name == 'Login') {
        this.submit()
      }
    })
    if (this.confirmationCode) {
      return this.confirm()
    }
    if (this.deviceCode) {
      return this.activeDevice()
    }
    window.addEventListener('online', (event) => {
      this.handleInternetConnection(true)
    })
    window.addEventListener('offline', (event) => {
      this.handleInternetConnection(false)
    })
    this.initData()
  },
  created() {
    let urlSearch = new URLSearchParams(window.location.search)
    let redirectRouteName = urlSearch.get('return_to')
    if (redirectRouteName) {
      this.redirectLink = redirectRouteName
      this.$router.replace({ name: 'LoginZendesk' })
    }
  },
}
</script>

<style lang="scss" scoped>
@include mobile {
  body.popupview #popup-inner {
    overflow: auto !important;
  }
}

.background-login {
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mobile {
    max-height: 100dvh;
    overflow: hidden;
  }
}

.home {
  margin-bottom: 32px;

  a {
    display: flex;
    justify-content: center;

    img {
      width: 98px;
    }
  }

  h2 {
    margin-top: 32px;
    margin-bottom: 0;
    text-align: center;
    color: $text-main2;
    font-size: 36px;
    font-weight: 700;
    line-height: 56px;
  }

  @include mobile {
    margin-bottom: 24px;

    h2 {
      font-size: 24px;
      line-height: 40px;
    }
  }
}

#login-captcha {
  display: flex;
  justify-content: center;
}

.confirm-email-success {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background-color: #ffffff;

  .content-success {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

    .form-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 32px;

      .img-email-success {
        width: 137px;

        @include mobile {
          width: 100px;
        }
      }

      .title {
        color: #000;
        text-align: center;
        font-size: 36px;
        font-weight: 700;
        line-height: 56px;
        margin: 0;
      }

      button {
        @include btn-common(contained, lg);
      }

      to_home {
      }
    }
  }
}

:deep {
  .modal-dialog {
    @include mobile {
      width: 100% !important;
      padding: 0 24px;
      max-width: 414px;
    }
  }

  .otp-input-fields {
    > div {
      display: flex;
      justify-content: center;
      gap: 8px;
      margin-bottom: 8px;
    }

    input {
      height: 52px;
      width: 44px;
      border-radius: 9px;
      background: #f0f0f0 !important;
      border: 2px solid #3e7dbe !important;
      text-align: center;
      outline: none;
      font-size: 16px;
      color: #000000;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &.error {
      input {
        border: 2px solid #eb1c24 !important;
      }
    }
  }
}

input,
input:-internal-autofill-selected {
  background: none !important;
}

.form-input {
  width: 100%;

  > img {
    position: relative !important;

    &:first-child {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 10px;
    }
  }
}

.form_actions {
  margin-bottom: 10px;

  .btn-submit {
    @include btn-common(contained, lg);
  }
}

.not-account {
  text-align: center;

  span {
    color: $text-main4;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  a {
    font-weight: 700;
    color: $text-main2;
    font-size: 14px;
    line-height: 24px;
  }
}

.input-group {
  margin-bottom: 24px;

  @include mobile {
    margin-bottom: 16px;
  }

  &.forgot-password {
    text-align: right;
  }

  &.final {
    margin: 24px 0;

    .lost-otp {
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      color: rgba($text-main2, 0.87);
      cursor: pointer;
    }
  }

  input {
    &::placeholder {
      color: $text-main-blur !important;
    }
  }

  .form-input {
    &.error {
      border: 1px solid $color-red !important;
      background: rgba(255, 91, 91, 0.1) !important;
    }

    input {
      background: transparent;
    }
  }
}

.input-group-prepend {
  span {
    line-height: 50px;
  }
}

.verify-box {
  border-radius: 3px;
  height: 45px;
  text-align: center;
  border: $border-white;
  padding-left: 0 !important;
  background-color: $pure_color_black;
  line-height: 30px;

  .icon-home2 {
    padding-right: 10px;
  }

  a {
    position: relative;
    top: 5%;
    transform: perspective(1px) translateY(-50%);
  }
}

.text-homepage-login {
  display: flex;
  /* text-align: center; */
  align-items: center;
  justify-content: center;

  margin: 20px;

  .link-text-login {
    font-size: $font-medium;
    line-height: 40px;
    color: $pure_color_white;
    font-weight: normal;
    align-items: center;
    justify-content: center;
  }

  .icon-network {
    color: $pure_color_white;
    font-size: $font-big-24;
    font-weight: normal;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
}

.form-container {
  width: 400px;
  max-width: 100vw;

  @include mobile {
    padding: 0 16px;
  }

  &.form-otp-container {
  }

  .btn-primary {
    margin-top: 0;
    font-size: 18px;
    border-color: $primary-0;
  }
}

.title-l {
  margin: 13px 0px 15px 0px;
  height: 1px;
  width: 55%;
  border-radius: 5px;
  background-color: $color-light-grey;
}

.left {
  float: left;
}

.f-13 {
  font-size: $font-small;
  font-weight: normal !important;
}

.title-account {
  font-family: $helvetica;
  margin: 0 0 20px;
  font-size: $font-big-20;
}

.img-network-login {
  height: 25px;
  margin: 0px 15px;
}

.link-primary {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 16px;
  color: $text-main2;
}

.text-center-opt span {
  width: 71px;
}

//LOGIN GOOGLE AUTHENTICATION CODE
.guest-page-form-header-opt {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  .title-otp {
    margin: 0;
    margin-bottom: 28px;
    padding-bottom: 0px;
    font-family: $helvetica;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: $dark-1;

    @include mobile {
      font-size: 20px;
      line-height: 25px;
    }
  }
}

.login_box.otp a {
  font-weight: 500 !important;
}

.login_box.login {
  @media (max-width: 340px) {
    a,
    label.form-check-label.text-normal {
      font-size: 14px !important;
    }
  }
}

.hr-input {
  height: 4px;
  margin: 0;
  border: 0;
  border-bottom: 1px solid green;
}

.form-check-label {
  margin-bottom: 0px;
}

//LOGIN GOOGLE AUTHENTICATION CODE
input {
  &:focus,
  &:active {
    border-bottom: 1px solid $border-jungle-green;
  }

  &.error {
    border: 1px solid $border-alizarin-crimson !important;
  }
}

.to_login {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: $text-main6;
  text-align: center;
  margin-bottom: 16px;

  a {
    font-weight: 700;
    color: $text-main2;
  }
}

.count-down {
  margin: 15px 0;
  text-align: center;
}
</style>
<style lang="scss">
.login_confirm_modal {
  .modal-content {
    .btn-confirm {
      width: auto;
    }
  }
}
</style>
