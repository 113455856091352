import BaseModelRequest from './base/BaseModelRequest'

export default class UserRequest extends BaseModelRequest {
  getModelName() {
    return 'users'
  }

  preVerifyCaptcha() {
    return this.get('/captcha/pre-verify')
  }

  login(email, password, otp = '', geetestData = {}, is_ggcaptcha, redirectUrl) {
    let params = {
      grant_type: 'password',
      client_id: process.env.VUE_APP_MIX_CLIENT_ID,
      client_secret: process.env.VUE_APP_MIX_CLIENT_SECRET,
      username: email,
      password: password,
      scope: '*',
      otp: otp,
      geetestData: geetestData,
      is_ggcaptcha: is_ggcaptcha,
      redirectUrl: redirectUrl,
    }
    return this.post('/oauth/token', params)
  }

  register(
    { email, password, passwordConfirmation, phone_no, referralCode = '' },
    geetestData = {},
    is_ggcaptcha
  ) {
    let params = {
      email: email,
      phone_no,
      password: password,
      password_confirmation: passwordConfirmation,
      agree_term: 1,
      referrer_code: referralCode,
      geetestData: geetestData,
      is_ggcaptcha: is_ggcaptcha,
    }
    return this.post('/users', params)
  }

  confirm(code) {
    return this.post('/confirm-email', { code })
  }

  confirmSMS(code, phone_no) {
    return this.post('/confirm-sms', { code, phone_no })
  }

  resendSMS(params = {}) {
    return this.post('/user/reconfirm-sms', params)
  }

  resendWA(params = {}) {
    return this.post('/user/reconfirm-wa', params)
  }

  resendConfirmSMS({ phone_no }) {
    return this.post('/reconfirm-sms', { phone_no })
  }

  resendConfirmWA({ phone_no }) {
    return this.post('/reconfirm-wa', { phone_no })
  }

  confirmResetPassword(token) {
    return this.post('/confirm-token', { token })
  }
  resendConfirm({ email }) {
    let params = {
      email: email,
    }
    return this.post('/reconfirm-email', { params })
  }

  resetPassword(email) {
    return this.post('/reset-password', { email })
  }

  executeResetPassword({ email, password, passwordConfirmation, token }) {
    let params = {
      email,
      token,
      password,
      password_confirmation: passwordConfirmation,
    }
    return this.post('/execute-reset-password', params)
  }

  getCurrentUser(useCache = true, params) {
    if (this.user && useCache) {
      return new Promise((resolve, reject) => {
        resolve(this.user)
      })
    }

    return new Promise((resolve, reject) => {
      let url = '/user'
      var self = this
      this.get(url, params)
        .then(function (user) {
          self.user = user
          resolve(user)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  getBalance(useCache = true, store) {
    if (useCache && window.GlobalSocket) {
      if (window.GlobalSocket.balance) {
        return new Promise((resolve, reject) => {
          const balance = store
            ? window.GlobalSocket.balance.data[store]
            : window.GlobalSocket.balance.data
          resolve({ success: true, data: balance })
        })
      }
    }

    return new Promise((resolve, reject) => {
      let url = '/balances'
      var self = this
      this.get(url)
        .then(function (res) {
          window.GlobalSocket.balance = res
          const balance = store
            ? window.GlobalSocket.balance.data[store]
            : window.GlobalSocket.balance.data
          resolve({ success: true, data: balance })
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  convertSmallBalance(params) {
    return new Promise((resolve, reject) => {
      let url = '/convert-small-balance'
      var self = this
      this.post(url, params)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  transferBalance(params) {
    return new Promise((resolve, reject) => {
      let url = '/transfer-balance'
      var self = this
      return this.post(url, params)
        .then(function (res) {
          resolve(res)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  getTotalSupply() {
    let url = '/total-supply'
    return this.get(url)
  }

  getReferralSetting() {
    let url = '/setting-referral'
    return this.get(url)
  }

  getLinkPnlChart(params) {
    let url = '/create-pnl-token'
    return this.post(url, params)
  }

  getDetailsBalance(currency, store) {
    let url = '/balance/' + currency
    return this.get(url, { store })
  }

  getDetailsUsdBalance(currency, store) {
    let url = '/balance-usd/' + currency
    return this.get(url, { store })
  }

  getSecuritySettings(params) {
    let url = '/security-settings'
    return this.get(url, params)
  }

  createUserQrcode(params) {
    let url = '/create-user-qrcode'
    return this.post(url, params)
  }

  registerBetaTester(params) {
    let url = '/register-beta-tester'
    return this.post(url, params)
  }

  getUserNotificationSettings(params) {
    let url = '/user-notification-settings'
    return this.get(url, params)
  }

  getUserSettings(params) {
    let url = '/user-settings'
    return this.get(url, params)
  }

  getUserKyc(params) {
    let url = '/user-kyc'
    return this.get(url, params)
  }

  getTopUserReferralCommission() {
    let url = '/top-user-referral-commission'
    return this.get(url)
  }

  getUserReferralCommission(params) {
    let url = '/user-referral-commission'
    return this.get(url, params)
  }
  activeWhitelist(params) {
    let url = '/user-whitelist'
    return this.put(url, params)
  }

  activeLeaderBoard(params) {
    let url = '/user/use-fake-name'
    return this.put(url, params)
  }

  getOrderBookSettings(params) {
    let url = '/order-book-settings'
    return this.get(url, params)
  }

  getUserReferralFriends(params) {
    let url = '/get-user-referral-friends'
    return this.get(url, params)
  }

  getAllReferrer(params) {
    let url = '/get-all-referrer'
    return this.get(url, params)
  }

  getTotalReferrer(params) {
    let url = '/get-total'
    return this.get(url, params)
  }

  updateOrderBookSettings(params) {
    let url = '/order-book-settings'
    return this.put(url, params)
  }

  getDeviceRegister() {
    let url = '/devices'
    return this.get(url)
  }

  getUserDevice(params) {
    let url = '/user-devices'
    return this.get(url, params)
  }

  updateRestrictMode(params) {
    let url = '/restrict-mode'
    return this.put(url, params)
  }

  deleteDeviceAccount(id) {
    let url = '/device/' + id
    return this.del(url)
  }

  grantAccessDevice(id) {
    let url = '/device/' + id
    return this.post(url)
  }

  getHistoryConnection(params) {
    let url = '/connections'
    return this.get(url, params)
  }

  getQRCodeGoogleUrl() {
    let url = '/key-google-authen'
    return this.get(url)
  }

  addSecuritySettingOtp(authentication_code) {
    let url = '/add-security-setting-otp'
    return this.put(url, authentication_code)
  }

  verify(params) {
    let url = '/verify-google-authenticator'
    return this.get(url, params)
  }

  getWithdrawDaily(currency) {
    let url = '/transactions/withdraw-daily'
    return this.get(url, { currency: currency })
  }

  getWithdrawFiatDaily() {
    let url = '/transactions/withdraw-daily-fiat'
    return this.get(url)
  }

  getWithdrawalAddresses(params) {
    let url = '/withdrawal-address'
    return this.get(url, params)
  }

  getWithdrawalsAddresses(params) {
    let url = '/withdrawals-address'
    return this.get(url, params)
  }

  updateOrCreateWithdrawalAddress(params) {
    let url = '/withdrawal-address'
    return this.post(url, params)
  }

  generateDepositAddress(params) {
    let url = '/deposit-address'
    return this.put(url, params)
  }

  getDepositAddress(params) {
    let url = '/deposit-address'
    return this.get(url, params)
  }

  changePassword(params) {
    let url = '/change-password'
    return this.put(url, params)
  }

  delGoogleAuth(params) {
    let url = '/google-auth'
    return this.del(url, params)
  }

  delRecoveryCodeWithAuth(params) {
    let url = '/del-recovery-code-with-auth'
    return this.del(url, params)
  }

  setUserLocale(params) {
    let url = '/locale'
    return this.put(url, params)
  }

  getInfoToAuthenticate() {
    let url = '/phone_verification_data'
    return this.get(url)
  }

  verifyBankAccount(params) {
    let url = '/bank-account'
    return this.put(url, params)
  }

  sendSmsOtp() {
    let url = '/send-sms-otp'
    return this.post(url)
  }

  disableOtpAuthentication(params) {
    let url = '/disable-otp-authentication'
    return this.del(url, params)
  }

  getWithdrawalLimit(params) {
    let url = '/withdrawal-limit'
    return this.get(url, params)
  }

  getDepositLimit(params) {
    let url = '/deposit-limit'
    return this.get(url, params)
  }

  downloadReportFriend(params, fileName) {
    const url = '/referral/friends/export'
    this.download(url, params, fileName)
  }

  downloadReportCommission(params, fileName) {
    const url = '/referral/commission/export'
    this.download(url, params, fileName)
  }

  otpVerify(params) {
    let url = '/otp-verify'
    return this.post(url, params)
  }

  createIdentity(params) {
    let url = '/create-identity'
    return this.post(url, params)
  }
  handleMsgDigisign(params) {
    let url = '/digisign/handling'
    return this.get(url, params)
  }

  updateBankAccountStatus(params) {
    let url = '/update-status-user-kyc'
    return this.post(url, params)
  }

  changeTelegramNotification(params) {
    let url = '/change-telegram-notification'
    return this.post(url, params)
  }

  changeEmailNotification(params) {
    let url = '/change-email-notification'
    return this.post(url, params)
  }

  changeLineNotification(params) {
    let url = '/change-line-notification'
    return this.post(url, params)
  }

  encryptId(params) {
    let url = '/encrypt-id'
    return this.post(url, params)
  }

  sendNotify(message) {
    return this.post('/send-notification', { message })
  }
  changeAmlPay(params) {
    let url = '/change-aml-pay'
    return this.post(url, params)
  }

  getSalesPointBalance(useCache = true) {
    if (useCache && window.GlobalSocket) {
      if (window.GlobalSocket.balance) {
        return new Promise((resolve, reject) => {
          resolve(window.GlobalSocket.balance)
        })
      }
    }

    return new Promise((resolve, reject) => {
      let url = '/balances'
      var self = this
      this.get(url)
        .then(function (res) {
          window.GlobalSocket.balance = res
          resolve(res)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  getUserWithdrawalSetting(params) {
    let url = '/user-withdrawal-setting'
    return this.get(url, params)
  }

  getUserApiSettings(params) {
    let url = '/hmac-tokens'
    return this.get(url, params)
  }

  createUserApiSettings(params) {
    let url = '/hmac-tokens'
    return this.post(url, params)
  }

  updateUserApiSettings(params) {
    const { id } = params
    let url = '/hmac-tokens/' + id
    return this.put(url, params)
  }

  deleteOneApiSettings(params) {
    const { id, otp } = params
    let url = '/hmac-tokens/' + id + '?otp=' + otp
    return this.del(url, params)
  }

  deleteAllApiSettings(params) {
    const { otp } = params
    let url = '/hmac-tokens/all?otp=' + otp
    return this.del(url, params)
  }

  getUserPairTradingSetting(params) {
    let url = '/user-pair-trading-setting'
    return this.get(url, params)
  }
  changeTypeWalletAmalPayFee(params) {
    let url = '/change-wallet-amal-fee'
    return this.get(url, params)
  }
  getDividendSetting() {
    let url = '/get-dividend-settings'
    return this.get(url)
  }
  changeAnimationStatus(params) {
    let url = '/margin/animation'
    return this.post(url, params)
  }
  getAnimationStatus() {
    let url = '/margin/animation'
    return this.get(url)
  }

  onUpdateFakeName(data) {
    let url = '/user-update-fake-name'
    return this.put(url, data)
  }

  getFeeTierStatus() {
    let url = '/fee-tier/status-check'
    return this.get(url)
  }
  getFeeTier() {
    let url = '/fee-tier/personal-fee-tier'
    return this.get(url)
  }
  getFeeTierDalyVolumeHistory(params) {
    let url = '/fee-tier/daily-volume-history'
    return this.get(url, params)
  }
  getFeeTierTradeSpotHistory(params) {
    let url = '/fee-tier/trade-spot-history'
    return this.get(url, params)
  }
  getFeeTierTradeFutureHistory(params) {
    let url = '/fee-tier/trade-future-history'
    return this.get(url, params)
  }
  getFeeTierDetail(params) {
    let url = '/fee-tier/detail'
    return this.get(url, params)
  }
  exportDailyVolumeHistory(params, fileName) {
    const url = '/fee-tier/daily-volume-history/export'
    this.download(url, params, fileName)
  }
  exportTradeSpotHistory(params, fileName) {
    const url = '/fee-tier/trade-spot-history/export'
    this.download(url, params, fileName)
  }
  exportTradeFutureHistory(params, fileName) {
    const url = '/fee-tier/trade-future-history/export'
    this.download(url, params, fileName)
  }
  addBankAccount(params) {
    let url = '/bank-account-manage/create'
    return this.post(url, params)
  }
  getBankAccount() {
    let url = '/bank-account-manage'
    return this.get(url)
  }
  removeBankAccount(id) {
    let params = {
      id: id,
    }
    let url = '/bank-account-manage/remove'
    return this.post(url, params)
  }
  getSlipperySetting() {
    let url = '/slippery'
    return this.get(url)
  }
  createOrUpdateSlippery(params) {
    let url = '/slippery/update-or-create'
    return this.post(url, params)
  }
  getSlipperyNotification() {
    let url = '/slippery/notification'
    return this.get(url)
  }
  updateNotification(data) {
    let url = '/slippery/notification'
    return this.put(url, data)
  }
  clearAllNotification() {
    let url = '/slippery/notification-clear-all'
    return this.del(url)
  }
  clearNotification(params) {
    let url = '/slippery/notification-clear'
    return this.del(url, params)
  }
  checkAddressInternal(currency, address, tag) {
    let url = `/check-address-internal?currency=${currency}&address=${address}`
    if (tag) {
      url += `|${tag}`
    }
    return this.get(url)
  }
  validateBlockchainAddress(currency, blockchain_address) {
    const url = `/validate-blockchain-address?currency=${currency}&blockchain_address=${blockchain_address}`
    return this.get(url)
  }
  getTravelRuleLimit() {
    let url = '/travel-rule'
    return this.get(url)
  }
  getTravelRuleRatePrice(coin) {
    let url = `/travel-rule/price?coin=${coin}`
    return this.get(url)
  }
  registerDevice(deviceToken) {
    let url = '/create-device'
    return this.post(url, { device: deviceToken })
  }
  deleteDevice(deviceToken) {
    let url = '/delete-device'
    return this.del(url, { device: deviceToken })
  }

  // New
  createLog(params) {
    let url = '/user/log'
    return this.post(url, params)
  }

  changePhone(params) {
    let url = '/change-phone'
    return this.put(url, params)
  }
}
