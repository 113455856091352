var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"otp-box"},[_c('p',{staticClass:"otp-title",domProps:{"innerHTML":_vm._s(
      _vm.$t('funds.withdrawals.otp.notify.title', {
        token: _vm.token,
        phone_no: _vm.maskPhoneNumberFunc(),
      })
    )}}),_c('div',{staticClass:"otp-box-header",staticStyle:{"margin-bottom":"0px"}},[_c('p',{staticClass:"otp-box-header_left"},[_vm._v(" "+_vm._s(_vm.$t('funds.withdrawals.otp.verify.title'))+" ")]),_c('p',{staticClass:"otp-box-header_right"},[_c('CountDown',{ref:"countDown",staticClass:"bold",attrs:{"duration":_vm.duration},on:{"end":_vm.handleCountdownEnd}}),_c('span',{staticClass:"resend-sms__btn",class:{
          link__active: _vm.isActiveResendOtp,
          text__active: _vm.isActiveResendOtp,
        },on:{"click":_vm.onResendOtp}},[_vm._v("  "+_vm._s(_vm.$t('funds.withdrawals.otp.resend.titleWa'))+" ")])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowResendSms && _vm.apiUrl !== 'https://dev-api.nvx.co.id'),expression:"isShowResendSms && apiUrl !== 'https://dev-api.nvx.co.id'"}]},[_c('ResendOtpVue',{attrs:{"isDisable":!_vm.isActiveResendOtp},on:{"button-resend-otp":_vm.onResendOtpSMS}})],1),_c('div',{staticClass:"otp-input",staticStyle:{"margin-top":"16px"}},[_c('div',{staticStyle:{"flex":"1","width":"100%"}},[_c('div',{ref:"otpCont",class:{ error: _vm.error },attrs:{"id":"otp-input-fields"}},[_c('div',{staticClass:"otp-input-container",staticStyle:{"display":"flex !important","flex-direction":"row","width":"100%"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.autoOtpSMS),expression:"autoOtpSMS"}],staticStyle:{"width":"100%"},attrs:{"type":"text","autocomplete":"one-time-code","inputmode":"numeric","maxlength":"6"},domProps:{"value":(_vm.autoOtpSMS)},on:{"keypress":function($event){return _vm.isNumber($event)},"input":function($event){if($event.target.composing)return;_vm.autoOtpSMS=$event.target.value}}})])]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.error),expression:"error"}],staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.error)+" ")])]),_c('button',{staticClass:"btn btn-otp-submit",attrs:{"disabled":_vm.isOnSubmit},on:{"click":_vm.onSubmitOtp}},[_vm._v(" "+_vm._s(_vm.$t('funds.withdrawals.otp.verify.submit'))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }