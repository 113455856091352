<template>
  <base-layout>
    <confirmation-modal linkOnCancel="Landing Page"></confirmation-modal>
  </base-layout>
</template>

<script>
import rf from "@/request/RequestFactory";
import RemoveErrorsMixin from "@/common/RemoveErrorsMixin";
import BaseLayout from "../auth/BaseLayout.vue";
import ConfirmationModal from "@/components/shared_components/common/ConfirmationModal";

export default {
  data() {
    return {
      email: "",
      password: "",
      passwordConfirmation: "",
      modalMessage: "",
      host: window.location.origin,
      isConfirming: false,
      isshow: false,
      heightModal: "",
      withModal: "",
    };
  },
  mixins: [RemoveErrorsMixin],
  components: {
    BaseLayout,
    ConfirmationModal,
  },
  props: {
    token: { type: String, default: "" },
    coin: { type: String, default: "" },
  },

  methods: {
    async confirm() {
      this.heightModal = "";
      this.isConfirming = true;
      if (this.isSubmitting) {
        return;
      }
      this.startSubmit();
      try {
        await rf.getRequest("TransactionRequest").confirmWithdraw(this.token);
        this.confirmWithdrawal("confirm-withdrawal-transaction");
      } catch (e) {
        this.invalidWithdrawal("confirm-fail-modal");
      }
    },

    confirmWithdrawal(message) {
      window.ConfirmationModal.show({
        type: message,
        title: this.$t("funds.withdrawals.confirm_withdrawal"),
        btnCancelLabel: null,
        btnConfirmLabel: null,
        widthBtnConfirm: "296px",
        onConfirm: () => {},
        onCancel: () => {},
        widthModal: "380px",
      });
    },

    invalidWithdrawal(message) {
      window.ConfirmationModal.show({
        type: message,
        title: this.$t("login.confirm_failed"),
        content: "",
        btnCancelLabel: null,
        btnConfirmLabel: null,
        onConfirm: () => {},
        onCancel: () => {},
        widthModal: "414px",
      });
    },
  },
  mounted() {
    if (this.token) {
      return this.confirm();
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/common.scss";

.form-input {
  display: block;
  width: 100%;
  height: 42px;
  line-height: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
  color: $color-grey-dusty;
  outline: none;
  box-shadow: none;

  &:focus,
  &:active {
    border-bottom: 1px solid $border-jungle-green !important;
  }

  &.error {
    border-bottom: 1px solid $border-alizarin-crimson !important;
  }
}

.invalid-feedback {
  //padding-top: 7px;
}

.input-group-prepend {
  span {
    line-height: 50px;
  }
}

.pt-20 {
  padding-top: 10px;
}

.verify-box {
  border-radius: 3px;
  height: 50px;
  text-align: center;
  border: 1px solid $color-green;
  padding-left: 0 !important;
  background-color: $color-dark-blue;
  line-height: 30px;

  .icon-home2 {
    padding-right: 10px;
  }

  a {
    position: relative;
    top: 9%;
    transform: perspective(1px) translateY(-50%);
  }
}

.btn-primary {
  height: 40px;
}

.title {
  text-transform: uppercase;
}

.btn-primary {
  height: 50px;
  border-radius: 3px;
  background-color: $background-jungle-green;
  border-color: $border-jungle-green;
  font-family: $font-family-roboto-bold;
  font-size: $font-root;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  letter-spacing: 1.1px;
  text-align: center;
  color: $text-white;
  text-transform: uppercase;

  &:disabled {
    background-color: $background-bright-gray !important;
    border-color: $border-bright-gray !important;
    color: $text-default !important;
  }

  &:hover:enabled {
    background-color: $background-blue-custom !important;
    border-color: $border-blue-custom !important;
    color: $text-white !important;
  }
}

.form-input {
  display: block;
  width: 100%;
  height: 42px;
  line-height: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0px;
  padding-right: 0px;
  color: $color-grey-dusty;
  font-size: $font-medium;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: normal;
  border-radius: none;
  background-color: $background-default;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 1px solid $border-alto;
  outline: none;
  box-shadow: none;

  .form-input-password {
    &:focus,
    &:active {
      border-bottom: 1px solid $border-jungle-green !important;
    }

    &.error {
      border-bottom: 1px solid $border-alizarin-crimson !important;
    }
  }
}

.title-account {
  width: 60%;
  font-family: Roboto;
  color: $color-eden !important;
  font-size: $font-big-20;
  margin: 0 0 20px;
}

.title-l {
  margin: 13px 0px 15px 0px;
  height: 1px;
  width: 30%;
  border-radius: 5px;
  background-color: $color-light-grey;
}

.email-reset {
  font-family: $font-family-roboto;
  font-size: $font-root;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  letter-spacing: normal;
  color: $text-default;
  font-weight: normal;

  &:focus,
  &:active {
    color: $text-default;
    border-bottom: 1px solid $border-alto !important;
  }
}

.text-homepage-login {
  text-decoration: none;
  background-color: $color-dark-blue;
  border: 1px solid $color-green;
  display: flex;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: 50px;

  // margin: 20px;
  .link-text-login {
    font-size: $font-medium;
    line-height: 40px;
    color: $color-jungle-green;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    font-family: $font-family-roboto-medium;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .icon-network {
    color: $color-jungle-green;
    font-size: 24px;
    font-weight: normal;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
}

.input-group-otp {
  margin-bottom: 20px;
}
</style>
