<template>
  <div class="contain">
    <main id="content">
      <slot></slot>
      <Message />
    </main>
  </div>
</template>

<script>
import Message from "@/components/shared_components/common/Message";
export default {
  components: {
    Message,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
#content {
  min-height: 100vh;
  background: $text-main1;
}
</style>
