<template>
  <div class="notification">
    <div class="notification-icon" @click.capture="toggleShowNoti">
      <img
        :src="
          hasNotificationUnseen()
            ? '/images/icon/notification.svg'
            : '/images/icon/not-notification.svg'
        "
        alt="notification.svg"
      />
    </div>
    <div
      :class="
        showNotificationList ? 'notification-box active' : 'notification-box'
      "
      v-if="showNotificationList"
    >
      <div class="notification-head">
        <button @click="clearAll()" v-if="notificationList.length !== 0">
          {{ $t("slippery_note.clear_all") }}
        </button>
        <div v-else></div>
        <span @click="$router.push('/slippery-note', () => {})">
          <img
            class="icon-slipperry"
            src="/images/icon/icon-settings.svg"
            alt="icon"
          />
          {{ $t("slippery_note.title") }}
        </span>
      </div>
      <ul
        class="notification-list"
        :class="{ nodata: notificationList.length === 0 }"
      >
        <template v-if="notificationList.length === 0">
          <div class="empty-notification__wrapper">
            <img
              class="icon-nodata"
              src="/images/table-nodata.png"
              alt="no_fund_icon"
            />
            <div class="empty-notification__msg">
              {{ $t("slippery_note.no_notify") }}
            </div>
          </div>
        </template>
        <template v-else>
          <li
            v-for="item in notificationList"
            :key="item.id"
            @click="toExchange(item.coin)"
            :class="{ unseen: !item.read_at }"
          >
            <div class="title">
              <img
                width="20"
                :src="`${STORAGE_URL}/icons/${item.coin}.png`"
                :alt="`${item.coin}-icon`"
              />
              <span class="pair">{{ item.coin | uppercase }}/IDR</span>
              <span
                class="price"
                :class="isUpto(item) ? 'price--upto' : 'price--downto'"
              >
                {{ $t("slippery_note.has_hit") }}
                {{
                  getThreshold(item) | formatCurrencyAmount(item.currency, "0")
                }}</span
              >
              <img :src="getTrendingIcon(item)" alt="trending" />
            </div>
            <div class="description">
              {{ item.coin | uppercase }}/IDR
              {{ $t("slippery_note.last_price_has_hit") }}
              {{
                isUpto(item)
                  ? $t("slippery_note.above")
                  : $t("slippery_note.below")
              }}
              {{
                getThreshold(item) | formatCurrencyAmount(item.currency, "0")
              }}
            </div>
            <div class="trigger-time">
              {{ $t("slippery_note.trigger_time") }}
              {{ item.created_at | convertToLocalTime }}
            </div>
            <div class="status" @click.stop="onToggleSlippy(item)">
              <div class="toggle-switch on">
                <span class="toggle-handle"></span>
              </div>
              <span>{{ $t("slippery_note.turn_on") }}</span>
            </div>
            <div class="close" @click.stop="removeNotification(item)">
              <img src="/images/close-circle.svg" alt="close.svg" />
            </div>
          </li>
        </template>
      </ul>
    </div>
    <div class="list-notify" v-if="showNotification">
      <div
        class="notify-item"
        v-for="item in lastestNotificationList"
        :key="item.coin"
        @click="toExchange2(item.coin)"
      >
        <div class="title">
          <img
            width="20"
            :src="`${STORAGE_URL}/icons/${item.coin}.png`"
            :alt="`${item.coin}-icon`"
          />
          <span class="pair">{{ item.coin | uppercase }}/IDR</span>
          <span
            class="price"
            :class="isUpto(item) ? 'price--upto' : 'price--downto'"
          >
            {{ $t("slippery_note.has_hit") }}
            {{
              getThreshold(item) | formatCurrencyAmount(item.currency, "0")
            }}</span
          >
          <img :src="getTrendingIcon(item)" alt="trending" />
        </div>
        <div class="description">
          {{ item.coin | uppercase }}/IDR
          {{ $t("slippery_note.last_price_has_hit") }}
          {{
            isUpto(item) ? $t("slippery_note.above") : $t("slippery_note.below")
          }}
          {{ getThreshold(item) | formatCurrencyAmount(item.currency, "0") }}
        </div>
        <div class="trigger-time">
          {{ $t("slippery_note.trigger_time") }}
          {{ item.created_at | convertToLocalTime }}
        </div>
        <div class="status" @click.stop="onToggleSlippy2(item)">
          <div class="toggle-switch on">
            <span class="toggle-handle"></span>
          </div>
          <span>{{ $t("slippery_note.turn_on") }}</span>
        </div>
        <div class="close" @click.stop="removeNotification2(item.coin)">
          <img src="/images/close-circle.svg" alt="close.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import rf from "@/request/RequestFactory";
import BigNumber from "bignumber.js";

export default {
  name: "NotificationModal",
  props: {
    accessToken: {
      default: undefined,
    },
  },
  data() {
    return {
      showNotificationList: false,
      showNotification: false,
      lastestNotificationList: [],
    };
  },
  computed: {
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === "storage_url")
        .value;
    },
    ...mapState({
      notificationList(state) {
        if (this.accessToken) {
          return state.notification;
        }
        return [];
      },
    }),
  },
  mounted() {
    if (this.accessToken) {
      this.getSlipperyNotification();
    }
  },
  watch: {
    "$route.path"(val) {
      this.handleCloseNoti();
    },
  },
  methods: {
    hasNotificationUnseen() {
      return this.notificationList.some((item) => !item.read_at);
    },
    getThreshold(item) {
      if (this.isUpto(item)) {
        if (new BigNumber(item.current_last_price).gt(item.max_threshold)) {
          return item.max_threshold;
        }
        return item.min_threshold;
      } else {
        if (new BigNumber(item.current_last_price).lt(item.min_threshold)) {
          return item.min_threshold;
        }
        return item.max_threshold;
      }
    },
    async toExchange(coin) {
      this.showNotification = false;
      this.showNotificationList = false;
      const payload = {
        currency: "idr",
        coin,
      };
      await rf
        .getRequest("UserRequest")
        .updateNotification(payload)
        .then((res) => {
          this.getSlipperyNotification();
        });
      this.$router.push(
        `/spot-exchange/basic?coin=${coin || "btc"}&currency=idr`,
        () => {}
      );
    },
    async toExchange2(coin) {
      this.showNotification = false;
      const payload = {
        currency: "idr",
        coin,
      };
      await rf
        .getRequest("UserRequest")
        .updateNotification(payload)
        .then((res) => {
          this.getSlipperyNotification();
        });

      this.removeNotification2(coin);
      this.lastestNotificationList = [];
      this.$router.push(
        `/spot-exchange/basic?coin=${coin || "btc"}&currency=idr`,
        () => {}
      );
    },
    getSlipperyNotification() {
      rf.getRequest("UserRequest")
        .getSlipperyNotification()
        .then((res) => {
          const list = res?.data?.data?.map((item) => {
            const obj = {
              ...item,
              ...JSON.parse(item?.data),
              data: null,
            };
            delete obj?.data;
            return obj;
          });
          this.setNotification(list || []);
        });
    },
    getTrendingIcon(item) {
      if (this.isUpto(item))
        return require(`@/assets/images/icon/trending-up.svg`);
      return require(`@/assets/images/icon/trending-down.svg`);
    },
    isUpto(item) {
      return new BigNumber(item.current_last_price).gt(
        item.previous_last_price
      );
    },
    clearAll() {
      rf.getRequest("UserRequest")
        .clearAllNotification()
        .then((res) => {
          this.setNotification([]);
        });
    },
    onToggleSlippy(item) {
      let payload = {
        currency: "idr",
        coin: item.coin,
        status: 0,
      };
      rf.getRequest("UserRequest")
        .updateNotification(payload)
        .then((res) => {
          this.removeNotification(item);
        })
        .catch((err) => {
          Message.error(
            this.$i18n.t("slippery_note.update_failed"),
            {},
            { position: "bottom_left" }
          );
        });
    },
    onToggleSlippy2(noti) {
      this.onToggleSlippy(noti);
      this.lastestNotificationList = this.lastestNotificationList.filter(
        (item) => item.coin !== noti.coin
      );
    },
    handleCloseNoti() {
      this.showNotificationList = false;
    },
    toggleShowNoti() {
      if (this.showNotification) {
        this.lastestNotificationList = [];
        this.getSlipperyNotification();
      }
      this.showNotification = false;
      this.showNotificationList = !this.showNotificationList;
    },
    getSocketEventHandlers() {
      return {
        NotificationUpdate: this.addNotification,
      };
    },
    addNotification(noti) {
      this.lastestNotificationList = this.lastestNotificationList.filter(
        (item) => item.coin !== noti.coin
      );
      this.lastestNotificationList = [noti, ...this.lastestNotificationList];

      this.showNotification = true;
      const item =
        this.notificationList.find((item) => item.coin === noti.coin) || null;
      let newList = [...this.notificationList];
      if (item) {
        newList = this.notificationList.filter(
          (item) => item.coin !== noti.coin
        );
        newList.unshift({
          ...noti,
        });
      } else {
        newList.unshift({
          ...noti,
        });
      }
      this.setNotification(newList);
    },
    setNotification(newList) {
      const list = newList.filter(
        (item) => item.max_threshold && item.min_threshold
      );
      this.$store.commit("setNotification", list);
    },
    removeNotification(item) {
      rf.getRequest("UserRequest")
        .clearNotification({ coin: item.coin, currency: "idr" })
        .then((res) => {
          this.getSlipperyNotification();
        });
    },
    removeNotification2(coin) {
      const payload = {
        currency: "idr",
        coin,
      };
      rf.getRequest("UserRequest")
        .updateNotification(payload)
        .then(() => {
          this.getSlipperyNotification();
          this.lastestNotificationList = this.lastestNotificationList.filter(
            (item) => item.coin !== coin
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$header-height: 60px;
$header-mobile: 60px;

.nvx-logo {
  img {
    max-height: 54px;
    width: auto;
  }

  margin-bottom: 8px;
}

.notification {
  position: relative;

  .notification-icon {
    cursor: pointer;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 25px;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
  }

  .notification-box {
    display: none;
    position: absolute;
    right: 4px;
    top: calc(100% - 10px);
    z-index: 100;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: $color-white;
    border-radius: 10px;
    z-index: 999;
    width: 440px;
    max-width: 100vw;

    @include mobile {
      width: calc(100vw - 32px);
      left: 16px;
      position: fixed;
      top: calc($header-height + 7px);
      right: 0;
    }

    /* &::before {
      content: "";
      position: absolute;
      top: -10px;
      right: 8px;
      transform: translateX(-8px);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid $color-white;
    } */
    &.active {
      display: flex;
      flex-direction: column;
    }

    .notification-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
      padding: 24px 24px 0;

      @include mobile {
        padding: 24px 16px 0;
      }

      button {
        @include btn-common(contained, sm);
        text-transform: capitalize;
        width: auto;
      }

      span {
        display: flex;
        align-items: center;
        gap: 6px;
        font-weight: 300;
        font-size: 14px;
        line-height: 24px;
        color: $text-main;
        cursor: pointer;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    ul.notification-list {
      max-height: 490px;
      overflow: auto;
      list-style-type: none;
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 22px 24px 24px 24px;

      @include mobile {
        max-height: 485px;
        padding: 22px 16px 24px 16px;
      }

      margin: 0;

      &::-webkit-scrollbar-track {
        margin-bottom: 10px;
      }

      li {
        padding: 32px 24px 24px 24px;
        border-radius: 6px;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        position: relative;
        background: #f8f8f8;

        &:hover {
          background: #ebebeb;
        }

        &.unseen::after {
          content: "";
          position: absolute;
          top: 12px;
          left: 12px;
          width: 10px;
          height: 10px;
          background: $color-red;
          border-radius: 20px;
        }

        .title {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;

          .pair {
            color: $text-main;
            font-weight: 700;
          }

          .price {
            white-space: nowrap;

            @include mobile {
              font-weight: 300;
            }

            &--downto {
              color: $color-red;
            }

            &--upto {
              color: $color-green;
            }
          }
        }

        .description {
          margin-top: 12x;
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
          color: $text-main;

          @include mobile {
            font-size: 14px;
            line-height: 22px;
          }
        }

        .trigger-time {
          margin-top: 12px;
          font-size: 16px;
          font-weight: 300;
          color: $text-main;

          @include mobile {
            font-size: 14px;
            line-height: 22px;
          }
        }

        .status {
          margin-top: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 16px;
          text-transform: capitalize;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;

          .toggle-switch {
            cursor: pointer;
            width: 48px;
            height: 24px;
            background: $bg-main1;
            border-radius: 14px;

            .toggle-handle {
              display: inline-block;
              width: 16px;
              height: 16px;
              background: #fff;
              border-radius: 50%;
              margin: 4px;
              transition: all 0.3s ease;
            }

            &.off {
              background: $text-main-blur;

              .toggle-handle {
                margin-left: 4px;
                opacity: 1;
              }
            }

            &.on {
              background: $bg-main1;

              .toggle-handle {
                margin-left: 28px;
                opacity: 1;
              }
            }
          }
        }

        .close {
          opacity: 1;
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer;

          img {
            height: 24px;
          }
        }
      }

      &.nodata {
        padding: 80px 16px;

        @include mobile {
          padding: 50px 16px 100px;
        }
      }

      .empty-notification__wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        text-align: center;
        justify-content: center;

        img {
          width: 137px;
          height: 137px;
          margin-left: auto;
          margin-right: auto;

          @include mobile {
            width: 100px;
            height: 100px;
          }
        }

        .empty-notification__msg {
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          color: $text-secondary;
        }
      }
    }
  }

  .list-notify {
    position: fixed;
    right: 20px;
    top: 100px;
    z-index: 999;

    @include mobile {
      right: 16px;
      top: 80px;
    }
  }

  .notify-item {
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    min-width: 330px;
    padding: 32px 24px 24px 24px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: white;
    border-radius: 6px;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    position: relative;

    &:hover {
      background: #ebebeb;
    }

    @include mobile {
      min-width: 315px;
      max-width: 330px;
      width: calc(100vw - 32px);
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;

      .pair {
        color: $text-main;
        font-weight: 700;
      }

      .price {
        @include mobile {
          font-weight: 300;
        }

        &--downto {
          color: $color-red;
        }

        &--upto {
          color: $color-green;
        }
      }
    }

    .description {
      margin-top: 12px;
      font-size: 16px;
      font-weight: 300;
      color: $text-main;

      @include mobile {
        font-size: 14px;
        line-height: 22px;
      }
    }

    .trigger-time {
      margin-top: 10px;
      font-size: 16px;
      color: $text-main;
      font-weight: 300;

      @include mobile {
        font-size: 14px;
        line-height: 22px;
      }
    }

    .status {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      text-transform: capitalize;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;

      .toggle-switch {
        cursor: pointer;
        width: 48px;
        height: 24px;
        background: $bg-main1;
        border-radius: 14px;

        .toggle-handle {
          display: inline-block;
          width: 16px;
          height: 16px;
          background: #fff;
          border-radius: 50%;
          margin: 4px;
          transition: all 0.3s ease;
        }

        &.off {
          background: $text-main-blur;

          .toggle-handle {
            margin-left: 4px;
            opacity: 1;
          }
        }

        &.on {
          background: $bg-main1;

          .toggle-handle {
            margin-left: 28px;
            opacity: 1;
          }
        }
      }
    }

    .close {
      opacity: 1;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;

      img {
        height: 24px;
      }
    }
  }
}
</style>
