<template>
  <div class="about_footer clearfix">
    <div class="list_link">
      <a
        v-for="(socicalNetwork, index) in socicalNetworks"
        :key="index"
        :href="socicalNetwork.link"
        :class="socicalNetwork.icon_class"
        target="_blank"
        class="icon icon-social"
      >
        <!-- <span class="tooltip_list_link">{{socicalNetwork.name}}</span> -->
      </a>
    </div>
    <div class="right_footer_ex">
      {{ siteSetting.copyright }}
    </div>
  </div>
</template>

<script>
import Const from "@/common/Const";
import AuthenticationUtils from "@/common/AuthenticationUtils";
import rf from "@/request/RequestFactory";
import { mapState } from "vuex";

export default {
  components: {},
  data() {
    return {
      visibleLangueges: false,
      languages: Const.LANGUAGES,
      socicalNetworks: {},
      siteSetting: {},
    };
  },
  props: {
    miniFooter: { type: Boolean, default: false },
  },
  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
    }),
    isExchangePage() {
      return this.$route.path === PathRoot.path;
    },
    isLanding() {
      return this.$route.path === "/";
    },
  },
  methods: {
    convertSiteSetting(data) {
      window._.each(data, (item) => {
        this.siteSetting[item.key] = item.value;
      });
    },

    getMasterdata() {
      this.convertSiteSetting(this.masterdata.settings);
      this.socicalNetworks =
        window._.filter(
          this.masterdata.social_networks,
          (item) => item.is_active
        ) || {};
    },
  },
  mounted() {
    this.getMasterdata();
  },
};
</script>
<style lang="scss" scoped>
.about_footer {
  padding: 22px 40px;

  .list_link {
    a.icon {
      display: inline-block;
      float: left;
      margin-right: 5px;
      width: 30px;
      height: 30px;
      line-height: 20px;
      padding: 5px;
      font-size: $font-big-20;
      color: $color-grey-dusty;
      border-radius: 3px;

      &:hover {
        color: $color-white;
      }
    }
  }

  .right_footer_ex {
    float: right;
    font-size: $font-small;
    color: $color-grey-dusty;
    line-height: 30px;
  }
}
</style>
