<template>
  <span
    :class="classes"
    :title="$options.filters?.formatCurrencyAmount(value, 'idr', '0')"
    >{{ value | formatCurrencyAmount("idr", "0") }} IDR</span
  >
</template>

<script>
export default {
  props: {
    classes: { type: String },
    subClasses: { type: String },
    value: { type: [String, Number], required: true },
    unit: { type: String, default: "idr" },
    usdCurrency: { type: Boolean, default: false },
    usdCurrency: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped></style>
