<template>
  <div class="otp-box">
    <p
      class="otp-title"
      v-html="
        $t('funds.withdrawals.otp.notify.title', {
          token: token,
          phone_no: maskPhoneNumberFunc(),
        })
      "
    ></p>

    <div class="otp-box-header" style="margin-bottom: 0px">
      <p class="otp-box-header_left">
        {{ $t('funds.withdrawals.otp.verify.title') }}
      </p>
      <p class="otp-box-header_right">
        <CountDown class="bold" ref="countDown" :duration="duration" @end="handleCountdownEnd" />
        <span
          class="resend-sms__btn"
          :class="{
            link__active: isActiveResendOtp,
            text__active: isActiveResendOtp,
          }"
          @click="onResendOtp"
        >
          &nbsp;{{ $t('funds.withdrawals.otp.resend.titleWa') }}
        </span>
      </p>
    </div>
    <div v-show="isShowResendSms && apiUrl !== 'https://dev-api.nvx.co.id'">
      <ResendOtpVue :isDisable="!isActiveResendOtp" @button-resend-otp="onResendOtpSMS" />
    </div>
    <div class="otp-input" style="margin-top: 16px">
      <div style="flex: 1; width: 100%">
        <div id="otp-input-fields" :class="{ error: error }" ref="otpCont">
          <!-- <input
            inputmode="numeric"
            v-for="(el, index) in 6"
            :key="el + index"
            class="input-otp"
            type="number"
            v-model="finalOtp[index]"
            :autofocus="index === 0"
            placeholder=""
            @keydown="handleKeyDown($event, index)"
            maxlength="1"
            @focus="resetErrors"
          /> -->
          <div
            class="otp-input-container"
            style="display: flex !important; flex-direction: row; width: 100%"
          >
            <!-- <v-otp-input
              ref="otpInput"
              input-classes="otp-input-new"
              separator=" "
              :num-inputs="6"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-change="handleOnChange"
              @on-complete="handleOnComplete"
              :input-type="'tel'"
            /> -->
            <input
              type="text"
              autocomplete="one-time-code"
              inputmode="numeric"
              style="width: 100%"
              v-model="autoOtpSMS"
              maxlength="6"
              @keypress="isNumber($event)"
            />
          </div>
        </div>
        <span class="invalid-feedback" v-show="error">
          {{ error }}
        </span>
      </div>
      <button class="btn btn-otp-submit" @click="onSubmitOtp" :disabled="isOnSubmit">
        {{ $t('funds.withdrawals.otp.verify.submit') }}
      </button>
    </div>
  </div>
</template>

<script>
import CountDown from '@/components/shared_components/funds/common/CountDown.vue';
import OtpInput from '@bachdgvn/vue-otp-input';
import { mapState } from 'vuex';
import { maskPhoneNumber } from '@/utils/masking-phone';
import ResendOtpVue from '@/components/ResendOtp.vue';

if ('OTPCredential' in window) {
  window.addEventListener('DOMContentLoaded', (e) => {
    const input = document.querySelector('input[autocomplete="one-time-code"]');
    if (!input) return;
    const ac = new AbortController();
    navigator.credentials
      .get({
        otp: { transport: ['sms'] },
        signal: ac.signal,
      })
      .then((otp) => {
        input.value = otp.code;
      })
      .catch((err) => {
        console.log(err);
      });
  });
}

export default {
  components: { OtpInput, CountDown, ResendOtpVue },
  props: {
    duration: { type: Number, default: 60 },
    error: { type: String, default: '' },
    resetErrors: { type: Function },
    phone_no: { type: String, default: '' },
    token: { type: String, default: 'IDR' },
  },
  data() {
    return {
      isShowResendSms: false,
      otp: '',
      digitCount: 6,
      isActiveResendOtp: false,
      finalOtp: [],
      phone_no: this.phone_no,
      isOnSubmit: false,
      autoOtpSMS: '',
      apiUrl: process.env.VUE_APP_API_URL
    };
  },

  computed: {
    ...mapState({
      isDeviceHover: (state) => state.isDeviceHover,
    }),
  },
  watch: {
    finalOtp() {
      this.otp = this.finalOtp.join('');
    },
    async autoOtpSMS(newVal, oldVal) {
      if (newVal !== '') {
        // this.$refs.otpInput.otp = newVal.split('');
        this.handleOnChange(newVal);
        if (newVal.length === 6) {
          this.onSubmitOtp();
        }
      }
    },
  },
  mounted() {
    this.setCountDown();
  },
  methods: {
    handleCountdownEnd() {
      this.isActiveResendOtp = true;
      this.isShowResendSms = true;
    },
    maskPhoneNumberFunc() {
      return maskPhoneNumber(this.phone_no);
    },
    handleKeyDown(event, index) {
      if (event.key !== 'Tab' && event.key !== 'ArrowRight' && event.key !== 'ArrowLeft') {
        event.preventDefault();
      }

      if (
        event.key === 'Backspace' ||
        event.key === 'Delete' ||
        event.which === 8 ||
        event.which === 46 ||
        !event.key ||
        !event.which
      ) {
        this.$set(this.finalOtp, index, null);

        if (index != 0) {
          this.$refs.otpCont?.children[index - 1].focus();
        }
        return;
      }
      if (new RegExp('^([0-9])$').test(event.key)) {
        this.$set(this.finalOtp, index, event.key);

        if (index != 5) {
          this.$refs.otpCont?.children[index + 1].focus();
        }
      }
    },
    onResendOtp() {
      if (this.isActiveResendOtp) {
        this.setCountDown();
        this.isActiveResendOtp = false;
        this.$emit('resend-otp');
      }
    },
    onResendOtpSMS() {
      if (this.isActiveResendOtp) {
        this.setCountDown();
        this.isActiveResendOtp = false;
        this.$emit('resend-otp-sms');
      }
    },
    onSubmitOtp() {
      this.isOnSubmit = true;
      this.$emit('submit-otp', this.otp);
      this.isOnSubmit = false;
    },
    setCountDown() {
      if (this.$refs.countDown) {
        this.$refs.countDown.setCountDown();
      }
    },
    async handleOnComplete(value) {
      this.otp = await value;
      this.onSubmitOtp();
    },
    handleOnChange(value) {
      this.resetErrors();
      this.autoOtpSMS = value;
      this.otp = value;
    },
    isNumber(evt) {
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
      const keyPressed = evt.key;

      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.otp-box {
  position: relative;
  margin-top: 16px;
  background: #f8f8f8;
  border-radius: 8px;
  padding: 16px;
  @include mobile {
    padding: 16px 12px;
  }
  #otp-input-fields {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    @include mobile {
      justify-content: center;
      gap: 8px;
    }
  }
  .otp-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    color: $text-secondary;
    margin-bottom: 16px;
    @include mobile {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    &_left {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: $text-secondary;
      @include mobile {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
      }
    }
    &_right {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      color: $text-main;
      @include mobile {
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
      }
      .bold {
        font-weight: 700;
      }
      .resend-sms__btn.link__active {
        color: $color-blue;
        cursor: pointer;
      }
    }
  }
  .otp-input {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: nowrap;
    @media (min-width: 1024px) and (max-width: 1400px) {
      flex-wrap: wrap;
    }
    @include mobile {
      flex-direction: column;
      gap: 16px;
      align-items: center;
    }
  }
  .btn-otp-submit {
    background: $bg-main6;
    padding: 9px;
    border-radius: 30px;
    color: white;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    height: 42px;
    min-width: 136px;
    @include mobile {
      width: 100%;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
</style>
<style lang="scss">
.otp-input-container div:first-child {
  column-gap: 5px;
}
.otp-input-new {
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  text-align: center;
  margin: 0 5px;
  &.error {
    border: 1px solid red !important;
  }
}
.otp-input-new::-webkit-inner-spin-button,
.otp-input-new::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
