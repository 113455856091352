<template>
  <div class="tableContainer tableContainer3" :style="{ width: widthTable }">
    <table>
      <thead>
        <tr
          :class="defaultSort ? 'default-sort' : 'custom-sort'"
          @click="onSort"
        >
          <slot
            :resetIE="resetIE"
            :innerHtmlSort="innerHtmlSort"
            :echoclick="echoclick"
          />
        </tr>
      </thead>
      <tbody>
        <slot name="first_row" />
        <slot
          name="body"
          v-for="(row, index) in rows"
          :item="row"
          :index="index"
        />
        <template v-if="this.rows.length === 0">
          <tr class="empty-data">
            <td colspan="50">
              <div
                class="empty-noti"
                :style="[height ? { 'min-height': height + 'vh' } : '']"
              >
                <div class="text-wrapper">
                  <img
                    class="icon-nodata"
                    src="/images/wallet/no_fund_icon.svg"
                    alt="no_fund_icon"
                  />
                  <span
                    v-if="tableNoData === false && typeHistory === 'deposit'"
                    >{{ $t("funds.transaction_history.no_deposit_data") }}</span
                  >
                  <span
                    v-else-if="
                      tableNoData === false && typeHistory === 'withdraw'
                    "
                    >{{
                      $t("funds.transaction_history.no_withdraw_data")
                    }}</span
                  >
                  <span v-else class="msg-empty">{{
                    msgEmptyData || $t("common.datatable.data_empty")
                  }}</span>
                </div>
              </div>
            </td>
          </tr>
        </template>
        <slot name="end_row" />
      </tbody>
    </table>
    <template v-if="lastPage > 1">
      <pagination
        ref="pagination"
        class="text-right"
        :per-page="perPage"
        :records="totalRecord"
        :chunk="chunk"
        @Pagination:page="onPageChange"
        :pageParent="page"
      ></pagination>
    </template>
  </div>
</template>

<script>
import Pagination from "./Pagination";

export default {
  components: {
    Pagination,
  },
  props: {
    getData: {
      type: Function,
    },
    limit: {
      type: Number,
      default: 10,
    },
    chunk: {
      type: Number,
      default: 6,
    },
    widthTable: {
      type: String,
      default: "100%",
    },
    msgEmptyData: {
      type: String,
    },
    typeHistory: {
      type: String,
    },
    defaultSort: {
      type: Boolean,
      default: true,
    },
    tableNoData: {
      type: Boolean,
      default: false,
    },
    innerHtmlSort: {
      type: String,
      default:
        `<span class="icon-arrow-double"></span>
        <span class="icon-sort-tabl3 icon-sort-down"></span> 
        <span class="icon-sort-tabl3 icon-sort-up"></span>`
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      maxPageWidth: 10,
      totalRecord: 0,
      lastPage: 0,
      page: 1,
      perPage: 10,
      fetching: false,
      rows: [],
      params: {},

      orderBy: null,
      sortedBy: null,

      emptyData: false,
      resetIE: false,
    };
  },
  computed: {
    emptyRow() {
      let emptyRowCount = Math.max(this.limit - _.size(this.rows), 0);
      return Math.min(emptyRowCount, this.limit);
    },
  },
  methods: {
    rawHtml() {
      document.getElementById("demo").innerHTML = "My First JavaScript";
    },
    onPageChange(page) {
      this.page = page;
      this.fetch();
    },
    echoclick() {
      this.resetIE = !this.resetIE;
    },
    getTarget(target) {
      let node = target;
      while (node.parentNode.nodeName !== "TR") {
        node = node.parentNode;
      }
      return node;
    },

    getSortOrder(target) {
      let sortOrder = target.dataset.sortOrder;
      switch (sortOrder) {
        case "asc":
          sortOrder = "";
          break;
        case "desc":
          sortOrder = "asc";
          break;
        default:
          sortOrder = "desc";
      }
      return sortOrder;
    },

    setSortOrders(target, sortOrder) {
      let iterator = target.parentNode.firstChild;
      while (iterator) {
        iterator.dataset.sortOrder = "";
        iterator = iterator.nextElementSibling;
      }
      target.dataset.sortOrder = sortOrder;
    },

    onSort(event) {
      const target = this.getTarget(event.target);
      const orderBy = target.dataset.sortField;
      if (!orderBy) {
        return;
      }
      this.sortedBy = this.getSortOrder(target);
      this.orderBy = this.sortedBy ? orderBy : "";
      Object.assign(this.params, {
        sort: this.orderBy,
        sort_type: this.sortedBy,
      });
      this.setSortOrders(target, this.sortedBy);
      this.fetch();
    },

    fetch() {
      try {
        const meta = {
          page: this.page,
          limit: this.limit,
        };

        this.fetching = true;
        this.getData(Object.assign(meta, this.params))
          .then((res) => {
            const data = res.data || res;
            if (!data) {
              return;
            }
            this.column = _.chain(this.$slots.default)
              .filter((el) => {
                return el.tag === "th";
              })
              .value().length;

            if (!data.data) {
              let sortData;
              if (this.sortedBy === "asc") {
                sortData = data.sort(function (a, b) {
                  let x = a.coin && a.coin.toLowerCase();
                  let y = b.coin && b.coin.toLowerCase();
                  if (x < y) {
                    return -1;
                  }
                  if (x > y) {
                    return 1;
                  }
                  return 0;
                });
              } else if (this.sortedBy === "desc") {
                sortData = data.sort(function (a, b) {
                  let x = a.coin && a.coin.toLowerCase();
                  let y = b.coin && b.coin.toLowerCase();
                  if (x > y) {
                    return -1;
                  }
                  if (x < y) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                sortData = data;
              }
              this.rows = sortData;
              this.$emit("DataTable:finish");
              return;
            }
            this.page = parseInt(data.current_page);
            this.totalRecord = parseInt(data.total);
            this.lastPage = parseInt(data.last_page);
            this.perPage = parseInt(data.per_page);
            this.rows = data.data;
            this.emptyData = window._.isEmpty(this.rows);
            this.$emit("DataTable:finish");
          })
          .then((res) => {
            this.fetching = false;
          });
      } catch (err) {
        this.fetching = false;
      }
    },
    refresh() {
      this.page = 1;
      this.params = Object.keys(this.params).map((k) => {
        if (!["start_date", "end_date", "page", "limit"].includes(k)) {
          delete this.params[k];
        }
      });
      setTimeout(() => {
        this.fetch();
      }, 1000);
    },

    filter(params) {
      this.page = 1;
      this.params = params;

      if (this.orderBy) {
        Object.assign(this.params, {
          sort: this.orderBy,
          sort_type: this.sortedBy,
        });
      }

      this.fetch();
    },

    getRows() {
      return this.rows;
    },

    setRows(rows) {
      this.rows = rows;
    },
  },
  created() {
    this.fetch();
    this.$on("DataTable:filter", (params) => {
      this.filter(params);
    });
  },
  mounted() {
    this.column = _.chain(this.$slots.default)
      .filter((el) => {
        return el.tag === "th";
      })
      .value().length;
  },
};
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  overflow-y: hidden;
  thead {
    text-align: center;
    color: $color_black;
    border: none;
    line-height: 17px;
    overflow: hidden;
    th {
      padding: 8px 0px 8px 0px;
      height: auto;
      font-size: 16px;
      line-height: 18px;
      color: #108CDD;
      white-space: nowrap;
      cursor: pointer;
      &:nth-child(1) {
        width: max(20%, 576px);
      }
      &:nth-child(2) {
        width: max(20%, 210px);
      }
      &:nth-child(3) {
        width: max(20%, 277px);
      }
      &:nth-child(4) {
        width: max(20%, 219px);
      }
      &:nth-child(5) {
        width: max(20%, 78px);
      }
      ::v-deep  {
        .group-sort-tabl3 {
          display: inline-table;
          vertical-align: middle;
          font-size: 15px;
          margin-left: 5px;
          position: relative;
          top: -2px;
        }
        .icon-arrow-double {
          font-size: 20px;
        }
        &[data-sort-order="desc"] {
          .icon-sort-down {
            display: block;
            position: relative;
            top: 8px;
            font-size: 9px;
            width: 15.73px;
          }
          .icon-arrow-double,
          .icon-sort-up {
            display: none;
          }
        }
        &[data-sort-order="asc"] {
          .icon-arrow-double,
          .icon-sort-down {
            display: none;
          }
          .icon-sort-up {
            display: block;
            position: relative;
            top: -4px;
            font-size: 9px;
            width: 15.73px;
          }
        }
      }
    }
  }

  tbody {
    text-align: center;
    tr {
      vertical-align: top;
      height: 32px;
      overflow-y: hidden;
      transition-property: height;
      transition-duration: 0.3s, 0.3s;
      transition-timing-function: ease, ease-in;
      .icon-arrow_2_2 {
        color: $color-green;
      }
      &.active {
        max-height: 300px;
        background-color: $color-bright-gray;
        transition-property: height;
        transition-duration: 0.3s, 0.3s;
        transition-timing-function: ease, ease-in;
      }
      &.empty-data {
        width: 100%;
        text-align: center;
        color: $dark-1;
        font-size: $font-root;
        text-align: center;
        td {
            width: 100%;
            text-align: center !important;
            color: $dark-1 !important;
            padding: 35px 0;

          .empty-noti {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            align-items: center;
            min-height: 50vh;
            .text-wrapper {
              user-select: none;
              pointer-events: none;
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .msg-empty {
              @include text-font(
                $helvetica,
                $dark-3,
                $font-root,
                700,
                18px
              );
            }
            .text-wrapper {
              display: flex;
              flex-direction: column;
              gap: 20px;
              justify-content: center;
              align-items: center;
            }
            .icon-nodata {
              font-size: 40px;
              color: $color-grey-sliver;
              width: 88px;
              height: 45px;
              margin-bottom: 20px;
            }
            @include mobile {
              align-items: unset;
              height: 100px;
              .text-wrapper {
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                width: fit-content;

                img {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
    
        p {
          padding-top: 22px;
          span {
            vertical-align: middle;
          }
        }
        .icon-nodata {
          font-size: 40px;
          color: $color-grey-sliver;
        }
      }
    }
    td {
        padding: 8px 0px 8px 0px;
        color: #000000;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;

        &:last-child {
            padding: 8px 0px 8px 0px;
            text-align: right;
        }
        &.info {
            background-color: $color-bright-gray;
            color: $color-grey-dusty;
            font-size: $font-small;
            text-align: left;
            padding: 8px 20px 8px 38px;
            .label-text {
            color: $dark-1;
            }
        }
    }
  }
}
@media only screen and (max-width: 992px) {
  table {
    thead {
      th {
        &:hover {
          color: $color-grey;
        }
      }
    }
  }
}
</style>
