<template>
  <div class="trade-history">
    <div class="tbl-history">
      <div class="tbl-title">
        <span>{{ $t('order.order_trade_form.price') }}</span>
        <span>{{ $t('order.order_trade_form.size') }}</span>
        <span>{{ $t('order.order_trade_form.time') }}</span>
      </div>
      <table class="table-size">
        <tbody>
          <tr
            v-for="(transaction, index) in getTransactions()"
            :key="index"
            @click="onRecentTradesClicked(transaction.price)"
          >
            <td
              class="trade-history-cl1"
              :class="transaction.transaction_type"
              v-tooltip="{
                content: filterPrice(transaction.price),
                classes: 'tooltip__wrapper',
              }"
            >
              {{
                transaction.price
                  ? Number(
                      parseFloat(transaction.price || 0).toFixed(isPairPrecisionIsFour ? 4 : 2)
                    ).toLocaleString('en', {
                      minimumFractionDigits: isPairPrecisionIsFour ? 4 : 2,
                    })
                  : '--'
              }}
            </td>
            <td
              class="trade-history-cl2"
              :title="
                transaction.quantity
                  | formatCurrencyAmount(null, '00')
                  | paddingRight(coinSettingByPair.fractionPartQuantity, '00')
              "
            >
              {{
                transaction.quantity
                  | formatCurrencyAmount(null, '00')
                  | paddingRight(coinSettingByPair.fractionPartQuantity, '00')
              }}
            </td>
            <td class="trade-history-cl3">
              {{ transaction.created_at | orderTime }}
            </td>
          </tr>
          <tr class="no-data" v-if="isEmptyData">
            <td>
              <p>
                <span class="icon-nodata">
                  <span class="icon-notfound3"></span>
                </span>
              </p>
              <p>{{ $t('order.trade_history.no_data') }}</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import rf from '@/request/RequestFactory';
import Const from '@/common/Const';
import BigNumber from 'bignumber.js';

export default {
  props: ['responsePairCoinSettings'],
  data() {
    return {
      marketTransactions: '',
      maxTransactionCount: 50,
      currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
      coin: this.$route.query.coin || Const.DEFAULT_COIN,

      isEmptyData: false,

      coinSettings: [],
      coinSettingByPair: {},
      disableByBetaTester: false,
    };
  },
  watch: {
    $route(to, from) {
      this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
      this.coin = this.$route.query.coin || Const.DEFAULT_COIN;

      this.getRecentTransactions();
      this.getCoinSettingsByPairCurrency();
      this.initData();
    },

    marketTransactions(newValue) {
      this.isEmptyData = window._.isEmpty(newValue);
    },
  },
  computed: {
    isPairPrecisionIsFour() {
      return (
        (this.coin === 'ada' && this.currency === 'usdt') ||
        (this.coin === 'xrp' && this.currency === 'usdt')
      );
    },
  },
  methods: {
    filterPrice(val) {
      return this.$options.filters.paddingRight(
        this.$options.filters.formatCurrencyAmount(val, this.currency),
        this?.coinSettingByPair?.fractionPartPrice ? this?.coinSettingByPair?.fractionPartPrice : 1,
        '0'
      );
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
      };
    },

    onSpotSelectedPairMarket(pair) {
      this.coin = pair.coin;
      this.currency = pair.currency;
      this.getRecentTransactions();
      this.getCoinSettingsByPairCurrency();
    },

    getTransactions() {
      return this.marketTransactions;
    },

    onRecentTradesClicked(price) {
      this.$broadcast('RecentTradeClicked', price);
    },

    getSocketEventHandlers() {
      return {
        OrderTransactionCreated: this.onOrderTransactionCreated,
      };
    },

    onOrderTransactionCreated(data) {
      let newItem = window._.cloneDeep(data.orderTransaction);
      if (this.currency != newItem.currency || this.coin != newItem.coin) {
        return;
      }
      let marketTransactions = window._.cloneDeep(this.marketTransactions);
      this.marketTransactions = this.pushNewTransaction(newItem, marketTransactions);
    },

    pushNewTransaction(transaction, transactions) {
      transactions.splice(0, 0, transaction);
      if (transactions.length > this.maxTransactionCount) {
        return transactions.slice(0, this.maxTransactionCount);
      }
      return transactions;
    },

    onReceiveTransactions(data) {
      if (!data || !data.length) return [];

      if (data.length <= this.maxTransactionCount) {
        return data;
      }

      return data.slice(0, this.maxTransactionCount);
    },
    getRecentTransactions() {
      let params = {
        currency: this.currency,
        coin: this.coin,
        count: 50,
      };
      if (this.disableByBetaTester) {
        this.marketTransactions = [];
        this.quantityTransactions = 0;
      } else {
        rf.getRequest('OrderRequest')
          .getRecentTransactions(params)
          .then((res) => {
            this.marketTransactions = this.onReceiveTransactions(res.data);
            this.quantityTransactions = this.marketTransactions.length;
          });
      }
    },

    getCoinSettings() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((res) => {
          this.coinSettings = window._.map(res.coin_settings, (item) => {
            item.fractionPartPrice = this.getDecimalPrecision(item.coin, item.precision);
            item.fractionPartQuantity = this.getDecimalPrecision(
              item.coin,
              item.quantity_precision
            );
            return item;
          });
          this.getCoinSettingsByPairCurrency();
        });
    },

    getCoinSettingsByPairCurrency() {
      if (window._.isEmpty(this.coinSettings)) {
        this.coinSettingByPair = {};
        return;
      }
      const result = window._.filter(this.coinSettings, (item) => {
        return item.currency === this.currency && item.coin === this.coin;
      });
      if (!window._.isEmpty(result)) {
        this.coinSettingByPair = result[0];
      }
    },

    getDecimalPrecision(currency, value) {
      if (currency === 'idr') {
        // convert to million unit
        value = new BigNumber(value).div(1000000).toString();
      }
      return Math.round(Math.log(1 / value) / Math.log(10));
    },
    initData() {
      this.disableByBetaTester = false;
      let coin = this.$route.query.coin || Const.DEFAULT_COIN;
      let currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;

      rf.getRequest('SettingRequest')
        .getPairCoinSettings({ coin, currency })
        .then((res) => {
          if (!res && !res.data) {
            return;
          }
          const setting = res.data;
          if (!setting.is_enable && setting.is_show_beta_tester) {
            this.disableByBetaTester = true;

            if (window.isAuthenticated) {
              rf.getRequest('UserRequest')
                .getUserPairTradingSetting({ coin, currency })
                .then((res) => {
                  if (res && res.data && res.data.enable_trading == Const.ENABLE_TRADING.ENABLE) {
                    this.disableByBetaTester = false;
                  }
                  this.getRecentTransactions();
                });
            } else {
              this.getRecentTransactions();
            }
          } else {
            this.getRecentTransactions();
          }
        });
    },
  },
  mounted() {
    let params = {
      currency: this.currency,
      coin: this.coin,
      count: 50,
    };
    this.getRecentTransactions();
    this.getCoinSettings();
    this.initData();
  },
};
</script>
<style lang="scss" scoped>
.trade-history {
  width: 100%;
  display: inline-block;
  @include mobile {
    border-left: 1px solid $grey-2;
    border-right: 1px solid $grey-2;
    border-bottom: 1px solid $grey-2;
    border-radius: 0 0 10px 10px;
  }
  .tbl-history {
    width: 100%;
    height: 100%;
    float: left;
    .tbl-title {
      display: grid;
      grid-template-columns: 35% 37% 28%;
      padding: 16px 14px;
      span {
        font-family: $helvetica;
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        color: $text-secondary;
        &:last-child {
          text-align: right;
        }
      }
    }
    .table-size {
      margin-top: -6px;
      width: 100%;
      height: 100%;
      float: left;
      tr {
        width: 100%;
        cursor: pointer;
        &.no-data {
          text-align: center;
          border-bottom-style: none;
          td {
            padding-top: 80px;
          }
          p {
            color: $color-gray-dark;
          }
          .icon-nodata {
            font-size: 60px;
            color: $color-grey-sliver;
          }
          &:hover {
            td {
              background: none !important;
            }
          }
        }
        .trade-history-cl1 {
          display: inline-block;
          width: 37%;
          text-align: left;
        }
        .trade-history-cl2 {
          width: 35%;
          display: inline-block;
          text-align: left;
        }
        .trade-history-cl3 {
          width: 28%;
          display: inline-block;
          text-align: right;
        }
        td {
          font-size: 14px;
          line-height: 22px;
          font-weight: 300;
          text-overflow: ellipsis;
          overflow: hidden;
          color: $text-main;
          padding-top: 6px;
          padding-bottom: 6px;
          padding-right: 6px;
          &:last-child {
            padding-right: 14px;
          }
          &:first-child {
            padding-left: 14px;
          }
          &.sell,
          &.buy {
            font-weight: 400;
            line-height: 24px;
          }
          &.sell {
            color: $color-red;
          }
          &.buy {
            color: $color-green;
          }
        }
        &:hover {
          td {
            background-color: $grey-3;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.tooltip__wrapper[aria-hidden='false'] {
  opacity: 1;
}
#home .page-container-right {
  #order_trade_form {
    overflow: hidden;
    height: 100%;
    .tab_content_ordertrade {
      background: $color-white;
      border-top: 1px solid $bg-main2;
      height: calc(100% - 50px);
      overflow-y: auto;
      .item:hover {
        background-color: initial;
      }
    }
  }
}
</style>
