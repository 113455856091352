import Vue from "vue";
import Vuex from "vuex";
import createLogger from "@/common/logger-vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

let rootStore = {
  state: {
    masterdata: localStorage.getItem("masterdata")
      ? JSON.parse(localStorage.getItem("masterdata"))
      : undefined,
    pairCoins: localStorage.getItem("pairCoins")
      ? JSON.parse(localStorage.getItem("pairCoins"))
      : [],
    totalPair: 0,
    notification: localStorage.getItem("slipperyNote")
      ? JSON.parse(localStorage.getItem("slipperyNote"))
      : [],
    taxSettings: localStorage.getItem("taxSettings")
      ? JSON.parse(localStorage.getItem("taxSettings"))
      : [],
    travelRuleSettings: localStorage.getItem("travelRuleSettings")
      ? JSON.parse(localStorage.getItem("travelRuleSettings"))
      : [],
    isDeviceHover: true,
  },
  getters: {},
  mutations: {
    setTotalPair: (state, payload) => {
      state.totalPair = payload;
    },
    setMasterdata: (state, payload) => {
      state.masterdata = payload;
    },
    setPairCoins: (state, payload) => {
      localStorage.setItem("pairCoins", JSON.stringify(payload));
      state.pairCoins = payload;
    },
    setNotification: (state, payload) => {
      state.notification = payload;
    },
    setTaxSettings: (state, payload) => {
      localStorage.setItem("taxSettings", JSON.stringify(payload));
      state.taxSettings = payload;
    },
    setIsDeviceHover: (state, payload) => {
      state.isDeviceHover = payload;
    },
  },
};

const resources = require.context(".", true, /^\.\/(?!index\.js$)/);
resources.keys().map((file) => {
  const data = resources(file);

  const storeData = data.default || data;
  const { actions, mutations, getters, state, ...otherDataStore } = storeData;

  rootStore = {
    ...rootStore,
    actions: {
      ...rootStore.actions,
      ...actions,
    },
    getters: {
      ...rootStore.getters,
      ...getters,
    },
    mutations: {
      ...rootStore.mutations,
      ...mutations,
    },
    state: {
      ...rootStore.state,
      ...state,
    },
  };
});

const presistedOptions = {
  paths: ["currentSymbol", "currentIndexSymbol", "common", "orderbook", "user"],
};
const plugins = [createPersistedState(presistedOptions)];
const store = new Vuex.Store({
  ...rootStore,
  strict: debug,
  plugins: debug ? [createLogger(), ...plugins] : plugins,
  // state: {
  //   user: {},
  //   instrument: {},
  //   settlement: {},
  //   funding: {},
  //   insurance: {},
  //   compositeIndex: {},
  //   localLang:{},

  // },
});

export default store;
