import '@/common/bootstrap'
import '@/common/Filters.js'
import '@/common/gt.js'

import Vue from 'vue'
import VueBroadcast from '@/common/VueBroadcast'
import VueRequestCanceler from '@/common/VueRequestCanceler'
import VeeValidate from 'vee-validate'
import VueRouter from 'vue-router'
import GlobalSocket from '@/common/GlobalSocket'
import Const from '@/common/Const'
import rf from '@/request/RequestFactory'

import ClickOutside from 'vue-click-outside'
import Routers from './routes'

import App from './App.vue'
import DataTable from '@/components/shared_components/common/DataTable/DataTable.vue'
import DataTable2 from '@/components/shared_components/common/DataTable/DataTable2.vue'
import DataTable3 from '@/components/shared_components/common/DataTable/DataTable3.vue'
import DataTable4 from '@/components/shared_components/common/DataTable/DataTable4.vue'
import DataListMobile from '@/components/shared_components/common/DataTable/DataListMobile.vue'
import DataTableLandingPage from '@/components/shared_components/common/DataTable/DataTableLandingPage.vue'

import FiatCurrency from '@/components/shared_components/common/FiatCurrency.vue'
import FullScreen from 'vue-fullscreen'
import Multiselect from 'vue-multiselect'
import VueClipboard from 'vue-clipboard2'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import CurrencyInput from '@/components/shared_components/common/CurrencyInput'
import InputOnlyNumber from '@/components/shared_components/common/InputOnlyNumber'
import InputOnlyNumber2 from '@/components/shared_components/common/InputOnlyNumber2'
import InputTextAndNumber from '@/components/shared_components/common/InputTextAndNumber'

import SiteSupport from '@/common/SiteSupport'
import AuthenticationUtils from '@/common/AuthenticationUtils'
import MixPanel from '@/common/MixPanel'
import 'url-search-params-polyfill'
import store from './store'
import _ from 'lodash'
import VTooltip from 'v-tooltip'
import '@/assets/sass/v-tooltip.scss'

import VueI18n from 'vue-i18n'
import locales from '@/locales'

import VueSocketIO from 'vue-socket.io'
import Paginate from 'vuejs-paginate'
import OtpInput from '@bachdgvn/vue-otp-input'

Object.defineProperty(Vue.prototype, '_', { value: _ })

Vue.config.productionTip = true

Vue.component('data-table', DataTable)
Vue.component('data-table2', DataTable2)
Vue.component('data-table3', DataTable3)
Vue.component('data-table4', DataTable4)
Vue.component('data-list-mobile', DataListMobile)
Vue.component('data-table-landing-page', DataTableLandingPage)
Vue.component('fiat-currency', FiatCurrency)
Vue.component('paginate', Paginate)
Vue.component('v-otp-input', OtpInput)

Vue.use(VueRouter)

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'en',
  messages: locales,
})

Vue.use(
  new VueSocketIO({
    debug: false,
    connection: process.env.VUE_APP_SOCKET_URL || '',
    vuex: {
      store,
    },
  })
)

Vue.use(VeeValidate, {
  i18nRootKey: 'validation', // customize the root path for validation messages.
  i18n,
  locale: localStorage.getItem('locale') || 'en',
  dictionary: locales,
})
Vue.use(VTooltip, {
  defaultTrigger: window.innerWidth > 768 ? 'hover focus click' : 'click',
})
VTooltip.options.autoHide = false
VTooltip.options.defaultClass = 'cs-tooltip'

Vue.use(FullScreen)
Vue.use(VueBroadcast)
Vue.use(VueRequestCanceler)
Vue.use(VueClipboard)
Vue.use(SiteSupport)
Vue.use(MixPanel)
Vue.use(VueAwesomeSwiper)

Vue.component('multiselect', Multiselect)
Vue.directive('click-outside', ClickOutside)
Vue.component('currency-input', CurrencyInput)
Vue.component('input-only-number', InputOnlyNumber)
Vue.component('input-only-number-2', InputOnlyNumber2)
Vue.component('input-text-number', InputTextAndNumber)

Vue.mixin({
  mounted: function () {
    if (this.getEventHandlers) {
      window._.forIn(this.getEventHandlers(), (handler, eventName) => {
        this.$on(eventName, handler)
      })
    }
    if (this.validate) {
      this.$on('UPDATED_LOCALE', () => {
        if (this.errors.count() > 0) {
          this.validate()
        }
      })
    }
  },
  beforeDestroy() {
    if (this.getEventHandlers) {
      window._.forIn(this.getEventHandlers(), (handler, eventName) => {
        this.$off(eventName, handler)
      })
    }
    if (this.validate) {
      this.$off('UPDATED_LOCALE')
    }
  },
})

const router = new VueRouter({
  ...Routers,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
      }
    } else {
      return { x: 0, y: 0 }
    }
  },
})

let isMixPanelInitialized = false

export var user = null
window.GlobalSocket = new GlobalSocket()
router.beforeEach(async (to, from, next) => {
  window.i18n.locale = AuthenticationUtils.getLocale(document.documentElement.lang)
  document.title = window.i18n.t('window_title')

  let url = window.location.href
  let redirectRouteName = null
  let urlSearch = new URLSearchParams(window.location.search)

  if (url.search('login') > 0 && url.search('return_to') > 0) {
    redirectRouteName = urlSearch.get('return_to')
  }
  if (to.meta.auth && !window.isAuthenticated) {
    return next({ path: '/login' })
  }

  if (to.meta.guest && window.isAuthenticated) {
    if (redirectRouteName) {
      let link = await getLink(redirectRouteName)
      window.location.href = link
      return null
    } else {
      if (
        (url.search('login') && urlSearch.get('code')) ||
        (url.search('login') && urlSearch.get('device')) ||
        (url.search('reset-password') && urlSearch.get('token'))
      ) {
        return next()
      }
      return next({ path: '/' })
    }
  }
  return next()
})

async function getLink(url) {
  let res = await rf.getRequest('UserRequest').getCurrentUser()
  let urlToZendesk = await rf
    .getRequest('ZendeskRequest')
    .autoLogin(decodeURIComponent(url), res.data.email)
  return urlToZendesk.data
}

router.afterEach((to, from) => {
  /**
   * ------------------------------------------------------------------
   * -------------- Mixpanel route tracking---------------------------
   * ------------------------------------------------------------------
   */
  const trackRoute = () => {
    if (from && from.name) {
      router.app.$mixpanel.track(`Route - ${to.name}`, {
        ...to,
        prevName: from.name,
        prevFullPath: from.fullPath,
      })
    } else {
      router.app.$mixpanel.track(`Route - ${to.name}`, { ...to })
    }
  }

  if (router.app && router.app.$mixpanel && to && to.name) {
    if (from && from.name === to.name) {
      return
    }

    if (isMixPanelInitialized) {
      // trackRoute();
      return
    }

    router.app.$mixpanel.init(Const.MIXPANEL_TOKEN)
    isMixPanelInitialized = true

    if (window.isAuthenticated) {
      // Mixpanel set current user
      rf.getRequest('UserRequest')
        .getCurrentUser()
        .then((response) => {
          const currentUser = response.data
          const userId = currentUser.id

          router.app.$mixpanel.identify(userId)
          router.app.$mixpanel.people.set({
            ...currentUser,
            $id: currentUser.id,
          })
        })
    }

    // trackRoute();
  }
  /**
   * ------------------------------------------------------------------
   * -------------- Finish. Mixpanel route tracking-------------------
   * ------------------------------------------------------------------
   */
})

Vue.mixin({
  data() {
    return {
      tabTitle: process.env.VUE_APP_SHORT_NAME,
      scrollY: 0,
      isSubmitting: false,
    }
  },
  methods: {
    startSubmit() {
      this.isSubmitting = true
    },
    endSubmit() {
      this.isSubmitting = false
    },
    getSubmitName(name) {
      return this.isSubmitting ? this.$t('common.processing') : name
    },
    openPageOverflow() {
      const body = document.body
      body.classList.add('no-scroll')
      this.scrollY = window.scrollY
      $('html').addClass('modal-open').css({
        marginTop: -this.scrollY,
        overflow: 'hidden',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: 'fixed',
      })
    },
    removePageOverflow() {
      const body = document.body
      body.classList.remove('no-scroll')
      $('html').removeClass('modal-open').css({
        marginTop: 0,
        overflow: 'visible',
        left: 'auto',
        right: 'auto',
        top: 'auto',
        bottom: 'auto',
        position: 'static',
      })
      window.scrollTo({ left: 0, top: this.scrollY, behavior: 'instant' })
      this.scrollY = 0
    },
    scrollItemToTop(classItem) {
      const elementToScroll = document.querySelector(classItem)
      if (elementToScroll)
        elementToScroll.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        })
    },
  },
})

window.app = new Vue({
  i18n,
  router,
  store,
  render: function (createElement) {
    return createElement(App)
  },
}).$mount('#app')

window.app.tabTitle = process.env.VUE_APP_SHORT_NAME + `${window.app.$t('window.suffix_title')}`
