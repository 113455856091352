<template>
  <div class="content-box third">
    <div class="title-step">{{ $t('account.otp.backup_title') }}</div>
    <div class="backup-key d-i-block">
      <p class="text-bold">{{ $t('account.otp.backup_text2') }}</p>
      <p class="text-grey">{{ $t('account.otp.backup_text1') }}</p>
    </div>
    <div class="show-key">
      <p type="text" class="recovery-code" name="recovery_code">{{ key2fa }}</p>
    </div>
  </div>
  <!-- end third -->
</template>
<script>
export default {
  props: {
    key2fa: { type: String, default: '' },
  },
}
</script>
<style lang="scss"></style>