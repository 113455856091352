<template>
  <div class="table_witdhdrar_usd" id="table_witdhdrar_usd">
    <template v-if="isMobile">
      <div class="transaction-history__mobile-wrapper">
        <data-list-mobile
          ref="datatable"
          :msg-empty-data="''"
          :get-data="getData"
          :isExpand="true"
          :limit="10"
          :height="30"
        >
          <template slot="table-empty-header">
            <thead>
              <tr>
                <th class="cl_amount">
                  <span class="sort">{{
                    $t("deposit_fiat.history_table.amount")
                  }}</span>
                  /
                  <span class="sort">{{
                    $t("funds.deposit_fiat.transaction_history_table.status")
                  }}</span>
                </th>
                <th class="cl_bank" style="text-align: start">
                  <span class="sort">{{
                    $t("funds.deposit_fiat.transaction_history_table.bank")
                  }}</span>
                  /
                  <span class="sort">{{
                    $t("funds.deposit_fiat.transaction_history_table.account")
                  }}</span>
                  <img
                    src="/images/icon/Question.svg"
                    alt=""
                    style="transform: translateY(-2px); padding-left: 4px"
                    v-tooltip="$t('funds.deposit_fiat.copy_account_number')"
                  />
                </th>
              </tr>
            </thead>
          </template>
          <template slot="header" slot-scope="props">
            <div class="transaction-history__mobile-title">
              <div
                class="mobile-title__item"
                data-sort-field="amount"
                @click="props.onSort"
              >
                {{ $t("deposit_fiat.history_table.amount") }}/{{
                  $t("deposit_fiat.history_table.status")
                }}
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              </div>
              <div class="mobile-title__item">
                <span class="nowrap"
                  >{{
                    $t("funds.deposit_fiat.transaction_history_table.bank")
                  }}/{{
                    $t("funds.deposit_fiat.transaction_history_table.account")
                  }}</span
                ><img
                  src="/images/icon/Question.svg"
                  alt=""
                  style="transform: translateY(-2px); padding-left: 4px"
                  v-tooltip="$t('funds.deposit_fiat.copy_account_number')"
                />
              </div>
            </div>
          </template>
          <template slot="body" slot-scope="props">
            <div class="transaction-history__mobile-item">
              <div class="mobile-item__wrapper">
                <div class="item-wrapper__title">
                  <span>{{
                    props.item.amount | abs | formatCurrencyAmount("idr", "0")
                  }}</span>
                  <br />
                  <strong
                    v-if="props.item.status == 'pending'"
                    class="pending"
                    >{{ $t("common.transaction_status.pending") }}</strong
                  >
                  <strong
                    v-if="props.item.status == 'success'"
                    class="success"
                    >{{ $t("common.transaction_status.success") }}</strong
                  >
                  <strong
                    v-if="props.item.status == 'cancelled'"
                    class="cancelled"
                    >{{ $t("common.transaction_status.cancel") }}</strong
                  >
                  <strong
                    v-if="props.item.status == 'rejected'"
                    class="rejected"
                    >{{ $t("common.transaction_status.rejected") }}</strong
                  >
                </div>
                <div class="item-wrapper__title">
                  <span>{{
                    `${getbankName(props.item.bank_name) || "--"}`
                  }}</span>
                  <br />
                  <span>{{ `${props.item.account_no || "--"}` }}</span>
                </div>
                <div
                  class="item-wrapper__dropdown-icon"
                  @click="props.onClickExpandItem(props)"
                >
                  <img
                    src="/images/instant-convert/icon-dropdown.svg"
                    alt=""
                    v-if="!props.isActive"
                  />
                  <img
                    src="/images/instant-convert/icon-dropdown2.svg"
                    alt=""
                    v-else
                  />
                </div>
              </div>
              <div
                class="mobile-item__expand-content"
                :class="{ active: props.isActive }"
              >
                <div class="expand-content__wrapper">
                  <div class="expand-content__title">
                    {{
                      $t("funds.deposit_fiat.transaction_history_table.time")
                    }}
                  </div>
                  <div class="expand-content__desc">
                    <span>{{ props.item.created_at | orderDate }} </span>
                    <span>{{ props.item.created_at | orderTime }}</span>
                  </div>
                </div>
                <div class="expand-content__wrapper">
                  <div class="expand-content__title">
                    {{ $t("withdraw_fiat.fee") }}
                  </div>
                  <div class="expand-content__desc">
                    {{
                      props.item.fee | abs | formatCurrencyAmount("idr", "0")
                    }}
                  </div>
                </div>
                <div class="expand-content__wrapper">
                  <div class="expand-content__title">
                    {{ $t("withdraw_fiat.tax") }}
                  </div>
                  <div class="expand-content__desc">
                    {{
                      props.item.tax | abs | formatCurrencyAmount("idr", "0")
                    }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </data-list-mobile>
      </div>
    </template>
    <template v-else>
      <data-table3
        :getData="getData"
        :limit="10"
        :widthTable="'100%'"
        ref="datatable"
        :msgEmptyData="msgEmptyData"
      >
        <template slot-scope="props">
          <th
            data-sort-field="created_at"
            class="cl_time"
            @click="props.echoclick"
          >
            {{ $t("funds.deposit_fiat.transaction_history_table.time") }}
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th
            data-sort-field="amount"
            class="cl_amount"
            @click="props.echoclick"
          >
            <!-- <span class="sort">{{$t('funds.deposit_fiat.transaction_history_table.bank')}}</span>
            &nbsp;/&nbsp;
            <span class="sort">{{$t('funds.deposit_fiat.transaction_history_table.account')}}</span>-->
            <span class="sort">{{
              $t("funds.deposit_fiat.transaction_history_table.received_amount")
            }}</span>
            /
            <span class="sort">{{
              $t("funds.deposit_fiat.transaction_history_table.status")
            }}</span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th class="cl_fee">{{ $t("withdraw_fiat.fee") }}</th>
          <th class="cl_tax" v-if="isIndoAccount">
            {{ $t("withdraw_fiat.tax") }}
          </th>
          <th class="cl_bank" style="text-align: start">
            <span class="sort">{{
              $t("funds.deposit_fiat.transaction_history_table.bank")
            }}</span>
            /
            <span class="sort">{{
              $t("funds.deposit_fiat.transaction_history_table.account")
            }}</span>
            <img
              src="/images/icon/Question.svg"
              alt=""
              style="transform: translateY(-2px); padding-left: 4px"
              v-tooltip="$t('funds.deposit_fiat.copy_account_number')"
            />
          </th>
          <!-- <th style="width: 20%" class="t-right">
            <span class="sort">{{$t('funds.deposit_fiat.pending_transaction.code')}}</span>
          </th> -->
        </template>
        <template slot="body" slot-scope="props">
          <tr>
            <td style="width: 20%" class="t-left">
              <span>{{ props.item.created_at | orderDate }}</span>
              <span>{{ props.item.created_at | orderTime }}</span>
              <!-- <span>{{props.item}}</span> -->
            </td>
            <td class="t-left">
              <span>{{
                props.item.amount | abs | formatCurrencyAmount("idr", "0")
              }}</span>
              <span v-if="props.item.status == 'pending'" class="pending">{{
                $t("common.transaction_status.pending")
              }}</span>
              <span v-if="props.item.status == 'success'" class="success">{{
                $t("common.transaction_status.success")
              }}</span>
              <span v-if="props.item.status == 'cancelled'" class="cancelled">{{
                $t("common.transaction_status.cancel")
              }}</span>
              <span v-if="props.item.status == 'rejected'" class="rejected">{{
                $t("common.transaction_status.rejected")
              }}</span>
              <!-- <span>{{props.item}}</span> -->
            </td>
            <td class="t-left">
              <span>{{
                props.item.fee | abs | formatCurrencyAmount("idr", "0")
              }}</span>
            </td>
            <td class="t-left" v-if="isIndoAccount">
              <span>{{
                props.item.tax | abs | formatCurrencyAmount("idr", "0")
              }}</span>
            </td>
            <td class="t-left">
              <div class="item_email_user">
                <span class="t-left">{{
                  `${getbankName(props.item.bank_name) || "--"}`
                }}</span>
                <span class="t-left">{{
                  `${props.item.account_no || "--"}`
                }}</span>
                <!-- <span class="tooltip_email_user">
                  <span
                    >{{ props.item.bank_name }}{{ " - "
                    }}{{ props.item.bank_branch }}</span
                  >
                  <br />
                  <span class="text-not-bold"
                    >{{ props.item.account_name }}{{ " - "
                    }}{{ props.item.account_no }}</span
                  >
                </span> -->
              </div>
            </td>
            <!-- <td style="width: 20%;" class="t-left">
              <span>{{ props.item.code}}</span> -->
            <!-- <span v-bind:class="[{cancel: isPending}]"></span>
              -->
            <!-- <button v-if="props.item.status== 'pending'" class="cancel" @click="cancel(props.item.id)">{{$t('notice.cancel')}}</button> -->
            <!-- </td> -->
          </tr>
        </template>
      </data-table3>
    </template>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      msgEmptyData: this.$i18n.t("funds.withdrawals.no_fiat_data"),
    };
  },
  props: {
    kyc: Object,
  },
  computed: {
    isIndoAccount() {
      return (
        this.kyc?.country === "Indonesia" && this.kyc.status === "verified"
      );
    },
    isMobile() {
      if (!this.windowWidth) return;
      return this.windowWidth <= 768;
    },
  },
  methods: {
    getbankName(name) {
      switch (name) {
        case "bca":
          return "BCA";
        case "mandiri":
          return "Mandiri";
        case "permata":
          return "Permata";
        case "cimb":
          return "CIMB";
        case "bni":
          return "BNI";
        case "bri":
          return "BRI";
        default:
          return "";
      }
    },
    async getData(params) {
      const meta = {
        type: "withdraw",
      };
      return rf
        .getRequest("TransactionRequest")
        .getUsdTransactions(Object.assign({}, params, meta));
    },
    getSocketEventHandlers() {
      return {
        TransactionCreated: this.onTransactionCreated,
      };
    },
    cancel(transactionId) {
      console.log(transactionId);
      rf.getRequest("TransactionRequest")
        .cancelUsdDepositTransaction(transactionId)
        .then((res) => {})
        .catch(function (error) {});
    },

    onTransactionCreated() {
      this.$refs.datatable.refresh();
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  created() {
    this.$on("UPDATED_LOCALE", () => {
      this.msgEmptyData = this.$i18n.t("funds.withdrawals.no_fiat_data");
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
};
</script>

<style lang="scss" scoped>
.f-left {
  text-align: left;
}
.t-cnter {
  text-align: center;
}
.f-right {
  float: right;
  text-align: right;
}
.pending {
  color: $color-yellow !important;
  font-weight: 700 !important;
}
.cancelled {
  color: $color-red !important;
  font-weight: 700 !important;
}
.rejected {
  font-weight: 700 !important;
  color: $color-denim !important;
}
.success {
  font-weight: 700 !important;
  color: $color-green !important;
}
.cancel {
  font-weight: 700 !important;
  color: $color-red !important;
}
th.t-left::after {
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

table tr td {
  vertical-align: middle;
}

.table_witdhdrar_usd {
  border-radius: 10px;
  .tableContainer {
    overflow: auto;
    :deep() {
      table {
        border: 0px;
        thead {
          tr {
            th {
              padding: 24px 5px 8px 5px;
              height: auto;
              font-size: 14px;
              font-weight: 700;
              line-height: 24px;
              color: $text-main2;
              opacity: 0.6;
              background: #f8f8f8;
              border-bottom: none;
              &:nth-child(1) {
                padding-left: 24px;
                min-width: 112px;
              }
              &:nth-child(2) {
                min-width: 148px;
              }
              &:nth-child(4),
              &:nth-child(3) {
                width: 90px;
              }
              &:last-child {
                padding-right: 24px;
                text-align: right;
                min-width: unset;
                max-width: unset;
              }
              &.cl_bank {
                min-width: 155px;
              }
            }
          }
        }
        tbody {
          background: white;
          tr {
            &:first-child td {
              padding-top: 15px;
            }
            &:last-child td {
              padding-bottom: 15px;
            }
            &:hover {
              background: white;
            }
            td {
              padding: 8px 5px;
              &:first-child {
                padding-left: 24px;
              }
              &:last-child {
                padding-right: 24px;
              }
              span {
                display: block;
                width: 100%;
                font-weight: 300;
                font-size: 16px;
                line-height: 24px;
                color: $text-main;
                font-family: $helvetica;
              }
            }
            // &:nth-child(odd) {
            //   background: $row-coin-value-background-color;
            // }
            // &:nth-child(even) {
            //   background: $background-default;
            // }
          }
        }
        .text-bold-account {
          text-overflow: ellipsis;
          width: 100%;
        }
        .text-not-bold-account {
          color: $text-grey-dusty;
          text-overflow: ellipsis;
          width: 100%;
        }
      }
    }
  }
}
table {
  border: 0px;
  min-width: 600px;
}
// .cl_amount {
//   &[data-sort-order="asc"] {
//     color: $color-jungle-green;
//     &::after {
//       content: "\e907" !important;
//     }
//   }
//   &[data-sort-order="desc"] {
//     color: $color-jungle-green;
//     &::after {
//       content: "\e906" !important;
//     }
//   }
// }
.item_email_user {
  display: block;
  position: relative;
  .txt_email_user {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .tooltip_email_user {
    position: absolute;
    top: 50%;
    right: 20px;
    translate: 0 -50%;
    z-index: 100;
    display: none !important;
    padding: 5px 20px;
    @include text-font($helvetica, $color-grey-dark, $font_root, 500, 20px);
    white-space: nowrap;
    background-color: $color_white;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    span {
      word-wrap: break-word;
      white-space: normal;
    }
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -10px;
      translate: 0 -50%;
      border: 5px solid transparent;
      border-left-color: $color_white;
      pointer-events: none;
    }
  }
  &:hover {
    .tooltip_email_user {
      display: block !important;
      transition: 0.5s;
    }
  }
}
.transaction-history__mobile-wrapper {
  .data-list__wrapper {
    .transaction-history__mobile-title {
      display: grid;
      border-radius: 10px 10px 0px 0px;
      background-color: $bg-main4;
      padding: 8px 16px;
      grid-template-columns: repeat(3, 1fr);
      .mobile-title__item {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        color: $text-secondary;
        &.text-right {
          text-align: right;
          padding-right: 30px;
        }
        .nowrap {
          white-space: nowrap;
        }
      }
    }
    .transaction-history__mobile-item {
      background-color: $color-white;
      padding: 10px 16px;
      border-bottom: 1px solid $bg-main2;
      .mobile-item__wrapper {
        display: grid;
        position: relative;
        grid-template-columns: repeat(3, 1fr);
        .item-wrapper__title {
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          color: $text-main;
        }
        .item-wrapper__dropdown-icon {
          position: absolute;
          right: 0px;
        }
      }
      .mobile-item__expand-content {
        display: none;
        &.active {
          display: flex;
        }
        margin-top: 12px;
        background-color: $bg-main4;
        padding: 16px;
        flex-direction: column;
        border-radius: 8px;
        gap: 8px;
        .expand-content__wrapper {
          display: flex;
          justify-content: space-between;
          .expand-content__title {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            color: $text-main;
          }
          .expand-content__desc {
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
            color: $text-main;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
#table_witdhdrar_usd {
  border-radius: 10px;
  overflow: hidden;
  min-width: 574px;
  @media (max-width: 1024px) {
    min-width: 700px;
  }
  @include tablet {
    min-width: unset;
  }
  @include mobile {
    min-width: unset;
  }
  .tableContainer {
    position: relative;
    @include mobile {
      & > :nth-child(2) {
        width: 100%;
        position: sticky;
        left: 0;
        text-align: center !important;
      }
    }
  }
  td {
    overflow: unset;
  }
}
</style>
