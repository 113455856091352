<template>
  <div id="lost-otp-modal">
    <modal
      :enableClose="false"
      :name="name"
      maxWidth="404"
      :hasModalFooter="false"
      @onShow="onShowModal"
    >
      <template slot="body" slot-scope="props">
        <div class="modal-header-custom">
          <img
            @click="closeModal"
            src="/images/close.svg"
            class="close-icon"
            alt="close"
          />
        </div>
        <div id="lost_otp_form">
          <h3 class="title-header-OPT">
            {{ $t("account.modal.lost_otp.title") }}
          </h3>
          <p
            class="note_header"
            v-html="$t('account.modal.lost_otp.guide')"
          ></p>
          <div class="input-group">
            <div
              class="form-input"
              :class="{ error: errors.has('otp_recovery_code') }"
            >
              <input
                @focus="resetErrors"
                v-model="otp_recovery_code"
                :placeholder="$t('account.modal.lost_otp.otp_restore')"
                type="text"
                maxlength="16"
                :data-vv-as="$t('validation.attributes.recovery_code')"
                name="otp_recovery_code"
                data-vv-validate-on="recovery_code"
              />
            </div>
            <div class="invalid-feedback">
              <span v-if="errors.has('otp_recovery_code')">
                {{ $t(errors.first("otp_recovery_code")) }}
              </span>
            </div>
          </div>
          <div class="btn-group">
            <button @click="delOtpRecoveryCode(props.configs)">
              {{ $t("account.modal.lost_otp.stop_use_otp") }}
            </button>
          </div>
          <div class="link-primary-OTP" @click="showLostOtpPolicyModal">
            {{ $t("account.modal.lost_otp.otp_policy") }}
          </div>
        </div>
      </template>
    </modal>
    <lost-otp-policy
      :name="`${this.name}_lostOtpPolicyModal`"
      :contact_email="contact_email"
      @onShow="onShowModal"
    />
  </div>
</template>
<script>
import Modal from "../common/Modal";
import rf from "@/request/RequestFactory";
import COMMON_CONST from "@/common/Const";
import LostOtpPolicy from "../otp/LostOtpPolicy";

export default {
  components: {
    Modal,
    maxlength: COMMON_CONST.MAX_LENGTH_INPUT,
    LostOtpPolicy,
  },

  data() {
    return {
      otp_recovery_code: "",
    };
  },

  props: {
    name: { type: String, default: "lostOtpForm" },
    contact_email: { type: String, default: "" },
  },

  methods: {
    showLostOtpPolicyModal() {
      this.errors.clear();
      CommonModal.hide(this.name);
      CommonModal.show(`${this.name}_lostOtpPolicyModal`);
    },

    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },

    closeModal() {
      CommonModal.hide(this.name);
    },

    async delOtpRecoveryCode(configs) {
      this.errors.clear();

      if (!this.otp_recovery_code) {
        this.errors.add("otp_recovery_code", this.$t("sms_otp_required"));
        $(".btn-success").blur();
        return;
      }

      if (this.errors.any()) {
        $(".btn-success").blur();
        return;
      }

      let params = {
        otp_recovery_code: this.otp_recovery_code,
      };

      if (window.isAuthenticated) {
        rf.getRequest("UserRequest")
          .delRecoveryCodeWithAuth(params)
          .then((res) => {
            this.onDelRecoveryCode(this.$t(res.message));
            this.$emit("DISABLE_OTP_SUCCESS");
          })
          .catch((error) => {
            const { message, success } = error.response.data;
            if (!success) this.errors.add("otp_recovery_code", message);
          });
      } else {
        const data = configs.data || {};
        params = Object.assign(params, data);
        rf.getRequest("UserRequest")
          .disableOtpAuthentication(params)
          .then((res) => {
            this.onDelRecoveryCode(this.$t(res.message));
            this.$emit("DISABLE_OTP_SUCCESS");
          });
      }
    },

    onErrors(errors) {
      this.errors.clear();
      _.forEach(errors, (messages, field) => {
        _.forEach(messages, (message) => {
          this.errors.add(field, message);
        });
      });
    },

    onDelRecoveryCode(message) {
      this.errors.clear();
      CommonModal.hide(this.name);
      Message.success(message, {}, { position: "bottom_left" });
    },

    onShowModal(val) {
      this.$emit("onShow", val);
    },
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      this.errors.clear();
    }),
      this.$on("hideCommonModal", (name) => {
        if (name === this.name) {
          this.otp_recovery_code = "";
          this.errors.clear();
        }
      });
  },
};
</script>
<style lang="scss" scoped>
#lost-otp-modal {
  :deep .modal .modal-dialog .modal-content .modal-body {
    padding: 24px;
  }
  :deep(#lostOtpForm) {
    .modal-dialog {
      // left: 0;
    }
  }
}
.close-icon {
  cursor: pointer;
}
#lost_otp_form {
  text-align: center;

  .title-header-OPT {
    margin-bottom: 8px;
    margin-top: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    color: rgba($text-main2, 0.87);
  }
  .note_header {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: rgba($text-main2, 0.6);
    margin: 0;
  }
  .input-group {
    margin-top: 24px;
    margin-bottom: 16px;
    .form-input {
      input,
      input::placeholder {
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
      }
    }
  }

  .btn-group {
    width: 100%;
    margin-bottom: 24px;
    button {
      @include btn-common(contained, md);
    }
  }

  .link-primary-OTP {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: $text-main4;
    cursor: pointer;
  }
}

.modal-header-custom {
  position: absolute;
  right: 15px;
  top: 15px;
}
</style>
