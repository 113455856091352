const instantConvertHistoryHeader = [
  {
    id: "created_at",
    name: "instant_convert.history.date",
  },
  {
    id: "traded_token",
    name: "instant_convert.history.traded_token",
  },
  {
    id: "received_token",
    name: "instant_convert.history.received_token",
  },
  {
    id: "traded_amount",
    name: "instant_convert.history.traded_amount",
  },
  {
    id: "conversion_rate",
    name: "instant_convert.history.conversion_rate",
  },
  {
    id: "received_amount",
    name: "instant_convert.history.received_amount",
  },
  {
    id: "fee",
    name: "instant_convert.history.fee",
  },
];

export default {
  CONVERT_HISTORY_HEADER: instantConvertHistoryHeader,
};
