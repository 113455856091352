<template>
  <div id="convert-history-screen">
    <div class="box-title">{{ $t("instant_convert.history.page_title") }}</div>
    <div id="convert-history" class="page_history">
      <!-- Actions -->
      <div class="group-control">
        <div class="group_1">
          <!-- Datepicker filter -->
          <div class="group-date-picker">
            <!-- Date from -->
            <div class="group-date">
              <div class="label-text">
                {{ $t("instant_convert.history.from") }}
              </div>
              <datepicker
                :language="datePickerLang"
                :value="dateFromDefault"
                class="date-from"
                @selected="selectDateFrom"
                :format="customFormatter"
                input-class="date-picker-input"
              ></datepicker>
            </div>
            <!-- Date to -->
            <div class="group-date">
              <div class="label-text">
                {{ $t("instant_convert.history.to") }}
              </div>
              <datepicker
                :language="datePickerLang"
                :value="dateToDefault"
                class="date-to"
                @selected="selectDateTo"
                :format="customFormatter"
                input-class="date-picker-input"
              ></datepicker>
            </div>
          </div>

          <!-- Coins filter -->
          <div class="group-pair">
            <div class="content-search">
              <div class="toolbar">
                <div class="toolbar-element">
                  <div class="toolbar-title">
                    {{ $t("instant_convert.history.traded_token") }}
                  </div>
                  <select-box
                    v-model="filters.coin"
                    :placeholder="$t('instant_convert.history.all')"
                    :options="coinMasters"
                  />
                </div>
                <div class="toolbar-element">
                  <div class="toolbar-title">
                    {{ $t("instant_convert.history.received_token") }}
                  </div>
                  <select-box
                    v-model="filters.currency"
                    :placeholder="$t('instant_convert.history.all')"
                    :options="coinMasters"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Search & Reset buttons -->
          <div class="group-search">
            <button class="button-search" @click="actionSearch">
              {{ $t("instant_convert.history.search") }}
            </button>
            <button class="button-reset" @click="actionReset">
              {{ $t("instant_convert.history.reset") }}
            </button>
          </div>
        </div>

        <!-- Export file -->
        <div class="export-file">
          <a
            class="excel_export"
            @click="downloadReport()"
            @mouseenter="mouseOver()"
            @mouseleave="mouseOver()"
          >
            <!-- <span class="icon-export" /> -->
            <img class="icon-export" alt="" src="/images/icon/export.svg" />
            {{ $t("instant_convert.history.export") }}
            <!-- <div v-show="isShowToolTip" class="export-file-tooltip">
              {{ $t('instant_convert.history.export_tooltip') }}
            </div> -->
          </a>
        </div>
      </div>
      <!-- Data list -->
      <template v-if="isMobile">
        <div class="convert-history__mobile-wrapper">
          <data-list-mobile
            ref="datatable"
            :msg-empty-data="''"
            :get-data="getData"
            :isExpand="true"
            :limit="10"
            :height="30"
          >
            <template slot="table-empty-header">
              <thead>
                <tr>
                  <th class="mobile-title__item">
                    {{ $t("instant_convert.history.date") }}
                    <i class="group-sort-tabl3"></i>
                  </th>
                  <th class="mobile-title__item">
                    {{ $t("instant_convert.history.traded_token")
                    }}<i class="group-sort-tabl3"></i>
                  </th>
                  <th
                    class="mobile-title__item"
                    data-sort-field="received_token"
                  >
                    {{ $t("instant_convert.history.received_token")
                    }}<i class="group-sort-tabl3"></i>
                  </th>
                </tr>
              </thead>
            </template>
            <template slot="header" slot-scope="props">
              <div class="convert-history__mobile-title">
                <div
                  class="mobile-title__item"
                  data-sort-field="created_at"
                  @click="props.onSort"
                >
                  {{ $t("instant_convert.history.date") }}
                  <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                </div>
                <div
                  class="mobile-title__item"
                  data-sort-field="traded_token"
                  @click="props.onSort"
                >
                  {{ $t("instant_convert.history.traded_token")
                  }}<i
                    class="group-sort-tabl3"
                    v-html="props.innerHtmlSort"
                  ></i>
                </div>
                <div
                  class="mobile-title__item"
                  data-sort-field="received_token"
                  @click="props.onSort"
                >
                  {{ $t("instant_convert.history.received_token")
                  }}<i
                    class="group-sort-tabl3"
                    v-html="props.innerHtmlSort"
                  ></i>
                </div>
              </div>
            </template>
            <template slot="body" slot-scope="props">
              <div class="convert-history__mobile-item">
                <div class="mobile-item__wrapper">
                  <div class="item-wrapper__title">
                    {{ formatDate(props.item.created_at) }}
                  </div>
                  <div class="item-wrapper__title">
                    {{ _.get(props.item, "traded_token") | uppercase }}
                  </div>
                  <div class="item-wrapper__title">
                    {{ _.get(props.item, "received_token") | uppercase }}
                  </div>
                  <div
                    class="item-wrapper__dropdown-icon"
                    @click="props.onClickExpandItem(props)"
                  >
                    <img
                      src="/images/instant-convert/icon-dropdown.svg"
                      alt=""
                      v-if="!props.isActive"
                    />
                    <img
                      src="/images/instant-convert/icon-dropdown2.svg"
                      alt=""
                      v-else
                    />
                  </div>
                </div>
                <div
                  class="mobile-item__expand-content"
                  :class="{ active: props.isActive }"
                >
                  <div class="expand-content__wrapper">
                    <div class="expand-content__title">
                      {{ $t("instant_convert.history.traded_amount") }}
                    </div>
                    <div class="expand-content__desc">
                      {{
                        formatAmount(
                          props.item.traded_amount,
                          props.item.traded_token
                        )
                      }}
                    </div>
                  </div>
                  <div class="expand-content__wrapper">
                    <div class="expand-content__title">
                      {{ $t("instant_convert.history.conversion_rate") }}
                    </div>
                    <div class="expand-content__desc">
                      {{
                        props.item.conversion_rate
                          | formatCurrencyAmount(props.item.received_token, "0")
                      }}
                    </div>
                  </div>
                  <div class="expand-content__wrapper">
                    <div class="expand-content__title">
                      {{ $t("instant_convert.history.received_amount") }}
                    </div>
                    <div class="expand-content__desc">
                      {{
                        formatAmount(
                          props.item.received_amount,
                          props.item.received_token
                        )
                      }}
                    </div>
                  </div>
                  <div class="expand-content__wrapper">
                    <div class="expand-content__title">
                      {{ $t("instant_convert.history.fee") }}
                    </div>
                    <div class="expand-content__desc">
                      {{
                        props.item.fee
                          | formatCurrencyAmount(props.item.received_token, "0")
                      }}
                      {{ _.get(props.item, "received_token") | uppercase }}
                    </div>
                  </div>
                  <div class="expand-content__wrapper">
                    <div class="expand-content__title">
                      {{ $t("instant_convert.history.tax") }}
                    </div>
                    <div class="expand-content__desc">
                      {{
                        props.item.tax
                          | formatCurrencyAmount(props.item.received_token, "0")
                      }}
                      {{ _.get(props.item, "received_token") | uppercase }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </data-list-mobile>
        </div>
      </template>
      <template v-else>
        <div class="data-table">
          <data-table3
            ref="datatable"
            :getData="getData"
            :msg-empty-data="$t('instant_convert.history.no_data')"
            class="datatable"
            id="convert-table"
            :limit="10"
            :height="20"
          >
            <template slot-scope="props">
              <th
                v-for="(item, index) in headerTable"
                :title="$t(item && item.name)"
                :key="item.id"
                :data-sort-field="item && item.id ? item.id : null"
                :class="`cl-${index + 1}`"
                @click="props.echoclick"
              >
                <span>{{ $t(item.name) }}</span>
                <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
              </th>
            </template>
            <template slot="body" slot-scope="props">
              <tr
                :class="{
                  canceled: _.get(props.item, 'status') === 'canceled',
                }"
              >
                <td class="cl-1">
                  {{ formatDate(props.item.created_at) }}
                </td>
                <td class="cl-2">
                  <span>{{
                    _.get(props.item, "traded_token") | uppercase
                  }}</span>
                </td>
                <td class="cl-3">
                  <span>{{
                    _.get(props.item, "received_token") | uppercase
                  }}</span>
                </td>
                <td class="cl-5" :title="props.item.traded_amount">
                  {{
                    formatAmount(
                      props.item.traded_amount,
                      props.item.traded_token
                    )
                  }}
                </td>
                <td class="cl-4" :title="props.item.conversion_rate">
                  {{
                    props.item.conversion_rate
                      | formatCurrencyAmount(props.item.received_token, "0")
                  }}
                </td>
                <td class="cl-5" :title="props.item.received_amount">
                  {{
                    formatAmount(
                      props.item.received_amount,
                      props.item.received_token
                    )
                  }}
                </td>
                <td class="cl-6" :title="props.item.fee">
                  {{
                    props.item.fee
                      | formatCurrencyAmount(props.item.received_token, "0")
                  }}
                  {{ _.get(props.item, "received_token") | uppercase }}
                </td>
                <td
                  class="cl-7"
                  v-if="isUserIndoAndKyc"
                  :title="props.item.tax"
                >
                  {{
                    props.item.tax
                      | formatCurrencyAmount(props.item.received_token, "0")
                  }}
                  {{ _.get(props.item, "received_token") | uppercase }}
                </td>
              </tr>
            </template>
          </data-table3>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { en, id } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
import COMMON_CONST from "@/common/Const";
import Utils from "@/common/Utils";
import MasterdataUtils from "@/common/MasterdataUtils";
import rf from "@/request/RequestFactory";
import CONVERT_HISTORY_HEADER_CONST from "@/components/finance_pages/DataTableInit";
import SelectBox from "@/components/shared_components/common/SelectBox.vue";

export default {
  name: "ConvertHistory",
  components: {
    Datepicker,
    SelectBox,
  },
  props: {
    coinCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_COIN,
    },
    currencyCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_CURRENCY,
    },
    // hiddenLogoCrypto: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  computed: {
    isMobile() {
      if (!this.windowWidth) return;
      return this.windowWidth <= 768;
    },
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === "storage_url")
        .value;
    },
    isUserIndoAndKyc() {
      return this.kyc.country === "Indonesia" && this.kyc.status === "verified";
    },
    headerTable() {
      if (this.isUserIndoAndKyc) {
        return [
          ...CONVERT_HISTORY_HEADER_CONST.CONVERT_HISTORY_HEADER,
          {
            id: "tax",
            name: "instant_convert.history.tax",
          },
        ];
      }
      return CONVERT_HISTORY_HEADER_CONST.CONVERT_HISTORY_HEADER;
    },
  },
  data() {
    return {
      datePickerLang: this.$i18n.locale === "id" ? id : en,
      windowWidth: window.innerWidth,
      commonConst: COMMON_CONST,
      dateFromDefault: moment().subtract(6, "days").toDate(),
      dateToDefault: moment().toDate(),
      dateFrom: moment().subtract(6, "days").format("YYYY-MM-DD"),
      dateTo: moment().format("YYYY-MM-DD"),
      coins: [],
      coinMasters: [],
      isShowCoinOption: false,
      filters: {
        coin: "",
        currency: "",
      },
      isShowToolTip: false,
      coin: this.coinCode,
      currency: this.currencyCode,
      kyc: {
        status: "",
        country: "",
      },
    };
  },
  created() {
    rf.getRequest("InstantConvert")
      .checkConvertStatus()
      .then((res) => {
        if (!res.data.enable) {
          this.$router.push("/404");
        }
      });
    rf.getRequest("UserRequest")
      .getUserKyc()
      .then((res) => {
        this.kyc.status = res.data.status;
        this.kyc.country = res.data.country;
      });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    refreshTable() {
      this.$refs.datatable.refresh();
    },
    formatDate(date) {
      return moment(date).local().format("YYYY-MM-DD HH:mm:ss");
    },
    formatAmount(amount, token) {
      let amountArr = amount.split(".");
      let decimal =
        token === "usdt" || token === "idr" || token === "busd" ? 2 : 8;
      if (amountArr.length > 1) {
        if (amountArr[1].length > decimal) {
          amountArr[1] = amountArr[1].substring(0, decimal);
        }
      }
      return Utils.formatCurrencyAmount(amountArr.join("."), token, "0");
    },
    getData(params) {
      if (!isAuthenticated) {
        return new Promise((resolve) => {
          return resolve({});
        });
      }
      const dateRange = this.getDefaultDateRange();
      params = Object.assign({}, dateRange, params);
      return rf
        .getRequest("InstantConvert")
        .getConvertHistory(params)
        .then((res) => {
          return res;
        });
    },
    actionSearch() {
      if (this.dateFrom > this.dateTo) {
        this.showError(this.$t("common.date.warning"));
        return;
      }
      this.$refs.datatable.filter(this.filterParams());
      if (!this.options) {
        this.$refs.selectDateTab?.resetSelectDate();
      }
    },
    filterParams() {
      const filterParams = {
        date_start: this.dateFrom,
        date_end: this.dateTo,
      };
      if (this.filters.coin) {
        filterParams.traded_token =
          this.filters.coin.toLowerCase() === "all"
            ? undefined
            : this.filters.coin.toLowerCase();
      }
      if (this.filters.currency) {
        filterParams.received_token =
          this.filters.currency.toLowerCase() === "all"
            ? undefined
            : this.filters.currency;
      }
      return filterParams;
    },
    async actionReset() {
      await Promise.all([
        (this.filters.coin = ""),
        (this.filters.currency = ""),
        (this.dateFromDefault = moment().subtract(6, "days").toDate()),
        (this.dateToDefault = moment().toDate()),
        (this.dateFrom = moment()
          .subtract(6, "days")
          .startOf("day")
          .format("YYYY-MM-DD")),
        (this.dateTo = moment().endOf("day").format("YYYY-MM-DD")),
        $(".datatable").find("th").attr("data-sort-order", ""),
      ]);
      this.actionSearch();
    },
    requestCoinList() {
      MasterdataUtils.getCoins((coins) => {
        let coinSorted = coins.sort();
        this.coinMasters = coinSorted.map((coin) => {
          return {
            id: coin,
            name: coin.toUpperCase(),
          };
        });
        this.coinMasters.unshift({
          id: "all",
          name: this.$t("instant_convert.history.all"),
        });
      });
    },
    onSelectCoin(coin) {
      this.isShowCoinOption = false;
      this.filters.coin =
        coin.toLowerCase() === "all"
          ? this.$t("instant_convert.history.all")
          : coin.toUpperCase();
    },
    onSearchCoin() {
      this.coins = _.filter(this.coinMasters, (item) => {
        return _.isMatch(item.toUpperCase(), this.filters.coin.toUpperCase());
      });
    },
    downloadReport() {
      let params = {
        ...this.filterParams(),
        timezone_offset: Utils.getTimzoneOffset(),
      };
      rf.getRequest("InstantConvert").exportConvertHistory(
        params,
        "ConvertHistory_" + moment().format("YYYYMMDDHHmmss")
      );
    },
    getDefaultDateRange() {
      return {
        date_start: this.dateFrom,
        date_end: moment().format("YYYY-MM-DD"),
      };
    },
    selectDateFrom(dateFrom) {
      this.dateFrom = moment(dateFrom).startOf("day").format("YYYY-MM-DD");
    },
    selectDateTo(dateTo) {
      this.dateTo = moment(dateTo).endOf("day").format("YYYY-MM-DD");
    },
    showCoinOption() {
      this.isShowCoinOption = true;
      this.coins = this.coinMasters;
    },
    mouseOver() {
      this.isShowToolTip = !this.isShowToolTip;
    },
    clickOut() {
      this.isShowCoinOption = false;
    },
    showError(message) {
      Message.error(message, {}, { position: "bottom_left" });
    },
    customFormatter(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.requestCoinList();
    document.title =
      this.$t("instant_convert.history.page_title") + ` – ${this.tabTitle}`;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/common.scss";

.convert-history__mobile-wrapper {
  .data-list__wrapper {
    .convert-history__mobile-title {
      display: grid;
      border-radius: 10px 10px 0px 0px;
      background-color: $bg-main4;
      padding: 8px 16px;
      grid-template-columns: repeat(3, 1fr);

      .mobile-title__item {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        color: $text-secondary;
      }
    }

    .convert-history__mobile-item {
      background-color: $color-white;
      padding: 10px 16px;
      border-bottom: 1px solid $bg-main2;

      .mobile-item__wrapper {
        display: grid;
        position: relative;
        grid-template-columns: repeat(3, 1fr);

        .item-wrapper__title {
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          color: $text-main;
        }

        .item-wrapper__dropdown-icon {
          position: absolute;
          right: 0px;
        }
      }

      .mobile-item__expand-content {
        display: none;

        &.active {
          display: flex;
        }

        margin-top: 12px;
        background-color: $bg-main4;
        padding: 16px;
        flex-direction: column;
        border-radius: 8px;
        gap: 8px;

        .expand-content__wrapper {
          display: flex;
          justify-content: space-between;

          .expand-content__title {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            color: $text-main;
          }

          .expand-content__desc {
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
            color: $text-main;
          }
        }
      }
    }
  }
}

.cl-1 {
  min-width: 180px;
}

.cl-2 {
  min-width: 130px;
}

.cl-3 {
  min-width: 130px;
}

.cl-4 {
  min-width: 130px;
}

.cl-5 {
  min-width: 150px;
}

.cl-6 {
  min-width: 145px;
}

.cl-8 {
  text-align: end;
}

// Page
#convert-history-screen {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 0 auto 50px;
  padding: 25px 80px;
  min-height: 30vh;
  max-width: 100%;

  @media (max-width: 1399px) {
    padding: 25px 32px;
  }

  @include tablet-d {
    padding: 25px 15px;
  }
}

// Page title
.box-title {
  @include text-font($helvetica, $text-main, 24px, 700, 40px);
}

// Page content
#convert-history {
  // Actions
  .group-control {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 24px;

    @include tablet-d() {
      margin-bottom: 32px;
      gap: 24px;
    }

    .group_1 {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;

      @include tablet-d() {
        width: 100%;
      }

      .group-date-picker {
        display: flex;
        gap: 15px;

        @include tablet-d() {
          width: 100%;
        }

        @include mobile-small() {
          flex-wrap: wrap;
        }

        .group-date {
          :deep .date-picker-input {
            border-radius: 10px;
            border-radius: 5px;
            border: none;
            height: 38px !important;
            letter-spacing: normal !important;
            background-position: top 12px right 16px !important;
            padding: 7px 50px 7px 16px !important;
            width: 150px !important;
          }

          @include mobile() {
            flex: 1;

            .vdp-datepicker {
              display: block;

              &.date-to :deep() .vdp-datepicker__calendar {
                right: 0%;
                left: auto;

                &::before {
                  right: 5%;
                  left: auto;
                }
              }

              :deep() input {
                width: 100% !important;
              }
            }
          }

          @include mobile-small() {
            flex: unset;
            width: 100%;

            .vdp-datepicker {
              &.date-to :deep() .vdp-datepicker__calendar {
                right: auto;
                left: 0%;

                &::before {
                  right: auto;
                  left: 5%;
                }
              }
            }
          }
        }

        .label-text {
          @include text-font(
            $helvetica,
            $text-secondary,
            $font-root,
            400,
            24px
          );
          margin-bottom: 8px;
        }
      }

      .group-pair {
        display: flex;
        gap: 15px;

        @include tablet;

        @include mobile {
          flex-wrap: wrap;
          width: 100%;
        }

        .label-text {
          display: flex;
          align-items: center;
          gap: 55px;
          margin-bottom: 8px;
          color: $text-secondary;
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;

          @include mobile() {
            justify-content: space-between;
          }
        }

        .content-search {
          @include tablet;

          @include mobile {
            width: 100%;
          }

          .toolbar {
            display: flex;
            align-items: center;
            gap: 16px;
            @include tablet;

            @include mobile {
              justify-content: space-between;
            }
          }

          // Right coin
          .toolbar-element {
            @include tablet;

            @include mobile {
              flex: 1;
            }

            .toolbar-title {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              margin-bottom: 8px;
              columns: $text-secondary;
            }

            :deep() .sc_search_select {
              .group_search_select {
                .button_drop_search {
                  background-color: $color-white;
                  padding: 8px 16px;
                  min-width: 140px;
                  height: 40px;
                  border-radius: 5px;
                  border: none;

                  @include mobile-small() {
                    width: 100% !important;
                  }

                  & span {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    color: $text-main;
                  }

                  & .icon-arrow1 {
                    font-size: $font-smaller;
                    line-height: 18px;
                    color: $dark-3;
                  }
                }

                .box_list_search_select {
                  min-width: 83px;

                  ul {
                    li {
                      &:hover {
                        background-color: $grey-3;
                      }

                      span,
                      a {
                        color: $dark-1;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .group-search {
        display: flex;
        align-items: flex-end;
        gap: 16px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;

        @include mobile() {
          justify-content: space-between;
          width: 100%;
        }

        .button-search,
        .button-reset {
          padding: 8px 24px;
          border: unset;
          border-radius: 60px;

          @include tablet;

          @include mobile {
            flex: 1;
          }
        }

        .button-search {
          background-color: $bg-main1;
          color: $text-white;

          &:active {
            background-color: $bg-main2;
            color: $text-main;
          }
        }

        .button-reset {
          color: $text-main;
          border: 1px solid $bg-main1;

          &:active {
            background-color: $bg-main1;
            color: $color-white;
          }
        }
      }
    }

    .export-file {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      white-space: nowrap;

      @include tablet-d() {
        justify-content: flex-start;
      }

      @include mobile() {
        justify-content: flex-start;
      }

      @include mobile-small() {
        white-space: normal;
      }

      .excel_export {
        display: flex;
        gap: 8px;
        position: relative;
        margin-top: auto;
        color: $text-dark-1;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }

      .export-file-tooltip {
        position: absolute;
        top: -50px;
        right: 0;
        padding: 6px 8px;
        @include text-font($helvetica, $color-white, 14px, 400, 25px);
        white-space: nowrap;
        background-color: rgba(0, 0, 0, 0.75);
        border-radius: 2px;

        &::before {
          content: "";
          position: absolute;
          bottom: -4px;
          right: 42px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid rgba(0, 0, 0, 0.75);
        }

        @include tablet-d() {
          left: 0;
          right: auto;

          &::before {
            left: 42px;
            right: auto;
          }
        }

        @include mobile() {
          left: 0;
          right: unset;

          &::before {
            right: 50%;
            transform: translateX(-50%);
          }
        }

        @include mobile-small() {
          white-space: normal;
        }
      }
    }
  }

  // Table wrapper
  .data-table {
    // min-height: 270px;
    border-radius: 10px;
    background-color: $color-white;
    overflow-x: auto;

    // data list
    :deep() #convert-table {
      min-width: fit-content !important;
      max-width: 100% !important;
      border-radius: 10px;

      @include tablet-d() {
        & > :nth-child(2) {
          // position: sticky;
          // left: 20px;
          // width: fit-content;
        }
      }

      thead {
        background-color: #f8f8f8;
      }

      thead tr th {
        border: unset;
        padding: 8px;
        @include text-font($helvetica, $text-main, $font-root, 700, 24px);
        white-space: nowrap;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 20px;
        }
      }

      tbody {
        tr.canceled td:nth-child(11) {
          color: $color-denim;
        }

        td {
          white-space: nowrap;
          vertical-align: bottom;
          padding: 10px 8px 12px;
          white-space: nowrap;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          border-bottom: 1px solid #dddddd;
          font-family: $helvetica;

          &:first-child {
            padding-left: 20px;
          }

          &:last-child {
            padding-right: 20px;
          }

          &.blue {
            color: $color-jungle-green;
          }

          &.red {
            color: $color-denim;
          }
        }
      }

      .empty-data {
        .empty-noti {
          min-height: auto !important;
        }

        .text-wrapper {
          gap: 0;
        }

        td {
          padding-top: 90px !important;
          padding-bottom: 113px !important;
          border-bottom: 0 !important;
        }
      }
    }
  }
}
</style>
