<template>
  <div id="instant-convert">
    <div class="page-title">{{ $t("instant_convert.title") }}</div>
    <div class="convert-form">
      <div class="form-group">
        <div class="text-label">
          <span class="text-bold">{{ $t("instant_convert.from") }}</span>
          <span v-if="accessToken && tradeToken"
            >{{ $t("instant_convert.available_amount") }}:
            {{
              availableAmount | toNumber | formatCurrencyAmount(tradeToken, "0")
            }}
            <span class="token">{{ tradeToken }}</span></span
          >
        </div>
        <div class="group-input">
          <div
            @click="focusField = 'tradeAmount'"
            :title="focusField === 'receivedAmount' ? amountTooltip : ''"
          >
            <MarkedInput
              v-model="dataInput.tradeAmount"
              :precision="tradePrecision"
              :placeholder="
                $t('instant_convert.placeholder_from') + minimumTrade
              "
              :visible-button="false"
              :classes="classTrade"
            />
          </div>
          <span class="set-max-amount" @click="setMaxAmount">{{
            $t("instant_convert.max")
          }}</span>
          <div class="dropdown">
            <SelectBox
              v-model="tradeToken"
              :options="isMarketFirst ? markets : tokenByMarket"
              :hasCustomLabel="true"
            >
              <template slot="label_selected" slot-scope="{ selected }">
                <span class="token-selected">
                  <img
                    v-if="selected"
                    style="transform: rotate(0deg)"
                    class="icon-coin"
                    :src="`${STORAGE_URL}/icons/${selected}.png`"
                    :alt="`${selected}-icon`"
                  />{{ selected.toUpperCase() }}</span
                >
              </template>
              <template slot="options" slot-scope="{ option }">
                <span class="token"
                  ><span>
                    <img
                      class="icon-coin"
                      :src="`${STORAGE_URL}/icons/${option.id}.png`"
                      :alt="`${option.id}-icon`"
                    />{{ option.name.toUpperCase() }}</span
                  ></span
                >
              </template>
            </SelectBox>
          </div>
        </div>
      </div>
      <div class="img-swap">
        <img
          src="images/icon/exchange-alt.svg"
          alt="exchange-alt.svg"
          @click="swapConvert"
        />
      </div>
      <div class="form-group">
        <div class="text-label">
          <span class="text-bold">{{ $t("instant_convert.to") }}</span>
          <span v-if="accessToken">
            {{ $t("instant_convert.current_having_amount") }}:
            {{
              currentHavingAmount
                | toNumber
                | formatCurrencyAmount(receivedToken, "0")
            }}
            <span class="token">{{ receivedToken }}</span></span
          >
        </div>
        <div class="group-input">
          <div
            @click="focusField = 'receivedAmount'"
            :title="focusField === 'tradeAmount' ? amountTooltip : ''"
          >
            <MarkedInput
              v-model="dataInput.receivedAmount"
              :precision="receivedPrecision"
              :visible-button="false"
              :classes="classReceived"
            />
          </div>
          <div class="dropdown">
            <SelectBox
              v-model="receivedToken"
              :options="isMarketFirst ? tokenByMarket : markets"
              :hasCustomLabel="true"
            >
              <template slot="label_selected" slot-scope="{ selected }">
                <span class="token-selected">
                  <img
                    v-if="selected"
                    class="icon-coin"
                    :src="`${STORAGE_URL}/icons/${selected}.png`"
                    :alt="`${selected}-icon`"
                  />{{ selected.toUpperCase() }}</span
                >
              </template>
              <template slot="options" slot-scope="{ option }">
                <span class="token"
                  ><span>
                    <img
                      class="icon-coin"
                      :src="`${STORAGE_URL}/icons/${option.id}.png`"
                      :alt="`${option.id}-icon`"
                    />{{ option.name.toUpperCase() }}</span
                  ></span
                >
              </template>
            </SelectBox>
          </div>
        </div>
      </div>
      <div class="convert-info">
        <div class="conversion-rate">
          <div class="rate-title">
            {{ $t("instant_convert.conversion_rate") }}:
          </div>
          <div v-if="tradeToken">
            1
            <img
              class="icon-coin"
              :src="`${STORAGE_URL}/icons/${tradeToken}.png`"
              :alt="`${tradeToken}-icon`"
            />
            {{ tradeToken }} ≈
            {{
              conversionRateRoundown
                | toNumber
                | formatCurrencyAmount(receivedToken, "0")
            }}
            <img
              class="icon-coin"
              :src="`${STORAGE_URL}/icons/${receivedToken}.png`"
              :alt="`${receivedToken}-icon`"
            />
            {{ receivedToken }}
          </div>
        </div>
        <div class="convert-fee">
          {{ $t("instant_convert.trading_fee") }}: {{ dataConvert.fee }}%
        </div>
        <div class="convert-tax" v-if="isUserIndoAndKyc">
          {{ $t("instant_convert.tax") }}: {{ taxRate }}%
        </div>
      </div>
      <div class="notice" v-if="focusField === 'receivedAmount'">
        {{ $t("instant_convert.note2") }}
      </div>
      <div class="notice" v-if="focusField === 'tradeAmount'">
        {{ $t("instant_convert.note1") }}
      </div>
      <div class="action">
        <button class="btn btn-refresh" @click="refresh">
          {{ $t("instant_convert.refresh") }}
        </button>
        <button
          class="btn btn-convert"
          :disabled="isResfreshData"
          @click="convertToken"
        >
          {{
            isResfreshData
              ? $t("instant_convert.refreshing")
              : $t("instant_convert.convert") + " (" + timeoutRefreshData + "s)"
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import { mapState } from "vuex";
import MarkedInput from "@/components/shared_components/common/MarkedInput";
import SelectBox from "@/components/shared_components/common/SelectBox";
import BigNumber from "bignumber.js";
import Utils from "@/common/Utils";

export default {
  name: "InstantConvert",
  components: {
    MarkedInput,
    SelectBox,
  },
  data() {
    return {
      accessToken: localStorage.getItem("access_token"),
      markets: [],
      tokenByMarket: [],
      isMarketFirst: true,
      availableBalance: undefined,
      tradeToken: "",
      receivedToken: "",
      oldTradeToken: "",
      oldReceivedToken: "",
      dataInput: {
        tradeAmount: undefined,
        receivedAmount: undefined,
      },
      convertType: "buy",
      classTrade: "input-trade",
      classReceived: "input-received",
      isResfreshData: false,
      intervalId: undefined,
      dataConvert: {
        maximum: null,
        minimum: 0,
        fee: 0,
        slippage: 0,
      },
      dataPrice: {
        buy: 0,
        sell: 0,
      },
      timeoutRefreshData: 0,
      focusField: "",
      pairInvalid: false,
      windowWidth: window.innerWidth,
      kyc: {
        status: "",
        country: "",
      },
      taxSettings: [
        {
          type_exchange: "instant_convert.vat",
          tax_rate: "0",
        },
        {
          type_exchange: "instant_convert.income",
          tax_rate: "0",
        },
      ],
      amountTooltip: "",
    };
  },
  watch: {
    tradeToken(val) {
      if (val == this.oldReceivedToken) {
        return;
      }
      if (this.isMarketFirst) {
        rf.getRequest("InstantConvert")
          .getTokensByMarket(this.tradeToken)
          .then((res) => {
            this.tokenByMarket = res.data.map((item) => {
              return {
                id: item,
                name: item.toUpperCase(),
              };
            });
            if (
              !this.tokenByMarket.find((item) => item.id === this.receivedToken)
            ) {
              this.receivedToken = this.tokenByMarket[0].id;
              this.handleChangePair();
            }
          });
      } else {
        this.handleChangePair();
      }
    },
    receivedToken(val) {
      if (val == this.oldTradeToken) {
        return;
      }
      if (!this.isMarketFirst) {
        rf.getRequest("InstantConvert")
          .getTokensByMarket(this.receivedToken)
          .then((res) => {
            this.tokenByMarket = res.data.map((item) => {
              return {
                id: item,
                name: item.toUpperCase(),
              };
            });
            if (
              !this.tokenByMarket.find((item) => item.id === this.tradeToken)
            ) {
              this.tradeToken = this.tokenByMarket[0].id;
              this.handleChangePair();
            }
          });
      } else {
        this.handleChangePair();
      }
    },
    "dataInput.tradeAmount"() {
      this.onChangeTradeInput(this.dataInput);
    },
    "dataInput.receivedAmount"() {
      this.onChangeReceivedInput(this.dataInput);
    },
    conversionRate() {
      if (this.focusField === "tradeAmount") {
        this.onChangeTradeInput(this.dataInput);
      } else if (this.focusField === "receivedAmount") {
        this.onChangeReceivedInput(this.dataInput);
      }
    },
  },
  created() {
    this.reload();
  },
  computed: {
    ...mapState({
      pairCoins: (state) => state.pairCoins,
    }),
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === "storage_url")
        .value;
    },
    taxRate() {
      if (this.tradeToken !== "idr" && this.receivedToken !== "idr") {
        const tax_rate = this.taxSettings.reduce((acc, item) => {
          return new BigNumber(acc).plus(item.tax_rate).toString();
        }, 0);
        return new BigNumber(tax_rate).mul(100).toString();
      } else {
        if (this.convertType === "buy") {
          const tax_rate = this.taxSettings.find(
            (item) => item.type_exchange === "instant_convert.vat"
          ).tax_rate;
          return new BigNumber(tax_rate).mul(100).toString();
        } else {
          const tax_rate = this.taxSettings.find(
            (item) => item.type_exchange === "instant_convert.income"
          ).tax_rate;
          return new BigNumber(tax_rate).mul(100).toString();
        }
      }
    },
    conversionRate() {
      switch (this.convertType) {
        case "buy":
          if (this.dataPrice.buy) {
            const temp = new BigNumber(this.dataPrice.buy).plus(
              new BigNumber(this.dataPrice.buy)
                .times(this.dataConvert.slippage)
                .div(100)
            );
            return new BigNumber(1).div(temp).toString();
          } else return 0;
        case "sell":
          return this.dataPrice.sell
            ? new BigNumber(this.dataPrice.sell)
                .minus(
                  new BigNumber(this.dataPrice.sell)
                    .times(this.dataConvert.slippage)
                    .div(100)
                )
                .toString()
            : 0;
        default:
          return "0";
      }
    },
    conversionRateRoundown() {
      let rateArr = this.conversionRate.toString().split(".");
      if (rateArr.length > 1) {
        rateArr[1] = rateArr[1].substring(0, this.receivedDecimal);
        return rateArr.join(".");
      } else {
        return rateArr[0];
      }
    },
    minimumTrade() {
      return Utils.formatCurrencyAmount(
        this.dataConvert.minimum,
        this.tradeToken,
        "0"
      );
    },
    tradePrecision() {
      return this.tradeToken === "usdt" ||
        this.tradeToken === "idr" ||
        this.tradeToken === "busd"
        ? Number(0.01)
        : Number(0.00000001);
    },
    receivedPrecision() {
      return this.receivedToken === "usdt" ||
        this.receivedToken === "idr" ||
        this.receivedToken === "busd"
        ? Number(0.01)
        : Number(0.00000001);
    },
    tradeDecimal() {
      return this.tradeToken === "usdt" ||
        this.tradeToken === "idr" ||
        this.tradeToken === "busd"
        ? 2
        : 8;
    },
    receivedDecimal() {
      return this.receivedToken === "usdt" ||
        this.receivedToken === "idr" ||
        this.receivedToken === "busd"
        ? 2
        : 8;
    },
    availableAmount() {
      let availableAmountArr = this.availableBalance
        ? this.availableBalance[this.tradeToken].available_balance.split(".")
        : ["0"];
      if (availableAmountArr.length > 1) {
        availableAmountArr[1] = availableAmountArr[1].substring(
          0,
          this.tradeDecimal
        );
        return availableAmountArr.join(".");
      } else {
        return availableAmountArr[0];
      }
    },
    currentHavingAmount() {
      let currentHavingAmountArr = this.availableBalance
        ? this.availableBalance[this.receivedToken].balance.split(".")
        : ["0"];
      if (currentHavingAmountArr.length > 1) {
        currentHavingAmountArr[1] = currentHavingAmountArr[1].substring(
          0,
          this.receivedDecimal
        );
        return currentHavingAmountArr.join(".");
      } else {
        return currentHavingAmountArr[0];
      }
    },
    isUserIndoAndKyc() {
      return this.kyc.country === "Indonesia" && this.kyc.status === "verified";
    },
  },
  methods: {
    async reload() {
      await rf
        .getRequest("InstantConvert")
        .checkConvertStatus()
        .then((res) => {
          if (!res.data.enable) {
            this.$router.push("/404");
          } else {
            this.tradeToken = res.data.from_default;
            this.receivedToken = res.data.default_setting_to;
            this.initData();
          }
        });
    },
    initData() {
      rf.getRequest("InstantConvert")
        .getMarkets()
        .then((res) => {
          this.markets = res.data.map((item) => {
            return {
              id: item,
              name: item.toUpperCase(),
            };
          });
          if (
            !this.tradeToken ||
            !this.markets.find((item) => item.id === this.tradeToken)
          ) {
            this.tradeToken = this.markets[0].id;
          }
          rf.getRequest("InstantConvert")
            .getTokensByMarket(this.tradeToken)
            .then((res) => {
              this.tokenByMarket = res.data.map((item) => {
                return {
                  id: item,
                  name: item.toUpperCase(),
                };
              });
              if (
                !this.receivedToken ||
                !this.tokenByMarket.find(
                  (item) => item.id === this.receivedToken
                )
              ) {
                this.receivedToken = this.tokenByMarket[0].id;
              }
              this.getBalance();
              this.getMarkets();
              this.getTokensByMarket(this.tradeToken);
              this.getPrice();
              this.intervalRefreshData();
              this.getUserKyc();
            });
        });
    },
    getBalance() {
      if (!this.accessToken) return;
      return rf
        .getRequest("InstantConvert")
        .getAvailableBalance()
        .then((res) => {
          this.availableBalance = res.data;
        });
    },
    getMarkets() {
      return rf
        .getRequest("InstantConvert")
        .getMarkets()
        .then((res) => {
          this.markets = res.data.map((item) => {
            return {
              id: item,
              name: item.toUpperCase(),
            };
          });
        });
    },
    getTokensByMarket(market) {
      return rf
        .getRequest("InstantConvert")
        .getTokensByMarket(market)
        .then((res) => {
          this.tokenByMarket = res.data.map((item) => {
            return {
              id: item,
              name: item.toUpperCase(),
            };
          });
        });
    },
    getDataConvert() {
      if (this.conversionRate == 0) return;
      const promise = rf
        .getRequest("InstantConvert")
        .getDataConvert(
          this.tradeToken,
          this.receivedToken,
          this.convertType,
          this.conversionRate
        )
        .then((res) => {
          this.dataConvert = Object.assign(this.dataConvert, res.data);
        });
      return Promise.all([promise, this.getTaxSettings()]);
    },
    getTaxSettings() {
      return rf
        .getRequest("SettingRequest")
        .getTaxSettings()
        .then((res) => {
          this.taxSettings = res?.data.filter((item) =>
            item.type_exchange.includes("instant_convert")
          );
        });
    },
    getPrice() {
      this.isResfreshData = true;
      let currentcy = this.tradeToken;
      let coin = this.receivedToken;
      if (this.convertType === "sell") {
        currentcy = this.receivedToken;
        coin = this.tradeToken;
      }
      return rf
        .getRequest("InstantConvert")
        .refreshPrice(currentcy, coin)
        .then(async (res) => {
          this.dataPrice = res.data;
          await this.getDataConvert();
          this.isResfreshData = false;
          this.timeoutRefreshData = 3;
        });
    },
    getUserKyc() {
      rf.getRequest("UserRequest")
        .getUserKyc()
        .then((res) => {
          this.kyc.status = res.data.status;
          this.kyc.country = res.data.country;
        });
    },
    refresh() {
      if (this.isMarketFirst) {
        if (
          !this.markets.find((item) => item.id === this.tradeToken) ||
          !this.tokenByMarket.find((item) => item.id === this.receivedToken)
        ) {
          this.pairInvalid = true;
        }
      } else {
        if (
          !this.tokenByMarket.find((item) => item.id === this.tradeToken) ||
          !this.markets.find((item) => item.id === this.receivedToken)
        ) {
          this.pairInvalid = true;
        }
      }
      if (!this.pairInvalid) {
        this.refreshData();
      } else {
        clearInterval(this.intervalId);
        this.reload();
        this.pairInvalid = false;
      }
    },
    async refreshData() {
      // await this.getBalance()
      // await this.getMarkets()
      // if (this.isMarketFirst) {
      //   await this.getTokensByMarket(this.tradeToken)
      // } else {
      //   await this.getTokensByMarket(this.receivedToken)
      // }
      // await this.getPrice()
      await Promise.all([
        this.getBalance(),
        this.getMarkets(),
        this.isMarketFirst
          ? this.getTokensByMarket(this.tradeToken)
          : this.getTokensByMarket(this.receivedToken),
        this.getPrice(),
      ]);
    },
    intervalRefreshData() {
      this.intervalId = setInterval(() => {
        if (this.timeoutRefreshData > 0) {
          this.timeoutRefreshData--;
        } else if (this.timeoutRefreshData == 0) {
          this.refreshData();
        }
      }, 1000);
    },
    handleChangePair() {
      let pair = `${this.tradeToken}_${this.receivedToken}`;
      let pairReverse = `${this.receivedToken}_${this.tradeToken}`;
      if (Object.keys(this.pairCoins).includes(pair)) {
        this.convertType = "buy";
      } else if (Object.keys(this.pairCoins).includes(pairReverse)) {
        this.convertType = "sell";
      } else {
        this.convertType = null;
      }
      this.getPrice();
    },
    setMaxAmount() {
      document.getElementsByClassName(this.classTrade)[0].focus();
      let maxTradeAmount = this.dataConvert.maximum
        ? new BigNumber(this.availableAmount).gt(this.dataConvert.maximum)
          ? this.dataConvert.maximum
          : this.availableAmount
        : this.availableAmount;
      let maxTradeAmountArr = maxTradeAmount.toString().split(".");
      if (maxTradeAmountArr.length > 1) {
        if (maxTradeAmountArr[1].length > this.tradeDecimal) {
          maxTradeAmountArr[1] = maxTradeAmountArr[1].substring(
            0,
            this.tradeDecimal
          );
        }
        this.dataInput.tradeAmount = maxTradeAmountArr.join(".");
      } else {
        this.dataInput.tradeAmount = maxTradeAmount;
      }
    },
    removeExponent(data) {
      var data = String(data).split(/[eE]/);
      if (data.length == 1) return data[0];

      var z = "",
        sign = this < 0 ? "-" : "",
        str = data[0].replace(".", ""),
        mag = Number(data[1]) + 1;

      if (mag < 0) {
        z = sign + "0.";
        while (mag++) z += "0";
        return z + str.replace(/^\-/, "");
      }
      mag -= str.length;
      while (mag--) z += "0";
      return str + z;
    },
    formatToLocalString(num) {
      let str = this.removeExponent(num).toString();
      for (let i = str.length; i > 0; i--) {
        if (str[i] === ".") {
          return (
            str.substring(0, i + 1).replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            str.substring(i + 1)
          );
        } else if (i === 1) {
          return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
    },
    onChangeTradeInput(dataInput) {
      const classes = document.activeElement.getAttribute("class");
      if (classes && classes.includes(this.classReceived)) {
        this.focusField = "receivedAmount";
        return;
      }
      if (!dataInput.tradeAmount) {
        dataInput.receivedAmount = undefined;
        return;
      } else {
        let amountArr = this.removeExponent(
          new BigNumber(`${dataInput.tradeAmount}`)
            .mul(`${this.conversionRate}`)
            .toNumber()
        )
          .toString()
          .split(".");
        this.amountTooltip = this.formatToLocalString(amountArr.join("."));
        if (amountArr.length > 1) {
          if (amountArr[1].length > this.receivedDecimal) {
            amountArr[1] = amountArr[1].substring(0, this.receivedDecimal);
          }
          dataInput.receivedAmount = amountArr.join(".");
        } else {
          dataInput.receivedAmount = amountArr[0];
        }
      }
    },
    onChangeReceivedInput(dataInput) {
      const classes = document.activeElement.getAttribute("class");
      if (classes && classes.includes(this.classTrade)) {
        this.focusField = "tradeAmount";
        return;
      }
      if (!dataInput.receivedAmount) {
        dataInput.tradeAmount = undefined;
        return;
      } else {
        dataInput.tradeAmount = new BigNumber(`${dataInput.receivedAmount}`)
          .div(`${this.conversionRate}`)
          .toNumber()
          .toFixed(this.tradeDecimal);
        this.amountTooltip = this.formatToLocalString(dataInput.tradeAmount);
      }
    },
    swapConvert() {
      this.isMarketFirst = !this.isMarketFirst;
      this.oldTradeToken = this.tradeToken;
      this.oldReceivedToken = this.receivedToken;
      let temp = this.tradeToken;
      this.tradeToken = this.receivedToken;
      this.receivedToken = temp;
      this.convertType = this.convertType === "buy" ? "sell" : "buy";
      this.getDataConvert();
      if (this.focusField === "tradeAmount") {
        this.focusField = "receivedAmount";
        document.getElementsByClassName(this.classReceived)[0].focus();
        this.dataInput.receivedAmount = this.dataInput.tradeAmount;
      } else if (this.focusField === "receivedAmount") {
        this.focusField = "tradeAmount";
        document.getElementsByClassName(this.classTrade)[0].focus();
        this.dataInput.tradeAmount = this.dataInput.receivedAmount;
      }
    },
    convertToken() {
      if (!this.accessToken) {
        this.$router.push("/login");
        return;
      }
      if (
        this.dataInput.tradeAmount == 0 ||
        this.dataInput.receivedAmount == 0
      ) {
        Message.error(
          this.$i18n.t("instant_convert.not_amount"),
          {},
          { position: "top_right" }
        );
        return;
      }
      if (!this.dataInput.tradeAmount || !this.dataInput.receivedAmount) {
        Message.error(
          this.$i18n.t("instant_convert.not_input_amount"),
          {},
          { position: "top_right" }
        );
        return;
      }
      if (this.kyc.status !== "verified") {
        return Message.error(
          this.$t("not_complete_kyc"),
          {},
          { position: "top_right" }
        );
      }
      let maxTradeAmount = this.dataConvert.maximum
        ? new BigNumber(this.availableAmount).gt(this.dataConvert.maximum)
          ? this.dataConvert.maximum
          : this.availableAmount
        : this.availableAmount;
      let maxTradeAmountArr = maxTradeAmount.toString().split(".");
      if (maxTradeAmountArr.length > 1) {
        if (maxTradeAmountArr[1].length > this.tradeDecimal) {
          maxTradeAmountArr[1] = maxTradeAmountArr[1].slice(
            0,
            this.tradeDecimal
          );
        }
        maxTradeAmount = maxTradeAmountArr.join(".");
      }
      let minimumArr = this.dataConvert.minimum.split(".");
      let minimumRounDown = 0;
      if (minimumArr.length > 1) {
        if (minimumArr[1].length > this.tradeDecimal) {
          minimumArr[1] = minimumArr[1].substring(0, this.tradeDecimal);
        }
        minimumRounDown = minimumArr.join(".");
      }
      if (new BigNumber(this.dataInput.tradeAmount).gt(this.availableAmount)) {
        Message.error(
          this.$i18n.t("instant_convert.balance_not_enough"),
          {},
          { position: "top_right" }
        );
        return;
      }
      if (
        new BigNumber(this.dataInput.tradeAmount).lt(this.dataConvert.minimum)
      ) {
        if (new BigNumber(this.dataInput.tradeAmount).eq(minimumRounDown)) {
          Message.error(
            `${this.$i18n.t(
              "instant_convert.amount_lower1"
            )} ${Utils.formatCurrencyAmount(
              this.dataConvert.minimum,
              this.tradeToken,
              "0"
            )} ${this.tradeToken.toUpperCase()}!`,
            {},
            { position: "top_right" }
          );
          return;
        } else {
          Message.error(
            `${this.$i18n.t(
              "instant_convert.amount_lower2"
            )} ${Utils.formatCurrencyAmount(
              this.dataConvert.minimum,
              this.tradeToken,
              "0"
            )} ${this.tradeToken.toUpperCase()}!`,
            {},
            { position: "top_right" }
          );
          return;
        }
      }
      if (new BigNumber(this.dataInput.tradeAmount).gt(maxTradeAmount)) {
        Message.error(
          `${this.$i18n.t(
            "instant_convert.amount_higher"
          )} ${Utils.formatCurrencyAmount(
            maxTradeAmount,
            this.tradeToken,
            "0"
          )} ${this.tradeToken.toUpperCase()}!`,
          {},
          { position: "top_right" }
        );
        return;
      }
      let convertRateArr = this.conversionRate.toString().split(".");
      let convertRate = this.conversionRate;
      if (convertRateArr.length > 1) {
        if (convertRateArr[1].length > this.receivedDecimal) {
          convertRateArr[1] = convertRateArr[1].slice(0, 15);
        }
        convertRate = convertRateArr.join(".");
      }
      let tradeAmountArr =
        this.focusField === "tradeAmount"
          ? this.dataInput.tradeAmount.split(".")
          : new BigNumber(`${this.dataInput.receivedAmount}`)
              .div(`${this.conversionRate}`)
              .toString()
              .split(".");

      if (tradeAmountArr.length > 1) {
        if (tradeAmountArr[1].length > 15) {
          tradeAmountArr[1] = tradeAmountArr[1].substring(0, 15);
        }
      }
      let payload = {
        trade_token: this.tradeToken,
        trade_amount: tradeAmountArr.join("."),
        received_token: this.receivedToken,
        received_amount: this.dataInput.receivedAmount,
        convert_type: this.convertType,
        conversion_rate: convertRate,
        slippage: this.dataConvert.slippage,
        price:
          this.convertType === "buy" ? this.dataPrice.buy : this.dataPrice.sell,
        fee: this.dataConvert.fee,
        tax_rate: new BigNumber(this.taxRate).div(100).toString(),
      };
      rf.getRequest("InstantConvert")
        .postInstantConvert(payload)
        .then((res) => {
          if (res.success) {
            this.dataInput.tradeAmount = "";
            this.getBalance();
            Message.success(
              this.$i18n.t("instant_convert.convert_success"),
              {},
              { position: "top_right" }
            );
          }
        })
        .catch((err) => {
          Message.error(
            this.$i18n.t(err.response.data.message),
            {},
            { position: "top_right" }
          );
          this.getDataConvert();
          if (err.response.data.message === "instant_convert.pair_disabled") {
            this.pairInvalid = true;
          }
        });
    },
  },
  mounted() {
    document.title = this.$t("instant_convert.title") + ` – ${this.tabTitle}`;
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
};
</script>

<style lang="scss" scoped>
#instant-convert {
  background-color: #f2f2f2;
  max-width: 572px;
  margin: 0 auto;
  color: $text-main;
  padding: 35px 15px;

  .page-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  .convert-form {
    margin-top: 24px;
    background: white;
    width: 100%;
    border-radius: 10px;
    padding: 24px;
  }

  .form-group {
    margin-bottom: 16px;

    .text-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      line-height: 22px;
      color: $text-secondary;

      @include mobile {
        font-size: 12px;
        line-height: 16px;
        font-weight: 300;
      }

      .text-bold {
        font-size: 16px;
        line-height: 24px;
        color: $text-secondary;
        font-weight: 500;

        @include mobile {
          font-size: 14px;
        }
      }

      .token {
        text-transform: uppercase;
      }
    }

    .group-input {
      position: relative;
      width: 100%;
      margin-top: 8px;
      background: #ffffff;
      padding: 0;
      border: 1px solid $grey-1;
      border-radius: 10px;
      display: grid;
      grid-template-columns: auto 120px;

      @include mobile {
        grid-template-columns: auto 115px;
      }

      :deep(input) {
        font-family: $helvetica;
        padding: 9px 16px;
        border: none;
        font-size: 16px;
        line-height: 24px;
        height: auto;
        overflow: hidden;
        text-overflow: ellipsis;

        &.input-trade {
          padding-right: 60px;
        }

        @include mobile {
          font-size: 14px;
          line-height: 22px;
          padding: 10px 15px;
        }
      }

      .set-max-amount {
        position: absolute;
        top: 0;
        right: 130px;
        padding: 9px 8px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
        color: $text-main;
        cursor: pointer;

        @include mobile {
          right: 120px;
          font-size: 14px;
          line-height: 22px;
          padding: 10px 5px;
        }
      }

      .dropdown {
        padding: 9px;
        padding-right: 16px;
        border-left: 1px solid $grey-1;

        :deep(.button_drop_search) {
          border: none;
          padding: 0;
          display: flex;
          align-items: center;
          gap: 10px;

          .token-selected {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: $text-main;

            img {
              height: 20px;
              width: 20px;
              margin-top: auto;
              margin-right: auto;
              margin-right: 8px;
              transform: rotate(0deg);

              @include mobile {
                height: 20px;
                width: 20px;
              }
            }

            @include mobile {
              font-size: 14px;
              line-height: 22px;
            }
          }

          img {
            margin: 0;
            width: 24px;
            height: 24px;

            @include mobile {
              width: 18px;
              height: 18px;
            }
          }
        }

        :deep(.box_list_search_select) {
          width: 130px;
          left: -5px;

          .list_search_select {
            li {
              white-space: nowrap;
              font-size: 18px;
              line-height: 23px;

              @include mobile {
                font-size: 14px;
                line-height: 22px;
              }

              .token {
                img {
                  height: 30px;
                  width: 30px;
                  margin-right: 8px;

                  @include mobile {
                    height: 20px;
                    width: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .img-swap {
    margin-bottom: 16px;
    text-align: center;

    img {
      cursor: pointer;
      width: 32px;
      height: 32px;
      padding: 4px;
      background: $text-main2;
      border-radius: 50%;
    }
  }

  .convert-info {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    .conversion-rate {
      margin-top: 16px;
      display: flex;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
        margin-bottom: 2px;

        @include mobile {
          width: 18px;
          height: 18px;
        }
      }

      div {
        text-transform: uppercase;
        line-height: 24px;
      }

      .rate-title {
        text-transform: none;
      }

      @include mobile {
        font-size: 12px;
        // line-height: 16px;
        height: 18px;
        font-weight: 300;

        div {
          line-height: 16px;
        }

        img {
          margin-bottom: auto;
        }
      }
    }

    .convert-tax,
    .convert-fee {
      margin-top: 8px;
    }

    @include mobile {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .notice {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 16px;
    padding: 16px;
    background-color: #f2f2f2;
    border-radius: 8px;

    @include mobile {
      padding: 8px;
      font-size: 12px;
      text-align: center;
      font-style: normal;
      font-weight: 300;
      line-height: 16px;
    }
  }

  .action {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    .btn {
      border-radius: 60px;
      width: 100%;
      padding: 8px 32px;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }

    .btn-refresh {
      background: #f2f2f2;
      color: $text-main;

      &:hover {
        background: $text-main;
        color: white;
      }
    }

    .btn-convert {
      background: $bg-main1;
      color: white;
    }
  }
}

@include tablet;

@include mobile {
  #instant-convert {
    padding: 16px 16px 60px;

    .page-title {
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
    }

    .convert-form {
      margin-top: 16px;
      padding: 24px 16px;

      .form-group {
      }
    }
  }
}
</style>
