var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top_mid_table",attrs:{"id":"top_mid_table"}},[_c('div',{staticClass:"content_orderbook",class:{ active: _vm.isShowOrderBook }},[_c('div',{staticClass:"head_orderbook__mobile-header"},[_c('div',{staticClass:"head_orderbook__title"},[_vm._v(" "+_vm._s(_vm.$t('order.order_book.order_book'))+" ")]),_c('div',{staticClass:"head_orderbook__toggle-btn",on:{"click":function($event){_vm.isShowOrderBook = !_vm.isShowOrderBook}}},[_c('div',{staticClass:"toggle-switch",class:_vm.isShowOrderBook ? 'on' : 'off'},[_c('span',{staticClass:"toggle-handle"})])])]),_c('div',{staticClass:"head_orderbook"},[_c('div',{staticClass:"group_sort_orderbook"},[_c('div',{staticClass:"list_decimals_od"}),_c('ul',{staticClass:"list_sort_od"},[_c('li',[_c('div',{staticClass:"icon_sort_od",on:{"click":function($event){_vm.tab = _vm.tabConfig.sell}}},[_c('img',{attrs:{"src":"/images/spot_exchange/order-book-ic-1.svg","alt":""}})])]),_c('li',[_c('div',{staticClass:"icon_sort_od",on:{"click":function($event){_vm.tab = _vm.tabConfig.bothSellBuy}}},[_c('img',{attrs:{"src":"/images/spot_exchange/order-book-ic-2.svg","alt":""}})])]),_c('li',[_c('div',{staticClass:"icon_sort_od",on:{"click":function($event){_vm.tab = _vm.tabConfig.buy}}},[_c('img',{attrs:{"src":"/images/spot_exchange/order-book-ic-3.svg","alt":""}})])])])])]),_c('div',{staticClass:"body_orderbook"},[_c('div',{staticClass:"top-title"},[_c('ul',{staticClass:"title-group"},[_c('li',{staticClass:"od-price"},[_vm._v(" "+_vm._s(_vm.$t('order.order_book.price'))+" ("+_vm._s(_vm.currency.toUpperCase())+") ")]),_c('li',{staticClass:"od-amount"},[_vm._v(" "+_vm._s(_vm.$t('order.order_book.amount'))+" ("+_vm._s(_vm.coin.toUpperCase())+") ")]),_c('li',{staticClass:"od-total"},[_vm._v(" "+_vm._s(_vm.$t('order.order_book.total'))+" ("+_vm._s(_vm.coin.toUpperCase())+") ")])])]),_c('div',{staticClass:"order-red-container",class:{
          active_red_max: _vm.tab === _vm.tabConfig.sell,
          ' active_red_min': _vm.tab === _vm.tabConfig.buy,
        }},[_c('table',{attrs:{"id":"orderbook_red"}},[_c('tbody',{staticClass:"orderbook_red"},_vm._l((_vm.sellOrderBookDisplay),function(item,index){return _c('tr',{key:index,class:{ 'row-clicked': item.isClickedRow },on:{"click":function($event){$event.stopPropagation();return _vm.onSellOrderBookClicked(item, index)}}},[_c('div',{staticClass:"fix_sell_od",style:({ width: item.percent + '%' })}),_c('td',{staticClass:"sell_color",attrs:{"title":item.price
                    ? Number(
                        parseFloat(item.price || 0).toFixed(_vm.isPairPrecisionIsFour ? 4 : 2)
                      ).toLocaleString('en', {
                        minimumFractionDigits: _vm.isPairPrecisionIsFour ? 4 : 2,
                      })
                    : '--'}},[_vm._v(" "+_vm._s(item.price ? Number( parseFloat(item.price || 0).toFixed(_vm.isPairPrecisionIsFour ? 4 : 2) ).toLocaleString('en', { minimumFractionDigits: _vm.isPairPrecisionIsFour ? 4 : 2, }) : '--')+" ")]),_c('td',{staticClass:"padr10 p-right",attrs:{"title":_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(item.quantity),null, '--'),_vm.coinSettingByPair.fractionPartQuantity, '00')}},[_vm._v(" "+_vm._s(_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(item.quantity,null, '--'),_vm.coinSettingByPair.fractionPartQuantity, '00'))+" ")]),_c('td',{attrs:{"title":_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(_vm.calculateTotalQuantityCoinForDisplay(_vm.sellOrderBookDisplay, item, index, 'sell')),null, '--'),_vm.coinSettingByPair.fractionPartQuantity, '00')}},[_vm._v(" "+_vm._s(_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(_vm.calculateTotalQuantityCoinForDisplay(_vm.sellOrderBookDisplay, item, index, 'sell')),null, '--'),_vm.coinSettingByPair.fractionPartQuantity, '00'))+" ")])])}),0)])]),_c('div',{staticClass:"block_center_od"},[_c('div',{staticClass:"border_block_center_od",class:{ sell_buy: _vm.tab === _vm.tabConfig.bothSellBuy }},[_c('div',{staticClass:"od_number__wrapper"},[_c('span',{staticClass:"od_number_fix text-oneline",class:{
                'grown-price': _vm.lastChange > 0,
                'down-price': _vm.lastChange < 0,
              },attrs:{"title":_vm.$options.filters?.formatCurrencyAmount(_vm.latestPrice, 'idr', '00')}},[_c('i',{staticClass:"icon_zhang",class:{
                  'icon-arrow5': _vm.lastChange > 0,
                  'icon-arrow6': _vm.lastChange < 0,
                },attrs:{"size":"12"}}),_vm._v(" "+_vm._s(_vm.latestPrice ? Number( parseFloat(_vm.latestPrice || 0).toFixed(_vm.isPairPrecisionIsFour ? 4 : 2) ).toLocaleString('en', { minimumFractionDigits: _vm.isPairPrecisionIsFour ? 4 : 2, }) : '--')+" ")])]),_c('img',{staticClass:"icon_analytics",attrs:{"src":"/images/icon/u_chart-line.svg","alt":""}})])]),_c('table',{class:{
          ' active_blue_min': _vm.tab === _vm.tabConfig.sell,
          ' active_blue_max': _vm.tab === _vm.tabConfig.buy,
        },attrs:{"id":"orderbook_blue"}},[_c('tbody',{staticClass:"orderbook_blue",class:{
            ' orderbook_blue_min': _vm.tab === 1,
            ' orderbook_blue_max': _vm.tab === 3,
          }},_vm._l((_vm.buyOrderBookDisplay),function(item,index){return _c('tr',{key:index,staticClass:"orderbook__row",class:{ 'row-clicked': item.isClickedRow },on:{"click":function($event){$event.stopPropagation();return _vm.onBuyOrderBookClicked(item, index)}}},[_c('div',{staticClass:"fix_back_od",style:({ width: item.percent + '%' })}),_c('td',{staticClass:"sell_color",attrs:{"title":item.price
                  ? Number(
                      parseFloat(item.price || 0).toFixed(_vm.isPairPrecisionIsFour ? 4 : 2)
                    ).toLocaleString('en', {
                      minimumFractionDigits: _vm.isPairPrecisionIsFour ? 4 : 2,
                    })
                  : '--'}},[_vm._v(" "+_vm._s(item.price ? Number( parseFloat(item.price || 0).toFixed(_vm.isPairPrecisionIsFour ? 4 : 2) ).toLocaleString('en', { minimumFractionDigits: _vm.isPairPrecisionIsFour ? 4 : 2, }) : '--')+" ")]),_c('td',{staticClass:"padr10 p-right",attrs:{"title":_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(item.quantity),_vm.coin, '--'),_vm.coinSettingByPair.fractionPartQuantity, '00')}},[_vm._v(" "+_vm._s(_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(item.quantity),null, '--'),_vm.coinSettingByPair.fractionPartQuantity, '00'))+" ")]),_c('td',{attrs:{"title":_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(_vm.calculateTotalQuantityCoinForDisplay(_vm.buyOrderBookDisplay, item, index, 'buy')),null, '--'),_vm.coinSettingByPair.fractionPartQuantity, '00')}},[_vm._v(" "+_vm._s(_vm._f("paddingRight")(_vm._f("formatCurrencyAmount")(_vm._f("toNumber")(_vm.calculateTotalQuantityCoinForDisplay(_vm.buyOrderBookDisplay, item, index, 'buy')),null, '--'),_vm.coinSettingByPair.fractionPartQuantity, '00'))+" ")])])}),0)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }