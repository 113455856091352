<template>
  <div class="main_agreementInner">
    <div class="tit_page">
      <label>{{ $t("menu_bottom.withdrawals_guide") }}</label>
    </div>
    <div class="main-mobile__wrapper">
      <div class="mobile__col-2-wrapper">
        <div class="mobile__multiple-row-item">
          <div class="mobile__cell-item mobile__cell-item--md">
            {{ $t("withdraw_guide.kyc_level") }}
          </div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.kyc") }} 1</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.kyc") }} 2</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.kyc") }} 3</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.kyc") }} 4</div>
        </div>
        <div class="mobile__multiple-row-item">
          <div class="mobile__cell-item mobile__cell-item--md">
            {{ $t("withdraw_guide.trade") }}
          </div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.yes") }}</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.yes") }}</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.yes") }}</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.yes") }}</div>
        </div>
      </div>
      <!-- - -->
      <div class="mobile__row-item">
        <div class="mobile__cell-item">
          {{ $t("withdraw_guide.fiat_deposit") }}
        </div>
      </div>
      <div class="mobile__col-2-wrapper">
        <div class="mobile__multiple-row-item">
          <div class="mobile__cell-item">
            {{ $t("withdraw_guide.fiat_deposit") }}
          </div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.no") }}</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.no") }}</div>
          <div class="mobile__cell-item">
            {{ $t("withdraw_guide.yes") }}
          </div>
          <div class="mobile__cell-item">
            {{ $t("withdraw_guide.yes") }}
          </div>
        </div>
        <div class="mobile__multiple-row-item">
          <div class="mobile__cell-item">
            {{ $t("withdraw_guide.fiat_deposit_limit") }}
          </div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.no") }}</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.no") }}</div>
          <div class="mobile__cell-item">
            {{ $t("withdraw_guide.unlimited") }}
          </div>
          <div class="mobile__cell-item">
            {{ $t("withdraw_guide.unlimited") }}
          </div>
        </div>
      </div>
      <!-- - -->
      <div class="mobile__row-item">
        <div class="mobile__cell-item">
          {{ $t("withdraw_guide.fiat_withdrawal") }}
        </div>
      </div>
      <div class="mobile__col-2-wrapper">
        <div class="mobile__multiple-row-item">
          <div class="mobile__cell-item">
            {{ $t("withdraw_guide.fiat_withdrawal") }}
          </div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.no") }}</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.no") }}</div>
          <div class="mobile__cell-item mobile__cell-item--lg">
            {{ $t("withdraw_guide.yes") }}
          </div>
          <div class="mobile__cell-item mobile__cell-item--lg">
            {{ $t("withdraw_guide.yes") }}
          </div>
        </div>
        <div class="mobile__multiple-row-item">
          <div class="mobile__cell-item">
            {{ $t("withdraw_guide.fiat_withdrawal_limit") }}
          </div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.no") }}</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.no") }}</div>
          <div class="mobile__cell-item mobile__cell-item--lg text-start">
            {{ $t("withdraw_guide.fiat_withdrawal_lv3_limit") }} <br />
            {{ $t("withdraw_guide.unlimited_monthly") }}
          </div>
          <div class="mobile__cell-item mobile__cell-item--lg text-start">
            {{ $t("withdraw_guide.fiat_withdrawal_lv4_limit") }}<br />
            {{ $t("withdraw_guide.unlimited_monthly") }}
          </div>
        </div>
      </div>

      <div class="mobile__row-item">
        <div class="mobile__cell-item">
          {{ $t("withdraw_guide.crypto_deposit") }}
        </div>
      </div>
      <div class="mobile__col-2-wrapper">
        <div class="mobile__multiple-row-item">
          <div class="mobile__cell-item mobile__cell-item--sm">
            {{ $t("withdraw_guide.crypto_deposit") }}
          </div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.yes") }}</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.yes") }}</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.yes") }}</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.yes") }}</div>
        </div>
        <div class="mobile__multiple-row-item">
          <div class="mobile__cell-item mobile__cell-item--sm">
            {{ $t("withdraw_guide.crypto_deposit_limit") }}
          </div>
          <div class="mobile__cell-item">
            {{ $t("withdraw_guide.unlimited") }}
          </div>
          <div class="mobile__cell-item">
            {{ $t("withdraw_guide.unlimited") }}
          </div>
          <div class="mobile__cell-item">
            {{ $t("withdraw_guide.unlimited") }}
          </div>
          <div class="mobile__cell-item">
            {{ $t("withdraw_guide.unlimited") }}
          </div>
        </div>
      </div>
      <div class="mobile__row-item">
        <div class="mobile__cell-item">
          {{ $t("withdraw_guide.crypto_withdraw") }}
        </div>
      </div>
      <div class="mobile__col-2-wrapper">
        <div class="mobile__multiple-row-item">
          <div class="mobile__cell-item mobile__cell-item--sm">
            {{ $t("withdraw_guide.crypto_withdraw") }}
          </div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.no") }}</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.no") }}</div>
          <div class="mobile__cell-item mobile__cell-item--lg">
            {{ $t("withdraw_guide.yes") }}
          </div>
          <div class="mobile__cell-item mobile__cell-item--lg">
            {{ $t("withdraw_guide.yes") }}
          </div>
        </div>
        <div class="mobile__multiple-row-item">
          <div class="mobile__cell-item mobile__cell-item--sm">
            {{ $t("withdraw_guide.crypto_withdraw_limit") }}
          </div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.no") }}</div>
          <div class="mobile__cell-item">{{ $t("withdraw_guide.no") }}</div>
          <div class="mobile__cell-item mobile__cell-item--lg text-start">
            {{ $t("withdraw_guide.crypto_withdrawal_lv3_limit") }} <br />
            {{ $t("withdraw_guide.unlimited_monthly") }}
          </div>
          <div class="mobile__cell-item mobile__cell-item--lg text-start">
            {{ $t("withdraw_guide.crypto_withdrawal_lv4_limit") }}<br />
            {{ $t("withdraw_guide.unlimited_monthly") }}
          </div>
        </div>
      </div>
    </div>
    <!-- --------- -->
    <div class="main">
      <div class="row-col-6">
        <div class="col-item">{{ $t("withdraw_guide.kyc_level") }}</div>
        <div class="col-item">{{ $t("withdraw_guide.trade") }}</div>
        <div class="col-multi-item">
          <div class="col-item--md">
            {{ $t("withdraw_guide.fiat_deposit") }}
          </div>
          <div class="row-col-2">
            <div class="col-item--sm">
              {{ $t("withdraw_guide.fiat_deposit") }}
            </div>
            <div class="col-item--sm">
              {{ $t("withdraw_guide.fiat_deposit_limit") }}
            </div>
          </div>
        </div>
        <div class="col-multi-item">
          <div class="col-item--md">
            {{ $t("withdraw_guide.fiat_withdrawal") }}
          </div>
          <div class="row-col-2">
            <div class="col-item--sm">
              {{ $t("withdraw_guide.fiat_withdrawal") }}
            </div>
            <div class="col-item--sm">
              {{ $t("withdraw_guide.fiat_withdrawal_limit") }}
            </div>
          </div>
        </div>
        <div class="col-multi-item">
          <div class="col-item--md">
            {{ $t("withdraw_guide.crypto_deposit") }}
          </div>
          <div class="row-col-2">
            <div class="col-item--sm">
              {{ $t("withdraw_guide.crypto_deposit") }}
            </div>
            <div class="col-item--sm">
              {{ $t("withdraw_guide.crypto_deposit_limit") }}
            </div>
          </div>
        </div>
        <div class="col-multi-item">
          <div class="col-item--md">
            {{ $t("withdraw_guide.crypto_withdraw") }}
          </div>
          <div class="row-col-2">
            <div class="col-item--sm">
              {{ $t("withdraw_guide.crypto_withdraw") }}
            </div>
            <div class="col-item--sm">
              {{ $t("withdraw_guide.crypto_withdraw_limit") }}
            </div>
          </div>
        </div>
      </div>
      <div class="row-col-10">
        <template v-for="(item, index) in kyc_1">
          <div class="col-item--sm" :key="index">{{ item.label }}</div>
        </template>
      </div>
      <div class="row-col-10">
        <template v-for="(item, index) in kyc_2">
          <div class="col-item--sm" :key="index">{{ item.label }}</div>
        </template>
      </div>
      <div class="row-col-10">
        <template v-for="(item, index) in kyc_3">
          <div
            :key="index"
            class="col-item--sm"
            :class="item?.class"
            v-html="item.label"
          ></div>
        </template>
      </div>
      <div class="row-col-10">
        <template v-for="(item, index) in kyc_4">
          <div
            :key="index"
            class="col-item--sm"
            :class="item?.class"
            v-html="item.label"
          ></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import { mapState } from "vuex";

export default {
  data() {
    return {
      withdrawal_limits: [],
      user: {},
      isAuthenticated: window.isAuthenticated,
      kyc_1: [
        { label: this.$i18n.t("withdraw_guide.kyc") + " 1" },
        { label: this.$i18n.t("withdraw_guide.yes") },
        { label: this.$i18n.t("withdraw_guide.no") },
        { label: this.$i18n.t("withdraw_guide.no") },
        { label: this.$i18n.t("withdraw_guide.no") },
        { label: this.$i18n.t("withdraw_guide.no") },
        { label: this.$i18n.t("withdraw_guide.yes") },
        { label: this.$i18n.t("withdraw_guide.unlimited") },
        { label: this.$i18n.t("withdraw_guide.no") },
        { label: this.$i18n.t("withdraw_guide.no") },
      ],
      kyc_2: [
        { label: this.$i18n.t("withdraw_guide.kyc") + " 2" },
        { label: this.$i18n.t("withdraw_guide.yes") },
        { label: this.$i18n.t("withdraw_guide.no") },
        { label: this.$i18n.t("withdraw_guide.no") },
        { label: this.$i18n.t("withdraw_guide.no") },
        { label: this.$i18n.t("withdraw_guide.no") },
        { label: this.$i18n.t("withdraw_guide.yes") },
        { label: this.$i18n.t("withdraw_guide.unlimited") },
        { label: this.$i18n.t("withdraw_guide.no") },
        { label: this.$i18n.t("withdraw_guide.no") },
      ],
      kyc_3: [
        { label: this.$i18n.t("withdraw_guide.kyc") + " 3" },
        { label: this.$i18n.t("withdraw_guide.yes") },
        { label: this.$i18n.t("withdraw_guide.yes") },
        { label: this.$i18n.t("withdraw_guide.unlimited") },
        { label: this.$i18n.t("withdraw_guide.yes") },
        {
          label:
            this.$i18n.t("withdraw_guide.fiat_withdrawal_lv3_limit") +
            " <span style='margin-top: 4px'> " +
            this.$i18n.t("withdraw_guide.unlimited_monthly") +
            "</span>",
          class: "item-multiple-text",
        },
        { label: this.$i18n.t("withdraw_guide.yes") },
        { label: this.$i18n.t("withdraw_guide.unlimited") },
        { label: this.$i18n.t("withdraw_guide.yes") },
        {
          label:
            this.$i18n.t("withdraw_guide.crypto_withdrawal_lv3_limit") +
            " <span style='margin-top: 4px'>" +
            this.$i18n.t("withdraw_guide.unlimited_monthly") +
            "</span>",
          class: "item-multiple-text",
        },
      ],
      kyc_4: [
        { label: this.$i18n.t("withdraw_guide.kyc") + " 4" },
        { label: this.$i18n.t("withdraw_guide.yes") },
        { label: this.$i18n.t("withdraw_guide.yes") },
        { label: this.$i18n.t("withdraw_guide.unlimited") },
        { label: this.$i18n.t("withdraw_guide.yes") },
        {
          label:
            this.$i18n.t("withdraw_guide.fiat_withdrawal_lv4_limit") +
            "<span style='margin-top: 4px'>" +
            this.$i18n.t("withdraw_guide.unlimited_monthly") +
            "</span>",
          class: "item-multiple-text",
        },
        { label: this.$i18n.t("withdraw_guide.yes") },
        { label: this.$i18n.t("withdraw_guide.unlimited") },
        { label: this.$i18n.t("withdraw_guide.yes") },
        {
          label:
            this.$i18n.t("withdraw_guide.crypto_withdrawal_lv4_limit") +
            " <span style='margin-top: 4px'>" +
            this.$i18n.t("withdraw_guide.unlimited_monthly") +
            "</span>",
          class: "item-multiple-text",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
    }),
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === "storage_url")
        .value;
    },
  },
  methods: {
    hightLight: function (level, position = 0) {
      if (position == level) return true;
    },
  },
  mounted() {
    rf.getRequest("MasterdataRequest")
      .getAll()
      .then((res) => {
        this.withdrawal_limits = _.groupBy(res.withdrawal_limits, "currency");
      });
    if (this.isAuthenticated) {
      rf.getRequest("UserRequest")
        .getCurrentUser()
        .then((res) => {
          this.user = res.data;
        });
    }
    document.title =
      this.$t("menu_bottom.withdrawals_guide") + ` – ${this.tabTitle}`;
  },
};
</script>

<style lang="scss" scoped>
// ----------------
.main-mobile__wrapper {
  display: none;
}

.main {
  max-width: 100%;
  overflow: auto;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: $text-main;
}

.row-col-10 {
  display: grid;
  grid-template-columns: repeat(2, 86px) repeat(2, 97.5px) repeat(2, 132.5px) repeat(
      2,
      120px
    ) repeat(2, 156.5px);
  gap: 8px;
  margin-left: auto;
  margin-right: auto;
}

.row-col-6 {
  display: grid;
  grid-template-columns: repeat(2, 86px) 203px 273px 248px 321px;
  gap: 8px;
  margin-left: auto;
  margin-right: auto;
}

.row-col-2 {
  display: grid;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.col-item {
  border-radius: 10px;
  background-color: $color-white;
  text-align: center;
}

.col-item {
  text-align: center;
  display: flex;
  border-radius: 10px;
  background-color: $color-white;
  padding: 8px;
  flex-direction: column;
  justify-content: center;

  &--sm {
    text-align: center;
    border-radius: 10px;
    background-color: $color-white;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.item-multiple-text {
      text-align: start;
      justify-content: flex-start;
    }
  }

  &--md {
    text-align: center;
    background-color: $color-white;
    border-radius: 10px;
    display: flex;
    padding: 20px 0;
    flex-direction: column;
    justify-content: center;
  }
}

.col-multi-item {
  display: flex;
  flex-direction: column;

  & > div:first-child {
    margin-bottom: 10px;
  }
}

.row {
  margin: unset;
  display: flex;
  gap: 10px;
}

// -----------------
// Page title
.tit_page {
  @include text-font($helvetica, $text-main, $font-big-24, 700, 40px);
  width: 1257px;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;

  * {
    margin: 0;
  }
}

.main_agreementInner {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-inline: auto;
  padding: 26px 15px 36px;
  max-width: 1620px;
  background-color: $color-alabaster;
  font-family: $helvetica;

  .main {
    display: grid;
    // flex-direction: column;
    gap: 8px;
    grid-gap: 8px;

    // Headings
    .main-header {
      @include text-font($helvetica, $dark-1, $font-big, 700, 22px);
    }

    // Table container
    .table-container {
      display: flex;
      overflow-x: auto;
      padding: 26px 16px 26px 26px;
      background-color: $color-white;
      border-radius: 10px;
    }

    // Table
    .withdrawal-limit {
      margin-inline: auto;
      width: 100%;

      th,
      td {
        white-space: nowrap;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      thead {
        border-bottom: 1px solid $grey-1;

        tr th {
          padding: 0 4px;
        }

        .th_first {
          padding-bottom: 5px;
          @include text-font($helvetica, $dark-1, $font-root, 700, 18px);
        }

        .th_second {
          padding-bottom: 10px;
          @include text-font($helvetica, $dark-3, $font-root, 500, 25px);
        }

        th {
          &.cl-1-1 {
            min-width: 221px;
          }

          &.cl-1-2 {
            min-width: 248px;
          }

          &.cl-1-3 {
            min-width: 280px;
          }

          &.cl-1-4 {
            min-width: 255px;
          }

          &.cl-1-5 {
            min-width: 293px;
          }

          &.cl-2-1 {
            min-width: 136px;
          }

          &.cl-2-2 {
            min-width: 171px;
          }

          &.cl-2-3 {
            min-width: 427px;
          }

          &.cl-2-4 {
            min-width: 131px;
          }

          &.cl-2-5 {
            min-width: 432px;
          }
        }
      }

      tbody {
        tr {
          &:first-child td {
            padding-top: 10px;
          }

          &:last-child td {
            padding-bottom: 0;
          }

          td {
            padding: 5px 4px;
            @include text-font($helvetica, $dark-1, $font-root, 600, 18px);
            vertical-align: middle;
          }
        }
      }
    }
  }
}

@include mobile {
  // Page title
  .tit_page {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: $text-main;
  }

  .main_agreementInner {
    gap: 16px;
    padding: 16px;

    .main {
      // Headings
      .main-header {
        font-size: $font-medium-bigger;
        line-height: 20px;
      }

      // Table container
      .table-container {
        padding: 14px 15px 28px;
      }

      // Table
      .withdrawal-limit {
        thead {
          .th_second {
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}

@include mobile {
  .main_agreementInner {
    .main-mobile__wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .mobile__col-2-wrapper {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
      }

      .mobile__multiple-row-item {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      .mobile__cell-item {
        border-radius: 10px;
        background-color: $color-white;
        padding: 8px;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        color: $text-main;

        &--md {
          padding: 16px 8px;
          height: 96px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        &--lg {
          height: 140px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        &--sm {
          height: 64px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        &.text-start {
          text-align: start;
        }
      }
    }

    .main {
      display: none;
    }
  }
}
</style>
