<template>
  <div id="add-bank-modal">
    <modal :enableClose="false" :name="name" :hasModalFooter="false" @onShow="onShowModal">
      <template slot="body">
        <div class="body-wrapper">
          <p class="text-center title">
            {{ $t('bank_account_management.add_bank') }}
          </p>
          <div class="input-field__wrapper">
            <p class="field-label">{{ $t('bank_account_management.bank') }} <span>*</span></p>
            <SelectBox
              :options="bankOptions"
              v-model="bank"
              :placeholder="$t('bank_account_management.choose_bank')"
              class="bank_select"
              :title-item="'name'"
            />
            <span v-show="errors.has('bank')" class="invalid-feedback">{{
              $t(errors.first('bank'))
            }}</span>
          </div>
          <div class="input-field__wrapper">
            <p class="field-label">
              {{ $t('bank_account_management.account_number') }} <span>*</span>
            </p>
            <div class="field-input__inner">
              <input
                type="text"
                v-model="account_number"
                @focus="resetError"
                :placeholder="$t('bank_account_management.enter_bank_account')"
              />
              <div class="icon-container" :class="{ active: loading }">
                <i class="loader"></i>
              </div>
            </div>
            <span v-show="errors.has('account_number')" class="admin-add-bank">
              <!-- {{ $t(errors.first('account_number')) }} -->
              Bank account information is incorrect, Please
              <a href="https://nvx.freshdesk.com/support/tickets/new" class="admin-hyperlink"
                >contact our admin</a
              >
              for further assistance
            </span>
          </div>
          <div class="input-field__wrapper input-owner__wrapper">
            <p class="field-label">
              {{ $t('bank_account_management.owner_name') }}
            </p>
            <input
              type="text"
              v-model="kyc.full_name"
              :placeholder="$t('bank_account_management.enter_bank_name')"
              :disabled="true"
            />
            <p class="fill-auto-label">
              {{ $t('bank_account_management.fill_bank_auto') }}
            </p>
          </div>
          <div class="btn-group">
            <button class="btn-cancel" @click="close()">
              {{ $t('bank_account_management.cancel') }}
            </button>
            <button class="btn-add" @click="handleValidateBankAccount()">
              {{ $t('bank_account_management.add') }}
            </button>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './Modal.vue';
import SelectBox from './SelectBox.vue';
import rf from '@/request/RequestFactory';
import { isNull, isUndefined, isEmpty } from 'lodash';

export default {
  name: 'AddBankModal',
  components: {
    Modal,
    SelectBox,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    kyc: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      bankOptions: [
        { id: 'bca', name: 'PT. BANK CENTRAL ASIA TBK' },
        { id: 'mandiri', name: 'PT. BANK MANDIRI (PERSERO) TBK' },
        { id: 'permata', name: 'PT. BANK PERMATA TBK' },
        { id: 'cimb', name: 'PT. BANK CIMB NIAGA TBK' },
        { id: 'bni', name: 'PT. BANK NEGARA INDONESIA (PERSERO)' },
      ],
      bank: undefined,
      account_number: undefined,
      owner_name: undefined,
      loading: false,
    };
  },
  watch: {
    account_number(newVal) {
      this.account_number = newVal.replace(/\D/g, '');
    },
  },
  methods: {
    validate() {
      this.resetError();

      if (isNull(this.bank) || isUndefined(this.bank) || isEmpty(this.bank)) {
        this.errors.add('bank', this.$i18n.t('bank_account_management.bank_required'));
      }
      if (
        isNull(this.account_number) ||
        isUndefined(this.account_number) ||
        isEmpty(this.account_number)
      ) {
        this.errors.add('account_number', this.$i18n.t('validation_field_required'));
      }

      if (this.errors.count() > 0) {
        return false;
      }
      return true;
    },

    resetError() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },

    handleValidateBankAccount() {
      if (!this.validate()) return;
      const params = {
        bank: this?.bank,
        account_no: String(this.account_number),
      };
      this.loading = true;
      rf.getRequest('TransactionRequest')
        .validateBankAccount(params)
        .then((res) => {
          this.owner_name = res?.data?.account_name;
          const bankName = res.data.account_name.toUpperCase();
          const kycName = this.kyc.full_name.toUpperCase();
          let isMatching = kycName.includes(bankName);

          if (isMatching) {
            this.addBank();
          } else {
            this.errors.add('account_number', this.$i18n.t('invalid_bank_account'));
          }
        })
        .catch((err) => {
          this.errors.add('account_number', this.$i18n.t('invalid_bank_account'));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onShowModal(val) {
      this.$emit('onShow', val);
    },
    async addBank() {
      let params = {
        bank: this.bank,
        account_number: this.account_number,
        owner_name: this.owner_name,
      };
      await rf
        .getRequest('UserRequest')
        .addBankAccount(params)
        .then((res) => {
          Message.success(this.$i18n.t('bank_account_management.add_bank_success'), {});
          this.$emit('addBankSuccess');
          this.close();
        })
        .catch((err) => {
          this.errors.add('account_number', this.$i18n.t(err.response.data.message));
        });
    },
    close() {
      this.bank = undefined;
      this.account_number = undefined;
      this.owner_name = undefined;
      this.resetError();
      window.CommonModal.hide(this.name);
    },
  },
};
</script>
<style lang="scss" scoped>
#add-bank-modal {
  .body-wrapper {
    // padding: 0 30px 30px 30px;
  }

  input {
    padding: 9px 16px;
    border-radius: 8px;
    border: 1px solid $bg-main2;
    background: $text-main1;
    width: 100%;
    color: $text-main;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    &:focus {
      border: 1px solid $bg-main1;
    }

    @include mobile {
      font-size: 14px;
      line-height: 22px;
      font-weight: 300;
      height: 40px;
    }

    &::placeholder {
      color: $text-main-blur;
    }

    &:disabled {
      color: $text-main-blur;
      -webkit-text-fill-color: $text-main-blur;
    }
  }

  :deep {
    .modal-dialog {
      width: 100%;
      max-width: 414px;

      @include mobile {
        width: calc(100vw - 30px) !important;
        padding: 0;
        transform: translate(0, 0) !important;
        top: 80px;
        left: 15px;

        .modal-body {
          padding: 16px 16px 24px;
        }
      }
    }

    .button_drop_search {
      border: 1px solid $bg-main2;
      padding: 8px 16px;

      @include mobile {
        height: 40px;
      }

      span {
        color: $text-main;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;

        @include mobile {
          font-size: 14px;
          line-height: 22px;
          font-weight: 300;
        }
      }

      .isPlaceholder {
        color: $text-main-blur;
      }

      i {
        font-size: 18px;
      }
    }
  }
}

.btn-add[disabled] {
  &:hover {
    opacity: unset !important;
  }

  cursor: no-drop;
}

.title {
  font-size: 24px;
  color: $text-main;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 30px;

  @include mobile {
    font-size: 18px;
    line-height: 32px;
    margin-top: 0;
    margin-bottom: 16px;
  }
}

.input-field__wrapper {
  margin-bottom: 16px;
}

.field-label {
  color: $text-secondary;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  @include mobile {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: 400;
  }

  span {
    color: $color-red;
  }
}

.field-input__inner {
  position: relative;
}

.fill-auto-label {
  color: rgba($text-main2, 0.38);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-top: 8px;

  @include mobile {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }
}

.btn-group {
  margin-top: 24px;
  display: flex;
  gap: 16px;

  .btn-cancel {
    @include btn-common(contained, md);
    background: $bg-main3;
    color: $text-main;
  }

  .btn-add {
    @include btn-common(contained, md);
  }
}

.icon-container {
  display: none;
  position: absolute;
  right: 10px;
  top: calc(50% - 10px);

  &.active {
    display: block;
  }
}

.loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader::after,
.loader::before {
  content: '';
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #333 #333 transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}

.bank_select :deep() {
  .group_search_select > .box_list_search_select {
    width: 100%;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);

    ul li {
      .full_name_coin_select {
        @include mobile {
          color: $text-main;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.admin-add-bank {
  color: red;
  .admin-hyperlink {
    color: rgb(46, 154, 225);
    text-decoration: underline;
  }
}

.admin-add-bank:hover {
  color: rgb(226, 0, 0);
  .admin-hyperlink {
    color: rgb(1, 135, 224);
  }
}
</style>
