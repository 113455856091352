import BaseRequest from "./base/BaseRequest";

export default class InstantConvert extends BaseRequest {
  getConvertHistory(params) {
    const url = "/instant-convert/history";
    return this.get(url, params);
  }
  exportConvertHistory(params, fileName) {
    const url = "/instant-convert/history/export";
    this.download(url, params, fileName);
  }
  postInstantConvert(data) {
    return this.post("/instant-convert/convert", data);
  }
  getAvailableBalance() {
    return this.get("/instant-convert/available-balance");
  }
  getMarkets() {
    return this.get("/instant-convert/markets");
  }
  getTokensByMarket(market) {
    return this.get(`/instant-convert/tokens?market=${market}`);
  }
  getDataConvert(tradeToken, receivedToken, type, conversionRate) {
    return this.get(
      `/instant-convert/data-convert?trade_token=${tradeToken}&received_token=${receivedToken}&convert_type=${type}&conversion_rate=${conversionRate}`
    );
  }
  refreshPrice(currency, coin) {
    return this.get(
      `/instant-convert/refresh-price?currency=${currency}&coin=${coin}`
    );
  }
  checkConvertStatus() {
    return this.get("/instant-convert/check-enable");
  }
  getConvertRate(price, type) {
    return this.get(
      `/instant-convert/conversion-rate?price=${price}&convert_type=${type}`
    );
  }
}
