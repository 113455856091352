<template>
  <div class="wrapper-resend-otp">
    <div class="text-resend-otp">
      {{ $t('funds.withdrawals.otp.resend.titleSms') }}
      <span
        :class="{ 'text-resend-otp-bold': !isDisable, 'text-resend-otp-bold-disable': isDisable }"
        @click="isDisable ? '' : handleResendOtp()"
        >{{ $t('funds.withdrawals.otp.resend.buttonSms') }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isDisable: Boolean,
  },
  methods: {
    handleResendOtp() {
      this.$emit('button-resend-otp')
    },
  },
}
</script>

<style lang="scss">
.wrapper-resend-otp {
  padding: 8px 12px 8px 12px;
  height: auto;
  background-color: #fff9ed;
  border: 1px solid #a87f2f;
  border-radius: 8px;
  .text-resend-otp {
    flex-direction: row;
    color: #a87f2f;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    display: block;
  }
  .text-resend-otp-bold {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  .text-resend-otp-bold-disable {
    font-weight: bold;
    text-decoration: underline;
  }
}
</style>
