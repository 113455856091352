<template>
  <div class="content-box second">
    <div class="title-step">{{ $t('account.otp.qt_title') }}</div>
    <div class="qr-code">
      <span class="text-grey">{{ $t('account.otp.qr_text') }}</span>
      <img class="img_qr" :src="urlQR" v-if="urlQR" />
      <div class="box_right_qr">
        <button class="btn-show-recovery-code" @click="ShowRecoveryCode = !ShowRecoveryCode">
          <template type="text" class="recovery-code" name="recovery_code" v-if="ShowRecoveryCode">
            {{ key2fa }}
          </template>
          <template v-else>
            {{ $t('account.modal.agreement_otp.view_secret_key') }}
          </template>
        </button>
      </div>
    </div>
  </div>
  <!-- end second -->
</template>
<script>
import rf from '@/request/RequestFactory'

export default {
  data() {
    return {
      urlQR: '',
      key2fa: '',
      ShowRecoveryCode: false
    }
  },
  methods: {
    getQRCodeGoogleUrl() {
      rf.getRequest('UserRequest')
        .getQRCodeGoogleUrl()
        .then((res) => {
          this.urlQR = res.data.url
          this.key2fa = res.data.key
          this.$emit('keyUpdated', this.key2fa)
        })
    }
  },
  created() {
    this.getQRCodeGoogleUrl()
  }
}
</script>

<style lang="scss" scoped>
.box_right_qr {
  .btn-show-recovery-code {
    @include btn-common(outlined, md, false);
    background: $text-main1;
    margin-top: 10px;
  }
}
</style>
