import SpotHome from "@/components/spotexchange_pages/exchange/basic/Home.vue";
import OpenOrderScreenSpotEx from "@/components/spotexchange_pages/order/OpenOrderScreen.vue";
import OrderHistoryScreenSpotEx from "@/components/spotexchange_pages/order/OrderHistoryScreen.vue";
import TradeHistoryScreenSpotEx from "@/components/spotexchange_pages/order/TradeHistoryScreen.vue";
import AboutUs from "@/pages/AboutUs.vue";
import Account from "@/pages/Account.vue";
import ApiCreated from "@/pages/ApiCreated.vue";
import CoinInformation from "@/pages/CoinInformation/CoinInformation.vue";
import ComingSoonPage from "@/pages/ComingSoon.vue";
import FeeTierProgram from "@/pages/FeeTierProgram.vue";
import HelpCenter from "@/pages/HelpCenter.vue";
import ConvertHistory from "@/pages/InstantConvert/ConvertHistory.vue";
import InstantConvert from "@/pages/InstantConvert/InstantConvert.vue";
import NotFound from "@/pages/NotFound.vue";
import Policy from "@/pages/Policy.vue";
import Referral from "@/pages/Referral.vue";
import SlipperyNote from "@/pages/SlipperyNote/SlipperyNote.vue";
import Terms from "@/pages/Terms.vue";
import WithdrawGuide from "@/pages/WithdrawGuide.vue";
import IdentityPersonal from "@/pages/account/IdentityPersonal.vue";
import AddressManager from "@/pages/address/AddressManager.vue";
import Login from "@/pages/auth/Login.vue";
import Register from "@/pages/auth/Register.vue";
import ResetPassword from "@/pages/auth/ResetPassword.vue";
import LicensePage from "@/pages/cooperation/License.vue";
import RedirectPage from "@/pages/cooperation/RedirectPage.vue";
import Balances from "@/pages/funds/Balances.vue";
import DepositFiat from "@/pages/funds/DepositFiat.vue";
import Deposits from "@/pages/funds/Deposits.vue";
import History from "@/pages/funds/History.vue";
import VerifyWithdrawal from "@/pages/funds/VerifyWithdrawal.vue";
import WithdrawFiat from "@/pages/funds/WithdrawFiat.vue";
import Withdrawals from "@/pages/funds/Withdrawals.vue";
import LandingNew from "@/pages/landing/LandingNew.vue";
import OpenOrderScreen from "@/pages/order/OpenOrderScreen.vue";
import OrderHistoryScreen from "@/pages/order/OrderHistoryScreen.vue";
import TradeHistoryScreen from "@/pages/order/TradeHistoryScreen.vue";
import Layout from "./Layout.vue";
import RegisterGoogleAuth from "./pages/auth/RegisterGoogleAuth.vue";

const Path = {
  mode: "history",
  routes: [
    // {
    //   path: '/test-camera',
    //   name: 'TestCamera',
    //   component: TestCamera,
    // },
    {
      path: "/login",
      name: "Login",
      component: Login,
      props: (route) => ({
        confirmationCode: route.query.code,
        deviceCode: route.query.device,
        customRedirect: route.params.customRedirect,
      }),
      meta: {
        guest: true,
      },
    },
    {
      path: "/register",
      name: "Register",
      // redirect: { name: 'Coming soon' },
      component: Register,
      props: (route) => ({
        phone: route.query.phone,
        region: route.query.region,
      }),
      meta: {
        guest: true,
      },
    },
    {
      path: "/register-google-auth",
      name: "RegisterGoogleAuth",
      component: RegisterGoogleAuth,
      props: (route) => ({
        phone: route.query.phone,
        region: route.query.region,
      }),
      meta: {
        auth: true,
      },
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
      props: (route) => ({ token: route.query.token }),
      meta: {
        guest: true,
      },
    },
    {
      path: "/verify-withdrawal",
      name: "Verify Withdrawal",
      component: VerifyWithdrawal,
      props: (route) => ({ token: route.query.token, coin: route.query.coin }),
      meta: {
        auth: false,
      },
    },
    {
      path: "/webview/chart",
      name: "WebviewSpotEx",
      component: () => import("@/pages/webview/ChartIndex.vue"),
      children: [
        {
          path: "/webview/chart/candle",
          name: "SpotExCandleChart",
          component: () => import("@/pages/webview/spot/ChartInfo.vue"),
          props: (route) => ({
            coin: route.query.coin,
            currency: route.query.currency,
            resolution: route.query.resolution,
            lang: route.query.lang,
          }),
        },
        {
          path: "/webview/chart/depth",
          name: "SpotExDepthChart",
          component: () => import("@/pages/webview/spot/HighCharts.vue"),
          props: (route) => ({
            coin: route.query.coin,
            currency: route.query.currency,
            lang: route.query.lang,
          }),
        },
      ],
    },
    {
      path: "/",
      component: Layout,
      key: "Layout",
      children: [
        {
          path: "/",
          name: "Landing Page",
          component: LandingNew,
        },
        {
          path: "/funds/balances",
          name: "Balances",
          component: Balances,
          meta: {
            auth: true,
          },
        },
        {
          path: "/funds/withdraw-idr",
          name: "Withdraw IDR",
          component: WithdrawFiat,
          meta: {
            auth: true,
          },
        },
        {
          path: "/funds/deposit-idr",
          name: "Deposit IDR",
          component: DepositFiat,
          meta: {
            auth: true,
          },
        },
        {
          path: "/funds/withdrawals",
          name: "Withdrawals",
          component: Withdrawals,
          meta: {
            auth: true,
          },
        },
        {
          path: "/funds/deposits",
          name: "Deposits",
          component: Deposits,
          meta: {
            auth: true,
          },
        },
        {
          path: "/funds/history",
          name: "Fund History",
          component: History,
          meta: {
            auth: true,
          },
        },
        {
          path: "/orders/open",
          name: "Open Order",
          component: OpenOrderScreen,
          meta: {
            auth: true,
          },
        },
        {
          path: "/orders/history",
          name: "Order History",
          component: OrderHistoryScreen,
          meta: {
            auth: true,
          },
        },
        {
          path: "/orders/trade-history",
          name: "Trade History",
          component: TradeHistoryScreen,
          meta: {
            auth: true,
          },
        },
        {
          path: "/spot-exchange/basic",
          name: "Spot Exchange",
          component: SpotHome,
          props: (route) => ({
            coin: route.query.coin,
            currency: route.query.currency,
          }),
        },
        {
          path: "/spot-exchange/orders/open",
          name: "Open Order SpotEx",
          component: OpenOrderScreenSpotEx,
          meta: {
            auth: true,
          },
        },
        {
          path: "/spot-exchange/orders/history",
          name: "History Order SpotEx",
          component: OrderHistoryScreenSpotEx,
          meta: {
            auth: true,
          },
        },
        {
          path: "/spot-exchange/orders/trade-history",
          name: "Trade History SpotEx",
          component: TradeHistoryScreenSpotEx,
          meta: {
            auth: true,
          },
        },
        {
          path: "/terms",
          name: "Terms of Use",
          component: Terms,
          props: (route) => ({
            isMobileApp: route.query.isMobileApp,
          }),
        },
        {
          path: "/privacy-policy",
          name: "Privacy Policy",
          component: Policy,
        },
        {
          path: "/referral",
          name: "Referral",
          component: Referral,
          meta: {
            auth: true,
          },
        },
        {
          path: "/address",
          name: "Address Manager",
          component: AddressManager,
          meta: {
            auth: true,
          },
        },
        // {
        //   path: '/fees',
        //   name: 'Trading Fee Guide',
        //   component: Fee,
        // },
        {
          path: "/fee-tier-program",
          name: "FeeTierProgram",
          component: FeeTierProgram,
          meta: {
            auth: true,
          },
        },
        {
          path: "/withdraw-guide",
          name: "Withdrawal Guide",
          component: WithdrawGuide,
        },
        {
          path: "/account",
          name: "Account",
          component: Account,
          meta: {
            auth: true,
          },
        },
        {
          path: "/account/api-key",
          name: "ApiCreated",
          component: ApiCreated,
          meta: {
            auth: true,
          },
        },
        {
          path: "/account/identity-personal",
          name: "IdentityPersonal",
          component: IdentityPersonal,
          meta: {
            auth: true,
          },
        },
        {
          path: "/coin-information",
          name: "Coin Information",
          component: CoinInformation,
        },
        {
          path: "/instant-convert",
          name: "Instant Convert",
          component: InstantConvert,
        },
        {
          path: "/convert-history",
          name: "Convert History",
          component: ConvertHistory,
          meta: {
            auth: true,
          },
        },
        {
          path: "/slippery-note",
          name: "Slippery Note",
          component: SlipperyNote,
          meta: {
            auth: true,
          },
        },
        {
          path: "/licenses",
          name: "License Page",
          component: LicensePage,
        },
        {
          path: "/about-us",
          name: "About Us",
          component: AboutUs,
        },
        {
          path: "/help-center",
          name: "Help Center",
          component: HelpCenter,
        },
        {
          path: "/redirect",
          name: "Redirect Page",
          component: RedirectPage,
        },
        // coming soon
        {
          path: "/coming-soon",
          name: "Coming soon",
          component: ComingSoonPage,
        },
        // ---------------
        {
          path: "*",
          name: "404",
          component: NotFound,
        },
      ],
    },
  ],
};

export const PathRoot = {
  path: "/salespoint",
  redirect: { name: "trading", params: { coin: "btc", currency: "idr" } },
};

export const getLayoutPath = (refs, returnPathString = false) => {
  let getdata = ({ path = "", name = "" }, needPathStingOnly) => {
    const children = Path.routes.find((el) => el.key === "Layout").children;
    const dataByPath = children.find((el) => el.path === path);
    const dataByName = children.find((el) => el.name === name);
    if (!needPathStingOnly) return name ? dataByName : dataByPath;
    return name ? dataByName.path : dataByPath.path;
  };

  try {
    if (typeof refs == "object" && refs.length > 0) {
      const data = refs.map((el, id) => {
        const value = getdata(el, false);
        return (el = { ...el, ...value });
      });
      return data;
    } else {
      return getdata(refs, returnPathString);
    }
  } catch (err) {
    console.error(err);
  }
};

export default Path;
