<template>
  <div id="form-input-order-id">
    <!--left-->
    <div class="form-input-order">
      <div class="head_form_order cleafix">
        <ul>
          <li
            @click="showFormOrder = CONSTS.TYPE_BUY"
            class="tab-buy"
            v-bind:class="{ active: showFormOrder === CONSTS.TYPE_BUY }"
          >
            {{ $t('order.order_form.buy') }}
            <!-- {{coin | currencyName}} -->
          </li>
          <li
            @click="showFormOrder = CONSTS.TYPE_SELL"
            class="tab-sell"
            v-bind:class="{ active: showFormOrder === CONSTS.TYPE_SELL }"
          >
            {{ $t('order.order_form.sell') }}
            <!-- {{coin | currencyName}} -->
          </li>
        </ul>
      </div>

      <div class="content_form_order">
        <div class="content_form_buy" v-if="showFormOrder === CONSTS.TYPE_BUY">
          <div class="right_from_mn cleafix">
            <!-- <i class="icon_money icon-wallet"></i> -->
            <img src="/images/spot_exchange/u_wallet.svg" alt="" class="icon_money icon-wallet" />
            <span class="align_vertical">
              {{ currencyBalance | formatCurrencyAmount(currency, '0') }}
              {{ toUpperCase(currency) }}
            </span>
          </div>
          <div class="group_input_form cleafix">
            <!-- <span class="txt_left_group" v-show="tab === 'limit'||tab === 'market'">
                {{ $t('order.order_form.price') }}
              </span> -->
            <label
              class="input_right_group"
              v-show="tab === 'limit' || tab === 'market'"
              @click="removeSelected('buy', tab)"
            >
              <MarkedInput
                v-show="tab === 'limit'"
                v-model="limitBuyOrder.price"
                :unit="currency"
                :placeholder="$t('order.order_form.price')"
                :precision="pricePrecision"
                :classes="classInput"
                :visible-button="true"
              />
              <MarkedInput
                v-show="tab === 'market'"
                :disabled="true"
                :value="$t('trading_information.market_price')"
                :placeholder="$t('order.order_form.price')"
                :input-text="true"
                :component="OrderForm"
              />
            </label>
          </div>

          <div class="group_input_form cleafix">
            <!-- span class="txt_left_group"  v-show="tab === 'stop_limit'||tab === 'stop_market'">
                {{ $t('order.order_form.stop') }}
              </span> -->
            <label
              @click="removeSelected('buy', tab)"
              class="input_right_group"
              v-show="tab === 'stop_limit' || tab === 'stop_market'"
            >
              <MarkedInput
                v-show="tab === 'stop_limit'"
                v-model="stopLimitBuyOrder.stop"
                :unit="currency"
                :placeholder="$t('order.order_form.stop')"
                :precision="pricePrecision"
                :classes="classInput"
                :visible-button="true"
              />
              <MarkedInput
                v-show="tab === 'stop_market'"
                v-model="stopMarketBuyOrder.stop"
                :unit="currency"
                :placeholder="$t('order.order_form.stop')"
                :precision="pricePrecision"
                :classes="classInput"
                :visible-button="true"
              />
            </label>
          </div>

          <div class="group_input_form cleafix">
            <!--  <span class="txt_left_group"  v-show="tab === 'stop_limit'||tab === 'stop_market'">
                {{ $t('order.order_form.limited_price') }}
              </span> -->
            <label
              @click="removeSelected('buy', tab)"
              class="input_right_group"
              v-show="tab === 'stop_limit' || tab === 'stop_market'"
            >
              <MarkedInput
                v-show="tab === 'stop_limit'"
                v-model="stopLimitBuyOrder.price"
                :unit="currency"
                :placeholder="$t('order.order_form.limited_price')"
                :precision="pricePrecision"
                :classes="classInput"
                :visible-button="true"
              />
              <MarkedInput
                :disabled="true"
                v-show="tab === 'stop_market'"
                :value="$t('trading_information.market_price')"
                :placeholder="$t('order.order_form.limited_price')"
                :input-text="true"
                :component="OrderForm"
              />
            </label>
          </div>

          <div class="group_input_form cleafix">
            <!--  <span class="txt_left_group">
                {{ $t('order.order_form.amount') }}
              </span> -->
            <label @click="removeSelected('buy', tab)" class="input_right_group">
              <MarkedInput
                v-show="tab === 'limit'"
                v-model="limitBuyOrder.amount"
                :unit="coin"
                :placeholder="$t('order.order_form.amount')"
                :precision="amountPrecision"
                :classes="classAmount"
                :visible-button="true"
              />
              <MarkedInput
                v-show="tab === 'market'"
                v-model="marketBuyOrder.amount"
                :unit="coin"
                :placeholder="$t('order.order_form.amount')"
                :precision="amountPrecision"
                :classes="classAmount"
                :visible-button="true"
              />
              <MarkedInput
                v-show="tab === 'stop_limit'"
                v-model="stopLimitBuyOrder.amount"
                :unit="coin"
                :placeholder="$t('order.order_form.amount')"
                :precision="amountPrecision"
                :classes="classAmount"
                :visible-button="true"
              />
              <MarkedInput
                v-show="tab === 'stop_market'"
                v-model="stopMarketBuyOrder.amount"
                :unit="coin"
                :placeholder="$t('order.order_form.amount')"
                :precision="amountPrecision"
                :classes="classAmount"
                :visible-button="true"
              />
            </label>
          </div>

          <div class="group_input_form cleafix">
            <span class="txt_left_group mgb-none"></span>
            <label class="input_right_group percent">
              <!-- <button class="per_btn" v-bind:class="{'activeBtnBuy' : percentBuy === 25}" @click="onBuyPercentClicked(25, tab)">25%</button> -->
              <span
                class="check_percent"
                tabindex="0"
                :class="{ selected: selectedPercent[tab]['p_25']['buy'] }"
                @click="onBuyPercentClicked(25, tab)"
                >25%</span
              >
              <span
                class="check_percent"
                tabindex="0"
                :class="{ selected: selectedPercent[tab]['p_50']['buy'] }"
                @click="onBuyPercentClicked(50, tab)"
                >50%</span
              >
              <span
                class="check_percent"
                tabindex="0"
                :class="{ selected: selectedPercent[tab]['p_75']['buy'] }"
                @click="onBuyPercentClicked(75, tab)"
                >75%</span
              >
              <span
                class="check_percent"
                tabindex="0"
                :class="{ selected: selectedPercent[tab]['p_100']['buy'] }"
                @click="onBuyPercentClicked(100, tab)"
                >100%</span
              >
            </label>
          </div>

          <div class="group_input_form cleafix">
            <!-- <span class="txt_left_group" v-show="tab === 'limit'||tab === 'stop_limit'">
                {{ $t('order.order_form.total') }}
              </span> -->
            <label
              @click="removeSelected('buy', tab)"
              class="input_right_group"
              v-show="tab === 'limit' || tab === 'stop_limit'"
            >
              <MarkedInput
                :unit="currency"
                v-show="tab === 'limit'"
                v-model="limitBuyOrder.total"
                :placeholder="$t('order.order_form.total')"
                :precision="pricePrecision"
                :classes="classTotal"
                :visible-button="false"
              />
              <MarkedInput
                :unit="currency"
                v-show="tab === 'stop_limit'"
                v-model="stopLimitBuyOrder.total"
                :placeholder="$t('order.order_form.total')"
                :precision="pricePrecision"
                :classes="classTotal"
                :visible-button="false"
              />
            </label>
          </div>

          <div class="group_input_form cleafix" v-if="Islogin">
            <span class="txt_left_group"></span>
            <div class="input_right_group no-select">
              <button
                class="button-success"
                @click="actionBuyOrderClicked()"
                :disabled="
                  disableBuySell ||
                  waitCountDownTimeoutRemain ||
                  blockTrading ||
                  isSubmitting ||
                  disableByBetaTester
                "
              >
                {{ $t('order.order_form.buy') }}
              </button>
              <!-- <button class="button-success" @click="actionBuyOrderClicked()" :disabled="disableBuySell || waitCountDownTimeoutRemain || blockTrading || isSubmitting">{{ $t('order.order_form.buy') }}</button> -->
            </div>
          </div>

          <div
            v-if="currentUser?.is_otc === 'true'"
            class="group_input_form cleafix"
            style="margin-top: 2vh"
          >
            <div class="column">
              <div class="action-group">
                <span class="action-label">Tax</span>
                <div
                  class="toggle-switch"
                  :class="params.tax ? 'on' : 'off'"
                  @click="changeStatusSwitch('tax')"
                >
                  <span class="toggle-handle"></span>
                </div>
              </div>

              <div class="action-group">
                <span class="action-label">Report Bappebti</span>
                <div
                  class="toggle-switch"
                  :class="params.bappebti === 1 ? 'on' : 'off'"
                  @click="changeStatusSwitch('bappebti')"
                >
                  <span class="toggle-handle"></span>
                </div>
              </div>

              <div class="action-group">
                <span class="action-label">Report CFX</span>
                <div
                  class="toggle-switch"
                  :class="params.cfx === 1 ? 'on' : 'off'"
                  @click="changeStatusSwitch('cfx')"
                >
                  <span class="toggle-handle"></span>
                </div>
              </div>

              <div class="action-group">
                <span class="action-label">Update Volume</span>
                <div
                  class="toggle-switch"
                  :class="params.is_update_volume === 1 ? 'on' : 'off'"
                  @click="changeStatusSwitch('is_update_volume')"
                >
                  <span class="toggle-handle"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="group_input_form cleafix fee-maker-taker-container" v-if="Islogin">
            <span class="fee-maker-taker"
              >Fee maker <strong>{{ feeMaker }}%</strong> - Fee taker
              <strong>{{ feeTaker }}%</strong></span
            >
          </div>

          <div class="group_input_form cleafix mgt15" v-if="!Islogin">
            <span class="txt_left_group"></span>
            <label class="input_right_group">
              <div class="textAuthen">
                <router-link class="but_get_log" :to="{ name: 'Login' }">{{
                  $t('order.order_form.login')
                }}</router-link>
                <span> {{ $t('order.order_form.or') }} </span>
                <router-link class="but_get_log" :to="{ name: 'Register' }">{{
                  $t('order.order_form.register')
                }}</router-link>
                <span> {{ $t('order.order_form.trade') }}</span>
              </div>
            </label>
          </div>
        </div>

        <div class="content_form_sell" v-if="showFormOrder === CONSTS.TYPE_SELL">
          <div class="right_from_mn cleafix">
            <!-- <i class="icon_money icon-wallet"></i> -->
            <img src="/images/spot_exchange/u_wallet.svg" alt="" class="icon_money icon-wallet" />
            <span class="align_vertical">
              {{ coinBalance | formatCurrencyAmount(coin, '0') }}
              {{ toUpperCase(coin) }}
            </span>
          </div>
          <div class="group_input_form cleafix">
            <!-- <span class="txt_left_group" v-show="tab === 'limit'||tab === 'market'">
                {{ $t('order.order_form.price') }}
              </span> -->
            <label
              @click="removeSelected('sell', tab)"
              class="input_right_group"
              v-show="tab === 'limit' || tab === 'market'"
            >
              <MarkedInput
                v-show="tab === 'limit'"
                v-model="limitSellOrder.price"
                :unit="currency"
                :placeholder="$t('order.order_form.price')"
                :precision="pricePrecision"
                :classes="classInput"
                :visible-button="true"
              />
              <MarkedInput
                :disabled="true"
                v-show="tab === 'market'"
                :value="$t('trading_information.market_price')"
                :placeholder="$t('order.order_form.price')"
                :input-text="true"
                :component="OrderForm"
              />
            </label>
          </div>

          <div class="group_input_form cleafix">
            <label
              @click="removeSelected('sell', tab)"
              class="input_right_group"
              v-show="tab === 'stop_limit' || tab === 'stop_market'"
            >
              <MarkedInput
                v-show="tab === 'stop_limit'"
                v-model="stopLimitSellOrder.stop"
                :unit="currency"
                :precision="pricePrecision"
                :placeholder="$t('order.order_form.stop')"
                :classes="classInput"
                :visible-button="true"
              />
              <MarkedInput
                v-show="tab === 'stop_market'"
                v-model="stopMarketSellOrder.stop"
                :unit="currency"
                :precision="pricePrecision"
                :placeholder="$t('order.order_form.stop')"
                :classes="classInput"
                :visible-button="true"
              />
            </label>
          </div>

          <div class="group_input_form cleafix">
            <!-- <span class="txt_left_group" v-show="tab === 'stop_limit'||tab === 'stop_market'">
                {{ $t('order.order_form.limited_price') }}
              </span> -->
            <label
              @click="removeSelected('sell', tab)"
              class="input_right_group"
              v-show="tab === 'stop_limit' || tab === 'stop_market'"
            >
              <MarkedInput
                v-show="tab === 'stop_limit'"
                v-model="stopLimitSellOrder.price"
                :unit="currency"
                :placeholder="$t('order.order_form.limited_price')"
                :precision="pricePrecision"
                :classes="classInput"
                :visible-button="true"
              />
              <MarkedInput
                :disabled="true"
                v-show="tab === 'stop_market'"
                :value="$t('trading_information.market_price')"
                :placeholder="$t('order.order_form.limited_price')"
                :input-text="true"
                :component="OrderForm"
              />
            </label>
          </div>

          <div class="group_input_form cleafix">
            <!--  <span class="txt_left_group">
                {{ $t('order.order_form.amount') }}
              </span> -->
            <label class="input_right_group" @click="removeSelected('sell', tab)">
              <MarkedInput
                v-show="tab === 'limit'"
                v-model="limitSellOrder.amount"
                :unit="coin"
                :placeholder="$t('order.order_form.amount')"
                :precision="amountPrecision"
                :classes="classAmount"
                :visible-button="true"
              />
              <MarkedInput
                v-show="tab === 'market'"
                v-model="marketSellOrder.amount"
                :unit="coin"
                :placeholder="$t('order.order_form.amount')"
                :precision="amountPrecision"
                :classes="classAmount"
                :visible-button="true"
              />
              <MarkedInput
                v-show="tab === 'stop_limit'"
                v-model="stopLimitSellOrder.amount"
                :unit="coin"
                :placeholder="$t('order.order_form.amount')"
                :precision="amountPrecision"
                :classes="classAmount"
                :visible-button="true"
              />
              <MarkedInput
                v-show="tab === 'stop_market'"
                v-model="stopMarketSellOrder.amount"
                :unit="coin"
                :placeholder="$t('order.order_form.amount')"
                :precision="amountPrecision"
                :classes="classAmount"
                :visible-button="true"
              />
            </label>
          </div>
          <div class="group_input_form cleafix">
            <span class="txt_left_group mgb-none"></span>
            <label class="input_right_group percent">
              <!-- <button class="per_btn" v-bind:class="{'activeBtnBuy' : percentBuy === 25}" @click="onSellPercentClicked(25, tab)">25%</button> -->
              <span
                class="check_percent"
                :class="{ selected: selectedPercent[tab]['p_25']['sell'] }"
                @click="onSellPercentClicked(25, tab)"
                >25%</span
              >
              <span
                class="check_percent"
                :class="{ selected: selectedPercent[tab]['p_50']['sell'] }"
                @click="onSellPercentClicked(50, tab)"
                >50%</span
              >
              <span
                class="check_percent"
                :class="{ selected: selectedPercent[tab]['p_75']['sell'] }"
                @click="onSellPercentClicked(75, tab)"
                >75%</span
              >
              <span
                class="check_percent"
                :class="{ selected: selectedPercent[tab]['p_100']['sell'] }"
                @click="onSellPercentClicked(100, tab)"
                >100%</span
              >
            </label>
          </div>

          <div class="group_input_form cleafix">
            <!-- <span class="txt_left_group" v-show="tab === 'limit'||tab === 'stop_limit'">{{ $t('order.order_form.total') }}</span> -->
            <label
              @click="removeSelected('sell', tab)"
              class="input_right_group"
              v-show="tab === 'limit' || tab === 'stop_limit'"
            >
              <MarkedInput
                :unit="currency"
                v-show="tab === 'limit'"
                v-model="limitSellOrder.total"
                :placeholder="$t('order.order_form.total')"
                :precision="pricePrecision"
                :classes="classTotal"
                :visible-button="false"
              />
              <MarkedInput
                :unit="currency"
                v-show="tab === 'stop_limit'"
                v-model="stopLimitSellOrder.total"
                :placeholder="$t('order.order_form.total')"
                :precision="pricePrecision"
                :classes="classTotal"
                :visible-button="false"
              />
            </label>
          </div>

          <div class="group_input_form cleafix" v-if="Islogin">
            <span class="txt_left_group"></span>
            <div class="input_right_group no-select">
              <button
                class="button-danger"
                @click="actionSellOrderClicked()"
                :disabled="
                  disableBuySell ||
                  waitCountDownTimeoutRemain ||
                  blockTrading ||
                  isSubmitting ||
                  disableByBetaTester
                "
              >
                {{ $t('order.order_form.sell') }}
              </button>
              <!-- <button class="button-danger" @click="actionSellOrderClicked()" :disabled="disableBuySell || waitCountDownTimeoutRemain || blockTrading || isSubmitting ">{{ $t('order.order_form.sell') }}</button> -->
            </div>
          </div>

          <div
            v-if="currentUser?.is_otc === 'true'"
            class="group_input_form cleafix"
            style="margin-top: 2vh"
          >
            <div class="column">
              <div class="action-group">
                <span class="action-label">Tax</span>
                <div
                  class="toggle-switch"
                  :class="params.tax ? 'on' : 'off'"
                  @click="changeStatusSwitch('tax')"
                >
                  <span class="toggle-handle"></span>
                </div>
              </div>

              <div class="action-group">
                <span class="action-label">Report Bappebti</span>
                <div
                  class="toggle-switch"
                  :class="params.bappebti === 1 ? 'on' : 'off'"
                  @click="changeStatusSwitch('bappebti')"
                >
                  <span class="toggle-handle"></span>
                </div>
              </div>

              <div class="action-group">
                <span class="action-label">Report CFX</span>
                <div
                  class="toggle-switch"
                  :class="params.cfx === 1 ? 'on' : 'off'"
                  @click="changeStatusSwitch('cfx')"
                >
                  <span class="toggle-handle"></span>
                </div>
              </div>

              <div class="action-group">
                <span class="action-label">Update Volume</span>
                <div
                  class="toggle-switch"
                  :class="params.is_update_volume === 1 ? 'on' : 'off'"
                  @click="changeStatusSwitch('is_update_volume')"
                >
                  <span class="toggle-handle"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="group_input_form cleafix fee-maker-taker-container" v-if="Islogin">
            <span class="fee-maker-taker"
              >Fee maker <strong>{{ feeMaker }}%</strong> - Fee taker
              <strong>{{ feeTaker }}%</strong></span
            >
          </div>

          <div class="group_input_form cleafix mgt15" v-if="!Islogin">
            <span class="txt_left_group"></span>
            <label class="input_right_group">
              <div class="textAuthen">
                <a class="but_get_log" href="/login">{{ $t('order.order_form.login') }}</a>
                <span> {{ $t('order.order_form.or') }} </span>
                <a class="but_get_log" href="/register">{{ $t('order.order_form.register') }}</a>
                <span> {{ $t('order.order_form.trade') }}</span>
              </div>
            </label>
          </div>
        </div>

        <div
          class="group_input_form cleafix trading-block-status"
          v-if="waitCountDownTimeoutRemain || blockTrading"
        >
          <span class="txt_left_group"></span>
          <label class="input_right_group">
            <span
              v-if="(displayRemainSecondBlockTrading || 0) > 0"
              v-html="
                $t('circuit_breaker.remain_second_text', {
                  minutes: convertSecondToMinute(displayRemainSecondBlockTrading),
                })
              "
            ></span>
          </label>
        </div>

        <div class="group_input_form cleafix trading-block-status" v-if="disableByBetaTester">
          <span class="txt_left_group"></span>
          <label class="input_right_group">
            <span v-html="$t('beta_tester.disable_by_beta_tester')"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory';
import moment from 'moment';
import Utils from '@/common/Utils';
import Const from '@/common/Const';
import OrderUtils from '@/common/OrderUtils';
import BigNumber from 'bignumber.js';
import MarkedInput from '@/components/spotexchange_pages/common/MarkedInput';
import { user } from '@/main';

const ALLOW_TRADING = false;
const BLOCK_TRADING = true;
const TYPE_SELL = 0;
const TYPE_BUY = 1;

export default {
  components: {
    MarkedInput,
  },

  props: ['tab', 'latestPrices'],

  data() {
    return {
      currentUser: {},
      params: {
        tax: true,
        cfx: 1,
        bappebti: 1,
        is_update_volume: 1,
      },
      coin: this.$route.query.coin || Const.DEFAULT_COIN,
      currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
      Islogin: window.isAuthenticated,
      currentPrice: undefined,
      prices: {},
      balances: {},
      percentSell: 0,
      percentBuy: 0,
      isActiveBuyPercent: false,
      masterData: undefined,
      coinSetting: undefined,
      clickRow: false,

      currencyBalance: undefined,
      coinBalance: undefined,
      limitBuyOrder: {
        price: undefined,
        amount: undefined,
        total: undefined,
        percent: 0,
      },
      limitSellOrder: {
        price: undefined,
        amount: undefined,
        total: undefined,
        percent: 0,
      },
      marketBuyOrder: {
        amount: undefined,
        percent: 0,
      },
      marketSellOrder: {
        amount: undefined,
        percent: 0,
      },
      stopLimitBuyOrder: {
        stop: undefined,
        price: undefined,
        amount: undefined,
        total: undefined,
        percent: 0,
      },
      stopLimitSellOrder: {
        stop: undefined,
        price: undefined,
        amount: undefined,
        total: undefined,
        percent: 0,
      },
      stopMarketBuyOrder: {
        stop: undefined,
        amount: undefined,
        percent: 0,
      },
      stopMarketSellOrder: {
        stop: undefined,
        amount: undefined,
        percent: 0,
      },

      selectedPercent: {
        limit: {
          p_25: {
            buy: false,
            sell: false,
          },
          p_50: {
            buy: false,
            sell: false,
          },
          p_75: {
            buy: false,
            sell: false,
          },
          p_100: {
            buy: false,
            sell: false,
          },
        },
        market: {
          p_25: {
            buy: false,
            sell: false,
          },
          p_50: {
            buy: false,
            sell: false,
          },
          p_75: {
            buy: false,
            sell: false,
          },
          p_100: {
            buy: false,
            sell: false,
          },
        },
        stop_limit: {
          p_25: {
            buy: false,
            sell: false,
          },
          p_50: {
            buy: false,
            sell: false,
          },
          p_75: {
            buy: false,
            sell: false,
          },
          p_100: {
            buy: false,
            sell: false,
          },
        },
        stop_market: {
          p_25: {
            buy: false,
            sell: false,
          },
          p_50: {
            buy: false,
            sell: false,
          },
          p_75: {
            buy: false,
            sell: false,
          },
          p_100: {
            buy: false,
            sell: false,
          },
        },
      },
      classAmount: 'input-amount',
      classTotal: 'input-total',
      OrderForm: 'order-form',
      classInput: 'border-bottom',
      showFormOrder: 1,
      isSubmitting: false,
      disableBuySell: false, //   disable for go live production
      blockTrading: false, // disable trading for circuit breaker
      disableByBetaTester: false,
      remainSecondBlockTrading: 0,
      displayRemainSecondBlockTrading: 0,
      countTimeoutRemain: null,
      waitCountDownTimeoutRemain: false,
      sellOrderBookDisplay: [],
      CONSTS: {
        ...Const,
        TYPE_SELL: TYPE_SELL,
        TYPE_BUY: TYPE_BUY,
      },
      feeMaker: undefined,
      feeTaker: undefined,
    };
  },

  watch: {
    latestPrices(newValue, oldValue) {
      this.onPricesUpdated(newValue);
    },
    remainSecondBlockTrading(remainSeconds) {
      this.displayRemainSecondBlockTrading = remainSeconds;
      if (this.countTimeoutRemain) {
        clearInterval(this.countTimeoutRemain);
      }

      if (this.blockTrading) {
        this.countTimeoutRemain = setInterval(() => {
          this.displayRemainSecondBlockTrading--;
          if (this.displayRemainSecondBlockTrading <= 0) {
            this.displayRemainSecondBlockTrading = 0;
            clearInterval(this.countTimeoutRemain);
            // this.showSuccess(this.$i18n.t("circuit_breaker.unblocking"));
            this.showUnblocking();
          }
        }, 1000);
      }
    },
    tab() {
      if (this.tab === 'limit') {
        this.percentBuy = this.limitBuyOrder.percent;
        this.percentSell = this.limitSellOrder.percent;
      } else if (this.tab === 'market') {
        this.percentBuy = this.marketBuyOrder.percent;
        this.percentSell = this.marketSellOrder.percent;
      } else if (this.tab === 'stop_limit') {
        this.percentBuy = this.stopLimitBuyOrder.percent;
        this.percentSell = this.stopLimitSellOrder.percent;
      } else {
        this.percentBuy = this.stopMarketSellOrder.percent;
        this.percentSell = this.stopMarketSellOrder.percent;
      }
    },

    //=================== limit Buy Order ===================
    'limitBuyOrder.price'(newValue, oldValue) {
      if (window._.isEmpty(newValue)) {
        return;
      }
      this.onChangePriceInput(newValue, this.limitBuyOrder);
    },
    'limitBuyOrder.amount'(newValue, oldValue) {
      this.onChangeAmountInput(newValue, oldValue, this.limitBuyOrder);
    },
    'limitBuyOrder.total'(newValue, oldValue) {
      this.onChangeTotalInput(newValue, oldValue, this.limitBuyOrder);
    },

    //=================== limit Sell Order ===================
    'limitSellOrder.price'(newValue, oldValue) {
      this.onChangePriceInput(newValue, this.limitSellOrder);
    },
    'limitSellOrder.amount'(newValue, oldValue) {
      this.onChangeAmountInput(newValue, oldValue, this.limitSellOrder);
    },
    'limitSellOrder.total'(newValue, oldValue) {
      this.onChangeTotalInput(newValue, oldValue, this.limitSellOrder);
    },

    //=================== Stop limit Buy Order ===================
    'stopLimitBuyOrder.price'(newValue, oldValue) {
      this.onChangePriceInput(newValue, this.stopLimitBuyOrder);
    },
    'stopLimitBuyOrder.amount'(newValue, oldValue) {
      this.onChangeAmountInput(newValue, oldValue, this.stopLimitBuyOrder);
    },
    'stopLimitBuyOrder.total'(newValue, oldValue) {
      this.onChangeTotalInput(newValue, oldValue, this.stopLimitBuyOrder);
    },

    //=================== Stop limit Sell Order ===================
    'stopLimitSellOrder.price'(newValue, oldValue) {
      this.onChangePriceInput(newValue, this.stopLimitSellOrder);
    },
    'stopLimitSellOrder.amount'(newValue, oldValue) {
      this.onChangeAmountInput(newValue, oldValue, this.stopLimitSellOrder);
    },
    'stopLimitSellOrder.total'(newValue, oldValue) {
      this.onChangeTotalInput(newValue, oldValue, this.stopLimitSellOrder);
    },

    $route(to, from) {
      this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
      this.coin = this.$route.query.coin || Const.DEFAULT_COIN;
      this.getFeeMakerTaker();

      this.onCurrencyPairChanged();
    },
  },

  computed: {
    pricePrecision() {
      return this.coinSetting ? parseFloat(this.coinSetting.price_precision) : Number(0.0001);
    },
    amountPrecision() {
      return this.coinSetting ? parseFloat(this.coinSetting.quantity_precision) : Number(0.0001);
    },
    minimumTotalOrder() {
      return this.coinSetting ? parseFloat(this.coinSetting.minimum_amount) : Number(0.0001);
    },
  },

  methods: {
    getUser(useCache = true) {
      rf.getRequest('UserRequest')
        .getCurrentUser(useCache)
        .then((res) => {
          this.currentUser = Object.assign({}, this.user, res.data);
        });
    },
    changeStatusSwitch(type) {
      this.showResult = false;
      if (!this.isSubmit) {
        switch (type) {
          case 'bappebti':
            if (this.params.bappebti === 0) this.params.bappebti = 1;
            else if (this.params.bappebti === 1) this.params.bappebti = 0;
            break;
          case 'cfx':
            if (this.params.cfx === 0) this.params.cfx = 1;
            else if (this.params.cfx === 1) this.params.cfx = 0;
            break;
          case 'is_update_volume':
            if (this.params.is_update_volume === 0) this.params.is_update_volume = 1;
            else if (this.params.is_update_volume === 1) this.params.is_update_volume = 0;
            break;
          case 'tax':
            this.params.tax = !this.params.tax;
            break;
          default:
            break;
        }
      }
    },
    isGreaterThanZero(value) {
      if (new BigNumber(`${value || 0}`).comparedTo(0) > 0) {
        return true;
      }
      return false;
    },
    getEventHandlers() {
      return {
        RecentTradeClicked: this.onRecentTradesClick,
        SellOrderBookClicked: this.onSellOrderBookClicked,
        BuyOrderBookClicked: this.onBuyOrderBookClicked,
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        FocusOrderForm: this.onFocusOrderForm,
        IgnoreBetaTesterStatusUpdate: this.onIgnoreBetaTesterStatusUpdate,
        SpotSellOrderBookDisplay: this.onSpotSellOrderBookDisplay,
      };
    },
    onIgnoreBetaTesterStatusUpdate(ignoreStatus) {
      if (ignoreStatus == this.CONSTS.BETA_TESTER.ACTIVE) {
        this.disableByBetaTester = false;
      } else {
        this.disableByBetaTester = true;
      }
    },
    onSpotSellOrderBookDisplay(data) {
      this.sellOrderBookDisplay = window._.cloneDeep(data);
    },
    onFocusOrderForm(type, price, maxAmount) {
      if (type == 'sell') {
        this.showFormOrder = 1;
        this.onSellOrderBookClicked(price, maxAmount);
      } else {
        this.showFormOrder = 0;
        this.onBuyOrderBookClicked(price, maxAmount);
      }
    },

    onSpotSelectedPairMarket(pair) {
      this.coin = pair.coin;
      this.currency = pair.currency;
      this.blockTrading = this.getBlockTradingStatus(this.currency, this.coin);
      this.onCurrencyPairChanged();
    },

    onRecentTradesClick(price) {
      this.updateLimitBuyOrder(price);
      this.updateStopLimitBuyOrder(price);
      this.updateStopMarketBuyOrder(price);

      this.updateLimitSellOrder(price);
      this.updateStopLimitSellOrder(price);
      this.updateStopMarketSellOrder(price);
    },

    onSellOrderBookClicked(price, maxAmount) {
      this.clickRow = true;
      this.clearLimitSellOrderForm();
      this.clearStopLimitSellOrderForm();

      this.updateLimitSellOrder(price);
      this.updateStopLimitSellOrder(price);

      const amount = this.calculateAmountSellOrderBookClicked(
        price,
        maxAmount,
        this.currencyBalance
      );

      this.updateLimitBuyOrder(price, amount);
      this.updateStopLimitBuyOrder(price, amount);
      this.updateStopMarketBuyOrder(price, amount);
    },

    onBuyOrderBookClicked(price, maxAmount) {
      this.clickRow = true;
      this.clearLimitBuyOrderForm();
      this.clearStopLimitBuyOrderForm();

      this.updateLimitBuyOrder(price);
      this.updateStopLimitBuyOrder(price);

      const amount = this.calculateAmountBuyOrderBookClicked(price, maxAmount, this.coinBalance);

      this.updateLimitSellOrder(price, amount);
      this.updateStopLimitSellOrder(price, amount);
      this.updateStopMarketSellOrder(price, amount);
    },

    calculateAmountSellOrderBookClicked(price, maxAmount, rawBalance) {
      if (!this.Islogin) {
        return maxAmount;
      }
      if (isNaN(rawBalance)) {
        return 0;
      }
      const balance = new BigNumber(`${rawBalance}`);

      const totalCurrency = new BigNumber(`${price}`).mul(`${maxAmount}`);

      let amount = 0;
      if (totalCurrency.gt(balance)) {
        // const exponent = `${Math.pow(10, this.amountPrecision)}`;
        amount = balance.div(`${price}`);
        amount = new BigNumber(Math.floor(amount.div(`${this.amountPrecision}`)))
          .mul(`${this.amountPrecision}`)
          .toString();
      } else {
        amount = `${maxAmount}`;
      }
      return amount;
    },

    calculateAmountBuyOrderBookClicked(price, maxAmount, rawBalance) {
      if (!this.Islogin) {
        return maxAmount;
      }
      if (isNaN(rawBalance)) {
        return 0;
      }
      const amount = new BigNumber(`${maxAmount}`);
      if (amount.lte(new BigNumber(`${rawBalance}`))) {
        return amount.toString();
      }
      return rawBalance;
    },

    updateLimitBuyOrder(price, amount) {
      this.limitBuyOrder.price = price;
      this.limitBuyOrder.amount = amount || this.limitBuyOrder.amount;
    },

    updateLimitSellOrder(price, amount) {
      this.limitSellOrder.price = price;
      this.limitSellOrder.amount = amount || this.limitSellOrder.amount;
    },

    updateStopLimitBuyOrder(price, amount) {
      this.stopLimitBuyOrder.price = price;
      this.stopLimitBuyOrder.stop = price;
      this.stopLimitBuyOrder.amount = amount || this.stopLimitBuyOrder.amount;
    },

    updateStopLimitSellOrder(price, amount) {
      this.stopLimitSellOrder.price = price;
      this.stopLimitSellOrder.stop = price;
      this.stopLimitSellOrder.amount = amount || this.stopLimitSellOrder.amount;
    },

    updateStopMarketBuyOrder(price, amount) {
      this.stopMarketBuyOrder.stop = price;
      this.stopMarketBuyOrder.amount = amount || this.stopMarketBuyOrder.amount;
    },

    updateStopMarketSellOrder(price, amount) {
      this.stopMarketSellOrder.stop = price;
      this.stopMarketSellOrder.amount = amount || this.stopMarketSellOrder.amount;
    },

    getSocketEventHandlers() {
      return {
        BalanceUpdated: this.onBalanceUpdated,
        PricesUpdated: this.onPricesUpdated,
        CircuitBreakerSettingUpdated: this.onCircuitBreakerSettingUpdated,
        CircuitBreakerCoinPairSettingUpdated: this.onCircuitBreakerCoinPairSettingUpdated,
      };
    },
    onCircuitBreakerSettingUpdated(data) {
      if (!data || !data.data) {
        return false;
      }
      // this.getMasterData(true);
      this.circuitBreakerSetting = data.data;
      let oldStatusTrading = this.blockTrading;
      this.blockTrading = this.getBlockTradingStatus(this.currency, this.coin);
      if (this.blockTrading != oldStatusTrading) {
        if (this.blockTrading) {
          // this.showError(this.$i18n.t("circuit_breaker.blocking"));
          // return false;
          this.showBlocking(false);
        }
        if (!this.displayRemainSecondBlockTrading) {
          // this.showSuccess(this.$i18n.t("circuit_breaker.unblocking"));
          this.showUnblocking();
        }
      }
    },
    onCircuitBreakerCoinPairSettingUpdated(data) {
      if (!data || !data.data) {
        return false;
      }
      // Sample data:
      // block_time: "9.00000"
      // circuit_breaker_percent: "8.0000000000"
      // coin: "eth"
      // created_at: "2019-08-22 04:25:08"
      // currency: "btc"
      // id: 1
      // range_listen_time: "7.00000"
      // status: "disable"
      // updated_at: "2019-08-23 04:28:11"
      let eventData = data.data;
      // Merge Block Status
      this.mergerBlockStatus(eventData);
      if (this.currency != eventData.currency || this.coin != eventData.coin) {
        return false;
      }
      this.updateRemainSecondBlockTrading(eventData);

      let newBlockTradingStatus = !!eventData.block_trading;
      if (this.blockTrading == newBlockTradingStatus) {
        return true;
      }

      rf.getRequest('MasterdataRequest')
        .getAll(true)
        .then((res) => {
          this.masterData = res;
        })
        .catch((e) => {
          console.log(e);
        });

      if (eventData.status == 'disable') {
        this.blockTrading = false;
        // this.showSuccess(this.$i18n.t("circuit_breaker.unblocking"));
        // return false;
        this.showUnblocking(false);
      }

      this.blockTrading = newBlockTradingStatus;

      if (this.blockTrading) {
        // this.showError(this.$i18n.t("circuit_breaker.blocking"));
        // return true;
        this.showBlocking(true);
      }
      if (!this.displayRemainSecondBlockTrading) {
        // this.showSuccess(this.$i18n.t("circuit_breaker.unblocking"));
        this.showUnblocking();
      }
    },
    showBlocking: _.debounce(function (retVal = null) {
      this.showError(this.$i18n.t('circuit_breaker.blocking'));
      if (retVal != null) {
        return retVal;
      }
    }, 500),
    showUnblocking: _.debounce(function (retVal = null) {
      this.showSuccess(this.$i18n.t('circuit_breaker.unblocking'));
      if (retVal != null) {
        return retVal;
      }
    }, 500),
    mergerBlockStatus(eventData) {
      this.coinPairSetting = this.coinPairSetting.map((item) => {
        if (item.coin == eventData.coin && item.currency == eventData.currency) {
          return eventData;
        }
        return item;
      });
    },
    getBlockTradingStatus(currency, coin) {
      if (!this.circuitBreakerSetting) {
        return ALLOW_TRADING;
      }
      // If circuit breaker setting is DISABLE monitor trading
      // no need block trading
      if (this.circuitBreakerSetting.status == 'disable') {
        return ALLOW_TRADING;
      }
      let res = this.coinPairSetting.find((item) => {
        return item.coin == this.coin && item.currency == this.currency;
      });
      if (!res) return ALLOW_TRADING;
      if (res.status == 'disable') return ALLOW_TRADING;
      if (res.block_trading == 0) return ALLOW_TRADING; // enable trading

      this.updateRemainSecondBlockTrading(res);

      return BLOCK_TRADING;
    },
    convertSecondToMinute(secondsInput) {
      let seconds = secondsInput || 0;
      let minutes = parseInt(seconds / 60) || 0;
      let secondsInMinute = parseInt(seconds % 60) || 0;

      return minutes + ':' + (secondsInMinute < 10 ? '0' + secondsInMinute : secondsInMinute);
    },
    updateRemainSecondBlockTrading(coinPairSetting) {
      let now = moment();
      let nowAt = moment().format('x');
      let timezoneOffset = new Date(Math.floor(nowAt / 1000)).getTimezoneOffset(); // Example: Asia/Bangkok: -420

      if (!coinPairSetting.unlocked_at) {
        let _this = this;
        let remainDiffSeconds = this.displayRemainSecondBlockTrading;
        if (remainDiffSeconds) {
          this.waitCountDownTimeoutRemain = true;
          setTimeout(() => {
            _this.remainSecondBlockTrading = 0;
            _this.displayRemainSecondBlockTrading = 0;
            _this.waitCountDownTimeoutRemain = false;
          }, remainDiffSeconds * 1000);
        }
        return true;
      }

      let unlockedAtUTC = coinPairSetting.unlocked_at;
      let unlockedAtWithTimezone = unlockedAtUTC;
      let remain = unlockedAtWithTimezone - nowAt;
      remain = Math.floor(remain / 1000);
      this.remainSecondBlockTrading = remain;
    },
    onBalanceUpdated(newBalances) {
      if (newBalances && newBalances[Const.TYPE_EXCHANGE_BALANCE]) {
        newBalances = newBalances[Const.TYPE_EXCHANGE_BALANCE];
      }

      this.balances = Object.assign({}, this.balances, newBalances);
      if (window._.isEmpty(this.balances)) {
        return;
      }
      this.calculateCoinBalance();
    },
    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      if (window._.isEmpty(this.prices)) {
        return;
      }
      this.calculateLimitOrder();
    },
    calculateCoinBalance() {
      if (this.balances[this.currency]) {
        this.currencyBalance = this.formatCurrencyRoundDown(
          this.balances[this.currency].available_balance,
          this.currency,
          '0'
        );
      }
      if (this.balances[this.coin]) {
        this.coinBalance = this.formatCurrencyRoundDown(
          this.balances[this.coin].available_balance,
          this.coin,
          '0'
        );
      }
    },
    calculateLimitOrder(isChangedPair) {
      var priceObject = this.prices[this.currency + '_' + this.coin] || {};
      this.currentPrice = priceObject.price;
      if (priceObject.price) {
        const price = new BigNumber(`${priceObject.price}`);
        if (!this.limitBuyOrder.price || isChangedPair) {
          this.limitBuyOrder.price = price;
        }
        if (!this.limitSellOrder.price || isChangedPair) {
          this.limitSellOrder.price = price;
        }
      }
    },
    calculateOrderTotal(data) {
      // input amount focus
      const classes = document.activeElement.getAttribute('class');
      if (classes && classes.includes(this.classTotal)) {
        return;
      }
      if (data.price && data.amount) {
        const price = new BigNumber(`${data.price}`);
        const amount = new BigNumber(`${data.amount}`);
        data.total = price.mul(amount).toString();
      }
    },
    calculateOrderAmount(data) {
      // input amount focus
      const classes = document.activeElement.getAttribute('class');
      if (classes && classes.includes(this.classAmount)) {
        return;
      }
      if (data.price && data.total) {
        const price = this.newBigNumber(data.price);
        if (price.eq(0)) {
          // division by zero
          return;
        }
        data.amount = this.newBigNumber(data.total).div(price);
      }
      if (!data.total && !this.clickRow) {
        data.amount = '';
      }
    },
    emptyTotalOrder(order) {
      order.total = null;
    },
    calculateBuyMarketPrice(percent, type) {
      if (this.sellOrderBookDisplay.length > 1) {
        let foundRecord = this.sellOrderBookDisplay[this.sellOrderBookDisplay.length - 1];
        if (foundRecord && foundRecord.price) {
          return foundRecord.price;
        }
      }

      return this.currentPrice;
    },
    onBuyPercentClicked(percent, type) {
      if (!this.Islogin) {
        return;
      }
      this.changeSelected(percent, 'buy', type);
      this.percentBuy = percent;
      let price = undefined;
      switch (type) {
        case 'limit':
          price = this.limitBuyOrder.price;
          this.limitBuyOrder.percent = percent;
          break;
        case 'market':
          price = this.calculateBuyMarketPrice(percent, type);
          this.marketBuyOrder.percent = percent;
          break;
        case 'stop_market':
          price = this.currentPrice;
          this.stopMarketBuyOrder.percent = percent;
          break;
        case 'stop_limit':
          price = this.stopLimitBuyOrder.price;
          this.stopLimitBuyOrder.percent = percent;
          break;
      }
      let amount = undefined;
      if (price) {
        const balance = new BigNumber(`${this.currencyBalance || 0}`);
        amount = balance
          .div(new BigNumber(`${price}`))
          .mul(percent)
          .div(100);
        amount = new BigNumber(amount.div(this.amountPrecision)).mul(this.amountPrecision);
      }
      switch (type) {
        case 'limit':
          this.limitBuyOrder.amount = amount;
          break;
        case 'market':
          this.marketBuyOrder.amount = amount;
          break;
        case 'stop_limit':
          this.stopLimitBuyOrder.amount = amount;
          break;
        case 'stop_market':
          this.stopMarketBuyOrder.amount = amount;
          break;
      }
    },
    onSellPercentClicked(percent, type) {
      if (!this.Islogin) {
        return;
      }
      this.changeSelected(percent, 'sell', type);
      this.percentSell = percent;
      const balance = new BigNumber(`${this.coinBalance || 0}`);
      let amount = balance.mul(percent).div(100);
      amount = new BigNumber(amount.div(this.amountPrecision)).mul(this.amountPrecision);
      switch (type) {
        case 'limit':
          this.limitSellOrder.amount = amount;
          this.limitSellOrder.percent = percent;
          break;
        case 'market':
          this.marketSellOrder.amount = amount;
          this.marketSellOrder.percent = percent;
          break;
        case 'stop_limit':
          this.stopLimitSellOrder.amount = amount;
          this.stopLimitSellOrder.percent = percent;
          break;
        case 'stop_market':
          this.stopMarketSellOrder.amount = amount;
          this.stopMarketSellOrder.percent = percent;
          break;
      }
    },
    changeSelected(percent, type, tab) {
      this.removeSelected(type, tab);
      this.selectedPercent[tab]['p_' + percent][type] = true;
    },
    removeSelected(type, tab) {
      this.selectedPercent[tab]['p_25'][type] = false;
      this.selectedPercent[tab]['p_50'][type] = false;
      this.selectedPercent[tab]['p_75'][type] = false;
      this.selectedPercent[tab]['p_100'][type] = false;
    },

    createOrder(data) {
      data.currency = this.currency;
      data.coin = this.coin;
      let balance = this.currencyBalance;
      if (data.trade_type === 'sell') {
        balance = this.coinBalance;
      }

      data.balance = balance;
      data.tax = this.params.tax;
      data.cfx = this.params.cfx;
      data.bappebti = this.params.bappebti;
      data.is_update_volume = this.params.is_update_volume;

      try {
        OrderUtils.validateOrderInput(data, this.coinSetting);
      } catch (error) {
        this.showError(this.$i18n.t(error.message, { value: error.value }));
        this.isSubmitting = false;
        return;
      }

      this.sendOrderRequest(data);
    },

    onChangePriceInput(newValue, order) {
      if (window._.isEmpty(newValue)) {
        this.emptyTotalOrder(order);
        return;
      }
      this.calculateOrderTotal(order);
    },

    onChangeAmountInput(newValue, oldValue, order) {
      if (window._.isEmpty(newValue)) {
        this.emptyTotalOrder(order);
        return;
      }
      const newAmount = this.toValue(newValue, this.amountPrecision);
      const oldAmount = this.toValue(oldValue, this.amountPrecision);
      if (newAmount === oldAmount) {
        return;
      }
      this.calculateOrderTotal(order);
    },

    async onChangeTotalInput(newValue, oldValue, order) {
      const newTotal = this.toValue(newValue, this.pricePrecision);
      const oldTotal = this.toValue(oldValue, this.pricePrecision);
      if (newTotal === oldTotal) {
        return;
      }
      await this.calculateOrderAmount(order);
      this.clickRow = false;
    },

    newBigNumber(value) {
      if (value instanceof BigNumber) {
        return value;
      }
      return new BigNumber(`${value || 0}`);
    },

    toValue(value, precision) {
      const decimalPart = this.calculateDecimalPart(precision);

      let result = '';
      if (value instanceof BigNumber) {
        result = value.toString();
      } else {
        result = `${value || ''}`;
      }

      const dotIndex = result.indexOf('.');
      if (dotIndex === -1) {
        return result;
      }
      if (decimalPart > 0) {
        return result.slice(0, dotIndex + decimalPart + 1);
      }
      return result.slice(0, dotIndex);
    },

    /*
        Get number decimal part.
        Use Math.log(x) which is equivalent to ln(x) in mathematics
        Ex: precision = 0.0016
        '1/precision' = 625 = 5 x 5 x 5 x 5
          => Math.log(625)/Math.log(5) = 'stop_market'.
      */
    calculateDecimalPart(precision) {
      return Math.round(Math.log(1 / precision) / Math.log(10));
    },

    actionBuyOrderClicked() {
      this.isSubmitting = true;
      if (this.tab === 'limit') {
        this.onCreateLimitBuyOrderClicked();
      } else if (this.tab === 'market') {
        this.onCreateMarketBuyOrderClicked();
      } else if (this.tab === 'stop_limit') {
        this.onCreateStopLimitBuyOrderClicked();
      } else if (this.tab === 'stop_market') {
        this.onCreateStopMarketBuyOrderClicked();
      }
      this.$emit('refreshOderGroup');
    },

    actionSellOrderClicked() {
      this.isSubmitting = true;
      if (this.tab === 'limit') {
        this.onCreateLimitSellOrderClicked();
      } else if (this.tab === 'market') {
        this.onCreateMarketSellOrderClicked();
      } else if (this.tab === 'stop_limit') {
        this.onCreateStopLimitSellOrderClicked();
      } else if (this.tab === 'stop_market') {
        this.onCreateStopMarketSellOrderClicked();
      }
      this.$emit('refreshOderGroup');
    },

    onCreateLimitBuyOrderClicked() {
      let data = {
        trade_type: 'buy',
        type: 'limit',
        quantity: this.limitBuyOrder.amount,
        price: this.limitBuyOrder.price,
        total: this.limitBuyOrder.total,
      };
      this.createOrder(data);
    },

    onCreateLimitSellOrderClicked() {
      let data = {
        trade_type: 'sell',
        type: 'limit',
        quantity: this.limitSellOrder.amount,
        price: this.limitSellOrder.price,
        total: this.limitSellOrder.total,
      };
      this.createOrder(data);
    },

    onCreateMarketBuyOrderClicked() {
      let data = {
        trade_type: 'buy',
        type: 'market',
        quantity: this.marketBuyOrder.amount,
      };
      this.createOrder(data);
    },

    onCreateMarketSellOrderClicked() {
      let data = {
        trade_type: 'sell',
        type: 'market',
        quantity: this.marketSellOrder.amount,
      };
      this.createOrder(data);
    },

    onCreateStopLimitBuyOrderClicked() {
      const stopCondition = this.getStopCondition(this.stopLimitBuyOrder.stop);
      let data = {
        trade_type: 'buy',
        type: 'stop_limit',
        quantity: this.stopLimitBuyOrder.amount,
        price: this.stopLimitBuyOrder.price,
        base_price: this.stopLimitBuyOrder.stop,
        stop_condition: stopCondition,
        total: this.stopLimitBuyOrder.total,
      };
      this.createOrder(data);
    },

    onCreateStopLimitSellOrderClicked() {
      const stopCondition = this.getStopCondition(this.stopLimitSellOrder.stop);
      let data = {
        trade_type: 'sell',
        type: 'stop_limit',
        quantity: this.stopLimitSellOrder.amount,
        price: this.stopLimitSellOrder.price,
        base_price: this.stopLimitSellOrder.stop,
        stop_condition: stopCondition,
        total: this.stopLimitSellOrder.total,
      };
      this.createOrder(data);
    },

    onCreateStopMarketBuyOrderClicked() {
      const stopCondition = this.getStopCondition(this.stopMarketBuyOrder.stop);
      let data = {
        trade_type: 'buy',
        type: 'stop_market',
        quantity: this.stopMarketBuyOrder.amount,
        base_price: this.stopMarketBuyOrder.stop,
        stop_condition: stopCondition,
      };
      this.createOrder(data);
    },

    onCreateStopMarketSellOrderClicked() {
      const stopCondition = this.getStopCondition(this.stopMarketSellOrder.stop);
      let data = {
        trade_type: 'sell',
        type: 'stop_market',
        quantity: this.stopMarketSellOrder.amount,
        base_price: this.stopMarketSellOrder.stop,
        stop_condition: stopCondition,
      };
      this.createOrder(data);
    },

    onCurrencyPairChanged() {
      this.calculateLimitOrder(true);
      this.calculateCoinBalance();

      this.getCoinSetting();
    },

    getStopCondition(basePrice) {
      if (basePrice) {
        basePrice = new BigNumber(`${basePrice}`);
        if (basePrice.gte(new BigNumber(`${this.currentPrice}`))) {
          return 'ge';
        } else {
          return 'le';
        }
      }
    },

    sendOrderRequest(data) {
      rf.getRequest('OrderRequest')
        .createANewOne(data)
        .then((res) => {
          if (res.success) {
            const tradeType =
              res.data.trade_type === 'sell'
                ? this.$i18n.t('order.order_form.sell')
                : this.$i18n.t('order.order_form.buy');
            this.showSuccess(
              this.$i18n.t('order.order_form.success', {
                trade_type: tradeType.toLowerCase(),
              })
            );
          }
          this.cleartOrderForm(data);
          this.isSubmitting = false;
        })
        .catch((error) => {
          if (!error.response) {
            this.showError(this.$i18n.t('common.message.network_error'));
          } else {
            this.showError(this.$i18n.t(error.response.data.message));
          }
          this.isSubmitting = false;
        });
    },

    cleartOrderForm(data) {
      if (data.type == 'limit' && data.trade_type == 'buy') {
        this.clearLimitBuyOrderForm();
      } else if (data.type == 'limit' && data.trade_type == 'sell') {
        this.clearLimitSellOrderForm();
      } else if (data.type == 'market' && data.trade_type == 'buy') {
        this.clearMarketBuyOrderForm();
      } else if (data.type == 'market' && data.trade_type == 'sell') {
        this.clearMarketSellOrderForm();
      } else if (data.type == 'stop_limit' && data.trade_type == 'buy') {
        this.clearStopLimitBuyOrderForm();
      } else if (data.type == 'stop_limit' && data.trade_type == 'sell') {
        this.clearStopLimitSellOrderForm();
      } else if (data.type == 'stop_market' && data.trade_type == 'buy') {
        this.clearStopMarketBuyOrderForm();
      } else if (data.type == 'stop_market' && data.trade_type == 'sell') {
        this.clearStopMarketSellOrderForm();
      }
    },

    clearLimitBuyOrderForm() {
      this.limitBuyOrder.amount = undefined;
      this.limitBuyOrder.total = undefined;
    },

    clearLimitSellOrderForm() {
      this.limitSellOrder.amount = undefined;
      this.limitSellOrder.total = undefined;
    },

    clearMarketBuyOrderForm() {
      this.marketBuyOrder.amount = undefined;
    },

    clearMarketSellOrderForm() {
      this.marketSellOrder.amount = undefined;
    },

    clearStopLimitBuyOrderForm() {
      this.stopLimitBuyOrder.amount = undefined;
      this.stopLimitBuyOrder.total = undefined;
    },

    clearStopLimitSellOrderForm() {
      this.stopLimitSellOrder.amount = undefined;
      this.stopLimitSellOrder.total = undefined;
    },

    clearStopMarketBuyOrderForm() {
      this.stopMarketBuyOrder.amount = undefined;
    },

    clearStopMarketSellOrderForm() {
      this.stopMarketSellOrder.amount = undefined;
    },

    showError(message) {
      Message.error(message, {}, { position: 'top_right' });
    },

    showSuccess(message) {
      Message.success(message, {}, { position: 'top_right' });
    },

    toUpperCase(value) {
      return Utils.getCurrencyName(value);
    },

    formatCurrencyRoundDown(amount, currency, value) {
      if (amount == undefined) {
        return 0;
      }

      let numberOfDecimalDigits =
        currency && currency.toLowerCase() === 'idr'
          ? Const.NUMBER_OF_DECIMAL_DIGITS_FIAT
          : Const.NUMBER_OF_DECIMAL_DIGITS; //currency === 'idr' ? 2 : 8;

      let newValue = new BigNumber(amount);

      return newValue.round(numberOfDecimalDigits, BigNumber.ROUND_FLOOR).toString();
    },

    getMasterData(immediate = false) {
      return rf
        .getRequest('MasterdataRequest')
        .getAll(immediate)
        .then((res) => {
          if (res.circuit_breaker_settings && res.circuit_breaker_settings.length > 0) {
            this.circuitBreakerSetting = res.circuit_breaker_settings[0];
          }
          this.coinPairSetting = res.circuit_breaker_coin_pair_settings;
          this.masterData = res;
          this.blockTrading = this.getBlockTradingStatus(this.currency, this.coin);
        });
    },

    getFeeMakerTaker() {
      rf.getRequest('PriceRequest')
        .getFeeMakerTaker({ currency: this.currency })
        .then((res) => {
          this.feeMaker = res.maker;
          this.feeTaker = res.taker;
        });
    },

    initData() {
      this.getUser();
      this.getQuantityPrecision();
      this.getMasterData();
      this.getFeeMakerTaker();

      if (window.isAuthenticated) {
        rf.getRequest('UserRequest')
          .getBalance(true, Const.TYPE_EXCHANGE_BALANCE)
          .then((res) => {
            this.onBalanceUpdated(res.data);
          });
      }
    },

    getQuantityPrecision() {
      let self = this;
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((res) => {
          self.masterData = res;
          this.getCoinSetting();
        });
    },

    getCoinSetting() {
      if (!this.masterData) {
        return;
      }
      const setting = window._.find(this.masterData.coin_settings, (setting) => {
        return setting.currency == this.currency && setting.coin == this.coin;
      });
      this.coinSetting = setting;
    },
  },
  mounted() {
    this.coin = this.$route.query.coin || Const.DEFAULT_COIN;
    this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.icon-wallet {
  padding-left: 2px;
}
.head_form_order {
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 16px;
  width: fit-content;
  border-radius: 30px;
  background: $grey-3;
  @include tablet {
    margin-left: auto;
    margin-right: auto;
  }
  @include mobile {
    margin-left: auto;
    margin-right: auto;
  }

  ul {
    margin: unset;
    li {
      display: block;
      float: left;
      color: $dark-3;
      font-size: 14px;
      padding: 7px 24px;
      line-height: 24px;
      font-weight: 700;
      height: auto;
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      &.tab-buy {
        border-radius: 60px;
        &.active {
          background-color: $bg-main1;
          color: $color-white;
        }
      }
      &.tab-sell {
        border-radius: 60px;
        &.active {
          background-color: $bg-main1;
          color: $color-white;
        }
      }
      @include mobile {
        padding: 7px 20px;
      }

      @include tablet {
        padding: 7px 20px;
      }
    }
  }
}
.content_form_order {
  .right_from_mn {
    display: block;
    overflow: hidden;
    float: none;
    width: 100%;
    color: $text-main;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 12px;
    img {
      width: 16px;
      height: 16px;
      margin-left: 0;
      margin-right: 5px;
      margin-bottom: 3px;
    }
  }
  :deep .coin_input {
    input,
    span {
      line-height: 22px;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      color: $text-main;
    }
    & input::placeholder {
      color: $text-main-blur;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
    }
    .icon-arrow1,
    .icon-arrow2 {
      font-size: 11px;
    }
  }
}
.input_right_group {
  margin-top: 4px;
  margin-bottom: 4px;
  width: 100%;
  &.no-select {
    user-select: none;
  }
  &.percent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 8px;
  }
  .check_percent {
    background-color: $color-light-grey;
    color: rgba(2, 0, 13, 0.38);
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding: 7px 8px;
    cursor: pointer;
    border-radius: 60px;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    &.selected {
      background: $bg-main1;
      color: $color-white;
    }
    @include mobile {
      font-size: 12px;
      line-height: 16px;
    }
    &:focus-visible {
      outline: 1px solid $bg-main1;
    }
  }
  .button-danger {
    width: 100%;
    height: 38px;
    border-radius: 60px;
    background-color: $bg-main6;
    color: $color-white;
    text-align: center;
    padding: 7px 24px;
    font-size: 14px;
    line-height: 24px;
    margin-top: 25px;
    font-weight: 700;

    @include mobile {
      margin-top: 4px;
    }

    @include tablet {
      margin-top: 4px;
    }
  }
  .button-success {
    width: 100%;
    height: 38px;
    border-radius: 60px;
    background-color: $bg-main6;
    color: $color-white;
    text-align: center;
    padding: 7px 24px;
    font-size: 14px;
    line-height: 24px;
    margin-top: 25px;
    font-weight: 700;

    @include mobile {
      margin-top: 4px;
      // background-color: $primary-2;
      // &:hover,
      // &:active {
      //   background-color: $primary-1;
      // }
    }

    @include tablet {
      margin-top: 4px;
      // background-color: $primary-2;
      // &:hover,
      // &:active {
      //   background-color: $primary-1;
      // }
    }
  }
}
.group_input_form {
  display: block;
  width: 100%;
  float: left;
}
.textAuthen {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  color: $text-main-blur;
  font-weight: 300;
  margin-top: 90px;
  a {
    font-weight: 700;
    color: $text-main;
    line-height: 24px;
  }
  @include tablet-d {
    margin-top: 98px;
    margin-bottom: 115px;
  }
}

.fee-maker-taker-container {
  margin-top: 10px;

  .fee-maker-taker {
    color: $text-main;
    font-size: 12px;
    font-weight: 300;
    line-height: 22px;
    height: 38px;
  }
}

.column {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 15px;
  flex: 1;
  max-width: 300px;
}

.action-group {
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.toggle-switch {
  cursor: pointer;
  width: 48px;
  height: 28px;
  background: $dark-3;
  border-radius: 14px;

  .toggle-handle {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: $color-white;
    border-radius: 50%;
    margin: 4px;
    transition: all 0.3s ease;
  }

  &.off {
    background: $dark-3;
    text-align: left;
  }

  &.on {
    background: $primary-2;
    text-align: right;
  }
}
</style>
