<template>
  <div class="install">
    <div class="content-box first">
      <div class="title-step">{{ $t('account.otp.install_title') }}</div>
      <p class="sub-title-step">{{ $t('account.otp.install_text') }}</p>
      <p class="sub-title-step2">{{ $t('account.otp.sub-install_text') }}</p>
      <div class="applink">
        <a class="iosapp" target="_blank" href="https://itunes.apple.com/us/app/google-authenticator/id388497605"><img
            src="@/assets/images/appstore.svg" alt="" /></a>
        <a class="androidapp" target="_blank"
          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"><img
            src="@/assets/images/googleplay.svg" alt="" /></a>
      </div>
    </div>
    <!-- end first -->
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped></style>
