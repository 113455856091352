import BaseRequest from './base/BaseRequest'
import Utils from '@/common/Utils'

export default class PriceRequest extends BaseRequest {
  getPrices() {
    if (window.GlobalSocket) {
      if (window.GlobalSocket.prices) {
        return new Promise((resolve, reject) => {
          resolve(window.GlobalSocket.prices)
        })
      }
    }

    return this.getPricesNow()
  }
  getPrices() {
    let url = '/prices'
    return this.get(url)
  }

  getIDRConversionPrice(coin) {
    let url = `/price?currency=idr&coin=${coin}`
    return this.get(url)
  }

  getPricesNow() {
    let url = '/prices'
    this.get(url).then((res) => {
      window.GlobalSocket.notifyPricesUpdate(res)
    })

    return new Promise((resolve, reject) => {
      resolve({ data: {} })
    })
  }

  getChartBars(params) {
    let url = '/chart/bars'
    return this.get(url, params)
  }

  getPriceScope(currency) {
    let url = '/price-scope'
    return this.get(url, currency)
  }

  getPrices24h(params) {
    let url = '/prices/24h'
    return this.get(url, params)
  }
  getPrice24hChart(params) {
    let url = '/prices/24h-with-chart'
    return this.get(url, params)
  }

  getMarketInfo(params) {
    let url = '/market-info'
    return this.get(url, params)
  }

  getAmlSettings(params) {
    let url = '/aml-settings'
    return this.get(url, params)
  }

  getBonus(params) {
    let url = '/get-my-bonus'
    return this.get(url, params)
  }

  getMarketPrices() {
    const url = '/coin-for-sale-point'
    return this.get(url)
  }

  getFeeMakerTaker(params) {
    const url = '/fee-maker-taker'
    return this.get(url, params)
  }
}
