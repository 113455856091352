<template>
  <div class="sc_search_select" v-click-outside="turnOffSelect">
    <div class="group_search_select clearfix">
      <div
        class="button_drop_search"
        :class="[{ active: isShow }, classFocused]"
        @click="toggleSelect()"
      >
        <slot
          name="label_selected"
          v-if="hasCustomLabel"
          :selected="value"
        ></slot>
        <!-- <span v-else v-html="$t(label)" /> -->
        <span v-else :class="{ isPlaceholder: label === placeholder }">
          <img
            v-if="isShowImageChooseLanguage"
            :src="optionSelected.flag"
            alt="image"
          />
          {{ label }}
        </span>
        <img src="/images/icon/CaretDown.svg" alt="" />
      </div>
      <div
        class="select-menu-overlay"
        v-if="isMenu"
        :class="{ active: isShow }"
        @click="onClickMenuOverlay"
      >
        <div class="box_list_search_select active" @click.stop>
          <div class="close-btn" @click="isShow = false" v-if="!isHideCloseBtn">
            <img src="/images/close-circle.svg" alt="" />
          </div>
          <div v-if="isSearchCountry" class="search-country">
            <input
              type="text"
              v-model.trim="searchCountry"
              :placeholder="$t('common.search')"
            />
          </div>
          <ul class="list_search_select">
            <li
              v-for="(option, index) in avaiableOptions"
              :class="{
                'selected-li': value === option[propertyOption],
                'note-li': option.id === 'maximum_bank',
                selected: index === indexSelected,
              }"
              :key="index"
              @click="select(option, index)"
            >
              <slot
                v-if="hasCustomLabel"
                name="options"
                :option="option"
              ></slot>
              <span
                v-else-if="option.id === 'maximum_bank'"
                v-html="
                  $t('bank_account_management.maximum_bank_note', {
                    limit: limitBankAccount,
                  })
                "
              ></span>
              <span
                v-else
                class="full_name_coin_select"
                :class="{ 'action-li': option.id === 'add_new_bank' }"
              >
                <img
                  v-if="option.id === 'add_new_bank'"
                  src="/images/wallet/fi_plus-circle.svg"
                  alt=""
                  style="margin-right: 8px; transform: translateY(-1px)"
                />
                {{
                  $t(
                    option.name
                      ? option.name
                      : `${option[propertyOption]
                          .charAt(0)
                          .toUpperCase()}${option[propertyOption].slice(1)}`
                  )
                }}
                <template v-if="isSelectPhone"> +{{ option.value }} </template>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="box_list_search_select active" v-else v-show="isShow">
        <div v-if="isSearchCountry" class="search-country">
          <input
            type="text"
            v-model.trim="searchCountry"
            :placeholder="$t('common.search')"
          />
        </div>
        <ul class="list_search_select">
          <li
            v-for="(option, index) in avaiableOptions"
            :class="{
              'selected-li': value === option[propertyOption],
              'note-li': option.id === 'maximum_bank',
            }"
            :key="index"
            :title="titleItem ? option[titleItem] : ''"
            @click="select(option, index)"
          >
            <slot v-if="hasCustomLabel" name="options" :option="option"></slot>
            <span
              v-else-if="option.id === 'maximum_bank'"
              v-html="
                $t('bank_account_management.maximum_bank_note', {
                  limit: limitBankAccount,
                })
              "
            ></span>
            <span
              v-else
              class="full_name_coin_select"
              :class="{ 'action-li': option.id === 'add_new_bank' }"
            >
              <img
                v-if="option.id === 'add_new_bank'"
                src="/images/wallet/fi_plus-circle.svg"
                alt=""
                style="margin-right: 8px; transform: translateY(-1px)"
              />
              {{
                $t(
                  option.name
                    ? option.name
                    : `${option[propertyOption]
                        .charAt(0)
                        .toUpperCase()}${option[propertyOption].slice(1)}`
                )
              }}
              <template v-if="isSelectPhone"> +{{ option.value }} </template>
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isHideCloseBtn: { type: Boolean, default: false },
    isMenu: Boolean,
    placeholder: {
      type: String,
    },
    hasCustomLabel: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    isReturnObject: {
      type: Boolean,
      default: false,
    },
    value: { default: "" },
    classFocused: { type: [String, Object], default: "" },
    propertyOption: "",
    usingIdProps: false,
    isSelectPhone: {
      type: Boolean,
      default: false,
    },
    isSearchCountry: {
      type: Boolean,
      default: false,
    },
    titleItem: { default: false },
    isShowImageChooseLanguage: { default: false },
  },
  data() {
    return {
      avaiableOptions: this.options,
      isShow: false,
      indexSelected: 0,
      searchCountry: "",
      limitBankAccount: 10,
      optionSelected: {},
    };
  },
  computed: {
    label() {
      const selected = this.options.find((item) => {
        if (window._.isObject(this.value)) {
          return item === this.value;
        }
        return (
          item[
            this.propertyOption && !this.usingIdProps
              ? this.propertyOption
              : "id"
          ] === this.value
        );
      });
      this.optionSelected = selected || this.options[0];
      if (selected) {
        return this.propertyOption && this.usingIdProps
          ? selected[this.propertyOption]
          : this.isSelectPhone
          ? `+${selected.value}`
          : selected.name;
      }
      return this.placeholder;
    },
  },
  watch: {
    options() {
      this.avaiableOptions = this.options;
    },
    searchCountry(newValue) {
      this.avaiableOptions = this.options.filter(
        (item) =>
          item.name.toLowerCase().includes(newValue.toLowerCase()) ||
          `+${item.value}`.toLowerCase().includes(newValue.toLowerCase())
      );
    },
  },
  methods: {
    onClickMenuOverlay() {
      if (this.isHideCloseBtn) this.turnOffSelect();
    },
    toggleSelect() {
      if (this.value == "Indonesia") return
      this.isShow = !this.isShow;
    },
    turnOffSelect() {
      this.isShow = false;
    },
    select(option, index) {
      if (this.isReturnObject) {
        this.$emit("input", option);
      } else {
        this.$emit(
          "input",
          this.propertyOption && !this.usingIdProps
            ? option[this.propertyOption]
            : option.id
        );
      }
      this.indexSelected = index;
      this.turnOffSelect();
    },
    includes(str, query) {
      /* istanbul ignore else */
      if (str === undefined) str = "undefined";
      if (str === null) str = "null";
      if (str === false) str = "false";
      const text = str.toString().toLowerCase();
      const value = query.trim().toString().toLowerCase();
      return text.includes(value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/common.scss";

.select-menu-overlay {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9998;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.select-menu-overlay.active {
  display: flex;

  .box_list_search_select.active {
    padding: 0;
    top: unset;
    margin-left: 16px;
    width: calc(100% - 32px);

    .close-btn {
      z-index: 1;
      position: absolute;
      top: 12px;
      right: 12px;
    }

    .list_search_select {
      padding: 24px 0;
      max-height: unset;

      li {
        padding: 12px 24px;

        &.selected {
          background-color: $bg-main2;

          & span {
            color: $text-main !important;
          }
        }

        span {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          font-family: $helvetica;
          color: $text-secondary !important;
        }
      }
    }
  }
}

.sc_search_select {
  .tit_search_select {
    font-size: $font-title-size-big;
    color: $color-white;
    margin-bottom: 20px;
    line-height: 44px;
  }

  .group_search_select {
    position: relative;

    .button_drop_search {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 16px;
      background: $text-main1;
      border-radius: 10px;
      cursor: pointer;
      width: 100%;

      span {
        color: rgba($text-main2, 0.87);
        font-size: 18px;
        font-weight: 400;
        line-height: 32px;
        white-space: nowrap;

        @include mobile {
          font-size: 16px;
          line-height: 24px;
        }

        &.isPlaceholder {
          color: $text-main-blur;
        }
      }

      i.icon-arrow1 {
        color: $text-main5;
        line-height: 20px;
        font-size: 9px;
      }

      img {
        transition: all 0.2s ease;
        width: 24px;
        height: 24px;
        border-radius: 50%;
      }

      &.active {
        > img {
          transform: rotate(180deg);
        }
      }
    }

    .box_list_search_select {
      position: absolute;
      background: $color-white;
      display: none;
      top: calc(100% + 2px);
      width: auto;
      left: 0px;
      z-index: 111;
      padding: 0;
      float: left;
      visibility: hidden;
      opacity: 0;
      background: $color-white;
      box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
      border-radius: 10px;
      padding: 10px 0px;
      overflow-y: auto;
      z-index: 20;

      ul {
        margin: 0;
      }
    }

    .box_list_search_select.active {
      display: block;
      visibility: visible;
      opacity: 1;
    }

    .box_input_s {
      display: block;
      width: 100%;
      padding: 0px 30px;

      .input_search_select {
        width: 100%;
        border: 1px solid $color-grey-sliver;
        color: $color-grey-dark;
        border-radius: 25px;
        height: 40px;
        outline: -webkit-focus-ring-color auto 0px;
        outline-offset: 0px;
        font-size: $font-small;
        padding: 0px 30px;
      }
    }

    .list_search_select {
      background: $color-white;
      list-style-type: none;
      overflow-y: auto;
      position: relative;
      margin: 10px 0;
      max-height: 225px;
      width: 100% !important;

      &:after {
        bottom: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        display: block;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: $color-white;
        border-width: 5px;
        margin-left: -5px;
      }

      .selected-li {
        background-color: $grey-3 !important;
      }

      li {
        display: block;
        width: 100%;
        cursor: pointer;
        padding: 7px 14px;

        .full_name_coin_select {
          color: $text-main !important;
          font-weight: 400;
          font-size: 16px;
          line-height: 32px;

          &.action-li {
            color: $text-main !important;
            font-weight: 700;
          }
        }

        overflow: hidden;

        &:hover {
          background-color: $grey-3 !important;
        }
      }

      .note-li {
        color: #667186;
        cursor: default;

        a {
          color: $primary-0 !important;
        }

        &:hover {
          background-color: white !important;
        }
      }
    }
  }
}

.search-country {
  width: 100%;
  padding: 7px 14px;
  border-bottom: 1px solid #ccc;

  input {
    border: none;
    width: 100%;
  }
}
</style>
<style lang="scss">
.bold {
  font-weight: 700 !important;
}

.icon_coin_slect {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 22px;

  img {
    width: 100%;
    height: 100%;
  }
}

.code_coin_slect {
  // display: inline-block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 700;
  color: rgba(2, 0, 13, 0.87);
  // margin-right: 8px;
}

.full_name_coin_select {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  float: left;
  line-height: 20px;
  font-size: $font-root;
  color: $color-grey-dark;
}

@include mobile {
  i.icon-arrow1 {
    margin-left: 0 !important;
  }
}
</style>
