<template>
  <div class="license-page">
    <div class="license-con">
      <div class="license-tit">
        <p>{{ $t("license.legal") }}</p>
      </div>
      <div class="license-page-center">
        <div class="module-container">
          <div class="module">
            <!-- <div class="tit">{{ $t('license.license_tit') }}</div> -->
            <div class="subtit" v-html="$t('license.license_subtit')"></div>
          </div>
          <div class="module">
            <!-- <div class="tit">{{ $t('license.certificate_tit') }}</div> -->
            <div class="card-group">
              <!-- <a class="card" href="/images/fincen/fincen.pdf" download="fincen.pdf"> -->
              <a class="card" href="/images/fincen/fincen.pdf" target="_blank">
                <div class="icon">
                  <!-- <img src="/images/fincen/fincen.svg" /> -->
                  <img src="/images/fincen/license-logo.png" />
                </div>
                <!-- <div class="license-icon-desc">{{$t('license.fincen')}}</div> -->
                <!-- <div class="license-icon-desc">FinCEN</div> -->
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {},
  mounted() {
    document.title = this.$t("license.license_footer") + ` – ${this.tabTitle}`;
  },
};
</script>

<style lang="scss" scoped>
.license-page {
  .license-con {
    .license-tit {
      padding: 90px 24px;
      @include text-font($helvetica, $color-white, 48px, 700, 64px);
      text-align: start;
      background-image: url("@/assets/images/about-us.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      p {
        margin: unset;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .license-page-center {
      margin-inline: auto;
      padding: 24px 24px 80px;
    }

    .module-container {
      max-width: 1280px;
      display: flex;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
      gap: 24px;
    }

    .module {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .tit {
        @include text-font(
          $helvetica,
          $primary-2,
          $font-medium-bigger,
          700,
          25px
        );
      }

      .subtit {
        @include text-font(
          $helvetica,
          $text-secondary,
          $font-medium-bigger,
          400,
          24px
        );

        :deep() a:hover {
          text-decoration: underline;
          color: $primary-1;
        }
      }
    }

    .card-group {
      display: flex;

      .card {
        display: flex;
        align-items: center;
        gap: 15px;

        .icon {
          width: 220px;
          height: auto;
          // truongvx - 28/4/2023
          justify-content: center;
          display: flex;
          flex-direction: column;

          // ---------------------
          &:hover + .license-icon-desc {
            color: $primary-1;
          }

          img {
            max-width: 100%;
            object-fit: cover;
          }
        }

        .license-icon-desc {
          @include text-font(
            $helvetica,
            $primary-2,
            $font-medium-bigger,
            700,
            25px
          );
          text-decoration-line: underline;

          &:hover {
            color: $primary-1;
          }
        }
      }
    }
  }

  @include mobile {
    .license-con {
      .license-tit {
        font-size: 48px;
        font-weight: 700;
        line-height: 64px;
      }

      .license-page-center {
        padding: 23px 25px 21px;
      }

      .module-container {
        gap: 10px;
        padding: 14px 15px;

        .module {
          .tit {
            line-height: 20px;
          }

          .subtit {
            font-size: $font-root;
            line-height: 25px;
          }

          .card-group {
            .license-icon-desc {
              font-size: 14px;
              line-height: 18px;
              text-decoration: unset;
            }
          }
        }
      }
    }
  }
}

@include tablet;

@include mobile {
  .license-page {
    .license-con {
      .license-tit {
        padding: 34px 20px;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      .license-page-center {
        padding: 24px 16px 60px;

        .module-container {
          padding: 0;
          gap: 16px;

          & .module .subtit {
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>
