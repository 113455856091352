<template>
  <PopupModal :isShow="isShow">
    <template slot="body">
      <div id="delete-credit-modal">
        <div class="img">
          <img
            class="icon-nodata"
            src="/images/question-mark-2.png"
            alt="no_fund_icon"
          />
        </div>
        <div class="delete-credit__title">
          {{ $t("deposit_fiat_modal.confirm_remove_card") }}
        </div>
        <div class="delete-credit__btn-group">
          <div
            class="btn-group__cancel--btn delete-credit__btn"
            @click="$emit('close')"
          >
            {{ $t("common.action.no") }}
          </div>
          <div
            class="btn-group__confirm--btn delete-credit__btn"
            @click="$emit('ok')"
          >
            {{ $t("common.action.yes") }}
          </div>
        </div>
      </div>
    </template>
  </PopupModal>
</template>

<script>
import PopupModal from "@/components/PopupModal.vue";

export default {
  name: "DeleteCreditCardModal",
  components: { PopupModal },
  props: {
    isShow: Boolean,
  },
};
</script>

<style lang="scss" scoped>
#delete-credit-modal {
  max-width: 348px;
  height: auto;
  margin: 0 auto;
  background: white;
  overflow: hidden;
  border-radius: 16px;
  padding: 24px;
  @include mobile {
    width: calc(100vw - 30px);
    border-radius: 10px;
  }
  .img {
    text-align: center;
    margin-bottom: 32px;
    @include mobile {
      margin-bottom: 24px;
    }
    img {
      height: 137px;
      width: 137px;
      @include mobile {
        width: 100px;
        height: 100px;
      }
    }
  }
  .delete-credit__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: $text-main;
  }
  .delete-credit__btn-group {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 0 8px;
    margin-top: 24px;
  }
  .delete-credit__btn {
    padding: 9px 32px;
    border-radius: 60px;
    width: 150px;
    text-align: center;
    border: none;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
    &.btn-group__cancel--btn {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $text-main;
      background: #f2f2f2;
    }

    &.btn-group__confirm--btn {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: white;
      background: $bg-main1;
    }
  }
}
</style>
