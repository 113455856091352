<template>
  <div class="box-table">
    <div id="container-tab">
      <template v-if="isMobile">
        <div class="market__mobile-wrapper">
          <div class="dropdown style-focus" tabindex="0">
            <button class="btn form-control btn-market" type="button" data-toggle="dropdown">
              <div class="left market-type-selected">
                <template v-if="selectedMarket === tabFavorites">
                  <img src="/images/spot_exchange/star.svg" alt="" />
                  <div>
                    {{ $t('landing.favorites') }}
                  </div>
                </template>
              </div>
              <div class="left market-type-selected" v-for="market in markets" :key="market?.id">
                <template v-if="selectedMarket === market">
                  {{ toUpperCase(market) }}
                </template>
              </div>
              <span class="icon icon-arrow1"></span>
            </button>
            <ul class="dropdown-menu">
              <div @click="showMarket(tabFavorites)" class="tab-element">
                <img src="/images/spot_exchange/star.svg" alt="" />
                <span class="text-size">{{ $t('landing.favorites') }}</span>
              </div>
              <div v-for="market in markets" @click="showMarket(market)" class="tab-element">
                <span class="text-size">{{ toUpperCase(market) }}</span>
              </div>
            </ul>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="tab-select">
          <label
            for="tab-favirotes"
            :class="selectedMarket === tabFavorites ? 'current-market' : ''"
            v-on:click="showMarket(tabFavorites)"
          >
            <!-- <span
              class="icon-star2"
              :class="selectedMarket === tabFavorites ? 'star_color' : ''"
            ></span> -->
            <img
              src="/images/spot_exchange/star.svg"
              alt=""
              :class="selectedMarket === tabFavorites ? 'star_color' : ''"
            />
            <div>
              {{ $t('landing.favorites') }}
            </div>
          </label>
          <template v-for="market in markets">
            <label
              :for="`tab-${market}`"
              :key="market"
              :class="selectedMarket === market ? 'current-market' : ''"
              @click="showMarket(market)"
              v-if="shortNameTab"
              >{{ market | uppercase }}</label
            >
            <label
              :for="`tab-${market}`"
              :key="market"
              :class="selectedMarket === market ? 'current-market' : ''"
              @click="showMarket(market)"
              v-else
              >{{ $t('landing.coin_markets', { coin: toUpperCase(market) }) }}</label
            >
          </template>
        </div>
      </template>
      <div class="search-coin">
        <img src="/images/spot_exchange/market_search.svg" alt="" class="icon" />
        <input
          type="text"
          :placeholder="`${$t('common.search')}...`"
          class="input-search"
          v-model="searchKey"
        />
      </div>
    </div>
    <div class="table-content">
      <div id="content">
        <market-coin
          :isMobile="isMobile"
          :priceScope="pricesFilter"
          :favorites="favorites"
          :marketSelected="selectedMarket"
          :columns="columns"
          :zone="zone"
          @SpotSelectedPairMarket="onSpotSelectedPairMarket"
        >
        </market-coin>
      </div>
    </div>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import BigNumber from 'bignumber.js'
import MarketCoin from './MarketCoin'
import FavoritesManager from '@/common/FavoritesManager'
import Const from '@/common/Const'

export default {
  components: {
    MarketCoin,
  },
  props: {
    //defaultMarket : { type: String, default: Const.DEFAULT_MARKET },
    columns: {
      type: [Array],
      default: () => {
        return []
      },
    },
    shortNameTab: { type: Boolean, default: false },
    callback: { type: Function, required: false },
    zone: { default: 0 },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      coinSettings: [],
      favorites: [],
      searchKey: '',
      priceScope: {},
      pricesFilter: {},
      sortColumn: 'coin',
      isSortDesc: false,
      isShowSearchField: false,
      markets: [],
      selectedMarket: Const.FAVORITES_TAB,
      tabFavorites: Const.FAVORITES_TAB,
      defaultMarket: Const.DEFAULT_MARKET,
      isLogin: window.isAuthenticated,
    }
  },
  computed: {
    getPairFavorites() {
      return window._.chain(this.favorites)
        .map((item) => item.coin_pair)
        .value()
    },
    isMobile() {
      if (!this.windowWidth) return
      return this.windowWidth <= 768
    },
  },
  watch: {
    selectedMarket(newMarket) {
      // Apply search key if have when change tabs
      if (!window._.isEmpty(this.searchKey)) {
        this.search()
        return
      }
      const result = window._.chain(this.pricesScope)
        .filter((item) => {
          return this.filterCoinByTab(item)
        })
        .orderBy(this.sortColumn, [this.isSortDesc ? 'desc' : 'asc'])
        .value()
      this.pricesFilter = Object.assign({}, result)
    },

    searchKey: function () {
      this.search()
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    getEventHandlers() {
      return {
        SpotUpdateVolumeMarket: this.onSpotUpdateVolumeMarket,
      }
    },
    onSpotUpdateVolumeMarket(res) {
      // Update 24h Volumn in Market Table
      const pair = `${res.currency}_${res.coin}`
      if (this.pricesScope) {
        const priceScope = this.pricesScope[pair]
        if (priceScope) {
          priceScope.volume = res.volume
        }
      }
    },
    onSpotSelectedPairMarket(pair) {
      // Handle changed pair if need.

      if (this.callback) {
        return this.callback()
      }
    },
    search() {
      const keyWord = window._.toLower(this.searchKey)
      this.pricesFilter = window._.chain(this.pricesScope)
        .filter((item) => {
          const filterByTab = this.filterCoinByTab(item)
          return filterByTab && item.coin.includes(keyWord)
        })
        .orderBy(this.sortColumn, [this.isSortDesc ? 'desc' : 'asc'])
        .value()
    },

    filterCoinByTab(item) {
      // Market coin tab
      let filterByTab = item.currency === this.selectedMarket

      // Favorites tab
      if (this.tabFavorites === this.selectedMarket) {
        const pair = `${item.coin}/${item.currency}`
        filterByTab = this.getPairFavorites.indexOf(pair) > -1
      }
      return filterByTab
    },

    toUpperCase(value) {
      if (!value) {
        return ''
      }
      return value.toUpperCase()
    },

    showMarket(market) {
      this.selectedMarket = market
    },

    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
        OrderTransactionCreated: this.onOrderTransactionCreated,
      }
    },

    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices)

      window._.each(this.pricesScope, (item) => {
        const coinPrice = newPrices[item.currency + '_' + item.coin]
        if (!coinPrice) {
          return
        }

        const lastPrice = new BigNumber(`${coinPrice.price || 0}`)
        const currentPrice = new BigNumber(`${coinPrice.current_price || 0}`)

        if (lastPrice.gt(`${item.max_price || 0}`)) {
          item.max_price = coinPrice.price
        }
        if (lastPrice.lt(`${item.min_price || 0}`)) {
          item.min_price = coinPrice.price
        }

        if (item.current_price) {
          let result = lastPrice.sub(`${item.current_price || 0}`).toString()
          this.$set(item, 'changePrice', result)
        }

        item.changed_percent = coinPrice.change
        item.previous_price = coinPrice.previous_price

        item.last_price = lastPrice.toString()
        item.current_price = currentPrice.toString()
        // item.last_price_fiat = this.toFiatPrice(item.last_price, item.currency);
        // item.current_price_fiat = this.toFiatPrice(
        //   item.current_price,
        //   item.currency
        // );
      })
    },

    onOrderTransactionCreated(data) {
      const pair = `${data.orderTransaction.currency}_${data.orderTransaction.coin}`
      const priceScope = this.pricesScope ? this.pricesScope[pair] : undefined
      if (priceScope) {
        priceScope.volume = new BigNumber(`${priceScope.volume || 0}`).add(
          `${data.orderTransaction.quantity}`
        )
      }
    },

    // toFiatPrice(price, fromCurrency) {
    //   return new BigNumber(price || 0)
    //     .mul(new BigNumber(this.getPrice("idr_" + fromCurrency)?.price))
    //     .toString();
    // },

    getPrice(pair) {
      if (!this.prices) {
        return { price: 1 }
      }
      return this.prices[pair]
    },

    // calculateFiatPrice() {
    //   window._.forEach(this.pricesScope, (item) => {
    //     item.last_price_fiat = this.toFiatPrice(item.last_price, item.currency);
    //   });
    // },

    initData() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((res) => {
          this.coinSettings = res.coin_settings.filter((item) => item.is_enable)
          this.markets = window._.chain(res.market_settings)
            .filter((item) => item.status == 'active')
            .map((item) => item.currency)
            .value()

          if (this.markets && this.markets.length > 0) {
            this.defaultMarket = this.markets[0]
          }
          return this.markets
        })
        .then((res) => {
          rf.getRequest('PriceRequest')
            .getPrices24h()
            .then((res) => {
              this.pricesScopeUpdated(this.filterData(res.data))
            })
            .finally(() => {
              this.selectedMarket = this.getSelectTabViaRoute()
            })
          this.getFavorites()
        })
    },

    filterData(data) {
      let result = {}
      for (let key in data) {
        let pair = data[key]
        let pairZone = this.getPairZone(pair)
        if (pairZone == this.zone) {
          result[key] = pair
        }
      }
      return result
    },

    getPairZone(data) {
      for (let setting of this.coinSettings) {
        if (data.currency === setting.currency && data.coin === setting.coin) {
          return setting.zone
        }
      }
      return -1
    },

    getSelectTabViaRoute() {
      const query = this.$route.query || {}
      return query.tab || query?.currency || this.defaultMarket
    },

    getFavorites() {
      FavoritesManager.getFavorites().then((data) => {
        let markets = this.markets

        data = window._.filter(data, (item) => {
          let coinPair = item.coin_pair
          let currency = coinPair.split('/')
          if (currency.length > 1) {
            currency = currency[1]
          } else {
            currency = ''
          }
          return markets.indexOf(currency) != -1
        })

        if (this.isLogin) this.favorites = data
      })
    },

    pricesScopeUpdated(newPrices) {
      this.pricesFilter = this.pricesScope = newPrices

      // this.calculateFiatPrice();
    },
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
    await this.initData()
  },
}
</script>

<style lang="scss" scoped>
.dropdown {
  font-family: $helvetica;
  &.open {
    .icon-arrow1 {
      rotate: 180deg;
    }
  }
  .btn-market {
    border-radius: 5px;
    border: solid 1px $bg-main2;
    color: $text-main;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    padding: 7px 16px;
    background-color: $color-white;
    text-align: left;
    width: 100%;
    height: auto;
    overflow: hidden;
    &:focus,
    &:active {
      outline: none !important;
    }
    .icon {
      display: inline;
      float: right;
      font-size: 12px;
      line-height: 20px;
      position: absolute;
      top: 10px;
      right: 12px;
    }
  }
  .dropdown-menu {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
    border: 1px solid $grey-1;
    background-color: $color-white;
    padding: 10px 0px;
    .tab-element {
      cursor: pointer;
      display: block;
      width: 100%;
      padding: 10px 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      color: $text-main;
      & > img {
        margin-right: 10px;
      }
      & > span {
        line-height: 24px;
      }
      &:hover {
        background: $grey-3;
      }
    }
  }
}
.market__mobile-wrapper {
  font-family: $helvetica;
  flex: 1;
  .market-type-selected {
    display: flex;
    & > img {
      margin-right: 10px;
    }
    & > div {
      margin-top: auto;
      margin-bottom: auto;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
      color: $text-main;
    }
  }
}
.box-table {
  font-family: $helvetica;
  #container-tab {
    background-color: white;
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: space-between;
    padding: 24px 24px 0 24px;
    @include mobile() {
      padding: 24px 16px 0;
      justify-content: space-between;
      gap: 16px;
    }

    .tab-select {
      max-width: 100%;
      overflow: auto;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      label {
        display: flex;
        cursor: pointer;
        width: auto;
        padding: 7px 24px;
        float: left;
        font-size: 14px;
        line-height: 24px;
        font-weight: 700;
        font-family: $helvetica;
        color: $text-secondary;
        white-space: nowrap;
        & img {
          margin-right: 10px;
        }
        &.current-market {
          border-radius: 60px;
          color: white;
          background-color: $bg-main1;
        }
        .icon-star2 {
          color: $color-orange;
        }
      }
    }
    .search-coin {
      position: relative;
      img.icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 12px;
      }
      .input-search {
        width: 200px;
        border-radius: 10px;
        border: unset;
        font-size: 14px;
        font-weight: 300;
        font-family: $helvetica;
        background-color: $bg-main4;
        line-height: 22px;
        color: $text-main;
        padding: 8px 16px 8px 48px;
        &::placeholder {
          color: $text-main-blur;
          line-height: 22px;
        }
      }
      @include tablet;
      @include mobile {
        .input-search {
          width: 170px;
        }
      }
    }
  }
  #content {
    position: relative;
    background-color: white;
    min-height: 240px;
    width: 100%;
    // padding-bottom: 28px;
    margin-top: 10px;
    z-index: 5;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: white;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: $color-grey-border;
    }
    @include tablet;
    @include mobile {
      padding: 0 16px 16px;
      max-height: 1000px;
      overflow: auto;
      &::-webkit-scrollbar {
        max-height: 120px;
      }
      &::-webkit-scrollbar-thumb {
        background: $bg-main2;
        border-radius: 30px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.box-table {
  #content {
    .item-tab {
      :deep(.tableContainer) {
        max-height: 400px;
        overflow: auto;
        padding-bottom: calc(24px - 16px);
        &::-webkit-scrollbar {
          width: 12px;
          height: 12px;
        }
        &::-webkit-scrollbar-thumb {
          border: 3px solid rgba(black, 0);
          border-radius: 30px;
          background-color: $bg-main2;
          background-clip: padding-box;
        }
        table {
          width: 100%;
          thead {
            background-color: #fff;
            position: sticky;
            top: 0;
            tr {
              th {
                height: 40px;
                background-color: $bg-main4;
                padding: 10px 0;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                color: $text-secondary;
                border-bottom: 0;
                font-family: $helvetica;
                &:hover {
                  color: $dark-3;
                }
                @include mobile() {
                  font-size: 12px;
                }
                &:first-child {
                  padding-left: 22px;
                }
              }
              th[data-sort-order='desc']:after,
              th[data-sort-order='asc']:after {
                margin-top: 4px;
              }
            }
          }
          tbody {
            width: 100%;
            overflow: auto;
            tr {
              td {
                font-size: 16px;
                font-weight: 300;
                line-height: 24px;
                font-family: $helvetica;
                color: $text-main;
                padding: 16px 0;
                border-bottom: 1px solid $bg-main2;
                @include mobile {
                  font-size: 12px;
                }
                &.col-pair {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  gap: 10px;
                  .icon-star1 {
                    color: $color-orange;
                    line-height: 14px;
                    font-size: 14px;
                    margin: 0;
                    padding-top: 4px;
                  }
                  .coin-icon {
                    .coin {
                      width: 20px;
                      height: 20px;
                      border-radius: 50%;
                    }
                    .currency {
                      width: 20px;
                      height: 20px;
                      border-radius: 50%;
                      margin-left: -7px;
                    }
                  }
                  .coin {
                    color: $text-main;
                    font-weight: 300;
                  }
                }
                .is-muted {
                  color: $dark-1;
                }
                &:first-child {
                  padding-left: 22px;
                }
                &:last-child {
                  text-align: right;
                }
              }

              &:last-child td {
                border-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
