<template>
  <div id="box_enter_api_key" :class="{ 'empty-list': !apiList.length }">
    <otp-modal
      ref="OtpModal"
      :submit="callbackOtp"
      :is-disable-button="isDisableButton"
      :params="dataOtp"
    />
    <div class="box-title">
      <div class="title_page">{{ $t("account.api.title_page") }}</div>
      <!-- <div class="des_title_page">{{ $t('account.api.des_title_page') }}</div> -->
    </div>
    <div class="group_action">
      <div class="left">
        <input
          type=""
          name=""
          class="input_enter_key"
          v-model="newApiName"
          :maxlength="maxlength"
          :placeholder="$t('account.api.input_placeholder')"
          @keyup="keyupInput"
        />
        <button
          class="btn_creat_new_key"
          :disabled="!newApiName || newApiName.trim() == ''"
          @click="showOTPModal({ action: 'create' })"
        >
          {{ $t("account.api.create_btn") }}
        </button>
      </div>
      <!-- <button class="btn btn-delete" @click="confirmDeleteAllApi()">
        {{ $t('account.api.delete_all') }}
      </button> -->
    </div>
    <div v-if="isLoading" class="loading-api">
      <div class="loader"></div>
    </div>
    <div class="content_page_api" v-if="apiList && apiList.length > 0">
      <div class="title-content">
        {{ $t("account.api.title") }}
      </div>

      <template v-for="(api, i) in apiList">
        <div
          class="list_box_api"
          :key="api.id"
          v-if="
            i + 1 >= page * perPage - perPage + 1 && i + 1 <= page * perPage
          "
        >
          <div class="box_api_detail">
            <div class="header_detail_api">
              <div class="name">{{ api.name }}</div>
              <div class="list_button">
                <!-- <button
                  class="btn btn_setting"
                  @click="editApi(api, scopeEditing[api.id])"
                  v-if="!enableEditing[api.id]"
                >
                  {{ $t('account.api.edit') }}
                </button>
                <button
                  class="btn"
                  @click="closeApi(api)"
                  v-if="enableEditing[api.id]"
                >
                  {{ $t('account.api.cancel') }}
                </button>
                <button
                  class="btn btn_setting"
                  @click="
                    showOTPModal({
                      action: 'save',
                      api,
                      scope: scopeEditing[api.id],
                    })
                  "
                  v-if="enableEditing[api.id]"
                >
                  {{ $t('account.api.save') }}
                </button> -->
                <button
                  class="btn_delete"
                  @click="confirmDeleteApi(api)"
                  v-if="!enableEditing[api.id]"
                >
                  {{ $t("account.api.delete") }}
                </button>
              </div>
            </div>
            <div class="body_detail_api">
              <div class="left_qr_code">
                <div class="img_qr_code">
                  <img class="img_qr" :src="STORAGE_URL + api.qr_code" />
                </div>
                {{ $t("account.api.scan_address") }}
              </div>
              <div class="right_sub">
                <div class="group_grid">
                  <div>
                    <div class="name">{{ $t("account.api.key") }}:</div>
                    <div class="content">
                      <div class="key">{{ api.id }}</div>
                      <span class="icon" @click="copyApiKey(api.id)">
                        <img src="@/assets/images/icon/copy.svg" alt="" />
                      </span>
                    </div>
                  </div>
                  <div>
                    <div class="name">{{ $t("account.api.secret_key") }}:</div>
                    <div class="key">{{ api.secret }}</div>
                  </div>
                </div>
                <!-- <div class="group_sub">
                  <div class="name">{{ $t('account.api.option') }}:</div>
                  <div class="list_option" v-if="!!scopeEditing[api.id]">
                    <label
                      class="container_checkbox_option"
                      v-for="rule in scopeEditing[api.id]"
                      v-if="rule"
                    >
                      {{ $t('account.api.permissions.' + rule.key) }}
                      <input
                        type="checkbox"
                        :checked="rule.enable"
                        :disabled="!enableEditing[api.id] || rule.scope == 4"
                        @click="updateScopeItem(api, rule)"
                      />
                      <span
                        :class="{
                          checkmark_option: true,
                          disabled: !enableEditing[api.id] || rule.scope == 4,
                        }"
                      ></span>
                    </label>
                  </div>
                </div>
                <div class="group_sub">
                  <div class="name">{{ $t('account.api.ip_access_res') }}</div>
                  <div class="list_access_restriction">
                    <label class="container_checkbox_restriction"
                      >{{ $t('account.api.unrestricted')
                      }}<span class="warning">{{
                        $t('account.api.not_recommended')
                      }}</span>
                      <input
                        type="radio"
                        @click="ipRestricted(api.id, 0)"
                        :disabled="!enableEditing[api.id]"
                        value="0"
                        v-model="is_restrict[api.id]"
                        :name="'is_restrict_' + api.id + '_0'"
                      />
                      <span
                        :class="{
                          checkmark_restriction: true,
                          disabled: !enableEditing[api.id],
                          selected:
                            !enableEditing[api.id] && !is_restrict[api.id],
                        }"
                      ></span>
                    </label>
                    <label class="container_checkbox_restriction"
                      >{{ $t('account.api.restrict_access') }}
                      <input
                        type="radio"
                        @click="ipRestricted(api.id, 1)"
                        :disabled="!enableEditing[api.id]"
                        value="1"
                        v-model="is_restrict[api.id]"
                        :name="'is_restrict_' + api.id + '_1'"
                      />
                      <span
                        :class="{
                          checkmark_restriction: true,
                          disabled: !enableEditing[api.id],
                          selected:
                            !enableEditing[api.id] && !!is_restrict[api.id],
                        }"
                      ></span>
                    </label>
                  </div>
                </div>
                <div class="box_add_api">
                  {{ $t('account.api.current_ip') }}
                  <div class="group_input_api">
                    <input
                      v-model="ipInput[api.id]"
                      v-show="enableEditing[api.id]"
                      type=""
                      name="ip"
                      placeholder="Trusted IPs: 0.0.0.0"
                    />
                    <button
                      class="btn btn-confirm"
                      v-show="enableEditing[api.id]"
                      @click="confirmIps(api.id)"
                    >
                      {{ $t('account.api.confirm') }}
                    </button>
                  </div>
                  <div style="display: flex; overflow: scroll; flex-wrap: wrap">
                    <div
                      v-for="ipItem in ipRestrictionSettings[api.id]"
                      class="ip-item"
                      :key="ipItem"
                    >
                      {{ ipItem }}
                      <div
                        class="icon"
                        @click.prevent="removeIps(api.id, ipItem)"
                        v-show="enableEditing[api.id]"
                      >
                        <span class="icon-close"></span>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div class="content_pagination">
      <pagination
        :perPage="perPage"
        :records="apiList.length"
        :chunk="chunk"
        :pageParent="page"
        @Pagination:page="pageChange"
      />
    </div>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import ConfirmationModal from "@/components/shared_components/common/ConfirmationModal";
import Utils from "@/common/Utils";
import OtpModal from "@/components/shared_components/funds/common/OtpModal.vue";
import COMMON_CONST from "@/common/Const";
import { mapState } from "vuex";
import pagination from "@/components/shared_components/common/DataTable/Pagination.vue";

const TOKEN_API_TYPE = "api_token";
const ruleList = [
  {
    key: "read",
    scope: 4,
    enable: true,
  },
  {
    key: "withdrawal",
    scope: 1,
    enable: false,
  },
  {
    key: "trading",
    scope: 2,
    enable: false,
  },
];

export default {
  components: {
    ConfirmationModal,
    OtpModal,
    pagination,
  },

  data() {
    return {
      maxlength: 20 || COMMON_CONST.MAX_LENGTH_INPUT,
      newApiName: "",
      oldApiName: "",
      otpCode: null,
      key2fa: "",
      enableEditing: {},
      scopeEditing: {},
      apiList: [],
      dataOtp: {},
      user: null,
      isDisableButton: false,
      ipRestrictionSettings: {},
      ip_restricted: {},
      ipInput: {},
      is_restrict: {},

      page: 1,
      perPage: 10,
      chunk: 6,
      isLoading: false,
    };
  },

  watch: {
    apiList(value) {
      this.apiList.map((el) => {
        const data = this.generateScope(el);
        this.generateIPlist(el);
        this.is_restrict = {
          ...this.is_restrict,
          [el.id]: el.is_restrict,
        };

        this.scopeEditing = {
          ...this.scopeEditing,
          [el.id]: data,
        };
      });
    },
    ipRestrictionSettings(value) {},
    ipInput(value) {},
  },

  mounted() {
    this.getUserInformation();
    this.getApiSettings();
    document.title = this.$t("account.api.title_page") + ` – ${this.tabTitle}`;
  },

  computed: {
    ...mapState({
      masterdata: (state) => state.masterdata,
    }),
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === "storage_url")
        .value;
    },
  },

  methods: {
    callbackOtp(data) {
      this.otpCode = data.otp;
      this.isDisableButton = false;

      if (this.dataOtp.action == "create") {
        this.createNewApi(data);
      }
      if (this.dataOtp.action == "save")
        this.saveApi(this.dataOtp.api, this.dataOtp.scope);
      if (this.dataOtp.action == "delete") this.deleteApi(this.dataOtp.api);
      if (this.dataOtp.action == "deleteAll") this.deleteApi();
      this.newApiName = "";
    },

    ipRestricted(api, condition) {
      // this.ipRestrictionSettings[api] = condition;
      this.is_restrict[api] = condition;
    },

    confirmIps(api) {
      const ip = this.ipInput[api];

      const validateIp =
        /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/.test(
          ip
        );

      if (validateIp) {
        if (!this.ipRestrictionSettings[api])
          this.ipRestrictionSettings[api] = [];
        this.ipRestrictionSettings = {
          ...this.ipRestrictionSettings,
          [api]: window._.union(this.ipRestrictionSettings[api], [ip]),
        };
      }

      return;
    },

    removeIps(api, item) {
      this.ipRestrictionSettings = {
        ...this.ipRestrictionSettings,
        [api]: window._.filter(
          this.ipRestrictionSettings[api],
          (el) => el !== item
        ),
      };
    },

    showOTPModal(data, bypass = false, skipDuplicate = false) {
      const field = this.newApiName ? this.newApiName.trim() : "";
      const indexAPIList = this.apiList.findIndex((obj) => obj.name === field);
      if (!skipDuplicate && (indexAPIList > -1 || field.length <= 0)) {
        Message.error(this.$t("account.api.duplicate_value"), {});
        return;
      }

      this.dataOtp = {
        ...data,
      };

      if (bypass) {
        this.callbackOtp(this.dataOtp);
        return;
      }

      window.CommonModal.show("OtpModal");
    },

    showGoogleAuthenticationModal() {
      window.CommonModal.show("OtpModal");
    },

    getUserInformation(useCache = true) {
      rf.getRequest("UserRequest")
        .getCurrentUser(useCache)
        .then((res) => {
          this.user = Object.assign({}, this.user, res.data);
        });
    },

    checkRestrictPage() {
      if (!this.user) {
        this.getUserInformation();
        return;
      }
      if (this.user.security_level < 2) {
        const path = "/page-not-found";
        this.$router.push({ path });
      }
      return;
    },

    getApiSettings() {
      this.isLoading = true;
      this.checkRestrictPage();
      rf.getRequest("UserRequest")
        .getUserApiSettings()
        .then((res) => {
          this.apiList = res.data;
          this.isLoading = false;
          this.refreshPage();
        });
    },

    generateScope({ id, scopes }) {
      const clone = window._.cloneDeep(ruleList);

      if (
        !scopes ||
        typeof scopes !== "object" ||
        (typeof scopes === "object" && scopes.length <= 0)
      ) {
        return clone;
      }

      if (JSON.stringify(scopes) == JSON.stringify(["*"])) {
        clone.map((el) => {
          el.enable = true;
        });
        return clone;
      } else {
        scopes.map((scope) => {
          let def = {
            7: [4, 2, 1],
            6: [4, 2],
            5: [4, 1],
            4: [4],
            3: [2, 1],
            2: [2],
            1: [1],
          };

          clone.map((el) => {
            const enable = def[scope] && def[scope].includes(el.scope);
            el.enable = el.enable ? el.enable : enable;
          });
        });

        return clone;
      }
    },

    generateIPlist(data) {
      const { ip_restricted } = data;
      if (ip_restricted) {
        const text = ip_restricted.split(",");
        data.ip_restricted = text;
        this.ipRestrictionSettings[data.id] = text;
        return data;
      }
    },

    showModal(config) {
      const classC = config.class;
      const type = config.type;
      const title = config.title;
      const content = config.content;
      const customContent = config.customContent;
      let btnCancelLabel = config.btnCancelLabel || this.$t("common.action.no");
      let btnConfirmLabel =
        config.btnConfirmLabel || this.$t("common.action.yes");
      if (config.noAction) {
        btnCancelLabel = null;
        btnConfirmLabel = null;
      }
      const onConfirm = config.onConfirm;
      const onCancel = config.onCancel;
      window.ConfirmationModal.show({
        class: classC,
        type: type,
        title: title,
        content: content,
        customContent: customContent,
        btnCancelLabel: btnCancelLabel,
        btnConfirmLabel: btnConfirmLabel,
        onConfirm: onConfirm,
        onCancel: onCancel,
      });
    },

    confirmDeleteApi(api) {
      const title = this.$i18n.t("account.api.delete_api");
      const message =
        api && api.name
          ? this.$i18n.t("account.api.delete_api_message_withname", {
              name: api.name,
            })
          : this.$i18n.t("account.api.delete_api_message_noname");
      this.showModal({
        type: "delete_api",
        title: title,
        class: "custom_modal_del_api",
        content: message,
        btnCancelLabel: this.$i18n.t("common.action.no"),
        btnConfirmLabel: this.$i18n.t("common.action.yes"),
        onConfirm: () => {
          this.showOTPModal({ action: "delete", api }, false, true);
        },
        onCancel: () => {},
      });
    },

    confirmDeleteAllApi() {
      const title = this.$i18n.t("Delete All");
      const message = this.$i18n.t("Are you sure want to delete all API Key?");
      this.showModal({
        type: "delete_all_api",
        title: title,
        class: "custom_modal_del_api",
        content: message,
        btnCancelLabel: this.$i18n.t("common.action.no"),
        btnConfirmLabel: this.$i18n.t("common.action.yes"),
        onConfirm: () => {
          this.showOTPModal({ action: "deleteAll" }, false, true);
        },
        onCancel: () => {},
      });
    },

    updateScopeItem(api, scopes) {
      const item = this.scopeEditing[api.id].find((el) => el.key == scopes.key);
      if (item)
        this.scopeEditing[api.id].find((el) => el.key == scopes.key).enable =
          !scopes.enable;
    },

    createNewApi(data) {
      const name = this.newApiName ? this.newApiName.trim() : "";
      if (!name) return;
      this.errors.clear();
      rf.getRequest("UserRequest")
        .createUserApiSettings({ name, scopes: 4, ...data })
        .then((res) => {
          window.CommonModal.hide("OtpModal");
          if (res.success) {
            this.getApiSettings();
            Message.success(this.$i18n.t("account.api.create_success"));
          }
        })
        .catch((err) => {
          this.newApiName = name;
          const errors = err.response.data.errors;
          if (errors) {
            Object.keys(errors).forEach((key) => {
              this.errors.add(key, this.$i18n.t(errors[key][0]));
            });
          } else {
            window.CommonModal.hide("OtpModal");
            Message.error(err.response.data.message);
          }
        });
    },

    editApi(api, previousRules) {
      const id = api.id;
      this.scopeEditing = {
        ...this.scopeEditing,
        [id]: previousRules,
      };

      this.enableEditing = {
        ...this.enableEditing,
        [id]:
          this.enableEditing[id] !== undefined ? !this.enableEditing[id] : true,
      };
    },

    closeApi(api) {
      this.enableEditing = {
        ...this.enableEditing,
        [api.id]: false,
      };
    },

    saveApi(api, previousRules) {
      const getScopeForCurrentRules = (inputScopeArray) => {
        if (inputScopeArray.every((el) => el.enable)) return 7;
        return inputScopeArray
          .filter((el) => el.enable)
          .map((el) => el.scope)
          .reduce((a, b) => a + b, 0);
      };

      const params = {
        ...api,
        otp: this.otpCode,
        scopes: getScopeForCurrentRules(previousRules),
        ip_restricted: this.ipRestrictionSettings[api.id]
          ? this.ipRestrictionSettings[api.id].join(",")
          : "",
        is_restrict: this.is_restrict[api.id],
      };

      rf.getRequest("UserRequest")
        .updateUserApiSettings(params)
        .then((res) => {
          if (res.data) {
            this.closeApi(api);
            Message.success(this.$i18n.t("account.api.save_success"), {});
          }
        })
        .catch((err) => {
          Message.error(this.$i18n.t("account.api.save_fail"), {});
        });
    },

    deleteApi(api) {
      if (api) {
        const id = api.id;
        rf.getRequest("UserRequest")
          .deleteOneApiSettings({ id, otp: this.otpCode })
          .then((res) => {
            if (api && res.success) {
              window.CommonModal.hide("OtpModal");
              Message.success(this.$i18n.t("account.api.delete_success"));
              this.getApiSettings();
            }
          })
          .catch((error) => {
            if (error.response && error.response.data.errors) {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.errors.add({
                  field: key,
                  msg: this.$t(error.response.data.errors[key][0]),
                });
              });
              if (!this.errors.has("otp")) CommonModal.hide("OtpModal");
            } else {
              this.errors.add("error", this.$t("server.logs"));
            }
          });
      } else {
        rf.getRequest("UserRequest")
          .deleteAllApiSettings({ otp: this.otpCode })
          .then((res) => {
            if (res.success) {
              this.getApiSettings();
              Message.success(this.$i18n.t("account.api.delete_success"));
            }
          })
          .catch((error) => {
            if (error.response && error.response.data.errors) {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.errors.add({
                  field: key,
                  msg: this.$t(error.response.data.errors[key][0]),
                });
              });
            } else {
              this.errors.add("error", this.$t("server.logs"));
            }
          });
      }
    },
    copyApiKey(text) {
      if (!navigator.clipboard) {
        Utils.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text);

      Message.success(
        this.$t("account.api.copy"),
        {}
        // { position: "bottom_left" }
      );
    },
    checkAvailable(api) {
      return api && api.id ? this.enableEditing[api.id] : false;
    },

    keyupInput(eve) {
      if (eve.key !== "Enter") return;
      this.showOTPModal({ action: "create" });
    },

    pageChange(eve) {
      this.page = eve;
    },

    refreshPage() {
      const currentPage = this.page;
      const length = this.perPage;
      const itemLength = this.apiList.length;

      let value = Math.ceil(itemLength / length);
      if (value === 0) value = 1;

      if (currentPage > value) {
        this.page = value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#box_enter_api_key {
  min-height: calc(100vh - 60px - 379px);
  position: relative;
  height: auto;
  margin: 0 auto;
  padding: 40px 80px 25px;
  width: 100%;

  @media (max-width: 1399px) {
    padding: 25px 32px;
  }

  @include tablet-d {
    padding: 16px 16px 60px;
  }

  // &.empty-list {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   .OtpModal {
  //     position: absolute;
  //   }
  //   .group_action .left {
  //     justify-content: flex-start;
  //   }

  //   @include mobile {
  //     .group_action,
  //     .group_action .left {
  //       width: 100%;
  //     }
  //   }
  // }

  .box-title {
    .title_page {
      font-family: $helvetica;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      color: $text-main;

      @include mobile {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }
    }

    .des_title_page {
      font-family: $helvetica;
      font-size: 14px;
      line-height: 20px;
      color: $dark-1;
    }
  }

  .group_action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    margin-top: 8px;

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 15px;

      .input_enter_key {
        width: 354px;
        padding: 9px 16px;
        background: white;
        border: 1px solid $bg-main2;
        border-radius: 10px;
        font-family: $helvetica;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 32px;

        &::placeholder {
          color: $text-main-blur;
        }

        @include mobile {
          width: 100%;
          flex: 1;
        }
      }

      .btn_creat_new_key {
        cursor: pointer;
        font-family: $helvetica;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        background: $bg-main1;
        border: 1px solid $bg-main1;
        border-radius: 60px;
        color: white;
        padding: 10px 32px;

        &:disabled {
          cursor: not-allowed;
          border: unset;
          background: $bg-main2;
          color: $text-main-blur;
        }
      }
    }

    @include mobile {
      display: block;

      .left {
        flex-direction: column;
        align-items: flex-start;

        .input_enter_key {
          padding: 8px 16px;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
        }

        .btn_creat_new_key {
          padding: 9px 32px;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }
    }

    .btn-delete {
      height: 34px;
      font-family: $helvetica;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid $secondary-red;
      background: white;
      border-radius: 5px;
      color: $secondary-red;
      text-transform: uppercase;
    }
  }

  .loading-api {
    margin: auto;
    display: flex;
    margin-top: 16px;
    height: 100%;

    .loader {
      display: block;
      margin: auto;
      border: 5px solid $color-grey-concrete;
      border-radius: 50%;
      border-top: 5px solid $bg-main1;
      width: 40px;
      height: 40px;
      -webkit-animation: spin 2s linear infinite;
      /* Safari */
      animation: spin 2s linear infinite;
    }
  }

  .content_page_api {
    margin-top: 24px;

    .title-content {
      font-family: $helvetica;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      color: $text-main;
    }

    .list_box_api {
      .box_api_detail {
        width: 100%;
        padding: 26px;
        margin-top: 16px;
        background-color: white;
        border-radius: 5px;

        .header_detail_api {
          border-bottom: 1px solid $bg-main2;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          padding-bottom: 23px;

          .name {
            font-family: $helvetica;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            color: $text-main;
          }

          .list_button {
            display: flex;
            align-items: center;
            gap: 10px;

            .btn_setting {
              height: 32px;
              font-family: $helvetica;
              font-size: 14px;
              line-height: 18px;
              background: $primary-2;
              border-radius: 30px;
              color: white;
            }

            .btn_delete {
              font-family: $helvetica;
              padding: 7px 24px;
              border: none;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              background: $secondary-red;
              border-radius: 60px;
              color: white;
            }
          }
        }

        .body_detail_api {
          margin-top: 20px;
          display: flex;
          flex-direction: row;
          gap: 70px;

          @include tablet-d {
            flex-direction: column;
            gap: 30px;
          }

          .left_qr_code {
            font-family: $helvetica;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
            color: $text-secondary;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .img_qr_code {
              display: block;
              width: 100%;
              max-width: 126px;
              aspect-ratio: 1 / 1;
              padding: 0px;
              margin-bottom: 15px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .right_sub {
            flex: 1;
            display: block;

            .group_grid {
              display: flex;
              gap: 120px;
              flex-direction: row;
              // width: fit-content;

              > div {
                max-width: 260px;
                flex: 1;
              }

              @include tablet-d {
                // flex-direction: column;
                width: 100%;
                gap: 30px;

                > div {
                  max-width: 100%;
                  flex: 1;
                }
              }

              @include mobile {
                flex-direction: column;
              }

              .name {
                font-family: $helvetica;
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 22px;
                color: $text-main-blur;
              }

              .key {
                margin-top: 5px;
                font-family: $helvetica;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                color: $text-main;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }

              .content {
                display: grid;
                grid-template-columns: 1fr 20px;
                gap: 10px;

                .icon {
                  margin-top: auto;
                  margin-bottom: auto;
                  cursor: pointer;
                }
              }
            }

            .group_sub {
              display: flex;
              align-items: flex-start;
              justify-content: space-between;
              gap: 10px;
              margin-bottom: 10px;

              .name {
                font-family: $helvetica;
                font-size: 14px;
                line-height: 20px;
                color: $dark-1;
              }

              .btn-copy-code {
                height: 34px;
                font-family: $helvetica;
                font-size: 14px;
                line-height: 20px;
                background: $primary-2;
                border-radius: 5px;
                color: white;
              }

              .list_option {
                display: block;
                width: 100%;

                .container_checkbox_option {
                  font-family: $helvetica;
                  font-size: 14px;
                  line-height: 20px;
                  color: $dark-1;
                }
              }

              .container_checkbox_option {
                display: block;
                position: relative;
                padding-left: 20px;
                margin-bottom: 10px;
                line-height: 22px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                font-weight: 500;
                font-family: $font-family-roboto-medium;
                font-size: 14px;
                color: #333333;

                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                  height: 0;
                  width: 0;
                }

                .checkmark_option {
                  position: absolute;
                  top: 4px;
                  left: 0;
                  height: 12px;
                  width: 12px;
                  background-color: transparent;
                  border: 1px solid #cccccc;

                  &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    left: 3px;
                    top: 0px;
                    width: 5px;
                    height: 8px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                  }

                  &.disabled {
                    background-color: #ececec !important;
                    border: solid 1px #e5e5e5 !important;
                  }
                }
              }

              .container_checkbox_option:hover input ~ .checkmark_option {
                background-color: #ccc;
              }

              .container_checkbox_option input:checked ~ .checkmark_option {
                background-color: #2196f3;
                border: 1px solid #2196f3;
              }

              .container_checkbox_option
                input:checked
                ~ .checkmark_option:after {
                display: block;
              }

              .container_checkbox_restriction {
                display: block;
                position: relative;
                padding-left: 20px;
                margin-bottom: 10px;
                line-height: 22px;
                cursor: pointer;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                font-weight: 500;
                font-family: $font-family-roboto-medium;
                font-size: 14px;
                color: #333333;

                .warning {
                  font-family: $font-family-roboto;
                  font-size: 13px;
                  color: #f74940;
                }

                input {
                  position: absolute;
                  opacity: 0;
                  cursor: pointer;
                }

                .checkmark_restriction {
                  position: absolute;
                  top: 4px;
                  left: 0;
                  height: 12px;
                  width: 12px;
                  background-color: transparent;
                  border-radius: 50%;
                  border: 1px solid #cccccc;

                  &.disabled {
                    background: #ececec;
                  }

                  &.selected {
                    background: #d2d2d2 !important;
                  }

                  &:after {
                    content: "";
                    position: absolute;
                    display: none;
                    top: 3px;
                    left: 3px;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: white;
                  }
                }
              }

              .container_checkbox_restriction:hover
                input
                ~ .checkmark_restriction {
                background-color: #ccc;
              }

              .container_checkbox_restriction
                input:checked
                ~ .checkmark_restriction {
                background-color: #2dac91;
                border: 1px solid #2dac91;
              }

              .container_checkbox_restriction
                input:checked
                ~ .checkmark_restriction:after {
                display: block;
              }
            }

            .box_add_api {
              .ip-item {
                padding: 10px 10px;
                background: antiquewhite;
                margin: 0px 13px 5px 0;
                display: -webkit-flex;
                display: -moz-flex;
                display: -ms-flex;
                display: -o-flex;
                display: flex;

                .icon-close {
                  font-weight: 600;
                  font-size: 12px;
                  margin-left: 10px;
                  color: $color-green;
                }
              }
            }

            .group_input_api {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 10px;
              margin-top: 10px;

              input {
                min-width: 250px;
                padding: 6px 15px;
                background: white;
                border: 1px solid $grey-2;
                border-radius: 5px;
                font-family: $helvetica;
                font-size: 14px;
                line-height: 20px;
              }

              button {
                height: 34px;
                font-family: $helvetica;
                font-size: 14px;
                line-height: 20px;
                background: $primary-2;
                border-radius: 5px;
                color: white;
              }

              .list_option {
                display: block;
                width: 400px;

                .container_checkbox_option {
                  display: block;
                  float: left;
                  width: 50%;
                }
              }

              .box_add_api {
                padding-left: 20px;
                font-size: 14px;
                color: #666666;
                line-height: 20px;

                .ip-item {
                  padding: 10px 10px;
                  background: antiquewhite;
                  margin: 0px 13px 5px 0;
                  display: -webkit-flex;
                  display: -moz-flex;
                  display: -ms-flex;
                  display: -o-flex;
                  display: flex;

                  .icon-close {
                    font-weight: 600;
                    font-size: 12px;
                    margin-left: 10px;
                    color: $color-green;

                    &:hover,
                    &:focus {
                      color: $color-eden;
                      cursor: pointer;
                    }
                  }
                }

                .group_input_api {
                  display: block;
                  width: 371px;
                  max-width: 100%;
                  margin-top: 12px;
                  margin-bottom: 12px;

                  input {
                    width: calc(100% - 95px - 10px);
                    float: left;
                    height: 40px;
                    line-height: 20px;
                    padding: 9px 12px;
                    background-color: transparent;
                    border: solid 1px #cfcfcf;
                    font-size: 14px;
                    color: #333333;

                    &:focus {
                      border: solid 1px #2e9bdb;
                    }

                    &::-webkit-input-placeholder {
                      color: #999999;
                    }

                    &:-ms-input-placeholder {
                      color: #999999;
                    }

                    &::placeholder {
                      color: #999999;
                    }
                  }

                  button {
                    width: 95px;
                    height: 40px;
                    border-radius: 3px;
                    background-color: #2dac91;
                    display: block;
                    float: right;
                    text-align: center;
                    text-transform: uppercase;
                    line-height: 20px;
                    padding: 10px 5px;
                    border: 0px;
                    font-family: $font-family-roboto-bold;
                    font-size: 12px;
                    color: #ffffff;

                    &:hover {
                      background-color: #2e9bdb;
                      border-color: #2e9bdb;
                      color: #ffffff;
                      outline: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    @include mobile {
      .title-content {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      .list_box_api {
        .box_api_detail {
          padding: 16px;

          .header_detail_api {
            padding-bottom: 16px;

            .name {
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
            }

            .list_button {
              .btn_delete {
                padding: 7px 24px;
                font-size: 14px;
                font-style: normal;
                border: none;
                font-weight: 700;
                line-height: 24px;
              }
            }
          }

          .body_detail_api {
            gap: 16px;

            .right_sub {
              .group_grid {
                gap: 16px;

                & > div {
                  max-width: 260px;
                }

                .name {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 22px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
#box_enter_api_key .OtpModal {
  .form-input {
    padding: 8px 16px;

    input {
      font-weight: 300;

      @include mobile {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

#confirmationModal {
  &.custom_modal_del_api {
    .modal-dialog {
      .modal-content {
        .modal-body {
          // .title_confirm_del {
          //   margin-top: 30px;
          //   margin-bottom: 30px;
          //   line-height: 20px;
          //   font-size: 16px;
          //   color: #1c1c1c;
          // }

          .content_text {
            font-weight: 700;
            font-size: 18px;
            // width: auto;
            // max-width: 360px !important;
            // line-height: 25px !important;
            // min-height: auto !important;
            // margin: 0 auto;
            // margin-bottom: 30px !important;
            // word-break: break-all;
          }
        }
      }
    }
  }
}
</style>
