<template>
  <div class="symbolDetail">
    <div class="detail_menu">
      <div class="detail_menu_con">
        <div class="scrollBlock">
          <ul>
            <li
              class="menu_item"
              v-for="(item, index) in $t('symbol_detail_left')"
              :key="index"
              :class="item.id == targetId ? 'active' : ''"
              :data-id="item.id"
              @click="getItemId"
            >
              {{ item.title_txt }}
              <!-- <a href="javascript:void(0);" :data-id="item.id" @click="getItemId">{{ item.title_txt }}</a> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="detail_menu_mobile">
      <div class="detail_menu_mobile--title">Select</div>
      <div class="detail_menu_mobile--wrapper">
        <select-box
          v-model="targetId"
          :isHideCloseBtn="true"
          :isMenu="true"
          :usingIdProps="true"
          :options="getListCoin()"
          :propertyOption="'title_txt'"
        ></select-box>
      </div>
    </div>
    <div class="detail_article">
      <div class="article_tit">
        {{ $t("symbol_detail_article." + targetId + ".article_tit") }}
      </div>
      <div class="article_con">
        <div class="article_detail_i">
          {{ $t("symbol_detail_introduction") }}
        </div>
        <div
          class="article_detail_c"
          v-html="$t(`symbol_detail_article.${targetId}.article_detail`)"
        />
        <div class="article_link">
          <div>
            <strong>{{ $t("symbol_detail_org") }}</strong>
            <a
              :href="
                $t('symbol_detail_article.' + targetId + '.article_link[0]')
              "
              target="_blank"
              >{{
                $t("symbol_detail_article." + targetId + ".article_link[0]")
              }}</a
            >
          </div>
          <div
            v-if="$t('symbol_detail_article.' + targetId + '.article_link[1]')"
          >
            <strong>{{ $t("symbol_detail_block") }}</strong>
            <a
              :href="
                $t('symbol_detail_article.' + targetId + '.article_link[1]')
              "
              target="_blank"
              >{{
                $t("symbol_detail_article." + targetId + ".article_link[1]")
              }}</a
            >
          </div>
          <div
            v-if="$t('symbol_detail_article.' + targetId + '.article_link[2]')"
          >
            <strong>{{ $t("symbol_detail_book") }}</strong>
            <a
              :href="
                $t('symbol_detail_article.' + targetId + '.article_link[2]')
              "
              target="_blank"
              >{{
                $t("symbol_detail_article." + targetId + ".article_link[2]")
              }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import vuescroll from "vuescroll";
import SelectBox from "@/components/shared_components/common/SelectBox";

export default {
  data() {
    return {
      targetId: "btc",
      scrollOption: {
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: "#02273b",
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: false,
          size: "6px",
        },
      },
    };
  },
  components: {
    vuescroll,
    SelectBox,
  },
  computed: {
    // getItemId() {
    //     console.log(this.$route.path);
    // }
  },
  methods: {
    getListCoin() {
      return this.$t("symbol_detail_left");
    },
    getItemId(e) {
      this.targetId = e.target.dataset.id;
    },
  },
  mounted() {
    document.title =
      this.$t("coin_information_btn_link") + ` – ${this.tabTitle}`;
  },
};
</script>
<style lang="scss" scope>
@import "@/assets/sass/common.scss";
$min-w: 1200px;
$max-w: 80%;
$c_white: rgba(255, 255, 255, 0.65);
$shadow: rgba(0, 19, 54, 0.1);

.symbolDetail {
  height: fit-content;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  box-sizing: border-box;

  .detail_menu {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 13px 0px;
    gap: 10px;
    width: 20%;

    @include tablet {
      width: 25%;
    }

    min-height: 455px;
    background: $color-white;
    box-shadow: 0px 4px 30px $shadow;

    .detail_menu_con {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 100%;

      .scrollBlock {
        width: 100%;
      }

      .menu_item {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 24px;
        gap: 10px;
        width: 100%;
        min-height: 40px;
        @include text-font($helvetica, $text-secondary, 16px, 400, 24px);
        // @include tablet() {
        //   font-size: 14px;
        //   padding: 10px;
        // }
        cursor: pointer;

        &.active {
          background: $bg-main2;
          border-right: solid 4px $bg-main1;
          @include text-font($helvetica, $text-main, 16px, 400, 24px);
          // @include tablet() {
          //   font-size: 14px;
          // }
        }
      }
    }
  }

  .detail_article {
    padding: 26px 33px;
    width: 80%;
    display: flex;
    flex-direction: column;

    .article_tit {
      @include text-font($helvetica, $text-main, 24px, 700, 40px);
      cursor: default;
      margin-bottom: 12px;
    }

    .article_con {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 14px 25px 20px;
      gap: 8px;
      width: 100%;
      height: 100%;
      background: $color-white;
      border-radius: 10px;
      margin: 0 auto;
      word-break: break-word;

      .article_detail_i {
        @include text-font($helvetica, $text-main, 16px, 700, 24px);
        cursor: default;
      }

      .article_detail_c {
        @include text-font($helvetica, $text-secondary, 16px, 300, 24px);
        cursor: default;
      }

      .article_link {
        display: flex;
        flex-direction: column;
        gap: 10px;

        strong {
          @include text-font($helvetica, $text-main, 16px, 700, 24px);
          cursor: default;
        }

        a {
          word-break: break-word;
          @include text-font($helvetica, $color-blue, 16px, 400, 24px);
          // &:hover {
          //     color: $color-orange;
          // }
          // &:focus {
          //     outline: none;
          // }
        }
      }
    }
  }

  .mt_10 {
    margin-bottom: 10px;
  }

  .detail_menu_mobile {
    display: none;
    padding: 16px 16px 0;

    &--wrapper {
      background-color: $color-white;
      border-radius: 12px;

      > .sc_search_select .group_search_select .button_drop_search {
        span {
          color: $text-main;
          font-size: 14px;
          line-height: 22px;
          font-weight: 300;
        }

        img {
          width: 18px;
          height: 18px;
        }
      }
    }

    &--title {
      margin-bottom: 10px;
      @include text-font($helvetica, $dark-1, 18px, 700, 23px);
    }
  }

  @include mobile {
    flex-direction: column;

    .detail_menu {
      display: none;
    }

    .detail_menu_mobile {
      display: block;

      .detail_menu_mobile--title {
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        margin-bottom: 8px;
        line-height: 22px;
        $color: $text-secondary;
      }
    }

    .detail_article {
      width: calc(100vw - 32px);
      min-height: 360px;
      border-radius: 10px;
      padding: 24px 16px;
      margin: 16px 16px 60px;
      background-color: $color-white;

      .article_tit {
        display: none;
        font-size: 18px;
        line-height: 23px;
      }

      .article_con {
        background-color: unset;
        padding: unset;
        color: $text-main;
        gap: 4px;

        .article_detail_i {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }

        .article_detail_c {
          font-size: 14px;
          font-weight: 300;
          line-height: 22px;
          color: $text-secondary;
        }

        .article_link {
          gap: 4px;

          strong,
          a {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }

          a {
            font-weight: 300;
            line-height: 22px;
          }
        }
      }
    }
  }
}

:deep() {
  .sc_search_select {
    .group_search_select {
      .button_drop_search {
        span {
          max-width: 70% !important;
        }
      }
    }
  }
}
</style>
