<template>
  <div id="address">
    <div>
      <div class="heading">
        <div class="tit_address title-medium-small">
          {{ $t('address.add_address') }}
        </div>
        <div class="group_reported">
          <span class="switch" @click="toggleWhiteList()">
            <input v-model="enableWhiteList" type="checkbox" />
            <span><i></i></span>
          </span>
          <span class="txt_reported">{{
            enableWhiteList ? $t('address.white_list_on') : $t('address.white_list_off')
          }}</span>
          <div class="tooltip-wrapper">
            <!-- <img src="/images/info-tooltip.svg" v-tooltip.bottom="{content:`${$t('account.tooltip1')}<br><br> ${$t('account.tooltip2')}`, trigger: 'hover click focus'}" alt="tooltip" /> -->
            <span class="help-box">
              <img src="/images/info-tooltip.svg" alt="" height="18" width="18" />
              <div class="box-tooltip">
                <div class="bubble top" id="still-not-receive">
                  <div>{{ $t('account.tooltip1') }}</div>
                  <br />
                  <div>{{ $t('account.tooltip2') }}</div>
                </div>
              </div>
            </span>
          </div>
          <!-- <img src="/images/info-tooltip.svg" v-tooltip="{content: `${$t('address.tooltip1')}<br><br> ${$t('address.tooltip2')}`, trigger: 'hover click focus'}" alt="tooltip" /> -->
        </div>
      </div>
      <div class="content_body_address">
        <!-- Begin Form Add Address -->
        <div class="content_left_address">
          <div class="bg_left_address">
            <div class="formInput">
              <div class="name_inp_gr">{{ $t('address.select_coin') }}</div>
              <div>
                <coin-select-box
                  @focus="resetErrors"
                  v-model="selectedCoin"
                  :placeholder="$t('address.select_coin')"
                  :options="coins"
                  name="coin"
                  :class="{ 'field-error': errors.has('coin_address') }"
                />
              </div>
              <span v-show="errors.has('coin_address')" class="invalid-feedback"
                >{{ errors.first('coin_address') }}
              </span>
            </div>

            <div class="formInput input-group">
              <div class="name_inp_gr">{{ $t('address.label') }}</div>
              <input
                :maxlength="maxLength20"
                type="text"
                v-model="params.address_name"
                class="form-input name-address"
                :class="{ error: errors.has('name_address') }"
                @focus="resetErrors"
              />
              <span v-show="errors.has('name_address')" class="invalid-feedback">{{
                errors.first('name_address')
              }}</span>
              <p class="labelHint">{{ $t('address.label_hint') }}</p>
            </div>

            <div class="formInput input-group">
              <div class="name_inp_gr">{{ $t('address.address') }}</div>
              <textarea
                :maxlength="maxLengthInput"
                v-model="params.wallet_address"
                class="form-input"
                :class="{ error: errors.has('wallet_address') }"
                rows="6"
                @focus="resetErrors"
              ></textarea>
              <!--
              <input type="text" v-model="params.wallet_address" class="form-input" :class="{'error': errors.has('wallet_address')}">
              -->
              <span v-show="errors.has('wallet_address')" class="invalid-feedback wid100">{{
                errors.first('wallet_address')
              }}</span>
            </div>

            <div class="formInput input-group" v-if="coinName === 'xrp' || coinName === 'eos'">
              <input
                :maxlength="maxLengthInput"
                type="text"
                v-model="params.wallet_sub_address"
                class="form-input tag-xrp"
                :placeholder="$t('address.tag')"
                :class="{ error: errors.has('wallet_sub_address') }"
                @focus="resetErrors"
              />
              <span v-show="errors.has('wallet_sub_address')" class="invalid-feedback">{{
                errors.first('wallet_sub_address')
              }}</span>
            </div>

            <div class="formInput">
              <!-- <label class="checkbox_address">
                <input type="checkbox" v-model="params.white_list" />
                <span></span>
                {{ $t('address.add_whitelist') }}
              </label> -->
              <div class="check-wrap">
                <div class="checked-group">
                  <div class="checkbox-input">
                    <input type="checkbox" id="checkboxAddWhiteList" v-model="params.white_list" />
                    <label for="checkboxAddWhiteList"></label>
                  </div>
                </div>
                <span>{{ $t('address.add_whitelist') }}</span>
              </div>
            </div>

            <div class="formInput btn-wrapper">
              <button class="btnSubmit_address" v-on:click="onSubmitForm">
                {{ $t('address.submit') }}
              </button>
            </div>
          </div>
        </div>
        <!-- End Form Add Address -->

        <!-- Begin Table Addresss -->
        <div class="content_right_address">
          <list-address
            ref="listAddress"
            :enableOtp="enableOtp"
            :enableWhiteList="enableWhiteList"
          ></list-address>
        </div>
        <!-- End Table Addresss -->

        <div class="mg_bot_address"></div>
      </div>
    </div>

    <otp-modal
      name="otp_modal_whitelist"
      :submit="updateModeWhitelist"
      :params="getParamsUpdateModeWhitelist()"
      :is-disable-button="isDisableButtonWhiteList"
      :contact_email="siteSetting.contact_email"
    />
    <otp-modal
      name="otp_modal_add_address"
      :submit="createAddress"
      :params="getParamsCreateAddress()"
      :is-disable-button="isDisableButtonAddress"
      :contact_email="siteSetting.contact_email"
    />
  </div>
</template>

<script>
import rf from '@/request/RequestFactory';
import Multiselect from 'vue-multiselect';
import MasterdataUtils from '@/common/MasterdataUtils';
import Utils from '@/common/Utils';
import OtpModal from '@/components/shared_components/funds/common/OtpModal';
import CoinSelectBox from '@/components/shared_components/common/CoinSelectBox';
import ListAddress from './ListAddress';
import COMMON_CONST from '@/common/Const';
import BUSDValidator from 'neka-wallet-address-validator';
import WAValidator from 'wallet-validator-testnet';

export default {
  components: {
    Multiselect,
    OtpModal,
    CoinSelectBox,
    ListAddress,
  },
  data() {
    return {
      coins: [],
      selectedCoin: undefined,
      params: this.initDefaultParams(),
      enableWhiteList: undefined,
      settingWhitelist: false,
      showTip: false,
      enableOtp: false,

      listAddress: [],
      maxLengthInput: COMMON_CONST.MAX_LENGTH_INPUT,
      maxLength20: COMMON_CONST.MAX_LENGTH_20,
      isDisableButtonWhiteList: false,
      isDisableButtonAddress: false,
      siteSetting: {},
      coinImages: [],
    };
  },
  computed: {
    coinName() {
      if (_.isEmpty(this.selectedCoin) || _.isEmpty(this.selectedCoin.coinName)) {
        return '';
      }
      return this.selectedCoin.coinName;
    },
  },
  watch: {
    selectedCoin: function () {
      this.resetErrors();
      this.params = this.initDefaultParams();
    },
    'params.address_name'(val) {
      if (val.charAt(0) === ' ') {
        this.params.address_name = val.slice(1);
      }
    },
    'params.wallet_address'(val) {
      if (val.charAt(0) === ' ') {
        this.params.wallet_address = val.slice(1);
      }
    },
  },
  methods: {
    findSelectedCoin() {
      const coinName = this.$route.query.coin || undefined;
      if (coinName) {
        this.selectedCoin = _.find(this.coins, (coin) => {
          return coin.coinName === coinName;
        });
      }
    },
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    toggleWhiteList() {
      const message = !this.enableWhiteList
        ? this.$i18n.t('address.turn_on_mode_whitelist')
        : this.$i18n.t('address.turn_off_mode_whitelist');
      this.updateSettingWhitelist(message);
      this.$nextTick(() => {
        this.enableWhiteList = this.settingWhitelist;
      });
    },

    updateSettingWhitelist(message) {
      let _self = this;
      ConfirmationModal.show({
        class: 'modal-update-whitelist',
        type: 'whiteList',
        title: '',
        content: message,
        btnCancelLabel: this.$i18n.t('common.action.no'),
        btnConfirmLabel: this.$i18n.t('common.action.yes'),
        onConfirm: () => {
          if (this.enableOtp) {
            CommonModal.show('otp_modal_whitelist');
          } else {
            const params = this.getParamsUpdateModeWhitelist();
            this.updateModeWhitelist(params);
          }
        },
        onCancel: () => {
          $('.buton_reported input').prop('checked', _self.enableWhiteList);
        },
      });
    },

    updateModeWhitelist(params) {
      this.isDisableButtonWhiteList = true;
      rf.getRequest('UserRequest')
        .activeWhitelist(params)
        .then((response) => {
          CommonModal.hide('otp_modal_whitelist');
          this.settingWhitelist = this.enableWhiteList = response.data.whitelist;
          this.isDisableButtonWhiteList = false;
          response.data.whitelist
            ? Message.success(this.$i18n.t('address.white_list_on'))
            : Message.success(this.$i18n.t('address.white_list_off'));
        })
        .catch((error) => {
          this.isDisableButtonWhiteList = false;
          if (!error.response) {
            Message.error(this.$i18n.t('common.message.network_error'));
          } else {
            this.onError(error.response.data.errors || []);
          }
        });
    },

    getParamsUpdateModeWhitelist() {
      return {
        active: !this.settingWhitelist,
      };
    },

    onSubmitForm() {
      if (!this.validate()) {
        return false;
      }
      try {
        let valid = false;
        if (this.coinName === 'busd') {
          valid = BUSDValidator.validate(this.params.wallet_address, this.coinName, 'both');
        } else {
          valid = WAValidator.validate(this.params.wallet_address, this.coinName, 'both');
        }

        if (!valid) {
          this.errors.add('wallet_address', this.$t('address.errors.blockchain_address'));
          return false;
        }
      } catch (error) {
        Message.error(this.$t('address.errors.wallet_address'), {}, { position: 'top_right' });
        return false;
      }

      if (this.enableOtp) {
        CommonModal.show('otp_modal_add_address');
      } else {
        const params = this.getParamsCreateAddress();
        this.createAddress(params);
      }
    },
    validate() {
      this.errors.clear();
      if (this.params.white_list && !this.enableWhiteList) {
        Message.error(this.$i18n.t('address.turn_on_whitelist'));
        return false;
      }

      if (_.isEmpty(this.coinName)) {
        this.$validator.errors.add({
          field: 'coin_address',
          msg: this.$i18n.t('address.errors.coin_required'),
        });
      }
      if (_.isEmpty(this.params.address_name.trim())) {
        this.$validator.errors.add({
          field: 'name_address',
          msg: this.$i18n.t('address.errors.label_required'),
        });
      }
      if (_.size(this.params.address_name) > 20) {
        this.$validator.errors.add({
          field: 'name_address',
          msg: this.$i18n.t('address.errors.max_length', {
            field: 'label',
            amount: 20,
          }),
        });
      }
      if (_.isEmpty(this.params.wallet_address.trim())) {
        this.$validator.errors.add({
          field: 'wallet_address',
          msg: this.$i18n.t('address.errors.address_required'),
        });
      }
      if (
        !Utils.isWalletAddress(
          this.coinName,
          this.params.wallet_address,
          this.params.wallet_sub_address
        )
      ) {
        this.$validator.errors.add({
          field: 'wallet_address',
          msg: this.$i18n.t('address.errors.blockchain_address'),
        });
      }

      if (
        _.isEmpty(this.params.wallet_sub_address.trim()) &&
        (this.coinName === 'xrp' || this.coinName === 'eos')
      ) {
        // this.$validator.errors.add({
        //   field: 'wallet_sub_address',
        //   msg: this.$i18n.t('address.errors.tag_required'),
        // });
      }

      const listAddress = this.$refs.listAddress.getAddresses() || [];
      const isExistWithdraw = _.find(listAddress, (withdrawAddress) => {
        return withdrawAddress.wallet_address === this.params.blockchain_address;
      });
      if (isExistWithdraw) {
        this.$validator.errors.add({
          field: 'wallet_address',
          msg: this.$i18n.t('address.errors.exist_withdraw_address'),
        });
      }

      if (this.errors.any()) {
        return false;
      }
      return true;
    },

    getParamsCreateAddress() {
      return {
        coin: this.coinName,
        name: this.params.address_name,
        wallet_address: this.params.wallet_address,
        wallet_sub_address: this.params.wallet_sub_address,
        white_list: this.params.white_list,
      };
    },

    createAddress(params) {
      this.isDisableButtonAddress = true;
      return rf
        .getRequest('AddressManagerRequest')
        .createANewOne(params)
        .then((res) => {
          this.onCreateAddress();
          this.isDisableButtonAddress = false;
        })
        .catch((error) => {
          this.isDisableButtonAddress = false;
          if (!error.response) {
            Message.error(this.$i18n.t('common.message.network_error'));
          } else {
            if (error.response && error.response.data.errors) {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.errors.add({
                  field: key,
                  msg: this.$t(error.response.data.errors[key][0]),
                });
              });
              if (!error.response.data.errors.otp) {
                CommonModal.hide('otp_modal_add_address');
              }
            }
            if (!this.errors.any()) {
              this.errors.add('error', this.$t('server.logs'));
            }
          }
        });
    },

    onCreateAddress() {
      this.$refs.listAddress.refresh();

      this.params = this.initDefaultParams();
      CommonModal.hide('otp_modal_add_address');
      Message.success(this.$i18n.t('address.add_success'));
    },

    onError(errors) {
      this.errors.clear();
      if (!errors.otp) {
        setTimeout(() => {
          CommonModal.hide('otp_modal_add_address');
          _.forEach(errors, (messages, field) => {
            _.forEach(messages, (message) => {
              this.$validator.errors.add({ field, msg: message });
            });
          });
        }, 1000);
      } else {
        this.$validator.errors.add({ field: 'otp', msg: errors['otp'][0] });
      }
    },

    initDefaultParams() {
      return {
        address_name: '',
        white_list: 0,
        wallet_address: '',
        wallet_sub_address: '',
      };
    },

    getSocketEventHandlers() {
      return {
        OtpUpdated: this.getSettingEnableOTP,
      };
    },

    getSettingEnableOTP() {
      rf.getRequest('UserRequest')
        .getSecuritySettings()
        .then((res) => {
          this.enableOtp = res.data.otp_verified === 1;
        });
    },

    getUserSettings() {
      rf.getRequest('UserRequest')
        .getUserSettings()
        .then((res) => {
          const setting =
            _.find(res.data, (item) => {
              return item.key === 'whitelist';
            }) || {};
          this.settingWhitelist = this.enableWhiteList = !!parseInt(setting.value);
        });
    },

    getCoinList() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((res) => {
          let coinCodes = window._.flatMap(res.coins_confirmation, function (value) {
            return [value.coin];
          });

          coinCodes = window._.uniq(coinCodes);

          let countCoins = coinCodes.length;

          const coinNames = {};
          const coinImages = {};

          _.forEach(res.coins, (item, key) => {
            coinNames[item.coin] = item.name;
            coinImages[item.coin] = item.icon_image;
          });

          for (let i = 0; i < countCoins; i++) {
            let coinCode = coinCodes[i];
            let coinInfo = window._.findLast(res.coins, (item) => item.coin == coinCode);

            if (!coinInfo || coinCode == 'idr') {
              let coin = window._.findLast(res.coin_settings, (item) => item.coin == coinCode);
              if (!coin) {
                coin = {
                  coin: coinCode,
                  name: this.$i18n.t(`currency.${coinCode}.fullname`),
                };
              }
              if (!coin.name) {
                coin.name = this.$i18n.t(`currency.${coinCode}.fullname`);
              }

              coinInfo = coin;
            } else {
              this.coins.push({
                coinName: coinCode,
                shortNameCurrency: coinCode.toUpperCase(),
                fullNameCurrency: coinInfo.name,
                iconCurrency: coinImages[coinCode],
              });
            }
          }

          this.findSelectedCoin();
        });
    },

    init() {
      this.getSettingEnableOTP();
      this.getUserSettings();
      this.getCoinList();
      this.getMasterData();
    },
    getMasterData() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((data) => {
          const masterdata = data;
          window._.each(masterdata.settings, (item) => {
            this.siteSetting[item.key] = item.value;
          });
        });
    },
  },
  mounted() {
    rf.getRequest('MasterdataRequest')
      .getAll()
      .then((data) => {
        _.forEach(data.coins, (item, key) => {
          this.coinImages[item.coin] = item.icon_image;
        });
        this.coinImages['idr'] = `/images/color_coins/usd.png`;
      });
    document.title = this.$t('menu.address') + ` – ${this.tabTitle}`;
    this.init();
  },
};
</script>

<style lang="scss" scoped>
#address {
  .multiselect__tags .multiselect__input {
    border: none !important;
  }

  .multiselect__input {
    height: 35px;
  }

  .button_drop_search {
    background: transparent;
    border-radius: 10px;
    padding: 10px 16px;

    &:hover {
      border-color: $color-green;

      i {
        color: $color-green;
      }
    }
  }

  .toolbarTable {
    background-color: $color-white;
    min-height: 465px;
    border-radius: 10px;
    margin-top: 20px !important;
  }

  .datatable {
    padding: 23px 28px !important;

    .tableContainer {
      table {
        background-color: $color-white;
        border: 0px;

        thead {
          background-color: $color-white;
          border: 0px;

          tr {
            padding: 0 25px;

            th {
              padding: 0px 0px 10px;
              line-height: 25px;
              height: 50px;
              font-size: $font-root;
              color: $dark-3;
            }
          }
        }

        tbody {
          tr {
            width: 100%;
            height: auto;
            padding: 0 25px;

            td {
              text-align: left;
              font-size: $font-root;
              color: $color-grey-dark;
              padding: 10px 0px;
              line-height: 20px;
            }
          }

          .empty-noti {
            height: 345px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.form-input {
  background: transparent;
  border-radius: 12px;
  border: 1px solid $grey-1;
  transition: all 0.3s;
  color: $dark-1;

  &:hover,
  &:active,
  &:focus {
    border-color: $dark-3;
  }

  &.name-address {
    height: 44px;
    border-radius: 10px;
  }
}

textarea.form-input {
  height: inherit;
  resize: none;
  border-radius: 10px;
}

.input-group {
  margin: 0px;
}

#address {
  height: 100%;
  width: 100%;
  max-width: 1320px;
  padding: 24px 20px;
  margin: auto;

  @include mobile {
    padding: 16px;
  }

  .title-medium-small {
    color: rgba($text-main2, 0.87);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;

    @include mobile {
      font-size: 18px;
      line-height: 32px;
    }
  }

  .content_body_address {
    padding-bottom: 45px;
    display: flex;

    .mg_bot_address {
      clear: both;
      display: block;
      // height: 26px;
    }

    .content_left_address {
      width: 443px;
      margin-right: 24px;

      @include mobile {
        margin-right: 0px;
      }

      .bg_left_address {
        min-height: 395px;
        background-color: $color-white;
        padding: 24px;
        border-radius: 10px;

        .btn-wrapper {
          text-align: right;
        }

        .formInput {
          margin-bottom: 20px;

          &:last-child {
            margin-bottom: 0px;
          }

          :deep(.sc_search_select) {
            .group_search_select {
              .button_drop_search {
                height: 52px;
                border-radius: 10px;

                @include mobile {
                  height: 40px;
                }

                .button_drop_search {
                  .title-coin {
                    span {
                      color: $text-main;
                    }
                  }
                }
              }

              .box_list_search_select {
                .code_coin_slect {
                  font-size: 16px;

                  @include mobile {
                    font-size: 14px;
                  }
                }

                .full_name_coin_select {
                  font-size: 16px;

                  @include mobile {
                    font-size: 14px;
                  }
                }
              }
            }
          }

          .btnSubmit_address {
            margin-top: 32px;
            @include btn-common(contained, lg);
            font-size: 16px;
            line-height: 24px;
            padding: 9px 32px;

            @include mobile {
              margin-top: 25px;
            }
          }

          .is-error {
            color: $color-yellow-pale;
            font-size: $font-root;
            line-height: 25px;
            margin-top: 14px;
            margin-bottom: 10px;
          }

          .labelForm {
            color: $color-grey-dusty;
            font-size: $font-small;
            line-height: 16px;
            margin-top: 10px;
            margin-bottom: 0px;
            text-align: right;
          }

          .tag-xrp {
            padding-left: 10px;
            padding-right: 10px;
          }

          .checkbox_address {
            cursor: pointer;
            line-height: 27px;
            font-size: 16px;
            font-weight: 400;
            color: $dark-1;
            margin-top: 18px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            input {
              display: none;
            }

            span {
              content: '';
              display: inline-block;
              border: 1px solid $dark-1;
              width: 20px;
              height: 20px;
              top: 0;
              left: 0;
              border-radius: 5px;
              margin-right: 10px;
              position: relative;
              background-color: transparent;
            }

            input:checked + span:after {
              opacity: 0.9;
              content: '';
              position: absolute;
              width: 13px;
              height: 7px;
              background: transparent;
              top: 4px;
              left: 3px;
              border: 2px solid $primary-2;
              border-top: none;
              border-right: none;
              transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
            }
          }
        }
      }
    }

    .content_right_address {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @include mobile {
      display: block;
    }
  }

  @include mobile {
    .content_body_address {
      flex-direction: column;

      .content_left_address,
      .content_right_address {
        width: 100%;
      }

      .content_left_address {
        padding-right: unset;

        .tit_address {
          font-size: 20px;
          line-height: 25px;
          margin-bottom: 15px;
        }

        .bg_left_address {
          padding: 24px 16px;

          .formInput {
            .btnSubmit_address {
              width: 100%;
              margin-bottom: 0;
            }
          }
        }

        .labelHint {
          font-size: 12px;
          line-height: 15px;
        }
      }

      .content_right_address {
        margin-top: 21px;
      }
    }
  }

  @include tablet {
    .content_body_address {
      flex-direction: column;

      .content_left_address,
      .content_right_address {
        width: 100%;
      }

      .content_left_address {
        padding-right: unset;

        .tit_address {
          font-size: 20px;
          line-height: 25px;
          margin-bottom: 15px;
        }

        .bg_left_address {
          padding: 14px 15px;

          .formInput {
            .btnSubmit_address {
              width: 100%;
              margin-bottom: 0;
            }
          }
        }

        .labelHint {
          font-size: 12px;
          line-height: 15px;
        }
      }

      .content_right_address {
        margin-top: 21px;
      }
    }
  }
}

.name_inp_gr {
  color: $text-secondary;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  margin-bottom: 8px;

  @include mobile {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
}

.group_reported {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  @include mobile {
    margin-top: 12px;
    justify-content: flex-start;
  }

  .txt_reported {
    color: $text-main;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    @include mobile {
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
    }
  }

  .icon_reported {
    position: relative;

    img {
      width: 20px;
      height: 20px;
    }

    .tiptool {
      font-family: $font-family-roboto;
      width: 200px;
      height: 200px;
      background-color: $color-white;
      z-index: 1000;
      border: 1px solid #c0c0c0;
      position: absolute;
      border-radius: 15px;
      color: $color-grey-dark;
      font-weight: normal;
      font-size: $font-small;
      -webkit-box-shadow: 1px 1px 1px 1px #c0c0c0;
      box-shadow: 1px 1px 1px 1px #c0c0c0;
      line-height: 20px;
      padding-top: 10px;
      right: 0px;
      left: auto;
      top: 100%;
      overflow: hidden;

      ul {
        list-style-type: none;
      }
    }

    ul {
      box-shadow: 4px 5px 4px #d4c9c9;
      border-radius: 5px;

      li {
        // text-indent: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
      }
    }
  }

  .tooltip-wrapper {
    display: flex;

    .help-box {
      position: relative;

      img {
        cursor: pointer;
      }
    }

    .box-tooltip {
      &::before {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 100%;
        translate: -50%;
        border: 5px solid transparent;
        border-bottom-color: $tool-tip-color;

        @include mobile {
          left: 47%;
        }

        @media screen and (min-width: 768px) and (max-width: 1600px) {
          left: 96%;
        }
      }

      width: 320px;
      position: absolute;
      display: none;
      z-index: 999999;
      text-align: left;
      background: $bg-main1;
      left: 50%;
      top: calc(100% + 0.75rem);
      translate: -50%;
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
      border-radius: 6px;

      color: var(--default-white, #fff);
      font-family: Helvetica;
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
      padding: 6px 8px;

      @include mobile {
        translate: -47%;
      }

      @media screen and (min-width: 768px) and (max-width: 1600px) {
        translate: -96%;
      }
    }

    &:hover .box-tooltip {
      display: block;
    }
  }
}

.check-wrap {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    color: $text-main;

    @include mobile {
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
    }
  }
}

.labelHint {
  margin-bottom: 20px;
  margin-top: 8px;
  color: $text-main-blur;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
}

:deep #otp_modal_add_address .modal-dialog {
  width: 100%;
}
</style>
<style lang="scss">
#confirmationModal.modal-update-whitelist {
  .modal-dialog {
    width: 414px;
    max-width: calc(100vw - 32px);
    padding: 0;

    .modal-content .close {
      display: none;
    }
  }
}
</style>
