<template>
  <div>
    <modal
      :name="name"
      id="registered-modal"
      :enableClose="false"
      :hasModalFooter="false"
    >
      <template slot="body">
        <div class="icon"><span class="icon-guarantee1"></span></div>
        <div class="message" v-html="message"></div>
        <button class="btn-default" @click="hideModal()">
          {{ $t("common.action.ok") }}
        </button>
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from "@/components/shared_components/common/Modal";

export default {
  components: {
    Modal,
  },

  props: {
    name: { type: String, default: "succeed-modal" },
    message: { type: String, default: "" },
  },

  methods: {
    hideModal() {
      window.CommonModal.hide(this.name);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/variables.scss";

#registered-modal {
  .modal-dialog {
    width: 440px;

    .modal-content {
      padding: 30px;
      background-color: $color-blue-cerulean-light;
      color: $color-white;
      text-align: center;

      .icon {
        .icon-guarantee1 {
          font-size: 50px;
          color: $color-corn-pale;
        }
      }

      .message {
        padding: 30px 0;
        font-size: $font-medium-bigger;
      }

      .btn-default {
        width: auto;
        background: $color-corn-pale;
        padding: 2px 45px;
        font-size: $font-root;
        color: $color-white;
      }
    }
  }
}
</style>
