<template>
  <div class="chart-container" id="exchange-chart">
    <fullscreen @change="fullscreenChange" ref="fullscreen">
      <div class="chart-option" :class="chartActive === 2 ? 'no-time-option' : ''">
        <div
          class="time-option"
          v-show="chartActive === 1"
          v-click-outside="
            () => {
              showFavoredList = false;
            }
          "
        >
          <template v-if="multipleChart">
            <div class="select-box-mul-chart">
              <select-box
                class="time"
                v-model="selectedPair"
                :placeholder="$t('common.no_select')"
                :options="pairs"
                :propertyOption="'id'"
                isReturnObject
              />
            </div>

            <div class="select-box-mul-chart">
              <select-box
                class="time"
                id="search_select_dt"
                v-model="selectedTime"
                :placeholder="$t('exchange.basic.label_minute')"
                :options="times()"
                :propertyOption="'id'"
              />
            </div>
          </template>
          <template v-else>
            <div>
              <select-box
                class="time"
                v-model="selectedMinute"
                :placeholder="$t('exchange.basic.label_minute')"
                :options="minutes()"
                :classFocused="{ 'active-time': timeActive === 1 }"
                :propertyOption="'id'"
              />
            </div>
            <div>
              <select-box
                class="time"
                v-model="selectedHour"
                :placeholder="$t('exchange.basic.label_hour')"
                :options="hours()"
                :classFocused="{ 'active-time': timeActive === 2 }"
                :propertyOption="'id'"
              />
            </div>
            <div
              class="time div-time"
              :class="{ 'active-time': timeActive == 3 }"
              @click="onChangeResolution('D')"
            >
              {{ $t('exchange.basic.time.day') }}
            </div>
            <div
              class="time div-time"
              :class="{ 'active-time': timeActive == 4 }"
              @click="onChangeResolution('W')"
            >
              {{ $t('exchange.basic.time.week') }}
            </div>
            <!-- API load data for chart does't support -->
            <div
              class="time div-time"
              :class="{ 'active-time': timeActive == 5 }"
              @click="onChangeResolution('M')"
            >
              {{ $t('exchange.basic.time.month') }}
            </div>
          </template>

          <div class="time div-time button-icon" v-on:click.stop="showIndicatorDialog()">
            <img src="/images/spot_exchange/chart-up.svg" alt="" />
          </div>
          <div class="time div-time button-icon" v-on:click.stop="showChartPropertiesDialog()">
            <img src="/images/spot_exchange/setting.svg" alt="" />
          </div>
          <div
            class="time div-time group_favered button-icon"
            :class="{ active: showFavoredList == true }"
            @click="onshowFavoredList()"
          >
            <div class="btn btn-favored">
              <img
                src="/images/spot_exchange/candle-stick.svg"
                class="icon"
                alt=""
                v-if="activeFavoredItem === 2"
              />
              <span
                v-else
                class="icon"
                :class="{
                  // 'icon-heikin-ashi2': activeFavoredItem === 2,
                  'icon-bar': activeFavoredItem === 1,
                  'icon-heikin-ashi3': activeFavoredItem === 3,
                  'icon-heikin-ashi': activeFavoredItem === 4,
                  'icon-line2': activeFavoredItem === 5,
                  'icon-line3': activeFavoredItem === 6,
                }"
              ></span>
              <i class="icon-arrow1 icon_drop"></i>
            </div>
            <div class="box_favored_list clearfix" v-show="showFavoredList == true">
              <ul class="favored_list">
                <li class="item" @click="(activeFavoredItem = 1), showSetBarStyle(0)">
                  <i class="icon icon-bar"></i>
                  <span class="name">{{ $t('barstyle_chart.bars') }}</span>
                </li>
                <li class="item" @click="(activeFavoredItem = 2), showSetBarStyle(1)">
                  <i class="icon icon-heikin-ashi2"></i>
                  <span class="name">{{ $t('barstyle_chart.candles') }}</span>
                </li>
                <li class="item" @click="(activeFavoredItem = 3), showSetBarStyle(9)">
                  <i class="icon icon-heikin-ashi3"></i>
                  <span class="name">{{ $t('barstyle_chart.hollow_candles') }}</span>
                </li>
                <li class="item" @click="(activeFavoredItem = 4), showSetBarStyle(8)">
                  <i class="icon icon-heikin-ashi"></i>
                  <span class="name">{{ $t('barstyle_chart.heikin_ashi') }}</span>
                </li>
                <li class="item" @click="(activeFavoredItem = 5), showSetBarStyle(2)">
                  <i class="icon icon-line2"></i>
                  <span class="name">{{ $t('barstyle_chart.line') }}</span>
                </li>
                <li class="item" @click="(activeFavoredItem = 6), showSetBarStyle(3)">
                  <i class="icon icon-line3"></i>
                  <span class="name">{{ $t('barstyle_chart.area') }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="time div-time button-icon">
            <img
              src="/images/spot_exchange/zoom-out.svg"
              class="icon-screen"
              alt=""
              @click="toggleFullScreen"
              v-if="!fullscreen"
            />
            <span
              v-else
              class="icon-screen"
              @click="toggleFullScreen"
              :class="{
                'icon-exit-full-screen': fullscreen,
              }"
            ></span>
          </div>
          <!-- <div
            class="time div-time button-icon"
            @click.stop="onSaveTrandingChartConfiguration()"
            id="btn-upload-chart"
          >
            <img
              class="icon-upload"
              src="/images/spot_exchange/entypo_arrow-up.svg"
            />
            <span class="tooltip-upload">{{
              $t("barstyle_chart.save_chart")
            }}</span>
          </div> -->
          <!-- <div
            class="time div-time button-icon"
            @click.stop="onReloadTrandingChart()"
            id="btn-download-chart"
          >
            <img
              class="icon-upload"
              src="/images/spot_exchange/entypo_arrow-down.svg"
            />
            <span class="tooltip-download">{{
              $t("barstyle_chart.load_chart")
            }}</span>
          </div> -->
        </div>

        <div class="chart-type">
          <span
            class="icon-close1"
            @click.stop="onCloseChart()"
            v-if="multipleChart && !fullscreen"
          />
          <div class="chart-tab">
            <div
              class="tab-element"
              :class="{ 'active-tab': chartActive == 1 }"
              @click="chartActive = 1"
            >
              {{ $t('exchange.basic.chart.candle') }}
            </div>
            <div
              class="tab-element"
              :class="{ 'active-tab': chartActive == 2 }"
              @click="chartActive = 2"
            >
              {{ $t('exchange.basic.chart.depth') }}
            </div>
          </div>
          <div class="more-option" v-click-outside="hideMoreOption">
            <template v-if="chartActive === 1">
              <img
                :src="
                  showMoreOption ? '/images/icon/close-option.svg' : '/images/icon/more-option.svg'
                "
                alt="close-option.svg"
                @click.stop="showMoreOption = !showMoreOption"
              />
              <ul class="options" v-if="showMoreOption">
                <li v-on:click.stop="showIndicatorDialog()">
                  <img src="/images/spot_exchange/chart-up.svg" alt="" />
                </li>
                <li v-on:click.stop="showChartPropertiesDialog()">
                  <img src="/images/spot_exchange/setting.svg" alt="" />
                </li>
                <li
                  v-on:click.stop="onshowFavoredList()"
                  class="time div-time group_favered"
                  :class="{ active: showFavoredList == true }"
                >
                  <div class="btn btn-favored">
                    <img
                      src="/images/spot_exchange/candle-stick.svg"
                      class="icon"
                      alt=""
                      v-if="activeFavoredItem === 2"
                    />
                    <span
                      v-else
                      class="icon"
                      :class="{
                        // 'icon-heikin-ashi2': activeFavoredItem === 2,
                        'icon-bar': activeFavoredItem === 1,
                        'icon-heikin-ashi3': activeFavoredItem === 3,
                        'icon-heikin-ashi': activeFavoredItem === 4,
                        'icon-line2': activeFavoredItem === 5,
                        'icon-line3': activeFavoredItem === 6,
                      }"
                    ></span>
                    <i class="icon-arrow1 icon_drop"></i>
                  </div>
                  <div class="box_favored_list clearfix" v-show="showFavoredList == true">
                    <ul class="favored_list">
                      <li
                        class="item"
                        @touchend.stop="(activeFavoredItem = 1), showSetBarStyle(0)"
                        @click.stop="(activeFavoredItem = 1), showSetBarStyle(0)"
                      >
                        <i class="icon icon-bar"></i>
                        <span class="name">{{ $t('barstyle_chart.bars') }}</span>
                      </li>
                      <li
                        class="item"
                        @touchend.stop="(activeFavoredItem = 2), showSetBarStyle(1)"
                        @click.stop="(activeFavoredItem = 2), showSetBarStyle(1)"
                      >
                        <i class="icon icon-heikin-ashi2"></i>
                        <span class="name">{{ $t('barstyle_chart.candles') }}</span>
                      </li>
                      <li
                        class="item"
                        @touchend.stop="(activeFavoredItem = 3), showSetBarStyle(9)"
                        @click.stop="(activeFavoredItem = 3), showSetBarStyle(9)"
                      >
                        <i class="icon icon-heikin-ashi3"></i>
                        <span class="name">{{ $t('barstyle_chart.hollow_candles') }}</span>
                      </li>
                      <li
                        class="item"
                        @touchend.stop="(activeFavoredItem = 4), showSetBarStyle(8)"
                        @click.stop="(activeFavoredItem = 4), showSetBarStyle(8)"
                      >
                        <i class="icon icon-heikin-ashi"></i>
                        <span class="name">{{ $t('barstyle_chart.heikin_ashi') }}</span>
                      </li>
                      <li
                        class="item"
                        @touchend.stop="(activeFavoredItem = 5), showSetBarStyle(2)"
                        @click.stop="(activeFavoredItem = 5), showSetBarStyle(2)"
                      >
                        <i class="icon icon-line2"></i>
                        <span class="name">{{ $t('barstyle_chart.line') }}</span>
                      </li>
                      <li
                        class="item"
                        @touchend.stop="(activeFavoredItem = 6), showSetBarStyle(3)"
                        @click.stop="(activeFavoredItem = 6), showSetBarStyle(3)"
                      >
                        <i class="icon icon-line3"></i>
                        <span class="name">{{ $t('barstyle_chart.area') }}</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li v-if="!fullscreen">
                  <img src="/images/spot_exchange/zoom-out.svg" alt="" @click="toggleFullScreen" />
                </li>
                <li
                  v-else
                  @click="toggleFullScreen"
                  :class="{
                    'icon-exit-full-screen': fullscreen,
                  }"
                ></li>
                <li class="chart-tab__mobile">
                  <div
                    class="tab-element"
                    :class="{ 'active-tab': chartActive == 1 }"
                    @click="chartActive = 1"
                  >
                    {{ $t('exchange.basic.chart.candle') }}
                  </div>
                  <div
                    class="tab-element"
                    :class="{ 'active-tab': chartActive == 2 }"
                    @click="chartActive = 2"
                  >
                    {{ $t('exchange.basic.chart.depth') }}
                  </div>
                </li>
              </ul>
            </template>
            <div class="chart-tab__mobile" v-else>
              <div
                class="tab-element"
                :class="{ 'active-tab': chartActive == 1 }"
                @click="chartActive = 1"
              >
                {{ $t('exchange.basic.chart.candle') }}
              </div>
              <div
                class="tab-element"
                :class="{ 'active-tab': chartActive == 2 }"
                @click="chartActive = 2"
              >
                {{ $t('exchange.basic.chart.depth') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-content" :class="{ 'chart-full-screen': fullscreen }">
        <trading-view-chart
          :key="symbol"
          :symbol="symbol"
          :resolution.sync="resolution"
          :min-tick="minTick"
          :responsePairCoinSettings="responsePairCoinSettings"
          multipleChart
          ref="tradingView"
          v-show="chartActive === 1"
        >
        </trading-view-chart>
        <high-charts
          :coin="coin"
          :currency="currency"
          :responsePairCoinSettings="responsePairCoinSettings"
          v-show="chartActive === 2"
          ref="depthChart"
        ></high-charts>
      </div>
    </fullscreen>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory';
import TradingViewChart from './TradingViewChart';
import HighCharts from './HighCharts';
import LocalStorageUtils from '@/common/LocalStorageUtils';
import SelectBox from '@/components/shared_components/common/SelectBox';
import Const from '@/common/Const';

const DAY = 'D';
const WEEK = 'W';
const MONTH = 'M';

export default {
  components: {
    TradingViewChart,
    HighCharts,
    SelectBox,
  },
  props: {
    keyChart: { type: Number, default: 1 },
    multipleChart: { type: Boolean, default: false },
    responsePairCoinSettings: {},
  },
  data() {
    return {
      currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
      coin: this.$route.query.coin || Const.DEFAULT_COIN,

      resolution: '1',
      minTick: 'default',
      masterData: undefined,

      selectedMinute: null,
      selectedHour: '60',
      isSelectMinute: true,
      chartActive: 1,
      timeActive: 2,
      fullscreen: false,

      // It's used for multiple charts.
      pairs: [],
      selectedPair: null,
      selectedTime: '60',

      /*button show Favored List*/
      showFavoredList: false,
      activeFavoredItem: 2,
      showMoreOption: false,
    };
  },
  computed: {
    symbol() {
      return this.toUpperCase(this.coin) + '/' + this.toUpperCase(this.currency);
    },
  },
  watch: {
    fullscreen() {
      this.hideMoreOption();
    },
    chartActive() {
      this.hideMoreOption();
    },
    resolution() {
      this.saveDataLocalStorage();
    },

    selectedMinute(newMinute) {
      if (!newMinute || this.multipleChart) {
        return;
      }
      this.timeActive = 1;
      this.resolution = newMinute;
      this.selectedHour = null;
    },

    selectedHour(newHour) {
      if (!newHour || this.multipleChart) {
        return;
      }
      this.timeActive = 2;
      this.resolution = newHour;
      this.selectedMinute = null;
    },

    selectedTime(newTime) {
      if (!newTime || !this.multipleChart) {
        return;
      }
      this.resolution = newTime;
    },

    $route(to, from) {
      this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
      this.coin = this.$route.query.coin || Const.DEFAULT_COIN;

      this.onChangedPairCurrency();
    },

    selectedPair(newValue) {
      if (!this.multipleChart) {
        return;
      }
      this.coin = newValue.coin;
      this.currency = newValue.currency;
      this.updateMinTick();
      this.onChangedPairInDepthChart();
    },
  },
  methods: {
    hideMoreOption() {
      this.showMoreOption = false;
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
      };
    },

    onSpotSelectedPairMarket(pair) {
      this.currency = pair.currency;
      this.coin = pair.coin;

      let foundPair = window._.find(this.pairs, (item) => {
        return item.coin == this.coin && item.currency == this.currency;
      });

      if (foundPair) {
        this.selectedPair = foundPair;
        this.updateMinTick();
        this.$refs.depthChart.reload(this.coin, this.currency);
      }
    },

    minutes() {
      return window._.map(Const.MINUTES, (item) => {
        const name = this.formatTime(item);
        return { id: `${item}`, name: name };
      });
    },

    hours() {
      return window._.map(Const.HOURS, (item) => {
        const name = this.formatTime(item);
        return { id: `${item}`, name: name };
      });
    },

    times() {
      const arr = [
        { id: 'D', name: this.$i18n.t('exchange.basic.time.one_day') },
        { id: 'W', name: this.$i18n.t('exchange.basic.time.one_week') },
        { id: 'M', name: this.$i18n.t('exchange.basic.time.one_month') },
      ];
      return window._.concat(this.minutes(), this.hours(), arr);
    },

    formatTime(time) {
      time = parseInt(time);
      if (time >= 60) {
        let hours = time / 60;
        return this.$i18n.t(hours > 1 ? 'exchange.basic.time.hours' : 'exchange.basic.time.hour', {
          number: hours,
        });
      }
      return this.$i18n.t(time > 1 ? 'exchange.basic.time.minutes' : 'exchange.basic.time.minute', {
        number: time,
      });
    },

    onChangedPairCurrency() {
      this.updateMinTick();
      this.getDataLocalStorage();

      // update depth chart
      this.onChangedPairInDepthChart();
    },

    onChangeResolution(time) {
      this.timeActive = this.getTimeActive(time);
      this.resolution = time;
      this.selectedMinute = null;
      this.selectedHour = null;
    },

    onCloseChart() {
      ConfirmationModal.show({
        type: 'warning',
        title: '',
        content: this.$i18n.t('exchange.basic.chart.msg_close_chart'),
        btnCancelLabel: this.$i18n.t('common.action.no'),
        btnConfirmLabel: this.$i18n.t('common.action.yes'),
        onConfirm: () => {
          this.$emit('closeChart', this.keyChart);
          this.fullscreen = false;
        },
        onCancel: () => {},
      });
    },

    onChangedPairInDepthChart() {
      if (this.chartActive === 2) {
        this.$refs.depthChart.reload(this.coin, this.currency);
      }
    },

    redrawChart() {
      this.$nextTick(() => {
        // Re-render depth chart
        this.$refs.depthChart.redraw();
      });
    },

    getTimeActive(time) {
      switch (time) {
        case DAY:
          return 3;
        case WEEK:
          return 4;
        case MONTH:
          return 5;
      }
      throw 'Selection tab not correct';
    },

    toggleFullScreen() {
      this.$refs['fullscreen'].toggle();
    },

    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },

    toUpperCase(value) {
      return value.toUpperCase();
    },

    async updateMinTick() {
      if (!this.masterData) {
        this.minTick = 'default';
        return;
      }
      let setting = window._.find(this.masterData.coin_settings, (setting) => {
        return setting.currency == this.currency && setting.coin == this.coin;
      });

      // If setting null, try re-call MasterData from API
      if (!setting) {
        let immediate = true;
        await rf
          .getRequest('MasterdataRequest')
          .getAll(immediate)
          .then((res) => {
            this.masterData = res;
          });
        setting = window._.find(this.masterData.coin_settings, (setting) => {
          return setting.currency == this.currency && setting.coin == this.coin;
        });
      }

      const newMinTick = setting ? 1 / setting.price_precision : 0.001;
      this.minTick = `${newMinTick}`;
    },

    saveDataLocalStorage() {
      const data = {
        selectedMinute: this.selectedMinute,
        selectedHour: this.selectedHour,
        resolution: this.resolution,
        timeActive: this.timeActive,
      };
      LocalStorageUtils.saveItem('trading_chart', data);
    },

    getDataLocalStorage() {
      const data = LocalStorageUtils.getItem('trading_chart');
      if (!data) {
        return;
      }

      this.selectedMinute = data.selectedMinute;
      this.selectedHour = data.selectedHour;
      this.resolution = data.resolution;
      this.timeActive = data.timeActive;
    },

    showIndicatorDialog() {
      this.hideMoreOption();
      if (!window._.isEmpty(this.$refs.tradingView)) {
        this.$refs.tradingView.showIndicatorDialog();
      }
    },

    onSaveTrandingChartConfiguration() {
      this.hideMoreOption();
      if (!window._.isEmpty(this.$refs.tradingView)) {
        this.$refs.tradingView.saveTradingChartConfiguration();
      }
    },

    onReloadTrandingChart() {
      this.hideMoreOption();
      if (!window._.isEmpty(this.$refs.tradingView)) {
        this.$refs.tradingView.reloadTrandingChart();
      }
    },

    showSetBarStyle(type) {
      // this.hideMoreOption();
      if (!window._.isEmpty(this.$refs.tradingView)) {
        this.$refs.tradingView.setBarStyle(type);
      }
    },

    showChartPropertiesDialog() {
      this.hideMoreOption();
      if (!window._.isEmpty(this.$refs.tradingView)) {
        this.$refs.tradingView.showChartPropertiesDialog();
      }
    },

    onshowFavoredList() {
      // this.hideMoreOption();
      this.showFavoredList = !this.showFavoredList;
    },

    initPairs() {
      window._.forEach(this.masterData.coin_settings, (setting) => {
        const item = {
          coin: setting.coin,
          currency: setting.currency,
          id: setting.id,
          name: `<b>${this.toUpperCase(setting.coin)}</b> / ${this.toUpperCase(setting.currency)}`,
        };
        this.pairs.push(item);
      });
      // Set select first
      this.selectedPair = this.pairs[0];
    },
  },
  created() {
    this.resolution = this.selectedHour;
    rf.getRequest('MasterdataRequest')
      .getAll()
      .then((res) => {
        this.masterData = res;
        this.updateMinTick();
        this.initPairs();
      });
    this.getDataLocalStorage();
  },
  props: ['responsePairCoinSettings'],
};
</script>

<style lang="scss" scoped>
$borderColor: $grey-2;
$heightOption: 38px;

#btn-upload-chart {
  position: relative;
  .tooltip-upload {
    position: absolute;
    top: 100%;
    margin-top: 5px;
    z-index: 5;
    background: $color-white;
    color: $dark-2;
    box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
    border-radius: 3px;
    white-space: nowrap;
    left: 50%;
    font-size: 15px;
    padding: 5px 10px;
    line-height: 20px;
    text-align: center;
    margin-left: -60px;
    display: none;
  }
  &:hover {
    .tooltip-upload {
      display: block;
    }
  }
}
#btn-download-chart {
  position: relative;
  .tooltip-download {
    position: absolute;
    top: 100%;
    margin-top: 5px;
    z-index: 5;
    background: $color-white;
    color: $dark-2;
    box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
    border-radius: 3px;
    white-space: nowrap;
    left: 50%;
    font-size: 15px;
    padding: 5px 10px;
    line-height: 20px;
    color: #000;
    text-align: center;
    margin-left: -60px;
    display: none;
  }
  &:hover {
    .tooltip-download {
      display: block;
    }
  }
}
.chart-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  @include mobile {
    border-left: 1px solid $grey-2;
    border-right: 1px solid $grey-2;
    border-bottom: 1px solid $grey-2;
  }
  & > :first-child {
    width: 100%;
    height: 100%;
  }
  .fullscreen {
    background: $color-white !important;
    overflow-y: hidden !important;
    z-index: 9999999999;
  }
  .chart-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background-color: $bg-main3;
    padding: 8px 13px;
    height: $heightOption;
    border-top: 1px solid $bg-main2;
    font-family: $helvetica;
    line-height: 28px;
    font-size: $font-smaller;
    color: $color-grey;
    &.no-time-option {
      justify-content: flex-end;
    }
    .time-option {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
      @include mobile {
        gap: 15px;
      }
      div {
        line-height: 15px;
        &.sc_search_select {
          margin-top: 0px;
        }
        select {
          height: 100%;
          padding-left: 5px;
          -webkit-appearance: none;
          border: none;
          border-right: 1px solid $borderColor;
          -webkit-border-radius: 0px;
        }
      }
      .select-box-mul-chart {
        border-right: 1px solid $borderColor;
      }
      .time {
        &.div-time {
          border-right: 0px;
          display: flex;
          // padding: 0px 8px;
          border-radius: 3px;
          color: $text-main;
          cursor: pointer;
          font-size: 14px;
          font-family: $helvetica;
          line-height: 24px;
          font-weight: 700;
          @include mobile {
            font-size: 12px;
            line-height: 16px;
            padding: 0;
            font-weight: 300;
          }
        }
        &.button-icon {
          color: $text-main5;
          cursor: pointer;
          font-size: 24px;
          @media (max-width: 1350px) {
            display: none !important;
          }
          .icon_drop {
            font-size: 9px;
            float: right;
            line-height: 26px;
            width: 16px;
            margin-left: 3px;
          }
          & img {
            width: 24px;
            height: 24px;
          }
        }
        &.active-time {
          background-color: $bg-main1 !important;
        }

        &.sc_search_select :deep() {
          .button_drop_search {
            border-radius: 4px !important;
          }
          .box_list_search_select {
            li {
              padding: 10px 16px;
            }
            .selected-li {
              background-color: $bg-main3 !important;
            }
            .full_name_coin_select {
              font-family: $helvetica;
              font-size: 16px;
              font-weight: 300;
              line-height: 24px;
              color: $text-main !important;
            }
          }
        }
      }
      .group_favered {
        position: relative;
        .btn-favored {
          border: 0px;
          margin: 0;
          padding: 0;
          line-height: 20px;
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .icon {
            font-size: 24px;
            color: $text-main5;
          }
          .icon_drop {
            font-size: 10px;
          }
        }
        .box_favored_list {
          position: absolute;
          top: 100%;
          right: 0px;
          z-index: 999;
          margin-top: 5px;
          height: auto;
          .favored_list {
            background: $color-white;
            box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
            border-radius: 3px;
            list-style-type: none;
            overflow-y: auto;
            margin: 0;
            padding: 5px 0px;
            li.item {
              display: flex;
              gap: 3px;
              width: 100%;
              line-height: 20px;
              cursor: pointer;
              padding: 3px 8px;
              font-size: 14px;
              overflow: hidden;
              color: $color-grey-dark;
              &:hover {
                background: $grey-3;
              }
              .icon {
                color: $dark-3;
                font-size: 16px;
                line-height: 20px;
                width: 20px;
              }
              .name {
                display: inline;
                white-space: nowrap;
                line-height: 20px;
                font-size: 14px;
              }
            }
          }
        }
        &.active {
          // background-color: $primary-2;
          .btn-favored {
            // color: $color-white;
            .icon_drop {
              -ms-transform: rotate(180deg);
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
            }
          }
        }
      }
    }
    .chart-type {
      float: right;
      height: 100%;
      display: flex;
      align-items: center;
      .total-aml-remaining {
        align-self: center;
        margin-right: -6px;
        font-family: $helvetica;
        font-size: $font-smaller;
        color: $color-green;
        span {
          font-family: $helvetica;
        }
      }
      .icon-close1 {
        color: $color-white;
        float: right;
        line-height: $heightOption;
        padding-left: 9px;
        padding-right: 9px;
        cursor: pointer;
        background: $color-grey;
      }
      .icon-screen {
        float: right;
        padding-left: 9px;
        padding-right: 9px;
        cursor: pointer;
        line-height: $heightOption;
        color: $text-main5;
      }
      .chart-tab__mobile,
      .chart-tab {
        cursor: pointer;
        margin: 0;
        display: flex;
        float: right;
        .tab-element {
          border-right: 0px;
          float: left;
          padding-left: 8px;
          padding-right: 8px;
          font-size: 12px;
          color: $text-main-blur;
          font-family: $helvetica;
          font-weight: 700;
          line-height: 16px;
          &.active-tab {
            color: $text-main;
          }
        }
        a {
          color: $color-grey-dusty;
          &:hover {
            color: $color-corn;
          }
        }
      }
      .chart-tab .tab-element {
        @media (max-width: 1350px) {
          display: none;
        }
      }
      .more-option {
        display: none;
        @media (max-width: 1350px) {
          display: block;
          position: relative;
          > img {
            width: 24px;
            height: 24px;
            cursor: pointer;
          }
          ul.options {
            position: absolute;
            width: 200px;
            z-index: 999;
            text-align: center;
            top: 30px;
            right: 0;
            flex-wrap: wrap;
            display: flex;
            justify-content: center;
            padding: 8px;
            gap: 12px;
            background: white;
            border-radius: 3px;
            box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
            & > li {
              margin: 5px 0;
              cursor: pointer;
              &.icon-exit-full-screen {
                font-size: 20px;
                color: #414141;
              }
              &.group_favered {
                position: relative;
                & .btn.btn-favored {
                  padding: 0;
                  border: 0px;
                  margin: 0;
                  padding: 0;
                  line-height: 20px;
                  background-color: transparent;
                  display: flex;
                  align-items: center;
                  justify-content: space-around;
                  .icon {
                    font-size: 24px;
                    color: $text-main5;
                  }
                  .icon_drop {
                    font-size: 10px;
                  }
                }
                &.active {
                  // background-color: $primary-2;
                  .btn-favored {
                    // color: $color-white;
                    .icon_drop {
                      -ms-transform: rotate(180deg);
                      -webkit-transform: rotate(180deg);
                      transform: rotate(180deg);
                    }
                  }
                }
                .box_favored_list {
                  position: absolute;
                  top: 100%;
                  right: 0px;
                  z-index: 999;
                  margin-top: 5px;
                  height: auto;
                  .favored_list {
                    background: $color-white;
                    box-shadow: 0px 3px 12px rgba(49, 49, 49, 0.51);
                    border-radius: 3px;
                    list-style-type: none;
                    overflow-y: auto;
                    margin: 0;
                    padding: 5px 0px;
                    li.item {
                      display: flex;
                      gap: 3px;
                      width: 100%;
                      line-height: 20px;
                      cursor: pointer;
                      padding: 3px 8px;
                      font-size: 14px;
                      overflow: hidden;
                      color: $color-grey-dark;
                      &:hover {
                        background: $grey-3;
                      }
                      .icon {
                        color: $dark-3;
                        font-size: 16px;
                        line-height: 20px;
                        width: 20px;
                      }
                      .name {
                        display: inline;
                        white-space: nowrap;
                        font-weight: 700;
                        line-height: 20px;
                        font-size: 14px;
                      }
                    }
                  }
                }
              }
            }
          }
          .tab-element {
            cursor: pointer;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            color: $text-main-blur;
            &.active-tab {
              color: $text-main;
            }
          }
        }
      }
    }
  }
  .chart-content {
    height: calc(100% - #{$heightOption});
    width: 100%;
    .vue-highcharts {
      box-shadow: none !important;
      border: none !important;
    }
    &.chart-full-screen {
      height: calc(100% - #{$heightOption}) !important;
    }
  }
}
:deep() {
  .button_drop_search {
    padding: 0 !important;
    border: none !important;
    background: none !important;
    display: flex !important;
    gap: 5px;
    align-items: center;
    .icon-arrow1 {
      margin: 0 !important;
      font-size: 10px !important;
      line-height: 1 !important;
    }
    span {
      @include text-font(
        $helvetica !important,
        $text-main !important,
        14px !important,
        300 !important,
        22px !important
      );
      @include mobile {
        @include text-font(
          $helvetica !important,
          $text-main !important,
          12px !important,
          400 !important,
          16px !important
        );
      }
    }
    img {
      width: 16px;
      height: 16px;
    }
  }
  .time {
    display: flex;
    align-items: center;
  }
  .active-time {
    background: $bg-main1 !important;
    color: white !important;
    padding: 5px 8px !important;
    border-radius: 3px !important;
    span,
    i {
      color: white !important;
    }
    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(154deg) brightness(108%)
        contrast(101%);
    }
  }
  .box_list_search_select {
    width: auto !important;
  }
}
</style>
