import rf from "@/request/RequestFactory";

const actions = {
  getIndicator({ commit }, params) {
    rf.getRequest("MarginRequest")
      .getIndicator()
      .then((res) => {
        if (res && res.data) {
          commit("updateIndicator", res.data);
        }
      });
  },
};

const getters = {
  easyMode: (state) => {
    return state.isEasyMode;
  },
  indicator: (state) => {
    return state.indicator;
  },
};

const mutations = {
  updateEasyMode: (state, payload) => {
    state.isEasyMode = payload;
  },
  updateIndicator: (state, payload) => {
    state.indicator = [...payload];
  },
};

export default {
  actions,
  getters,
  mutations,
  state: {
    isEasyMode: false,
    indicator: [],
  },
};
