<template>
  <PopupModal :isShow="isShow">
    <template slot="body">
      <div class="banks-modal">
        <img
          src="@/assets/images/expand-left.svg"
          alt=""
          class="back-icon"
          v-if="confirmCreditCard && step === 1"
          @click="handleGoBack"
        />
        <img
          src="@/assets/images/close-circle.svg"
          alt=""
          class="close-icon"
          v-if="step !== 3"
          @click="handleCloseModal"
        />
        <div class="modal-tit">
          {{
            confirmCreditCard ? $t('deposit_fiat_modal.title_1') : $t('deposit_fiat_modal.title_2')
          }}
        </div>
        <div class="modal-body">
          <div class="amount-label">{{ $t('deposit_fiat_modal.total') }}</div>
          <div class="amount" :class="{ 'error-amount': errors.has('amount-total') }">
            <div class="left">
              <div class="amount-value">
                IDR {{ amount | formatCurrencyAmount(currencyName, '0') }}
              </div>
            </div>
            <div class="count-down" v-if="dataBankMethodsSuccess">
              <span>{{ $t('deposit_fiat_modal.pay_within') }}</span>
              <CountDown
                :duration="countDuration(dataBankMethodsSuccess)"
                :autoCountDown="true"
                :timeHour="true"
                class="count"
              />
            </div>
          </div>
          <span v-show="errors.has('amount-total')" class="invalid-feedback">{{
            $t(errors.first('amount-total'))
          }}</span>
          <DepositMethods
            v-if="step === 1 || step === 2"
            ref="depositMethod"
            :amount="amount"
            :pending.sync="pending"
            :confirmCreditCard="confirmCreditCard"
            :showConfirmCardModal.sync="confirmCreditCard"
            @close="handleCloseModal"
            @next-step="onNextStep"
            @prev-step="onPrevStep"
            @showErrorMaximumAmount="showErrorMaximumAmount"
          />
          <BankMethods
            v-if="step !== 1"
            :amount="amount"
            :pending.sync="pending"
            :confirmCreditCard="confirmCreditCard"
            :showConfirmCardModal.sync="confirmCreditCard"
            @close="handleCloseModal"
            @next-step="onNextStep"
            @select-bank="onSelectBank"
            @showErrorMaximumAmount="showErrorMaximumAmount"
            @bankMethodsSuccess="onBankMethodsSuccess"
          />
        </div>
      </div>
    </template>
  </PopupModal>
</template>

<script>
import PopupModal from '@/components/PopupModal.vue';
import DepositMethods from '@/components/shared_components/funds/deposit_usd/step-1/DepositMethods.vue';
import BankMethods from './step-2/BankMethods.vue';
import CountDown from '@/components/shared_components/funds/common/CountDown.vue';
import moment from 'moment';

export default {
  name: 'DepositModal',
  components: { DepositMethods, PopupModal, BankMethods, CountDown },
  props: {
    isShow: Boolean,
    amount: Object | null | String | Number | Array,
  },
  data() {
    return {
      confirmCreditCard: false,
      step: 1,
      pending: {},
      currencyName: 'idr',
      dataBankMethodsSuccess: null,
    };
  },
  methods: {
    handleGoBack() {
      this.confirmCreditCard = false;
      this.step = 1;
    },
    handleCloseModal() {
      this.$emit('close');
      this.errors.clear();
      this.handleGoBack();
      this.step = 1;
      this.dataBankMethodsSuccess = null;
      // credit cảd
      this.$refs.depositMethod.isShowlistBank =
        this.$refs.depositMethod.isShowlistCreditCard =
        this.$refs.depositMethod.isAddNewCard =
          false;
      this.$refs.depositMethod.card_number = '';
      this.$refs.depositMethod.expiredTime = '';
      this.$refs.depositMethod.cvv = '';
      // -------
    },
    onNextStep() {
      this.step = this.step + 1;
    },
    onPrevStep() {
      this.step = 1;
    },
    onSelectBank() {
      this.step = 4;
    },
    countDuration(item) {
      let time = item.expiry_time;
      if (!time) time = item.created_at + 86400000;
      const date = moment(time);
      const timestamp = moment(date).format('X');
      const now = Date.now() / 1000;
      return Number((timestamp - now).toFixed(0));
    },
    showErrorMaximumAmount(msg) {
      this.errors.add({
        field: 'amount-total',
        msg: msg,
      });
    },
    onBankMethodsSuccess(data) {
      this.dataBankMethodsSuccess = data;
    },
  },
  watch: {
    pending: {
      handler(newValue) {
        this.$emit('update:pending', newValue);
      },
    },
    step() {
      this.errors.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
.banks-modal {
  max-width: 448px;
  height: auto;
  margin: 0 auto;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  @include mobile {
    width: calc(100vw - 30px);
    border-radius: 10px;
  }
  img.close-icon {
    // margin-right: 30px;
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 24px;
    right: 12px;
    top: 12px;
  }
  img.back-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 12px;
    top: 12px;
  }
  .modal-tit {
    color: $text-main;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-top: 24px;
  }
  .modal-body {
    padding: 16px 24px 24px;
    max-height: 500px;
    overflow: auto;
    .amount-label {
      color: $text-secondary;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      padding-bottom: 8px;
      @include mobile {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
      }
    }
    .amount {
      border: 1px solid $bg-main2;
      border-radius: 8px;
      padding: 9px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 42px;
      @include mobile {
        height: 40px;
      }
      .count-down {
        display: flex;
        gap: 5px;
        span {
          color: $text-main-blur;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          white-space: nowrap;
          margin-bottom: auto;
          margin-top: auto;
          @include mobile {
            font-size: 12px;
            line-height: 16px;
          }
        }
        .count {
          color: $text-main;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          @include mobile {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
      .amount-value {
        color: $text-main;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        @include mobile {
          font-size: 14px;
          font-weight: 300;
          line-height: 22px;
        }
      }
    }
    .error-amount {
      border: 1px solid var(--primary-error, #ff5b5b);
      background: rgba(255, 91, 91, 0.1);
    }
  }
}
</style>
