<template>
  <div>
    <div class="bank-list" v-if="!showConfirmCardModal">
      <div class="modal-tit">{{ $t("deposit_fiat_modal.title_2") }}</div>
      <div class="bank-item" :class="{ active: isShowlistCreditCard }">
        <div class="bank-wrapper">
          <!-- TODO: uncomment when deposit by credit card is ready -->
          <!-- <div
            class="card__wrapper"
            :class="{ activeCreditCard: isShowlistCreditCard }"
            @click.prevent="toggleListCreditCard"
          >
            <img
              :src="
                '/images/icon/' +
                `${
                  isShowlistCreditCard
                    ? 'bank-card-active.svg'
                    : 'bank-card.svg'
                }`
              "
            />
            <div>
              <div
                class="bank-name"
                :class="{ activeBankName: isShowlistCreditCard }"
              >
                {{ $t("deposit_fiat_modal.credit_card") }}
              </div>
            </div>
          </div> -->
          <div
            class="card__wrapper"
            :class="{ activeCreditCard: isShowlistBank }"
            @click="toggleListBank"
          >
            <img
              :src="
                '/images/icon/' +
                `${isShowlistBank ? 'bank-active.svg' : 'bank.svg'}`
              "
            />
            <div>
              <div
                class="bank-name"
                :class="{ activeBankName: isShowlistBank }"
              >
                {{ $t("deposit_fiat_modal.bank_transfer") }}
              </div>
            </div>
          </div>
        </div>

        <div class="credit-card__info">
          <div
            class="credit-card__info-item"
            v-for="item in listCredit"
            :key="item?.id"
            @click="selectCreditCard(item)"
          >
            <div class="info-item__title">
              <img :src="getImageCard(item?.card_number)" alt="no image" />
            </div>
            <div class="info-item__desc">
              <div class="info-card_number">
                {{ item?.card_number }}
              </div>
              <div class="info-expiry">
                {{ $t("deposit_fiat_modal.expires_on") }}
                {{ `${mounthFormat(item?.expiry_month)}/${item?.expiry_year}` }}
              </div>
            </div>
            <img
              src="@/assets/images/delete-icon.svg"
              alt=""
              class="delete-card-icon"
              @click.stop="handleOpenDeleteCardModal(item)"
            />
            <div class="info-item__expired">
              {{ $t("deposit_fiat_modal.expired") }}
            </div>
          </div>
          <div
            class="credit-card__info-btn"
            @click="handleAddNewCard"
            v-if="!isAddNewCard"
          >
            <img
              src="/images/icon/u_plus.svg"
              alt="icon"
              height="24"
              width="24"
            />
            {{ $t("deposit_fiat_modal.add_new_card") }}
          </div>
          <div class="add-credit__form" v-else>
            <div class="add-creadit__form--title">
              {{ $t("deposit_fiat_modal.add_new_card") }}
            </div>
            <div class="credit__form-item">
              <div class="form-item__wrapper">
                <div class="form-item__title">
                  {{ $t("deposit_fiat_modal.card_number") }}
                  <span class="required">*</span>
                </div>
                <input
                  v-model="card_number"
                  type="text"
                  :class="{
                    errorInput:
                      errors.has('card_number') || errors.has('card_existed'),
                  }"
                  name="card_number"
                  maxlength="19"
                  @click="resetError"
                />
              </div>
              <div
                class="error"
                v-show="
                  errors.has('card_number') ||
                  errors.has('expiredTime') ||
                  errors.has('cvv_number')
                "
              >
                {{
                  errors.first("expiredTime") ===
                  "validation.credit_card.expiry_date_invalid"
                    ? $t("validation.credit_card.expiry_date_invalid")
                    : $t("validation.credit_card.card_invalid")
                }}
              </div>
            </div>
            <div class="credit__form-group-item">
              <div class="form-item__wrapper">
                <div class="form-item__title">
                  {{ $t("deposit_fiat_modal.expiry") }}
                  <span class="required">*</span>
                </div>
                <input
                  v-model="expiredTime"
                  maxlength="5"
                  placeholder="MM/YY"
                  type="text"
                  @keyup="formatString"
                  :class="{ errorInput: errors.has('expiredTime') }"
                  name="expiredTime"
                  @click="resetError"
                  @keypress="preventNonNumericalInput($event)"
                />
              </div>
              <div class="form-item__wrapper">
                <div class="form-item__title">
                  CVV <span class="required">*</span>
                </div>
                <input
                  v-model="cvv"
                  type="text"
                  :class="{ errorInput: errors.has('cvv_number') }"
                  maxlength="4"
                  name="cvv_number"
                  @click="resetError"
                  @keypress="preventNonNumericalInput($event)"
                />
              </div>
            </div>
            <div class="error" v-show="errors.has('card_existed')">
              {{ errors.first("card_existed") }}
            </div>
            <div class="btn-group">
              <button
                type="submit"
                class="cancel-credit__btn"
                @click="cancelAddCredit"
              >
                {{ $t("deposit_fiat_modal.cancel") }}
              </button>
              <button
                type="submit"
                class="add-credit__btn"
                @click="createCreditCard"
                :disabled="isDisableCreateCreditCard"
              >
                <img
                  :class="{ disabled: isDisableCreateCreditCard }"
                  src="/images/icon/plus-white.svg"
                  alt="icon"
                />
                {{ $t("deposit_fiat_modal.add") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bank-list" v-else>
      <div class="modal-tit">
        {{ $t("deposit_fiat_modal.credit_card_payment_confirmation") }}
      </div>
      <div class="bank-item" :class="{ active: isShowlistCreditCard }">
        <div class="credit-card__info">
          <div class="credit-card__info-item">
            <!-- <div class="info-item__title">
              {{ getTypeCreditCard(selectedCard?.card_number) }}
            </div>
            <div class="info-item__desc">{{ selectedCard?.card_number }}</div> -->
            <div class="info-item__title">
              <img
                :src="getImageCard(selectedCard?.card_number)"
                alt="no image"
              />
            </div>
            <div class="info-item__desc">
              <div class="info-card_number">
                {{ selectedCard?.card_number }}
              </div>
              <div class="info-expiry">
                {{ $t("deposit_fiat_modal.expires_on") }}
                {{
                  `${mounthFormat(selectedCard?.expiry_month)}/${
                    selectedCard?.expiry_year
                  }`
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="credit-card__confirm-btn-wrapper">
          <div class="credit-card__confirm-btn" @click="onSubmitDeposit">
            {{ $t("deposit_fiat_modal.confirm") }}
          </div>
        </div>
      </div>
    </div>
    <delete-credit-card-modal
      :isShow="isShowDeleteCardModal"
      @close="handleCloseDeleteCardModal"
      @ok="handleDeleteCreditCard"
    />
    <ModalVerifyCard
      v-if="showModalVerifyCard"
      :url="urlVerifyCard"
      @responseVerifyCard="handleResponseVerifyCard"
    />
  </div>
</template>

<script>
import CountDown from "@/components/shared_components/funds/common/CountDown.vue";
import DeleteCreditCardModal from "@/components/shared_components/funds/deposit_usd/step-1/DeleteCreditCardModal.vue";
import ModalVerifyCard from "@/components/shared_components/funds/deposit_usd/step-1/ModalVerifyCard.vue";
import rf from "@/request/RequestFactory";
import { getTypeCreditCard } from "@/common/Utils";
import moment from "moment";
import BigNumber from "bignumber.js";
import {
  visaRegex,
  masterCardRegex,
  JCBRegex,
  americanExpressRegex,
} from "@/common/Utils";

export default {
  name: "DepositMethods",
  props: {
    amount: Object | null | String | Number | Array,
    confirmCreditCard: Boolean,
  },
  components: {
    CountDown,
    DeleteCreditCardModal,
    ModalVerifyCard,
  },
  computed: {
    chevonImages() {
      const images = require("@/assets/images/icon/expand-right.svg");
      return images;
    },
    isDisableCreateCreditCard() {
      if (
        !this.card_number ||
        this.card_number.length < 15 ||
        !this.cvv ||
        this.cvv.length < 3 ||
        !this.expiredTime
      ) {
        return true;
      }
      return false;
    },
  },
  watch: {
    pending: {
      handler(newValue) {
        this.$emit("update:pending", newValue);
      },
    },
    confirmCreditCard: {
      handler(newValue) {
        this.showConfirmCardModal = newValue;
      },
    },
    showConfirmCardModal: {
      handler(newValue) {
        this.$emit("update:showConfirmCardModal", newValue);
      },
    },
    card_number(newValue) {
      this.card_number = newValue
        .replace(/\D/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .trim();
    },
  },
  data() {
    return {
      pending: {},
      selectedCard: null,
      showConfirmCardModal: false,
      card_number: "",
      cvv: "",
      expiredTime: "",
      listCredit: [],
      selectedCard: null,
      isShowlistCreditCard: false,
      isShowlistBank: false,
      isShowDeleteCardModal: false,
      isAddNewCard: false,
      duration: 600,
      showDetailBank: false,
      paymentOptions: [
        { id: 0, name: "Creadit card", code: "bca" },
        { id: 1, name: "Bank transfer", code: "bri" },
      ],
      getTypeCreditCard,
      showModalVerifyCard: false,
      urlVerifyCard: "",
      maximumDepositWithCreditCard: 999999999,
      isCreatingTransaction: false,
    };
  },
  methods: {
    mounthFormat(mounth) {
      if (mounth < 10) {
        return `0${mounth}`;
      }
      return mounth;
    },
    getImageCard(number) {
      const cardNumber = number.replace(/\s+/g, "");
      if (!cardNumber) return "/images/icon/master_card.png";
      if (cardNumber.match(visaRegex)) return "/images/icon/visa.png";
      if (cardNumber.match(masterCardRegex))
        return "/images/icon/master_card.png";
      if (cardNumber.match(JCBRegex)) return "/images/icon/jcb.jpg";
      if (cardNumber.match(americanExpressRegex))
        return "/images/icon/american_express.png";
      return "/images/icon/master_card.png";
    },
    handleAddNewCard() {
      if (this.listCredit.length < 5) {
        this.isAddNewCard = true;
      } else {
        Message.error(this.$t("validation.credit_card.max_card"));
      }
    },
    preventNonNumericalInput(e) {
      e = e || window.event;
      var charCode = typeof e.which == "undefined" ? e.keyCode : e.which;
      var charStr = String.fromCharCode(charCode);
      if (!charStr.match(/^[0-9 /]+$/)) e.preventDefault();
    },
    handleCloseDeleteCardModal() {
      this.isShowDeleteCardModal = false;
    },
    handleOpenDeleteCardModal(value) {
      this.isShowDeleteCardModal = true;
      this.selectedCard = value;
    },
    async handleDeleteCreditCard() {
      const params = {
        id: this.selectedCard?.id,
      };
      rf.getRequest("TransactionRequest")
        .deleteCreditCard(params)
        .then((res) => {
          if (res?.success == true) {
            this.getCreditCard();
            this.handleCloseDeleteCardModal();
            Message.success(
              this.$i18n.t("deposit_fiat_modal.remove_card_success")
            );
          }
        })
        .catch(function (error) {
          console.log(error.response.data.message);
        });
    },
    cancelAddCredit() {
      this.isAddNewCard = false;
      this.card_number = "";
      this.cvv = "";
      this.expiredTime = "";
    },
    formatString(event) {
      var inputChar = String.fromCharCode(event.keyCode);
      var code = event.keyCode;
      var allowedKeys = [8];
      if (allowedKeys.indexOf(code) !== -1) {
        return;
      }
      event.target.value = event.target.value
        .replace(
          /^([1-9]\/|[2-9])$/g,
          "0$1/" // 3 > 03/
        )
        .replace(
          /^(0[1-9]|1[0-2])$/g,
          "$1/" // 11 > 11/
        )
        .replace(
          /^([0-1])([3-9])$/g,
          "0$1/$2" // 13 > 01/3
        )
        .replace(
          /^(0?[1-9]|1[0-2])([0-9]{2})$/g,
          "$1/$2" // 141 > 01/41
        )
        .replace(
          /^([0]+)\/|[0]+$/g,
          "0" // 0/ > 0 and 00 > 0
        )
        .replace(
          /[^\d\/]|^[\/]*$/g,
          "" // To allow only digits and `/`
        )
        .replace(
          /\/\//g,
          "/" // Prevent entering more than 1 `/`
        );
    },
    toggleListCreditCard() {
      this.isShowlistCreditCard = !this.isShowlistCreditCard;
      if (this.isShowlistCreditCard == true) {
        this.getCreditCard();
      }
      this.$emit("prev-step");
      this.isShowlistBank = false;
    },
    toggleListBank() {
      this.isShowlistBank = !this.isShowlistBank;
      this.isShowlistCreditCard = false;
      if (!this.isShowlistBank === true) this.$emit("prev-step");
      else this.$emit("next-step");
    },
    getCreditCard() {
      rf.getRequest("TransactionRequest")
        .getCreditCard()
        .then((res) => {
          this.listCredit = res?.data;
        })
        .catch(function (error) {
          console.log(error.response.data.message);
        });
    },
    isExpiredCard(data) {
      const dateExpired = moment(
        `${data.expiry_year}-${data.expiry_month}`,
        "YYYY-MM"
      )
        .endOf("month")
        .toDate();
      if (moment(dateExpired).isBefore(moment())) {
        return true;
      }
      return false;
    },
    async createCreditCard() {
      const res = await this.$validator.validateAll();
      if (!res) return;
      const date = this.expiredTime.split("/");
      const params = {
        card_number: this.card_number.replaceAll(" ", ""),
        expiry_month: Number(date[0]),
        expiry_year: "20" + date[1],
        cvv: this.cvv,
      };
      const isExpiredYear = Number("20" + date[1]) < moment().year();
      const isExpiredMonth =
        Number("20" + date[1]) === moment().year() &&
        date[0] < moment().month() + 1;
      if (isExpiredMonth || isExpiredYear) {
        this.errors.add(
          "expiredTime",
          "validation.credit_card.expiry_date_invalid"
        );
        return;
      }
      rf.getRequest("TransactionRequest")
        .createCreditCard(params)
        .then((res) => {
          if (res?.success == true) {
            Message.success(
              this.$i18n.t("deposit_fiat_modal.add_new_card_success"),
              {},
              { position: "top_right" }
            );
            this.getCreditCard();
            this.cancelAddCredit();
          }
        })
        .catch((error) => {
          if (error.response.data?.errors?.card_number)
            this.errors.add(
              "card_number",
              this.$i18n.t(error.response.data?.errors?.card_number[0])
            );
          if (error.response.data?.errors?.cvv) {
            this.errors.add(
              "cvv_number",
              this.$i18n.t(error.response.data?.errors?.cvv[0])
            );
          }
          if (
            error.response.data?.errors?.expiry_month ||
            error.response.data?.errors?.expiry_year
          ) {
            this.errors.add(
              "expiredTime",
              this.$i18n.t(error.response.data?.errors?.expiry_month[0])
            );
          }
          if (this.errors.items.length === 0) {
            this.errors.add(
              "card_existed",
              this.$t(error.response.data.message)
            );
          }
        });
    },
    onSubmitDeposit() {
      if (this.isCreatingTransaction) {
        return;
      }
      if (this.isExpiredCard(this.selectedCard)) {
        Message.error(
          this.$i18n.t("funds.deposit_fiat.card_expired_message"),
          {},
          { position: "top_right" }
        );
        return;
      }
      this.duration = 600;
      let params = {
        amount: `${this.amount || 0}`,
        card_id: this.selectedCard.id,
        payment_type: "credit_card",
      };
      this.isCreatingTransaction = true;
      rf.getRequest("TransactionRequest")
        .depositUsd(params)
        .then((res) => {
          this.isCreatingTransaction = false;
          this.showModalVerifyCard = true;
          this.urlVerifyCard = res.data.redirect_url;
        })
        .catch((error) => {
          this.isCreatingTransaction = false;
          const errMsg = error.response.data.message;
          if (errMsg.status_code == 202)
            Message.error(
              window.i18n.t("funds.deposit_fiat.fail_message"),
              {},
              { position: "top_right" }
            );
          if (errMsg.status_code == 402)
            Message.error(
              window.i18n.t("funds.deposit_fiat.card_not_support_message"),
              {},
              { position: "top_right" }
            );
          else
            Message.error(
              window.i18n.t("funds.deposit_fiat.something_wrong"),
              {},
              { position: "top_right" }
            );
        });
      if (this.$refs.countDown) {
        this.$refs.countDown.setCountDown();
      }
    },
    selectCreditCard(cardData) {
      if (new BigNumber(this.amount).gt(this.maximumDepositWithCreditCard)) {
        this.$emit(
          "showErrorMaximumAmount",
          "funds.deposit_fiat.maximum_deposit_with_credit_card"
        );
        return;
      }
      this.selectedCard = cardData;
      this.showConfirmCardModal = true;
    },
    handleResponseVerifyCard(res) {
      this.showModalVerifyCard = false;
      this.$emit("close");
      if (res.status_code == 202)
        return Message.error(
          this.$i18n.t("funds.deposit_fiat.fail_message"),
          {},
          { position: "top_right" }
        );
      return Message.success(
        this.$i18n.t("funds.deposit_fiat.success_message"),
        {},
        { position: "top_right" }
      );
    },
    backToWallet() {
      this.showDetailBank = false;
      if (this.$refs.countDown) {
        this.$refs.countDown.resetCountDown();
      }
      this.$emit("close");
    },
    resetError() {
      this.errors.clear();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  padding: 20px;
  .amount {
    border: 1px solid $border-gray;
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .count-down {
      color: $primary-0;
    }
  }
  .bank-list {
    margin-top: 16px;
    .modal-tit {
      color: $text-main;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 16px;
      @include mobile {
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
      }
    }
    .bank-item {
      cursor: pointer;

      .bank-wrapper {
        display: flex;
        justify-content: space-between;
        gap: 16px;
      }
      .card__wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        border-radius: 8px;
        gap: 4px;
        background: var(--default-background-2, #f2f2f2);
        height: 92px;
        .expand-right {
          transition: transform 0.1s linear;
          &.rote {
            transform: rotate(90deg);
          }
        }
      }
      &.active .credit-card__info {
        display: block;
      }
      .activeCreditCard {
        background: $text-main;
      }
      .activeBankName {
        color: $text-main1 !important;
      }

      .bank-name {
        cursor: pointer;
        color: $text-main;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
      }
      & > img {
        cursor: pointer;
      }
      .bank-description {
        line-height: 1;
      }
    }
  }
  .bank-detail {
    margin-top: 30px;
    font-size: 16px;
    line-height: 20px;
    .bank-name {
      font-weight: 600;
    }
    .description {
      margin-top: 30px;
    }
    .account-number {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .copy {
        cursor: pointer;
      }
    }
    .tips {
      margin-top: 30px;
      color: $primary-2;
      cursor: pointer;
    }
    .btn-back {
      background: $primary-2;
      border-radius: 10px;
      font-size: 20px;
      color: white;
      padding: 10px 30px;
      display: flex;
      justify-content: center;
      margin: 30px auto 0 auto;
    }
  }
}
.credit-card__info {
  display: none;
  grid-column: span 3;
  margin-top: 16px;
  .credit-card__info-item {
    border-radius: 8px;
    background: var(--default-background-2, #f2f2f2);
    padding: 16px;
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    .info-item__expired {
      display: none;
      color: #13273b;
      font-size: 15px;
      font-weight: 400;
    }
    & + .credit-card__info-item {
      margin-top: 16px;
    }
    .delete-card-icon {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 24px;
      transform: translateY(-50%);
    }
    .info-item__desc {
      .info-card_number {
        color: $text-main;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        @include mobile {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .info-expiry {
        color: $text-secondary;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        @include mobile {
          font-size: 12px;
          line-height: 16px;
          font-weight: 300;
        }
      }
    }
    .info-item__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      img {
        width: 24px;
        height: auto;
      }
    }
  }
  .credit-card__info-btn {
    cursor: pointer;
    text-transform: capitalize;
    margin-top: 16px;
    display: flex;
    padding: 9px 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    border: 1px solid $bg-main1;
    color: $text-main;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    height: 42px;
  }
}
.add-credit__form {
  margin-top: 16px;
  .add-creadit__form--title {
    text-transform: capitalize;
    color: $text-main;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    @include mobile {
      font-size: 14px;
    }
  }
}
.credit__form-group-item,
.credit__form-item {
  margin-top: 16px;
  @include mobile {
    margin-top: 8px;
  }
}
.credit__form-group-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
}
.error {
  color: $color-red;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-top: 8px;
}
.form-item__wrapper {
  .form-item__title {
    text-transform: capitalize;
    color: $text-secondary;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    cursor: default;
    @include mobile {
      font-size: 14px;
      font-weight: 400;
    }
    .required {
      color: $color-red;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
  & > input {
    font-family: $helvetica;
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    color: $text-main;
    margin-top: 8px;
    height: 42px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $bg-main2;
    background: $color-white;
    width: 100%;
    padding: 9px 16px;
    @include mobile {
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
    }
    &:focus {
      border-color: $bg-main1;
    }
    &::placeholder {
      color: $text-main-blur;
    }
    &.errorInput {
      border: 1px solid $text-warning !important;
    }
  }
}

.btn-group {
  display: flex;
  gap: 16px;
  margin-top: 16px;
  .cancel-credit__btn {
    display: flex;
    width: 100%;
    height: 42px;
    justify-content: center;
    align-items: center;
    padding: 9px 32px;
    border-radius: 60px;
    background: $bg-main3;
    color: $text-main;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  .add-credit__btn {
    display: flex;
    gap: 10px;
    width: 100%;
    height: 42px;
    justify-content: center;
    align-items: center;
    padding: 9px 32px;
    border-radius: 60px;
    background-color: $bg-main1;
    color: $color-white;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    &:disabled {
      background: $bg-main3;
      color: $text-main;
      cursor: not-allowed;
    }
    img {
      width: 24px;
      height: 24px;
      &.disabled {
        filter: invert(0.5);
      }
    }
    &:disabled {
      color: rgba(2, 0, 13, 0.38);
      background-color: $bg-main3;
      cursor: not-allowed;
    }
    &:disabled {
      color: rgba(2, 0, 13, 0.38);
      cursor: not-allowed;
    }
  }
}

.credit-card__confirm-btn-wrapper {
  grid-column: span 3;
  display: flex;
  justify-content: center;
  margin-top: 12px;
  .credit-card__confirm-btn {
    display: flex;
    width: 100%;
    height: 42px;
    padding: 9px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    background: $bg-main1;
    color: $color-white;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    &:hover {
      opacity: 0.9;
    }
  }
}
</style>
