<template>
  <div id="open-order-screen">
    <div class="box-title">{{ $t("menu.open_orders") }}</div>
    <div class="open-order-wrap">
      <open-order
        class="order-group-screen"
        :options="{ isNoPadding: true }"
        :isSpotMobile="isMobile"
        hiddenLogoCrypto
      />
    </div>
  </div>
</template>

<script>
import COMMON_CONST from "@/common/Const";
import OpenOrder from "./OpenOrder.vue";

export default {
  components: {
    OpenOrder,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      dateList: COMMON_CONST.OPEN_ORDER_DATE_TAB,
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    selectDate(item) {},
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    document.title = this.$t("menu.open_orders") + ` – ${this.tabTitle}`;
  },
  computed: {
    isMobile() {
      if (!this.windowWidth) return;
      return this.windowWidth <= 768;
    },
  },
};
</script>
<style lang="scss" scoped>
#open-order-screen {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 16px;
  min-height: 30vh;
  max-width: 100%;
  padding: 25px 80px;
  margin: 0 auto 50px;
  @media (max-width: 1399px) {
    padding: 25px 32px;
  }
  @include tablet-d {
    padding: 25px 15px;
  }
  .box-title {
    @include text-font($helvetica, $text-main, 24px, 700, 40px);
  }
  .open-order-wrap {
    // padding: 10px 20px;
    background-color: white;
    border-radius: 10px;
  }
}

@include mobile {
  #open-order-screen {
    padding: 16px;
    .box-title {
      font-size: 18px;
      font-weight: 700;
      color: text-main;
      line-height: 32px;
    }
    .open-order-wrap {
      background-color: unset;
    }
    :deep .open-order__mobile-wrapper {
      padding: 0;
      .open-order__mobile-body {
        margin: 0;
        .item-title__wrapper {
          white-space: nowrap;
          &:first-child {
            width: 70px;
            font-size: 14px;
            font-style: normal;
            font-weight: 700 !important;
            line-height: 24px;
            color: $text-main;
          }
          & .item-title__date {
            width: unset;
            margin-left: 4px;
            color: $text-main-blur;
            font-size: 14px;
            font-weight: 300;
            line-height: 22px;
          }
        }
        .open-oder__item-desc {
          grid-template-columns: repeat(4, 1fr);
          & > .item-desc__wrapper:nth-child(4) {
            text-align: end;
          }
          & > .item-desc__wrapper:nth-child(3n + 3) {
            text-align: start;
          }
          & .item-desc__wrapper.single-page-2-col {
            grid-column: span 2;
            text-align: start;
          }
          & .item-desc__wrapper .item-desc__value {
            & .blue {
              color: $color-green;
              font-weight: 700;
            }
            & .red {
              color: $color-red;
              font-weight: 700;
            }
          }
        }
      }
      .open-order__mobile-header {
        .checkbox-function {
          display: none;
        }
        #cancel-box {
          position: absolute;
          top: 16px;
          right: 16px;
          .dropdown-toggle,
          .btn-cancel {
            background-color: $color-white;
          }
        }
      }
    }
  }
}
</style>
