<template>
  <div id="slippery-note">
    <div class="page-title">{{ $t('slippery_note.title') }}</div>
    <div class="slippery-main">
      <div v-if="isLoading" class="slippery">
        <div class="loader"></div>
      </div>
      <div v-for="token in slipperySetting" :key="token.id" class="slippery-item" min="0">
        <div class="cl-1">
          <div class="main">
            <span>{{ $t('slippery_note.price') }}</span>
            <div class="input-group">
              <MarkedInput
                :unit="'idr'"
                v-model="token.price"
                :placeholder="'---'"
                :precision="0.01"
                :visible-button="false"
                :classes="'price-input'"
              />
              <div class="coin">
                <img :src="STORAGE_URL + '/icons/' + token.coin + '.png'" />
                {{ token.coin }}
              </div>
            </div>
          </div>
          <div v-show="token.price_require" class="invalid-feedback">
            {{ token.price_message }}
          </div>
        </div>
        <div class="cl-2">
          <div class="main">
            <span>{{ $t('slippery_note.travel_percent') }}</span>
            <div class="input-group">
              <MarkedInput
                :unit="'%'"
                v-model="token.percent"
                :placeholder="'---'"
                :precision="0.01"
                :visible-button="true"
                :classes="'percentage-input'"
                :min="0.01"
                :max="99.99"
              />
            </div>
          </div>
          <div v-show="token.percent_require" class="invalid-feedback">
            {{ token.percent_message }}
          </div>
        </div>
        <div class="cl-3">
          <div
            class="toggle-switch"
            :class="token.status ? 'on' : 'off'"
            @click.stop="token.status = !token.status"
          >
            <span class="toggle-handle"></span>
          </div>
          <span>{{
            token.status == 1 ? $t('slippery_note.turn_on') : $t('slippery_note.turn_off')
          }}</span>
        </div>
      </div>
    </div>
    <div class="slippery-action">
      <button class="btn-cancel" @click="cancelSlippery">
        {{ $t('slippery_note.cancel') }}
      </button>
      <button class="btn-save" :disabled="isSaving" @click="saveSlippery">
        {{ $t('slippery_note.save') }}
      </button>
    </div>
  </div>
</template>

<script>
import MarkedInput from '@/components/shared_components/common/MarkedInput';
import rf from '@/request/RequestFactory';
import Const from '@/common/Const';

export default {
  name: 'SlipperyNote',
  components: {
    MarkedInput,
  },
  data() {
    return {
      slipperySetting: [],
      prices: [],
      isSaving: false,
      isLoading: false,
    };
  },
  created() {
    this.initSlipperyNote();
  },
  computed: {
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === 'storage_url').value;
    },
  },
  methods: {
    async initSlipperyNote() {
      this.isLoading = true;
      await rf
        .getRequest('PriceRequest')
        .getPrices()
        .then((res) => {
          this.prices = res.data;
        });
      const masterdata = this.$store.state.masterdata;
      this.slipperySetting = masterdata.coins.map((obj) => {
        return {
          id: obj.id,
          coin: obj.coin,
          price: '',
          percent: '3',
          status: true,
          price_require: false,
          percent_require: false,
        };
      });
      rf.getRequest('UserRequest')
        .getSlipperySetting()
        .then((res) => {
          if (res.data.length != this.slipperySetting.length) {
            this.slipperySetting = this.slipperySetting.map((obj) => {
              obj.price =
                this.prices[`idr_${obj.coin}`].price > 0
                  ? this.prices[`idr_${obj.coin}`].price
                  : this.prices[`idr_${obj.coin}`].current_price;
              obj.status = true;
              return {
                ...obj,
              };
            });
            let payload = this.slipperySetting.map((obj) => {
              return {
                currency: 'idr',
                coin: obj.coin,
                price: obj.price ? obj.price : 0,
                percent: obj.percent ? obj.percent : 0,
                status: obj.status ? 1 : 0,
              };
            });
            rf.getRequest('UserRequest').createOrUpdateSlippery(payload);
          } else {
            this.slipperySetting = this.slipperySetting.map((obj) => {
              const found = res.data.find((item) => item.coin === obj.coin);
              obj.price = found.price != 0 ? found.price : '';
              obj.percent = found.percent != 0 ? found.percent : '3';
              obj.status = found.status == 1 ? true : false;
              return {
                ...obj,
              };
            });
          }
        });
      this.isLoading = false;
    },
    saveSlippery() {
      if (!this.validate()) {
        return;
      }
      this.isSaving = true;
      let payload = this.slipperySetting.map((obj) => {
        return {
          currency: 'idr',
          coin: obj.coin,
          price: obj.price ? obj.price : 0,
          percent: obj.percent ? obj.percent : 0,
          status: obj.status ? 1 : 0,
        };
      });
      rf.getRequest('UserRequest')
        .createOrUpdateSlippery(payload)
        .then((res) => {
          Message.success(
            this.$i18n.t('slippery_note.update_success'),
            {},
            { position: 'top_right' }
          );
          this.isSaving = false;
          setTimeout(() => {
            this.$router.push(
              `/spot-exchange/basic?coin=${Const.DEFAULT_COIN}&currency=${Const.DEFAULT_CURRENCY}`
            );
          }, 1000);
        })
        .catch((err) => {
          this.initSlipperyNote();
          this.isSaving = false;
          Message.error(
            this.$i18n.t('slippery_note.update_failed'),
            {},
            { position: 'bottom_left' }
          );
        });
    },
    cancelSlippery() {
      ConfirmationModal.show({
        class: 'cancel-slippery-modal',
        type: 'warning-2',
        title: '',
        content: this.$i18n.t('slippery_note.slippery_reset'),
        btnCancelLabel: this.$i18n.t('common.action.no'),
        btnConfirmLabel: this.$i18n.t('common.action.yes'),
        btnRow: true,
        onConfirm: () => {
          this.$router.go(-1);
        },
        onCancel: function () {},
      });
    },
    validate() {
      this.slipperySetting = this.slipperySetting.map((item) => {
        if (item.status) {
          item.price_require = !Number(item.price);
          item.price_message = !item.price
            ? this.$i18n.t('slippery_note.field_required')
            : Number(item.price) === 0 && this.$i18n.t('slippery_note.price_invalid');

          item.percent_require = !Number(item.percent);
          item.percent_message = !item.percent
            ? this.$i18n.t('slippery_note.fi')
            : Number(item.percent) === 0 && this.$i18n.t('slippery_note.percent_invalid');
        } else {
          item.price_require = false;
          item.price_message = '';
          item.percent_require = false;
          item.percent_message = '';
        }
        return item;
      });
      const isPass = !this.slipperySetting.some((item) => {
        if (item.status) {
          return !Number(item.price) || !Number(item.percent);
        } else {
          return false;
        }
      });

      return isPass;
    },
  },
  mounted() {
    document.title = this.$t('slippery_note.title') + ` – ${this.tabTitle}`;
  },
};
</script>
<style lang="scss">
#confirmationModal.cancel-slippery-modal {
  .modal-dialog.dialog-warning {
    padding: 0;
    max-width: 348px;

    .modal-body {
      .content_text {
        font-weight: 700 !important;
      }
    }

    @include mobile {
      min-width: auto;
      width: calc(100vw - 32px);
    }
  }
}
</style>

<style lang="scss" scoped>
#slippery-note {
  background-color: $grey-3;
  max-width: 1320px;
  margin: 0 auto;
  padding: 20px 20px 40px 20px;

  @include mobile {
    padding: 16px 16px 60px 16px;
  }

  .page-title {
    font-size: 24px;
    line-height: 40px;
    font-weight: 700;
    color: $text-main;
    text-transform: capitalize;

    @include mobile {
      font-size: 18px;
      line-height: 32px;
    }
  }

  .slippery-main {
    margin-top: 20px;
    background: #fff;
    border-radius: 10px;
    padding: 24px;

    @include mobile {
      margin-top: 16px;
    }

    .slippery-item {
      display: grid;
      grid-template-columns: 3fr 1.5fr 1fr;
      gap: 20px;
      margin-top: 40px;

      @include mobile {
        grid-template-columns: 1fr;
        gap: 16px;
        margin-top: 16px;
        border-top: solid 1px #ddd;
        padding-top: 16px;

        &:first-child {
          border-top: none;
          padding-top: 0;
        }
      }

      &:first-child {
        margin-top: 0;
      }

      .cl-1 {
        span {
          display: block;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: $text-secondary;
          margin-bottom: 5px;

          @include mobile {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
          }
        }

        .input-group {
          margin: 0;
          border: solid 1px $bg-main2;
          border-radius: 8px;
          overflow: hidden;
          display: grid;
          grid-template-columns: auto 80px;
          align-items: center;
          flex: 1;

          @include mobile {
            grid-template-columns: auto 70px;
          }

          :deep(.coin_input) {
            .price-input {
              border: none;
              border-radius: 0;
              font-size: 16px;
              padding: 5px 10px;
              width: 100%;
              border-right: solid 1px $bg-main2;
              text-align: center;
              color: $text-main2;

              @include mobile {
                font-size: 14px;
                line-height: 22px;
                font-weight: 300;
                text-align: left;
              }

              &::placeholder {
                color: $text-main2;
              }
            }

            span {
              @include mobile {
                font-size: 14px;
                font-weight: 300;
                right: 10px;
              }
            }
          }

          .coin {
            text-transform: uppercase;
            color: $text-main2;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;

            @include mobile {
              font-size: 14px;
              line-height: 22px;
              font-weight: 300;
            }

            img {
              height: 20px;
              width: 20px;
            }
          }
        }
      }

      .cl-2 {
        span {
          display: block;
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: $text-secondary;
          margin-bottom: 5px;
        }

        .input-group {
          margin: 0;
          border: solid 1px $bg-main2;
          border-radius: 8px;

          :deep(.percentage-input) {
            border: none;
            font-size: 16px;
            padding: 5px 50px 5px 10px;
            width: 100%;
            color: $text-main2;
            height: 42px;
          }
        }
      }

      .cl-3 {
        min-width: 125px;
        padding: 10px 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 10px;

        @include mobile {
          justify-content: flex-start;
          padding: 0;
        }

        span {
          font-size: 16px;
          color: $text-main;
          font-weight: 300;
          text-transform: capitalize;
        }

        .toggle-switch {
          cursor: pointer;
          width: 48px;
          height: 24px;
          background: $bg-main1;
          border-radius: 14px;

          .toggle-handle {
            display: inline-block;
            width: 16px;
            height: 16px;
            background: #fff;
            border-radius: 50%;
            margin: 4px;
            transition: all 0.3s ease;
          }

          &.off {
            background: $text-main-blur;

            .toggle-handle {
              margin-left: 4px;
              opacity: 1;
            }
          }

          &.on {
            background: $bg-main1;

            .toggle-handle {
              margin-left: 28px;
              opacity: 1;
            }
          }
        }
      }
    }

    .slippery {
      min-height: 300px;
      margin: auto;
      display: flex;

      .loader {
        display: block;
        margin: auto;
        border: 5px solid $color-grey-concrete;
        border-radius: 50%;
        border-top: 5px solid $bg-main1;
        width: 40px;
        height: 40px;
        -webkit-animation: spin 2s linear infinite;
        /* Safari */
        animation: spin 2s linear infinite;
      }
    }
  }

  .slippery-action {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    gap: 16px;

    @include mobile {
      margin-top: 16px;
    }

    button {
      &.btn-cancel {
        @include btn-common(contained, lg);
        background: transparent;
        border: solid 1px $text-main2;
        color: $text-main;
        width: 200px;
        max-width: 100%;

        @include mobile {
          padding: 9px 32px;
          max-height: 42px;
        }
      }

      &.btn-save {
        @include btn-common(contained, lg);
        width: 200px;
        max-width: 100%;

        @include mobile {
          padding: 9px 32px;
          max-height: 42px;
        }
      }
    }
  }
}
</style>
