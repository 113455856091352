<template>
  <div class="amount-box">
    <span class="amount-input">
      <currency-input
        @focus="$emit('focus')"
        :precision="2"
        v-model="amount"
        :class="{ error: errors.has('amount') }"
        class="form-input-amount txt-right"
        id="amount"
        :placeholder="placeholder"
      />
      <span class="max-amount">
        <span class="color-grey-dark">{{ coin | uppercase }}</span>
      </span>
      <span class="max-amount responsive">
        <span class="color-grey-dark">{{ coin | uppercase }}</span>
      </span>
    </span>
    <span class="submit" @click="$emit('withdraw')">{{
      $t("funds.withdrawals.submit")
    }}</span>
    <span class="set-max" @click="setMaxAmount()">{{
      $t("common.max_only")
    }}</span>
    <span class="invalid-feedback" v-if="errors.has('amount')">{{
      $t(errors.first("amount"), { field: $t(errors.first("amount-field")) })
    }}</span>
    <span class="available responsive">
      {{ $t("funds.withdrawals.withdrawal_placeholder_available") }}
      {{ calcAmountAvailable() | formatCurrencyAmount(coin, "0") }}</span
    >
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import Utils from "@/common/Utils";

export default {
  inject: ["$validator"],
  data() {
    return {
      amount: "",
    };
  },
  props: {
    placeholder: { required: false, type: String },
    value: { required: true },
    coin: { type: String, required: true },
    withdrawLimit: { type: Object, required: true },
    minAmount: { type: String, required: false },
    balance: { type: Object, required: true },
    withdrawDaily: { type: String, default: "0" },
  },
  computed: {
    maxWithdrawAvailable: function () {
      const limit = `${this.withdrawLimit.limit || 0}`;
      const fee = `${this.withdrawLimit.fee || 0}`;
      const dailyLimit = `${this.withdrawLimit.daily_limit || 0}`;
      const withdrawDaily = `${this.withdrawDaily || 0}`;
      const availableBalance = this.balance.available_balance || 0;
      const withdrawDailyLimit = BigNumber.max(
        new BigNumber(dailyLimit).minus(withdrawDaily),
        "0"
      );

      let withdrawDailyAvailable = new BigNumber("0");
      if (
        new BigNumber(availableBalance).comparedTo(
          BigNumber.min(withdrawDailyLimit, limit).add(fee)
        ) === -1
      ) {
        withdrawDailyAvailable = new BigNumber(availableBalance).minus(fee);
      } else {
        withdrawDailyAvailable = BigNumber.min(withdrawDailyLimit, limit);
      }
      return BigNumber.max(withdrawDailyAvailable, "0").toString();
    },

    limitOfDay() {
      return new BigNumber(`${this.withdrawLimit.daily_limit || 0}`)
        .minus(`${this.withdrawDaily || 0}`)
        .toString();
    },

    externalAmount() {
      return new BigNumber(`${this.amount || 0}`)
        .sub(`${this.withdrawLimit.fee || "0"}`)
        .toString();
    },
  },

  watch: {
    value(val) {
      const externalAmount = new BigNumber(`${this.externalAmount || 0}`);
      if (externalAmount.comparedTo(`${val || 0}`) === 0) {
        return;
      }
      this.amount = val;
    },

    amount: function (newVal) {
      if (window._.isEmpty(newVal)) {
        return this.emitInput("");
      }
      const newAmount = new BigNumber(`${newVal || 0}`);
      if (newAmount.comparedTo("0") < 1) {
        return this.emitInput("0");
      }
      this.emitInput(this.externalAmount);
    },

    coin: function () {
      this.amount = "";
    },
  },

  methods: {
    emitInput(newVal) {
      if (newVal !== this.value) {
        this.$emit("input", newVal);
      }
    },

    calcAmountAvailable() {
      const amount = new BigNumber(`${this.maxWithdrawAvailable || 0}`).add(
        `${this.withdrawLimit.fee || 0}`
      );
      if (amount.comparedTo(this.withdrawLimit.fee || 0) === 0) {
        return "0";
      }
      return amount.toString();
    },

    validate() {
      if (window._.isEmpty(this.amount)) {
        this.errors.add("amount", "funds.withdrawals.errors.required");
      }
      const amount = `${this.amount || 0}`;
      let minAmount = `${this.minAmount}`;

      if (new BigNumber(amount).comparedTo(minAmount) < 0) {
        this.errors.add(
          "amount",
          this.$i18n.t("funds.withdrawals.errors.min_amount", {
            min_amount: Utils.formatCurrencyAmount(minAmount, this.coin, "0"),
          })
        );
      }
      if (
        new BigNumber(amount).comparedTo(
          `${this.balance.available_balance || 0}`
        ) > 0
      ) {
        this.errors.add(
          "amount",
          this.$i18n.t("funds.withdrawals.errors.max_balance")
        );
      }
      if (
        new BigNumber(amount).comparedTo(`${this.withdrawLimit.limit || 0}`) > 0
      ) {
        this.errors.add(
          "amount",
          this.$i18n.t("funds.withdrawals.errors.max_once_amount")
        );
      }
      if (new BigNumber(amount).comparedTo(`${this.limitOfDay || 0}`) > 0) {
        this.errors.add(
          "amount",
          this.$i18n.t("funds.withdrawals.error_message.over_daily_limit")
        );
      }
    },

    setMaxAmount() {
      this.amount = this.calcAmountAvailable();
    },
  },
};
</script>

<style lang="scss" scoped>
.f-left {
  float: left;
}

.form-input-amount {
  width: 100%;
  height: 41px;
  border-radius: 12px;
  border: solid 1px $color-alto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10.5px 16px;
  gap: 10px;
  &:focus {
    border: 1px solid $bg-main1 !important;
  }
  @include text-font($helvetica, $dark-1, $font-medium-bigger, 500, 20px);
  &::placeholder {
    @include text-font($helvetica, $dark-4, $font-medium-bigger, 500, 20px);
  }
  &.error {
    border: 1px solid $color-red !important;
    background: rgba(255, 91, 91, 0.1) !important;
  }
  input {
    background: transparent;
  }
}

.amount-box {
  width: 100%;
  // height: 41px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // gap: 15px;
  flex-wrap: wrap;
  .amount-input {
    width: calc(100% - 110px);
    position: relative;
    float: left;
    height: 100%;
    max-height: 41px;
    @include text-font($helvetica, $dark-4, $font-medium-bigger, 500, 20px);
    input {
      height: 100%;
      padding-right: 120px;
      height: 41px;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10.5px 16px;
      gap: 10px;
      @include text-font($helvetica, $dark-1, $font-medium-bigger, 500, 20px);
    }
    .max-amount {
      position: absolute;
      right: 10px;
      z-index: 9;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      cursor: pointer;
    }
  }
  .set-max {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10.5px 20px;
    gap: 10px;
    width: 77px;
    height: 41px;
    background: $max-bg-color;
    border: 1px solid $primary-2;
    border-radius: 12px;
    @include text-font($helvetica, $primary-2, $font-medium-bigger, 800, 20px);
  }
  .color-grey-dark {
    color: $text-main;
  }
}
.responsive {
  display: none;
}
@include mobile {
  .max-amount {
    display: none;
  }
  .responsive {
    display: block;
  }
  .input-group {
    margin: 0;
  }
  .amount-box {
    height: auto;
    .amount-input {
      height: auto;
      width: calc(100% - 70px);
      input {
        padding: 10px 45px 10px 10px;
      }
    }
    .set-max {
      padding: 10.5px 10px;
      width: 57px;
      height: 41px;
    }
  }
  .available {
    color: $dark-3;
    margin-top: 10px;
  }
}
</style>
