<template>
  <div>
    <div class="bank-list" v-if="!showDetailBank">
      <div class="bank-item" v-for="bank in bankOptions" :key="bank.id" @click="selectBank(bank)">
        <img src="/images/icon/bank.svg" alt="bank-card" />
        <div class="bank">
          <div class="bank-name">{{ bank.name }}</div>
          <div class="bank-description">
            {{ $t('deposit_fiat_modal.available_transfer') }}
          </div>
        </div>
        <img src="@/assets/images/icon/expand-right.svg" height="15" alt="expand-right" />
      </div>
    </div>
    <div class="bank-detail" v-else>
      <div class="bank-name-selected">
        <div>{{ $t('deposit_fiat_modal.transfer_to') }}</div>
        <div>{{ bankSelected.name }}</div>
      </div>
      <div class="description">
        {{ $t('deposit_fiat_modal.complete_payment', { code: bankSelected.code }) }}
      </div>
      <template v-if="bankSelected.code === 'echannel'">
        <div class="account-number">
          <span
            >{{ $t('deposit_fiat_modal.bill_key') }}: <strong>{{ pending.bill_key }}</strong></span
          >
          <img
            class="copy"
            src="@/assets/images/icon/copy.svg"
            height="20"
            alt="copy"
            @click="copyToClipboard(pending.bill_key)"
          />
        </div>
        <div class="account-number">
          <span
            >{{ $t('deposit_fiat_modal.bill_code') }}:
            <strong>{{ pending.biller_code }}</strong></span
          >
          <img
            class="copy"
            src="@/assets/images/icon/copy.svg"
            height="20"
            alt="copy"
            @click="copyToClipboard(pending.biller_code)"
          />
        </div>
      </template>
      <template v-else>
        <div class="account-number">
          <div>
            {{ $t('deposit_fiat_modal.virtual_account_number') }}:
            <span class="account-number-select">{{ bankSelected.accountNumber }}</span>
          </div>
          <img
            class="copy"
            src="/images/wallet/copy-icon.svg"
            height="20"
            alt="copy"
            @click="copyToClipboard(bankSelected.accountNumber)"
          />
        </div>
        <div class="account-number">
          <div>
            {{ $t('deposit_fiat_modal.account_name') }}:
            <span class="account-number-select">{{ bankSelected.accountName }}</span>
          </div>
        </div>
      </template>
      <div class="tips" @click="toggleShowInstruction">
        <img src="@/assets/images/icon/tips.svg" height="24" alt="tips" />
        <span>{{ $t('deposit_fiat_modal.how_to_pay') }}</span>
      </div>
      <button class="btn-back" @click="backToWallet()">
        {{ $t('deposit_fiat_modal.got_it') }}
      </button>
    </div>
    <InstructionModal :isShow="showInstruction" @close="closeInstruction" />
  </div>
</template>

<script>
import CountDown from '@/components/shared_components/funds/common/CountDown.vue';
import rf from '@/request/RequestFactory';
import InstructionModal from '@/components/shared_components/funds/deposit_usd/InstructionModal.vue';
import BigNumber from 'bignumber.js';

export default {
  name: 'BankMethods',
  components: {
    CountDown,
    InstructionModal,
  },
  props: {
    amount: Object | null | String | Number | Array,
    confirmCreditCard: Boolean,
  },
  watch: {
    pending: {
      handler(newValue) {
        this.$emit('update:pending', newValue);
      },
    },
    confirmCreditCard: {
      handler(newValue) {
        this.showConfirmCardModal = newValue;
      },
    },
    showDetailBank: {
      handler(newValue) {
        this.$emit('update:showConfirmCardModal', newValue);
      },
    },
  },
  data() {
    return {
      pending: {},
      duration: 600,
      showDetailBank: false,
      bankSelected: {},
      currencyName: 'idr',
      bankOptions: [
        // { id: 0, name: "BCA", code: "bca", accountNumber: "" },
        // { id: 1, name: "Echannel", code: 'echannel', accountNumber: "" },
        // { id: 2, name: "BNI", code: "bni", accountNumber: "" },
        { id: 3, name: 'Permata', code: 'permata', accountNumber: '' },
        // { id: 4, name: 'BRI', code: 'bri', accountNumber: '' },
      ],
      showInstruction: false,
    };
  },
  methods: {
    formatMoney(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    copyToClipboard(content) {
      if (!navigator.clipboard) {
        Utils.fallbackCopyTextToClipboard(content);
        return;
      }
      navigator.clipboard.writeText(content);

      Message.success(
        this.$i18n.t('funds.deposit_fiat.copy_success_message'),
        {},
        { position: 'top_right' }
      );
    },
    toggleShowInstruction() {
      this.showInstruction = !this.showInstruction;
      this.showDetailBank = false;
    },
    closeInstruction() {
      this.showInstruction = false;
      this.backToWallet();
    },
    selectBank(bank) {
      this.bankSelected = bank;
      this.showDetailBank = true;
      this.$emit('select-bank');
      this.duration = 600;
      let params = {
        amount: `${this.amount || 0}`,
        bank: this.bankSelected.code,
        payment_type: 'bank',
      };
      this.isLoading = true;
      rf.getRequest('TransactionRequest')
        .depositUsd(params)
        .then((res) => {
          this.$emit('bankMethodsSuccess', res.data);
          Message.success(
            this.$i18n.t('funds.deposit_fiat.success_message'),
            {},
            { position: 'top_right' }
          );
          this.isLoading = false;
          this.pending = res.data;
          this.bankSelected.accountNumber = res.data.va_number;
          this.bankSelected.accountName = 'PT ASET KRIPTO INTERNASIONAL';
        })
        .catch(function (error) {
          console.log('masukkkk');
        });
      if (this.$refs.countDown) {
        this.$refs.countDown.setCountDown();
      }
    },
    backToWallet() {
      this.showDetailBank = false;
      if (this.$refs.countDown) {
        this.$refs.countDown.resetCountDown();
      }
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body {
  padding: 20px;
  .amount {
    border: 1px solid $border-gray;
    border-radius: 10px;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .count-down {
      color: $primary-0;
    }
  }
  .bank-list {
    margin-top: 30px;
    .head-tit {
      font-size: 20px;
      line-height: 24px;
      color: #13273b;
      font-weight: 600;
    }
    .bank-item {
      width: 100%;
      height: 90px;
      padding: 16px;
      background: $bg-main3;
      border-radius: 8px;
      margin-top: 15px;
      display: grid;
      align-items: center;
      grid-template-columns: 24px auto 20px;
      gap: 8px;
      @include mobile {
        height: auto;
      }
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
      }
      .bank {
        display: flex;
        gap: 4px;
        margin-right: 8px;
        flex-direction: column;

        .bank-name {
          color: $text-main;
          font-size: 18px;
          font-weight: 700;
          line-height: 32px;
          @include mobile {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .bank-description {
          color: $text-secondary;
          font-size: 14px;
          font-weight: 300;
          line-height: 22px;
          @include mobile {
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }
  }
  .bank-detail {
    margin-top: 16px;
    font-size: 16px;
    line-height: 20px;
    .bank-name-selected {
      display: flex;
      justify-content: space-between;
      color: $text-main;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      @include mobile {
        font-size: 14px;
        line-height: 24px;
      }
    }
    .description {
      margin-top: 16px;
      color: $text-main;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      @include mobile {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .account-number {
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $text-main;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      @include mobile {
        font-size: 14px;
        line-height: 22px;
      }
      .account-number-select {
        color: $text-main;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        @include mobile {
          font-size: 14px;
          line-height: 24px;
        }
      }
      .copy {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }
    }
    .account-name {
      margin-top: 8px;
    }
    .tips {
      margin-top: 8px;
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      color: $text-main;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      @include mobile {
        font-size: 14px;
        line-height: 22px;
      }
    }
    .btn-back {
      margin-top: 24px;
      padding: 9px 32px;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 42px;
      border-radius: 60px;
      background: $bg-main6;
      color: $color-white;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
    }
  }
}
</style>
