<template>
  <div class="item-tab">
    <template v-if="isMobile">
      <div class="market-coin__list">
        <data-list-mobile ref="datatable" :get-data="getData" :table-no-data="true">
          <template slot="table-empty-header">
            <thead>
              <tr>
                <th v-if="isVisibleColumn('pair')" style="min-width: 150px">
                  <span>{{ $t('landing.pair') }}</span>
                </th>
                <th class="col-last-price" v-if="isVisibleColumn('last_price')">
                  <span>{{ $t('landing.last_price') }}</span>
                </th>
                <th class="col-24h-changed" v-if="isVisibleColumn('changed_percent')">
                  <span>{{ $t('landing.change') }}</span>
                </th>
                <th class="col-max-price" v-if="isVisibleColumn('max_price')">
                  <span>{{ $t('landing.high') }}</span>
                </th>
                <th class="col-min-price" v-if="isVisibleColumn('min_price')">
                  <span>{{ $t('landing.low') }}</span>
                </th>
                <th class="col-volume" v-if="isVisibleColumn('volume')">
                  <span>{{ $t('landing.volume') }}</span>
                </th>
              </tr>
            </thead>
          </template>
          <template slot="body" slot-scope="props">
            <div class="market-coin__item">
              <div
                class="market-coin__item-title"
                @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
                v-if="isVisibleColumn('pair')"
              >
                <img
                  :src="
                    isFavorite(props.item.coin, props.item.currency)
                      ? '/images/spot_exchange/star.svg'
                      : '/images/spot_exchange/star-empty.svg'
                  "
                  @click.stop="
                    isFavorite(props.item.coin, props.item.currency)
                      ? removeFavorite(props.item.coin, props.item.currency)
                      : insertFavorite(props.item.coin, props.item.currency)
                  "
                />
                <span class="coin-icon coin-icon__mobile">
                  <img class="coin" :src="STORAGE_URL + '/icons/' + props.item.coin + '.png'" />
                  <img
                    class="currency"
                    :src="STORAGE_URL + '/icons/' + props.item.currency + '.png'"
                  />
                </span>
                <span class="coin"
                  >{{ props.item.coin | currencyName }} /
                  {{ props.item.currency | currencyName }}</span
                >
              </div>
              <div class="market-coin__item-body">
                <div class="item-desc__wrapper">
                  <div
                    class="item-desc__value"
                    @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
                  >
                    <span class="change-node" :class="calculateLastPriceStatus(props.item)">
                      {{ props.item.last_price | formatCurrencyAmount(props.item.currency, '0') }}
                    </span>
                    <span
                      class="is-muted"
                      v-if="props.item.currency !== 'idr' && props.item.last_price_fiat > 0"
                    >
                      /
                      {{ props.item.last_price_fiat | formatCurrencyAmount('idr', '0') }}
                      IDR
                    </span>
                  </div>
                  <div
                    class="item-desc__title"
                    data-sort-field="last_price"
                    @click="(e) => onClickSortField(e, props)"
                  >
                    {{ $t('landing.last_price') }}
                    <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                  </div>
                </div>
                <div class="item-desc__wrapper">
                  <div
                    class="item-desc__value"
                    @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
                    :class="{
                      grown: props.item.changed_percent > 0,
                      down: props.item.changed_percent < 0,
                      black: props.item.changed_percent == 0,
                    }"
                  >
                    {{ props.item.changed_percent | changedPercent }}
                  </div>
                  <div
                    class="item-desc__title"
                    data-sort-field="changed_percent"
                    @click="(e) => onClickSortField(e, props)"
                  >
                    {{ $t('landing.change') }}
                    <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                  </div>
                </div>
                <div class="item-desc__wrapper">
                  <div
                    class="item-desc__value"
                    @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
                  >
                    {{ props.item.volume | formatCurrencyAmount(props.item.currency, '0') }}
                  </div>
                  <div
                    class="item-desc__title"
                    data-sort-field="volume"
                    @click="(e) => onClickSortField(e, props)"
                  >
                    {{ $t('landing.volume') }}
                    <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </data-list-mobile>
      </div>
    </template>
    <template v-else>
      <data-table3
        ref="datatable"
        :get-data="getData"
        :table-no-data="true"
        id="data-table-landing"
        class="table-landing"
        :height="30"
      >
        <template slot-scope="props">
          <th
            data-sort-field="coin"
            v-if="isVisibleColumn('pair')"
            @click="props.echoclick"
            style="min-width: 150px"
          >
            <span>{{ $t('landing.pair') }}</span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th
            data-sort-field="last_price"
            class="col-last-price"
            v-if="isVisibleColumn('last_price')"
            @click="props.echoclick"
          >
            <span>{{ $t('landing.last_price') }}</span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th
            data-sort-field="changed_percent"
            class="col-24h-changed"
            v-if="isVisibleColumn('changed_percent')"
            @click="props.echoclick"
          >
            <span>{{ $t('landing.change') }}</span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th
            data-sort-field="max_price"
            class="col-max-price"
            v-if="isVisibleColumn('max_price')"
            @click="props.echoclick"
          >
            <span>{{ $t('landing.high') }}</span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th
            data-sort-field="min_price"
            class="col-min-price"
            v-if="isVisibleColumn('min_price')"
            @click="props.echoclick"
          >
            <span>{{ $t('landing.low') }}</span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
          <th
            data-sort-field="volume"
            class="col-volume"
            v-if="isVisibleColumn('volume')"
            @click="props.echoclick"
          >
            <span>{{ $t('landing.volume') }}</span>
            <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
            <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
          </th>
        </template>
        <template slot="body" slot-scope="props">
          <tr>
            <td
              class="text-left col-pair"
              @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
              v-if="isVisibleColumn('pair')"
            >
              <img
                :src="
                  isFavorite(props.item.coin, props.item.currency)
                    ? '/images/spot_exchange/star.svg'
                    : '/images/spot_exchange/star-empty.svg'
                "
                @click.stop="
                  isFavorite(props.item.coin, props.item.currency)
                    ? removeFavorite(props.item.coin, props.item.currency)
                    : insertFavorite(props.item.coin, props.item.currency)
                "
              />
              <span class="coin-icon">
                <img class="coin" :src="STORAGE_URL + '/icons/' + props.item.coin + '.png'" />
                <img
                  class="currency"
                  :src="STORAGE_URL + '/icons/' + props.item.currency + '.png'"
                />
              </span>
              <span class="coin"
                >{{ props.item.coin | currencyName }}/{{ props.item.currency | currencyName }}</span
              >
            </td>
            <td
              class="col-coin"
              @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
              v-if="isVisibleColumn('coin')"
            >
              {{ props.item.coin | fullName }}
            </td>
            <td
              class="col-last-price"
              @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
              :title="props.item.last_price | formatCurrencyAmount(props.item.currency, '0')"
              v-if="isVisibleColumn('last_price')"
            >
              <span class="change-node" :class="calculateLastPriceStatus(props.item)">
                <!-- {{
                  props.item.last_price
                    | formatCurrencyAmount(props.item.currency, "0")
                }} -->
                {{
                  props.item.last_price
                    ? Number(
                        parseFloat(props.item.last_price || 0).toFixed(
                          isPairPrecisionIsFour(props.item.coin, props.item.currency) ? 4 : 2
                        )
                      ).toLocaleString('en', {
                        minimumFractionDigits: isPairPrecisionIsFour(
                          props.item.coin,
                          props.item.currency
                        )
                          ? 4
                          : 2,
                      })
                    : '--'
                }}
              </span>
              <span
                class="is-muted"
                v-if="props.item.currency !== 'idr' && props.item.last_price_fiat > 0"
              >
                /
                {{ props.item.last_price_fiat | formatCurrencyAmount('idr', '0') }}
                IDR
              </span>
            </td>
            <td
              class="change-node col-24h-changed"
              @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
              :class="{
                grown: props.item.changed_percent > 0,
                down: props.item.changed_percent < 0,
                black: props.item.changed_percent == 0,
              }"
              :title="props.item.changed_percent | changedPercent"
              v-if="isVisibleColumn('changed_percent')"
            >
              {{ props.item.changed_percent | changedPercent }}
            </td>
            <td
              class="col-max-price"
              @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
              :title="props.item.max_price | formatCurrencyAmount(props.item.currency, '0')"
              v-if="isVisibleColumn('max_price')"
            >
              {{ props.item.max_price | formatCurrencyAmount(props.item.currency, '0') }}
            </td>
            <td
              class="col-min-price"
              @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
              :title="props.item.min_price | formatCurrencyAmount(props.item.currency, '0')"
              v-if="isVisibleColumn('min_price')"
            >
              {{ props.item.min_price | formatCurrencyAmount(props.item.currency, '0') }}
            </td>
            <td
              class="col-volume text-left"
              @click="selectedCurrenyPair(props.item.currency, props.item.coin)"
              :title="props.item.volume | formatCurrencyAmount(props.item.coin, '0')"
              v-if="isVisibleColumn('volume')"
            >
              {{ props.item.volume | formatCurrencyAmount(props.item.coin, '0') }}
            </td>
          </tr>
        </template>
      </data-table3>
    </template>
  </div>
</template>

<script>
import FavoritesManager from '@/common/FavoritesManager';
import COMMON_CONST from '@/common/Const';
import BigNumber from 'bignumber.js';
const ALL_COLUMNS = [
  'pair',
  'coin',
  'last_price',
  'changed_percent',
  'max_price',
  'min_price',
  'volume',
];

export default {
  components: {},
  props: {
    isMobile: Boolean,
    priceScope: { type: [Object, Array] },
    columns: {
      type: [Array],
      default: () => {
        return [];
      },
    },
    favorites: { type: [Array] },
    marketSelected: { type: String },
    zone: {},
  },
  data() {
    return {
      pricesSorted: {},
      FixSortIE: null,
    };
  },
  watch: {
    priceScope: {
      handler() {
        this.pricesSorted = this.priceScope;
        if (this.$refs.datatable) {
          this.$refs.datatable.refresh();
        }
      },
      immediate: true,
    },
    // pricesSorted() {
    //   if (this.$refs.datatable) {
    //     this.$refs.datatable.refresh()
    //   }
    // },
  },
  computed: {
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === 'storage_url').value;
    },
    visibleColumns() {
      if (window._.isEmpty(this.columns)) {
        return ALL_COLUMNS;
      }
      return this.columns;
    },
  },
  methods: {
    onClickSortField(event, props) {
      props.onSort(event);
      props.echoclick(event);
    },
    calculateLastPriceStatus(item) {
      if (!item.last_price || !item.previous_price) {
        return {
          black: true,
        };
      }

      let compareResult = new BigNumber(item.last_price).comparedTo(item.previous_price);
      return {
        grown: compareResult > 0,
        down: compareResult < 0,
        black: compareResult == 0,
      };
    },
    isVisibleColumn(column) {
      return window._.indexOf(this.visibleColumns, column) > -1;
    },
    isFavorite(coin, currency) {
      const coinPair = this.getCoinPair(coin, currency);
      let result = window._.filter(this.favorites, function (value) {
        return value.coin_pair == coinPair;
      });
      return result.length > 0;
    },

    insertFavorite(coin, currency) {
      const coinPair = this.getCoinPair(coin, currency);
      FavoritesManager.addFavorite({ coin_pair: coinPair }).then((data) => {
        this.favorites.push(data);
      });
      // if (this.$refs.datatable) {
      //   this.$refs.datatable.refresh();
      // }
    },

    removeFavorite(coin, currency) {
      const coinPair = this.getCoinPair(coin, currency);
      let favoriteObject = window._.find(this.favorites, ['coin_pair', coinPair]);

      FavoritesManager.removeFavorite(favoriteObject).then((res) => {
        this.favorites.splice(this.favorites.indexOf(favoriteObject), 1);
        // In the favorites tab, table remove pair which isn't favorited.
        if (this.marketSelected === COMMON_CONST.FAVORITES_TAB) {
          this.pricesSorted = window._.filter(this.pricesSorted, (item) => {
            const pair = this.getCoinPair(item.coin, item.currency);
            return pair !== coinPair;
          });
        }
      });
      if (this.$refs.datatable) {
        this.$refs.datatable.refresh();
      }
    },

    getCoinPair(coin, currency) {
      return `${coin}/${currency}`;
    },

    selectedCurrenyPair(currency, coin) {
      if (!currency) {
        currency = 'eth';
      }
      if (!coin) {
        coin = 'btc';
      }

      const pair = { currency: currency, coin: coin };
      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        `?currency=${pair.currency}&coin=${pair.coin}`;
      // const newurl = `/spot-exchange/basic?currency=${pair.currency}&coin=${pair.coin}`;

      this.$broadcast('SpotSelectedPairMarket', { ...pair, zone: this.zone });
      // window.history.pushState({ path: newurl }, '', newurl);

      this.$router.replace(
        window.location.pathname + `?currency=${pair.currency}&coin=${pair.coin}`
      );
    },

    getData(params) {
      return new Promise((resolve) => {
        let data = Object.values(this.pricesSorted);
        if (this.marketSelected === COMMON_CONST.FAVORITES_TAB) {
          data = _.chain(data)
            .filter((item) => this.isFavorite(item.coin, item.currency))
            .value();
        } else {
          data = _.chain(data)
            .filter((item) => item.currency === this.marketSelected)
            .value();
        }
        if (params.sort && params.sort_type) {
          let temp = data.map((obj) => {
            if (params.sort === 'coin') {
              return {
                ...obj,
              };
            }
            return {
              ...obj,
              [params.sort]: new BigNumber(obj[params.sort]).toNumber() || 0,
            };
          });
          data = _.orderBy(temp, [params.sort], [params.sort_type]);
        }
        return resolve({ data: { data } });
      });
    },

    isPairPrecisionIsFour(coin, currency) {
      return (coin === 'ada' && currency === 'usdt') || (coin === 'xrp' && currency === 'usdt');
    },
  },
};
</script>

<style lang="scss" scoped>
@include tablet;
@include mobile {
  .market-coin__list .data-list__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .market-coin__item {
      padding: 16px;
      background-color: $bg-main4;
      .market-coin__item-title {
        img {
          width: 20px;
          height: auto;
        }
        & .coin-icon__mobile {
          img:nth-child(2) {
            margin-left: -8px;
          }
        }
        & > span {
          margin-left: 4px;
        }
        & .coin {
          margin-left: 6px;
          color: $text-main;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
        }
      }
      .market-coin__item-body {
        display: grid;
        margin-top: 16px;
        gap: 16px;
        grid-template-columns: 1fr 90px;
        .item-desc__wrapper {
          max-width: 100%;
          text-overflow: ellipsis;
          overflow: hidden;
          &:nth-child(2n + 2) {
            text-align: end;
          }
          .item-desc__title {
            margin-top: 3px;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: $text-main-blur;
          }
          .item-desc__value {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            color: $text-main;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
.item-tab {
  display: block;
  width: 100%;
  .grown {
    color: $color-green !important;
  }
  .down {
    color: $color-denim !important;
  }
  .table-landing {
    table {
      .group-sort-tabl3 {
        margin-left: 6px;
      }
      .icon-star1 {
        display: block;
        float: left;
        width: 15px;
        font-size: $font-medium;
        height: 20px;
        float: left;
        color: $color-grey;
        cursor: pointer;
      }
      .icon-star2 {
        color: $color-corn-pale;
        cursor: pointer;
      }

      th {
        padding: 25px 10px 10px 0;
        white-space: nowrap;
        @include mobile {
          padding: 10px 10px 10px 0;
        }
      }

      td {
        white-space: nowrap;
        text-align: left;
        &.text-left {
          text-align: left !important;
        }
      }
    }
  }
  .col-pair {
    font-family: $helvetica;
    display: flex;
    align-items: flex-end;
    gap: 10px;
    img {
      width: 20px;
      height: auto;
    }
    .coin {
      height: 20px;
      width: 20px;
      color: $dark-1;
      font-weight: 700;
      white-space: nowrap;
      @include mobile {
        font-size: 12px;
      }
    }
    .currency {
      height: 20px;
      width: 20px;
      color: $color-grey-dark;
      font-weight: 700;
      margin-left: -5px;
    }
  }

  .is-muted {
    color: $text-main;
  }
}
</style>

<style lang="scss" scoped>
.table-landing > table tr {
  padding: 0 25px;
  cursor: pointer;
}

.table-landing > table td {
  padding: 10px 10px 10px 0;
  color: $dark-1;
}

.table-landing > table td:last-child {
  padding-right: 25px;
}

.table-landing > table tr td:first-child {
  padding-left: 0;
}

td {
  font-family: $helvetica;
}
</style>

<style lang="scss">
#data-table-landing {
  table {
    tbody {
      tr {
        &.empty-data {
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}
</style>
