<template>
  <div>
    <modal
      :name="modalName"
      :hasModalFooter="false"
      :enableClose="false"
      width="414"
    >
      <template slot="body">
        <div slot="body" class="popup_body_cancel_google_auth_form">
          <div class="header">
            <img src="/images/close.svg" @click="closeModal" alt="close" />
          </div>
          <div class="icon">
            <!-- <span class="icon-moon icon-google"></span> -->
            <img src="/images/google-authen-blue.svg" alt="google-authen" />
            <div class="box-title">
              <h3>{{ $t("account.modal.cancel_2fa.title") }}</h3>
            </div>
          </div>
          <div class="input-group">
            <div class="form-input" :class="{ error: errors.has('password') }">
              <input
                type="password"
                @focus="resetErrors"
                name="password"
                :placeholder="$t('common.placeholders.login_password')"
                v-model="cancelGoogleAuthForm.password"
              />
            </div>
            <div class="invalid-feedback">
              <span v-show="errors.has('password')">
                {{ errors.first("password") }}
              </span>
            </div>
          </div>
          <div class="input-group last">
            <div class="form-input" :class="{ error: errors.has('code') }">
              <input-only-number
                autocomplete="off"
                @focus="resetErrors"
                :placeholder="$t('common.placeholders.otp')"
                v-model="cancelGoogleAuthForm.code"
                name="code"
                maxlength="6"
                @keyup.enter="delOtpRecoveryCode"
              >
              </input-only-number>
            </div>
            <div class="invalid-feedback">
              <span v-show="errors.has('code')">
                {{ errors.first("code") }}
              </span>
            </div>
          </div>
          <div class="form-group btn-group mb-0">
            <button
              :disabled="isSubmitting"
              class="btn-submit"
              @click="delOtpRecoveryCode"
            >
              {{
                getSubmitName($t("modal_trading_pair_information.btn_submit"))
              }}
            </button>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from "../common/Modal";
import rf from "@/request/RequestFactory";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      cancelGoogleAuthForm: {
        code: "",
        password: "",
      },
      modalName: "CancelGoogleAuthModal",
    };
  },
  methods: {
    closeModal() {
      this.$emit("CancelGoogleAuthModal:CLOSE");
    },
    resetErrors() {
      this.errors.clear();
    },
    async delOtpRecoveryCode() {
      if (this.isSubmitting) {
        return;
      }
      if (this.errors.any()) {
        return;
      }
      try {
        this.startSubmit();
        const res = await rf
          .getRequest("UserRequest")
          .delGoogleAuth(this.cancelGoogleAuthForm);
        this.endSubmit();
        this.$emit("CancelGoogleAuthModal:CLOSE");
        this.showSuccess(this.$t("account.modal.cancel_2fa.success"));
      } catch (error) {
        this.endSubmit();
        this.resetErrors();
        const errors = error.response.data.errors;
        Object.keys(errors).forEach((key) => {
          this.errors.add({
            field: key,
            msg: this.$t(error.response.data.errors[key][0]),
          });
        });
      }
    },
    showSuccess(message) {
      Message.success(message, {}, { position: "top_right" });
    },
    clearInput() {
      this.cancelGoogleAuthForm = Object.assign({
        code: "",
        password: "",
      });
    },
  },
  created() {
    this.$on("hideCommonModal", (modalName) => {
      if (modalName !== this.modalName) {
        return;
      }
      this.clearInput();
    });
  },
};
</script>
<style lang="scss">
#CancelGoogleAuthModal {
  .modal-dialog {
    max-width: 100%;
  }
  .modal-body {
    padding: 30px;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/sass/common.scss";

.popup_body_cancel_google_auth_form {
  .box-title {
    h3 {
      color: rgba($text-main2, 0.87);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-top: 14px;
      margin-bottom: 24px;
    }
    .title-l {
      margin: 10px 0px 10px 0px;
      height: 1px;
      width: 5%;
      border-radius: 5px;
      background-color: $color-light-grey;
    }
  }
  .icon {
    text-align: center;
    span {
      font-size: $font-title-size-big-small-2;
      color: $color-eden;
    }
  }
  label {
    font-weight: normal;
    color: $color-black;
    margin-bottom: 0px;
  }
  .note_header {
    color: $color-black;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .row_form {
    margin-top: 20px;
    position: relative;
  }

  .input-group {
    &.last {
      margin-bottom: 24px;
    }
  }

  .btn-group {
    width: 100%;
    .btn-submit {
      @include btn-common(contained, md);
      line-height: 32px;
      font-size: 18px;
      padding: 10px 32px;
    }
  }
}
.is-danger {
  color: $color-alizarin-crimson;
}
</style>
