<template>
  <div class="contain">
    <PageHeader :account="email" :totalBalance="totalBtcBalance" @logout="logout" />
    <main id="content" class="page-container" :class="{ home: $route.path === '/' }">
      <router-view />
    </main>
    <Loading :isLoading="isLoading" />
    <PageFooter v-if="!isPwa() && !isIOS()" />
    <Message />
    <ConfirmationModal />
    <ConfirmationModalSecond />
    <UnregisteredSessionDialog />
    <MasterdataChangedDialog />
  </div>
</template>

<script>
import rf from '@/request/RequestFactory';
import moment from 'moment';
import Utils from '@/common/Utils';
import Const from '@/common/Const';
import Message from '@/components/shared_components/common/Message';
import ConfirmationModal from '@/components/shared_components/common/ConfirmationModal.vue';
import ConfirmationModalSecond from '@/components/shared_components/common/ConfirmationModalSecond';
import UnregisteredSessionDialog from '@/components/shared_components/common/UnregisteredSessionDialog.vue';
import MasterdataChangedDialog from '@/components/shared_components/common/MasterdataChangedDialog.vue';
import BigNumber from 'bignumber.js';
import AuthenticationUtils from '@/common/AuthenticationUtils';
import PageHeader from './pages/common/PageHeader.vue';
import PageFooter from './pages/common/PageFooter.vue';
import Loading from '@/components/shared_components/common/Loading.vue';
import { mapMutations, mapState } from 'vuex';
import { getMessaging, getToken } from 'firebase/messaging';

export default {
  name: 'Layout',
  components: {
    Loading,
    Message,
    ConfirmationModal,
    UnregisteredSessionDialog,
    MasterdataChangedDialog,
    PageHeader,
    PageFooter,
    ConfirmationModalSecond,
  },
  data() {
    return {
      APP_NAME: process.env.VUE_APP_NAME,
      statusApp: false,
      articles: [],
      nav_page_bar: false,
      user_bar: false,
      lang_bar: false,
      funds_bar: false,
      order_bar: false,
      noti_extent: false,
      isAuthenticated: window.isAuthenticated,
      email: '',
      accountBalances: {},
      prices: {},
      subHover: '',
      showNewsList: false,
      isShowTradingVolumeRankingSpot: false,
      isShowTradingVolumeRankingMargin: false,
      news: [],
      newsCountUnRead: 0,
      currentNewsPage: 1,
      newsMaxPage: 1,
      userNewsInfo: {},
      newsReadIds: [],
      totalBtcBalance: '0',
      insurance: {},
    };
  },
  computed: {
    ...mapState({
      coins: (state) => state.masterdata.coins,
      isLoading: (state) => state.common.loading,
    }),
    isSpotExchangePage() {
      return this.$route.path === '/spot-exchange';
    },
    handlerCurrencyAmount() {
      let mount = this.totalBtcBalance | Utils.formatCurrencyAmount('btc', 0);
      let currentAmount = mount.toString().split('.')[0];
      return currentAmount;
    },
    handlerCurrencyAmount() {
      let amount = this.totalBtcBalance | Utils.formatCurrencyAmount('btc', 0);
      let currentAmount = (amount + '').split('.')[0];
      return currentAmount;
    },
  },
  methods: {
    ...mapMutations(['updateUser']),
    calculateTotalBtcBalance() {
      if (
        window._.isEmpty(this.prices) ||
        window._.isEmpty(this.coins) ||
        window._.isEmpty(this.accountBalances)
      ) {
        return '0';
      }
      this.totalBtcBalance = this.sumBalances();
      return this.totalBtcBalance;
    },
    formatCreatedAt(newsRecord) {
      const EMPTY_SYMBOL = '--';
      let createdAt = newsRecord.created_at || '';
      if (createdAt == '') {
        return EMPTY_SYMBOL;
      }

      let dateFormatted = moment(createdAt, 'YYYY-MM-DD HH:mm:ss').format('DD-MM');
      if (dateFormatted == 'Invalid date') {
        return EMPTY_SYMBOL;
      }

      return dateFormatted;
    },
    checkActive: function (tabIndex) {
      if (tabIndex == 1) {
        if (
          this.$route.path == '/funds/balances' ||
          this.$route.path == '/funds/withdrawals' ||
          this.$route.path == '/funds/deposits' ||
          this.$route.path == '/funds/history'
        ) {
          return true;
        } else {
          return false;
        }
      }
      if (tabIndex == 2) {
        if (
          this.$route.path == '/orders/open' ||
          this.$route.path == '/orders/history' ||
          this.$route.path == '/orders/trade-history'
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    async logout() {
      //dev
      // const vapidKey = "BB-uS7UJ68EVzvK76g9sSjrEFzhPba5o08rTKefl3DAokpkBUyQ0wXgXnjbLvCV8z34WhCI5kfe8aA6T_T954SA";
      //prod
      const vapidKey =
        'BCsek1ojxs5iFrXF9WTWnNvypB43v7tarIFjZ78SEpRkg0DXqFTjvfELPdWqmAyMsRjYgfQ38QMRrUZqD2J5UPc';
      AuthenticationUtils.removeAuthenticationData();

      // Create Log Logout
      await rf.getRequest('UserRequest').createLog({ activity: 'Logout' });

      if (!this.isPwa()) {
        this.gotoUrl('/');
        return;
      }

      if (this.isIOS()) {
        this.removeDeviceTokenRequest();
        return;
      }
      const messaging = getMessaging();
      if (
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers['request-device-token']
      ) {
        window.iOSPushCapability = true;
      }
      getToken(messaging, {
        vapidKey: vapidKey,
      }).then((res) => {
        rf.getRequest('UserRequest')
          .deleteDevice(res)
          .then(() => {
            this.gotoUrl('/');
          });
      });
    },
    removeDeviceTokenRequest() {
      if (window.iOSPushCapability) {
        window.webkit.messageHandlers['request-remove-device-token'].postMessage(
          'request-remove-device-token'
        );
      }
    },
    isIOS() {
      const browserInfo = navigator.userAgent.toLowerCase();
      if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
        return true;
      }
      if (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
          navigator.platform
        )
      ) {
        return true;
      }
      return false;
    },
    isPwa() {
      return ['fullscreen', 'standalone', 'minimal-ui'].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
      );
    },
    async handleRemoveDevice(event) {
      // Xử lý sự kiện request-device-token ở đây
      const token = event.detail;
      try {
        await rf.getRequest('UserRequest').deleteDevice(token);
        this.gotoUrl('/');
      } catch (error) {
        console.log(error);
      }
    },
    addPricesInBtc() {
      this.prices['btc_btc'] = { price: 1 };
      for (const fiat of Const.FIAT_LIKE_LIST) {
        const fiatPrice = this.prices[`${fiat}_btc`];
        const checkNullPrice = fiatPrice && !new BigNumber(fiatPrice.price).isZero();
        const price = checkNullPrice ? new BigNumber('1').div(fiatPrice.price).toString() : '0';
        this.prices[`btc_${fiat}`] = { price };
      }
    },
    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
        BalanceUpdated: this.onBalanceUpdated,
        ZendeskNewsUpdated: this.onZendeskNewsUpdated,
      };
    },
    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      this.addPricesInBtc();
      this.calculateTotalBtcBalance();
    },
    onBalanceUpdated(newAccountBalances) {
      this.accountBalances = window._.merge({}, this.accountBalances, newAccountBalances);
      this.calculateTotalBtcBalance();
    },
    onZendeskNewsUpdated(res) {
      let data = res.data || [];
      let currentLocale = window.localStorage.locale;
      if (currentLocale == 'en') {
        currentLocale = 'en-us';
      }

      for (let i = 0; i < data.length; i++) {
        let currentItem = data[i];
        let newItemLocale = currentItem.locale;
        if (newItemLocale == currentLocale) {
          this.news.push(currentItem);
          this.newsCountUnRead++;
        }
      }

      return this.news;
    },
    sumBalances() {
      let result = new BigNumber(0);
      let coinList = this.accountBalances[Const.TYPE_MAIN_BALANCE] || {};
      for (let coin in coinList) {
        result = result.plus(this.getTotalBalanceByCoin(coin));
      }
      return result.toNumber();
    },
    getTotalBalanceByCoin(coin) {
      let mainBalanceData = this.accountBalances[Const.TYPE_MAIN_BALANCE] || {};
      let marginBalanceData = this.accountBalances[Const.TYPE_MARGIN_BALANCE] || {};
      let exchangeBalanceData = this.accountBalances[Const.TYPE_EXCHANGE_BALANCE] || {};
      let mamBalanceData = this.accountBalances[Const.TYPE_MAM_BALANCE] || {};
      let airdropBalanceData = this.accountBalances[Const.TYPE_AIRDROP_BALANCE] || {};

      let item = {
        ...this.accountBalances[Const.TYPE_MAIN_BALANCE][coin],
      };

      item.balance =
        (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].balance) || 0;
      item.availableBalance =
        (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].available_balance) || 0;
      item.exchangeBalance =
        (exchangeBalanceData && exchangeBalanceData[coin] && exchangeBalanceData[coin].balance) ||
        0;
      item.exchangeAvailabelBalance =
        (exchangeBalanceData &&
          exchangeBalanceData[coin] &&
          exchangeBalanceData[coin].available_balance) ||
        0;
      item.marginBalance =
        (marginBalanceData && marginBalanceData[coin] && marginBalanceData[coin].balance) || 0;
      item.mamBalance =
        (mamBalanceData && mamBalanceData[coin] && mamBalanceData[coin].balance) || 0;
      item.airdropBalance =
        (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].balance) || 0;
      item.airdropBalanceBonus =
        (airdropBalanceData &&
          airdropBalanceData[coin] &&
          airdropBalanceData[coin].balance_bonus) ||
        0;

      item.exchangeBalance = this.formatCurrencyRoundDown(coin, item.exchangeBalance);

      let coinType = coin || '';
      item.totalBalance = new BigNumber(item.balance).add(item.exchangeBalance);

      if (coinType == Const.BTC) {
        item.totalBalance = new BigNumber(item.totalBalance).add(item.marginBalance);
      }
      if (coinType == Const.AML) {
        item.totalBalance = new BigNumber(item.totalBalance)
          .plus(item.marginBalance)
          .plus(item.airdropBalance)
          .plus(item.airdropBalanceBonus);
      }
      item.totalBalance = this.formatCurrencyRoundDown(coin, item.totalBalance.toString());

      item.btcValue = this.convertToBtcValue(coin, item.totalBalance);

      let usdValue = this.convertToUsdt(coin, item.totalBalance);
      if (!usdValue) {
        const pair = 'idr_btc';
        const price = this.prices[pair] ? this.prices[pair].price : '0';
        usdValue = new BigNumber(item.btcValue).mul(price).toString();
      }
      item.usdValue = usdValue;

      if (item.btcValue == 'NaN' || new BigNumber(item.btcValue).isZero()) {
        return 0;
      }
      return item.usdValue;
    },
    convertToBtcValue(coin, value) {
      let balance = value;
      if (new BigNumber(balance).isZero()) {
        return 0;
      }

      let pair = `btc_${coin}`;
      if (!this.prices[pair] || !this.prices[pair].price) {
        pair = `${coin}_btc`;
        if (!this.prices[pair] || !this.prices[pair].price) {
          return 0;
        }
        return new BigNumber(`${balance}`).div(this.prices[pair].price).toString();
      }
      return new BigNumber(`${balance}`).mul(this.prices[pair].price).toString();
    },
    convertToUsdt(coin, value) {
      let balance = value;
      if (new BigNumber(balance).isZero()) {
        return 0;
      }
      if (coin === Const.USDT) {
        return new BigNumber(balance).toString();
      }

      let pair = `usdt_${coin}`;
      let pairReverse = `${coin}_usdt`;
      if (this.prices[pair]) {
        return new BigNumber(`${balance}`).mul(this.prices[pair].price).toString();
        // return 1;
      } else if (this.prices[pairReverse]) {
        if (!this.prices[pairReverse].price) return '0';
        return new BigNumber(`${balance}`).div(this.prices[pairReverse].price).toString();
        // return 1;
      }
    },

    convertBtcToCoinValue(coin, value) {
      let balance = value;
      let pair = `btc_${coin}`;
      if (!this.prices[pair] || !this.prices[pair].price) {
        pair = `${coin}_btc`;
        if (!this.prices[pair] || !this.prices[pair].price) {
          return 0;
        }
        return new BigNumber(`${balance}`).mul(this.prices[pair].price).toString();
      }
      return new BigNumber(`${balance}`).div(this.prices[pair].price).toString();
    },
    formatCurrencyRoundDown(currency, value) {
      let numberOfDecimalDigits =
        currency && currency.toLowerCase() === 'idr'
          ? Const.NUMBER_OF_DECIMAL_DIGITS_FIAT
          : Const.NUMBER_OF_DECIMAL_DIGITS; //currency === 'idr' ? 2 : 8;

      let newValue = new BigNumber(value);
      return newValue.round(numberOfDecimalDigits, BigNumber.ROUND_FLOOR).toString();
    },
    getBalanceByCoin(coin) {
      if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return '0';
      if (coin === 'usdt') {
        return this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance;
      }
      if (coin === 'idr') {
        if (!this.prices['idr_usdt']) return '0';
        return new BigNumber(`${this.accountBalances[Const.TYPE_MAIN_BALANCE]['idr'].balance || 0}`)
          .div(`${this.prices['idr_usdt'].price}`)
          .toString();
      }
      const pair = `usdt_${coin}`;
      if (!this.prices[pair]) return '0';

      let coinBalance = this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance || 0;
      let coinPrice = this.prices[pair].price;
      return new BigNumber(coinBalance).mul(coinPrice).toString();
    },
    init() {
      noti_extent: false;
      rf.getRequest('UserRequest')
        .getCurrentUser()
        .then(async (res) => {
          this.email = window._.get(res, 'data.email', '');
          this.updateUser(res.data);
          try {
            await rf.getRequest('UserRequest').createLog({
              activity: `Visiting ${
                this.$route.name === 'Landing Page'
                  ? 'Landing'
                  : this.$route.name === 'IdentityPersonal'
                  ? 'KYC'
                  : this.$route.name
              } Page`,
            });
          } catch (e) {
            console.log(e);
          }
        });
      rf.getRequest('UserRequest')
        .getBalance()
        .then((res) => {
          this.onBalanceUpdated(res.data);
        });
      rf.getRequest('PriceRequest')
        .getPricesNow()
        .then((res) => {
          this.onPricesUpdated(res.data);
        });
    },
    getArticles() {
      rf.getRequest('ZendeskRequest')
        .getArticlesAnnouncement()
        .then((res) => {
          this.articles.push(res.articles);
        })
        .catch((error) => {});
    },
    gotoUrl(url) {
      window.location.href = url;
    },
    getNewsData(page = 1) {
      let params = {
        page: page,
        locale:
          window.localStorage.getItem('locale') == 'en'
            ? 'en-us'
            : window.localStorage.getItem('locale'),
      };
      return rf
        .getRequest('NewsRequest')
        .getList(params)
        .then((res) => {
          this.newsMaxPage = (res.data ? res.data.last_page : 1) || 1;
          return this.mappingNewsData(res);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    mappingNewsData(res) {
      let data = res.data ? res.data.data : [];
      for (let i = 0, length = data.length; i < length; i++) {
        let currentItem = data[i];
        this.news.push(currentItem);
      }
      this.markReadedNews();

      return this.news;
    },
    getUserNewsInfo() {
      let params = {
        locale: AuthenticationUtils.getLocale() == 'en' ? 'en-us' : AuthenticationUtils.getLocale(),
      };

      return rf
        .getRequest('NewsRequest')
        .getUserNewsInfo(params)
        .then((res) => {
          if (res.data) {
            let resData = res.data;
            this.userNewsInfo = resData;
            this.newsCountUnRead = resData.count_unread;
            this.newsReadIds = resData.news_read_ids;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        this.loadMoreNews();
      }
    },
    loadMoreNews() {
      if (this.currentNewsPage < this.newsMaxPage) {
        this.getNewsData(++this.currentNewsPage);
      }
    },
    markReaded(item) {
      if (item.isRead) return true;
      if (!this.isAuthenticated) return true;

      rf.getRequest('NewsRequest')
        .markReaded(item)
        .then((res) => {
          if (res.success && res.data) {
            let resData = res.data;
            this.newsReadIds && resData.id && this.newsReadIds.push(resData.id);
            if (this.newsCountUnRead > 0) {
              this.newsCountUnRead--;
            }
            if (this.userNewsInfo.count_unread > 0) {
              this.userNewsInfo.count_unread--;
            }
            this.markReadedNews();
          }
        });
    },
    markReadedNews() {
      for (var i = 0, length = this.news.length; i < length; i++) {
        let currentItem = this.news[i];
        if (this.newsReadIds.includes(currentItem.id)) {
          currentItem.isRead = 1;
        }
      }
    },
    getInsuranceFund() {
      return rf
        .getRequest('InsuranceRequest')
        .getInsuranceFund()
        .then((res) => {
          this.insurance = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    isAppMobile() {
      if (this.$route.path.includes('/terms') && this.$route.query.isMobileApp == 1) {
        this.statusApp = true;
      } else if (this.$route.path.includes('referral') && this.$route.query.isMobileApp == 1) {
        this.statusApp = true;
      }
    },
    getSettingShowLeaderboard() {
      rf.getRequest('LeaderboardRequest')
        .getLeaderboardSetting({ type: 'spot' })
        .then((res) => {
          if (res.success) {
            let result = res ? res.data : null;
            this.isShowTradingVolumeRankingSpot = result && result.value === '1';
          }
        });
    },
    async createLog() {
      try {
        await rf.getRequest('UserRequest').createLog({
          activity: `Visiting ${
            this.$route.name === 'Landing Page'
              ? 'Landing'
              : this.$route.name === 'IdentityPersonal'
              ? 'KYC'
              : this.$route.name
          } Page`,
        });
      } catch (e) {
        this.isSubmitting = false;
      }
    },
  },
  beforeDestroy() {
    // Gỡ bỏ lắng nghe sự kiện trước khi component bị hủy
    window.removeEventListener('request-remove-device-token', this.handleRemoveDevice);
  },
  mounted() {
    this.getUserNewsInfo().then((res) => {
      this.getNewsData();
    });

    this.getInsuranceFund();
    this.getSettingShowLeaderboard();

    if (this.isAuthenticated) {
      this.init();
    }
    let _this = this;
    this.$on('CHANGED_PASSWORD', (type, content, params, configs) => {
      _this.isAuthenticated = false;
    });
    this.isAppMobile();
    window.addEventListener('request-remove-device-token', this.handleRemoveDevice);
  },
  watch: {
    $route: function (to, from) {
      this.createLog();
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
$header-height: 74px;
$header-mobile: 60px;

.page-container {
  max-width: 100vw;
  width: 100%;
  margin: auto;
  padding: 0;
  min-height: calc(100% - 60px - 370px);

  @include tablet-d {
    margin-top: unset;
    max-width: 100vw;
    width: 100%;
    padding: 0;
    margin: auto;
    margin-top: $header-mobile;
    min-height: calc(100% - 60px - 670px);
  }
}

.f-fr {
  float: right;
}

.f-fl {
  float: left;
}
</style>
