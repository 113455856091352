<template>
  <div class="input-form-container">
    <div class="address-box">
      <div class="address-top-box">
        <template v-if="!isAddNewAddress">
          <div class="lhs-address-chain">
            <template v-if="enableWhiteListAndEmptyWhitlist">
              <span class="empty_whitelist form-input-block-chain">{{ $t("funds.withdrawals.empty_whitelist.text") }}
                <router-link :to="{ name: 'Address Manager' }" target="_blank">
                  {{
                    $t("funds.withdrawals.empty_whitelist.address_management")
                  }}</router-link>
              </span>
            </template>
            <template v-else>
              <input type="text" class="form-control form-input-block-chain" :maxlength="maxlength"
                v-model="address.blockchain_address" :disabled="enableWhiteList" @focus="$emit('focus')" ref="input"
                :class="{ error: errors.has('blockchain_address') }" />
            </template>
            <span class="btn-drop" :class="{ active_address: isTurnOnSelectWithdrawAddress }"
              @click="toggleSelectWithdrawAddresses()">
              <i class="glyphicon icon-arrow1" :class="{ show_address: isTurnOnSelectWithdrawAddress }"></i>
            </span>
          </div>
          <span v-show="errors.has('blockchain_address')" class="f-left invalid-feedback">{{
            errors.first("blockchain_address") }}</span>
        </template>

        <template v-else>
          <div class="lhs-address-chain">
            <input type="text" class="form-control form-input-block-chain" disabled="disabled"
              :placeholder="$t('funds.withdrawals.add_new_address')" @focus="$emit('focus')" />
            <span class="btn-drop" :class="{ active_address: isTurnOnSelectWithdrawAddress }"
              @click="toggleSelectWithdrawAddresses()">
              <i class="glyphicon icon-arrow1" :class="{ show_address: isTurnOnSelectWithdrawAddress }"></i>
            </span>
          </div>
        </template>
      </div>
      <div class="select" v-show="isTurnOnSelectWithdrawAddress"
        :class="{ active_address: isTurnOnSelectWithdrawAddress }">
        <span class="option" v-for="withdrawAddress in withdrawAddresses" @click="
          selectWithdrawAddress(
            withdrawAddress.wallet_address,
            withdrawAddress.wallet_sub_address
          )
          " :key="withdrawAddress.wallet_address">
          {{ withdrawAddress.wallet_name }} -
          {{ withdrawAddress.wallet_address }}
          <span v-if="withdrawAddress.wallet_sub_address">
            - {{ withdrawAddress.wallet_sub_address }}</span>
        </span>
        <span class="option" v-if="!enableWhiteList" @click="addNewAddress()">
          <img src="/images/icon/u_plus.svg" alt="icon" height="24" width="24" />
          {{ $t("funds.withdrawals.use_a_new_address") }}
        </span>
      </div>
    </div>

    <div class="new-address" v-if="isAddNewAddress">
      <div class="wallet-name input-group">
        <input type="text" class="form-input-block-chain" :maxlength="maxLength20" @focus="$emit('focus')"
          :class="{ error: isAddNewAddress && errors.has('wallet_name') }"
          :placeholder="$t('funds.withdrawals.withdrawal_placeholder_label')" v-model="address.wallet_name" />
        <span v-show="errors.has('wallet_name')" class="f-left invalid-feedback">{{ errors.first("wallet_name") }}</span>
      </div>
      <div class="sub-char">-</div>
      <div class="wallet-address input-group">
        <input type="text" class="form-input-block-chain" :maxlength="maxlength" @focus="$emit('focus')" :class="{
          error: isAddNewAddress && errors.has('blockchain_address'),
        }" :placeholder="$t('funds.withdrawals.withdrawal_placeholder_address')"
          v-model="address.blockchain_address" />
        <span v-show="errors.has('blockchain_address')" class="f-left invalid-feedback">{{
          errors.first("blockchain_address") }}</span>
      </div>
    </div>
    <div class="xrp-tag" v-if="coin === 'xrp' || coin === 'eos'">
      <label>{{ coin | uppercase }} {{ $t("funds.withdrawals.xrp_tag") }}</label>
      <!-- <label class="checkbox_nomemo"><input type="checkbox" @click="checkNoMemo($event.target.checked)"> {{ $t('funds.withdrawals.no_memo') }}</label> -->
      <input-destination-tag ref="blockchain_sub_address" :disabled="enableWhiteList"
        v-model="address.blockchain_sub_address" :coin="coin" @focus="$emit('focus')" />
      <!-- <span v-show="errors.has('blockchain_sub_address')" class="f-left invalid-feedback">{{ errors.first('blockchain_sub_address') }}</span> -->
    </div>
  </div>
</template>

<script>
import Utils from "@/common/Utils";
import InputDestinationTag from "./InputDestinationTag";
import COMMON_CONST from "@/common/Const";

export default {
  inject: ["$validator"],
  components: {
    InputDestinationTag,
  },
  data() {
    return {
      address: {},
      isAddNewAddress: false,
      isTurnOnSelectWithdrawAddress: false,
      maxlength: COMMON_CONST.MAX_LENGTH_INPUT,
      maxLength20: COMMON_CONST.MAX_LENGTH_20,
      isNoMemo: false,
    };
  },

  computed: {
    enableWhiteListAndEmptyWhitlist() {
      return this.enableWhiteList && this.withdrawAddresses.length === 0;
    },
  },

  props: {
    value: { type: Object, required: true },
    withdrawAddresses: { type: Array, required: true },
    coin: { type: String, required: true },
    enableWhiteList: { type: Boolean, default: false },
  },

  watch: {
    value: function (newVal) {
      if (_.isEmpty(newVal)) {
        return (this.address = {});
      }
      this.address = Object.assign({}, this.address, newVal);
    },
    address: {
      handler: function (newVal, oldVal) {
        const internalVal = JSON.stringify(newVal);
        const externalVal = JSON.stringify(this.value);
        if (internalVal === externalVal) {
          return;
        }
        this.$emit("input", newVal);
      },
      deep: true,
    },
    coin: function () {
      this.address = {};
      this.clearInternalErrors();
      this.isTurnOnSelectWithdrawAddress = false;
    },
    withdrawAddresses: function () {
      this.autoFillWithdrawAddress();
    },
    isAddNewAddress(val) {
      this.$emit("onAddNewAddress", val);
    },
  },

  methods: {
    checkNoMemo(e) {
      this.$emit("onIsNoMemo", e);
    },
    toggleSelectWithdrawAddresses() {
      if (this.enableWhiteListAndEmptyWhitlist) {
        return;
      }
      this.isTurnOnSelectWithdrawAddress = !this.isTurnOnSelectWithdrawAddress;
    },

    addNewAddress() {
      this.address = Object.assign({}, this.address, {
        blockchain_address: "",
        blockchain_sub_address: "",
      });
      this.clearInternalErrors();
      this.isAddNewAddress = true;
      this.isTurnOnSelectWithdrawAddress = false;
    },

    selectWithdrawAddress(address, subAddress) {
      this.address = Object.assign({}, this.address, {
        blockchain_address: address || "",
        blockchain_sub_address: subAddress || "",
      });
      this.clearInternalErrors();
      this.isAddNewAddress = false;
      this.isTurnOnSelectWithdrawAddress = false;
    },

    clearInternalErrors() {
      this.errors.remove("blockchain_address");
      this.errors.remove("wallet_name");
    },

    autoFillWithdrawAddress() {
      const address = _.head(this.withdrawAddresses) || {};
      this.address = Object.assign({}, this.address, {
        blockchain_address: address.wallet_address || "",
        blockchain_sub_address: address.wallet_sub_address || "",
      });
    },

    validate() {
      if (this.enableWhiteListAndEmptyWhitlist) {
        return;
      }
      if (this.isAddNewAddress && _.isEmpty(this.address.wallet_name)) {
        this.errors.add(
          "wallet_name",
          this.$i18n.t("address.errors.label_required")
        );
      }
      if (_.isEmpty(this.address.blockchain_address)) {
        this.errors.add(
          "blockchain_address",
          this.$i18n.t("address.errors.address_required")
        );
      }
      if (this.coin === "xrp" || this.coin === "eos") {
        this.$refs.blockchain_sub_address.validate();
      }
      if (this.coin === "xrp" || this.coin === "eos") {
        if (
          this.address.blockchain_address &&
          this.address.blockchain_sub_address &&
          !Utils.isWalletAddress(
            this.coin,
            this.address.blockchain_address,
            this.address.blockchain_sub_address
          )
        ) {
          this.errors.add(
            "blockchain_address",
            this.$i18n.t("funds.withdrawals.errors.blockchain_address")
          );
        }
        if (this.isAddNewAddress) {
          this.withdrawAddresses.forEach((e) => {
            if (
              e &&
              e.wallet_address == this.address.blockchain_address &&
              e.wallet_sub_address == this.address.blockchain_sub_address
            ) {
              this.errors.add(
                "blockchain_address",
                this.$i18n.t(
                  "funds.withdrawals.errors.blockchain_address_sub_address_exists"
                )
              );
              // this.errors.add('blockchain_sub_address', this.$i18n.t('funds.withdrawals.errors.blockchain_address_sub_address_exists'));
            }
          });
        }
      } else {
        if (
          !Utils.isWalletAddress(this.coin, this.address.blockchain_address)
        ) {
          this.errors.add(
            "blockchain_address",
            this.$i18n.t("funds.withdrawals.errors.blockchain_address")
          );
        }
        if (this.isAddNewAddress) {
          this.withdrawAddresses.forEach((e) => {
            if (e && e.wallet_address == this.address.blockchain_address) {
              this.errors.add(
                "blockchain_address",
                this.$i18n.t(
                  "funds.withdrawals.errors.blockchain_address_exists"
                )
              );
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.input-form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .input-group {
    margin: 0px;
  }
}

input {
  height: 100%;
  padding-right: 120px;
  // height: 41px;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10.5px 16px;
  gap: 10px;
  @include text-font($helvetica, $dark-1, $font-medium-bigger, 500, 20px);
}

:deep() {

  .xrp-tag input,
  .form-input-block-chain {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;
    height: 42px;
    min-height: 42px;
    background: $color-white;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 100%;
    color: $text-main !important;
    font-size: 16px;

    @include mobile {
      font-size: 14px;
    }

    &::placeholder {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      color: $text-secondary;
    }

    &:focus {
      border: 1px solid $bg-main1 !important;
      box-shadow: none;
    }
  }
}

.active_address {
  &:hover {
    background-color: $color-bright-gray;
  }
}

.icon-arrow1 {
  &:before {
    color: $dark-3;
  }
}

.address-box {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .address-top-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    // gap: 10px;
    .lhs-address-chain {
      display: flex;
      gap: 16px;

      @include mobile {
        gap: 8px;
      }
    }
  }

  .select {
    background: transparent;

    .option {
      cursor: pointer;
      @include text-font($helvetica, $text-main, 16px, 700, 24px);
      display: flex;
      gap: 8px;
      word-break: break-all;

      @include mobile {
        font-size: 14px;
      }
    }
  }

  .form-control {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;
    width: calc(100% - 54px);
    height: 41px;
    background: $color-white;
    border: 1px solid #ddd;
    border-radius: 8px;
    height: 42px;
    color: $text-main;
  }

  .btn-drop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 10px;
    width: 42px;
    min-height: 42px;
    background: $color-white;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;

    @include mobile {
      height: auto;
    }

    @include tablet {
      height: auto;
    }
  }

  .show_address {
    transform: rotate(180deg);
  }
}

.new-address {
  display: flex;
  align-items: flex-start;

  .form-control {
    border: 1px solid $color-grey-border;
    border-radius: 0;
    height: 42px;
    outline: none;
    background: $color-white;
  }

  .wallet-name,
  .wallet-address {
    flex: 1;
  }

  .sub-char {
    width: 15px;
    text-align: center;
    padding-top: 8px;
  }

  .error-box {
    width: 100%;
    margin: 5px 0;

    .is-error {
      display: inline-block;
    }
  }

  @include mobile-small {
    flex-direction: column;

    .wallet-name,
    .wallet-address {
      width: 100%;
    }
  }
}

.error-box {
  font-size: $font-smaller;
}

.empty_whitelist {
  padding: 10px 12px;
  font-size: 14px;
  color: $emperor;
  border: 1px solid $color-red;
  background: rgba(255, 91, 91, 0.1);
  width: calc(100% - 45px);
  display: inline-block;

  @include mobile {
    height: auto;
  }

  @include tablet {
    height: auto;
  }
}

input {

  &:focus,
  &:active {
    border: 1px solid $bg-main1;
  }

  &.error {
    border: 1px solid $color-red !important;
    background: rgba(255, 91, 91, 0.1) !important;
  }
}

.checkbox_nomemo {
  float: right;
}

.xrp-tag {
  label {
    text-transform: capitalize;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $text-secondary;
  }
}
</style>
