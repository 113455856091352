const openOrderHeader = [
  {
    id: "created_at",
    name: "order.open_order.date",
  },
  {
    id: "coin",
    name: "order.open_order.pair",
  },
  {
    id: "type",
    name: "order.open_order.type",
  },
  {
    id: "trade_type",
    name: "order.open_order.side",
  },
  {
    id: "price",
    name: "order.open_order.price",
  },
  {
    id: "quantity",
    name: "order.open_order.amount",
  },
  {
    name: "order.open_order.filled",
  },
  {
    name: "order.open_order.total",
  },
  {
    name: "order.open_order.trigger_conditions",
    textRight: true,
  },
];

const orderHistoryHeader = [
  {
    id: "updated_at",
    name: "order.open_order.date",
  },
  {
    id: "coin",
    name: "order.open_order.pair",
  },
  {
    id: "type",
    name: "order.open_order.type",
  },
  {
    id: "trade_type",
    name: "order.open_order.side",
  },
  {
    name: "order.order_history.average",
  },
  {
    id: "price",
    name: "order.open_order.price",
  },
  {
    name: "order.open_order.filled",
  },
  {
    name: "order.open_order.amount",
  },
  {
    name: "order.open_order.total",
  },
  {
    name: "order.open_order.trigger_conditions",
  },
  {
    name: "order.order_history.status",
  },
];

const tradeHistoryHeader = [
  {
    id: "created_at",
    name: "order.open_order.date",
  },
  {
    id: "coin",
    name: "order.open_order.pair",
  },
  {
    id: "trade_type",
    name: "order.open_order.side",
  },
  {
    id: "price",
    name: "order.open_order.price",
  },
  {
    name: "order.open_order.filled",
  },
  {
    id: "fee",
    name: "order.trade_history.fee",
  },
  {
    name: "order.open_order.total",
  },
];

export default {
  OPEN_ORDER_HEADER: openOrderHeader,
  ORDER_HISTORY_HEADER: orderHistoryHeader,
  TRADE_HISTORY_HEADER: tradeHistoryHeader,
};
