<template>
  <!-- NEW BALANCE LAYOUT -->
  <div id="balance" class="page_container" :class="{ is_no_single_page: !isSinglePage }">
    <div class="balance-page-container">
      <div class="balance-container">
        <div class="box-title title-medium-small" v-if="isSinglePage">
          {{ $t('funds.balances.balance') }}
        </div>

        <!-- end-box-title -->
        <div class="es-toolbar" v-if="isSinglePage">
          <div class="es-tb-left">
            <div class="es-tb-lhs">
              <div
                class="btn-small-icon text-center"
                @click="showNotification()"
                v-click-outside="hideNotification"
              >
                <!-- <span class="icon icon-help"></span> -->
                <div class="checkbox-input">
                  <input
                    type="checkbox"
                    @click="hideBalances = !hideBalances"
                    id="checkboxFiveInput"
                    name=""
                  />
                  <label for="checkboxFiveInput"></label>
                </div>
                <span class="checkbox-input-label"
                  >{{ $t('funds.balances.hide_small_balance') }}
                </span>
              </div>
            </div>
            <div class="input-search">
              <div class="group-btn-input-search">
                <input
                  type="text"
                  name=""
                  :placeholder="$t('approved_order.btn_search')"
                  v-model="search"
                  @keyup="searchCoin()"
                />
                <span class="btn-search" @click="searchCoin()"></span>
              </div>
            </div>
          </div>

          <div class="es-tb-right">
            <div class="estimate-value">
              <div class="total">
                {{ $t('funds.balances.estimated_value') }}:
                <span class="number-value"
                  >{{ totalUsdtBalance.toFixed(2) | formatCurrencyAmount('usdt', '0') }}
                  USDT /
                  {{ splitAmount(totalBalanceIdr, 'usdt') | formatCurrencyAmount('usdt', '0') }}
                  IDR
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- end es-toolbar -->
        <div class="table-container table-balance">
          <alert-with-button-modal />
          <!-- <message-with-icon /> -->
          <template v-if="isMobile">
            <data-list-mobile
              ref="datatable"
              :msg-empty-data="''"
              :get-data="loadDataBalance"
              :isLoading="isLoading"
              :isExpand="true"
              :limit="10"
              :height="30"
            >
              <template slot="table-empty-header">
                <div class="balance__mobile-title">
                  <div class="mobile-title__item">
                    {{ $t('funds.balances.coin') }}
                  </div>
                  <div class="mobile-title__item">
                    {{ $t('funds.balances.name') }}
                  </div>
                  <div class="mobile-title__item">
                    {{ $t('funds.balances.total_balance') }}
                  </div>
                </div>
              </template>
              <template slot="header" slot-scope="props">
                <div class="balance__mobile-title">
                  <div class="mobile-title__item">
                    {{ $t('funds.balances.coin') }}
                  </div>
                  <div class="mobile-title__item">
                    {{ $t('funds.balances.name') }}
                  </div>
                  <div
                    class="mobile-title__item"
                    data-sort-field="totalBalance"
                    @click="props.onSort"
                  >
                    {{ $t('funds.balances.total_balance') }}
                    <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                  </div>
                </div>
              </template>
              <template slot="body" slot-scope="props">
                <div class="balance__mobile-item">
                  <div class="mobile-item__wrapper">
                    <div class="item-wrapper__title">
                      <img
                        class="icon-coin"
                        :src="`${STORAGE_URL}/icons/${props.item.coin}.png`"
                        :alt="`${props.item.coin}-icon`"
                      />
                      {{ props.item.coin | uppercase }}
                    </div>
                    <div class="item-wrapper__title">
                      {{ props.item.name }}
                    </div>
                    <div class="item-wrapper__title total-balance">
                      {{
                        splitAmount(props.item.totalBalance, props.item.coin)
                          | toNumber
                          | formatCurrencyAmount(props.item.coin, '0')
                      }}
                    </div>
                    <div class="item-wrapper__dropdown-icon" @click="activeRow(props.item)">
                      <img
                        src="/images/instant-convert/icon-dropdown2.svg"
                        alt=""
                        v-if="rowSelected.coin === props.item.coin"
                      />
                      <img src="/images/instant-convert/icon-dropdown.svg" alt="" v-else />
                    </div>
                  </div>
                  <div
                    class="mobile-item__expand-content"
                    :class="{ active: rowSelected.coin === props.item.coin }"
                  >
                    <div class="expand-content__wrapper">
                      <div class="expand-content__title">
                        {{ $t('funds.balances.avaliable_balance') }}
                      </div>
                      <div class="expand-content__desc">
                        {{
                          splitAmount(props.item.availableBalance, props.item.coin)
                            | toNumber
                            | formatCurrencyAmount(props.item.coin, '0')
                        }}
                      </div>
                    </div>
                    <div class="expand-content__wrapper">
                      <div class="expand-content__title">
                        {{ $t('funds.balances.exchange_available_balance') }}
                      </div>
                      <div class="expand-content__desc">
                        {{
                          splitAmount(props.item.exchangeAvailabelBalance, props.item.coin)
                            | toNumber
                            | formatCurrencyAmount(props.item.coin, '0')
                        }}
                      </div>
                    </div>
                    <div class="expand-content__wrapper">
                      <div class="expand-content__title">
                        {{ $t('funds.balances.exchange_balance') }}
                      </div>
                      <div class="expand-content__desc">
                        {{
                          splitAmount(props.item.exchangeBalance, props.item.coin)
                            | toNumber
                            | formatCurrencyAmount(props.item.coin, '0')
                        }}
                      </div>
                    </div>
                    <hr />
                    <div class="content_transfer_form">
                      <form class="transfer_form">
                        <div class="group_transfer_form">
                          <label class="txt">{{ $t('funds.balances.from') }}</label>
                          <div class="wallet-type" v-if="props.item.isTransferFromMain">
                            {{ $t('funds.balances.avaliable_balance') }}
                          </div>
                          <div class="wallet-type" v-else>
                            {{ $t('funds.balances.exchange_balance') }}
                          </div>
                        </div>

                        <!-- Swap inputs button -->
                        <div class="group_transfer_form">
                          <button
                            class="btn-transfer-form mobile"
                            @click="swapBalance($event, props.item)"
                          >
                            <img src="/images/wallet/tranfer_icon.svg" />
                          </button>
                        </div>

                        <!-- To / Select balance type -->
                        <div class="group_transfer_form">
                          <label class="txt">{{ $t('funds.balances.to') }}</label>
                          <div class="wallet-type" v-if="!props.item.isTransferFromMain">
                            {{ $t('funds.balances.avaliable_balance') }}
                          </div>
                          <div class="wallet-type" v-else>
                            {{ $t('funds.balances.exchange_balance') }}
                          </div>
                        </div>

                        <!-- Amount -->
                        <div class="group_transfer_form">
                          <label class="txt">{{ $t('order.trade_history.amount') }}</label>
                          <div class="input-amount">
                            <currency-input
                              :precision="
                                props.item.coin == 'idr' ||
                                props.item.coin == 'usdt' ||
                                props.item.coin == 'busd'
                                  ? 2
                                  : 8
                              "
                              :class="{
                                error: errors.has('amount' + props.item.coin),
                              }"
                              class="input-form w_220"
                              :value="props.item.amount"
                              v-model="props.item.amount"
                              @focus="resetValidateRowWhenFocus(props.item)"
                              @blur="() => handleChangeAmount(props.item)"
                            />
                            <span class="name_amount">{{ props.item.coin }}</span>
                          </div>
                          <div
                            v-show="errors.has('amount' + props.item.coin)"
                            class="invalid-feedback"
                          >
                            {{ errors.first('amount' + props.item.coin) }}
                          </div>
                          <div
                            v-show="errors.has('server' + props.item.coin)"
                            class="invalid-feedback"
                          >
                            {{ errors.first('server' + props.item.coin) }}
                          </div>

                          <div class="group_input_form">
                            <!-- <span class="txt_left_group mgb-none"></span> -->
                            <label class="input_right_group percent">
                              <span
                                class="check_percent"
                                :class="{ active: percentActive === 25 }"
                                @click="onPercentClicked(props.item, 25)"
                                >25%</span
                              >
                              <span
                                class="check_percent"
                                :class="{ active: percentActive === 50 }"
                                @click="onPercentClicked(props.item, 50)"
                                >50%</span
                              >
                              <span
                                class="check_percent"
                                :class="{ active: percentActive === 75 }"
                                @click="onPercentClicked(props.item, 75)"
                                >75%</span
                              >
                              <span
                                class="check_percent"
                                :class="{ active: percentActive === 100 }"
                                @click="onPercentClicked(props.item, 100)"
                                >100%</span
                              >
                            </label>
                          </div>
                        </div>
                        <!-- Transfer button -->
                        <div class="group_transfer_form action-mobile">
                          <button
                            class="btn-send-transfer-form"
                            @click.prevent="transferBalance($event, props.item)"
                            :disabled="isSubmittingTransfer"
                          >
                            {{ $t('funds.balances.tranfer') }}
                          </button>
                          <button
                            class="btn-transfer-form btn-reload"
                            @click.prevent="onPercentClicked(props.item, 0)"
                          >
                            <img src="/images/icon/icon-reload.svg" class="reload" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </template>
            </data-list-mobile>
          </template>
          <template v-else>
            <data-table
              :getData="loadDataBalance"
              ref="datatable"
              :msgEmptyData="noFunds"
              :isLoading="isLoading"
              :limit="10"
              @DataTable:finish="onDatatableFinished"
            >
              <th data-sort-field="coin" class="cl-1 coin_balances" @click="FixSortIE = !FixSortIE">
                {{ $t('funds.balances.coin') }}
                <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
                <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
              </th>
              <th v-if="isSinglePage" @click="FixSortIE = !FixSortIE" class="cl-2">
                {{ $t('funds.balances.name') }}
              </th>
              <th class="cl-3 text-center after_none"></th>
              <th class="cl-4 after_none">
                {{ $t('funds.balances.deposit') }}
              </th>
              <th class="cl-5 after_none">
                {{ $t('funds.balances.withdrawal') }}
              </th>
              <th class="cl-6" data-sort-field="totalBalance" @click="FixSortIE = !FixSortIE">
                {{ $t('funds.balances.total_balance') }}
                <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
                <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
              </th>
              <th class="cl-7" data-sort-field="availableBalance" @click="FixSortIE = !FixSortIE">
                {{ $t('funds.balances.avaliable_balance') }}
                <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
                <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
              </th>
              <!-- <th data-sort-field="order">{{$t('funds.balances.in_order')}}</th> -->
              <th class="cl-8 after_none">
                {{ $t('funds.balances.transfer') }}
              </th>
              <th class="cl-7" data-sort-field="exchangeBalance" @click="FixSortIE = !FixSortIE">
                {{ $t('funds.balances.exchange_available_balance') }} /<br />{{
                  $t('funds.balances.exchange_balance') | titleCase
                }}
                <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
                <span v-if="FixSortIE === false" class="iconFixSorIE icon-arrow6"></span>
              </th>

              <template slot="body" slot-scope="props">
                <tr v-if="_.size(coinNames) > 0">
                  <td class="cl-1 coin_balances">
                    <img
                      class="icon-coin"
                      :src="`${STORAGE_URL}/icons/${props.item.coin}.png`"
                      :alt="`${props.item.coin}-icon`"
                    />
                    {{ props.item.coin | uppercase }}
                  </td>
                  <td v-if="isSinglePage" class="cl-2">
                    {{ props.item.name }}
                  </td>
                  <td class="cl-3 text-center">
                    <div
                      class="group_button_tooltip"
                      v-if="!props.item.isDeposit || !props.item.isWithdraw"
                    >
                      <div class="tooltip_hover">
                        {{ $t('funds.disable_coin_msg') }}
                      </div>
                      <a class="btn-deposit-tooltip">
                        <i class="icon-warning3"></i>
                      </a>
                    </div>
                  </td>
                  <td class="cl-4">
                    <router-link
                      v-if="props.item.isDeposit"
                      :to="
                        props.item.coin == 'idr'
                          ? { name: 'Deposit IDR' }
                          : {
                              name: 'Deposits',
                              query: { coin: props.item.coin },
                            }
                      "
                    >
                      <img class="icon_balances" src="/images/wallet/deposit_icon.svg" />
                    </router-link>
                    <a class="btn-lose-color" v-if="!props.item.isDeposit">
                      <img class="icon_balances" src="/images/wallet/deposit_icon.svg" />
                    </a>
                  </td>
                  <td class="cl-5">
                    <router-link
                      v-if="props.item.isWithdraw"
                      :to="
                        props.item.coin == 'idr'
                          ? { name: 'Withdraw IDR' }
                          : {
                              name: 'Withdrawals',
                              query: { coin: props.item.coin },
                            }
                      "
                    >
                      <img class="icon_balances" src="/images/wallet/withdrawal_icon.svg" />
                    </router-link>
                    <a class="btn-lose-color" v-if="!props.item.isWithdraw">
                      <img class="icon_balances" src="/images/wallet/withdrawal_icon.svg" />
                    </a>
                  </td>

                  <td class="cl-6">
                    {{
                      splitAmount(props.item.totalBalance, props.item.coin)
                        | toNumber
                        | formatCurrencyAmount(props.item.coin, '0')
                    }}
                  </td>
                  <td cllass="cl-7">
                    {{
                      splitAmount(props.item.availableBalance, props.item.coin)
                        | toNumber
                        | formatCurrencyAmount(props.item.coin, '0')
                    }}
                  </td>
                  <td class="cl-8 tranfer-cell" @click="activeRow(props.item)">
                    <span class="glyphicon icon_balances btn-transfer">
                      <img src="/images/wallet/tranfer_icon.svg" />
                    </span>
                  </td>
                  <td class="cl-9">
                    {{
                      splitAmount(props.item.exchangeAvailabelBalance, props.item.coin)
                        | toNumber
                        | formatCurrencyAmount(props.item.coin, '0')
                    }}
                    /
                    {{
                      splitAmount(props.item.exchangeBalance, props.item.coin)
                        | toNumber
                        | formatCurrencyAmount(props.item.coin, '0')
                    }}
                  </td>
                </tr>

                <template v-if="rowSelected.coin === props.item.coin">
                  <tr>
                    <td colspan="11" class="table_drop_balances">
                      <!-- <div class="close_tbale" @click="activeRow(props.item)"><i class="icon-close"></i></div> -->
                      <div class="content_transfer_form">
                        <form class="transfer_form">
                          <!-- From / Amount -->

                          <div class="group_transfer_form">
                            <label class="txt">{{ $t('funds.balances.from') }}</label>
                            <div class="wallet-type" v-if="props.item.isTransferFromMain">
                              {{ $t('funds.balances.avaliable_balance') }}
                            </div>
                            <div class="wallet-type" v-else>
                              {{ $t('funds.balances.exchange_balance') }}
                            </div>
                          </div>

                          <!-- Swap inputs button -->
                          <div class="group_transfer_form">
                            <label class="txt"></label>
                            <button
                              class="btn-transfer-form"
                              @click="swapBalance($event, props.item)"
                            >
                              <img src="/images/wallet/tranfer_icon.svg" class="transfer-icon" />
                            </button>
                          </div>

                          <!-- To / Select balance type -->
                          <div class="group_transfer_form">
                            <label class="txt">{{ $t('funds.balances.to') }}</label>
                            <div class="wallet-type" v-if="!props.item.isTransferFromMain">
                              {{ $t('funds.balances.avaliable_balance') }}
                            </div>
                            <div class="wallet-type" v-else>
                              {{ $t('funds.balances.exchange_balance') }}
                            </div>
                          </div>

                          <!-- Amount -->
                          <div class="group_transfer_form">
                            <label class="txt">{{ $t('order.trade_history.amount') }}</label>
                            <div class="input-amount">
                              <currency-input
                                :key="props.item.coin"
                                :precision="precisionByToken(props.item.coin.toLowerCase())"
                                :class="{
                                  error: errors.has('amount' + props.item.coin),
                                }"
                                class="input-form w_220"
                                :value="props.item.amount"
                                v-model="props.item.amount"
                                @focus="resetValidateRowWhenFocus(props.item)"
                                @blur="() => handleChangeAmount(props.item)"
                              />
                              <span class="name_amount">{{ props.item.coin }}</span>
                            </div>
                            <div
                              v-show="errors.has('amount' + props.item.coin)"
                              class="invalid-feedback"
                            >
                              {{ errors.first('amount' + props.item.coin) }}
                            </div>
                            <div
                              v-show="errors.has('server' + props.item.coin)"
                              class="invalid-feedback"
                            >
                              {{ errors.first('server' + props.item.coin) }}
                            </div>

                            <div class="group_input_form">
                              <!-- <span class="txt_left_group mgb-none"></span> -->
                              <label class="input_right_group">
                                <span
                                  class="check_percent"
                                  :class="{ active: percentActive === 25 }"
                                  @click="onPercentClicked(props.item, 25)"
                                  >25%</span
                                >
                                <span
                                  class="check_percent"
                                  :class="{ active: percentActive === 50 }"
                                  @click="onPercentClicked(props.item, 50)"
                                  >50%</span
                                >
                                <span
                                  class="check_percent"
                                  :class="{ active: percentActive === 75 }"
                                  @click="onPercentClicked(props.item, 75)"
                                  >75%</span
                                >
                                <span
                                  class="check_percent"
                                  :class="{ active: percentActive === 100 }"
                                  @click="onPercentClicked(props.item, 100)"
                                  >100%</span
                                >
                              </label>
                            </div>
                          </div>
                          <!-- Transfer button -->
                          <div class="group_transfer_form">
                            <label class="txt"></label>
                            <button
                              class="btn-send-transfer-form"
                              @click.prevent="transferBalance($event, props.item)"
                              :disabled="isSubmittingTransfer"
                            >
                              {{ $t('funds.balances.tranfer') }}
                            </button>
                          </div>
                          <div class="group_transfer_form">
                            <label class="txt"></label>
                            <button
                              class="btn-transfer-form btn-reload"
                              @click.prevent="onPercentClicked(props.item, 0)"
                            >
                              <img src="/images/icon/icon-reload.svg" class="reload" />
                            </button>
                          </div>
                        </form>
                      </div>
                    </td>
                  </tr>
                </template>

                <template v-if="props.item.isActiveRowMam">
                  <tr>
                    <td colspan="11" class="table_drop_balances">
                      <!-- <div class="close_tbale" @click="activeRowMam(props.item)"><i class="icon-close"></i></div> -->
                      No data
                    </td>
                  </tr>
                </template>
              </template>
            </data-table>
          </template>
          <!-- end pagination-container -->
        </div>
      </div>
      <!-- end table container -->
      <!-- <airdrop-balance/>
        <mam-transfer-history/> -->
    </div>
    <!-- end-balance-container -->
  </div>
  <!-- END NEW BALANCE LAYOUT -->
</template>

<script>
import rf from '@/request/RequestFactory';
import BigNumber from 'bignumber.js';
import Const from '@/common/Const';
import AlertWithButtonModal from '@/components/shared_components/common/AlertWithButtonModal';
import { mapState } from 'vuex';
import COMMON_CONST from '@/common/Const';

const MIN_USDT = 0.00000001;
const MIN_AMOUNT_CRYPTO = Math.pow(10, -8); // 10^-8
const TRANSFER_TYPE_MAIN_BALANCE = 'main';
const TRANSFER_TYPE_EXCHANGE_BALANCE = 'spot';
const TRANSFER_TYPE_MARGIN_BALANCE = 'margin';

// Prevent convert 0.00000001 to 1e-8
BigNumber.config({ EXPONENTIAL_AT: 15 });

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      FixSortIE: null,
      hideBalances: false,
      isShowNotification: false,
      search: '',
      limit: undefined,
      accountBalances: undefined,
      prices: undefined,
      balances: [],
      totalPendingWithdraws: [],
      selectedCoin: '',
      tradeCoin: [],
      displayPairCoin: false,
      tradeUsd: [],
      totalOrder: 0,

      isAuthenticated: window.isAuthenticated,
      coinSetting: {},
      coinsMasterData: {},
      coinNames: {},
      coinImages: {},
      noFunds: this.$i18n.t('funds.balances.no_data'),
      isSubmittingTransfer: false,
      isLoading: false,
      percentActive: '',
      rowSelected: {},
    };
  },
  components: {
    AlertWithButtonModal,
  },
  props: {
    isSinglePage: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    accountBalances(newAccBalances) {
      // const currencies = Object.keys(newAccBalances);
      // this.getTotalPendingWithdraw(currencies, this.refresh);
    },
    prices(newPrices) {
      this.refresh();
    },
    totalPendingWithdraws(newVal) {
      this.refresh();
    },
    hideBalances() {
      this.$refs.datatable.refresh();
    },
    isTransferFromMain() {
      this.$refs.datatable.refresh();
    },
  },
  computed: {
    ...mapState({
      masterData: (state) => state.masterdata,
    }),
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === 'storage_url').value;
    },
    totalUsdtBalance() {
      return this.sumBy('usdValue');
    },
    totalBalanceIdr() {
      const btcValue = this.totalUsdtBalance.toString();
      const price = this.prices && this.prices['idr_usdt'] ? this.prices['idr_usdt'].price : 0;
      // console.log('balance idr', btcValue, '---', price);
      return new BigNumber(btcValue).mul(price).toNumber();
    },

    usdAvailableBalance() {
      const usdBalance = this.balances.find((item) => item.coin === 'idr');
      return usdBalance ? usdBalance.availableBalance : 0;
    },
    isMobile() {
      if (!this.windowWidth) return;
      return this.windowWidth <= 768;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  methods: {
    precisionByToken(token) {
      if (token === 'idr' || token === 'usdt' || token === 'busd') {
        return 2;
      }
      return 8;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    splitAmount(amount, currency) {
      let amtStr = amount.toString() || '';
      const decimal =
        currency.toLowerCase() === 'idr' ||
        currency.toLowerCase() === 'usdt' ||
        currency.toLowerCase() === 'busd'
          ? COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS_FIAT
          : COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS;
      if (amtStr.split('.').length === 1) {
        return amtStr;
      } else {
        return amtStr.split('.')[0] + '.' + amtStr.split('.')[1].slice(0, decimal);
      }
    },
    displayPositive(value) {
      if (new BigNumber(`${value || 0}`).comparedTo(0) < 0) {
        return 0;
      }
      return value;
    },
    resetValidateRowWhenFocus(item) {
      this.errors.remove('amount' + item.coin);
      this.errors.remove('server' + item.coin);
      this.percentActive = '';
    },
    resetValidateRow(item) {
      item.isTransferFromMain = true;
      item.amount = 0;
      this.errors.remove('amount' + item.coin);
      this.errors.remove('server' + item.coin);
    },
    activeRow(item) {
      if (this.rowSelected.coin === item.coin) {
        this.rowSelected = {};
      } else {
        this.rowSelected = item;
        this.percentActive = '';
      }
      this.resetValidateRow(item);
      this.$forceUpdate();
    },
    HiddenAcriveRow(item) {
      this.$set(item, 'isActiveRow', false);
    },
    activeRowMam(item) {
      item.isActiveRowMam = !item.isActiveRowMam;
      this.$forceUpdate();
    },
    addPricesInBtc() {
      this.prices['btc_btc'] = { price: 1 };
      for (const fiat of Const.FIAT_LIKE_LIST) {
        const fiatPrice = this.prices[`${fiat}_btc`];
        const checkNullPrice = fiatPrice && !new BigNumber(fiatPrice.price).isZero();
        const price = checkNullPrice ? new BigNumber('1').div(fiatPrice.price).toString() : '0';
        this.prices[`btc_${fiat}`] = { price };
      }
    },
    isShowcoin(coin) {
      return coin === this.selectedCoin;
    },
    searchCoin() {
      this.$refs?.datatable.refresh();
    },
    showCoin(coin) {
      this.selectedCoin = coin;
      this.tradeCoin = this.currencyCoins.filter((item) => {
        return item.coin.indexOf(coin) > -1;
      });
      this.tradeUsd = this.currencyCoins.filter((item) => {
        return item.currency === 'idr';
      });
    },
    getMinBtc() {
      return MIN_USDT;
    },
    showNotification() {
      this.isShowNotification = !this.isShowNotification;
    },
    hideNotification() {
      this.isShowNotification = false;
    },
    async loadDataBalance(params) {
      this.isLoading = true;
      return await new Promise((resolve) => {
        let data = this.balances;

        data = _.filter(data, (item) =>
          `${item.coin} ${item.name}`.toLowerCase().includes(this.search.toLowerCase())
        );
        if (this.hideBalances) {
          data = _.filter(data, (item) => {
            let btcValue = new BigNumber(item.totalBalance);
            return btcValue.gte(MIN_USDT);
          });
        }
        if (params.sort && params.sort_type) {
          data = _.orderBy(
            data,
            (item) => {
              if (isNaN(item[params.sort])) {
                return item[params.sort];
              }
              return new BigNumber(`${item[params.sort]}`).toNumber();
            },
            [params.sort_type]
          );
        }

        setTimeout(() => {
          this.isLoading = false;
        }, 5000);

        return resolve({ data });
      });
    },
    refreshNow() {
      // Refresh datatable
      let immediateRefresh = false;
      this.transferData(immediateRefresh);
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    refresh() {
      const isNoData = window._.isEmpty(this.accountBalances) || window._.isEmpty(this.prices);
      if (isNoData) {
        return;
      }
      this.transferData();
      this.totalOrder = this.sumBy('orderBtcValue');
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    transferData(isMerge = true) {
      if (this.balances && this.balances.length > 0 && isMerge) {
        this.mergeTransferData();
        return true;
      }

      if (!this.accountBalances) return false;

      let data = [];
      let coinList = this.accountBalances[Const.TYPE_MAIN_BALANCE] || {};
      let mainBalanceData = this.accountBalances[Const.TYPE_MAIN_BALANCE] || {};
      let marginBalanceData = this.accountBalances[Const.TYPE_MARGIN_BALANCE] || {};
      let exchangeBalanceData = this.accountBalances[Const.TYPE_EXCHANGE_BALANCE] || {};
      let mamBalanceData = this.accountBalances[Const.TYPE_MAM_BALANCE] || {};
      let airdropBalanceData = this.accountBalances[Const.TYPE_AIRDROP_BALANCE] || {};

      for (let coin in coinList) {
        const item = {};
        item.coin = coin;
        item.name = this.getCoinName(coin);

        item.balance =
          (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].balance) || 0;
        item.availableBalance =
          (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].available_balance) ||
          0;
        item.exchangeBalance =
          (exchangeBalanceData && exchangeBalanceData[coin] && exchangeBalanceData[coin].balance) ||
          0;
        item.airdropBalanceBonus =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin].balance_bonus) ||
          0;
        item.availableAirdropBalanceBonus =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin].available_balance_bonus) ||
          0;
        item.exchangeAvailabelBalance =
          (exchangeBalanceData &&
            exchangeBalanceData[coin] &&
            exchangeBalanceData[coin].available_balance) ||
          0;
        item.marginBalance =
          (marginBalanceData && marginBalanceData[coin] && marginBalanceData[coin].balance) || 0;
        item.marginAvailabelBalance =
          (marginBalanceData &&
            marginBalanceData[coin] &&
            marginBalanceData[coin].available_balance) ||
          0;
        item.mamBalance =
          (mamBalanceData && mamBalanceData[coin] && mamBalanceData[coin].balance) || 0;
        item.airdropBalance =
          (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].balance) || 0;

        item.totalBalance = new BigNumber(item.balance).plus(item.exchangeBalance);
        let coinType = item.coin || '';
        if (coinType == Const.BTC) {
          item.totalBalance = new BigNumber(item.totalBalance).plus(item.marginBalance);
        }
        if (coinType == Const.AML) {
          item.totalBalance = new BigNumber(item.totalBalance)
            .plus(item.marginBalance)
            .plus(item.airdropBalance)
            .plus(item.airdropBalanceBonus);
        }
        item.totalBalance = this.formatCurrencyRoundDown(coin, item.totalBalance.toString());

        item.availableBalance = this.formatCurrencyRoundDown(coin, item.availableBalance);
        item.exchangeBalance = this.formatCurrencyRoundDown(coin, item.exchangeBalance);
        item.exchangeAvailabelBalance = this.formatCurrencyRoundDown(
          coin,
          item.exchangeAvailabelBalance
        );
        item.marginBalance = this.formatCurrencyRoundDown(coin, item.marginBalance);
        item.marginAvailabelBalance = this.formatCurrencyRoundDown(
          coin,
          item.marginAvailabelBalance
        );
        item.mamBalance = this.formatCurrencyRoundDown(coin, item.mamBalance);
        item.airdropBalance = this.formatCurrencyRoundDown(coin, item.airdropBalance);

        item.disableMarginBalance = coinType != Const.BTC && coinType != Const.AML;
        item.enableMamBalance = coinType == Const.BTC;
        if ([Const.BTC, Const.AML, Const.ETH].includes(coinType)) item.enableAirdropBalance = true;
        else item.enableAirdropBalance = false;
        item.isTransferFromMain = true;
        item.amount = 0;
        item.transferType = TRANSFER_TYPE_EXCHANGE_BALANCE;

        item.order = this.calculateInOrder(item);
        item.orderBtcValue = this.getOrderBtcBalance(item);
        item.btcValue = this.convertToBtcValue(coin, item.totalBalance);

        item.usdValue = this.convertToUsdtValue(coin, item.totalBalance);

        item.isDeposit = this.coinSetting[item.coin] && this.coinSetting[item.coin].is_deposit;
        item.isWithdraw = this.coinSetting[item.coin] && this.coinSetting[item.coin].is_withdraw;

        data.push(item);
      }

      this.balances = window._.sortBy(data, 'name');
      const usdData = this.balances.find((item) => item.coin === 'idr');
      this.balances = this.balances.filter((item) => item.coin !== 'idr');
      this.balances.unshift(usdData);
    },
    mergeTransferData() {
      let data = [];
      let coinList = this.accountBalances[Const.TYPE_MAIN_BALANCE];
      let mainBalanceData = this.accountBalances[Const.TYPE_MAIN_BALANCE];
      let marginBalanceData = this.accountBalances[Const.TYPE_MARGIN_BALANCE];
      let exchangeBalanceData = this.accountBalances[Const.TYPE_EXCHANGE_BALANCE];
      let mamBalanceData = this.accountBalances[Const.TYPE_MAM_BALANCE];
      let airdropBalanceData = this.accountBalances[Const.TYPE_AIRDROP_BALANCE];

      for (let coin in coinList) {
        const item = {};
        item.coin = coin;

        item.balance =
          (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].balance) || 0;
        item.availableBalance =
          (mainBalanceData && mainBalanceData[coin] && mainBalanceData[coin].available_balance) ||
          0;
        item.exchangeBalance =
          (exchangeBalanceData && exchangeBalanceData[coin] && exchangeBalanceData[coin].balance) ||
          0;
        item.airdropBalanceBonus =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin].balance_bonus) ||
          0;
        item.availableAirdropBalanceBonus =
          (airdropBalanceData &&
            airdropBalanceData[coin] &&
            airdropBalanceData[coin].available_balance_bonus) ||
          0;
        item.exchangeAvailabelBalance =
          (exchangeBalanceData &&
            exchangeBalanceData[coin] &&
            exchangeBalanceData[coin].available_balance) ||
          0;
        item.marginBalance =
          (marginBalanceData && marginBalanceData[coin] && marginBalanceData[coin].balance) || 0;
        item.marginAvailabelBalance =
          (marginBalanceData &&
            marginBalanceData[coin] &&
            marginBalanceData[coin].available_balance) ||
          0;
        item.mamBalance =
          (mamBalanceData && mamBalanceData[coin] && mamBalanceData[coin].balance) || 0;
        item.airdropBalance =
          (airdropBalanceData && airdropBalanceData[coin] && airdropBalanceData[coin].balance) || 0;

        // Note: Please re-check with
        // resources/assets/js/desktop/Layout.vue:getTotalBalanceByCoin
        // when edit this function
        item.totalBalance = new BigNumber(item.balance).plus(item.exchangeBalance);
        let coinType = item.coin || '';
        if (coinType == Const.BTC) {
          item.totalBalance = new BigNumber(item.totalBalance).plus(item.marginBalance);
        }
        if (coinType == Const.AML) {
          item.totalBalance = new BigNumber(item.totalBalance)
            .plus(item.marginBalance)
            .plus(item.airdropBalance)
            .plus(item.airdropBalanceBonus);
        }
        item.totalBalance = this.formatCurrencyRoundDown(coin, item.totalBalance.toString());

        item.availableBalance = this.formatCurrencyRoundDown(coin, item.availableBalance);
        item.exchangeBalance = this.formatCurrencyRoundDown(coin, item.exchangeBalance);
        item.exchangeAvailabelBalance = this.formatCurrencyRoundDown(
          coin,
          item.exchangeAvailabelBalance
        );
        item.marginBalance = this.formatCurrencyRoundDown(coin, item.marginBalance);
        item.marginAvailabelBalance = this.formatCurrencyRoundDown(
          coin,
          item.marginAvailabelBalance
        );
        item.mamBalance = this.formatCurrencyRoundDown(coin, item.mamBalance);
        item.airdropBalance = this.formatCurrencyRoundDown(coin, item.airdropBalance);

        item.order = this.calculateInOrder(item);
        item.orderBtcValue = this.getOrderBtcBalance(item);

        item.btcValue = this.convertToBtcValue(coin, item.totalBalance);

        let usdValue = this.convertToUsdtValue(coin, item.totalBalance);
        if (!usdValue) {
          const pair = 'usd_btc';
          const price = this.prices[pair] ? this.prices[pair].price : '0';
          usdValue = new BigNumber(item.usdValue).mul(price).toString();
        }
        item.usdValue = usdValue;

        data.push(item);
      }

      this.updateTableBalanceAfterTransfer(data);
      this.balances = window._.sortBy(this.balances, 'name');
      const usdData = this.balances.find((item) => item.coin === 'idr');
      this.balances = this.balances.filter((item) => item.coin !== 'idr');
      this.balances.unshift(usdData);
    },
    updateTableBalanceAfterTransfer(data) {
      var _this = this;
      var newData = [];
      window._.each(data, (item, index) => {
        let coinRecord = window._.find(
          _this.balances,
          (itemBalance) => itemBalance.coin == item.coin
        );

        if (coinRecord) {
          let amount = new BigNumber(coinRecord.amount || '0');
          newData.push({
            ...coinRecord,
            balance: item.balance,
            availableBalance: item.availableBalance,
            exchangeBalance: item.exchangeBalance,
            exchangeAvailabelBalance: item.exchangeAvailabelBalance,
            marginBalance: item.marginBalance,
            marginAvailabelBalance: item.marginAvailabelBalance,
            mamBalance: item.mamBalance,
            airdropBalance: item.airdropBalance,
            airdropBalanceBonus: item.airdropBalanceBonus,
            availableAirdropBalanceBonus: item.availableAirdropBalanceBonus,
            totalBalance: item.totalBalance,
            amount: amount.toString(),
            isActiveRow: coinRecord.isActiveRow,
            order: item.order,
            orderBtcValue: item.orderBtcValue,
            btcValue: item.btcValue,
            usdValue: item.usdValue,
          });
        }
      });
      this.balances = newData;
    },
    getCoinName(coin) {
      let coinName = '';
      if (this.coinsMasterData && this.coinsMasterData[coin]) {
        coinName = this.coinsMasterData[coin].name;
      }
      return coinName;
    },
    getBalance(coin, currency) {
      if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

      if (coin === currency) {
        return `${this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance}`;
      }
      const pair = `${currency}_${coin}`;
      if (!this.prices[pair]) {
        return 0;
      }
      return new BigNumber(`${this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance}`)
        .mul(this.prices[pair].price)
        .toString();
    },
    formatCurrencyRoundDown(currency, value) {
      let numberOfDecimalDigits = 10;

      let newValue = new BigNumber(value);
      return newValue.round(numberOfDecimalDigits, BigNumber.ROUND_FLOOR).toString();
    },
    getBtcBalance(coin, currency) {
      if (!this.accountBalances[Const.TYPE_MAIN_BALANCE][coin]) return 0;

      let balance = this.accountBalances[Const.TYPE_MAIN_BALANCE][coin].balance;
      if (coin === currency) {
        return `${balance}`;
      }

      return this.convertToBtcValue(coin, balance);
    },
    convertToBtcValue(coin, value) {
      let balance = value;
      if (new BigNumber(balance).isZero()) {
        return 0;
      }
      if (coin === Const.BTC) {
        return new BigNumber(balance).toString();
      }

      let pair = `btc_${coin}`;
      let pairReverse = `${coin}_btc`;
      if (this.prices[pair]) {
        return new BigNumber(`${balance}`).mul(this.prices[pair].price).toString();
        // return 1
      } else if (this.prices[pairReverse]) {
        if (!this.prices[pairReverse].price) return '0';
        return new BigNumber(`${balance}`).div(this.prices[pairReverse].price).toString();
        // return 1
      }
    },
    convertToUsdtValue(coin, value) {
      let balance = value;
      if (new BigNumber(balance).isZero()) {
        return 0;
      }
      if (coin === Const.USDT) {
        return new BigNumber(balance).toString();
      }

      let pair = `usdt_${coin}`;
      let pairReverse = `${coin}_usdt`;
      if (this.prices[pair]) {
        return new BigNumber(`${balance}`).mul(this.prices[pair].price).toString();
        // return 1;
      } else if (this.prices[pairReverse]) {
        if (!this.prices[pairReverse].price) return '0';
        return new BigNumber(`${balance}`).div(this.prices[pairReverse].price).toString();
        // return 1;
      }
    },
    convertBtcToCoinValue(coin, value) {
      let balance = value;
      let pair = `btc_${coin}`;
      let pairReverse = `${coin}_btc`;
      if (!this.prices[pair] && !this.prices[pairReverse]) {
        return new BigNumber(`${balance}`).toString();
      }
      if (!this.prices[pair]) {
        return new BigNumber(`${balance}`).mul(this.prices[pairReverse].price).toString();
      } else if (!this.prices[pair].price) return '0';
      return new BigNumber(`${balance}`).div(this.prices[pair].price).toString();
    },
    convertCoins(fromCoin, toCoin, value) {
      // Example:
      // If do not have price of usd_$coin pair, transform to btc_$coin pair then transform to usd_btc pair.
      let btcValue = this.convertToBtcValue(fromCoin, value);
      let newValue = this.convertBtcToCoinValue(toCoin, btcValue);

      return new BigNumber(newValue).toString();
    },
    getOrderBtcBalance(item) {
      if (this.prices[`btc_${item.coin}`]) {
        return new BigNumber(item.order)
          .minus(item.availableBalance)
          .mul(this.prices[`btc_${item.coin}`].price)
          .toString();
      } else if (this.prices[`${item.coin}_btc`]) {
        if (!this.prices[`${item.coin}_btc`].price) return '0';
        return new BigNumber(item.order)
          .minus(item.availableBalance)
          .div(this.prices[`${item.coin}_btc`].price)
          .toString();
      }
      return '0';
    },
    calculateInOrder(item) {
      return new BigNumber(`${item.totalBalance}`).toString();
    },
    sumBy(attr) {
      let total = '0';
      for (let item of this.balances) {
        if (item[attr] == 'NaN' || new BigNumber(item[attr]).isZero()) {
          continue;
        }
        total = new BigNumber(total).plus(item[attr]).toString();
      }
      return new BigNumber(total).toNumber();
    },
    onBalanceUpdated(newAccountBalances) {
      this.accountBalances = window._.merge({}, this.accountBalances, newAccountBalances);
      this.refresh();
    },
    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      this.addPricesInBtc();

      // this.refreshNow();
      this.transferData();
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    onMainBalanceUpdated(mainUsdBalance) {
      if (
        mainUsdBalance &&
        mainUsdBalance.coin_used &&
        mainUsdBalance.coin_used == 'idr' &&
        this.accountBalances &&
        this.accountBalances.main &&
        this.accountBalances.main.usd
      ) {
        this.accountBalances.main.usd.balance = mainUsdBalance.total_balance;
        this.accountBalances.main.usd.available_balance = mainUsdBalance.available_balance;
        this.refresh();
      }
    },
    onAmlBalanceUpdated(mainAmalBalance) {
      this.accountBalances.main.amal.balance = mainAmalBalance.total_balance;
      this.accountBalances.main.amal.available_balance = mainAmalBalance.available_balance;
      this.refresh();
    },
    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
        BalanceUpdated: this.onBalanceUpdated,
        MainBalanceUpdated: this.onMainBalanceUpdated,
        AmlBalanceUpdated: this.onAmlBalanceUpdated,
      };
    },
    getTotalPendingWithdraw(currencies, callback) {
      if (window._.isEmpty(currencies)) {
        return;
      }

      const isContainUsdCurrency = window._.includes(currencies, 'idr');

      if (!isContainUsdCurrency || currencies.length > 1) {
        rf.getRequest('TransactionRequest')
          .getTotalPendingWithdraw()
          .then((res) => {
            window._.each(res.data, (item) => {
              this.updateCurrencyPendingWithdraw(item.currency, item.total);
            });
            if (callback) {
              callback();
            }
          });
      }
      // usd currency
      if (isContainUsdCurrency) {
        rf.getRequest('TransactionRequest')
          .getTotalUsdPendingWithdraw()
          .then((res) => {
            if (!res.data.total) {
              return;
            }
            this.updateCurrencyPendingWithdraw('idr', res.data.total);
            if (callback) {
              callback();
            }
          });
      }
    },
    updateCurrencyPendingWithdraw(currency, totalPendingWithdraw) {
      const item = window._.find(this.totalPendingWithdraws, (item) => item.currency === currency);
      if (item) {
        item.total = totalPendingWithdraw || '0';
      } else {
        this.totalPendingWithdraws.push(item);
      }
    },
    validate(item) {
      this.errors.remove('amount' + item.coin);
      this.errors.remove('server' + item.coin);

      let amount = new BigNumber(item.amount || 0);
      let isTransferFromMain = item.isTransferFromMain;

      if (amount.isZero()) {
        this.errors.add('amount' + item.coin, this.$i18n.t('funds.balances.amount_over_0'));
        return false;
      }

      if (amount.lt(MIN_AMOUNT_CRYPTO)) {
        this.errors.add('amount' + item.coin, this.$i18n.t('funds.balances.min_amount_is_invalid'));
        return false;
      }

      let availableBalance = new BigNumber(item.availableBalance);
      let exchangeBalance = new BigNumber(item.exchangeBalance);
      let exchangeAvailabelBalance = new BigNumber(item.exchangeAvailabelBalance);
      let marginBalance = new BigNumber(item.marginBalance);
      let marginAvailabelBalance = new BigNumber(item.marginAvailabelBalance);

      if (isTransferFromMain) {
        if (availableBalance.lt(amount)) {
          this.errors.add(
            'amount' + item.coin,
            this.$i18n.t(
              'funds.balances.amount_can_not_greater_than_exchange_available_balance_without_orderbook'
            )
          );
          return false;
        }
      } else {
        if (item.transferType == TRANSFER_TYPE_EXCHANGE_BALANCE && exchangeBalance.lt(amount)) {
          this.errors.add(
            'amount' + item.coin,
            this.$i18n.t('funds.balances.amount_can_not_greater_than_available_balance')
          );
          return false;
        }

        // With Spot Exchange has orders in orderbook (orders is not match),
        // balance in orders is locking
        // Need check amount with condition:
        // amount <= exchangeAvailabelBalance  --> ok
        // amount > exchangeAvailabelBalance   --> error
        if (
          item.transferType == TRANSFER_TYPE_EXCHANGE_BALANCE &&
          exchangeAvailabelBalance.lt(amount)
        ) {
          this.errors.add(
            'amount' + item.coin,
            this.$i18n.t(
              'funds.balances.amount_can_not_greater_than_exchange_available_balance_without_orderbook'
            )
          );
          return false;
        }

        if (item.transferType == TRANSFER_TYPE_MARGIN_BALANCE && marginBalance.lt(amount)) {
          this.errors.add(
            'amount' + item.coin,
            this.$i18n.t('funds.balances.amount_can_not_greater_than_margin_available_balance')
          );
          return false;
        }
        if (
          item.transferType == TRANSFER_TYPE_MARGIN_BALANCE &&
          marginAvailabelBalance.lt(amount)
        ) {
          this.errors.add(
            'amount' + item.coin,
            this.$i18n.t('funds.balances.amount_can_not_greater_than_margin_available_balance')
          );
          return false;
        }
      }

      return true;
    },
    handleChangeAmount(item) {
      item.amountPayload = item.amount;
    },
    onPercentClicked(item, percent) {
      let balance = 0;
      if (item.isTransferFromMain) {
        balance = item.availableBalance;
      } else {
        if (item.transferType === 'spot') {
          balance = item.exchangeAvailabelBalance;
        } else if (item.transferType === 'margin') {
          balance = item.marginAvailabelBalance;
        }
      }

      item.amount = new BigNumber(balance).mul(percent).div(100).toString();
      item.amountPayload = new BigNumber(balance).mul(percent).div(100).toString();
      this.percentActive === percent ? (this.percentActive = '') : (this.percentActive = percent);
    },
    transferBalance(e, item) {
      e.preventDefault();
      if (!this.validate(item)) {
        return false;
      }

      if (this.isSubmittingTransfer) {
        return false;
      }

      this.isSubmittingTransfer = true;
      let amount = this.formatCurrencyRoundDown(item.coin, item.amountPayload) || 0;
      let isTransferFromMain = item.isTransferFromMain;
      let coinSubmitting = item.coin;

      // Build data
      let self = this;
      let data = {
        coin_value: amount,
        coin_type: item.coin,
        from: isTransferFromMain ? TRANSFER_TYPE_MAIN_BALANCE : item.transferType,
        to: isTransferFromMain ? item.transferType : TRANSFER_TYPE_MAIN_BALANCE,
      };

      // this.isLoading = true;
      // this.$refs.datatable.rows = [];
      rf.getRequest('UserRequest')
        .transferBalance(data)
        .then((response) => {
          this.isSubmittingTransfer = false;
          if (!response.success || !response.data) {
            this.showErrorMessage(this.$i18n.t('funds.balances.transfer_balance_fails'));
            // this.isLoading = false;
          } else {
            this.showInfoMessage(this.$i18n.t('funds.balances.transfer_balance_success'));
            rf.getRequest('UserRequest')
              .getBalance(false)
              .then((res) => {
                this.onBalanceUpdated(res.data);
                // this.isLoading = false;
              });
          }
        })
        .catch((err) => {
          this.isSubmittingTransfer = false;
          if (err.response && err.response.data && err.response.data.message) {
            this.showErrorMessage(err.response.data.message);
            // this.isLoading = false;
          } else {
            this.showErrorMessage(this.$i18n.t('funds.balances.transfer_balance_fails'));
            // this.isLoading = false;
          }
        });
    },
    swapBalance(e, item) {
      e.preventDefault();
      item.isTransferFromMain = !item.isTransferFromMain;
      // this.validate(item);
    },
    showErrorMessage(message, callBack = () => {}) {
      // window.MessageWithIcon.error(message)
      Message.error(message, {}, { position: 'top_right' });
    },
    showInfoMessage(message, callBack = () => {}) {
      // window.MessageWithIcon.success(message)
      Message.success(message, {}, { position: 'top_right' });
    },
    getWithdrawalLimit() {
      rf.getRequest('UserRequest')
        .getWithdrawalLimit()
        .then((res) => {
          //this.withdrawalLimit = res.data;
          return res;
        });
    },
    getUserInformation() {
      rf.getRequest('UserRequest')
        .getCurrentUser()
        .then((res) => {
          let securityLevel = res.data.security_level;

          rf.getRequest('UserRequest')
            .getWithdrawalLimit()
            .then((resWithdrawalLimit) => {
              this.limit = resWithdrawalLimit.data;
            });
        });
    },
    handleMasterData() {
      this.currencyCoins = this.masterData.coin_settings;
      this.coinSetting = window._.keyBy(this.masterData.coins_confirmation, (item) => item.coin);
      this.coinsMasterData = window._.keyBy(this.masterData.coins, (item) => item.coin);
      this.coinsMasterData.usd = {
        name: this.$i18n.t(`currency.usd.fullname`),
      };
      this.coinsMasterData.idr = {
        name: 'Indonesian Rupiah',
      };

      _.forEach(this.masterData.coins, (item, key) => {
        this.coinNames[item.coin] = item.name;
        this.coinImages[item.coin] = item.icon_image;
      });

      this.coinNames['idr'] = this.$i18n.t(`currency.usd.fullname`);
      this.coinImages['idr'] = `/images/color_coins/usd.png`;
    },
  },
  created() {
    document.title = this.$t('menu.balances') + ` – ${this.tabTitle}`;
    if (!this.isAuthenticated) {
      return;
    }

    rf.getRequest('UserRequest')
      .getBalance(false)
      .then((res) => {
        this.onBalanceUpdated(res.data);
      });

    rf.getRequest('PriceRequest')
      .getPrices()
      .then((res) => {
        this.onPricesUpdated(res.data);
      });
  },
  mounted() {
    this.handleMasterData();
    this.getUserInformation();

    this.$on('UPDATED_LOCALE', () => {
      if (this.$refs.datatable) {
        const activeRows = this.$refs.datatable.rows.filter((row) => {
          return row.isActiveRow;
        });
        if (!activeRows) {
          return;
        }
        activeRows.forEach((row) => {
          this.validate(row);
        });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.cl-1,
.cl-2 {
  min-width: 110px;
}

.cl-6 {
  min-width: 135px;
}

.cl-7 {
  min-width: 166px;
}

// .cl-4,
// .cl-5,
// .cl-8 {
//   padding-left: 10px;
//   padding-right: 10px;
// }
.cl-9 {
  min-width: 203px;
}

.cl-10 {
  min-width: 185px;
}

.table-balance {
  background-color: white;
  padding-bottom: 8px;
  border-radius: 10px;
}

.full-page-background {
  width: 100%;
  display: inline-block;
  padding-top: 60px;
}

.number-entry-user {
  text-align: center;
  font-weight: normal;

  .title-entry {
    font-size: 30px;
    font-weight: bold;
  }
}

#balance {
  padding: 24px;
  margin: 0 auto;
  max-width: 1370px;

  .title-medium-small {
    color: $text-main;
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;

    @include mobile {
      font-size: 18px;
      line-height: 32px;
    }
  }

  .balance-page-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &.is_no_single_page {
    .balance-container {
      margin: 0;

      .table-container thead th {
        height: 22px;
        border-bottom: 1px solid $color-gray;
        font-size: $font-mini;
      }
    }
  }

  table {
    border-spacing: 10px;

    > * > * > * {
      padding-inline: 4px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.balance-container {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .balance__mobile-title {
    display: grid;
    border-radius: 10px 10px 0px 0px;
    background-color: $bg-main4;
    padding: 8px 16px;
    grid-template-columns: 80px 100px auto;

    .mobile-title__item {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      color: $text-secondary;

      &[data-sort-order='asc'],
      &[data-sort-order='desc'] {
        color: $color-blue;
      }
    }
  }

  .balance__mobile-item {
    background-color: $color-white;
    padding: 10px 16px;
    border-bottom: 1px solid $bg-main2;

    &:last-child {
      border-bottom: none;
    }

    .mobile-item__wrapper {
      display: grid;
      position: relative;
      grid-template-columns: 80px 100px auto;

      .item-wrapper__title {
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
        color: $text-main;

        &.total-balance {
          max-width: calc(100% - 30px);
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .item-wrapper__dropdown-icon {
        position: absolute;
        right: 0px;
      }
    }

    .mobile-item__expand-content {
      display: none;

      &.active {
        display: flex;
      }

      margin-top: 12px;
      background-color: $bg-main4;
      padding: 16px;
      flex-direction: column;
      border-radius: 8px;
      gap: 8px;

      .expand-content__wrapper {
        display: flex;
        justify-content: space-between;

        .expand-content__title {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          color: $text-main;
        }

        .expand-content__desc {
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          color: $text-main;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          word-wrap: break-word;
        }
      }

      hr {
        margin: 10px 0px;
        border-color: #ddd;
      }
    }
  }
}

.balance-info {
  line-height: 25px;
  width: 200px;
  margin-top: 8px;
  margin-left: -2px;
  font-weight: 100;
  font-size: $font-small;
  padding-right: 5px;
  padding-left: 5px;
  position: absolute;
  background: $background-default;
  border: $border_thin;
  box-shadow: 0 2px 10px 0 $border-color-grey;
  border-top: none;
  z-index: 10;
  padding: 5px 10px;
}

label,
input {
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: $text-main;
}

.sort_up {
  color: $color-corn-pale !important;
}

.sort_down {
  color: $color-corn-pale !important;
}

.es-toolbar {
  font-size: $font-root;
  // margin-bottom: 20px;
  // margin: 5px 0 17px 0;
  display: flex;
  justify-content: space-between;

  .es-tb-left,
  .es-tb-right {
    display: flex;
    align-items: center;
    gap: 24px;

    .es-tb-lhs {
      display: flex;
      gap: 20px;
    }
  }

  .icon {
    color: $color-grey-dusty;
  }

  .btn-small-icon {
    width: max-content;
    height: 25px;
    // border: 1px solid $color-gray;
    cursor: pointer;
    color: $color-grey-dark;
    border-radius: 3px;
    // padding: 5px 1px 4px 1px;
    display: flex;
    align-items: center;
    gap: 16px;

    .icon-help {
      font-size: $font-medium;
      margin-right: 5px;
      position: relative;
      top: 1px;
    }

    .checkbox-input {
      width: 24px;
      display: flex;
      position: relative;

      input {
        display: none;
      }

      label {
        cursor: pointer;
        width: 24px;
        height: 24px;
        border: 1px solid $dark-1;
        border-radius: 5px;

        &:after {
          opacity: 0;
          content: '';
          position: absolute;
          width: 16px;
          height: 8px;
          background: transparent;
          top: 6px;
          left: 5px;
          border: 2px solid $text-main1;
          border-top: none;
          border-right: none;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
        }
      }

      input[type='checkbox']:checked + label:after {
        opacity: 1;
      }

      input[type='checkbox']:checked + label {
        background-color: #010304;
      }
    }

    .checkbox-input-label {
      color: $text-main;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;

      @include mobile {
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
      }
    }
  }

  .btn-checkbox {
    width: 70px;
    height: 32px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $color-gray;
    cursor: pointer;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 3px;

    // padding: 5px 1px 4px 1px;
    .checkbox-input {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      position: relative;
      top: -1px;

      input {
        display: none;
      }

      label {
        cursor: pointer;
        position: absolute;
        width: 12px;
        height: 12px;
        top: 0;
        left: 0;
        border: 1px solid $color-grey-dusty;
        border-radius: 1px;

        &:after {
          opacity: 0;
          content: '';
          position: absolute;
          width: 12px;
          height: 5px;
          background: transparent;
          top: 0px;
          left: 0px;
          border: 3px solid $color-grey-dusty;
          border-top: none;
          border-right: none;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }

      input[type='checkbox']:checked + label:after {
        opacity: 1;
      }
    }
  }

  .btn-convert-icon {
    cursor: pointer;
    border-radius: 3px;
    min-width: 142px;
    height: 25px;
    // line-height: 20px;
    // border: 1px solid $color-alto;
    color: $dark-4;
    border-radius: 3px;
    // padding: 5px 1px 4px 1px;
    display: flex;
    align-items: center;
    gap: 10px;

    span {
      vertical-align: middle;
    }

    .icon-convert {
      width: 20px;
      height: 20px;
    }

    &:hover {
      color: $color-white;
      background-color: $color-blue-custom;
      border-color: $color-blue-custom;
    }

    pointer-events: none;
    opacity: 0.5;
  }

  .input-search {
    width: 258px;
    height: 42px;
    background-color: $color-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 16px;
    gap: 10px;
    border-radius: 8px;

    // &:focus-within {
    //   border-radius: 3px;
    //   border: solid 1px $color-jungle-green;
    // }
    .group-btn-input-search {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 10px;
      width: 100%;
      height: 18px;

      input {
        background: $color-white;
        color: $text-main;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        border: none;
        width: calc(100% - 30px);
        padding-left: 0px;

        &:focus {
          outline: none;
        }
      }

      .btn-search {
        cursor: pointer;
        width: 24px;
        height: 24px;
        background-image: url('@/assets/images/wallet/search_icon.png');
        background-repeat: no-repeat;
        background-size: 24px 24px;
      }
    }
  }

  .estimate-value {
    color: $text-secondary;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: right;

    // margin-top: -9px;
    .total {
      margin-bottom: 5px;
      // line-height: 16px;

      .number-value {
        color: $text-main;
      }
    }

    .limit {
      display: flex;
      gap: 10px;

      span {
        &:first-child {
          margin-right: 3px;
        }
      }
    }
  }
}

.table-container {
  background: $color-white;
  border-radius: 10px;
}

.icon-coin {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  vertical-align: middle;
}

.group_button_tooltip {
  position: relative;

  &:hover {
    .tooltip_hover {
      display: block;
    }
  }

  .btn-deposit-tooltip {
    font-size: 20px;
    font-weight: 600;
  }

  .tooltip_hover {
    position: absolute;
    top: 50%;
    right: 100%;
    translate: 0 -50%;
    z-index: 5;
    display: none;
    margin-right: 8px;
    padding: 16px 14px 14px 19px;
    min-width: 165px;
    background-color: $color-white;
    border-radius: 3px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.22);
    @include text-font($helvetica, $color-grey-dark, $font-root, 500, 20px);
    text-align: left;
    white-space: normal;
  }
}

#balance {
  &.page_container {
    min-height: 500px;
    .balance-container {
      .table-container {
        table thead {
          border-radius: 20px 10px 0px 0px;
        }

        th {
          color: $text-secondary;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          padding: 8px;
          height: 66px;
          border-bottom: none;
          background: #f8f8f8;

          &:first-child {
            padding-left: 22px;
          }

          &:last-child {
            padding-right: 22px;
            text-align: left;
          }

          &.after_none:after {
            display: none;
          }

          &[data-sort-order='asc'],
          &[data-sort-order='desc'] {
            color: $color-blue;

            &:after {
              color: $color-blue;
            }
          }
        }

        tr {
          border-bottom: 1px solid #dddddd;

          td {
            color: $text-main;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            overflow: visible;
            vertical-align: middle;
            padding: 8px;

            &:first-child {
              padding-left: 22px;
            }

            &:last-child {
              padding-right: 22px;
              text-align: left;
            }

            .icon_balances {
              font-size: 20px;
              color: $color-jungle-green;
              cursor: pointer;
            }

            .icon_balances.active-icon {
              color: $color-eden;
            }

            .btn-lose-color {
              .icon_balances {
                color: $color-grey;
                cursor: not-allowed;
              }
            }

            .btn-transfer {
              cursor: pointer;
            }

            &.acvite_transfer {
              .btn-transfer {
                background-color: $color-bright-gray;
              }
            }

            .btn-mam {
              display: inline-block;
              width: 38px;
              height: 38px;
              line-height: 42px;
              position: relative;
              margin-top: -18px;
              font-size: 11px;
              top: 12px;
              cursor: pointer;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
              text-align: center;
              overflow: hidden;
              background-color: transparent;
              margin-left: 3px;
            }

            &.acvite_mam {
              .btn-mam {
                background-color: $color-bright-gray;
              }
            }
          }

          &:hover {
            background-color: $color-bright-gray;

            td {
              .icon_balances {
                color: $color-eden;
              }

              .btn-lose-color {
                .icon_balances {
                  color: $color-grey;
                  cursor: not-allowed;
                }
              }
            }
          }
        }

        .table_drop_balances {
          position: relative;
          padding: 24px 32px;
          background-color: $bg-main4;
          overflow: visible;

          .close_tbale {
            position: absolute;
            top: 20px;
            right: 20px;
            display: block;
            width: 20px;
            height: 20px;
            font-size: $font-smaller;
            line-height: 20px;
            text-align: center;
            color: $color-grey-dark;
            cursor: pointer;
          }
        }
      }
    }
  }

  .transfer_form {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    @include mobile {
      flex-direction: column;
      gap: 0;
    }
  }

  .group_transfer_form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    gap: 8px;
    width: fit-content;
    height: 106px;

    @include mobile {
      width: 100%;
      height: auto;

      &.swap-btn {
        height: 20px;
      }
    }

    &.action-mobile {
      margin-top: 20px;
      display: grid;
      grid-template-columns: 1fr 36px;
      gap: 16px;

      .btn-reload {
        border-radius: 8px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 38px;
        width: 38px;

        img {
          margin: 0;
        }
      }
    }

    .invalid-feedback {
      margin-top: 0px;
    }

    .main_account_input {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 7px 14px;
      gap: 10px;
      border: 1px solid $grey-1;
      border-radius: 10px;
    }

    .btn-transfer-form {
      background-color: transparent;

      &.mobile {
        transform: rotate(90deg);
        align-self: center;
      }

      .transfer-icon {
        margin-top: 8px;
      }

      &.btn-reload {
        border-radius: 8px;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        width: 36px;

        img {
          margin: 0;
          height: 24px;
          width: 24px;
        }
      }
    }

    .btn-send-transfer-form {
      display: inline-flex;
      padding: 7px 24px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 60px;
      background: $bg-main1;
      color: $color-white;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;

      &:hover {
        opacity: 0.9;
      }
    }

    label.txt {
      color: var(--text-secondary, rgba(2, 0, 13, 0.6));
      font-size: 14px;
      height: 18px;

      @include mobile {
        font-size: 12px;
        font-weight: 300;
        line-height: 22px;
      }
    }

    .wallet-type {
      min-width: 232px;
      min-height: 36px;
      background: #fff;
      border-radius: 5px;
      padding: 7px 16px;
      color: $text-main;
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;

      @include mobile {
        width: 100%;
        margin-bottom: 12px;
      }
    }

    input.input-form {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 7px 16px;
      gap: 10px;
      border: none;
      border-radius: 5px;
      width: 232px;
      height: 36px;
      color: $text-main !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      background: #ffffff;

      @include mobile {
        width: 100%;
        margin-bottom: 12px;
      }

      &.input-form_bg:disabled {
        background: #ffffff !important;
      }

      &::placeholder {
        color: $text-main !important;
      }

      &:disabled {
        background-color: $color-white !important;
        opacity: 1;
        color: $text-main;
      }
    }

    select.input-form {
      border-radius: 5px;
      border: none;
      background-color: white;
      height: 38px;
      padding: 7px 16px;
      width: 232px;
      color: $text-main;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;

      @include mobile {
        width: 100%;
      }
    }

    .w_155 {
      width: 155px;
    }

    .w_184 {
      width: 184px;
    }

    .w_220 {
      width: 220px;

      @include mobile {
        width: 100%;
      }
    }

    .input-amount {
      position: relative;

      @include mobile {
        width: 100%;
        height: 38px;
      }

      .name_amount {
        position: absolute;
        top: 20%;
        right: 5%;
        color: $text-main;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        text-transform: uppercase;
      }

      input.input-form {
        padding-right: 40px;
        @include text-font($helvetica, $dark-1, $font-root, 500, 18px);
      }

      & > input:focus {
        border: 1px solid $bg-main1;
      }

      .error {
        border: 1px solid #ff5b5b;
        background: rgba(255, 91, 91, 0.1);
      }
    }
  }

  .select-control {
    border-radius: 5px;
    border: none;
    background-color: $color-white;
    height: 38px;
    padding: 7px 16px;
    width: 232px;
    color: $text-main;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      width: 100%;
      margin-bottom: 12px;
    }

    .select-icon:before {
      color: $color-black;
      font-size: 14px;
      line-height: 22px;
      display: inline-block;
      // position: absolute;
      // top: 15%;
      // right: 5%
    }

    &:hover {
      border-color: $color-orange !important;
    }
  }

  .dropdown {
    width: 100%;

    span.select-icon {
      width: 24px;

      &::before {
        line-height: 24px;
      }
    }
  }

  .dropdown-menu {
    width: 100%;
    border-radius: 3px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: $color-white;
    padding: 10px 0px;

    .tab-element {
      cursor: pointer;
      display: block;
      width: 100%;
      line-height: 20px;
      padding: 5px 20px;
      font-size: $font-root;
      color: $color-grey-dark;

      &:hover {
        background-color: #f2f3f5;
      }
    }

    .disabled-item {
      display: none;
      background-color: #bbb;
      cursor: not-allowed;
      color: white;

      &:hover {
        color: white;
      }
    }
  }

  .group_input_form {
    width: 100%;
  }

  .input_right_group {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 8px;

    &.percent {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 8px;
    }

    .check_percent {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 8px;
      width: 52px;
      height: 30px;
      border-radius: 60px;
      background: $color-light-grey;
      color: $text-secondary;
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;

      @include mobile {
        width: 100%;
      }

      &.active {
        color: $color-white;
        background: $bg-main1;
      }
    }
  }
}

#balance {
  @include mobile() {
    padding: 24px 16px;

    .es-toolbar {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;

      .estimate-value {
        @include text-font($helvetica, $dark-3, $font-root, 500, 25px);
        text-align: right;

        // margin-top: -9px;
        .total {
          font-size: 12px;

          .number-value {
            font-size: 12px;
            color: $dark-1;
            margin: 0;
            display: block;
          }
        }

        .limit {
          display: flex;
          flex-direction: column;
          gap: 5px;
          font-size: 12px;

          span {
            font-size: 12px;

            &:first-child {
              margin-right: 3px;
            }
          }
        }
      }
    }

    .es-tb-left {
      align-items: center;
      gap: 15px;
      width: 100%;

      .es-tb-lhs {
        // justify-content: space-between;
        gap: 18px;
        width: 100%;
      }
    }

    .total,
    .limit {
      text-align: start;
    }

    .input-search {
      width: 100%;
    }
  }

  @include tablet() {
    .es-toolbar {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }

    .total,
    .limit {
      text-align: start;
    }
  }
}
</style>

<style lang="scss">
#balance {
  .tableContainer {
    overflow-y: auto;
    border-radius: 10px;

    table {
      overflow-y: inherit;
    }
  }
}
</style>
