<template>
  <!-- 邀请奖励 -->
  <div class="lading-page"></div>
</template>
<script>
export default {
  data() {
    return {
      redirectLink: this.$route,
    };
  },
  computed: {},
  methods: {},
  mounted() {
    window.location.href = `gscexapp://${Object.keys(this.redirectLink.query)}`;
  },
};
</script>
<style lang="scss" scoped></style>
