<template slot="body">
  <div class="container">
    <div id="account-authen2">
      <!-- <span class="icon-close" @click="closeModal"></span> -->
      <div class="box-title title-medium-small">
        <img src="/images/google-authen-blue.svg" alt="google-authen" />
        <h3>{{ $t('account.otp.enable_des') }}</h3>
      </div>
      <!-- End title -->
      <div class="authen-container">
        <!-- Begin Steps -->
        <div class="steps">
          <div class="list_authen">
            <div
              class="wrap-step"
              :class="{ active: step.current === 0 || step.current >= 1 }"
            >
              <div @click="jumpStep(0)" class="step">1</div>
            </div>
            <div
              class="wrap-step"
              :class="{ active: step.current === 1 || step.current >= 2 }"
            >
              <div @click="jumpStep(1)" class="step">2</div>
              <div class="line"></div>
            </div>
            <div
              class="wrap-step"
              :class="{ active: step.current === 2 || step.current >= 3 }"
            >
              <div @click="jumpStep(2)" class="step">3</div>
              <div class="line"></div>
            </div>
            <div class="wrap-step" :class="{ active: step.current === 3 }">
              <div @click="jumpStep(3)" class="step">4</div>
              <div class="line"></div>
            </div>
          </div>
        </div>
        <!-- End steps -->

        <!-- Begin StepContent -->
        <div class="step-content">
          <div v-show="step.current == 0">
            <install></install>
          </div>
          <!-- end-step-0 -->
          <div v-show="step.current == 1">
            <QR @keyUpdated="updateKey2fa"></QR>
          </div>
          <!-- end-step-1 -->
          <div v-show="step.current == 2">
            <BackupKey :key2fa="key2fa"></BackupKey>
          </div>
          <div v-show="step.current == 3">
            <div class="content-box fourth">
              <div class="title-step">
                {{ $t('account.otp.enable_title') }}
              </div>
              <div class="verify-input">
                <div class="login">
                  <div class="input-group">
                    <!-- <div class="name_input">
                        {{ $t('account.otp.txt_pass') }}
                      </div> -->
                    <div class="form-input">
                      <input
                        v-model="password"
                        @keyup.enter="verifyCode"
                        @focus="resetError"
                        name="password"
                        type="password"
                        :class="{ error: errors.has('password') }"
                        :placeholder="$t('common.placeholders.login_password')"
                      />
                    </div>
                    <span
                      class="invalid-feedback"
                      v-if="errors.has('password')"
                    >
                      {{ $t(errors.first('password')) }}
                    </span>
                  </div>
                </div>
                <div class="authencode">
                  <div class="input-group">
                    <!-- <div class="name_input">
                        {{ $t('common.placeholders.otp') }}
                      </div> -->
                    <div class="form-input">
                      <input-only-number
                        type="text"
                        autocomplete="off"
                        v-model="authenticationCode"
                        @keyup.enter="verifyCode"
                        @focus="resetError"
                        @keypress="isNumber"
                        maxlength="6"
                        name="code"
                        :data-vv-as="
                          $t('validation.attributes.otp_recovery_code')
                        "
                        :class="{ error: errors.has('code') }"
                        :placeholder="$t('common.placeholders.otp')"
                      />
                    </div>
                    <span class="invalid-feedback" v-if="errors.has('code')">
                      {{ $t(errors.first('code')) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end-step-2 -->
        </div>
        <!-- End StepContent -->

        <!-- begin step-button -->
        <div class="step-button">
          <div class="fl-right">
            <button
              id="btnNext"
              class="btn-next fl-right"
              v-show="step.current <= 2"
              @click="nextStep"
            >
              {{ $t('account.otp.next') }}
            </button>
            <span class="para" v-show="step.current == 0">{{
              $t('account.otp.enable_text1')
            }}</span>
            <span class="para" v-show="step.current == 1">{{
              $t('account.otp.enable_text2')
            }}</span>
            <span class="para" v-show="step.current == 2">{{
              $t('account.otp.enable_text3')
            }}</span>
            <button
              :disabled="isSubmitting"
              class="btn-enable btn-next fl-right"
              v-show="step.current == 3"
              @click="verifyCode"
            >
              {{ getSubmitName($t('account.otp.enable_des')) }}
            </button>
          </div>
          <button class="btn-prev" v-show="step.current >= 1" @click="backStep">
            <img
              class="arrow-left"
              src="/images/arrow-left.svg"
              alt="arrow-left"
            />{{ $t('account.otp.back') }}
          </button>
        </div>
        <!-- end step-button -->
      </div>
    </div>

    <!-- End authen-container -->
  </div>
  <!-- End account-authen -->
</template>

<script>
import Modal from '@/components/shared_components/common/Modal'
import Install from '../account/otp/Install.vue'
import QR from '../account/otp/QR.vue'
import BackupKey from '../account/otp/BackupKey.vue'
import rf from '@/request/RequestFactory'

export default {
  data() {
    return {
      isShowing: false,
      step: {
        current: 0,
        max: 3,
      },
      isVerifyOTP: false,
      authenticationCode: '',
      password: '',
      key2fa: '',
      modalName: 'EnableGoogleAuthentication',
    }
  },
  components: {
    Modal,
    Install,
    QR,
    BackupKey,
  },
  methods: {
    navigateToLandingPage() {
      window.location.href = this.$router.resolve({
        name: 'Landing Page',
      }).href
    },
    nextStep() {
      this.jumpStep(this.step.current + 1)
    },
    backStep() {
      this.jumpStep(this.step.current - 1)
    },
    jumpStep(target) {
      target = Math.max(0, target)
      target = Math.min(this.step.max, target)

      if (target < 1 || this.key2fa) {
        this.step.current = target
      }
      $('#btnNext').blur()
    },
    resetError() {
      this.errors.clear()
    },
    updateKey2fa(key) {
      this.key2fa = key
    },
    isNumber($event) {
      this.resetError()
      const keyCode = $event.which ? $event.which : $event.keyCode
      if (keyCode > 31 && (keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault()
      }
    },
    closeModal() {
      this.isShowing = false
      this.$emit(`${this.modalName}:CLOSE`)
    },
    async verifyCode() {
      if (this.isSubmitting) {
        return
      }
      if (_.isEmpty(this.password)) {
        this.errors.add({
          field: 'password',
          msg: this.$t('validation.auth.password_required'),
        })
      }
      if (_.isEmpty(this.authenticationCode)) {
        this.errors.add({
          field: 'code',
          msg: this.$t('validation.auth.ggauth_required'),
        })
      }
      if (this.authenticationCode.length < 6) {
        this.errors.add({
          field: 'code',
          msg: this.$t('validation.auth.ggauth_length'),
        })
      }
      if (this.errors.any()) {
        return
      }
      try {
        this.startSubmit()
        await rf
          .getRequest('UserRequest')
          .addSecuritySettingOtp({
            code: this.authenticationCode,
            password: this.password,
          })
          .then(async (response) => {
            if (response.success) {
              this.navigateToLandingPage()
            }
          })
      } catch (error) {
        this.endSubmit()
        this.errors.clear()
        const errors = error.response.data.errors

        Object.keys(errors).forEach((key) => {
          this.errors.add({
            field: key,
            msg: errors[key][0],
          })
        })
      }
    },
    getEventHandlers() {
      return {
        showCommonModal: this.onModalShowing,
      }
    },
    onModalShowing(modalName) {
      if (modalName == 'EnableGoogleAuthentication') {
        this.isShowing = true
      }
    },
  },
  created() {
    this.$on('showCommonModal', (modalName, userConfigs) => {
      if (modalName === this.modalName) {
        this.step.current = 0
        this.password = ''
        this.authenticationCode = ''
      }
    })
  },
  mounted() {
    window.addEventListener('keyup', (event) => {
      if (
        event.keyCode === 13 &&
        this.modalName == 'EnableGoogleAuthentication' &&
        this.isShowing &&
        this.step.current == 3
      ) {
        this.verifyCode()
      }
    })
  },
}
</script>
<style lang="scss">
#EnableGoogleAuthentication {
  .modal-body {
    padding: 30px 30px 20px 30px;

    @include mobile {
      padding: 30px 16px;
    }
  }

  .modal-dialog {
    max-width: 100%;
  }
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modal-footer {
  display: none;
}

.arrow-left {
  margin-right: 9px;
}

.header {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}

.fl-right {
  width: 100%;
}

#account-authen2 {
  width: 414px;

  .text-bold,
  .text-grey {
    font-size: 12px;
    line-height: 20px;
  }

  .text-bold {
    color: rgba($text-main2, 0.87);
    margin-bottom: 5px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .text-grey {
    color: rgba($text-main2, 0.38);
    margin-bottom: 15px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  .box-title {
    text-align: center;

    h3 {
      color: rgba($text-main2, 0.87);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-top: 14px;
      margin-bottom: 24px;

      @include mobile {
      }
    }

    .title-l {
      margin: 10px 0px 10px 0px;
      height: 1px;
      width: 28%;
      border-radius: 5px;
      background-color: $color-light-grey;
    }
  }

  .wrapper-authen-container {
    // width: 414px;
    // background-color: red;
  }

  .authen-container {
    width: '100%';
    margin: 0 auto;
    background: $background-default;
    padding: 20px;
    border-radius: 20px;

    .steps {
      width: 100%;
      height: auto;

      .list_authen {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-top: 24px;

        .wrap-step {
          position: relative;

          .step {
            width: 44px;
            height: 44px;
            border-radius: 20px;
            border: 1px solid $bg-main2;
            background: $bg-main4;

            color: rgba($color: $text-main2, $alpha: 0.38);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 44px;
            text-align: center;
            cursor: pointer;
            z-index: 1;
            position: relative;
          }

          .line {
            position: absolute;
            content: '';
            width: 76px;
            height: 4px;
            background: $bg-main3;
            top: 50%;
            left: -76px;
            transform: translateY(-50%);

            @include mobile {
              width: 60px;
              left: -60px;
            }
          }

          &.active {
            .step,
            .line {
              color: $text-main1;
              background: $bg-main1;
              border-color: $bg-main1;
            }
          }
        }
      }
    }

    .step-content {
      .content-box {
        width: 100%;
        margin-top: 16px;

        .title-step {
          color: rgba($color: $text-main2, $alpha: 0.87);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;

          @include mobile {
          }
        }

        .sub-title-step {
          color: rgba($color: $text-main2, $alpha: 0.38);
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 16px;
        }

        .sub-title-step2 {
          color: $text-main2;
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 16px;
        }

        .applink {
          display: flex;
          gap: 12px;

          a {
            display: inline-block;
            flex: 1;

            img {
              width: 100%;
            }
          }
        }
      }

      .qr-code {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 141px;
          height: 141px;
        }

        .text-grey {
          margin-bottom: 15px;
          vertical-align: top;
          display: inline-block;
        }
      }

      .show-key {
        p {
          margin: 0;
          color: rgba($color: $text-main2, $alpha: 0.87);
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px;
          border-radius: 10px;
          border: 1px solid $bg-main2;
          padding: 10px 16px;
        }
      }

      .backup-key {
        display: block;
      }

      .verify-input {
        margin-top: 20px;
        width: 100%;
        display: flex;
        flex-direction: column;

        .d-i-block {
          position: relative;
        }

        label {
          display: block;
          color: $color-grey-dusty;
          font-weight: normal;
          margin-bottom: 10px;
        }

        input {
          &::placeholder {
            color: $text-main-blur;
            font-size: 18px;
            font-weight: 300;
            line-height: 32px;
          }
        }
      }
    }

    .step-button {
      width: 100%;
      margin-top: 25px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        &.btn-next {
          @include btn-common(contained, lg);

          @include desktop {
            &:not(:focus):hover {
              background: $bg-main1;
              color: $text-white;
            }
          }
        }

        span {
          &.icon-arrow_2_3 {
            margin-right: 5px;
            float: left;
            line-height: 20px;
          }

          &.icon-arrow_2 {
            float: right;
            line-height: 20px;
            margin-left: 5px;
          }
        }
      }

      .btn-prev {
        @include btn-common(no, lg, false);
        width: auto;
        margin-top: 10px;
        padding: 0;
      }

      .para {
        color: $text-secondary;
        display: inline-block;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        margin-top: 16px;
      }
    }
  }
}

.name_input {
  font-family: $helvetica;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 23px;
}
</style>
