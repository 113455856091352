<template>
  <div id="referral">
    <div class="content_referral">
      <div class="conainer_referral">
        <div class="tit_page_referral">{{ $t("refferal.title") }}</div>
        <div class="body_page_referral">
          <div class="step_account">
            <div class="block_circle">
              <div class="block_content">
                <div class="circle step_link">
                  <img
                    src="/images/icon/icon-get-link-new.svg"
                    alt="icon"
                    class="icon"
                  />
                </div>
                <div class="block-content__wrapper">
                  <div class="title_step">1. {{ $t("refferal.title1") }}</div>
                  <div class="content_step">{{ $t("refferal.text1") }}</div>
                </div>
              </div>
            </div>
            <div class="block_circle">
              <div class="block_content">
                <div class="circle step_link">
                  <img
                    src="/images/icon/icon-invite-friend-new.svg"
                    alt="icon"
                    class="icon"
                  />
                </div>
                <div class="block-content__wrapper">
                  <div class="title_step">2. {{ $t("refferal.text2") }}</div>
                  <div class="content_step">{{ $t("refferal.text3") }}</div>
                </div>
              </div>
            </div>
            <div class="block_circle">
              <div class="block_content">
                <div class="circle step_link">
                  <img
                    src="/images/icon/icon-get-reward-new.svg"
                    alt="icon"
                    class="icon"
                  />
                </div>
                <div class="block-content__wrapper">
                  <div class="title_step">
                    3. {{ $t("refferal.get_rewards") }}
                  </div>
                  <div class="content_step">
                    {{
                      $t("refferal.text5", {
                        percent: settingReferral.refund_percent,
                      })
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box_qr_referral">
            <div class="box_qr_referral_left">
              <div class="crop_qr_referral">
                <img
                  :src="QR_CODE_URL || '/images/admin/loading.gif'"
                  alt="qr_code"
                />
                <div class="qr__content-mobile-wrapper">
                  <div class="id_referral">
                    <span class="tit_id_referral"
                      >{{ $t("refferal.text6") }}&nbsp;</span
                    >
                    <span class="code_id_referral">{{
                      refferalCode.referrer_code
                    }}</span>
                  </div>
                  <div class="society_link">
                    <div class="tit_society_link">
                      {{ $t("refferal.text9") }}
                    </div>
                    <div class="list_society_link">
                      <a
                        @click="fbShareAction()"
                        href="javascript:void(0)"
                        class="icon"
                      >
                        <img
                          src="/images/icon/icon-fb-w.svg"
                          alt="icon-facebook"
                        />
                      </a>
                      <a
                        @click="twShareAction()"
                        href="javascript:void(0)"
                        class="icon"
                      >
                        <img
                          src="/images/icon/icon-twitter-w.svg"
                          alt="icon-twitter"
                        />
                      </a>
                      <a
                        @click="linShareAction()"
                        href="javascript:void(0)"
                        class="icon"
                      >
                        <img
                          src="/images/icon/icon-linkedin-w.svg"
                          alt="icon-linkedin"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="qr_content_referral">
                <div class="id_referral">
                  <span class="tit_id_referral">{{
                    $t("refferal.text6")
                  }}</span>
                  <span class="code_id_referral">{{
                    refferalCode.referrer_code
                  }}</span>
                </div>

                <div class="society_link">
                  <div class="tit_society_link">{{ $t("refferal.text9") }}</div>
                  <div class="list_society_link">
                    <a
                      @click="fbShareAction()"
                      href="javascript:void(0)"
                      class="icon"
                    >
                      <img
                        src="/images/icon/icon-fb-w.svg"
                        alt="icon-facebook"
                      />
                    </a>
                    <a
                      @click="twShareAction()"
                      href="javascript:void(0)"
                      class="icon"
                    >
                      <img
                        src="/images/icon/icon-twitter-w.svg"
                        alt="icon-twitter"
                      />
                    </a>
                    <a
                      @click="linShareAction()"
                      href="javascript:void(0)"
                      class="icon"
                    >
                      <img
                        src="/images/icon/icon-linkedin-w.svg"
                        alt="icon-linkedin"
                      />
                    </a>
                  </div>
                </div>

                <div class="group_input_link_referran">
                  <div class="tit_input_link_referran">
                    {{ $t("refferal.text8") }}
                  </div>
                  <div class="link_referran_value">
                    <div class="link_referral">
                      {{ this.appUrl }}/?ref={{ refferalCode.referrer_code }}
                    </div>
                    <button
                      v-clipboard:copy="
                        `${this.appUrl}/?ref=${refferalCode.referrer_code}`
                      "
                      @click="showSuccess($t('refferal.text25'))"
                      class="btn-copy"
                    >
                      <img src="/images/icon/copy.svg" alt="icon copy" />
                      {{ $t("refferal.copy_link") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="box_qr_referral_right">
              <div class="referel_block">
                <div class="referal_element">
                  <p class="content">{{ numberFriend }}</p>
                  <p class="tit">{{ $t("refferal.text10") }}</p>
                </div>
                <div class="referal_element">
                  <p class="content">
                    {{ balanceCommissions | formatCurrencyAmount("btc", "0") }}
                    BTC
                  </p>
                  <p class="tit" v-html="$t('refferal.text23')"></p>
                </div>
              </div>
            </div>
          </div>

          <div class="export_refarral">
            <div class="table_left_refarral">
              <div class="table_header">
                <p class="table_title">{{ $t("refferal.text10") }}</p>
                <a class="excel_export" @click="downloadReportFriend()">
                  <img src="/images/icon/export.svg" alt="icon-export" />
                  <span class="text_link">{{ $t("refferal.text26") }}</span>
                </a>
              </div>
              <div class="datatable">
                <data-table2
                  :getData="getAllReferrer"
                  :msgEmptyData="$t('account.no_referral_history')"
                  @DataTable:finish="onLoadHight"
                  :isTableFriend="true"
                  :isMargin="true"
                >
                  <template slot-scope="props">
                    <th width="40%" class="t-left text">
                      {{ $t("common.placeholders.email") }}
                    </th>
                    <th width="60%" class="text t-right">
                      {{ $t("refferal.text11") }}
                    </th>
                  </template>
                  <template slot="body" slot-scope="props">
                    <tr>
                      <td width="40%" class="t-left text-table">
                        {{ props.item.email }}
                      </td>
                      <td width="60%" class="t-right text-table">
                        {{ convertTime(props.item.created_at) }}
                      </td>
                    </tr>
                  </template>
                </data-table2>
              </div>
            </div>

            <div class="table_right_refarral">
              <div class="table_header">
                <p class="table_title">{{ $t("refferal.text24") }}</p>
                <a class="excel_export" @click="downloadReportCommission()">
                  <img
                    class="icon-export"
                    alt=""
                    src="/images/icon/export.svg"
                  />
                  <!-- <img src="/images/icon/icon-export.svg" alt="icon-export" /> -->
                  <span class="text_link">{{ $t("refferal.text26") }}</span>
                </a>
              </div>
              <div class="datatable">
                <div class="head_group_positions">
                  <div
                    class="item"
                    @click="showContentTypes = 'spot'"
                    v-bind:class="{ active: showContentTypes === 'spot' }"
                  >
                    {{ $t("common.spot_exchange") }}
                  </div>
                </div>
                <template v-if="isMobile">
                  <div class="commission-history__mobile-wrapper">
                    <data-list-mobile
                      :get-data="getUserReferralCommission"
                      :isExpand="true"
                      :msgEmptyData="$t('account.no_commission_history')"
                      ref="datatable_commission"
                      @DataTable:finish="onLoadHight"
                      :isTableHistory="true"
                    >
                      <template slot="table-empty-header">
                        <div class="commission-history__mobile-title">
                          <div class="mobile-title__item">
                            {{ $t("refferal.text22") }}
                          </div>
                          <div class="mobile-title__item">
                            {{ $t("refferal.text27") }}
                          </div>
                        </div>
                      </template>
                      <template slot="header">
                        <div class="commission-history__mobile-title">
                          <div class="mobile-title__item">
                            {{ $t("refferal.text22") }}
                          </div>
                          <div class="mobile-title__item">
                            {{ $t("refferal.text27") }}
                          </div>
                        </div>
                      </template>
                      <template slot="body" slot-scope="props">
                        <div
                          class="commission-history__mobile-item"
                          :class="{ isHidePaginate: props?.isHidePaginate }"
                        >
                          <div class="mobile-item__wrapper">
                            <div class="item-wrapper__title">
                              {{
                                trimNumber(props.item.amount)
                                  | formatCurrencyAmount(props.item.coin, "0")
                              }}
                              {{ props.item.coin.toUpperCase() }}
                            </div>
                            <div class="item-wrapper__title">
                              {{ props.item.commission_rate }}%
                            </div>
                            <div
                              class="item-wrapper__dropdown-icon"
                              @click="props.onClickExpandItem(props)"
                            >
                              <img
                                src="/images/instant-convert/icon-dropdown.svg"
                                alt=""
                                v-if="!props.isActive"
                              />
                              <img
                                src="/images/instant-convert/icon-dropdown2.svg"
                                alt=""
                                v-else
                              />
                            </div>
                          </div>
                          <div
                            class="mobile-item__expand-content"
                            :class="{ active: props.isActive }"
                          >
                            <div class="expand-content__wrapper">
                              <div class="expand-content__title">
                                {{ $t("common.placeholders.email") }}
                              </div>
                              <div class="expand-content__desc">
                                {{ props.item.email }}
                              </div>
                            </div>
                            <div class="expand-content__wrapper">
                              <div class="expand-content__title">
                                {{ $t("refferal.text11") }}
                              </div>
                              <div class="expand-content__desc">
                                {{ convertTime(props.item.created_at) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </data-list-mobile>
                  </div>
                </template>
                <template v-else>
                  <data-table2
                    :getData="getUserReferralCommission"
                    :msgEmptyData="$t('account.no_commission_history')"
                    ref="datatable_commission"
                    @DataTable:finish="onLoadHight"
                    :isTableHistory="true"
                  >
                    <template slot-scope="props">
                      <th width="25%" class="t-left text">
                        {{ $t("refferal.text22") }}
                      </th>
                      <th width="25%" class="t-left text">
                        {{ $t("refferal.text27") }}
                      </th>
                      <th width="20%" class="t-left text">
                        {{ $t("common.placeholders.email") }}
                      </th>
                      <th width="30%" class="t-right text">
                        {{ $t("refferal.text11") }}
                      </th>
                    </template>
                    <template slot="body" slot-scope="props">
                      <tr>
                        <td width="25%" class="t-left text-table">
                          {{
                            trimNumber(props.item.amount)
                              | formatCurrencyAmount(props.item.coin, "0")
                          }}
                          {{ props.item.coin.toUpperCase() }}
                        </td>
                        <td width="25%" class="t-left text-table">
                          {{ props.item.commission_rate }}%
                        </td>
                        <td width="20%" class="t-left text-table">
                          {{ props.item.email }}
                        </td>
                        <td width="30%" class="t-right text-table">
                          {{ convertTime(props.item.created_at) }}
                        </td>
                      </tr>
                    </template>
                  </data-table2>
                </template>
              </div>
            </div>
          </div>

          <div class="wrap_referal">
            <div class="title_absolute">{{ $t("refferal.text12") }}</div>
            <div class="detail_referal">
              <div class="block_tric_referral">
                <ul>
                  <li class="block_tric__title">
                    <span
                      class="line-bold"
                      v-html="
                        $t('refferal.text14', {
                          rate: trimNumber(this.settingReferral.refund_percent),
                          rate2: trimNumber(
                            this.settingReferral.refund_percent_next_program
                          ),
                          people:
                            this.settingReferral.number_people_in_next_program,
                        })
                      "
                    ></span>
                  </li>
                  <li
                    class="line"
                    v-if="this.settingReferral.number_of_levels >= 1"
                  >
                    {{ $t("referral.level1") }}:
                    <span
                      >{{ $t("referral.rate")
                      }}{{
                        trimNumber(
                          this.settingReferral.refund_percent_at_level_1
                        )
                      }}%</span
                    >
                  </li>
                  <li
                    class="line"
                    v-if="this.settingReferral.number_of_levels >= 2"
                  >
                    {{ $t("referral.level2") }}:
                    <span
                      >{{ $t("referral.rate")
                      }}{{
                        trimNumber(
                          this.settingReferral.refund_percent_at_level_2
                        )
                      }}%</span
                    >
                  </li>
                  <li
                    class="line"
                    v-if="this.settingReferral.number_of_levels >= 3"
                  >
                    {{ $t("referral.level3") }}:
                    <span
                      >{{ $t("referral.rate")
                      }}{{
                        trimNumber(
                          this.settingReferral.refund_percent_at_level_3
                        )
                      }}%</span
                    >
                  </li>
                  <li
                    class="line"
                    v-if="this.settingReferral.number_of_levels >= 4"
                  >
                    {{ $t("referral.level4") }}:
                    <span
                      >{{ $t("referral.rate")
                      }}{{
                        trimNumber(
                          this.settingReferral.refund_percent_at_level_4
                        )
                      }}%</span
                    >
                  </li>
                  <li
                    class="line"
                    v-if="this.settingReferral.number_of_levels >= 5"
                  >
                    {{ $t("referral.level5") }}:
                    <span
                      >{{ $t("referral.rate")
                      }}{{
                        trimNumber(
                          this.settingReferral.refund_percent_at_level_5
                        )
                      }}%</span
                    >
                  </li>
                  <p class="line">
                    *
                    {{
                      $t("referral.next_program", {
                        condition:
                          this.settingReferral.number_people_in_next_program,
                        rate: this.settingReferral.refund_percent_next_program,
                      })
                    }}
                  </p>
                  <li
                    class="line"
                    v-if="this.settingReferral.number_of_levels >= 1"
                  >
                    {{ $t("referral.level1") }}:
                    <span
                      >{{ $t("referral.rate")
                      }}{{
                        trimNumber(
                          this.settingReferral
                            .refund_percent_in_next_program_lv_1
                        )
                      }}%</span
                    >
                  </li>
                  <li
                    class="line"
                    v-if="this.settingReferral.number_of_levels >= 2"
                  >
                    {{ $t("referral.level2") }}:
                    <span
                      >{{ $t("referral.rate")
                      }}{{
                        trimNumber(
                          this.settingReferral
                            .refund_percent_in_next_program_lv_2
                        )
                      }}%</span
                    >
                  </li>
                  <li
                    class="line"
                    v-if="this.settingReferral.number_of_levels >= 3"
                  >
                    {{ $t("referral.level3") }}:
                    <span
                      >{{ $t("referral.rate")
                      }}{{
                        trimNumber(
                          this.settingReferral
                            .refund_percent_in_next_program_lv_3
                        )
                      }}%</span
                    >
                  </li>
                  <li
                    class="line"
                    v-if="this.settingReferral.number_of_levels >= 4"
                  >
                    {{ $t("referral.level4") }}:
                    <span
                      >{{ $t("referral.rate")
                      }}{{
                        trimNumber(
                          this.settingReferral
                            .refund_percent_in_next_program_lv_4
                        )
                      }}%</span
                    >
                  </li>
                  <li
                    class="line"
                    v-if="this.settingReferral.number_of_levels >= 5"
                  >
                    {{ $t("referral.level5") }}:
                    <span
                      >{{ $t("referral.rate")
                      }}{{
                        trimNumber(
                          this.settingReferral
                            .refund_percent_in_next_program_lv_5
                        )
                      }}%</span
                    >
                  </li>
                  <li class="mtop block_tric__title">
                    <span
                      class="line-bold"
                      v-html="$t('refferal.text16', { APP_NAME: APP_NAME })"
                    ></span>
                  </li>
                  <li class="block_tric__title">
                    <span
                      class="line-bold"
                      v-html="$t('refferal.text18')"
                    ></span>
                  </li>
                  <li class="block_tric__title">
                    <span
                      class="line-bold"
                      v-html="$t('refferal.text19', { APP_NAME: APP_NAME })"
                    ></span>
                  </li>
                </ul>
              </div>

              <div class="block_important">
                <p class="fo_blue">{{ $t("refferal.text20") }}</p>
                <p
                  class="text"
                  v-html="$t('refferal.text21', { APP_NAME: APP_NAME })"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import rf from "@/request/RequestFactory";
import BigNumber from "bignumber.js";
import Utils from "@/common/Utils";
import Modal from "@/components/shared_components/common/Modal.vue";
import DateTimeSelect from "@/components/shared_components/common/DateTimeSelect";
import COMMON_CONST from "@/common/Const";
import moment from "moment";

export default {
  components: {
    DateTimeSelect,
    Modal,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      userReferralCommissions: undefined,
      userReferralCommissionsForMargin: undefined,
      prices: undefined,
      sort: {},
      numberFriend: 0,
      refferalCode: {
        referrer_code: "",
        url: "",
        urlImg: "",
      },
      titles: "",
      isLogin: window.isAuthenticated,
      topUserRefCommissions: [],
      settingReferral: {},
      heightTable: "",
      APP_NAME: process.env.VUE_APP_SHORT_NAME,
      showContentTypes: "spot",
    };
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    getHeightTable() {
      const heightTableFriends = document.getElementById(
        "table_referral_friends"
      )?.clientHeight;
      const heightTableLatest = document.getElementById(
        "table_latest_commission"
      )?.clientHeight;

      this.heightTable =
        heightTableFriends > heightTableLatest
          ? heightTableFriends
          : heightTableLatest;
    },
    onLoadHight() {
      this.$nextTick(() => {
        this.getHeightTable();
      });
    },
    convertTime(time) {
      const stillUtc = moment.utc(time).toDate();
      const local = moment(stillUtc).local().format("YYYY-MM-DD HH:mm:ss");
      return local;
    },
    trimNumber(number) {
      if (!number) return number;
      return new BigNumber(number);
    },
    getReferralSetting() {
      rf.getRequest("UserRequest")
        .getReferralSetting()
        .then((res) => {
          this.settingReferral = res.data;
          this.settingReferral.refund_percent =
            Number(this.settingReferral.refund_percent_at_level_1) +
            Number(this.settingReferral.refund_percent_at_level_2) +
            Number(this.settingReferral.refund_percent_at_level_3) +
            Number(this.settingReferral.refund_percent_at_level_4) +
            Number(this.settingReferral.refund_percent_at_level_5);
          this.settingReferral.refund_percent_next_program =
            Number(this.settingReferral.refund_percent_in_next_program_lv_1) +
            Number(this.settingReferral.refund_percent_in_next_program_lv_2) +
            Number(this.settingReferral.refund_percent_in_next_program_lv_3) +
            Number(this.settingReferral.refund_percent_in_next_program_lv_4) +
            Number(this.settingReferral.refund_percent_in_next_program_lv_5);
        });
    },
    fbShareAction() {
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" +
          this.appUrl +
          "/?ref=" +
          this.refferalCode.referrer_code,
        "&t=" + document.title,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
      );
    },
    twShareAction() {
      window.open(
        "https://twitter.com/intent/tweet?original_referer=" +
          this.appUrl +
          "/?ref=" +
          "&ref_src=&text=&tw_p=tweetbutton&url=" +
          this.appUrl +
          "/?ref=" +
          this.refferalCode.referrer_code,
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
      );
    },
    linShareAction() {
      window.open(
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
          this.appUrl +
          "/?ref=" +
          this.refferalCode.referrer_code +
          "&title=" +
          "&summary=" +
          "&source=",
        "",
        "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600"
      );
    },

    showSuccess(message) {
      Message.success(message, {});
    },
    showDateTimeSelectModal() {
      window.CommonModal.show("DateTimeSelect");
    },
    downloadReportFriend() {
      rf.getRequest("UserRequest").downloadReportFriend(
        { timezone_offset: Utils.getTimzoneOffset(), lang: this.$i18n.locale },
        "ReferralFriends_" + moment().format("YYYYMMDDHHmmss")
      );
    },
    downloadReportCommission() {
      rf.getRequest("UserRequest").downloadReportCommission(
        {
          timezone_offset: Utils.getTimzoneOffset(),
          type: this.showContentTypes || "spot",
          lang: this.$i18n.locale,
        },
        "CommissionHistory_" + moment().format("YYYYMMDDHHmmss")
      );
    },

    getUserReferralCommission(params = null) {
      if (!this.isLogin) {
        return;
      }
      let default_object = {
        type: this.showContentTypes || "spot",
      };
      const response = rf
        .getRequest("UserRequest")
        .getUserReferralCommission(Object.assign(default_object, params));
      return response;
    },

    getAllUserReferralCommission() {
      if (!this.isLogin) {
        return;
      }
      const response = rf.getRequest("UserRequest").getUserReferralCommission();
      this.getuserReferralCommissionBalances(response);
      let params = {
        type: "margin",
      };
      const responseForMargin = rf
        .getRequest("UserRequest")
        .getUserReferralCommission(params);
      this.getuserReferralCommissionBalancesForMargin(responseForMargin);
    },
    getUserReferralFriends() {
      if (!this.isLogin) {
        return;
      }
      const promise = rf.getRequest("UserRequest").getUserReferralFriends();
      return promise;
    },
    getAllReferrer(params = null) {
      if (!this.isLogin) {
        return;
      }
      rf.getRequest("UserRequest")
        .getAllReferrer(params)
        .then((res) => {
          this.numberFriend = res.data.total;
        })
        .catch((err) => {});
      const promise = rf.getRequest("UserRequest").getAllReferrer(params);
      return promise;
    },
    getuserReferralCommissionBalances(response) {
      response.then((res) => {
        this.userReferralCommissions = res.data;
      });
    },
    getuserReferralCommissionBalancesForMargin(response) {
      response.then((res) => {
        this.userReferralCommissionsForMargin = res.data;
      });
    },
    getTopUserReferralCommission() {
      rf.getRequest("UserRequest")
        .getTopUserReferralCommission()
        .then((res) => {
          this.topUserRefCommissions = res.data;
        });
    },
    createUserQrcode() {
      let params = {
        url: `${window.location.origin}/?${COMMON_CONST.REFERRAL_KEY}=`,
      };
      rf.getRequest("UserRequest")
        .createUserQrcode(params)
        .then((res) => {
          this.refferalCode = Object.assign({}, this.refferalCode, res.data);
        });
    },
    getPrices() {
      rf.getRequest("PriceRequest")
        .getPrices()
        .then((res) => {
          this.onPricesUpdated(res.data);
        });
    },
    loadDataImmediately() {
      this.getReferralSetting();
      this.createUserQrcode();
      this.getUserReferralFriends();
      // this.getTopUserReferralCommission()
    },
    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
    },
    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
      };
    },
    refresh() {
      this.sumBTC();
      this.sumBTCForMargin();
      const isNoData =
        window._.isEmpty(this.accountBalances) ||
        window._.isEmpty(this.prices) ||
        window._.isEmpty(this.coins);
      if (isNoData) {
        return;
      }
    },
    sumBTC() {
      let sum = new BigNumber(0);
      let self = this;
      _.map(this.userReferralCommissions, function (value, key) {
        sum = sum?.add(
          new BigNumber(
            self.getBalance(value?.amount, value?.coin.toLowerCase(), "btc")
          )
        );
      });
      return sum;
    },
    sumBTCForMargin() {
      let sum = new BigNumber(0);
      let self = this;
      _.map(this.userReferralCommissionsForMargin, function (value, key) {
        sum = sum?.add(
          new BigNumber(
            self.getBalance(value?.amount, value?.coin.toLowerCase(), "btc")
          )
        );
      });
      return sum;
    },
    getBalance(balance, coin, currency) {
      if (!balance) {
        return 0;
      }

      if (coin === currency) {
        return balance;
      }

      const pair_btc =
        coin == "idr" ? `${coin}_${currency}` : `${currency}_${coin}`;
      const pair_eth = `eth_${coin}`;
      const pair_usdt = `usdt_${coin}`;
      const pair_usd = `idr_${coin}`;
      if (!this.prices?.pair_btc) {
        if (pair_btc == "btc_usdt" && this.prices?.usdt_btc)
          return new BigNumber(balance)
            .div(this.prices["usdt_btc"].price)
            .toString();
        if (this.prices?.pair_eth) {
          return new BigNumber(balance)
            .mul(this.prices[pair_eth].price)
            .mul(this.prices["btc_eth"].price)
            .toString();
        }
        if (this.prices?.pair_usdt) {
          return new BigNumber(balance)
            .mul(this.prices[pair_usdt].price)
            .div(this.prices["usdt_btc"].price)
            .toString();
        }
        if (this.prices?.pair_usd) {
          return new BigNumber(balance)
            .mul(this.prices[pair_usd].price)
            .div(this.prices["idr_btc"].price)
            .toString();
        }
        return 0;
      }
      return coin == "idr"
        ? new BigNumber(balance).div(this.prices[pair_btc].price).toString()
        : new BigNumber(balance).mul(this.prices[pair_btc].price).toString();
    },
    getImageMedal(index) {
      switch (index) {
        case 0:
          return "icon-no1";
        case 1:
          return "icon-no2";
        case 2:
          return "icon-no3";
      }
    },
  },
  mounted() {
    document.title = this.$t("refferal.title") + ` – ${this.tabTitle}`;
    if (this.isLogin) {
      this.loadDataImmediately();
      this.getAllUserReferralCommission();
    }
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  watch: {
    prices(newPrices) {
      this.refresh();
    },
    showContentTypes(newValue) {
      this.$refs.datatable_commission.refresh();
    },
  },
  computed: {
    isMobile() {
      if (!this.windowWidth) return;
      return this.windowWidth <= 768;
    },
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === "storage_url")
        .value;
    },
    QR_CODE_URL() {
      const now = new Date();
      return this.refferalCode?.urlImg
        ? this.STORAGE_URL + this.refferalCode.urlImg + "?v=" + now.getTime()
        : "";
    },
    balanceCommissions() {
      if (this.userReferralCommissions) {
        return this.sumBTC();
      }
    },
    balanceCommissionsForMargin() {
      if (this.userReferralCommissionsForMargin) {
        return this.sumBTCForMargin();
      }
    },
    appUrl() {
      return window.location.origin;
    },
  },
  created() {
    this.getPrices();
  },
};
</script>

<style scoped lang="scss" scoped>
.content_referral {
  max-width: 1370px;
  margin: 0 auto;
  color: $text-main;
  padding: 25px 15px;

  .conainer_referral {
    width: 100%;

    .tit_page_referral {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 24px;
    }

    .body_page_referral {
      background: white;
      padding: 25px;
      border-radius: 10px;

      .step_account {
        display: flex;
        justify-content: center;
        gap: 25px;
        margin-bottom: 30px;

        .block_circle {
          border-radius: 10px;
          position: relative;
          width: 100%;

          .block_content {
            position: relative;
            padding: 16px;
            background-color: $bg-main3;
            border: 1px solid $bg-main1;
            border-radius: 10px;
            // background-image: url(@/assets/images/icon/Vector-1.png);
            // background-size: 100% 100%;
            // background-repeat: no-repeat;

            .circle {
              margin-bottom: 24px;

              .icon {
                width: 50px;
                height: 50px;
              }
            }

            .title_step {
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 32px;
              color: $text-main;
              margin-bottom: 5px;
            }

            .content_step {
              font-size: 14px;
              line-height: 22px;
              color: $text-main-blur;

              padding-right: 20px;
            }
          }
        }
      }

      .box_qr_referral {
        padding: 25px;
        border-radius: 10px;
        background: var(
          --gradient,
          linear-gradient(180deg, #010304 0%, rgba(65, 65, 65, 0.9) 100%)
        );
        display: flex;
        justify-content: space-between;

        .box_qr_referral_left {
          display: flex;
          width: 50%;
          border-right: 1px solid $bg-main2;

          .crop_qr_referral {
            margin-right: 23px;
            border-radius: 20px;

            img {
              border-radius: 20px;
              width: 145px;
              height: 145px;
            }

            .qr__content-mobile-wrapper {
              display: none;
            }
          }

          .qr_content_referral {
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
            margin-top: auto;
            margin-bottom: auto;
            max-width: 100%;
            overflow: auto;
            padding-right: 16px;

            .id_referral {
              display: flex;
              align-items: center;
              gap: 10px;

              .tit_id_referral,
              .code_id_referral {
                color: $color-white;
              }
            }

            .society_link {
              display: flex;
              align-items: center;
              margin-bottom: 3px;
              gap: 12px;

              .tit_society_link {
                color: $color-white;
              }

              .list_society_link {
                display: flex;
                align-items: center;

                > * {
                  margin-right: 14px;

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }

            .group_input_link_referran {
              .tit_input_link_referran {
                font-family: $helvetica;
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                color: $color-white;
                margin-bottom: 9px;
              }

              .link_referran_value {
                display: flex;
                gap: 12px;
              }

              .link_referral {
                padding: 10.5px 16px;
                background: transparent;
                border: 1px solid $color-white;
                border-radius: 12px;
                width: 242px;
                min-height: 40px;

                @include mobile {
                  width: 100%;
                }

                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                font-family: $helvetica;
                font-size: 16px;
                font-style: normal;
                font-weight: 300;
                line-height: 24px;
                color: $color-white;
              }

              .btn-copy {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 38px;
                margin-top: auto;
                gap: 10px;

                padding: 7px 24px;
                border-radius: 60px;
                background: transparent;

                font-family: $helvetica;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                border: 1px solid $color-white;
                color: $color-white;
                white-space: nowrap;
              }
            }
          }
        }

        .box_qr_referral_right {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .referel_block {
            display: flex;
            justify-content: center;
            gap: 40px;
            flex: 1;

            .referal_element {
              text-align: center;
              flex: 1;

              .content {
                font-family: $helvetica;
                font-weight: 700;
                font-size: 24px;
                line-height: 30px;
                color: $color-white;
              }

              .tit {
                font-family: $helvetica;
                max-width: 182px;
                margin-left: auto;
                margin-right: auto;
                font-size: 14px;
                line-height: 25px;
                color: $color-white;
                opacity: 0.6;
              }
            }
          }
        }
      }

      .export_refarral {
        margin-top: 26px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 28px;

        .table_left_refarral {
          display: flex;
          flex-direction: column;
        }

        .table_left_refarral,
        .table_right_refarral {
          .table_header {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .table_title {
              font-size: 24px;
              font-style: normal;
              font-weight: 700;
              line-height: 40px;
              color: $text-main;
              margin: unset;
            }

            .excel_export {
              display: flex;
              align-items: center;

              img {
                width: 18px;
                height: auto;
              }

              .text_link {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                color: $bg-main1;

                margin-left: 7px;
              }
            }
          }

          .datatable {
            flex: 1;
            min-height: 250px;

            :deep .table-friend {
              padding: unset;

              tbody {
                td {
                  padding: 10px 8px 12px;
                  white-space: nowrap;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 22px;
                  color: $text-main;
                  border-bottom: 1px solid #dddddd;

                  &:first-child {
                    padding-left: 20px;
                  }

                  &:last-child {
                    padding-right: 20px;
                    text-align: start;
                  }

                  @include mobile {
                    &:first-child {
                      padding-left: 16px !important;
                    }

                    &:last-child {
                      text-align: start;
                      padding-right: 16px !important;
                    }
                  }
                }
              }

              thead {
                background-color: #f8f8f8;

                & tr th {
                  padding: 10px !important;
                  @include text-font(
                    $helvetica,
                    $text-secondary,
                    14px,
                    700,
                    24px
                  );
                  white-space: nowrap;
                  border-bottom: unset !important;

                  &:first-child {
                    border-top-left-radius: 10px;
                    padding-left: 20px !important;
                  }

                  &:last-child {
                    border-top-right-radius: 10px;
                    text-align: start;
                    padding-right: 20px !important;
                  }

                  @include mobile {
                    padding: 8px !important;
                    @include text-font(
                      $helvetica,
                      $text-secondary,
                      12px,
                      700,
                      16px
                    );

                    &:first-child {
                      padding-left: 16px !important;
                    }

                    &:last-child {
                      text-align: start;
                      padding-right: 16px !important;
                    }
                  }
                }
              }
            }

            :deep .table-history {
              padding: unset;
              min-height: 379px;
              border-radius: 10px;

              tbody {
                td {
                  padding: 10px 8px 12px;
                  white-space: nowrap;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 22px;
                  color: $text-main;
                  border-bottom: 1px solid #dddddd;

                  &:first-child {
                    padding-left: 20px;
                  }

                  &:last-child {
                    padding-right: 20px;
                  }
                }
              }

              thead {
                background-color: #f8f8f8;

                & tr th {
                  padding: 10px !important;
                  @include text-font(
                    $helvetica,
                    $text-secondary,
                    14px,
                    700,
                    24px
                  );
                  white-space: nowrap;
                  border-bottom: unset !important;

                  &:first-child {
                    border-top-left-radius: 10px;
                    padding-left: 20px !important;
                  }

                  &:last-child {
                    border-top-right-radius: 10px;
                    padding-right: 20px !important;
                  }
                }
              }
            }

            // :deep() th {
            //   white-space: nowrap;
            //   padding-left: 4px !important;
            //   padding-right: 4px !important;
            //   &:first-child {
            //     padding-left: 0 !important;
            //   }
            //   &:last-child {
            //     padding-right: 0 !important;
            //   }
            // }
            .head_group_positions {
              display: flex;

              .item {
                padding: 7px 20px;
                border-radius: 10px 10px 0px 0px;
                text-align: center;
                font-size: 14px;
                line-height: 24px;
                font-weight: 700;
                color: $dark-3;
                background: $grey-3;
                border: 1px solid $bg-main2;
                border-bottom: transparent;
                color: $dark-3;
                cursor: pointer;

                &.active {
                  background: $color-white;
                  color: $text-main;
                }
              }
            }
          }
        }
      }

      .wrap_referal {
        margin-top: 27px;

        :deep br {
          display: none;
        }

        .title_absolute {
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px;
          margin-bottom: 15px;
          color: $text-main;
        }

        .detail_referal {
          background: white;

          .line-bold {
            font-weight: 400;
            color: $text-main;
          }

          .mtop {
            // display: inline-block;
            margin-top: 10px !important;
          }

          .block_tric_referral {
            ul {
              list-style-type: initial;
              padding-left: 20px;
            }

            li {
              font-size: 16px;
              font-weight: 300;
              line-height: 24px;
              color: $text-secondary;

              &.block_tric__title {
                &::marker {
                  font-size: 12px;
                }
              }
            }

            p {
              &.line {
                margin-left: -20px;
              }

              margin: 0;
              font-size: 16px;
              line-height: 24px;
              color: $text-secondary;
            }
          }

          .block_important {
            margin-top: 10px;
            padding: 20px;
            background: $bg-main3;

            .fo_blue {
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              color: $text-main;
            }

            .text {
              font-size: 16px;
              font-weight: 300;
              line-height: 24px;
              color: $text-secondary;
            }
          }
        }
      }
    }
  }
}

@include tablet() {
  .content_referral .conainer_referral .body_page_referral {
    .box_qr_referral {
      padding: 16px;

      .box_qr_referral_left {
        width: 100%;

        .crop_qr_referral {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-right: 16px;
        }

        .qr_content_referral {
          .id_referral {
          }

          .society_link {
            // margin-top: 6px;
          }

          .group_input_link_referran {
            .tit_input_link_referran {
            }
          }
        }

        .qr_content_referral {
          .group_input_link_referran {
            .link_referran_value {
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }

            .link_referral {
              min-width: 100%;
              margin-right: 0;
            }

            .btn-copy {
              min-width: 100%;
              padding: 10.5px 0px;
            }
          }
        }
      }

      .box_qr_referral_right {
        width: 100%;

        .referel_block {
          margin-top: 0px;
          flex-direction: column;
          gap: 24px;

          .referal_element {
            .content {
              font-size: 20px;
              line-height: 25px;
            }

            .tit {
              font-size: 14px;
              line-height: 25px;
            }
          }
        }
      }
    }

    .export_refarral {
      grid-template-columns: 1fr;

      .table_left_refarral,
      .table_right_refarral {
        width: 100%;
        overflow: auto;

        .table_header {
          margin-bottom: 15px;
        }

        .text {
          font-size: 14px;
        }
      }

      .table_right_refarral {
        // .head_group_positions {
        //   .item {
        //     flex: 1;
        //   }
        // }
        .tableContainer {
          border-top-right-radius: 0;

          :deep .box_table2 {
            overflow: auto;

            // table {
            //   min-width: 800px;
            // }
          }
        }
      }
    }
  }
}

@include mobile() {
  .commission-history__mobile-wrapper {
    border-radius: 0px 10px 10px 10px;
    border: 1px solid $bg-main2;

    .data-list__wrapper {
      .commission-history__mobile-title {
        display: grid;
        border-radius: 0 10px 0px 0px;
        background-color: $bg-main4;
        padding: 8px 16px;
        grid-template-columns: repeat(2, 1fr);

        .mobile-title__item {
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px;
          color: $text-secondary;
        }
      }

      .commission-history__mobile-item {
        background-color: $color-white;
        padding: 10px 16px;
        border-bottom: 1px solid $bg-main2;

        .mobile-item__wrapper {
          display: grid;
          position: relative;
          grid-template-columns: repeat(2, 1fr);

          .item-wrapper__title {
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
            color: $text-main;
          }

          .item-wrapper__dropdown-icon {
            position: absolute;
            right: 0px;
          }
        }

        .mobile-item__expand-content {
          display: none;

          &.active {
            display: flex;
          }

          margin-top: 12px;
          background-color: $bg-main4;
          padding: 16px;
          flex-direction: column;
          border-radius: 8px;
          gap: 8px;

          .expand-content__wrapper {
            display: flex;
            justify-content: space-between;

            .expand-content__title {
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              color: $text-main;
            }

            .expand-content__desc {
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 22px;
              color: $text-main;
            }
          }
        }

        &:last-child.isHidePaginate {
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          border-bottom: none;
        }
      }

      :deep table.table-nodata .empty-data {
        background-color: transparent;
      }
    }
  }

  .t-center {
    text-align: center;
  }

  .content_referral {
    padding: 16px;

    .conainer_referral {
      .tit_page_referral {
        color: $text-main;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
      }

      .body_page_referral {
        padding: 27px 16px;

        .step_account {
          flex-direction: column;
          gap: 10px;
          margin-bottom: 20px;

          .block_circle {
            .block_content {
              padding: 16px;
              min-height: 160px;
              background-repeat: no-repeat;
              display: flex;
              flex-direction: column;
              gap: 16px;

              .circle {
                width: 100%;
                height: 40px;
                margin-bottom: 10px;

                .icon {
                  width: 40px;
                  height: 40px;
                  height: 100%;
                }
              }

              .block-content__wrapper {
                width: 100%;
              }

              .title_step {
                width: 100%;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                color: $bg-main1;
                line-height: 24px;
                margin-bottom: 5px;
              }

              .content_step {
                width: 100%;
                font-size: 12px;
                font-style: normal;
                font-weight: 300;
                line-height: 16px;
                color: $text-main-blur;
              }
            }
          }
        }

        .box_qr_referral {
          flex-direction: column;
          padding: 25px 16px;

          .box_qr_referral_left {
            width: 100%;
            border-right: none;
            border-bottom: 1px solid rgba(221, 221, 221, 0.2);
            flex-direction: column;
            padding-bottom: 16px;

            .crop_qr_referral {
              margin-right: 0;
              display: flex;
              justify-content: space-between;

              img {
                width: 90px;
                height: 90px;
              }

              .qr__content-mobile-wrapper {
                display: flex;
                flex-direction: column;
                gap: 8px;
                justify-content: center;

                .id_referral {
                  color: $color-white;

                  .tit_id_referral {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 22px;
                  }

                  .code_id_referral {
                  }
                }

                .society_link {
                  display: flex;
                  gap: 12px;
                  justify-content: flex-end;

                  .tit_society_link {
                    color: $color-white;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 22px;
                  }

                  .list_society_link {
                    display: flex;
                    gap: 15px;

                    img {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
              }
            }

            .qr_content_referral {
              display: flex;
              padding-right: unset;
              justify-content: center;
              flex-direction: column;
              margin-top: 24px;

              .id_referral {
                display: none;
                justify-content: center;
              }

              .society_link {
                display: none;
                margin-top: 6px;
                flex-direction: column;
                justify-content: center;
                gap: 0;

                > .tit_society_link {
                  margin-bottom: 10px;
                }
              }

              .group_input_link_referran {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .tit_input_link_referran {
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 24px;
                }
              }
            }

            .qr_content_referral {
              .group_input_link_referran {
                .link_referran_value {
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                }

                .link_referral {
                  min-width: 100%;
                  margin-right: 0;
                  padding: 8px 16px;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 22px;
                }

                .btn-copy {
                  min-width: 100%;
                  padding: 7px 24px;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 24px;
                  gap: 10px;

                  img {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }

          .box_qr_referral_right {
            width: 100%;
            padding-top: 16px;

            .referel_block {
              margin-top: 0px;
              flex-direction: row;
              gap: 16px;
              max-width: 100%;

              .referal_element {
                max-width: calc(50% - 8px);

                &:first-child {
                  .tit,
                  .content {
                    text-align: left;
                  }
                }

                &:last-child {
                  .tit,
                  .content {
                    text-align: right;
                  }
                }

                .content {
                  font-size: 18px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 32px;
                  white-space: nowrap;
                }

                .tit {
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 300;
                  margin: unset;
                  max-width: unset;
                  margin-bottom: 4px;
                  opacity: 0.7;
                  line-height: 16px;
                }
              }
            }
          }
        }

        .export_refarral {
          grid-template-columns: 1fr;
          gap: 24px;

          .table_left_refarral,
          .table_right_refarral {
            width: 100%;
            overflow: auto;

            .table_header {
              margin-bottom: 20px;
              gap: 8px;

              // flex-wrap: wrap;
              .table_title {
                color: $text-main;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 32px;
              }

              .excel_export .text_link {
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 22px;
                color: $bg-main1;
              }
            }

            .text {
              font-size: 14px;
            }
          }

          .table_right_refarral {
            .table_header {
              flex-direction: column;
              align-items: flex-start;
            }

            .datatable {
              min-height: unset;

              .head_group_positions {
                .item {
                  font-size: 12px;
                  padding: 7px 20px;
                  min-width: 142px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 16px;
                }
              }
            }

            .tableContainer {
              border-top-right-radius: 0;

              :deep .box_table2 {
                overflow: auto;

                // table {
                //   min-width: 800px;
                // }
              }
            }
          }
        }

        .wrap_referal {
          li {
            text-align: justify;
          }

          .title_absolute {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
          }

          .detail_referal {
            .block_tric_referral {
              ul {
                padding-left: 16px;

                li {
                  &:first-child {
                    margin-bottom: 10px;
                  }

                  &.line {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 22px;
                  }

                  &::marker {
                    font-size: 16px;
                  }

                  &.block_tric__title {
                    &::marker {
                      font-size: 12px;
                    }
                  }
                }

                li,
                p {
                  font-size: 14px;
                }

                p.line {
                  margin-left: -16px;
                }

                span.line-bold {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                }
              }
            }

            .block_important {
              p.fo_blue {
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
              }

              p.text {
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
#referral {
  .tableContainer {
    .box_table2 {
      .t-left {
        text-align: left;
      }
    }
  }
}
</style>
