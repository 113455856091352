export const orderHistory = {
  success: true,
  message: null,
  dataVersion: "e6a0ac32a50b8094ed284297826419f0dbcc6737",
  data: {
    current_page: 1,
    data: [
      {
        id: 5833,
        email: "qc25052023+102@gmail.com",
        updated_at: 1691142882135,
        coin: "xrp",
        currency: "idr",
        type: "limit",
        trade_type: "buy",
        executed_price: "10000.0000000000",
        price: "10000.0000000000",
        executed_quantity: "0.0010000000",
        quantity: "0.0010000000",
        fee: "0.0000000000",
        status: "executed",
        stop_condition: null,
        base_price: null,
      },
      {
        id: 5813,
        email: "qc25052023+102@gmail.com",
        updated_at: 1691141392553,
        coin: "xrp",
        currency: "idr",
        type: "limit",
        trade_type: "sell",
        executed_price: "12000.0000000000",
        price: "12000.0000000000",
        executed_quantity: "0.0010000000",
        quantity: "0.0010000000",
        fee: "0.0000000000",
        status: "executed",
        stop_condition: null,
        base_price: null,
      },
      {
        id: 5811,
        email: "qc25052023+102@gmail.com",
        updated_at: 1691141334651,
        coin: "usdt",
        currency: "idr",
        type: "limit",
        trade_type: "sell",
        executed_price: "10000.0000000000",
        price: "10000.0000000000",
        executed_quantity: "0.0010000000",
        quantity: "0.0010000000",
        fee: "0.0000000000",
        status: "executed",
        stop_condition: null,
        base_price: null,
      },
      {
        id: 5810,
        email: "qc25052023+102@gmail.com",
        updated_at: 1691141323420,
        coin: "usdt",
        currency: "idr",
        type: "limit",
        trade_type: "sell",
        executed_price: "10340.0000000000",
        price: "10000.0000000000",
        executed_quantity: "0.0010000000",
        quantity: "0.0010000000",
        fee: "0.0046530000",
        status: "executed",
        stop_condition: null,
        base_price: null,
      },
      {
        id: 5762,
        email: "qc25052023+102@gmail.com",
        updated_at: 1691116499200,
        coin: "btc",
        currency: "idr",
        type: "limit",
        trade_type: "sell",
        executed_price: "0.0000000000",
        price: "9000.0000000000",
        executed_quantity: "0.0000000000",
        quantity: "0.0100000000",
        fee: "0.0000000000",
        status: "canceled",
        stop_condition: null,
        base_price: null,
      },
      {
        id: 5761,
        email: "qc25052023+102@gmail.com",
        updated_at: 1691116275193,
        coin: "eth",
        currency: "btc",
        type: "limit",
        trade_type: "sell",
        executed_price: "0.0000000000",
        price: "1.0000000000",
        executed_quantity: "0.0000000000",
        quantity: "0.0050000000",
        fee: "0.0000000000",
        status: "canceled",
        stop_condition: null,
        base_price: null,
      },
      {
        id: 5760,
        email: "qc25052023+102@gmail.com",
        updated_at: 1691116267829,
        coin: "eth",
        currency: "btc",
        type: "limit",
        trade_type: "sell",
        executed_price: "1.0000000000",
        price: "1.0000000000",
        executed_quantity: "0.0040000000",
        quantity: "0.0040000000",
        fee: "0.0000000000",
        status: "executed",
        stop_condition: null,
        base_price: null,
      },
      {
        id: 5759,
        email: "qc25052023+102@gmail.com",
        updated_at: 1691116263818,
        coin: "eth",
        currency: "btc",
        type: "limit",
        trade_type: "sell",
        executed_price: "1.0000000000",
        price: "1.0000000000",
        executed_quantity: "0.0030000000",
        quantity: "0.0030000000",
        fee: "0.0000000000",
        status: "executed",
        stop_condition: null,
        base_price: null,
      },
      {
        id: 5758,
        email: "qc25052023+102@gmail.com",
        updated_at: 1691116259286,
        coin: "eth",
        currency: "btc",
        type: "limit",
        trade_type: "sell",
        executed_price: "1.0000000000",
        price: "1.0000000000",
        executed_quantity: "0.0020000000",
        quantity: "0.0020000000",
        fee: "0.0000000000",
        status: "executed",
        stop_condition: null,
        base_price: null,
      },
      {
        id: 5757,
        email: "qc25052023+102@gmail.com",
        updated_at: 1691116247008,
        coin: "eth",
        currency: "btc",
        type: "limit",
        trade_type: "sell",
        executed_price: "1.0000000000",
        price: "1.0000000000",
        executed_quantity: "0.0010000000",
        quantity: "0.0010000000",
        fee: "0.0000000000",
        status: "executed",
        stop_condition: null,
        base_price: null,
      },
    ],
    first_page_url:
      "http://dev-api.nvx.co.id/api/v1/orders/transactions?page=1",
    from: 1,
    last_page: 7,
    last_page_url: "http://dev-api.nvx.co.id/api/v1/orders/transactions?page=7",
    links: [
      { url: null, label: "pagination.previous", active: false },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/transactions?page=1",
        label: "1",
        active: true,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/transactions?page=2",
        label: "2",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/transactions?page=3",
        label: "3",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/transactions?page=4",
        label: "4",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/transactions?page=5",
        label: "5",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/transactions?page=6",
        label: "6",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/transactions?page=7",
        label: "7",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/transactions?page=2",
        label: "pagination.next",
        active: false,
      },
    ],
    next_page_url: "http://dev-api.nvx.co.id/api/v1/orders/transactions?page=2",
    path: "http://dev-api.nvx.co.id/api/v1/orders/transactions",
    per_page: 10,
    prev_page_url: null,
    to: 10,
    total: 70,
  },
};

export const tradeHistory = {
  success: true,
  message: null,
  dataVersion: "e6a0ac32a50b8094ed284297826419f0dbcc6737",
  data: {
    current_page: 1,
    data: [
      {
        trade_type: "sell",
        fee: "0.0000003000",
        tax: "0.0120000000",
        fee_amal: null,
        created_at: 1691147061546,
        currency: "idr",
        coin: "xrp",
        price: "12000.0000000000",
        quantity: "0.0010000000",
        amount: "12.0000000000",
      },
      {
        trade_type: "buy",
        fee: "0.0000200000",
        tax: "0.0000011000",
        fee_amal: null,
        created_at: 1691146761962,
        currency: "idr",
        coin: "xrp",
        price: "10000.0000000000",
        quantity: "0.0010000000",
        amount: "10.0000000000",
      },
      {
        trade_type: "sell",
        fee: "0.0000007000",
        tax: "0.0100000000",
        fee_amal: null,
        created_at: 1691142624248,
        currency: "idr",
        coin: "usdt",
        price: "10000.0000000000",
        quantity: "0.0010000000",
        amount: "10.0000000000",
      },
      {
        trade_type: "sell",
        fee: "0.0046530000",
        tax: "0.0103400000",
        fee_amal: null,
        created_at: 1691141323949,
        currency: "idr",
        coin: "usdt",
        price: "10340.0000000000",
        quantity: "0.0010000000",
        amount: "10.3400000000",
      },
      {
        trade_type: "sell",
        fee: "0.0000000000",
        tax: "0.0000084000",
        fee_amal: null,
        created_at: 1691137433947,
        currency: "btc",
        coin: "eth",
        price: "1.0000000000",
        quantity: "0.0040000000",
        amount: "0.0040000000",
      },
      {
        trade_type: "sell",
        fee: "0.0000000000",
        tax: "0.0000063000",
        fee_amal: null,
        created_at: 1691137433743,
        currency: "btc",
        coin: "eth",
        price: "1.0000000000",
        quantity: "0.0030000000",
        amount: "0.0030000000",
      },
      {
        trade_type: "sell",
        fee: "0.0000000000",
        tax: "0.0000042000",
        fee_amal: null,
        created_at: 1691137433461,
        currency: "btc",
        coin: "eth",
        price: "1.0000000000",
        quantity: "0.0020000000",
        amount: "0.0020000000",
      },
      {
        trade_type: "sell",
        fee: "0.0000000000",
        tax: "0.0000021000",
        fee_amal: null,
        created_at: 1691137433248,
        currency: "btc",
        coin: "eth",
        price: "1.0000000000",
        quantity: "0.0010000000",
        amount: "0.0010000000",
      },
      {
        trade_type: "sell",
        fee: "0.0000000000",
        tax: "0.0900000000",
        fee_amal: null,
        created_at: 1691136294046,
        currency: "idr",
        coin: "btc",
        price: "90000000.0000000000",
        quantity: "0.0000010000",
        amount: "90.0000000000",
      },
      {
        trade_type: "buy",
        fee: "0.0000009000",
        tax: "0.0000011000",
        fee_amal: null,
        created_at: 1690969117558,
        currency: "idr",
        coin: "xrp",
        price: "10299.0000000000",
        quantity: "0.0010000000",
        amount: "10.2990000000",
      },
    ],
    first_page_url:
      "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=1",
    from: 1,
    last_page: 10,
    last_page_url:
      "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=10",
    links: [
      { url: null, label: "pagination.previous", active: false },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=1",
        label: "1",
        active: true,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=2",
        label: "2",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=3",
        label: "3",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=4",
        label: "4",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=5",
        label: "5",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=6",
        label: "6",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=7",
        label: "7",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=8",
        label: "8",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=9",
        label: "9",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=10",
        label: "10",
        active: false,
      },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=2",
        label: "pagination.next",
        active: false,
      },
    ],
    next_page_url:
      "http://dev-api.nvx.co.id/api/v1/orders/trading-histories?page=2",
    path: "http://dev-api.nvx.co.id/api/v1/orders/trading-histories",
    per_page: 10,
    prev_page_url: null,
    to: 10,
    total: 92,
  },
};

export const funds = {
  data: {
    data: [
      {
        coin: "btc",
        name: "Bitcoin",
        totalBalance: "0.4246680606",
        availableBalance: "0.4246680606",
        order: "0",
        orderBtcValue: "0",
        btcValue: "0.4246680606",
        usdValue: "Infinity",
        isDeposit: 1,
        isWithdraw: 1,
      },
      {
        coin: "bch",
        name: "Bitcoin Cash",
        totalBalance: "13.3203302354",
        availableBalance: "3.3203302354",
        order: "10",
        orderBtcValue: "1.1",
        btcValue: "1.465236325894",
        usdValue: "48819010.312741",
        isDeposit: 1,
        isWithdraw: 1,
      },
      {
        coin: "ada",
        name: "Cardano",
        totalBalance: "533.1810308959",
        availableBalance: "533.1810308959",
        order: "0",
        orderBtcValue: "0",
        btcValue: "0.0063981723707508",
        usdValue: "2455298.6472756195",
        isDeposit: 1,
        isWithdraw: 1,
      },
      {
        coin: "eth",
        name: "Ethereum",
        totalBalance: "1.3802174284",
        availableBalance: "1.3802174284",
        order: "0",
        orderBtcValue: "0",
        btcValue: "1.3802174284",
        usdValue: "38245824.940964",
        isDeposit: 1,
        isWithdraw: 1,
      },
      {
        coin: "ltc",
        name: "LiteCoin",
        totalBalance: "5.9295440591",
        availableBalance: "5.9295440591",
        order: "0",
        orderBtcValue: "0",
        btcValue: "83.0136168274",
        usdValue: "8119394.470859205607",
        isDeposit: 1,
        isWithdraw: 1,
      },
      {
        coin: "xrp",
        name: "Ripple",
        totalBalance: "4.9277829590",
        availableBalance: "4.9267829590",
        order: "0.001",
        orderBtcValue: "0.00011",
        btcValue: "0.54205612549",
        usdValue: "64061.178467",
        isDeposit: 1,
        isWithdraw: 1,
      },
      {
        coin: "usdt",
        name: "USDT",
        totalBalance: "2498608.5586180000",
        availableBalance: "2498607.5576180000",
        order: "1.001",
        orderBtcValue: "0",
        btcValue: "85.73326493560591243886",
        usdValue: "24986085586.18",
        isDeposit: 1,
        isWithdraw: 1,
      },
      {
        coin: "bnb",
        name: "currency.bnb.fullname",
        totalBalance: "1.4107878011",
        availableBalance: "1.4107878011",
        order: "0",
        orderBtcValue: 0,
        btcValue: "0.0116049616583821155",
        usdValue: "5172889.0742959337",
        isDeposit: 1,
        isWithdraw: 1,
      },
      {
        coin: "busd",
        name: "currency.busd.fullname",
        totalBalance: "472.7367994752",
        availableBalance: "472.7367994752",
        order: "0",
        orderBtcValue: 0,
        btcValue: "0.0458967766480776699",
        usdValue: "4892825.87456832",
        isDeposit: 1,
        isWithdraw: 1,
      },
      {
        coin: "idr",
        name: "currency.idr.fullname",
        totalBalance: "5602695.7297242518",
        availableBalance: "5602695.7297242518",
        order: "0",
        orderBtcValue: "0",
        btcValue: "0.06225217477471390889",
        usdValue: "5602695.7297242518",
        isDeposit: 1,
        isWithdraw: 1,
      },
    ],
  },
};

export const openOrders = {
  success: true,
  message: null,
  dataVersion: "6ce93f5078ee4c1516fbe107481dd7e30a17564e",
  data: {
    current_page: 1,
    data: [
      {
        id: 5753,
        original_id: null,
        user_id: 591,
        email: "qc25052023+106@gmail.com",
        trade_type: "buy",
        currency: "idr",
        coin: "btc",
        type: "limit",
        ioc: null,
        quantity: "0.1000000000",
        price: "1000.0000000000",
        executed_quantity: "0.0000000000",
        executed_price: "0.0000000000",
        base_price: null,
        stop_condition: null,
        fee: "0.0000000000",
        tax_vat: "0.0000000000",
        tax_vat_rate: "0",
        tax_income: "0.0000000000",
        tax_income_rate: "0",
        tax_unit: null,
        status: "pending",
        created_at: 1691053485470,
        updated_at: 1691053485470,
        total: "100.00000000000000000000",
      },
      {
        id: 5733,
        original_id: null,
        user_id: 591,
        email: "qc25052023+106@gmail.com",
        trade_type: "sell",
        currency: "idr",
        coin: "btc",
        type: "limit",
        ioc: null,
        quantity: "1.0000000000",
        price: "460000000.0000000000",
        executed_quantity: "0.6119033300",
        executed_price: "460000000.0000000000",
        base_price: null,
        stop_condition: null,
        fee: "281475.5318000000",
        tax_vat: "0.0000000000",
        tax_vat_rate: "0",
        tax_income: "281475.5318000000",
        tax_income_rate: "0.001",
        tax_unit: "idr",
        status: "executing",
        created_at: 1690887566454,
        updated_at: 1690887566454,
        total: "460000000.00000000000000000000",
      },
    ],
    first_page_url: "http://dev-api.nvx.co.id/api/v1/orders/pending?page=1",
    from: 1,
    last_page: 1,
    last_page_url: "http://dev-api.nvx.co.id/api/v1/orders/pending?page=1",
    links: [
      { url: null, label: "pagination.previous", active: false },
      {
        url: "http://dev-api.nvx.co.id/api/v1/orders/pending?page=1",
        label: "1",
        active: true,
      },
      { url: null, label: "pagination.next", active: false },
    ],
    next_page_url: null,
    path: "http://dev-api.nvx.co.id/api/v1/orders/pending",
    per_page: 10,
    prev_page_url: null,
    to: 2,
    total: 2,
  },
};
