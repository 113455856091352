<template>
  <div>
    <transition name="modal" v-if="isMasterdataChanged">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-body">
              <p name="body" v-html="$t('masterdata.text')"></p>
            </div>
            <div class="modal-footer">
              <div name="footer" class="text-center btn-control">
                <a href="#" @click="reload()" class="btn btn-primary btn-sm"
                  >{{ $t("common.refresh") }} ({{ limitSecond }})</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Const from "@/common/Const";
import rf from "@/request/RequestFactory";

export default {
  data() {
    return {
      isMasterdataChanged: false,
      label: "",
      limitSecond: 5,
    };
  },
  watch: {
    limitSecond(newValue) {
      if (!newValue) {
        this.reload();
      } else {
        this.decreaseTime();
      }
    },
  },
  methods: {
    reload() {
      window.location.reload();
    },

    decreaseTime() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.limitSecond--;
        }, 1000);
      });
    },

    getMasterdataLatest() {
      rf.getRequest("MasterdataRequest").getAll(true);
    },
  },
  created() {
    this.$on(Const.MASTERDATA.EVENT_CHANGED, (data) => {
      this.getMasterdataLatest();
    });
  },
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 300px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  border-bottom: none !important;
}

.modal-body {
  text-align: center;
}

.modal-footer {
  border-top: none !important;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.btn-primary {
  color: white;
  font-size: 14px;
}

.btn-primary:active {
  border: none;
}
</style>
