<template>
  <div
    id="trade-history"
    :class="{ no_padding: options && options.isNoPadding }"
  >
    <div class="group-control" v-if="!options">
      <date-tab @change-select-date="changeDate" ref="selectDateTab" />
      <div class="group-date">
        <span class="date-title">From</span>
        <datepicker
          :value="dateFromDefault"
          class="date-from"
          @selected="selectDateFrom"
          :format="customFormatter"
        >
        </datepicker>
        <datepicker
          :value="dateToDefault"
          class="date-to"
          @selected="selectDateTo"
          :format="customFormatter"
        >
        </datepicker>
      </div>
      <button class="button-search" @click="actionSearch">Search</button>
    </div>
    <div
      class="group-control"
      v-if="options"
      :class="{ is_single_page: options && options.isNoPadding }"
    >
      <div class="group_1">
        <div class="group-date-picker">
          <div class="group-date">
            <div class="label-text">{{ $t("order.order_history.date") }}</div>
            <datepicker
              :value="dateFromDefault"
              class="date-from"
              @selected="selectDateFrom"
              :format="customFormatter"
              input-class="date-picker-input"
            ></datepicker>
          </div>
          <div class="group-date">
            <div class="label-text">To</div>
            <datepicker
              :value="dateToDefault"
              class="date-to"
              @selected="selectDateTo"
              :format="customFormatter"
              input-class="date-picker-input"
            ></datepicker>
          </div>
        </div>

        <div class="group-pair">
          <div class="label-text">{{ $t("order.order_history.pair") }}</div>
          <div class="content-search">
            <div class="toolbar-element">
              <div class="coin-input" v-click-outside="clickOut">
                <input
                  type="text"
                  :placeholder="$t('order.order_history.coin')"
                  v-model="filters.coin"
                  @click="showCoinOption()"
                  @keyup="onSearchCoin()"
                  @keyup.enter="search"
                />
                <div class="box-coin-option" v-if="isShowCoinOption">
                  <div class="coin-option">
                    <span
                      class="option"
                      v-for="coin in coins"
                      @click="onSelectCoin(coin)"
                      >{{ coin | uppercase }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <span class="bw-element">/</span>
            <div class="toolbar-element2">
              <select-box
                v-model="filters.currency"
                :placeholder="$t('order.order_history.all')"
                :options="getMarkets()"
              />
            </div>
          </div>
        </div>

        <div class="group-type">
          <div class="label-text">{{ $t("order.order_history.side") }}</div>
          <div class="content-search">
            <select-box
              v-model="filters.tradeType"
              :placeholder="$t('order.order_history.all')"
              :options="commonConst.SELECT_SIDE"
            />
            <button class="button-search" @click="actionSearch">Search</button>
            <button class="button-reset" @click="actionReset">Reset</button>
          </div>
        </div>
      </div>

      <div class="export-file">
        <div class="excel_export f-fr mart3">
          <a
            @click.prevent="downloadReport()"
            @mouseenter="mouseOver()"
            @mouseleave="mouseOver()"
          >
            <span class="icon-export" />&nbsp;
            {{ $t("order.order_history.export_complete_trade_history") }}
          </a>
        </div>
        <!-- <div v-show="isShowToolTip" class="export-file-tooltip" @touchstart="isShowToolTip = false">
          {{ $t('order.order_history.export_complete_trade_history_tooltip') }}
        </div> -->
      </div>
    </div>
    <data-table
      ref="datatable"
      :getData="getData"
      :msg-empty-data="$t('order.trade_history.no_data')"
      class=""
      id="order-table"
    >
      <th
        v-for="item in headerTable"
        :title="$t(item.name)"
        :data-sort-field="item.id"
        @click="FixSortIE = !FixSortIE"
      >
        {{ $t(item.name) }}
        <span v-if="FixSortIE === true" class="iconFixSorIE icon-arrow5"></span>
        <span
          v-if="FixSortIE === false"
          class="iconFixSorIE icon-arrow6"
        ></span>
      </th>
      <template slot="body" slot-scope="props">
        <tr>
          <td class="cl_date" :title="props.item.created_at | timestampToDate">
            {{ props.item.created_at | timestampToDate }}
          </td>
          <td
            :title="`${props.item.coin} / ${props.item.currency}` | uppercase"
          >
            {{ props.item.coin | uppercase }}/{{
              props.item.currency | uppercase
            }}
          </td>
          <td
            :class="{
              blue: props.item.trade_type === 'buy',
              red: props.item.trade_type === 'sell',
            }"
            :title="props.item.trade_type | tradeType"
          >
            {{ props.item.trade_type | tradeType }}
          </td>
          <td
            :title="
              props.item.price | formatCurrencyAmount(props.item.currency, '0')
            "
          >
            <span
              v-if="
                props.item.type == 'market' || props.item.type == 'stop_market'
              "
              >{{ $t("order.trade_history.market") }}</span
            >
            <span v-else>{{
              props.item.price | formatCurrencyAmount(props.item.currency, "0")
            }}</span>
          </td>
          <td :title="props.item.quantity | toNumber">
            {{ props.item.quantity | toNumber }}
          </td>
          <td
            width="190px"
            class="t-left"
            v-if="props.item.trade_type === 'buy'"
            :title="
              props.item.fee | toNumber | formatCurrencyAmount(props.item.coin)
            "
          >
            {{
              props.item.fee | toNumber | formatCurrencyAmount(props.item.coin)
            }}
          </td>
          <td
            width="190px"
            class="t-left"
            v-if="props.item.trade_type !== 'buy'"
            :title="props.item.fee | formatCurrencyAmount(props.item.currency)"
          >
            {{ props.item.fee | formatCurrencyAmount(props.item.currency) }}
          </td>
          <td
            :title="props.item.amount | formatCurrencyAmount(props.item.coin)"
          >
            {{ props.item.amount | formatCurrencyAmount(props.item.coin) }}
          </td>
        </tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import DateTab from "../common/DateTab.vue";
import OPEN_ORDER_HEADER_CONST from "./DataTableInit.js";
import COMMON_CONST from "@/common/Const";
import rf from "@/request/RequestFactory";
import moment from "moment";
import MasterdataUtils from "@/common/MasterdataUtils";
import Utils from "@/common/Utils";
import SelectBox from "@/components/shared_components/common/SelectBox";

export default {
  components: {
    DateTab,
    Datepicker,
    SelectBox,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
    coin: {
      type: String,
      default: COMMON_CONST.DEFAULT_COIN,
    },
    currency: {
      type: String,
      default: COMMON_CONST.DEFAULT_CURRENCY,
    },
  },
  data() {
    return {
      headerTable: OPEN_ORDER_HEADER_CONST.TRADE_HISTORY_HEADER,
      commonConst: COMMON_CONST,
      dateFromDefault: moment().subtract(6, "days").toDate(),
      dateToDefault: moment().toDate(),
      dateFrom: moment().subtract(6, "days").startOf("day").format("x"),
      dateTo: moment().endOf("day").format("x"),
      coins: [],
      coinMasters: [],
      isShowCoinOption: false,
      filters: {
        currency: "",
        tradeType: "",
      },
      durationDateFrom: null,
      isHide: false,
      isShowToolTip: false,
      FixSortIE: null,
    };
  },
  methods: {
    changeDate(currentDateTab) {
      switch (currentDateTab.id) {
        case this.commonConst.ONE_DAY:
          this.durationDateFrom = moment().startOf("day").format("x");
          break;
        case this.commonConst.ONE_WEEK:
          this.durationDateFrom = moment()
            .subtract(6, "days")
            .startOf("day")
            .format("x");
          break;
        case this.commonConst.ONE_MONTH:
          this.durationDateFrom = moment()
            .subtract(1, "months")
            .add("days", 1)
            .startOf("day")
            .format("x");
          break;
        default:
          this.durationDateFrom = moment()
            .subtract(3, "months")
            .add("days", 1)
            .startOf("day")
            .format("x");
      }
      const params = this.getDefaultDateRange();
      this.$refs.datatable.filter(params);
    },
    getData(params) {
      if (!isAuthenticated) {
        return new Promise((resolve) => {
          return resolve({});
        });
      }
      const dateRange = this.getDefaultDateRange();
      params = Object.assign({}, params, dateRange);
      return rf.getRequest("OrderRequest").getTradeHistory(params);
    },

    getDefaultDateRange() {
      return {
        start_date: this.dateFrom,
        end_date: moment().endOf("day").format("x"),
      };
    },

    actionSearch() {
      if (this.dateFrom > this.dateTo) {
        ConfirmationModal.show({
          type: "warning",
          title: "",
          content: this.$i18n.t("common.date.warning"),
          btnCancelLabel: "",
          btnConfirmLabel: "",
        });
      }
      this.$refs.datatable.filter(this.filterParams());
      if (!this.options) {
        this.$refs.selectDateTab.resetSelectDate();
      }
    },
    filterParams() {
      const filterParams = {
        start_date: this.dateFrom,
        end_date: this.dateTo,
      };
      if (this.filters.coin) {
        filterParams.coin = this.filters.coin;
      }
      if (this.filters.currency) {
        filterParams.currency = this.filters.currency;
      }
      if (this.filters.tradeType) {
        filterParams.trade_type = this.filters.tradeType;
      }
      return filterParams;
    },
    actionReset() {
      this.filters.coin = "";
      this.filters.currency = "";
      this.filters.tradeType = "";
      this.dateFromDefault = moment().subtract(6, "days").toDate();
      this.dateToDefault = moment().toDate();
      this.dateFrom = moment().subtract(6, "days").startOf("day").format("x");
      this.dateTo = moment().endOf("day").format("x");
      this, $refs.datatable.refresh();
    },
    selectDateFrom(dateFrom) {
      this.dateFrom = moment(dateFrom).valueOf();
      this.dateFrom = moment(dateFrom).startOf("day").format("x");
    },
    selectDateTo(dateTo) {
      this.dateTo = moment(dateTo).endOf("day").format("x");
    },
    customFormatter(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    requestCoinList() {
      MasterdataUtils.getCoins((coins) => {
        this.coinMasters = coins;
      });
    },
    onSelectCoin(coin) {
      this.isShowCoinOption = false;
      this.filters.coin = coin.toUpperCase();
    },
    clickOut() {
      this.isShowCoinOption = false;
    },
    showCoinOption() {
      this.isShowCoinOption = true;
      this.coins = this.coinMasters;
    },
    async downloadReport() {
      const params = {
        start_date: this.dateFrom,
        end_date: this.dateTo,
        timezone_offset: Utils.getTimzoneOffset(),
        lang: this.$i18n.locale,
      };
      rf.getRequest("OrderRequest").downloadTradeHistory(
        params,
        "TradeHistory_" + moment().format("YYYYMMDDHHmmss")
      );
    },
    mouseOver() {
      this.isShowToolTip = !this.isShowToolTip;
    },

    getMarkets() {
      return window._.map(COMMON_CONST.MARKETS, (item) => {
        return { id: item, name: item.toUpperCase() };
      });
    },
  },
  mounted() {
    this.requestCoinList();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/common.scss";

#trade-history {
  .group-control {
    display: flex !important;
    justify-content: space-between;
    margin-bottom: 11px;
    gap: 15px;
    flex-wrap: wrap;

    .group_1 {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      max-width: 100%;

      .group-date-picker {
        display: flex;
        gap: 15px;

        .label-text {
          @include text-font($helvetica, $dark-1, 14px, 700, 18px);
          margin-bottom: 7px;
        }

        .date-picker-input {
          border-radius: 10px;
        }
      }

      .group-type {
        .label-text {
          @include text-font($helvetica, $dark-1, 14px, 700, 18px);
          margin-bottom: 7px;
        }

        .content-search {
          display: flex;
          gap: 15px;

          .button_drop_search {
            padding: 7px 14px;
            background: $color-white;
            border: 1px solid $grey-1;
            border-radius: 10px;
            height: 32px;
          }

          .button-search {
            padding: 7px 20px;
            background: $primary-2;
            border-radius: 30px;

            @include text-font($helvetica, $color-white, 14px, 800, 18px);

            &:hover {
              background: $primary-1;
            }
          }

          .button-reset {
            padding: 7px 20px;
            border: 1px solid $primary-2;
            border-radius: 30px;

            @include text-font($helvetica, $primary-2, 14px, 800, 18px);

            &:hover {
              background: $primary-1;
              color: $color-white;
            }
          }
        }
      }

      .group-pair {
        flex: 1;

        .label-text {
          @include text-font($helvetica, $dark-1, 14px, 700, 18px);
          margin-bottom: 7px;
        }

        .content-search {
          display: flex;
          align-items: center;
          gap: 5px;

          .toolbar-element {
            .coin-input {
              position: relative;

              input {
                border: 1px solid $grey-1;
                border-radius: 10px;
                height: 32px;
                padding: 0 10px;
              }

              .box-coin-option {
                position: absolute;
                top: calc(100% + 2px);
                width: 100%;
                left: 0;
                z-index: 2;

                .coin-option {
                  display: flex;
                  flex-direction: column;
                  border-radius: 3px;
                  list-style-type: none;
                  padding: 10px 0px;
                  box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
                  background: $color-white;

                  border-radius: 10px;

                  .option {
                    padding: 7px 14px;
                    cursor: pointer;
                    @include text-font($helvetica, $dark-1, 14px, 500, 20px);

                    &:hover {
                      background: $grey-3;
                    }
                  }
                }
              }
            }
          }

          .toolbar-element2 {
            .group_search_select {
              .button_drop_search {
                padding: 7px 14px;
                background: $color-white;
                border: 1px solid $grey-1;
                border-radius: 10px;
                height: 32px;
              }
            }
          }
        }
      }
    }

    .export-file {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .excel_export {
        position: relative;
        @include text-font($helvetica, $primary-2, 14px, 700, 18px);
        margin-top: auto;

        .export-file-tooltip {
          position: absolute;
          padding: 6px 8px;
          bottom: calc(100% + 10px);
          right: 0;
          background: rgba(0, 0, 0, 0.75);
          border-radius: 2px;
          @include text-font($helvetica, $color-white, 14px, 400, 25px);

          &:before {
            content: "";
            position: absolute;
            bottom: -8px;
            right: 15%;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 8px solid rgba(0, 0, 0, 0.75);
          }
        }
      }
    }
  }

  #order-table {
    padding: 10px 20px;
    border-radius: 10px;

    table {
      border: 0px;

      thead {
        background-color: $color-white;
        border: 0px;

        tr {
          height: 48px;

          th {
            border-bottom: 1px solid #c7cbd3;
            @include text-font($helvetica, $dark-3, 14px, 500, 25px);
            padding: 8px 0px 8px 20px;

            &:last-child {
              padding: 8px 20px 8px 0px;
            }
          }
        }
      }

      tbody {
        text-align: left;

        tr {
          &.canceled {
            opacity: 0.3;
          }

          height: auto;
          font-size: $font-small;

          td {
            font-size: $font-root;
            font-weight: 500;
            color: $color-grey-dark;
            font-family: $font-family-roboto-medium;
            line-height: 21px;
            padding: 7px 0px 6px 20px;
            overflow: initial;
            vertical-align: middle;

            &:last-child {
              text-align: left;
              padding: 7px 20px 6px 0px;
            }
          }
        }
      }
    }
  }
}

#date-tab {
  float: left;
}

@include mobile() {
  #trade-history #order-table {
    overflow: auto;

    table thead tr th {
      min-width: 120px;
    }
  }
}
</style>

<style lang="scss">
@import "@/assets/sass/common.scss";

#trade-history {
  .is_single_page {
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          background: transparent;
          padding: 7px 10px 5px 10px;
          width: 100%;
          height: 32px;
          border: 1px solid $color-alto;
          background-color: $color-white-smoke;
          border-radius: 3px;
        }

        .box_list_search_select {
          ul {
            width: 80px;

            li {
              &:hover {
                background-color: $grey-3;

                span,
                a {
                  color: $dark-1;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
