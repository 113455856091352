<template>
  <div>
    <div class="VuePagination" v-show="totalPages > 1">
      <ul class="pagination VuePagination__pagination">
        <!--<li class="VuePagination__pagination-item page-item VuePagination__pagination-item-prev-chunk "-->
        <!--:class="{disabled : !allowedChunk(-1)}">-->
        <!--<a class="page-link" href="javascript:void(0);"-->
        <!--@click="setChunk(-1)">&lt;&lt;</a>-->
        <!--</li>-->

        <li
          class="VuePagination__pagination-item page-item VuePagination__pagination-item-prev-page page-prev"
          :class="{ disabled: !allowedPage(page - 1) }"
        >
          <a class="page-link" href="javascript:void(0);" @click="prev()">
            <i class="icon-arrow4"></i>
            <!-- <img class="arrow-left" src="/images/arrow-pagination.svg" alt="arrow"> -->
          </a>
        </li>

        <template v-for="item in pages">
          <li
            :key="item"
            class="VuePagination__pagination-item page-item"
            :class="{ active: parseInt(page) === parseInt(item) }"
          >
            <a class="page-link" role="button" @click="setPage(item)">{{
              item
            }}</a>
          </li>
        </template>

        <li
          class="VuePagination__pagination-item page-item VuePagination__pagination-item-next-page page-next"
          :class="{ disabled: !allowedPage(page + 1) }"
        >
          <a class="page-link final" href="javascript:void(0);" @click="next()">
            <i class="icon-arrow3"></i>
            <!-- <img class="arrow-right" src="/images/arrow-pagination.svg" alt="arrow"> -->
          </a>
        </li>

        <!--<li class="VuePagination__pagination-item page-item VuePagination__pagination-item-next-chunk "-->
        <!--:class="{disabled : !allowedChunk(1)}">-->
        <!--<a class="page-link" href="javascript:void(0);"-->
        <!--@click="setChunk(1)">&gt;&gt;</a>-->
        <!--</li>-->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    pageParent: {
      type: Number,
      default: 1,
    },
    records: {
      type: Number,
      required: true,
    },
    chunk: {
      type: Number,
      required: false,
      default: 6,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      page: this.pageParent,
    };
  },
  watch: {
    records() {
      if (this.page > this.totalPages) {
        this.page = 1;
      }
    },
    pageParent() {
      this.page = this.pageParent;
    },
  },
  computed: {
    pages: function () {
      if (!this.records) return [];

      return range(this.paginationStart, this.chunk, this.totalPages);
    },
    totalPages: function () {
      return this.records ? Math.ceil(this.records / this.perPage) : 1;
    },
    totalChunks: function () {
      return Math.ceil(this.totalPages / this.chunk);
    },
    currentChunk: function () {
      return Math.ceil(this.page / this.chunk);
    },
    paginationStart: function () {
      return (this.currentChunk - 1) * this.chunk + 1;
    },
    pagesInCurrentChunk: function () {
      return this.paginationStart + this.chunk <= this.totalPages
        ? this.chunk
        : this.totalPages - this.paginationStart + 1;
    },
  },
  methods: {
    setPage: function (page) {
      if (this.allowedPage(page)) {
        this.paginate(page);
      }
    },
    paginate(page) {
      this.page = page;
      this.$emit("Pagination:page", page);
    },
    next: function () {
      return this.setPage(this.page + 1);
    },
    prev: function () {
      return this.setPage(this.page - 1);
    },
    setChunk: function (direction) {
      this.setPage((this.currentChunk - 1 + direction) * this.chunk + 1);
    },
    allowedPage: function (page) {
      return page >= 1 && page <= this.totalPages;
    },
    allowedChunk: function (direction) {
      return (
        (parseInt(direction) === 1 && this.currentChunk < this.totalChunks) ||
        (parseInt(direction) === -1 && this.currentChunk > 1)
      );
    },
    allowedPageClass: function (direction) {
      return this.allowedPage(direction) ? "" : "disabled";
    },
    allowedChunkClass: function (direction) {
      return this.allowedChunk(direction) ? "" : "disabled";
    },
    activeClass: function (page) {
      return parseInt(this.page) === parseInt(page) ? "active" : "";
    },
  },
};

function range(start, chunk, total) {
  if (start + chunk > total) {
    start = Math.max(total - chunk + 1, 1);
  }
  var end = chunk > total ? total : chunk;
  return Array.apply(0, Array(end)).map(function (element, index) {
    return index + start;
  });
}
</script>

<style lang="scss" scoped>
.VuePagination {
  background-color: transparent;
  .VuePagination__pagination {
    margin-right: 20px;
  }
}
.pagination > .disabled > a {
  color: rgba(2, 0, 13, 0.38) !important;
}
.page-item {
  &:first-child a:link,
  &:last-child a:link {
    border-top-left-radius: unset;
    border-bottom-right-radius: unset;
    border-radius: 50%;
    background: $bg-main3;
    transform: none;
    &.page-prev {
      a {
        font-size: 14px;
        font-weight: normal;
        color: $dark-1;
        &:hover {
          color: $text-white;
          background-color: $bg-main1;
        }
      }
      &.disabled {
        a {
          color: $grey-1;
        }
      }
    }
    &.page-next {
      a {
        font-size: 14px;
        color: $dark-1;
        &:hover {
          color: $text-white;
          background-color: $bg-main1;
        }
      }
      &.disabled {
        a {
          color: $grey-1;
        }
      }
    }
  }
  a {
    position: relative;
    padding: 10px;
    font-weight: bold;
    text-decoration: none;
    color: $bg-main1;
    background-color: $bg-main3;
    margin-right: 8px;
    font-size: 14px;
    line-height: 24px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: $font-mini;
    }

    .arrow-right {
      transform: rotate(180deg);
    }

    &:hover {
      color: $text-white;
      background-color: $bg-main1;
    }
    &:link {
      color: $dark-1;
      background: transparent;
    }
  }
  &.active {
    a {
      color: $text-white;
      background-color: $bg-main1;
      border: none;
      &:hover {
        color: $text-white;
        background-color: $bg-main1;
      }
    }
  }

  .final {
    margin-right: 0;
  }
}
@include tablet;
@include mobile {
  .VuePagination {
    display: flex;
    justify-content: center;
    .pagination {
      margin-right: unset;
    }
  }
}
</style>
