<template>
  <div id="list-address">
    <div class="bg_right_address">
      <div class="group_head_right_add">
        <div
          class="dropdown-block"
          v-click-outside="onClickOutsite"
          @click="toggleSelectOptionsMenu = !toggleSelectOptionsMenu"
        >
          <div class="checked-group">
            <div class="checkbox-input">
              <input
                type="checkbox"
                :checked="
                  lstChecked.split('--').length === originAddresses.length &&
                  lstChecked !== ''
                "
                id="checkboxAllWhitelist"
                @change="onChangeCheckboxAll($event.target.checked)"
                @click="chkAllClick($event.target.checked)"
              />
              <label for="checkboxAllWhitelist"></label>
            </div>
          </div>
          <img src="/images/arrow-down.svg" alt="arrow-down" />
          <ul class="menu" v-if="toggleSelectOptionsMenu">
            <li v-for="option in selectOptions" :key="option.name">
              <a @click.stop="onSelectedOption(option.value)"
                ><span class="fleft wid100">{{ $t(option.name) }}</span></a
              >
            </li>
          </ul>
        </div>

        <button
          class="button_left1"
          :disabled="!isAddWhitelisted"
          @click="onAddAddressesWhitelist()"
        >
          {{ $t('address.add_whitelist') }}
        </button>

        <button
          class="button_left2"
          :disabled="!isRemoveWhitelisted"
          @click="onRemoveAddressesWhitelist()"
        >
          {{ $t('address.remove_whitelist') }}
        </button>

        <button
          class="button_left3"
          :disabled="!isAddWhitelisted && !isRemoveWhitelisted"
          @click="onDeleteAddresses()"
        >
          {{ $t('address.delete') }}
        </button>

        <div class="input_text_icon input-group">
          <input
            :maxlength="maxLengthInput"
            type="text"
            class="search_input form-input"
            :placeholder="$t('common.search')"
            v-model="searchKey"
          />
          <!-- <span class="glyphicon icon-Search icon-search"></span> -->
          <img src="/images/search.svg" class="search-icon" alt="search" />
        </div>

        <span
          class="favourite-box fright"
          :class="{ favor_active: showWhitelisted }"
          @click="onlyShowAddressesWhitelist()"
          v-tooltip.bottom="{ content: `${$t('address.only_dis')}` }"
        >
          <img
            :src="`/images/star${showWhitelisted ? '' : '-uncheck'}.svg`"
            alt="star"
          />
        </span>
      </div>
      <template v-if="isMobile">
        <data-list-mobile
          ref="datatable"
          :msg-empty-data="''"
          :get-data="getData"
          :isExpand="true"
          :limit="10"
          :height="30"
        >
          <template slot="table-empty-header">
            <thead class="no-data">
              <tr>
                <th class="th-strong"></th>
                <th class="th-strong">{{ $t('address.coin') }}</th>
                <th class="th-strong">{{ $t('address.label') }}</th>
                <th class="th-strong"></th>
              </tr>
            </thead>
          </template>
          <div class="mobile-header-item" slot="header">
            <span class="mobile-title__item"></span>
            <span class="mobile-title__item">{{ $t('address.coin') }}</span>
            <span class="mobile-title__item">{{ $t('address.label') }}</span>
            <span class="mobile-title__item"></span>
          </div>
          <template slot="body" slot-scope="props">
            <div class="transaction-history__mobile-item">
              <div class="mobile-item__wrapper">
                <div class="item-wrapper__title">
                  <span class="checked-group">
                    <span class="checkbox-input">
                      <input
                        type="checkbox"
                        :checked="
                          lstChecked.indexOf('-' + props.item.id + '-') >= 0
                        "
                        @change="
                          onChangeCheckbox(props.item, $event.target.checked)
                        "
                        @click="
                          checkItemClick(props.item, $event.target.checked)
                        "
                        :id="`checkboxItemWhiteList${props.index}`"
                      />
                      <label
                        :for="`checkboxItemWhiteList${props.index}`"
                      ></label>
                    </span>
                  </span>
                  <img
                    :src="`/images/star${
                      props.item.is_whitelist ? '' : '-uncheck'
                    }.svg`"
                    @click="onAddAddressWhitelist(props.item)"
                  />
                </div>
                <div class="item-wrapper__title">
                  {{ props.item.coin | uppercase }}
                </div>
                <div class="item-wrapper__title">
                  {{ props.item.wallet_name }}
                </div>
                <div
                  class="item-wrapper__dropdown-icon"
                  @click="props.onClickExpandItem(props)"
                >
                  <img
                    src="/images/instant-convert/icon-dropdown.svg"
                    alt=""
                    v-if="!props.isActive"
                  />
                  <img
                    src="/images/instant-convert/icon-dropdown2.svg"
                    alt=""
                    v-else
                  />
                </div>
              </div>
              <div
                class="mobile-item__expand-content"
                :class="{ active: props.isActive }"
              >
                <div class="expand-content">
                  <div class="expand-content__wrapper">
                    <div class="item-expand">
                      <div class="item-expand-left">
                        {{ $t('address.address') }}
                      </div>
                      <div class="item-expand-right">
                        {{ props.item.wallet_address }}
                      </div>
                    </div>
                    <div class="item-expand">
                      <div class="item-expand-left">
                        {{ $t('address.memo') }}
                      </div>
                      <div class="item-expand-right">
                        {{ props.item.wallet_sub_address || '--' }}
                      </div>
                    </div>
                  </div>
                  <button
                    class="btn-remove"
                    @click="onRemoveAddress(props.item.id)"
                  >
                    {{ $t('account.remove') }}
                  </button>
                </div>
              </div>
            </div>
          </template>
        </data-list-mobile>
      </template>
      <div class="toolbarTable" v-else>
        <div class="datatable">
          <data-table
            :getData="getData"
            @DataTable:finish="onDatatableFinish"
            :widthTable="'100%'"
            :msgEmptyData="$t('address.table.empty_msg')"
            :classHeader="'header-css'"
            class="table_min"
            ref="datatable"
          >
            <th class="col-checkbox"></th>
            <th class="col-coin">{{ $t('address.coin') }}</th>
            <th class="col-label">{{ $t('address.label') }}</th>
            <th class="col-address">{{ $t('address.address') }}</th>
            <th class="col-memo">{{ $t('address.memo') }}</th>
            <th class="col-del"></th>
            <th class="col-expand"></th>
            <template slot="body" slot-scope="props">
              <tr :class="{ active: rowExpand === props.index }">
                <td
                  class="col-checkbox"
                  :class="{ star_active: props.item.is_whitelist }"
                  style="padding-left: 12px"
                >
                  <span class="checked-group">
                    <span class="checkbox-input">
                      <input
                        type="checkbox"
                        :checked="
                          lstChecked.indexOf('-' + props.item.id + '-') >= 0
                        "
                        @change="
                          onChangeCheckbox(props.item, $event.target.checked)
                        "
                        @click="
                          checkItemClick(props.item, $event.target.checked)
                        "
                        :id="`checkboxItemWhiteList${props.index}`"
                      />
                      <label
                        :for="`checkboxItemWhiteList${props.index}`"
                      ></label>
                    </span>
                  </span>
                  <img
                    :src="`/images/star${
                      props.item.is_whitelist ? '' : '-uncheck'
                    }.svg`"
                    @click="onAddAddressWhitelist(props.item)"
                  />
                </td>
                <td class="col-coin">
                  {{ props.item.coin | uppercase }}
                </td>
                <td class="col-label">
                  <span>{{ props.item.wallet_name }}</span>
                </td>
                <td class="col-address">
                  <div>{{ props.item.wallet_address }}</div>
                </td>

                <td class="col-memo">
                  <span v-if="props.item.wallet_sub_address">{{
                    props.item.wallet_sub_address
                  }}</span>
                  <span v-else>--</span>
                </td>

                <td
                  style="
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    gap: 8px;
                    margin-top: 8px;
                  "
                  class=""
                  :class="{ rotate: rowExpand === props.index }"
                >
                  <img
                    style="cursor: pointer"
                    src="/images/close.svg"
                    @click="onRemoveAddress(props.item.id)"
                    alt="icon"
                  />
                  <img
                    style="cursor: pointer"
                    @click="showRowExpand(props.index)"
                    src="/images/spot_exchange/icon-dropdown.svg"
                    alt="icon"
                  />
                </td>
              </tr>
              <template v-if="rowExpand === props.index">
                <tr>
                  <td colspan="2" class="text-expand-left">
                    {{ $t('address.address') }}
                  </td>
                  <td colspan="3" class="text-right wallet_address">
                    {{ props.item.wallet_address }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="text-expand-left">
                    {{ $t('address.memo') }}
                  </td>
                  <td colspan="3" class="text-right">
                    {{ props.item.wallet_sub_address || '--' }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2" class="text-expand-left">
                    <button
                      style="margin-left: 20px"
                      class="btn-remove"
                      @click="onRemoveAddress(props.item.id)"
                    >
                      {{ $t('account.remove') }}
                    </button>
                  </td>
                </tr>
              </template>
            </template>
          </data-table>
        </div>
      </div>
    </div>
    <otp-modal
      :name="whitelistModalName"
      :submit="updateWalletsWhiteList"
      :params="getParamsAddAddressWhitelist()"
      :is-disable-button="isDisableButton"
      :contact_email="siteSetting.contact_email"
    />
  </div>
</template>

<script>
import rf from '@/request/RequestFactory'
import MasterdataUtils from '@/common/MasterdataUtils'
import OtpModal from '@/components/shared_components/funds/common/OtpModal'
import COMMON_CONST from '@/common/Const'

const ALL = 1
const NONE = 2
const NOT_WHITELISTED = 3
const WHITELISTED = 4

export default {
  components: {
    OtpModal,
  },
  props: {
    enableOtp: { type: Boolean, default: false },
    enableWhiteList: { type: Boolean, default: false },
  },
  computed: {
    isMobile() {
      if (!window.innerWidth) return
      return window.innerWidth <= 768
    },
  },
  data() {
    return {
      selectOptions: [],
      selectedOption: ALL,
      toggleSelectOptionsMenu: false,

      selectAllCheckbox: false,

      isAddWhitelisted: false,
      selectedAddAddressIds: [],

      isRemoveWhitelisted: false,
      selectedRemoveAddressIds: [],

      searchKey: '',
      originAddresses: [], // Origin data source of datatable
      params: {},
      showWhitelisted: false,

      whitelistModalName: 'otp_modal_add_whitelist',
      maxLengthInput: COMMON_CONST.MAX_LENGTH_INPUT,
      isDisableButton: false,
      lstChecked: '',
      siteSetting: {},
      rowExpand: '',
    }
  },
  watch: {
    searchKey() {
      this.$refs.datatable.rows = this.search()
    },
  },
  methods: {
    showRowExpand(num) {
      this.rowExpand = this.rowExpand === num ? '' : num
    },
    onClickOutsite() {
      this.toggleSelectOptionsMenu = false
    },
    search() {
      const keyword = window._.toLower(this.searchKey)

      const result = []
      let index = -1
      window._.forEach(this.getAddresses(), (item) => {
        if (item.coin.includes(keyword)) {
          result[++index] = item
        }
      })
      return result
    },

    getData(params) {
      return rf.getRequest('UserRequest').getWithdrawalsAddresses(params)
    },

    onDatatableFinish() {
      // Reset data when reload datatable again.
      this.originAddresses = []
      this.getAddresses()
      if (window._.isEmpty(this.originAddresses)) {
        this.isAddWhitelisted = false
        this.isRemoveWhitelisted = false
        this.selectAllCheckbox = false
      }
    },

    refresh() {
      this.$refs.datatable.refresh()
    },

    initSelectOptions() {
      this.selectOptions.push({ value: ALL, name: 'address.all' })
      this.selectOptions.push({ value: NONE, name: 'address.none' })
      this.selectOptions.push({
        value: NOT_WHITELISTED,
        name: 'address.not_whitelisted',
      })
      this.selectOptions.push({
        value: WHITELISTED,
        name: 'address.whitelisted',
      })

      this.selectedOption = NONE // Default is selected all of items.
    },

    onSelectedOption(value) {
      this.selectedOption = value
      this.toggleSelectOptionsMenu = false

      switch (this.selectedOption) {
        case ALL:
          this.chkAllClick(true)
          this.onChangeCheckboxAll(true)
          break
        case NONE:
          this.chkAllClick(false)
          this.onChangeCheckboxAll(false)
          break
        case NOT_WHITELISTED:
          this.updateCheckboxesWhitelisted(false)
          break
        case WHITELISTED:
          this.updateCheckboxesWhitelisted(true)
          break
        default:
          console.error('Selection incorrect!')
          break
      }
    },

    onChangeCheckboxAll(isChecked) {
      this.selectAllCheckbox = isChecked
      this.selectedOption = isChecked ? ALL : NONE
      window._.forEach(this.getAddresses(), (item) => {
        this.updateStateCheckboxes(item, isChecked)
      })
      this.updateStateButtons()
    },

    onChangeCheckbox(item, isChecked) {
      this.updateStateCheckboxes(item, isChecked)
      this.updateStateButtons()
      //this.selectAllCheckbox = window._.filter(this.getAddresses(), item => item.isChecked).length === this.getAddresses().length;
    },

    updateStateCheckboxes(item, isChecked) {
      item.isChecked = isChecked
      item.isAddWhitelisted = item.isChecked && !item.is_whitelist
      item.isRemoveWhitelisted = item.isChecked && item.is_whitelist
    },

    updateStateButtons() {
      this.isAddWhitelisted =
        window._.filter(this.getAddresses(), (item) => item.isAddWhitelisted)
          .length > 0
      this.isRemoveWhitelisted =
        window._.filter(this.getAddresses(), (item) => item.isRemoveWhitelisted)
          .length > 0
    },

    updateCheckboxesWhitelisted(isWhitelisted) {
      window._.forEach(this.getAddresses(), (item) => {
        this.checkItemClick(item, item.is_whitelist == isWhitelisted)
        this.onChangeCheckbox(item, item.is_whitelist == isWhitelisted)
      })
    },

    onlyShowAddressesWhitelist(showWhitelistWithoutTrigger) {
      if (!showWhitelistWithoutTrigger) {
        this.showWhitelisted = !this.showWhitelisted
      }

      let data = this.getAddresses()
      if (this.showWhitelisted) {
        data = window._.filter(
          this.$refs.datatable.rows,
          (item) => item.is_whitelist
        )
      } else {
        if (!window._.isEmpty(this.searchKey)) {
          data = this.search()
        }
      }
      this.$refs.datatable.rows = data
    },

    getAddresses() {
      const rows = this.$refs.datatable.rows
      if (window._.isEmpty(this.originAddresses)) {
        this.originAddresses = rows
      }
      // In case if has a new address
      // if (rows.length > this.originAddresses.length) {
      //   this.originAddresses = rows;
      // }
      return this.originAddresses
    },

    getAddressById(data, id) {
      const result = window._.filter(data, (item) => item.id === id)
      return result ? result[0] : null
    },

    onRemoveAddressesWhitelist() {
      this.selectedRemoveAddressIds = window._.chain(this.getAddresses())
        .filter((item) => item.isRemoveWhitelisted)
        .map((item) => item.id)
        .value()

      switch (this.selectedOption) {
        case ALL:
        case NONE:
          this.chkAllClick(false)
          this.onChangeCheckboxAll(false)
          break
        case NOT_WHITELISTED:
        case WHITELISTED:
          this.updateCheckboxesWhitelisted(false)
          break
        default:
          console.error('Selection incorrect!')
          break
      }

      const params = {
        idWallets: this.selectedRemoveAddressIds,
        active: false,
      }
      this.updateWalletsWhiteList(params)
    },

    onAddAddressesWhitelist() {
      this.selectedAddAddressIds = window._.chain(this.getAddresses())
        .filter((item) => item.isAddWhitelisted)
        .map((item) => item.id)
        .value()

      if (!this.enableWhiteList) {
        Message.error(this.$i18n.t('address.turn_on_whitelist'))
        return
      }
      if (this.enableOtp) {
        CommonModal.show(this.whitelistModalName)
        return
      }

      switch (this.selectedOption) {
        case ALL:
        case NONE:
          this.chkAllClick(false)
          this.onChangeCheckboxAll(false)
          break
        case NOT_WHITELISTED:
        case WHITELISTED:
          this.updateCheckboxesWhitelisted(false)
          break
        default:
          console.error('Selection incorrect!')
          break
      }
      this.updateWalletsWhiteList(this.getParamsAddAddressWhitelist())
    },

    onAddAddressWhitelist(item) {
      if (!item.is_whitelist) {
        if (!this.enableWhiteList) {
          Message.error(this.$i18n.t('address.turn_on_whitelist'))
          return
        }
        if (this.enableOtp) {
          this.selectedAddAddressIds = [item.id]
          CommonModal.show(this.whitelistModalName)
          return
        }
      }
      this.addAddressWhitelist(item.id, !item.is_whitelist)
    },

    addAddressWhitelist(addressId, isWhitelisted) {
      const params = { idWallets: [addressId], active: isWhitelisted }
      this.updateWalletsWhiteList(params)
    },

    updateWalletsWhiteList(params) {
      this.isDisableButton = true
      rf.getRequest('AddressManagerRequest')
        .updateWalletsWhiteList(params)
        .then((res) => {
          this.onUpdateWalletWhiteList(params)
          this.isDisableButton = false
          this.chkAllClick(false)
          this.onChangeCheckboxAll(false)
        })
        .catch((error) => {
          this.isDisableButton = false
          if (!error.response) {
            Message.error(this.$i18n.t('common.message.network_error'))
          } else {
            this.onError(error.response.data.errors || [])
          }
        })
    },

    onUpdateWalletWhiteList(params) {
      CommonModal.hide(this.whitelistModalName)
      window._.forEach(this.getAddresses(), (item) => {
        if (params.idWallets.indexOf(item.id) > -1) {
          item.is_whitelist = params.active
        }
      })
      //this.onSelectedOption(this.selectedOption);
      if (this.showWhitelisted) {
        this.onlyShowAddressesWhitelist(true)
      }
      Message.success(this.$i18n.t('address.updated_success'))
    },

    getParamsAddAddressWhitelist() {
      return {
        idWallets: this.selectedAddAddressIds,
        active: true,
      }
    },

    onDeleteAddresses() {
      const addressIds = window._.chain(this.getAddresses())
        .filter((item) => item.isChecked)
        .map((item) => item.id)
        .value()
      this.onRemoveAddress(addressIds)
    },

    onRemoveAddress(ids) {
      const msgContent = this.$t('address.confirm_remove_address')
      if (Array.isArray(ids)) {
        this.showModal(msgContent, 'primary', this.removeAddress, ids)
      } else {
        this.showModal(msgContent, 'primary', this.removeAddress, ids)
      }
    },

    removeAddress(addressIds) {
      const params = { idWallets: addressIds }
      rf.getRequest('AddressManagerRequest')
        .removeWalletsAddress(params)
        .then((res) => {
          this.refresh()

          if (Array.isArray(addressIds)) {
            for (var i = 0; i < addressIds.length; i++) {
              if (this.lstChecked.indexOf('-' + addressIds[i] + '-') >= 0) {
                this.lstChecked = this.lstChecked.replace(
                  '-' + addressIds[i] + '-',
                  ''
                )
              }
            }
          } else {
            if (this.lstChecked.indexOf('-' + addressIds + '-') >= 0) {
              this.lstChecked = this.lstChecked.replace(
                '-' + addressIds + '-',
                ''
              )
            }
          }

          Message.success(this.$i18n.t('address.deleted_success'))
        })
        .catch((error) => {
          if (!error.response) {
            Message.error(this.$i18n.t('common.message.network_error'))
          } else {
            this.onError(error.response.data.errors || [])
          }
        })
    },

    showModal(msgContent, type, callback, params) {
      ConfirmationModal.show({
        type: type,
        title: '',
        content: this.$t('address.confirm_remove_address'),
        btnCancelLabel: this.$i18n.t('common.action.no'),
        btnConfirmLabel: this.$i18n.t('common.action.yes'),
        onConfirm: () => {
          callback(params)
        },
      })
    },

    onError(errors) {
      this.errors.clear()
      _.forEach(errors, (messages, field) => {
        _.forEach(messages, (message) => {
          this.errors.add(field, message)
        })
      })
    },

    init() {
      this.initSelectOptions()
      this.getMasterData()
    },
    getMasterData() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((data) => {
          const masterdata = data
          window._.each(masterdata.settings, (item) => {
            this.siteSetting[item.key] = item.value
          })
        })
    },
    checkItemClick(item, isChecked) {
      if (isChecked && this.lstChecked.indexOf('-' + item.id + '-') < 0) {
        this.lstChecked += '-' + item.id + '-'
      }

      if (!isChecked && this.lstChecked.indexOf('-' + item.id + '-') >= 0) {
        this.selectAllCheckbox = false
        this.lstChecked = this.lstChecked.replace('-' + item.id + '-', '')
      }
    },
    chkAllClick(isChecked) {
      window._.forEach(this.getAddresses(), (item) => {
        this.checkItemClick(item, isChecked)
      })
    },
  },
  mounted() {
    document.title = this.$t('menu.address') + ` – ${this.tabTitle}`
    this.init()

    this.$on('UPDATED_LOCALE', () => {})
  },
}
</script>

<style lang="scss" scoped>
.text-expand-left {
  color: red;
  text-align: left;
  padding-left: 20px;
}
#list-address {
  flex: 1;
  display: flex;
  flex-direction: column;

  .bg_right_address {
    display: flex;
    flex-direction: column;
    flex: 1;

    .group_head_right_add {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 10px;

      &:afrer {
        display: block;
        clear: both;
        content: '';
      }

      // > * {
      //   margin-right: 10px;

      //   &:last-child {
      //     margin-right: 0;
      //   }
      // }

      .dropdown-block {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;
        border: 1px solid $bg-main2;
        background: $text-main1;
        width: 120px;
        padding: 7px 10px;
        position: relative;

        @media only screen and (max-width: 488px) {
          width: calc(100% - 131px - 12px);
        }

        .checkbox-all {
          margin: 0;

          input {
            cursor: pointer;
          }
        }

        .checkbox_custom {
          cursor: pointer;

          span {
            width: 18px;
            height: 18px;
          }

          input {
            &:checked + span:after {
              width: 10px;
              height: 6px;
            }
          }
        }

        span.icon_drop_checkbox {
          cursor: pointer;
          display: inline-block;
          width: 9px;
          height: 9px;
          margin-left: 0px;
          vertical-align: 17px;
          content: '';
          float: right;
          position: absolute;
          top: 10px;
          right: 20%;
          font-size: $font-mini;
          color: $color-grey;
        }

        &:hover {
          span.icon_drop_checkbox {
            color: $color-green;
          }
        }

        .menu {
          background: $color-white;
          box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
          border-radius: 10px;
          position: absolute;
          top: 39px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -ms-flex-negative: 1;
          flex-shrink: 1;
          margin: 0;
          padding: 0;
          z-index: 999;
          min-width: 100%;
          left: 0;

          li {
            list-style: none;
            -ms-flex-preferred-size: 1;
            flex-basis: 1;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            float: none;
            width: auto;
            padding: 0;
            margin: 0;

            &:hover {
              background-color: $grey-3;
            }

            a {
              display: block;
              position: relative;
              display: inline-block;
              white-space: nowrap;
              -webkit-box-sizing: border-box;
              box-sizing: border-box;
              width: 100%;
              padding: 7px 10px;
              margin: 0;
              line-height: 34px;
              font-size: $font-root;
              color: $dark-1;
              font-weight: 400;
            }
          }
        }
      }

      .input_text_icon {
        flex: 1;
        display: flex;
        position: relative;
        align-items: center;
        min-width: 124px;
        max-width: 137px;
        margin-bottom: 0;

        input {
          padding: 7px 16px 7px (16px + 18px + 8px);
        }

        .search-icon {
          position: absolute;
          left: 16px;
          width: 18px;
          height: 18px;
        }

        span {
          position: absolute;
          left: 8px;
          top: 8px;
          color: $color-grey-dusty;
        }
      }

      .favor_active {
        .icon-star2 {
          &::before {
            color: $color-corn-pale !important;
          }
        }
      }

      .favourite-box {
        width: 38px;
        height: 34px;
        padding: 7px 10px;
        display: flex;
        justify-content: center;
        align-content: center;
        position: relative;
        background-color: white;
        border: 1px solid $grey-1;
        margin-bottom: 0;
        border-radius: 10px;
        cursor: pointer;

        input {
          display: none;
        }

        .icon-star2 {
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: -7px;
          margin-left: -7px;
        }

        input:checked + .icon-star2:after {
          color: $color-yellow;
        }

        b.tooltip_favourite {
          position: absolute;
          width: 157px;
          height: 63px;
          background: $background-default;
          box-shadow: 0px 0px 38px rgba(51, 51, 51, 0.4);
          right: -18px;
          top: 100%;
          padding: 12px 5px;
          text-align: center;
          line-height: 20px;
          font-size: $font-small;
          color: #333;
          font-weight: 500;
          margin-top: 6px;
          display: none;
          -webkit-transition: 0.5s;
          transition: 0.5s;
          z-index: 100;

          &:after {
            bottom: 100%;
            right: 25px;
            border: solid transparent;
            content: ' ';
            height: 0px;
            width: 0px;
            position: absolute;
            pointer-events: none;
            border-color: rgba(136, 183, 213, 0);
            border-bottom-color: $color-white;
            border-width: 6px;
            margin-right: 0px;
          }
        }

        &:hover {
          b.tooltip_favourite {
            display: block;
            -webkit-transition: 0.5s;
            transition: 0.5s;
          }
        }
      }

      .br-d {
        display: none;
      }
    }

    .toolbarTable {
      border-radius: 10px;
      margin-top: 20px;
      overflow-y: auto;
      background-color: $text-main1;
      flex: 1;

      .datatable {
        .tableContainer {
          table {
            thead {
              th {
                padding: 8px 0;
                color: $text-secondary;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;

                &.col-checkbox {
                  width: 10%;
                }

                &.col-address {
                  padding-left: 8px;
                }
              }
            }

            tbody {
              tr {
                border-bottom: 1px solid rgba(221, 221, 221, 1);

                td {
                  color: $text-main;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;

                  img {
                    width: 20px;
                    height: 20px;
                    vertical-align: middle;
                  }

                  div {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 500px;
                  }
                }

                .col-checkbox {
                  width: 10%;

                  .icon-star2,
                  input {
                    cursor: pointer;
                  }

                  .checked-group {
                    margin-right: 8px;
                  }

                  .favourite-box {
                    line-height: 20px;
                    margin: 0px;
                    margin-right: 8px;
                  }

                  .icon-star2 {
                    cursor: pointer;
                    color: $color-grey;
                    display: inline-block;
                    line-height: 20px;
                    position: relative;
                    top: -1px;
                  }
                }

                .star_active {
                  .icon-star2 {
                    color: $color-corn-pale;
                  }
                }

                .col-coin {
                  text-align: left;
                  width: 10%;
                }

                .col-label {
                  width: 15%;
                  text-align: left;
                  white-space: pre-wrap;
                  word-break: break-all;
                }

                .col-address {
                  width: 45%;
                  text-align: left;
                  white-space: pre-wrap;
                  word-break: break-all;
                  padding-left: 8px;
                }

                .col-memo {
                  width: 10%;
                  white-space: pre-wrap;
                  word-break: break-all;
                }

                .col-del {
                  text-align: center;
                  width: 10%;
                }

                .col-expand {
                  display: none;

                  @include mobile {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    width: auto;
                    justify-content: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @include mobile {
    .bg_right_address {
      .group_head_right_add {
        flex-wrap: wrap;
        gap: 11px;
        justify-content: space-between;

        .input_text_icon {
          margin: 0px;
          min-width: calc(100% - 38px - 12px);
        }
      }

      .toolbarTable {
        // overflow-x: auto;
        .datatable {
          .col-address,
          .col-memo,
          .col-del {
            display: none;
          }

          .wallet_address {
            text-overflow: ellipsis;
            max-width: 200px;
            overflow: hidden;
          }

          .col-expand {
            width: 5%;

            img {
              transform: rotate(0);
              transition: transform 0.2s linear;
            }

            &.rotate {
              img {
                transform: rotate(180deg);
              }
            }
          }

          :deep table tbody tr {
            &.row-expand1,
            &.row-expand2 {
              .text-left {
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px;
                padding: 0 0 0 16px;
              }

              .text-right {
                font-size: 14px;
                font-style: normal;
                font-weight: 300;
                line-height: 22px;
                padding: 0 16px 0 0;
              }
            }

            &.active {
              background: none;
              border-bottom: none;
              min-height: auto;
            }

            &.row-expand1 {
              border-bottom: none;

              .text-left {
                padding-top: 16px;
                background: $bg-main4;
                border-top-left-radius: 8px;
              }

              .text-right {
                padding-top: 16px;
                background: $bg-main4;
                border-top-right-radius: 8px;
              }
            }

            &.row-expand2 {
              border-bottom: none;

              .text-left {
                padding-bottom: 16px;
                background: $bg-main4;
                border-bottom-left-radius: 8px;
              }

              .text-right {
                padding-bottom: 16px;
                background: $bg-main4;
                border-bottom-right-radius: 8px;
              }
            }
          }
        }
      }
    }
  }

  @include tablet {
    .bg_right_address {
      .group_head_right_add {
        flex-wrap: wrap;
        gap: 11px;

        .input_text_icon {
          width: 50%;
        }
      }

      .toolbarTable {
        overflow-x: auto;

        .datatable {
          min-width: 1200px;
        }
      }
    }
  }
}

.icon-close {
  font-size: $font-small;
  color: $color-jungle-green;
  border: 0px;
  width: 100%;
  padding: 8px 0;
  text-align: end;

  &:hover {
    color: $color-eden;
  }
}

@media only screen and (max-width: 1499px) {
  #list-address
    .bg_right_address
    .toolbarTable
    .datatable
    .tableContainer
    table
    tbody
    tr
    td
    div {
    white-space: initial;
  }
}

:deep(.tableContainer) {
  min-height: 50vh;
  display: flex !important;
  justify-content: space-between;
  flex-direction: column;
}

.button_left3 {
  @include btn-common(contained, sm);
  background: $color-red;
  width: auto;
}

.button_left1,
.button_left2 {
  @include btn-common(no, sm, false);
  width: auto;
}

.btn-remove {
  @include btn-common(contained, sm, false);
  background: none;
  border: 1px solid $color-red;
  color: $color-red;
  padding: 8px 12px;
  width: auto;
}

.button_left2 {
  @media screen and (min-width: 350px) and (max-width: 486px) {
    width: 66% !important;
    display: flex;
    justify-content: center;
  }
}

.button_left3 {
  @media screen and (min-width: 350px) and (max-width: 486px) {
    width: 30% !important;
    display: flex;
    justify-content: center;
  }
}

.icon_close {
  cursor: pointer;
}

.data-list__wrapper {
  background: $text-main1;
  margin-top: 16px;
  border-radius: 10px;
}

.mobile-title__item {
  color: $text-secondary;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  padding: 8px 0;
}

.mobile-header-item {
  display: grid;
  grid-template-columns: 86px 70px 1fr 20px;
  background: $bg-main4;
  border-radius: 10px 10px 0px 0px;

  @include mobile-small {
    grid-template-columns: 76px 50px 1fr 20px;
  }
}

.transaction-history__mobile-item {
  & + & {
    border-top: 1px solid $bg-main2;
  }
}

.mobile-item__wrapper {
  display: grid;
  grid-template-columns: 70px 70px 1fr 20px;
  padding: 7px 16px 14px 16px;

  .item-wrapper__title {
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    color: $text-secondary;
  }

  @include mobile-small {
    grid-template-columns: 60px 50px 1fr 20px;

    .item-wrapper__title {
      overflow-wrap: break-word;
      max-width: 120px;
    }
  }
}

.mobile-item__expand-content {
  display: none;
  padding: 0 16px 16px;

  &.active {
    display: block;
  }
}

.expand-content__wrapper {
  background: $bg-main4;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.checked-group {
  margin-right: 8px;
}

.item-expand {
  display: flex;
  justify-content: space-between;

  .item-expand-left {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: $text-main;
  }

  .item-expand-right {
    color: $text-main;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    max-width: 185px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.th-strong {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}
</style>
