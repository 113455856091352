<template>
  <PopupModal :is-show="isShow">
    <div slot="body">
      <div class="withdrawal-modal">
        <div class="guest-page-form">
          <div class="modal-header">
            <button type="button" id="btn_close_modal" class="close" @click="handleCloseModal">
              <img src="/images/icon/icon-close.svg" alt="icon" />
            </button>
          </div>
          <div class="form-container">
            <div class="guest-page__form-header">
              <!-- {{
                isUnderRequiredAmount
                  ? $t('withdraw_crypto_modal.title_2', { amount: travelRuleCrypto.limit_amount })
                  : $t('withdraw_crypto_modal.title_1', { amount: travelRuleCrypto.limit_amount })
              }} -->
              {{
                $t('withdraw_crypto_modal.title_2', {
                  amount: formatMoney(travelRuleCrypto.limit_amount),
                })
              }}
            </div>
            <div class="guest-page__form-body">
              <div class="form-body__section">
                <div class="input-group__header">
                  {{ $t('withdraw_crypto_modal.form.originator_info') }}
                </div>
                <div class="input-group">
                  <p class="input-group__label">{{ $t('withdraw_crypto_modal.form.full_name') }}</p>
                  <input
                    autocomplete="off"
                    placeholder="--"
                    :class="{ 'input-error': errors.has('originator_name') }"
                    class="form-input"
                    type="text"
                    v-validate="'required'"
                    name="originator_name"
                    :value="kyc.full_name"
                    disabled
                  />
                </div>

                <div class="input-group">
                  <p class="input-group__label">{{ $t('withdraw_crypto_modal.form.address') }}</p>
                  <input
                    autocomplete="off"
                    placeholder="--"
                    v-validate="'required'"
                    :class="{ 'input-error': errors.has('address') }"
                    class="form-input"
                    type="text"
                    name="address"
                    v-model="kyc.address"
                    disabled
                  />
                </div>
                <div class="input-group">
                  <p class="input-group__label">
                    {{ $t('withdraw_crypto_modal.form.identity_number') }}
                  </p>
                  <input
                    autocomplete="off"
                    placeholder="--"
                    v-validate="'required'"
                    :class="{
                      'input-error': errors.has('identification_number'),
                    }"
                    class="form-input"
                    type="text"
                    name="identification_number"
                    v-model="kyc.id_number"
                    disabled
                  />
                </div>
                <!-- <div class="input-group">
                  <p class="input-group__label">
                    {{ $t('withdraw_crypto_modal.form.wallet_address') }}
                  </p>
                  <input
                    autocomplete="off"
                    placeholder="--"
                    v-validate="'required'"
                    :class="{ 'input-error': errors.has('blockchain_address') }"
                    class="form-input"
                    type="text"
                    name="blockchain_address"
                    v-model="kyc.blockchain_address"
                    disabled
                  />
                </div> -->
                <!-- ubah -->
                <!-- <div class="input-group" v-if="isUnderRequiredAmount"> -->
                <div class="input-group">
                  <p class="input-group__label">
                    {{ $t('withdraw_crypto_modal.form.source_of_funds') }} <span>*</span>
                  </p>
                  <div @click="resetErrors">
                    <SelectBox
                      v-model="form.source_of_funds"
                      :options="sourceOfFundsOptions"
                      :placeholder="$t('source_of_funds.choose')"
                      name="source_of_funds"
                      :class="{ 'input-error': errors.has('source_of_funds') }"
                    />
                  </div>

                  <div class="error-msg">
                    <span v-show="errors.has('source_of_funds')">
                      {{ $t('withdraw_crypto_modal.form.field_required') }}
                    </span>
                  </div>
                </div>
                <div class="input-group" v-if="form.source_of_funds === 'Others'">
                  <input
                    :placeholder="$t('withdraw_crypto_modal.form.source_of_funds_placeholder')"
                    maxlength="100"
                    v-validate="'required'"
                    :class="{
                      'input-error': errors.has('other_source_of_funds'),
                    }"
                    class="form-input"
                    type="text"
                    name="other_source_of_funds"
                    v-model="form.other_source_of_funds"
                    @focus="resetErrors"
                  />
                  <div class="error-msg">
                    <span v-show="errors.has('other_source_of_funds')">
                      {{ $t('withdraw_crypto_modal.form.field_required') }}
                    </span>
                  </div>
                </div>
                <!-- ubah -->
                <!-- <div class="input-group" v-if="isUnderRequiredAmount"> -->
                <div class="input-group">
                  <p class="input-group__label">
                    {{ $t('withdraw_crypto_modal.form.upload_supporting_document') }} <span>*</span>
                  </p>
                  <input
                    type="file"
                    name="supporting_documents"
                    id="supporting_documents"
                    class="file-selector__img"
                    accept="image/png,image/jpeg,image/jpg,image/heic,image/heif,application/pdf"
                    ref="file"
                    @change="previewImage"
                  />
                  <label
                    for="supporting_documents"
                    class="input-group__file-selector"
                    :class="{
                      'input-error': errors.has('supporting_documents'),
                    }"
                  >
                    <div
                      class="input-group__image-preview"
                      v-if="previewImageSrc || (isPdfFile && url_supporting_documents)"
                    >
                      <img v-if="!isPdfFile" class="preview" :src="previewImageSrc" />
                      <vue-pdf-embed
                        v-else
                        class="pdf-preview"
                        height="200"
                        :page="1"
                        :source="url_supporting_documents"
                      />
                      <img
                        @click.prevent="removeImg($refs.file)"
                        src="/images/icon/icon-close.svg"
                        alt="close-icon"
                        class="close-icon"
                      />
                    </div>
                    <div class="img-wrapper" v-else>
                      <img class="cloud-upload" src="/images/Upload.svg" alt="cloud-upload" />
                    </div>
                  </label>
                  <div class="error-msg" v-show="errors.has('supporting_documents')">
                    {{ errors.first('supporting_documents') }}
                  </div>
                </div>
              </div>
              <div class="form-body__section">
                <div class="input-group__header">
                  {{ $t('withdraw_crypto_modal.form.recipient') }}
                </div>
                <div class="input-group">
                  <p class="input-group__label">
                    {{ $t('withdraw_crypto_modal.form.full_name') }} <span>*</span>
                  </p>
                  <input
                    autocomplete="off"
                    :placeholder="$t('withdraw_crypto_modal.form.full_name_placeholder')"
                    maxlength="100"
                    :class="{ 'input-error': errors.has('recepient_name') }"
                    class="form-input"
                    type="text"
                    name="recepient_name"
                    v-model="form.recepient_name"
                    @focus="resetErrors"
                  />

                  <div class="error-msg">
                    <span v-show="errors.has('recepient_name')">
                      {{ errors.first('recepient_name') }}
                    </span>
                  </div>
                </div>
                <div class="input-group">
                  <p class="input-group__label">
                    {{ $t('withdraw_crypto_modal.form.address') }} <span>*</span>
                  </p>
                  <input
                    autocomplete="off"
                    :placeholder="$t('withdraw_crypto_modal.form.address_placeholder')"
                    maxlength="200"
                    :class="{ 'input-error': errors.has('recepient_address') }"
                    class="form-input"
                    type="text"
                    name="recepient_address"
                    v-model="form.recepient_address"
                    @focus="resetErrors"
                  />

                  <div class="error-msg">
                    <span v-show="errors.has('recepient_address')">
                      {{ $t('withdraw_crypto_modal.form.field_required') }}
                    </span>
                  </div>
                </div>
                <div class="input-group">
                  <p class="input-group__label">
                    {{ $t('withdraw_crypto_modal.form.wallet_address') }}
                  </p>
                  <input
                    disabled
                    class="form-input"
                    type="text"
                    name="recepient_blockchain_address"
                    :value="recepient_blockchain_address"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Submit Action -->
          <div class="submit-btn__wrapper">
            <button
              class="btn-primary withdrawal__submit-btn"
              @click="submitWithdrawal"
              type="button"
            >
              {{ getSubmitName($t('withdraw_crypto_modal.form.submit')) }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </PopupModal>
</template>

<script>
import PopupModal from '@/components/PopupModal.vue';
import Datepicker from 'vuejs-datepicker';
import SelectBox from '@/components/shared_components/common/SelectBox';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import heic2any from 'heic2any';

export default {
  inject: ['$validator'],
  components: {
    PopupModal,
    Datepicker,
    SelectBox,
    VuePdfEmbed,
  },
  props: {
    name: { type: String, default: 'withdrawal-modal' },
    isShow: { type: Boolean, default: false },
    submit: { type: Function },
    kyc: { type: Object },
    recepient_blockchain_address: '',
    errorMessenger: { type: String },
    isDisableButton: { type: Boolean, default: false },
    contact_email: { type: String, default: '' },
    amount: { type: Number | String, default: 0 },
    travelRuleCrypto: { type: Object },
    isUnderRequiredAmount: { type: Boolean, default: true },
  },
  data() {
    return {
      form: {
        source_of_funds: null,
        recepient_name: '',
        recepient_address: '',
      },
      sourceOfFundsOptions: [
        { id: 'Salary', name: this.$i18n.t('source_of_funds.salary') },
        { id: 'Personal savings accounts', name: this.$i18n.t('source_of_funds.personal_saving') },
        { id: 'Employment income', name: this.$i18n.t('source_of_funds.employment_income') },
        { id: 'Property sales', name: this.$i18n.t('source_of_funds.property_sale') },
        { id: 'Inheritances and gifts', name: this.$i18n.t('source_of_funds.inheritances') },
        { id: 'Legal settlements', name: this.$i18n.t('source_of_funds.legal_settlements') },
        { id: 'Others', name: this.$i18n.t('source_of_funds.others') },
      ],
      url_supporting_documents: '',
      isPdfFile: false,
      nameRegex: /^[a-zA-Z ]*$/,
      previewImageSrc: '',
    };
  },
  computed: {
    isValid() {
      if (!this.isUnderRequiredAmount) {
        if (
          !this.form.source_of_funds ||
          !this.validateImage(this.$refs.file.files[0]) ||
          !this.form.recepient_name ||
          !/^[A-Za-z\s]+$/.test(this.form.recepient_name) ||
          !this.form.recepient_address
        )
          return false;
        return true;
      } else {
        if (
          !this.form.recepient_name ||
          !/^[A-Za-z\s]+$/.test(this.form.recepient_name) ||
          !this.form.recepient_address
        )
          return false;
        return true;
      }
    },
  },
  watch: {
    errorMessenger(newVal) {
      if (newVal) {
        this.$validator.errors.add({
          field: 'otp',
          msg: newVal,
        });
      }
    },
    'form.recepient_name'(val) {
      if (!this.nameRegex.test(val)) {
        this.form.recepient_name = this.form.recepient_name.replace(/[^a-zA-Z ]/g, '');
      }
    },
  },
  methods: {
    formatMoney(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },
    removeImg(input) {
      this.previewImageSrc = '';
      this.url_supporting_documents = '';
      if (input && input.value) {
        input.value = null;
      }
      this.resetErrors();
    },
    validate() {
      if (!this.isUnderRequiredAmount) {
        if (!this.form.source_of_funds)
          this.errors.add('source_of_funds', this.$i18n.t('validation_field_required'));
        this.validateImage(this.$refs.file.files[0]);
      }
      if (!this.form.recepient_name)
        this.errors.add('recepient_name', this.$i18n.t('validation_field_required'));
      if (!/^[A-Za-z\s]+$/.test(this.form.recepient_name))
        this.errors.add('recepient_name', 'The full name is invalid');
      if (!this.form.recepient_address)
        this.errors.add('recepient_address', this.$i18n.t('validation_field_required'));
    },
    convertUrl(url) {
      fetch(url)
        .then((res) => res.blob())
        .then((blob) =>
          heic2any({
            blob,
            toType: 'image/png',
            quality: 1,
          })
        )
        .then((conversionResult) => {
          this.previewImageSrc = URL.createObjectURL(conversionResult);
        });
    },
    previewImage: function (event) {
      this.resetErrors();
      const input = event.target;
      if (input.files && input.files[0]) {
        this.isPdfFile = input.files[0].type === 'application/pdf';
        const reader = new FileReader();
        reader.onload = (e) => {
          const name = input.files[0].name;
          this.previewImageSrc = e.target.result;
          if (
            name.includes('heic') ||
            name.includes('heif') ||
            name.includes('HEIC') ||
            name.includes('HEIF')
          ) {
            this.convertUrl(e.target.result);
          }
          this.url_supporting_documents = e.target.result;
          this.form = { ...this.form, supporting_documents: input.files[0] };
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    validateImage(file) {
      const fileSize = file?.size;
      const name = file?.name.split('.') || [''];
      const typeByNameFile = name[name.length - 1].toLowerCase();
      if (!file) {
        this.errors.add('supporting_documents', this.$i18n.t('validation_field_required'));
        return false;
      } else if (
        typeByNameFile !== 'jpeg' &&
        typeByNameFile !== 'png' &&
        typeByNameFile !== 'jpg' &&
        typeByNameFile !== 'heic' &&
        typeByNameFile !== 'heif' &&
        typeByNameFile !== 'pdf'
      ) {
        this.errors.add(
          'supporting_documents',
          this.$i18n.t('withdraw_crypto_modal.form.invalid_document_type')
        );
        return false;
      } else if (typeof file !== 'string' && fileSize > 10485760) {
        this.errors.add(
          'supporting_documents',
          this.$i18n.t('withdraw_crypto_modal.form.document_size_over_limit')
        );
        return false;
      }
      return true;
    },

    handleCloseModal() {
      this.$emit('close');
      this.form = {};
      this.previewImageSrc = '';
    },
    async submitWithdrawal() {
      this.validate();
      const isValid = await this.$validator.validate();
      if (!isValid) return;
      if (!this.isValid) return;

      let document = {
        origin: {
          full_name: this.kyc.full_name,
          address: this.kyc.address,
          identification_number: this.kyc.id_number,
          wallet_address: this.kyc.blockchain_address,
        },
        recepient: {
          full_name: this.form.recepient_name?.trim(),
          address: this.form.recepient_address?.trim(),
          wallet_address: this.recepient_blockchain_address,
        },
      };

      if (!this.isUnderRequiredAmount) {
        document.origin.source_of_funds =
          this.form.source_of_funds !== 'Others'
            ? this.form.source_of_funds
            : this.form.other_source_of_funds?.trim();
      }
      this.$emit('submitWithdrawal', document, this.form.supporting_documents);
      setTimeout(() => {
        this.form.source_of_funds = '';
        this.form.other_source_of_funds = '';
        this.form.recepient_name = '';
        this.form.recepient_address = '';
        this.removeImg(this.$refs.file);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.close-icon {
  width: 21px;
  height: 21px;
  min-height: unset;
  background: transparent;
  position: absolute;
  right: 9px;
  top: 9px;
  cursor: pointer;
  z-index: 9999;
  transition: all 0.3s ease-in-out;
}
.withdrawal-modal {
  max-width: 1016px;
  margin: 16px auto;
  padding: 24px;
  background-color: white;
  border-radius: 16px;
  @include tablet {
    max-width: 512px;
    width: calc(100dvw - 30px);
    margin: 15px auto;
    border-radius: 10px;
  }
  @include mobile {
    width: calc(100dvw - 30px);
    margin: 15px auto;
    border-radius: 10px;
  }
  .guest-page-form {
    .form-container {
      .guest-page__form-header {
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        color: $text-main;
      }
      .guest-page__form-body {
        margin-top: 24px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
        @include tablet {
          grid-template-columns: 1fr;
        }
        @include mobile {
          grid-template-columns: 1fr;
        }
        .form-body__section {
          .input-group__header {
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: $text-main;
            margin-bottom: 16px;
          }
          .input-group {
            :deep() {
              .input-error {
                .button_drop_search {
                  border-color: $color-red;
                }
              }
              .button_drop_search {
                border: 1px solid #ddd;
                border-radius: 8px;
                padding: 16px;
                height: 42px;
                margin-bottom: 5px;
                > span {
                  font-size: 16px;
                  line-height: 24px;
                  @include mobile {
                    font-size: 14px;
                    line-height: 22px;
                    font-weight: 300;
                  }
                }
              }
            }
            margin-bottom: unset;
            .input-group__label {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              color: $text-secondary;
              margin-bottom: 8px;
              @include mobile {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
              }
              span {
                color: $color-red;
              }
            }

            input {
              border-radius: 8px;
              font-weight: 300;
              font-size: 16px;
              line-height: 24px;
              color: $text-main;
              background: white;
              border: 1px solid #ddd;
              height: 42px;
              margin-bottom: 5px;
              @include mobile {
                font-size: 14px;
                font-weight: 300;
                line-height: 22px;
              }
              &:disabled {
                opacity: 1 !important;
                background: #f2f2f2 !important;
                color: $text-main !important;
                border: 1px solid #ddd !important;
                -webkit-text-fill-color: $text-main;
              }
              &:focus {
                border-color: $bg-main1;
              }
            }
            & + .input-group {
              margin-top: 10px;
            }
            input.file-selector__img {
              display: none;
            }
            .input-group__file-selector {
              height: 190px;
              padding: 16px;
              border: 1px solid #ddd;
              border-radius: 8px;
              position: relative;
              display: flex;
              justify-content: center;
              cursor: pointer;
              .img-wrapper {
                margin-top: auto;
                margin-bottom: auto;
              }
              .input-group__image-preview {
                margin: 0 auto;
                text-align: center;
                img.preview {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
                div.pdf-preview {
                  height: 100%;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
    .submit-btn__wrapper {
      margin-top: 33px;
      width: 100%;
      display: flex;
      justify-content: center;
      @include mobile {
        margin-top: 8px;
      }
      .withdrawal__submit-btn {
        width: 100%;
        background: $bg-main6;
        color: white;
        border-radius: 60px;
        padding: 9px 32px;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
}
.input-error {
  border-color: red !important;
}
.error-msg {
  color: red;
  font-size: 12px;
}
.guest-page-form {
  position: relative;
  .modal-header {
    position: absolute;
    border: 0;
    top: -12px;
    right: -12px;
    padding: 0;

    .close {
      opacity: 0.9;
      z-index: 100;
      position: relative;
      outline: none;
      background: transparent !important;
      &:hover {
        opacity: 1;
      }
      .icon-close {
        border-radius: 50%;
        color: $color-grey;
        line-height: 32px;
        overflow: hidden;
        text-align: center;
        display: inline-block;
        font-size: $font-smaller;
        width: 30px;
        height: 30px;
        margin: 0px 0px 0 0;
        cursor: pointer;
        padding-left: 2px;
        &:hover {
          background-color: $color-light-grey;
          color: $color-grey;
        }
      }
    }
  }
}
</style>
