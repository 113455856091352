<template>
  <div>
    <input
      @focus="$emit('focus')"
      :maxlength="maxLengthInput"
      class="address"
      :placeholder="placeholder"
      :class="{ error: errors.has(field) }"
      v-model="internalValue"
    />
    <span v-show="errors.has(field)" class="invalid-feedback">{{
      $t(errors.first(field))
    }}</span>
  </div>
</template>

<script>
import COMMON_CONST from "@/common/Const";

export default {
  inject: ["$validator"],
  data() {
    return {
      internalValue: "",
      maxLengthInput: COMMON_CONST.MAX_LENGTH_INPUT,
    };
  },
  props: {
    placeholder: { type: String },
    field: { type: String, required: true },
    value: { required: true },
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      if (this.internalValue === this.value) {
        return;
      }
      this.$emit("input", val);
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    validate() {
      if (_.isEmpty(this.internalValue.trim())) {
        this.errors.add(this.field, "bank_setting.error.lose_" + this.field);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
input {
  &:focus {
    border: 1px solid $bg-main1 !important;
  }
  @include text-font($helvetica, $dark-1, $font-medium-bigger, 500, 20px);
  &::placeholder {
    @include text-font($helvetica, $dark-4, $font-medium-bigger, 500, 20px);
  }
}
.address {
  width: 100%;
  height: 41px;
  border-radius: 12px;
  border: solid 1px $color-alto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10.5px 16px;
  gap: 10px;
}
</style>
