<template>
  <modal
    :enableClose="false"
    :hasModalFooter="false"
    :name="name"
    width="770"
    id="agreement-otp-modal"
    @onShow="onShowModal"
  >
    <div slot="body">
      <div class="modal-header-custom">
        <img
          @click="hideModal"
          src="/images/close.svg"
          class="close-icon"
          alt="close"
        />
      </div>
      <div class="modal-form">
        <div class="modal-header-lost-OTP">
          <span :class="{ 'title-modal-ja': isJa, 'title-modal': !isJa }">{{
            $t("account.modal.agreement_otp.title")
          }}</span>
        </div>
        <div class="note-header-top">
          <span
            v-html="
              $t('account.modal.agreement_otp.policy_content', {
                contact_email: contact_email,
              })
            "
          ></span>
        </div>
        <div class="note-header-content">
          <div class="note-header-left">
            <div style="display: flex">
              <div>
                <span class="color_grey_dark color_grey_dark-first-left">{{
                  $t("account.modal.agreement_otp.policy_content_text1")
                }}</span>
              </div>
              <div class="color_grey_dark-lostotp-request">
                <span class="color_grey_dark">{{
                  $t("account.modal.agreement_otp.policy_content_text2")
                }}</span>
              </div>
            </div>
            <p class="color_grey_dark">
              {{ $t("account.modal.agreement_otp.policy_content_text3") }}
            </p>
            <p class="pad-l10">
              {{ $t("account.modal.agreement_otp.policy_content_text4") }}
            </p>
            <p class="pad-l10">
              {{ $t("account.modal.agreement_otp.policy_content_text5") }}
            </p>
            <p class="pad-l10">
              {{
                $t("account.modal.agreement_otp.policy_content_text6", {
                  APP_NAME: APP_NAME,
                })
              }}
            </p>
            <p class="color_grey_dark">
              {{ $t("account.modal.agreement_otp.policy_content_text7") }}
            </p>
            <p class="pad-l10">
              {{ $t("account.modal.agreement_otp.policy_content_text8") }}
            </p>
            <p class="pad-l10">
              {{ $t("account.modal.agreement_otp.policy_content_text9") }}
            </p>
            <p class="pad-l10">
              {{ $t("account.modal.agreement_otp.policy_content_text10") }}
            </p>
          </div>
          <div class="note-header-right">
            <div class="note-header-right-content">
              <div class="note-header-right-content-1 right-content">
                <img src="/images/success.svg" alt="success" />
                <div class="p">
                  <span class="txt_right_policy">{{
                    $t("account.modal.agreement_otp.policy_content_text11")
                  }}</span>
                </div>
              </div>
              <div class="note-header-right-content-2 right-content">
                <img src="/images/success.svg" alt="success" />
                <div class="p">
                  <span class="txt_right_policy">{{
                    $t("account.modal.agreement_otp.policy_content_text12")
                  }}</span>
                </div>
              </div>
              <div class="note-header-right-content-3 right-content">
                <img src="/images/success.svg" alt="success" />
                <div class="p">
                  <span class="txt_right_policy">{{
                    $t("account.modal.agreement_otp.policy_content_text13", {
                      APP_NAME: APP_NAME,
                    })
                  }}</span>
                </div>
              </div>
              <div class="note-header-right-content-4 right-content">
                <img src="/images/success.svg" alt="success" />
                <div class="p">
                  <span class="txt_right_policy">{{
                    $t("account.modal.agreement_otp.policy_content_text14")
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="click-lost-OTP">
          <button @click="hideModal">
            {{ $t("common.action.confirm") }}
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import Modal from "../common/Modal";
export default {
  components: {
    Modal,
  },

  props: {
    name: { type: String, default: "lostOtpPolicyModal" },
    contact_email: { type: String, default: "" },
  },
  data() {
    return {
      isJa: window.localStorage.getItem("locale") == "ja",
      APP_NAME: process.env.VUE_APP_SHORT_NAME,
    };
  },
  methods: {
    hideModal() {
      CommonModal.hide(this.name);
    },

    onShowModal(val) {
      this.$emit("onShow", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-primary {
  max-width: 354px;
}
.modal-dialog {
  width: 830px !important;
}
.modal-header-custom {
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;

  @include mobile {
    top: 10px;
    right: 10px;
  }
}
#agreement-otp-modal {
  .modal-form {
    width: 100%;
    margin: 0 auto;
    .icon-save:before {
      content: "\E922";
      color: $text-curious-blue;
      height: 15px;
      width: 20.2px;
    }
    .modal-header-lost-OTP {
      width: 100%;
      padding: 0;
      margin-bottom: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      .title-modal {
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        color: $text-main2;
        margin-bottom: 8px;

        @include mobile {
          font-size: 14px;
          line-height: 25px;
        }
      }
      .title-modal-ja {
        width: 259px;
        height: 40px;
        font-family: $font-family-roboto-medium;
        font-size: 17px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 2;
        letter-spacing: normal;
        text-align: center;
        color: $text-eden;
      }

      .hr-title {
        width: 170px;
        height: 1px;
        border-radius: 5px;
        background-color: $background-light-grey;
      }
    }

    .note-header-top {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: rgba($text-main2, 0.6);
      text-align: center;
      :deep {
        .primary-link {
          color: $color-blue;
        }
      }
    }
    .note-header-content {
      margin-top: 24px;
      display: flex;
      gap: 8px;

      .note-header-left {
        flex: 1;
        padding: 24px;
        border-radius: 8px;
        background: #f8f8f8;
        p {
          margin: 0;
        }
        .color_grey_dark-lostotp-request {
          padding-left: 3px;
        }
        .color_grey_dark,
        .pad-l10 {
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          color: $dark-1;
          @include mobile {
            font-size: 14px;
            line-height: 20px;
          }
        }
        .pad-l10 {
          font-weight: 400;
        }

        .color_grey_dark {
          margin-top: 17px;
        }
        @include mobile {
          padding: 16px 24px;
        }
      }
      .note-header-right {
        flex: 1;
        padding: 24px;
        border-radius: 8px;
        background: #f8f8f8;
        .icon-guarantee1 {
          color: $color-corn-pale;
          padding-right: 11px;
          float: left;
          line-height: 20px;
          font-size: $font-big-20;
        }
        .txt_right_policy {
          color: $dark-1;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;

          @include mobile {
            font-size: 14px;
            line-height: 20px;
          }
        }
        p {
          font-family: $font-family-roboto;
          font-size: 13px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.54;
          letter-spacing: normal;
          color: $text-grey-dusty;
        }
        i {
          height: 15px;
          width: 20.2px;
        }
        .note-header-right-content {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          .right-content {
            display: flex;
            gap: 12.5px;
            align-items: flex-start;
            margin-bottom: 15px;

            &:last-child {
              margin-bottom: 0;
            }
          }
          .img {
            width: 32px;
          }
          .p {
            width: auto;
            p {
              margin: 0;
              padding: 0;
            }
          }
        }

        @include mobile {
          padding: 14px;
        }
      }

      @include mobile {
        flex-direction: column;
      }
    }

    @include mobile {
      margin-top: 12px;
      padding: 0;
      max-height: 550px;
      overflow-y: auto;
    }
  }
  .click-lost-OTP {
    margin-top: 16px;
    button {
      @include btn-common(contained, lg);
    }
  }

  .modal-dialog {
    width: 760px !important;
    max-width: 100%;
    .modal-content {
      .modal-footer {
        .btn-primary {
          margin-top: 15px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#agreement-otp-modal {
  .modal-header {
    height: 30px;
  }
  .modal-footer {
    height: 25px;
    padding: 0px;
  }
}
</style>
