<template>
  <div>
    <div v-if="visibleMarkets == true" @click="onCloseMarketModal" class="click_out_side"></div>
    <div class="exchange-header" :class="{ 'exchange-header1': !visibleMarkets }">
      <div class="exchange-market" v-on:click="toggleModal1">
        <img src="/images/icon/u_chart.svg" alt="" class="chart__icon" />
        <div class="exchange-market__content">
          {{ zone == 0 ? coin : '--' | uppercase }}/{{ zone == 0 ? currency : '--' | uppercase }}
        </div>
        <img
          :src="
            visibleMarkets
              ? '/images/spot_exchange/chevon-up.svg'
              : '/images/spot_exchange/chevon-down.svg'
          "
          alt=""
        />
      </div>

      <div class="list_coin_hd" v-show="visibleMarkets">
        <markets-dark @closeMarketModal="onCloseMarketModal" :zone="0" />
      </div>
    </div>
    <div v-if="visibleMarkets2 == true" @click="onCloseMarketModal2" class="click_out_side"></div>
    <top-info :latestPrices="latestPrices" />
  </div>
</template>

<script>
import Const from '@/common/Const';
import TopInfo from './TopInfo';
import DigitalClock from './DigitalClock';
import MarketsDark from './MarketsDark';
import PriceOtherExchange from './PriceOtherExchange';

export default {
  components: {
    TopInfo,
    DigitalClock,
    MarketsDark,
    PriceOtherExchange,
  },
  props: ['latestPrices'],
  data() {
    let coinEx = this.$route.query.coin;
    let currencyEx = this.$route.query.currency;
    if (coinEx && currencyEx) {
      localStorage.setItem('spotEx_coin', coinEx);
      localStorage.setItem('spotEx_currency', currencyEx);
    } else {
      coinEx = localStorage.spotEx_coin || Const.DEFAULT_COIN;
      currencyEx = localStorage.spotEx_currency || Const.DEFAULT_CURRENCY;
      if (coinEx != this.$route.query.coin || currencyEx != this.$route.query.currency) {
        this.$router.push({ query: { coin: coinEx, currency: currencyEx } });
      }
    }
    return {
      coin: coinEx,
      currency: currencyEx,
      visibleMarkets: false,
      visibleMarkets2: false,
      zone: localStorage.spotEx_zone || 0,
    };
  },
  methods: {
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
      };
    },
    onSpotSelectedPairMarket(pair) {
      this.coin = pair.coin;
      this.currency = pair.currency;
      this.zone = pair.zone || 0;
      if (localStorage) {
        localStorage.setItem('spotEx_coin', this.coin);
        localStorage.setItem('spotEx_currency', this.currency);
        localStorage.setItem('spotEx_zone', pair.zone);
      }
    },
    onCloseMarketModal() {
      this.visibleMarkets = false;
    },
    onCloseMarketModal2() {
      this.visibleMarkets2 = false;
    },
    toggleModal1() {
      this.visibleMarkets = !this.visibleMarkets;
      this.visibleMarkets2 = false;
    },
    toggleModal2() {
      this.visibleMarkets2 = !this.visibleMarkets2;
      this.visibleMarkets = false;
    },
  },
  watch: {
    $route(to, from) {
      this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
      this.coin = this.$route.query.coin || Const.DEFAULT_COIN;

      // hide markets dialog
      this.visibleMarkets = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.exchange-header {
  z-index: 2022;
  position: relative;
  display: inline;
  float: left;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  font-family: $helvetica;
  border: 0;
  color: $color-white;
  border-radius: 5px;
  background-color: $bg-main1;
  min-width: 145px;
  max-width: 250px;
  margin-right: 24px;
  cursor: pointer;
  text-align: right;
  padding: 7px 16px;
  border-radius: 60px;

  &.exchange-header1 {
    z-index: 0;
  }
  .exchange-market {
    display: flex;
    justify-content: start;
    gap: 10px;
    & .exchange-market__content {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    & > .chart__icon {
    }
    & span.icon-arrow1,
    span.icon-arrow2 {
      margin-top: auto;
      height: 100%;
      line-height: unset;
      margin-bottom: auto;
    }
  }
  .icon-arrow1,
  .icon-arrow2 {
    margin-left: 5px;
    color: $color-white;
    font-weight: 800;
    line-height: 18px;
    font-size: 14;
    float: right;
  }
  .trading-pair {
    cursor: pointer;
  }
}
.top-info-content {
  display: inline-block;
  margin-top: 10px;
}
.icon_coin_ch {
  float: left;
  line-height: 18px;
  margin-right: 10px;
}
.list_coin_hd {
  position: absolute;
  width: 100%;
  top: 100%;
  z-index: 999;
  left: 0px;
  text-align: left;
  ul {
    margin-top: 10px;
    background-color: $color-alabaster;
    border-radius: 3px;
    padding: 10px 0px;
    li {
      line-height: 20px;
      padding: 5px 20px;
      color: $color-jungle-green;
      font-size: $font-small;
      font-family: $font-family-roboto-bold;
      cursor: pointer;
      &:hover {
        background-color: $color-jungle-green;
        color: $color-white;
      }
    }
  }
}
.click_out_side {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(51, 51, 51, 0.7);
  z-index: 2010;
}
@include tablet {
  .coin_info_exchange {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px 16px !important;
    & .exchange-header {
      width: fit-content;
    }
    & .list_coin_hd {
      width: calc(100vw - 32px);
    }
  }
}
@include mobile {
  .coin_info_exchange {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 8px 16px !important;
    & .exchange-header {
      width: fit-content;
    }
    & .list_coin_hd {
      width: calc(100vw - 32px);
    }
  }
}
</style>
