<template>
  <div id="mam_funds" class="page_container">
    <funds-spot-ex
      :isSinglePage="false"
      ref="balancesComponent"
      :isSpotMobile="isSpotMobile"
    />
  </div>
</template>

<script>
import FundsSpotEx from "@/pages/funds/FundsSpotEx.vue";

export default {
  props: {
    isSpotMobile: Boolean,
  },
  components: {
    FundsSpotEx,
  },
  methods: {
    refreshTable() {
      this.$refs.balancesComponent.refresh();
    },
    hideSmallPair(hideBalances) {
      this.$refs.balancesComponent.hideBalances = hideBalances;
      this.$refs.balancesComponent.refresh();
    },
  },
};
</script>
