<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import rf from "@/request/RequestFactory";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";

export default {
  name: "App",
  data() {
    return {
      isAuthenticated: window.isAuthenticated,
    };
  },
  created() {
    rf.getRequest("MasterdataRequest")
      .getAll()
      .then((res) => {
        this.$store.commit("setMasterdata", res);
      });
    rf.getRequest("SettingRequest")
      .getTaxSettings()
      .then((res) => {
        this.$store.commit("setTaxSettings", res.data);
      });
    // load freshworks widget
    let pathName = window.location.pathname;
    if (!pathName.includes("webview")) {
      window.fwSettings = {
        widget_id: 150000002850,
      };
      !(function () {
        if (typeof window.FreshworksWidget != "function") {
          var n = function () {
            n.q.push(arguments);
          };
          (n.q = []), (window.FreshworksWidget = n);
        }
      })();
      this.loadScript("https://widget.freshworks.com/widgets/150000002850.js");
    }
  },
  methods: {
    loadScript(link) {
      var script = document.createElement("script");
      script.src = link;
      script.async = true;
      document.body.appendChild(script);
    },
    getSocketEventHandlers() {
      return {
        update_tax_event: this.updateTaxSettings,
      };
    },
    updateTaxSettings(res) {
      this.$store.commit("setTaxSettings", res.data);
    },
    pushDeviceTokenRequest() {
      if (window.iOSPushCapability) {
        window.webkit.messageHandlers["request-device-token"].postMessage(
          "request-device-token"
        );
      }
    },
    isIOS() {
      const browserInfo = navigator.userAgent.toLowerCase();
      if (browserInfo.match("iphone") || browserInfo.match("ipad")) {
        return true;
      }
      if (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform)
      ) {
        return true;
      }
      return false;
    },
    isPwa() {
      return ["fullscreen", "standalone"].some(
        (displayMode) =>
          window.matchMedia("(display-mode: " + displayMode + ")").matches
      );
    },
    async handleFirebase() {
      if (this.isAuthenticated) {
        if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers["request-device-token"]
        ) {
          window.iOSPushCapability = true;
        }
        if (this.isIOS()) {
          this.pushDeviceTokenRequest();
          return;
        }
        // dev
        // const firebaseConfig = {
        //   apiKey: "AIzaSyAWfuNivIW3Z695MQ0iLQ9nuDoJ_BSheYw",
        //   authDomain: "nvx-app.firebaseapp.com",
        //   projectId: "nvx-app",
        //   storageBucket: "nvx-app.appspot.com",
        //   messagingSenderId: "243109049993",
        //   appId: "1:243109049993:web:55e03c6347693d8b84f858",
        // };

        // const vapidKey = "BB-uS7UJ68EVzvK76g9sSjrEFzhPba5o08rTKefl3DAokpkBUyQ0wXgXnjbLvCV8z34WhCI5kfe8aA6T_T954SA";

        // prod
        const firebaseConfig = {
          apiKey: "AIzaSyCSD9wDeN9zMRbVzJ73Y8Yji4T_jsJ48LY",
          authDomain: "nvx-app-a8c23.firebaseapp.com",
          projectId: "nvx-app-a8c23",
          storageBucket: "nvx-app-a8c23.appspot.com",
          messagingSenderId: "92362927339",
          appId: "1:92362927339:web:192b02b11481ad322622ae",
          measurementId: "G-L0P82T3774",
        };
        const vapidKey =
          "BNCf72JFbOlzMChH7CGa8YWJ5QLody-3sz9tEI9QtkYFtUN5oSnA7Vz4OjmKk5SII1TNfhGtCN7AKi9W";

        const app = initializeApp(firebaseConfig);
        getAnalytics(app);

        const messaging = getMessaging();

        getToken(messaging, {
          vapidKey: vapidKey,
        })
          .then((res) => {
            rf.getRequest("UserRequest").registerDevice(res);
          })
          .catch((err) => {
            console.log("getToken - error: ", err);
          });
      }
    },
    handlePushNotification(event) {
      // Xử lý sự kiện request-device-token ở đây
      const token = event.detail;
      try {
        rf.getRequest("UserRequest").registerDevice(token);
      } catch (error) {}

      // ...
    },
  },
  watch: {
    $route() {
      document.getElementById("app").scrollIntoView();
      document.getElementById("app").classList.remove("overlay");
    },
  },
  mounted() {
    this.handleFirebase();
    rf.getRequest("PriceRequest")
      .getPrices24h()
      .then((res) => {
        this.$store.commit("setTotalPair", res.data.length);
        this.$store.commit("setPairCoins", res.data);
      });
    if (this.isPwa()) {
      this.handleFirebase();
    }

    const divApp = document.getElementById("app");
    divApp.addEventListener("mouseenter", () => {
      this.$store.commit("setIsDeviceHover", true);
    });
    divApp.addEventListener("touchstart", () => {
      this.$store.commit("setIsDeviceHover", false);
    });

    window.addEventListener(
      "request-device-token",
      this.handlePushNotification
    );
  },
  beforeDestroy() {
    // Gỡ bỏ lắng nghe sự kiện trước khi component bị hủy
    window.removeEventListener(
      "request-device-token",
      this.handlePushNotification
    );
  },
};
</script>
<style lang="scss">
@import "@/assets/sass/main.scss";
@import "@/assets/sass/reset.scss";

body {
  margin: 0;
}
</style>
