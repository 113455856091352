<template>
  <div>
    <input-text-number
      :placeholder="$t('funds.withdraw_fiat.account_no')"
      class="address"
      :class="{ error: errors.has('account_no') }"
      v-model="internalValue"
      @focus="$emit('focus')"
    />
    <span v-show="errors.has('account_no')" class="invalid-feedback">{{
      $t(errors.first("account_no"))
    }}</span>
  </div>
</template>

<script>
export default {
  inject: ["$validator"],
  data() {
    return {
      internalValue: "",
    };
  },
  props: {
    value: { required: true },
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      if (this.internalValue === this.value) {
        return;
      }
      this.$emit("input", val);
    },
  },
  methods: {
    validate() {
      if (_.isEmpty(this.internalValue)) {
        this.errors.add("account_no", "bank_setting.error.lose_account_no");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.address {
  width: 100%;
  height: 41px;
  border-radius: 12px;
  border: solid 1px $color-alto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10.5px 16px;
  gap: 10px;
  &:focus {
    border: 1px solid $bg-main1 !important;
  }
  @include text-font($helvetica, $dark-1, $font-medium-bigger, 500, 20px);
  &::placeholder {
    @include text-font($helvetica, $dark-4, $font-medium-bigger, 500, 20px);
  }
}
</style>
