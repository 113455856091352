<template>
  <PopupModal :isShow="isShow">
    <template slot="body">
      <div class="banks-modal">
        <img
          src="@/assets/images/close-circle.svg"
          alt=""
          class="close-icon"
          @click="handleCloseModal"
        />
        <div class="modal-tit">{{ $t('funds.withdrawals.select_address') }}</div>
        <div class="modal-body">
          <div
            class="add-new-address-button"
            v-if="!isAddNewAddress && !isCancelAdd"
            @click="handleAddNewAddress"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <g clip-path="url(#clip0_297_38263)">
                <path
                  d="M12.5 5V19"
                  stroke="#67666E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.5 12H19.5"
                  stroke="#67666E"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_297_38263">
                  <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                </clipPath>
              </defs>
            </svg>
            <span>{{ $t('funds.withdrawals.form_add_new_address') }}</span>
          </div>
          <div class="new-address" v-else-if="!isCancelAdd">
            <div class="title">{{ $t('funds.withdrawals.form_add_new_address') }}</div>
            <div class="wallet-name input-group">
              <label>{{ $t('funds.withdrawals.withdrawal_title_label') }}*</label>
              <input
                type="text"
                class="form-input"
                :maxlength="maxLength20"
                :class="{ error: isAddNewAddress && errors.has('wallet_name') }"
                v-model="newAddress.wallet_name"
                :placeholder="$t('funds.withdrawals.withdrawal_placeholder_label')"
                @focus="resetErrorLabel"
                :disabled="isSubmitNewAddress"
              />
              <span v-show="errors.has('wallet_name')" class="f-left invalid-feedback">{{
                errors.first('wallet_name')
              }}</span>
            </div>
            <div class="wallet-address input-group">
              <label>{{ $t('funds.withdrawals.withdrawal_title_address') }}*</label>
              <input
                type="text"
                class="form-input"
                :maxlength="maxlength"
                :class="{
                  error: isAddNewAddress && errors.has('blockchain_address'),
                }"
                v-model="newAddress.blockchain_address"
                :placeholder="$t('funds.withdrawals.withdrawal_placeholder_address')"
                @focus="resetErrorAddress"
                :disabled="isSubmitNewAddress"
              />
              <span v-show="errors.has('blockchain_address')" class="f-left invalid-feedback">{{
                errors.first('blockchain_address')
              }}</span>
            </div>
            <div class="xrp-tag" v-if="coin === 'xrp' || coin === 'eos'">
              <label>{{ coin | uppercase }} {{ $t('funds.withdrawals.xrp_tag') }}*</label>
              <input-destination-tag
                ref="blockchain_sub_address"
                v-model="newAddress.blockchain_sub_address"
                :coin="coin"
                @focus="resetErrorTag"
                :disabled="isSubmitNewAddress"
              />
            </div>
            <div
              v-if="coin == 'usdt' && user.is_otc == 'true'"
              style="display: flex; column-gap: 10px"
            >
              <label class="ng-binding radio-network"
                ><input
                  type="radio"
                  value="erc20"
                  v-model="network"
                  name="networkAddress"
                  style="margin-top: 0"
                />
                ERC20</label
              >
              <label class="ng-binding radio-network"
                ><input
                  type="radio"
                  value="trc20"
                  v-model="network"
                  name="networkAddress"
                  style="margin-top: 0"
                />
                TRC20</label
              >
            </div>

            <div class="form-group-btn-address">
              <button
                type="button"
                @click="handleCancelNewAddress"
                class="bordered"
                :disabled="isSubmitNewAddress"
              >
                {{ $t('common_action.cancel') }}
              </button>
              <button
                type="button"
                @click="addNewAddress"
                class="contained"
                :disabled="isSubmitNewAddress"
              >
                {{ $t('funds.withdrawals.add_address') }}
              </button>
            </div>
          </div>
          <div class="discard-new-address" v-else>
            <div class="title">{{ $t('funds.withdrawals.title_discard_new_address') }}</div>
            <div class="text">{{ $t('funds.withdrawals.text_discard_new_address') }}</div>
            <div class="form-group-btn-address">
              <button
                type="button"
                @click="handleGoBackNewAddress"
                class="contained"
                :disabled="isSubmitNewAddress"
              >
                {{ $t('funds.withdrawals.back_discard_new_address') }}
              </button>
              <button
                type="button"
                @click="handleDiscardNewAddress"
                class="bordered"
                :disabled="isSubmitNewAddress"
              >
                {{ $t('funds.withdrawals.yes_discard_new_address') }}
              </button>
            </div>
          </div>
          <div v-if="isFetchingAddress">
            <div class="loader"></div>
          </div>
          <div v-else>
            <div
              class="addresses-container"
              :class="isAddNewAddress || isCancelAdd ? 'scrolled' : ''"
            >
              <div
                v-for="withdrawAddress in withdrawAddresses"
                :key="withdrawAddress.wallet_address"
                class="address-box-modal"
                :class="
                  selectedAddress?.blockchain_address === withdrawAddress.wallet_address
                    ? 'selected'
                    : ''
                "
                @click="
                  selectAddress(
                    withdrawAddress.wallet_address,
                    withdrawAddress.wallet_sub_address,
                    withdrawAddress.wallet_name,
                    withdrawAddress.network
                  )
                "
              >
                <div class="wallet-name-container">
                  <span class="new-badge" v-if="isNew(withdrawAddress.created_at, Date())">{{
                    $t('common.new')
                  }}</span>
                  <span class="wallet-name">{{ withdrawAddress.wallet_name }}</span>
                  <svg
                    class="checked"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_297_38269)">
                      <path
                        d="M5 12L10 17L20 7"
                        stroke="#108CDD"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_297_38269">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <span class="wallet-address">{{ withdrawAddress.wallet_address }}</span>
                <span class="wallet-address">{{ withdrawAddress.wallet_sub_address }}</span>
                <span class="wallet-address" style="text-transform: uppercase">{{
                  withdrawAddress.network
                }}</span>
              </div>
            </div>

            <div class="form-group-btn">
              <button
                type="submit"
                @click="handleSelectAddress"
                :disabled="
                  isAddNewAddress || !selectedAddress.blockchain_address || isSubmitNewAddress
                "
              >
                {{ $t('funds.withdrawals.submit') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </PopupModal>
</template>

<script>
import rf from '@/request/RequestFactory';
import PopupModal from '@/components/PopupModal.vue';
import InputDestinationTag from './InputDestinationTag';
import COMMON_CONST from '@/common/Const';
import Utils from '@/common/Utils';
import moment from 'moment';
import BUSDValidator from 'neka-wallet-address-validator';
import WAValidator from 'wallet-validator-testnet';
import { mapState } from 'vuex';

export default {
  name: 'ModalAddress',
  components: { PopupModal, InputDestinationTag },
  props: {
    isShow: Boolean,
    withdrawAddresses: { type: Array, required: true },
    withdrawAddressParent: { type: Object, required: false },
    coin: { type: String, required: true },
    isFetchingAddress: { type: Boolean, required: true },
    showAddNewAddress: { type: Boolean, required: true },
  },
  data() {
    return {
      selectedAddress: {},
      newAddress: {},
      isAddNewAddress: false,
      maxlength: COMMON_CONST.MAX_LENGTH_INPUT,
      maxLength20: COMMON_CONST.MAX_LENGTH_20,
      isCancelAdd: false,
      isSubmitNewAddress: false,
      network: 'erc20',
    };
  },
  watch: {
    showAddNewAddress: function (newVal, oldVal) {
      this.isAddNewAddress = newVal;
    },
    network() {
      this.resetErrors();
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    handleCloseModal() {
      this.$emit('close');
      if (this.withdrawAddressParent) {
        this.selectedAddress = Object.assign({}, this.selectedAddress, {
          blockchain_address: this.withdrawAddressParent.blockchain_address || '',
          blockchain_sub_address: this.withdrawAddressParent.blockchain_sub_address || '',
          wallet_name: this.withdrawAddressParent.wallet_name || '',
          network: this.network || 'erc20',
        });
      }
    },
    handleSelectAddress() {
      this.$emit('onSelect', this.selectedAddress);
    },
    selectAddress(address, subAddress, name, network) {
      this.selectedAddress = Object.assign({}, this.selectedAddress, {
        blockchain_address: address || '',
        blockchain_sub_address: subAddress || '',
        wallet_name: name || '',
        network: network || '',
      });
    },
    handleAddNewAddress() {
      this.isAddNewAddress = true;
    },
    handleCancelNewAddress() {
      this.isAddNewAddress = false;
      if (
        _.isEmpty(this.newAddress.wallet_name) &&
        _.isEmpty(this.newAddress.blockchain_address) &&
        _.isEmpty(this.newAddress.blockchain_sub_address)
      ) {
        this.isCancelAdd = false;
      } else {
        this.isCancelAdd = true;
      }
    },
    handleGoBackNewAddress() {
      this.isAddNewAddress = true;
      this.isCancelAdd = false;
    },
    handleDiscardNewAddress() {
      this.newAddress = {};
      this.isAddNewAddress = false;
      this.isCancelAdd = false;
    },
    validate() {
      if (this.isAddNewAddress && _.isEmpty(this.newAddress.wallet_name)) {
        this.errors.add('wallet_name', this.$i18n.t('address.errors.label_required'));
      }
      if (_.isEmpty(this.newAddress.blockchain_address)) {
        this.errors.add('blockchain_address', this.$i18n.t('address.errors.address_required'));
      }
      if (this.coin === 'xrp' || this.coin === 'eos') {
        this.$refs.blockchain_sub_address.validate();
      }
      if (this.coin === 'xrp' || this.coin === 'eos') {
        if (
          this.newAddress.blockchain_address &&
          this.newAddress.blockchain_sub_address &&
          !Utils.isWalletAddress(
            this.coin,
            this.newAddress.blockchain_address,
            this.newAddress.blockchain_sub_address
          )
        ) {
          this.errors.add(
            'blockchain_address',
            this.$i18n.t('funds.withdrawals.errors.blockchain_address')
          );
        }
        if (this.isAddNewAddress) {
          this.withdrawAddresses.forEach((e) => {
            if (
              e &&
              e.wallet_address == this.newAddress.blockchain_address &&
              e.wallet_sub_address == this.newAddress.blockchain_sub_address
            ) {
              this.errors.add(
                'blockchain_address',
                this.$i18n.t('funds.withdrawals.errors.blockchain_address_sub_address_exists')
              );
              // this.errors.add('blockchain_sub_address', this.$i18n.t('funds.withdrawals.errors.blockchain_address_sub_address_exists'));
            }
          });
        }
      } else {
        if (!Utils.isWalletAddress(this.coin, this.newAddress.blockchain_address)) {
          this.errors.add(
            'blockchain_address',
            this.$i18n.t('funds.withdrawals.errors.blockchain_address')
          );
        }
        if (this.isAddNewAddress) {
          this.withdrawAddresses.forEach((e) => {
            if (e && e.wallet_address == this.newAddress.blockchain_address) {
              this.errors.add(
                'blockchain_address',
                this.$i18n.t('funds.withdrawals.errors.blockchain_address_exists')
              );
            }
          });
        }
      }
    },
    async addNewAddress() {
      this.isSubmitNewAddress = true;
      await this.validate();
      if (!this.errors.any()) {
        try {
          try {
            let valid = false;
            if (this.coin === 'busd') {
              valid = BUSDValidator.validate(this.newAddress.blockchain_address, this.coin, 'both');
            } else if (this.coin === 'usdt' && this.network === 'trc20') {
              if (
                this.newAddress.blockchain_address[0] === 'T' &&
                this.newAddress.blockchain_address.length == 34
              ) {
                valid = true;
              }
            } else {
              valid = WAValidator.validate(this.newAddress.blockchain_address, this.coin, 'both');
            }

            if (!valid) {
              this.errors.add('blockchain_address', this.$t('address.errors.blockchain_address'));
              this.isSubmitNewAddress = false;
              return false;
            }
          } catch (error) {
            Message.error(
              this.$t('address.errors.blockchain_address'),
              {},
              { position: 'top_right' }
            );
            this.isSubmitNewAddress = false;
            return;
          }

          // const res = await rf
          //   .getRequest('UserRequest')
          //   .validateBlockchainAddress(this.coin, this.newAddress.blockchain_address)

          const formData = new FormData();
          formData.append('coin', this.coin);
          formData.append('wallet_address', this.newAddress.blockchain_address);
          formData.append('wallet_sub_address', this.newAddress.blockchain_sub_address ?? '');
          formData.append('wallet_name', this.newAddress.wallet_name);
          formData.append('network', this.network);

          await rf.getRequest('UserRequest').updateOrCreateWithdrawalAddress(formData);
          Message.success(this.$t('funds.withdrawals.added_new_address'), {});
          this.newAddress = {};
          this.isAddNewAddress = false;
          this.isSubmitNewAddress = false;
          this.$emit('onSuccessAdd');
        } catch (error) {
          Message.error(error.response.data?.message, {}, { position: 'top_right' });
          this.isSubmitNewAddress = false;
          return;
        }
      } else {
        this.isSubmitNewAddress = false;
      }
    },
    resetErrors() {
      this.errors.clear();
    },
    resetErrorLabel() {
      this.errors.remove('wallet_name');
    },
    resetErrorAddress() {
      this.errors.remove('blockchain_address');
    },
    resetErrorTag() {
      this.errors.remove('blockchain_sub_address');
    },
    isNew(d1, d2) {
      const formatDate1 = moment(d1, 'YYYY-MM-DD HH:mm:ss')
        .add(1, 'days')
        .format('YYYY-MM-DD HH:mm:ss');
      const formatDate2 = moment(d2).format('YYYY-MM-DD HH:mm:ss');
      return moment(formatDate1).isAfter(formatDate2);
    },
  },
};
</script>

<style lang="scss" scoped>
.banks-modal {
  max-width: 448px;
  height: auto;
  margin: 0 auto;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  @include mobile {
    width: calc(100vw - 30px);
    border-radius: 10px;
    // height: 313px;
  }

  img.close-icon {
    // margin-right: 30px;
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 24px;
    right: 12px;
    top: 12px;
  }

  .modal-tit {
    color: $text-main;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-top: 24px;
    text-transform: capitalize;
  }

  .modal-body {
    padding: 10px 24px 24px;

    @include mobile {
      padding: 16px;
    }

    :deep(.content) {
      div {
        font-size: 18px;
        font-weight: 300;
        line-height: 32px;
        color: $text-main;
        margin-top: 24px;
        margin-bottom: 8px;

        @include mobile {
          strong {
            line-height: 24px;
          }

          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
        }

        &:first-child {
          margin-top: 0;
        }
      }

      ol {
        padding-left: 24px;
        list-style-position: outside;
        margin-bottom: 0;

        li {
          margin-bottom: 8px;
          color: $text-main;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          @include mobile {
            strong {
              line-height: 24px;
            }

            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

.addresses-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-bottom: 16px;
  max-height: calc(100vh - 300px);
  overflow-y: scroll;

  &.scrolled {
    max-height: 200px;
  }

  .address-box-modal {
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    cursor: pointer;

    .wallet-name-container {
      display: flex;
      align-items: center;
      column-gap: 4px;
    }

    .wallet-name {
      font-weight: 700;
      color: #23212c;
    }

    .new-badge {
      font-weight: 700;
      color: #108cdd;
      text-transform: uppercase;
    }

    .wallet-address {
      font-weight: 500;
      color: #67666e;
      word-wrap: break-word;
      width: 100%;
    }

    .checked {
      display: none;
    }

    &.selected {
      background-color: #e7f3fc;
      border-color: #108cdd;

      .checked {
        display: block;
      }
    }
  }
}

.form-group-btn {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  button {
    @include btn-common(contained, sm);
  }
}

.new-address {
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;

  label {
    font-weight: 500;
  }

  .title {
    text-align: center;
    color: $text-main;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 16px;
  }

  .input-group {
    display: flex;
    flex-direction: column;

    .error {
      margin-bottom: 4px;
    }
  }

  .form-control {
    border: 1px solid $color-grey-border;
    border-radius: 0;
    height: 42px;
    outline: none;
    background: $color-white;
  }

  .form-input {
    border: 1px solid #dddddd;
    padding: 10px 16px;
    border-radius: 10px;
    background: #ffffff;
    display: flex;
    width: 100%;
    position: relative;
    background-color: transparent;

    &:focus {
      border-color: $color-jungle-green;
    }

    &.error {
      border: 1px solid $color-red;
      background: rgba(255, 91, 91, 0.1);
    }
  }

  .wallet-name,
  .wallet-address {
    flex: 1;
  }

  .sub-char {
    width: 15px;
    text-align: center;
    padding-top: 8px;
  }

  .error-box {
    width: 100%;
    margin: 5px 0;

    .is-error {
      display: inline-block;
    }
  }

  @include mobile-small {
    flex-direction: column;

    .wallet-name,
    .wallet-address {
      width: 100%;
    }
  }
}

.discard-new-address {
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;

  label {
    font-weight: 500;
  }

  .title {
    text-align: center;
    color: $text-main;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 8px;
  }

  .text {
    text-align: center;
    color: $text-secondary;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 8px;
  }
}

.add-new-address-button {
  width: 100%;
  padding: 16px;
  border: 2px dashed #dddddd;
  border-radius: 8px;
  text-align: center;
  color: #9f9ea3;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  margin-bottom: 16px;
}

.form-group-btn-address {
  padding-top: 8px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  column-gap: 10px;

  .contained {
    @include btn-common(contained, sm);
    font-size: 14px;
    font-weight: 700;
  }

  .bordered {
    @include btn-common(outlined, sm);
    font-size: 14px;
    font-weight: 700;
  }
}

.invalid-feedback {
  margin-top: 0 !important;
}

.loader {
  display: block;
  margin: auto;
  border: 5px solid $color-grey-concrete;
  border-radius: 50%;
  border-top: 5px solid $bg-main1;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}
</style>
