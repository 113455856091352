import rf from "@/request/RequestFactory";
import BigNumber from "bignumber.js";

const actions = {
  getPositions({ commit }, params) {
    return new Promise((resolve, reject) => {
      rf.getRequest("MarginRequest")
        .getPositions(params)
        .then((res) => {
          const data = res.data && Array.isArray(res.data) ? res.data : [];
          const count = _.filter(data, (item) =>
            parseInt(item.current_qty)
          ).length;
          commit("updatePositions", data);
          commit("updatePositionCount", count);
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getPositionRequest({}, params) {
    return rf.getRequest("MarginRequest").getPositions(params);
  },
  updateLeverage({ commit }, params) {
    return new Promise((resolve, reject) => {
      rf.getRequest("MarginRequest")
        .updateLeverage(params)
        .then((res) =>
          rf
            .getRequest("MarginRequest")
            .getPositions({
              is_mam: params.is_mam,
              is_investment: params.is_investment,
              account: params.account,
            })
        )
        .then((res) => {
          commit("updatePositions", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateMargin({ commit }, params) {
    return new Promise((resolve, reject) => {
      rf.getRequest("MarginRequest")
        .updateMargin(params)
        .then((res) => rf.getRequest("MarginRequest").getPositions())
        .then((res) => {
          commit("updatePositions", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateRiskLimit({ commit }, params) {
    return new Promise((resolve, reject) => {
      rf.getRequest("MarginRequest")
        .updateRiskLimit(params)
        .then((res) => rf.getRequest("MarginRequest").getPositions())
        .then((res) => {
          commit("updatePositions", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const getters = {
  currentPosition: (state, getters) => {
    const data = state.positions.find((el) => el.symbol == state.currentSymbol);
    return data
      ? data
      : {
          current_qty: 0,
          entry_price: 0,
          entry_value: 0,
          init_margin: 0,
          is_cross: 1,
          leverage: getters.currentInstrument?.risk_limit,
          maint_margin: 0,
          open_order_buy_qty: 0,
          open_order_buy_value: 0,
          open_order_sell_qty: 0,
          open_order_sell_value: 0,
          risk_limit: getters.currentInstrument?.risk_limit,
          risk_value: 0,
          pnl_ranking: 0,
          required_init_margin_percent: getters.currentInstrument?.init_margin,
          required_maint_margin_percent:
            getters.currentInstrument?.maint_margin,
          symbol: state.currentSymbol,
        };
  },
  positionCount: (state) => {
    return state.positionCount;
  },
  position: (state, getters) => {
    return getters.currentPosition;
  },
  positions: (state) => {
    return state.positions;
  },
  closedPosition: (state) => {
    return state.positions;
  },
};

const mutations = {
  updatePositions: (state, payload) => {
    state.positions = [...payload];
  },

  updatePositionCount: (state, payload) => {
    state.positionCount = payload;
  },

  //Update any position by symbol itself, if there is no symbol found we use current symbol instead
  //Example: this.updateCurrentPosition({symbol: 'BTCUSD', 'risk_limit': 12 ...}) => update any properties you want to
  //or this.updateCurrentPosition({'risk_limit': 12}) => update properties finding by current symbol
  updatePosition: (state, position) => {
    if (!(state.positions && Array.isArray(state.positions)))
      state.positions = [];

    const index = state.positions.findIndex(
      (el) => el.symbol == position.symbol
    );
    if (index >= 0) {
      // if (new BigNumber(position.current_qty || 0).eq('0')) {
      //     state.positions.splice(index, 1);
      // } else {
      state.positions[index] = position;
      // }
    } else {
      // if (!(new BigNumber(position.current_qty || 0).eq('0'))) {
      state.positions.push(position);
      // }
    }

    state.positions = [...state.positions];
  },
};

export default {
  actions,
  getters,
  mutations,
  state: {
    positions: [],
    positionCount: 0,
  },
};
