<template>
  <div>
    <transition name="modal" v-if="isUnregisteredSession && showModal">
      <div class="modal-mask">
        <div class="modal-wrapper" v-click-outside="clickOut">
          <div class="modal-container">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="clickOut"
            >
              <img src="/images/close.svg" alt="close" />
            </button>
            <div class="modal-body">
              <p name="body" v-html="$t('unregistered_session.text')"></p>
            </div>
            <div class="modal-footer">
              <div name="footer" class="text-center btn-control">
                <a href="#" @click="login" class="btn-login">{{
                  $t('unregistered_session.login')
                }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import AuthenticationUtils from '@/common/AuthenticationUtils'
export default {
  name: 'UnregisteredSessionDialog',
  data() {
    return {
      isUnregisteredSession: false,
      showModal: true,
    }
  },
  methods: {
    login() {
      window.location.href = '/login?destination=' + this.$route.path
    },
    clickOut() {
      this.showModal = false
      AuthenticationUtils.removeAuthenticationData()
      this.login()
    },
  },
  created() {
    this.$on('UserSessionRegistered', (data) => {
      this.isUnregisteredSession = true
      localStorage.removeItem('access_token')
    })
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-container {
  background: $color-white;
  box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
  border-radius: 10px;
  max-width: 380px;
  width: 380px;
  padding: 24px;
  position: relative;

  @include mobile {
    width: calc(100vw - 32px);
  }

  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    opacity: 1;
  }

  .modal-body {
    padding: 0;
    text-align: center;
    margin-bottom: 16px;
    display: block;

    p {
      margin: 0;
      font-size: 14px;
      line-height: 24px;
      font-weight: 700;
      color: $text-main;
    }
  }

  .modal-footer {
    display: block;
    border: none;
    padding: 0;
    display: block;
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .btn-login {
    background: $bg-main6;
    color: white;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    border-radius: 60px;
    display: inline-block;
    width: 100%;
    padding: 9px 32px;

    &:hover,
    &:active,
    &:focus {
      background: #ddd;
      color: $text-main;
    }
  }
}
</style>
