<template>
  <div id="open-order" :class="{ no_padding: options && options.isNoPadding }">
    <data-table
      ref="datatable"
      :get-data="getData"
      :msg-empty-data="$t('order.open_order.no_data')"
      class=""
      id="order-table"
      :limit="10"
    >
      <th
        v-for="(item, index) in headerTable"
        :title="$t(item.name)"
        :key="index"
        :data-sort-field="item.id"
      >
        {{ $t(item.name)
        }}{{ item.name === "order.open_order.filled" ? "%" : null }}
      </th>
      <th class="cancel-box text-right">
        <cancel-box @action-cancel-box="actionCancelOpenOrder" />
      </th>
      <template slot="body" slot-scope="props">
        <tr>
          <td class="cl_date" :title="props.item.created_at | timestampToDate">
            {{ props.item.created_at | timestampToDate }}
          </td>
          <td
            :title="`${props.item.coin} / ${props.item.currency}` | uppercase"
          >
            {{ props.item.coin | uppercase }}/{{
              props.item.currency | uppercase
            }}
          </td>
          <td :title="props.item.type | uppercaseFirst">
            {{ props.item.type | uppercaseFirst }}
          </td>
          <td
            :class="{
              blue: props.item.trade_type === 'buy',
              red: props.item.trade_type === 'sell',
            }"
            :title="props.item.trade_type | tradeType"
          >
            {{ props.item.trade_type | tradeType }}
          </td>
          <td>
            <span
              v-if="
                props.item.type == 'market' || props.item.type == 'stop_market'
              "
              :title="$t('order.open_order.market')"
            >
              {{ $t("order.open_order.market") }}</span
            >
            <span
              v-else
              :title="
                props.item.price
                  | formatCurrencyAmount(props.item.currency, '0')
              "
            >
              {{
                props.item.price
                  | formatCurrencyAmount(props.item.currency, "0")
              }}
            </span>
          </td>
          <td
            :title="
              props.item.quantity
                | toNumber
                | formatCurrencyAmount(props.item.coin, '0')
            "
          >
            {{
              props.item.quantity
                | toNumber
                | formatCurrencyAmount(props.item.coin, "0")
            }}
          </td>
          <td
            :title="
              props.item.executed_quantity
                | toNumber
                | divBigNumber(props.item.quantity)
                | floatToPercent
            "
          >
            {{
              props.item.executed_quantity
                | toNumber
                | divBigNumber(props.item.quantity)
                | floatToPercent
            }}
          </td>
          <td
            :title="
              props.item.price
                | mulBigNumber(props.item.quantity)
                | formatCurrencyAmount(props.item.currency, '0')
            "
          >
            {{
              props.item.price
                | mulBigNumber(props.item.quantity)
                | formatCurrencyAmount(props.item.currency, "0")
            }}
          </td>
          <td>
            {{ props.item.stop_condition | convertConditionStatus }}
            {{
              props.item.base_price
                | formatCurrencyAmount(props.item.currency, "0")
            }}
          </td>
          <td class="t-right">
            <button
              class="btn-cancel btn-page-open"
              @click="actionCancelOpenOrder('one', props.item.id)"
            >
              <i class="icon-close"></i>
            </button>
          </td>
        </tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import OPEN_ORDER_HEADER_CONST from "./DataTableInit.js";
import COMMON_CONST from "@/common/Const";
import rf from "@/request/RequestFactory";
import CancelBox from "@/components/shared_components/common/CancelBox";

export default {
  components: {
    CancelBox,
  },
  props: {
    options: {
      type: Object,
      default: null,
    },
    coin: {
      type: String,
      default: COMMON_CONST.DEFAULT_COIN,
    },
    currency: {
      type: String,
      default: COMMON_CONST.DEFAULT_CURRENCY,
    },
  },
  data() {
    return {
      headerTable: OPEN_ORDER_HEADER_CONST.OPEN_ORDER_HEADER,
      commonConst: COMMON_CONST,
      isHide: false,
      deleteType: COMMON_CONST.DEFAULT,
    };
  },
  methods: {
    isIE() {
      let ua = navigator.userAgent;
      var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
      if (is_ie && $("table").length > 0) {
        $("#open-order table").addClass("fixieCancelbox-full");
      }
    },
    getData(params) {
      if (!window.isAuthenticated) {
        return new Promise((resolve) => {
          return resolve({});
        });
      }
      return rf.getRequest("OrderRequest").getOrdersPending(params);
    },
    actionCancelOpenOrder(deleteType = null, id = null) {
      if (
        (!deleteType && this.deleteType === this.commonConst.DEFAULT) ||
        this.$refs.datatable.rows.length === 0
      ) {
        return;
      }
      const type = deleteType ? deleteType : this.deleteType;
      this.confirmCancelOrder(deleteType, id);
    },
    confirmCancelOrder(type, id = "") {
      let confirmationMessage = "";
      switch (type) {
        case "one":
          confirmationMessage = this.$i18n.t(
            "order.open_order.cancel_one_message"
          );
          break;
        case "all": {
          confirmationMessage = this.$i18n.t(
            "order.open_order.cancel_all_message"
          );
          break;
        }
        case "limit": {
          confirmationMessage = this.$i18n.t(
            "order.open_order.cancel_limit_price_message"
          );
          break;
        }
        case "stop_limit": {
          confirmationMessage = this.$i18n.t(
            "order.open_order.cancel_stop_limit_price_message"
          );
          break;
        }
        case "stop_market": {
          confirmationMessage = this.$i18n.t(
            "order.open_order.cancel_stop_market_price_message"
          );
          break;
        }
        default:
          return;
      }
      ConfirmationModal.show({
        type: "primary",
        title: "",
        content: confirmationMessage,
        btnCancelLabel: this.$i18n.t("common.action.no"),
        btnConfirmLabel: this.$i18n.t("common.action.yes"),
        onConfirm: () => {
          this.cancelOrder(type, id);
        },
        onCancel: function () {},
      });
    },
    cancelOrder(type, id = "") {
      this.isShowConfirmDialog = false;
      let message = this.$i18n.t("order.open_order.cancel_orders_success");
      switch (type) {
        case "one":
          message = this.$i18n.t("order.open_order.cancel_order_success");
          this.requestHandler(
            rf.getRequest("OrderRequest").cancel(id),
            message
          );
          break;
        case "all":
          this.requestHandler(
            rf.getRequest("OrderRequest").cancelAll(),
            message
          );
          break;
        default:
          this.requestHandler(
            rf.getRequest("OrderRequest").cancelByType({ type: type }),
            message
          );
          break;
      }
    },
    requestHandler(promise, message) {
      promise
        .then((res) => {
          this.showSuccessMessage(message);
        })
        .catch((error) => {
          if (!error.response) {
            this.showErrorMessage(this.$i18n.t("common.message.network_error"));
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    showSuccessMessage(message) {
      this.$refs.datatable.refresh();
      Message.success(message, {}, { position: "bottom_left" });
    },
    showErrorMessage(message) {
      Message.error(message, {}, { position: "bottom_left" });
    },
  },
  mounted() {
    this.isIE();
  },
};
</script>

<style lang="scss" scoped>
#order-table {
  // overflow: auto;
  th.cancel-box {
    padding-right: 25px;
    overflow: inherit !important;

    &:after {
      display: none;
    }
  }

  table {
    border: 0px;

    thead {
      background-color: $color-white;
      border: 0px;

      tr {
        height: 48px;

        th {
          font-family: $font-family-roboto-medium;
          font-size: $font-small;
          padding: 8px 0px 8px 20px;

          &:last-child {
            padding: 8px 20px 8px 0px;
          }
        }
      }
    }

    tbody {
      text-align: left;

      tr {
        &.canceled {
          opacity: 0.3;
        }

        height: auto;
        font-size: $font-small;

        td {
          font-size: $font-root;
          font-weight: 500;
          color: $color-grey-dark;
          font-family: $font-family-roboto-medium;
          line-height: 21px;
          padding: 7px 0px 6px 20px;
          overflow: initial;
          vertical-align: middle;

          &:last-child {
            text-align: left;
            padding: 7px 20px 6px 0px;
          }
        }
      }
    }

    .btn-cancel {
    }

    .btn-page-open.btn-cancel {
      margin-right: 40px;
      color: $color-jungle-green;
      font-family: $font-family-roboto;
      display: block;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      font-size: $font-small;
      float: right;

      &:hover {
        color: $color-eden;
      }
    }

    .btn-page-open.btn-confirm {
      &:hover {
        color: $color-eden;
      }
    }
  }
}

.blue {
  color: $color-jungle-green;
}

.red {
  color: #f74940;
}
</style>
