<template>
  <div id="fee-tier-program">
    <div class="title-fee-tier">{{ $t("fee_tier.title") }}</div>
    <div class="content">
      <div class="fee-tier-guide">
        <div class="step" v-for="item in feeTierGuide" :key="item.step">
          <div class="step-icon">
            <img :src="item.icon" :alt="item.icon" />
          </div>
          <div class="step-title">{{ item.step }}. {{ $t(item.title) }}</div>
          <div class="step-description">{{ $t(item.description) }}</div>
        </div>
      </div>
      <div class="fee-tier-overview">
        <div class="avatar">
          <img src="/images/icon/avatar.png" alt="avatar" />
        </div>
        <div class="overview-grid">
          <!-- Spot -->
          <div class="grid-item">
            <div class="grid-item-title">
              {{ $t("fee_tier.detail.tier") }}
              {{ feeTierInfo !== null ? feeTierInfo.spot.level : "--" }}
            </div>
            <div
              class="grid-item-description"
              v-html="$t('fee_tier.overview.current_tier', { type: 'Spot' })"
            />
            <!-- {{ $t("fee_tier.overview.current_tier", { type: "Spot" }) }} -->
            <!-- </div> -->
          </div>
          <div class="grid-item">
            <div class="grid-item-title">
              {{
                feeTierInfo !== null
                  ? fixedNumber(feeTierInfo.spot.adv_estimated)
                  : "--"
              }}
              USDT
            </div>
            <div
              class="grid-item-description"
              v-html="
                $t('fee_tier.overview.current_volume_trading', { type: 'Spot' })
              "
            />
          </div>
          <div class="grid-item">
            <div class="grid-item-title">
              {{ $t("fee_tier.detail.tier") }}
              {{
                feeTierInfo !== null ? feeTierInfo.spot.level_estimated : "--"
              }}
            </div>
            <div
              class="grid-item-description"
              v-html="$t('fee_tier.overview.estimate_tier', { type: 'Spot' })"
            />
          </div>
          <div class="grid-item">
            <div class="grid-item-title">
              {{
                feeTierInfo !== null
                  ? fixedNumber(feeTierInfo.spot.adv_estimated_next_tier)
                  : "--"
              }}
              USDT
            </div>
            <div
              class="grid-item-description"
              v-html="
                $t('fee_tier.overview.amount_of_volume', { type: 'Spot' })
              "
            />
          </div>
          <div class="grid-item">
            <div class="grid-item-title">
              {{ periodTimeLeft }}
            </div>
            <div
              class="grid-item-description"
              v-html="$t('fee_tier.overview.period_time_left')"
            />
          </div>
          <div
            class="grid-item"
            v-if="feeTierInfo !== null && feeTierInfo.special_list_bonus"
          >
            <div class="grid-item-title">
              {{ feeTierInfo.special_list_bonus }}%
            </div>
            <div
              class="grid-item-description"
              v-html="$t('fee_tier.overview.special_bonus')"
            />
          </div>
          <!-- <div class="grid-item mobile">
            <div class="grid-item-title">
              {{ periodTimeLeft }}
            </div>
            <div
              class="grid-item-description"
              v-html="$t('fee_tier.overview.period_time_left')"
            />
          </div> -->
        </div>
      </div>
      <div class="fee-tier-history">
        <div class="grid-history">
          <div class="grid-header">
            <div class="title">{{ $t("fee_tier.volume_history.title") }}</div>
            <div class="export" @click="exportVolumeHistory">
              <img src="/images/icon/export.svg" alt="icon-export" />
              <span>{{ $t("fee_tier.export") }}</span>
            </div>
          </div>
          <div class="volume-history">
            <div class="tab-history">
              <span
                class="tab-item"
                :class="{ active: tabVolumeHistorySelected === 'spot' }"
                @click="tabVolumeHistorySelected = 'spot'"
                >{{ $t("fee_tier.spot_ex") }}</span
              >
            </div>
            <div class="content-tab">
              <VolumeHistoryTable
                :data="volumeHistory"
                :feeTierStatus="feeTierStatus"
                @changePage="getFeeTierDalyVolumeHistory"
                :loadingDailyVolume="loadingDailyVolume"
              />
            </div>
          </div>
        </div>
        <div class="grid-history">
          <div class="grid-header">
            <div class="title">{{ $t("fee_tier.trade_history.title") }}</div>
            <div class="export" @click="exportTradeHistory">
              <img src="/images/icon/export.svg" alt="icon-export" />
              <span>{{ $t("fee_tier.export") }}</span>
            </div>
          </div>
          <div class="trade-history">
            <div class="tab-history">
              <span
                class="tab-item"
                :class="{ active: tabTradeHistorySelected === 'spot' }"
                @click="tabTradeHistorySelected = 'spot'"
                >{{ $t("fee_tier.spot_ex") }}</span
              >
            </div>
            <div class="content-tab">
              <TradeHistoryTable
                :data="tradeHistory"
                :type="tabTradeHistorySelected"
                @changePage="changePageTradeHistory"
                :loadingTradeHistory="loadingTradeHistory"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="fee-tier-details">
        <div class="last-period">
          <div class="title">
            {{ $t("fee_tier.last_period_program_detail") }}
          </div>
          <ol>
            <li>{{ $t("fee_tier.last_period_program_detail.text_1") }}</li>
          </ol>
          <div class="exchanges" v-if="feeTierInfo">
            <div class="exchange">
              <div class="exchange-name">{{ $t("fee_tier.spot_ex") }}</div>
              <SelectBox
                :options="tokens"
                :propertyOption="'name'"
                v-model="lastPeriod.tokenSelected"
              />
              <DetailFeeTierTable
                :data="lastPeriodSpotDetail"
                :currentLevel="feeTierInfo.spot.level"
                :loadingLastDetails="loadingLastDetails"
                nameDetails="Last Details"
              />
            </div>
          </div>
        </div>
        <div class="current-period">
          <div class="title">
            {{ $t("fee_tier.current_period_program_detail") }}
          </div>
          <ol>
            <li>{{ $t("fee_tier.current_period_program_detail.text_1") }}</li>
          </ol>
          <div class="exchanges">
            <div class="exchange">
              <div class="exchange-name">{{ $t("fee_tier.spot_ex") }}</div>
              <SelectBox
                :options="tokens"
                :propertyOption="'name'"
                v-model="currentPeriod.tokenSelected"
              />
              <DetailFeeTierTable
                :data="currentPeriodSpotDetail"
                :loadingCurrentDetails="loadingCurrentDetails"
                nameDetails="Current Details"
              />
            </div>
          </div>
        </div>
        <div class="notice-wrap">
          <div class="notice">{{ $t("fee_tier.notice") }}</div>
          <div class="notice-detail">{{ $t("fee_tier.notice.text_1") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";
import Utils from "@/common/Utils";
import SelectBox from "@/components/shared_components/common/SelectBox";
import VolumeHistoryTable from "@/components/shared_components/fee_tier/VolumeHistoryTable";
import TradeHistoryTable from "@/components/shared_components/fee_tier/TradeHistoryTable";
import DetailFeeTierTable from "@/components/shared_components/fee_tier/DetailFeeTierTable";
import moment from "moment";

export default {
  name: "FeeTierProgram",
  components: {
    SelectBox,
    VolumeHistoryTable,
    TradeHistoryTable,
    DetailFeeTierTable,
  },
  data() {
    return {
      feeTierGuide: [
        {
          step: 1,
          title: "fee_tier.guide.step_1_title",
          description: "fee_tier.guide.step_1_description",
          icon: "/images/icon/icon-trade.svg",
        },
        {
          step: 2,
          title: "fee_tier.guide.step_2_title",
          description: "fee_tier.guide.step_2_description",
          icon: "/images/icon/icon-chart.svg",
        },
        {
          step: 3,
          title: "fee_tier.guide.step_3_title",
          description: "fee_tier.guide.step_3_description",
          icon: "/images/icon/icon-increase-level.svg",
        },
        {
          step: 4,
          title: "fee_tier.guide.step_4_title",
          description: "fee_tier.guide.step_4_description",
          icon: "/images/icon/icon-money-alt.svg",
        },
      ],
      feeTierStatus: null,
      feeTierInfo: null,
      intervalId: null,
      durationTimeLeft: moment.duration(0, "days"),
      volumeHistory: {},
      tradeHistory: {},
      tabVolumeHistorySelected: "spot",
      tabTradeHistorySelected: "spot",
      tokens: [],
      contracts: [],
      lastPeriodSpotDetail: [],
      lastPeriodFutureDetail: [],
      lastPeriod: {
        tokenSelected: undefined,
        contractSelected: undefined,
      },
      currentPeriodSpotDetail: [],
      currentPeriodFutureDetail: [],
      currentPeriod: {
        tokenSelected: undefined,
        contractSelected: undefined,
      },
      loadingDailyVolume: true,
      loadingTradeHistory: true,
      loadingLastDetails: true,
      loadingCurrentDetails: true,
    };
  },
  created() {
    rf.getRequest("UserRequest")
      .getFeeTierStatus()
      .then((res) => {
        this.feeTierStatus = res.data;
        if (
          res.data.fee_tier_status === 0 ||
          moment.utc().isBefore(moment.utc(res.data.date_apply))
        ) {
          this.$router.push("/404");
        } else {
          this.fetchData();
        }
      });
  },
  computed: {
    periodTimeLeft() {
      const days = this.durationTimeLeft.days() || "0";
      const hour = this.durationTimeLeft.hours() || "0";
      const minute = this.durationTimeLeft.minutes() || "0";
      const sec = this.durationTimeLeft.seconds() || "0";
      if (days === "0" && hour === "0" && minute === "0" && sec === "0") {
        return "--";
      }
      return `${days}d ${hour}h ${minute}m ${sec}s`;
    },
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  mounted() {
    document.title = this.$t("fee_tier.title") + ` – ${this.tabTitle}`;
  },
  watch: {
    tabVolumeHistorySelected(newValue) {
      this.getFeeTierDalyVolumeHistory();
    },
    tabTradeHistorySelected(newValue) {
      switch (this.tabTradeHistorySelected) {
        case "spot":
          this.getFeeTierTradeSpotHistory();
          break;
        case "future":
          this.getFeeTierTradeFutureHistory();
          break;
        default:
          break;
      }
    },
    "lastPeriod.tokenSelected"(newValue) {
      this.getLastPeriodSpotDetail();
    },
    "lastPeriod.contractSelected"(newValue) {
      this.getLastPeriodFutureDetail();
    },
    "currentPeriod.tokenSelected"(newValue) {
      this.getCurrentPeriodSpotDetail();
    },
    "currentPeriod.contractSelected"(newValue) {
      this.getCurrentPeriodFutureDetail();
    },
  },
  methods: {
    async fetchData() {
      await this.getFeeTierInfo();
      await this.getFeeTierDalyVolumeHistory();
      await this.getFeeTierTradeSpotHistory();
      this.getTokens();
      this.getContracs();
    },
    fixedNumber(number) {
      if (!number || number == 0) {
        return "0";
      }
      if (number.split(".").length === 1) {
        return Utils.formatCurrencyAmount(Number(number).toFixed(0), "idr", 0);
      }
      return Utils.formatCurrencyAmount(
        Math.floor((Number(number) + Number.EPSILON) * 100) / 100,
        "idr",
        0
      );
    },
    getFeeTierInfo() {
      rf.getRequest("UserRequest")
        .getFeeTier()
        .then((res) => {
          this.feeTierInfo = res.data;
          if (
            moment
              .utc()
              .isBefore(moment.utc(this.feeTierInfo.period_time_left.end))
          ) {
            this.countdownTimeLeft();
          }
        });
    },
    countdownTimeLeft() {
      this.intervalId = setInterval(() => {
        this.updateCountdownPeriodTimeLeft();
      }, 1000);
    },
    updateCountdownPeriodTimeLeft() {
      const timeLeft = moment.utc(this.feeTierInfo.period_time_left.end);
      const now = moment.utc();
      this.durationTimeLeft = moment.duration(timeLeft.diff(now));
    },
    getFeeTierDalyVolumeHistory(page) {
      let params = {
        page: page || 1,
        limit: 10,
        exchange_type: this.tabVolumeHistorySelected === "spot" ? 1 : 2,
      };
      this.loadingDailyVolume = true;
      rf.getRequest("UserRequest")
        .getFeeTierDalyVolumeHistory(params)
        .then((res) => {
          if (res.success) {
            this.volumeHistory = res.data;
          }
        })
        .finally(() => {
          this.loadingDailyVolume = false;
        });
    },
    changePageTradeHistory(page) {
      switch (this.tabTradeHistorySelected) {
        case "spot":
          this.getFeeTierTradeSpotHistory(page);
          break;
        case "future":
          this.getFeeTierTradeFutureHistory(page);
          break;
        default:
          break;
      }
    },
    getFeeTierTradeSpotHistory(page) {
      let params = {
        page: page || 1,
        limit: 10,
      };
      this.loadingTradeHistory = true;
      rf.getRequest("UserRequest")
        .getFeeTierTradeSpotHistory(params)
        .then((res) => {
          if (res.success) {
            this.tradeHistory = res.data;
          }
        })
        .finally(() => {
          this.loadingTradeHistory = false;
        });
    },
    getFeeTierTradeFutureHistory(page) {
      let params = {
        page: page || 1,
        limit: 5,
      };
      rf.getRequest("UserRequest")
        .getFeeTierTradeFutureHistory(params)
        .then((res) => {
          if (res.success) {
            this.tradeHistory = res.data;
          }
        });
    },
    getTokens() {
      rf.getRequest("MasterdataRequest")
        .getAll()
        .then((res) => {
          this.tokens = res.market_settings.map((item) => {
            return {
              id: item.id,
              name: item.currency.toUpperCase(),
            };
          });
          this.lastPeriod.tokenSelected = this.tokens[0].name;
          this.currentPeriod.tokenSelected = this.tokens[0].name;
        });
    },
    getContracs() {
      rf.getRequest("MarginRequest")
        .getInstrument()
        .then((res) => {
          this.contracts = res.data.map((item) => {
            return {
              id: item.id,
              name: item.symbol.toUpperCase(),
            };
          });
          this.lastPeriod.contractSelected = this.contracts[0].name;
          this.currentPeriod.contractSelected = this.contracts[0].name;
        });
    },
    getLastPeriodSpotDetail() {
      let params = {
        exchange: 1,
        market: this.lastPeriod.tokenSelected.toLowerCase(),
        period: "last",
      };
      this.loadingLastDetails = true;
      rf.getRequest("UserRequest")
        .getFeeTierDetail(params)
        .then((res) => {
          this.lastPeriodSpotDetail = res.data;
        })
        .finally(() => {
          this.loadingLastDetails = false;
        });
    },
    getLastPeriodFutureDetail() {
      let params = {
        exchange: 2,
        market: this.lastPeriod.contractSelected.toLowerCase(),
        period: "last",
      };
      rf.getRequest("UserRequest")
        .getFeeTierDetail(params)
        .then((res) => {
          this.lastPeriodFutureDetail = res.data;
        });
    },
    getCurrentPeriodSpotDetail() {
      let params = {
        exchange: 1,
        market: this.currentPeriod.tokenSelected.toLowerCase(),
        period: "current",
      };
      this.loadingCurrentDetails = true;
      rf.getRequest("UserRequest")
        .getFeeTierDetail(params)
        .then((res) => {
          this.currentPeriodSpotDetail = res.data;
        })
        .finally(() => {
          this.loadingCurrentDetails = false;
        });
    },
    getCurrentPeriodFutureDetail() {
      let params = {
        exchange: 2,
        market: this.currentPeriod.contractSelected.toLowerCase(),
        period: "current",
      };
      rf.getRequest("UserRequest")
        .getFeeTierDetail(params)
        .then((res) => {
          this.currentPeriodFutureDetail = res.data;
        });
    },
    exportVolumeHistory() {
      let params = {
        timezone_offset: Utils.getTimzoneOffset(),
        exchange_type: this.tabVolumeHistorySelected === "spot" ? 1 : 2,
      };
      let filename =
        this.tabVolumeHistorySelected === "spot"
          ? "SpotDailyVolumeHistory_" + moment().format("YYYYMMDDHHmmss")
          : "FutureDailyVolumeHistory_" + moment().format("YYYYMMDDHHmmss");
      rf.getRequest("UserRequest").exportDailyVolumeHistory(params, filename);
    },
    exportTradeHistory() {
      switch (this.tabTradeHistorySelected) {
        case "spot":
          rf.getRequest("UserRequest").exportTradeSpotHistory(
            {
              timezone_offset: Utils.getTimzoneOffset(),
            },
            "SpotTradeHistory_" + moment().format("YYYYMMDDHHmmss")
          );
          break;
        case "future":
          rf.getRequest("UserRequest").exportTradeFutureHistory(
            {
              timezone_offset: Utils.getTimzoneOffset(),
            },
            "FutureTradeHistory_" + moment().format("YYYYMMDDHHmmss")
          );
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#fee-tier-program {
  max-width: 1370px;
  margin: 0 auto;
  padding: 24px 15px;

  .title-fee-tier {
    font-family: $helvetica;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: $text-main;
    margin-bottom: 24px;
  }

  .content {
    background: white;
    padding: 25px;
    border-radius: 10px;

    .fee-tier-guide {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 26px;

      @include tablet {
        grid-template-columns: 1fr;
      }

      @include mobile {
        grid-template-columns: 1fr;
      }

      .step {
        position: relative;
        padding: 16px 24px;
        background-color: $bg-main3;
        border: 1px solid $bg-main1;
        border-radius: 10px;

        .step-icon {
          position: relative;
          height: 50px;
          width: 50px;
          background: rgba(1, 3, 4, 0.1);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;

          img {
            width: 24px;
            height: auto;
          }
        }

        .step-title {
          font-family: $helvetica;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          color: $text-main;
          margin-top: 20px;
        }

        .step-description {
          font-family: $helvetica;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          color: $text-main-blur;
          margin-top: 5px;
        }
      }
    }

    .fee-tier-overview {
      border-radius: 10px;
      background: var(
        --gradient,
        linear-gradient(180deg, #010304 0%, rgba(65, 65, 65, 0.9) 100%)
      );
      padding: 25px 40px;
      margin-top: 24px;

      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 144px;
          height: auto;
          border-radius: 50%;
          background: transparent;
        }
      }

      .overview-grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 24px;
        margin-top: 20px;

        @include mobile {
          grid-template-columns: 1fr;
        }

        .grid-item {
          border-radius: 10px;
          text-align: center;

          &.desktop {
            @include mobile {
              display: none;
            }
          }

          &.mobile {
            @include desktop {
              display: none;
            }

            @include tablet {
              display: none;
            }
          }

          .grid-item-title {
            font-family: $helvetica;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
            color: white;
          }

          .grid-item-description {
            font-family: $helvetica;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
            color: white;
            opacity: 0.7;
            margin-top: 5px;
          }
        }
      }
    }

    .fee-tier-history {
      margin-top: 24px;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 12px));
      grid-gap: 24px;

      @include tablet {
        grid-template-columns: 1fr;
      }

      @include mobile {
        display: flex;
        flex-direction: column;
      }

      .grid-history {
        .grid-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 7px;

          @include mobile {
            align-items: flex-end;
          }

          .title {
            font-family: $helvetica;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            color: $text-main;
          }

          .export {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 7px;

            img {
              width: 18px;
              height: auto;
            }

            span {
              font-family: $helvetica;
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 22px;
              color: $bg-main1;
            }
          }
        }

        .volume-history,
        .trade-history {
          margin-top: 20px;

          .tab-history {
            .tab-item {
              cursor: pointer;
              display: inline-block;
              width: 142px;
              padding: 7px;
              background: $grey-1;
              font-family: $helvetica;
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: 24px;
              text-align: center;
              color: $text-main;
              border: solid 1px $bg-main2;
              border-bottom: none;
              border-radius: 10px 10px 0px 0px;

              &.active {
                background: white;
                color: $text-main;
              }
            }
          }

          .content-tab {
            background: white;
            border: solid 1px $grey-1;
            border-radius: 0 10px 10px 10px;

            :deep .table-wrapper {
              table {
                tbody tr {
                  td {
                    padding: 10px 10px 12px;
                    border-bottom: 1px solid $bg-main2;

                    &:first-child {
                      padding-left: 16px;
                    }

                    &:last-child {
                      padding-right: 16px;
                    }
                  }
                }

                thead {
                  background-color: $bg-main4;

                  tr {
                    th {
                      padding: 10px;
                      background-color: transparent;
                      border-bottom: none;

                      &:first-child {
                        padding-left: 16px;
                      }

                      &:last-child {
                        padding-right: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .fee-tier-details {
      .title {
        margin-top: 24px;
        font-family: $helvetica;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
        color: $text-main;
      }

      ol {
        margin-top: 4px;
        padding-left: 24px;

        li {
          list-style-type: disc;
          font-family: $helvetica;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          color: $text-main-blur;
        }
      }

      .notice-wrap {
        margin-top: 24px;
        background: $bg-main3;
        padding: 20px;
        border-radius: 5px;

        .notice {
          font-family: $helvetica;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          color: $text-main;
        }

        .notice-detail {
          font-family: $helvetica;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
          color: $text-secondary;
          margin-top: 8px;
        }
      }

      .exchanges {
        margin-top: 10px;

        .exchange {
          .exchange-name {
            font-family: $helvetica;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
            color: $text-secondary;
          }

          :deep(.sc_search_select) {
            margin-top: 8px;
            width: 200px;
            border-radius: 10px;
            border: solid 1px $bg-main2;

            .button_drop_search {
              padding: 10px 16px;

              span {
                font-size: 18px;
                font-style: normal;
                font-weight: 300;
                line-height: 32px;
                color: $text-main;
              }
            }

            .box_list_search_select {
              width: 100%;

              .full_name_coin_select {
                @include mobile {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  @include mobile {
    padding: 16px;

    .title-fee-tier {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }

    .content {
      padding: 24px 16px;

      .fee-tier-guide {
        .step {
          .step-title {
            margin-top: 16px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }

          .step-description {
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
          }

          .step-icon {
            width: 40px;
            height: 40px;
          }
        }
      }

      .fee-tier-overview {
        padding: 25px 16px;

        .avatar img {
          width: 80px;
          height: 80px;
        }

        .overview-grid {
          gap: 24px;
          grid-gap: 24px;

          .grid-item .grid-item-title {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }

          .grid-item .grid-item-description {
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
          }
        }
      }

      .fee-tier-history {
        .grid-history {
          .grid-header {
            .title {
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 32px;
            }
          }

          .trade-history,
          .volume-history {
            margin-top: 20px;

            .tab-history .tab-item {
              padding: 7px 20px;
              min-width: 142px;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
            }
          }
        }
      }

      .fee-tier-details {
        .current-period,
        .last-period {
          .title {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px;
          }

          ol {
            li {
              font-size: 12px;
              font-style: normal;
              font-weight: 300;
              line-height: 16px;
            }

            padding-left: 16px;
          }

          .exchanges .exchange {
            .exchange-name {
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
            }

            :deep(.sc_search_select) {
              margin-top: 8px;
              width: 100%;

              .button_drop_search {
                padding: 8px 16px;

                span {
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 22px;
                }
              }
            }
          }
        }

        .notice-wrap {
          padding: 20px;

          .notice {
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }

          .notice-detail {
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>
