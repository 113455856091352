<template>
  <div id="about-us">
    <div class="content">
      <h2>{{ $t("about_us.about_us") }}</h2>
      <p>{{ $t("about_us.about_us_content_1") }}</p>
      <p>{{ $t("about_us.about_us_content_2") }}</p>
      <p>{{ $t("about_us.about_us_content_3") }}</p>

      <h2>{{ $t("about_us.our_vision") }}</h2>
      <p>{{ $t("about_us.our_vision_content") }}</p>

      <h2>{{ $t("about_us.our_mission") }}</h2>
      <p>{{ $t("about_us.our_mission_content") }}</p>

      <h2>{{ $t("about_us.features") }}</h2>
      <p>{{ $t("about_us.features_content") }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  data() {
    return {};
  },
  mounted() {
    document.title = this.$t("about_us.about_us") + ` – ${this.tabTitle}`;
  },
};
</script>

<style lang="scss" scoped>
#about-us {
  max-width: 1370px;
  margin: 0 auto;

  .content {
    padding: 24px 16px;
    background-color: #f2f2f2;

    h2 {
      color: $text-main2;
      font-weight: 700;
      font-size: 24px;
      line-height: 40px;

      &:first-child {
        margin-top: 0;
      }
    }

    p {
      color: $text-secondary;
      font-size: 16px;
      line-height: 25px;
      font-weight: 400;
    }

    @include mobile {
      h2 {
        font-size: 18px;
        line-height: 24px;
      }

      p {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}
</style>
