import rf from "@/request/RequestFactory";

const actions = {
  getCompositeIndex({ commit }) {
    return new Promise((resolve, reject) => {
      rf.getRequest("MarginRequest")
        .getCompositeIndex()
        .then((res) => {
          commit("updateCompositeIndex", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getCompositeIndexRequest({}, params) {
    return rf.getRequest("MarginRequest").getCompositeIndex(params);
  },
};

const getters = {
  compositeIndex: (state) => {
    return state.compositeIndex;
  },
};

const mutations = {
  updateCompositeIndex: (state, payload) => {
    state.compositeIndex = { ...payload };
  },
};

const moduleName = "compositeIndex";
export default {
  actions,
  getters,
  mutations,
  state: {
    [moduleName]: {},
  },
};
