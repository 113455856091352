export default {
  methods: {
    resetError() {
      this.errors.clear();
    },
    convertRemoteErrors(error) {
      const errors = error.response.data.errors;
      Object.keys(errors).forEach((key) => {
        this.errors.add({
          field: key,
          msg: window.i18n.t(errors[key][0]),
        });
      });
      if (!this.errors.any()) {
        this.errors.add("error", window.i18n.t(error.response.data.message));
      }
    },
  },
};
