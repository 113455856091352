<template>
  <div
    id="open-history"
    class="page_history"
    :class="{
      no_padding: options && options.isNoPadding,
      'spot-order-history': isSpotExchangePage,
      'order-history': !isSpotExchangePage,
    }"
  >
    <template v-if="isSpotMobile">
      <div class="order-history__mobile-wrapper">
        <div class="group-control" v-if="!options">
          <date-tab @change-select-date="changeDate" ref="selectDateTab" />
          <div class="group-date">
            <div class="group-date__item">
              <span class="date-title">{{ $t('funds.history.from') }}</span>
              <datepicker
                :language="datePickerLang"
                :value="dateFromDefault"
                class="date-from"
                @selected="selectDateFrom"
                :format="customFormatter"
              ></datepicker>
            </div>
            <div class="group-date__item">
              <span class="date-title">{{ $t('funds.history.to') }}</span>
              <datepicker
                :language="datePickerLang"
                :value="dateToDefault"
                class="date-to"
                @selected="selectDateTo"
                :format="customFormatter"
              ></datepicker>
            </div>
          </div>
          <button class="button-search" @click="actionSearch">
            {{ $t('order.order_history.search') }}
          </button>
          <div class="checkbox-function">
            <label for="checkbox_open_order_id">
              <input
                type="checkbox"
                name="checkbox"
                v-model="isHideOtherPair"
                value="check"
                id="checkbox_open_order_id"
                @change="onOrderListUpdated(null, isHideOtherPair)"
              />
              <div>{{ $t('order.open_order.hide_other_pairs') }}</div>
            </label>
          </div>
        </div>
        <div
          class="group-control"
          v-if="options"
          :class="{ is_single_page: options && options.isNoPadding }"
        >
          <div class="group_1">
            <div class="group-date-picker">
              <div class="group-date">
                <div class="label-text">
                  {{ $t('order.order_history.from') }}
                </div>
                <datepicker
                  :language="datePickerLang"
                  :value="dateFromDefault"
                  class="date-from"
                  @selected="selectDateFrom"
                  :format="customFormatter"
                  input-class="date-picker-input"
                ></datepicker>
              </div>
              <div class="group-date">
                <div class="label-text">{{ $t('order.order_history.to') }}</div>
                <datepicker
                  :language="datePickerLang"
                  :value="dateToDefault"
                  class="date-to"
                  @selected="selectDateTo"
                  :format="customFormatter"
                  input-class="date-picker-input"
                ></datepicker>
              </div>
            </div>
            <div class="group-pair">
              <div class="content-search">
                <div class="label-text">
                  {{ $t('order.order_history.pair') }}
                </div>
                <div class="toolbar">
                  <div class="toolbar-element2">
                    <select-box
                      v-model="filters.coin"
                      :placeholder="$t('order.order_history.all')"
                      :options="getCoins()"
                    />
                  </div>
                  <span class="bw-element">/</span>
                  <div class="toolbar-element2">
                    <select-box
                      v-model="filters.currency"
                      :placeholder="$t('order.order_history.all')"
                      :options="getMarkets()"
                    />
                  </div>
                </div>
              </div>
              <div class="toolbar-element3">
                <div class="label-text">
                  {{ $t('order.order_history.side') }}
                </div>
                <select-box
                  v-model="filters.tradeType"
                  :placeholder="$t('order.order_history.all')"
                  :options="sideOptions"
                />
              </div>
            </div>

            <div class="group-search">
              <button class="button-search" @click="actionSearch">
                {{ $t('order.order_history.search') }}
              </button>
              <button class="button-reset" @click="actionReset">
                {{ $t('order.order_history.reset') }}
              </button>
            </div>
          </div>

          <div class="export-file">
            <div class="excel_export">
              <a
                @click.prevent="downloadReport()"
                @mouseenter="mouseOver()"
                @mouseleave="mouseOver()"
              >
                <img class="icon-export" alt="" src="/images/icon/export.svg" />
                <!-- <span class="icon-export" /> -->
                &nbsp;
                {{ $t('order.order_history.export_complete_order_history') }}
              </a>
              <!-- <div
              v-show="isShowToolTip"
              class="export-file-tooltip"
              @touchstart="isShowToolTip = false"
            >
              {{
                $t('order.order_history.export_complete_order_history_tooltip')
              }}
            </div> -->
            </div>
          </div>
        </div>
        <div class="order-history__mobile-body">
          <data-list-mobile
            ref="datatable"
            :msg-empty-data="$t('order.order_history.no_data')"
            :get-data="getData"
            :limit="10"
            :height="30"
          >
            <template slot="table-empty-header">
              <thead>
                <tr>
                  <th
                    v-for="(item, index) in headerTable"
                    :title="$t(item && item.name)"
                    :key="item.id"
                    :data-sort-field="item && item.id ? item.id : null"
                    :class="`cl-${index + 1}`"
                  >
                    <span>{{ $t(item.name) }}</span>
                  </th>
                  <th class="cl-11 th-status" title="$t('order.order_history.status')">
                    <span>{{ $t('order.order_history.status') }}</span>
                  </th>
                </tr>
              </thead>
            </template>
            <template slot="body" slot-scope="props">
              <div class="order-history__list-item">
                <div class="order-history__item-title">
                  <div class="item-title__wrapper">
                    <div v-if="!hiddenLogoCrypto" class="item-title__logo-wrapper">
                      <img
                        class="coin"
                        width="20"
                        height="20"
                        :src="STORAGE_URL + '/icons/' + props.item.coin + '.png'"
                      />
                      <img
                        class="currency"
                        width="20"
                        height="20"
                        :src="STORAGE_URL + '/icons/' + props.item.currency + '.png'"
                      />
                    </div>
                    <div>
                      {{ props.item.coin | uppercase }}/{{ props.item.currency | uppercase }}
                    </div>
                    <div
                      v-if="!hiddenLogoCrypto"
                      :class="{
                        blue: props.item.trade_type === 'buy',
                        red: props.item.trade_type === 'sell',
                      }"
                    >
                      {{
                        props.item.trade_type === 'buy'
                          ? $t('common.trade_type.buy')
                          : $t('common.trade_type.sell')
                      }}
                    </div>
                    <div class="item-title__date">
                      {{ _.get(props.item, 'updated_at') | timestampToDate }}
                    </div>
                  </div>
                </div>
                <div class="order-history__item-desc">
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{ localizeType(_.get(props.item, 'type')) | uppercaseFirst }}
                    </div>
                    <div class="item-desc__title" data-sort-field="type" @click="props.onSort">
                      {{ $t('order.open_order.type')
                      }}<i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper" v-if="hiddenLogoCrypto">
                    <div class="item-desc__value">
                      <div
                        :class="{
                          blue: props.item.trade_type === 'buy',
                          red: props.item.trade_type === 'sell',
                        }"
                      >
                        {{
                          props.item.trade_type === 'buy'
                            ? $t('common.trade_type.buy')
                            : $t('common.trade_type.sell')
                        }}
                      </div>
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="trade_type"
                      @click="props.onSort"
                    >
                      {{ $t('order.open_order.side')
                      }}<i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{
                        _.get(props.item, 'executed_price')
                          | formatCurrencyAmount(props.item.currency, '0')
                      }}
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="executed_price"
                      @click="props.onSort"
                    >
                      {{ $t('order.order_history.average')
                      }}<i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      <template
                        v-if="
                          _.get(props.item, 'type') == 'market' ||
                          _.get(props.item, 'type') == 'stop_market'
                        "
                        class="cl-6"
                        :title="$t('order.order_history.market')"
                      >
                        {{ $t('order.order_history.market') }}
                      </template>
                      <template
                        v-else
                        class="cl-6"
                        :title="
                          _.get(props.item, 'price')
                            | formatCurrencyAmount(_.get(props.item, 'currency'), '0')
                        "
                      >
                        {{
                          _.get(props.item, 'price')
                            | formatCurrencyAmount(_.get(props.item, 'currency'), '0')
                        }}
                      </template>
                    </div>
                    <div class="item-desc__title" data-sort-field="price" @click="props.onSort">
                      {{ $t('order.open_order.price')
                      }}<i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{
                        _.get(props.item, 'executed_quantity')
                          | toNumber
                          | formatCurrencyAmount(null, '0')
                      }}
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="executed_quantity"
                      @click="props.onSort"
                    >
                      {{ $t('order.open_order.filled')
                      }}<i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{ props.item.quantity | toNumber | formatCurrencyAmount(null, '0') }}
                    </div>
                    <div class="item-desc__title" data-sort-field="quantity" @click="props.onSort">
                      {{ $t('order.open_order.amount')
                      }}<i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{
                        _.get(props.item, 'executed_price')
                          | mulBigNumber(_.get(props.item, 'executed_quantity'))
                          | formatCurrencyAmount(_.get(props.item, 'currency'), '0')
                      }}
                    </div>
                    <div class="item-desc__title" data-sort-field="total" @click="props.onSort">
                      {{ $t('order.open_order.total')
                      }}<i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
                    </div>
                  </div>
                  <div
                    class="item-desc__wrapper"
                    :class="{ 'multiple__column-item': !hiddenLogoCrypto }"
                  >
                    <div class="item-desc__value">
                      {{ _.get(props.item, 'stop_condition') | convertConditionStatus }}
                      {{
                        _.get(props.item, 'base_price')
                          | formatCurrencyAmount(_.get(props.item, 'currency'), '')
                      }}
                    </div>
                    <div class="item-desc__title">
                      {{ $t('order.open_order.trigger_conditions') }}
                    </div>
                  </div>
                  <div
                    class="item-desc__wrapper"
                    :class="{ 'multiple__column-item': !hiddenLogoCrypto }"
                  >
                    <div
                      class="item-desc__status-btn"
                      :class="getStatusColor(props.item)"
                      v-if="!hiddenLogoCrypto"
                    >
                      {{ getStatus(props.item) }}
                    </div>
                    <template v-else>
                      <div class="item-desc__value" :class="getStatusColor(props.item)">
                        {{ getStatus(props.item) }}
                      </div>
                      <div class="item-desc__title">{{ $t('order.order_history.status') }}</div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </data-list-mobile>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="group-control" v-if="!options">
        <date-tab @change-select-date="changeDate" ref="selectDateTab" />
        <div class="group-date">
          <span class="title-date">{{ $t('funds.history.from') }}</span>
          <datepicker
            :language="datePickerLang"
            :value="dateFromDefault"
            class="date-from"
            @selected="selectDateFrom"
            :format="customFormatter"
            tabindex="0"
          ></datepicker>
          <span class="title-date">{{ $t('funds.history.to') }}</span>
          <datepicker
            :language="datePickerLang"
            :value="dateToDefault"
            tabindex="0"
            class="date-to"
            @selected="selectDateTo"
            :format="customFormatter"
          ></datepicker>
        </div>
        <button class="btn-search" @click="actionSearch" tabindex="0">
          {{ $t('order.order_history.search') }}
        </button>
      </div>

      <div
        class="group-control"
        v-if="options"
        :class="{ is_single_page: options && options.isNoPadding }"
      >
        <div class="group_1">
          <div class="group-date-picker">
            <div class="group-date">
              <div class="label-text">{{ $t('order.order_history.from') }}</div>
              <datepicker
                :language="datePickerLang"
                :value="dateFromDefault"
                class="date-from"
                @selected="selectDateFrom"
                :format="customFormatter"
                input-class="date-picker-input"
              ></datepicker>
            </div>
            <div class="group-date">
              <div class="label-text">{{ $t('order.order_history.to') }}</div>
              <datepicker
                :language="datePickerLang"
                :value="dateToDefault"
                class="date-to"
                @selected="selectDateTo"
                :format="customFormatter"
                input-class="date-picker-input"
              ></datepicker>
            </div>
          </div>
          <div class="group-pair">
            <div class="content-search">
              <div class="label-text">{{ $t('order.order_history.pair') }}</div>
              <div class="toolbar">
                <div class="toolbar-element2">
                  <select-box
                    v-model="filters.coin"
                    :placeholder="$t('order.order_history.all')"
                    :options="getCoins()"
                  />
                </div>
                <span class="bw-element">/</span>
                <div class="toolbar-element2">
                  <select-box
                    v-model="filters.currency"
                    :placeholder="$t('order.order_history.all')"
                    :options="getMarkets()"
                  />
                </div>
              </div>
            </div>
            <div class="toolbar-element3">
              <div class="label-text">{{ $t('order.order_history.side') }}</div>
              <select-box
                v-model="filters.tradeType"
                :placeholder="$t('order.order_history.all')"
                :options="sideOptions"
              />
            </div>
          </div>

          <div class="group-search">
            <button class="button-search" @click="actionSearch">
              {{ $t('order.order_history.search') }}
            </button>
            <button class="button-reset" @click="actionReset">
              {{ $t('order.order_history.reset') }}
            </button>
          </div>
        </div>

        <div class="export-file">
          <div class="excel_export">
            <a
              @click.prevent="downloadReport()"
              @mouseenter="mouseOver()"
              @mouseleave="mouseOver()"
            >
              <img class="icon-export" alt="" src="/images/icon/export.svg" />
              &nbsp;
              {{ $t('order.order_history.export_complete_order_history') }}
            </a>
          </div>
        </div>
      </div>

      <div class="data-table">
        <data-table3
          ref="datatable"
          :getData="getData"
          :msg-empty-data="$t('order.order_history.no_data')"
          class="datatable"
          id="order-table"
          :limit="10"
          :height="20"
        >
          <template slot-scope="props">
            <th
              v-for="(item, index) in headerTable"
              :title="$t(item && item.name)"
              :key="item.id"
              :data-sort-field="item && item.id ? item.id : null"
              :class="`cl-${index + 1}`"
              @click="props.echoclick"
            >
              <span>{{ $t(item.name) }}</span>
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
            <th
              class="cl-11 th-status"
              title="Status"
              data-sort-field="status"
              @click="props.echoclick"
            >
              <span><i v-html="props.innerHtmlSort" class="group-sort-tabl3"></i></span>
              <span>{{ $t('order.order_history.status') }}</span>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
          </template>
          <template slot="body" slot-scope="props">
            <tr :class="{ canceled: _.get(props.item, 'status') === 'canceled' }">
              <td class="cl-1 cl_date" :title="_.get(props.item, 'updated_at') | timestampToDate">
                {{ _.get(props.item, 'updated_at') | timestampToDate }}
              </td>
              <td
                class="cl-2"
                :title="`${_.get(props.item, 'coin')}/${_.get(props.item, 'currency')}` | uppercase"
              >
                <span v-if="!hiddenLogoCrypto">
                  <img
                    class="coin"
                    width="20"
                    height="20"
                    :src="STORAGE_URL + '/icons/' + props.item.coin + '.png'"
                  />
                  <img
                    class="currency"
                    width="20"
                    height="20"
                    :src="STORAGE_URL + '/icons/' + props.item.currency + '.png'"
                  />
                </span>
                <span>
                  {{ _.get(props.item, 'coin') | uppercase }}/{{
                    _.get(props.item, 'currency') | uppercase
                  }}
                </span>
              </td>
              <td class="cl-3" :title="localizeType(_.get(props.item, 'type')) | uppercaseFirst">
                {{ localizeType(_.get(props.item, 'type')) | uppercaseFirst }}
              </td>
              <td
                class="cl-4 status"
                :class="{
                  blue: _.get(props.item, 'trade_type') === 'buy',
                  red: _.get(props.item, 'trade_type') === 'sell',
                }"
              >
                {{
                  props.item.trade_type === 'buy'
                    ? $t('common.trade_type.buy')
                    : $t('common.trade_type.sell')
                }}
              </td>
              <td
                classs="cl-5"
                :title="
                  _.get(props.item, 'executed_price')
                    | formatCurrencyAmount(_.get(props.item, 'currency'), '0')
                "
              >
                {{
                  _.get(props.item, 'executed_price')
                    | formatCurrencyAmount(props.item.currency, '0')
                }}
              </td>

              <td
                v-if="
                  _.get(props.item, 'type') == 'market' ||
                  _.get(props.item, 'type') == 'stop_market'
                "
                class="cl-6"
                :title="$t('order.order_history.market')"
              >
                {{ $t('order.order_history.market') }}
              </td>
              <td
                v-else
                class="cl-6"
                :title="
                  _.get(props.item, 'price')
                    | formatCurrencyAmount(_.get(props.item, 'currency'), '0')
                "
              >
                {{
                  _.get(props.item, 'price')
                    | formatCurrencyAmount(_.get(props.item, 'currency'), '0')
                }}
              </td>
              <!-- td column market -->

              <td
                class="cl-7"
                :title="
                  _.get(props.item, 'executed_quantity')
                    | toNumber
                    | formatCurrencyAmount(null, '0')
                "
              >
                {{
                  _.get(props.item, 'executed_quantity')
                    | toNumber
                    | formatCurrencyAmount(null, '0')
                }}
              </td>
              <td
                class="cl-8"
                :title="props.item.quantity | toNumber | formatCurrencyAmount(null, '0')"
              >
                {{ props.item.quantity | toNumber | formatCurrencyAmount(null, '0') }}
              </td>
              <td
                class="cl-9"
                :title="
                  _.get(props.item, 'executed_price')
                    | mulBigNumber(_.get(props.item, 'executed_quantity'))
                    | formatCurrencyAmount(_.get(props.item, 'currency'), '0')
                "
              >
                {{
                  _.get(props.item, 'executed_price')
                    | mulBigNumber(_.get(props.item, 'executed_quantity'))
                    | formatCurrencyAmount(_.get(props.item, 'currency'), '0')
                }}
              </td>
              <td class="cl-10">
                {{ _.get(props.item, 'stop_condition') | convertConditionStatus }}
                {{
                  _.get(props.item, 'base_price')
                    | formatCurrencyAmount(_.get(props.item, 'currency'), '')
                }}
              </td>
              <td class="cl-11 t-right2" :class="getStatusColor(props.item)">
                {{ getStatus(props.item) }}
              </td>
            </tr>
          </template>
        </data-table3>
      </div>
    </template>
  </div>
</template>

<script>
import { en, id } from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';
import DateTab from '../common/DateTab.vue';
import OPEN_ORDER_HEADER_CONST from './DataTableInit.js';
import COMMON_CONST from '@/common/Const';
import rf from '@/request/RequestFactory';
import moment from 'moment';
import Utils from '@/common/Utils';
import SelectBox from '@/components/shared_components/common/SelectBox';
export default {
  components: {
    DateTab,
    Datepicker,
    SelectBox,
  },
  props: {
    isSpotMobile: Boolean,
    options: {
      type: Object,
      default: null,
    },
    coinCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_COIN,
    },
    currencyCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_CURRENCY,
    },
    hiddenLogoCrypto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      datePickerLang: this.$i18n.locale === 'id' ? id : en,
      isHideOtherPair: false,
      headerTable: OPEN_ORDER_HEADER_CONST.ORDER_HISTORY_HEADER,
      commonConst: COMMON_CONST,
      sideOptions: [],
      dateFromDefault: moment().subtract(6, 'days').toDate(),
      dateToDefault: moment().toDate(),
      dateFrom: moment().subtract(6, 'days').startOf('day').format('x'),
      dateTo: moment().endOf('day').format('x'),
      coins: [],
      coinMasters: [],
      isShowCoinOption: false,
      filters: {
        coin: '',
        currency: '',
        tradeType: '',
      },
      durationDateFrom: null,
      isHide: false,
      isShowToolTip: false,
      coin: this.coinCode,
      currency: this.currencyCode,
      fetchingOrderHistory: false,
    };
  },
  computed: {
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === 'storage_url').value;
    },
    isSpotExchangePage() {
      return this.$route.path === '/spot-exchange/basic';
    },
  },
  methods: {
    refreshTable() {
      this.$refs.datatable.refresh();
    },
    localizeType(type) {
      if (type == 'limit') return this.$t('order.order_form.limit');
      if (type == 'market') return this.$t('orders.open_order.market');
      if (type == 'stop_market') return this.$t('order.order_form.stop_market');
      if (type == 'stop_limit') return this.$t('order.order_form.stop_limit');
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        SpotOrderListUpdated: this.onOrderListUpdated,
      };
    },
    onSpotSelectedPairMarket(pair) {
      this.isHideOtherPair = false;
      this.coin = pair.coin;
      this.currency = pair.currency;
    },
    onOrderListUpdated(data, isHideOtherPair) {
      if (!this.fetchingOrderHistory) {
        this.fetchingOrderHistory = true;
        this.getData().then((res) => {
          if (isHideOtherPair) {
            this.$refs.datatable.filter({
              coin: this.coin,
              currency: this.currency,
            });
          } else {
            this.$refs.datatable.refresh();
          }
        });
      }
    },
    changeDate(currentDateTab) {
      switch (currentDateTab.id) {
        case this.commonConst.ONE_DAY:
          this.durationDateFrom = moment().startOf('day').format('x');
          break;
        case this.commonConst.ONE_WEEK:
          this.durationDateFrom = moment().subtract(6, 'days').startOf('day').format('x');
          break;
        case this.commonConst.ONE_MONTH:
          this.durationDateFrom = moment()
            .subtract(1, 'months')
            .add('days', 1)
            .startOf('day')
            .format('x');
          break;
        default:
          this.durationDateFrom = moment()
            .subtract(3, 'months')
            .add('days', 1)
            .startOf('day')
            .format('x');
      }
      const params = {
        start_date: this.durationDateFrom,
        end_date: moment().endOf('day').format('x'),
      };
      this.$refs.datatable.filter(params);
    },
    getData(params) {
      if (!isAuthenticated) {
        return new Promise((resolve) => {
          return resolve({});
        });
      }
      const dateRange = this.getDefaultDateRange();
      params = Object.assign({}, dateRange, params);
      return rf
        .getRequest('OrderRequest')
        .getOrderHistory(params)
        .then((res) => {
          this.fetchingOrderHistory = false;
          // return orderHistory;
          return res;
        });
    },
    getStatus(order) {
      if (order.status == 'canceled') {
        return this.$t('common.order_status.canceled');
      }

      if (order.executed_quantity == order.quantity) {
        return this.$t('common.order_status.filled');
      }

      return this.$t('common.order_status.partial_filled');
    },
    getStatusColor(order) {
      if (order.status == 'canceled') {
        return 'color-red';
      }

      if (order.executed_quantity == order.quantity) {
        return 'color-green';
      }
      return 'color-green';
    },
    getDefaultDateRange() {
      return {
        start_date: this.dateFrom,
        end_date: moment().endOf('day').format('x'),
      };
    },
    showError(message) {
      Message.error(message, {}, { position: 'top_right' });
    },
    actionSearch() {
      if (this.dateFrom > this.dateTo) {
        this.showError(this.$t('common.date.warning'));
        return;
      }
      this.$refs.datatable.filter(this.filterParams());
      if (!this.options) {
        this.$refs.selectDateTab.resetSelectDate();
      }
    },
    filterParams() {
      const filterParams = {
        start_date: this.dateFrom,
        end_date: this.dateTo,
      };
      if (this.filters.coin) {
        filterParams.coin =
          this.filters.coin.toLowerCase() === 'all' ? undefined : this.filters.coin.toLowerCase();
      }
      if (this.filters.currency) {
        filterParams.currency =
          this.filters.currency.toLowerCase() === 'all' ? undefined : this.filters.currency;
      }
      if (this.filters.tradeType) {
        filterParams.trade_type =
          this.filters.tradeType.toLowerCase() === 'all' ? undefined : this.filters.tradeType;
      }
      return filterParams;
    },

    async actionReset() {
      await Promise.all([
        (this.filters.coin = ''),
        (this.filters.currency = ''),
        (this.filters.tradeType = ''),
        (this.dateFromDefault = moment().subtract(6, 'days').toDate()),
        (this.dateToDefault = moment().toDate()),
        (this.dateFrom = moment().subtract(6, 'days').startOf('day').format('x')),
        (this.dateTo = moment().endOf('day').format('x')),
        $('.datatable').find('th').attr('data-sort-order', ''),
      ]);

      this.actionSearch();
    },
    selectDateFrom(dateFrom) {
      this.dateFrom = moment(dateFrom).startOf('day').format('x');
    },
    selectDateTo(dateTo) {
      this.dateTo = moment(dateTo).endOf('day').format('x');
    },
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },
    clickOut() {
      this.isShowCoinOption = false;
    },
    showCoinOption() {
      this.isShowCoinOption = true;
      this.coins = this.coinMasters;
    },
    downloadReport() {
      const params = {
        ...this.filterParams(),
        timezone_offset: Utils.getTimzoneOffset(),
      };
      rf.getRequest('OrderRequest').downloadTransactions(
        params,
        'OrderHistory_' + moment().format('YYYYMMDDHHmmss')
      );
    },
    mouseOver() {
      this.isShowToolTip = !this.isShowToolTip;
    },
    getCoins() {
      let coins = [];
      coins = this.$store.state.masterdata.coins.map((coin) => {
        return {
          id: coin.coin,
          name: coin.coin.toUpperCase(),
        };
      });
      coins.unshift({
        id: 'all',
        name: this.$t('order.order_history.all'),
      });
      return coins;
    },
    getMarkets() {
      let markets = [];
      markets = this.$store.state.masterdata.market_settings.map((market) => {
        return {
          id: market.currency,
          name: market.currency.toUpperCase(),
        };
      });
      markets.unshift({
        id: 'all',
        name: this.$t('order.order_history.all'),
      });
      return markets;
    },
    initSideOptions() {
      this.sideOptions = this.commonConst.SELECT_SIDE.map((option) => {
        return {
          id: option.id,
          name: this.$t(option.name),
        };
      });
      this.sideOptions.unshift({
        id: 'all',
        name: this.$t('order.order_history.all'),
      });
    },
  },
  mounted() {
    this.initSideOptions();
    this.$on('UPDATED_LOCALE', () => {
      this.initSideOptions();
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/common.scss';
#SpotExchange_page
  #order-group-block
  #order-group
  #open-history
  .order-history__mobile-wrapper
  .group-control {
  #date-tab {
    margin-left: unset;
    :deep ul {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      li a {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
#open-history .order-history__mobile-wrapper {
  padding: 8px 16px 16px;
  & .group-control {
    flex-wrap: nowrap;
    flex-direction: column;

    .group-date {
      justify-content: space-between;
      gap: 16px;
      .group-date__item {
        display: flex;
        flex-direction: column;
        gap: 4px;
        .date-title {
          color: $text-secondary;
          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
        }
        :deep .vdp-datepicker input {
          width: 100% !important;
        }
      }
    }
    .checkbox-function {
      display: flex;
      label {
        display: flex;
        margin: unset;
        margin-top: auto;
        margin-bottom: auto;
        position: relative;
        & input {
          margin: unset;
          width: 24px;
          height: 24px;
          border: 1px solid $bg-main2 !important;
          border-radius: 4px;
          &[type='checkbox']:checked + div::before {
            content: '';
            display: block;
            position: absolute;
            text-align: center;
            height: 24px;
            width: 24px;
            left: 0;
            background-color: $bg-main1;
            border-radius: 4px;
          }
          &[type='checkbox']:checked + div::after {
            content: url('@/assets/images/spot_exchange/checkbox-tick.svg');
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
        & div {
          color: $text-dark-1;
          margin-left: 16px;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }
  & .order-history__mobile-body {
    margin-top: 32px;
    & .data-list__wrapper {
      overflow: auto;
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .order-history__list-item {
      padding: 16px;
      border-radius: 10px;
      background-color: $bg-main4;
      .order-history__item-title {
        display: flex;
        justify-content: space-between;
        .item-title__wrapper {
          .item-title__logo-wrapper {
            img:nth-child(2) {
              margin-left: -8px;
            }
          }
          & > div {
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
          }
          display: flex;
          & > div:nth-child(2) {
            color: $text-main;
            width: 70px;
            font-weight: 300;
            margin-left: 6px;
          }
          & > div:nth-child(3) {
            margin-left: 4px;
          }
          & > div:nth-child(4) {
            margin-left: 11px;
          }
          & .item-title__date {
            margin-top: auto;
            margin-bottom: auto;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 16px;
            color: $text-main-blur;
          }
          & div.blue {
            color: $color-green;
            font-weight: 700;
          }
          & div.red {
            color: $color-red;
            font-weight: 700;
          }
        }
      }
      .order-history__item-desc {
        margin-top: 16px;
        display: grid;
        gap: 8px;
        row-gap: 16px;
        grid-template-columns: repeat(6, 1fr);
        .item-desc__wrapper {
          max-width: 100%;
          grid-column: span 2;
          text-overflow: ellipsis;
          overflow: hidden;
          &.multiple__column-item {
            grid-column: span 3;
          }
          &:nth-child(3n + 3) {
            text-align: end;
          }
          .item-desc__title {
            margin-top: 3px;
            white-space: nowrap;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            color: $text-main-blur;
          }
          .item-desc__value {
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            min-height: 22px;
            line-height: 22px;
            color: $text-main;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &.color-red {
              font-weight: 700 !important;
              color: $color-red;
            }
            &.color-green {
              color: $color-green;
              font-weight: 700 !important;
            }
            &.blue {
              font-weight: 700 !important;
              color: $color-green;
            }
            &.red {
              font-weight: 700 !important;
              color: $color-red;
            }
          }

          .item-desc__status-btn {
            margin-left: auto;
            padding: 8px 12px;
            min-width: 80px;
            border-radius: 100px;
            max-width: 100%;
            width: fit-content;
            text-align: center;
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            &.red {
              color: $text-white;
              background-color: $color-red;
            }
            &.color-red {
              color: $text-white;
              background-color: $color-red;
            }
            &.color-green {
              background-color: $color-green;
              color: $color-white;
            }
            &.blue {
              background-color: $color-green;
              color: $color-white;
            }
          }
        }
      }
    }
  }
}
.cl-1 {
  min-width: 165px;
}
.cl-2 {
  min-width: 133px;
  .currency {
    margin-left: -5px;
  }
}
.cl-3 {
  min-width: 100px;
}
.cl-4 {
  min-width: 89px;
}
.cl-5 {
  min-width: 130px;
}
.cl-6 {
  min-width: 115px;
}
.cl-7 {
  min-width: 88px;
}
.cl-8 {
  min-width: 108px;
}
.cl-9 {
  min-width: 125px;
}
.cl-10 {
  min-width: 119px;
}
.cl-11 {
  min-width: 78px;
}
#open-history {
  .group-control {
    display: flex;
    gap: 15px;
    margin-top: 8px;
    flex-wrap: wrap;
    margin-bottom: 11px;
    .title-date {
      color: $text-secondary;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-top: auto;
      margin-bottom: auto;
    }
    .btn-search {
      display: flex;
      padding: 7px 24px;
      height: 38px;
      border-radius: 60px;
      background: $bg-main1;
      color: $color-white;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
    }
    .group_1 {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;

      .group-date-picker {
        display: flex;
        gap: 15px;

        .label-text {
          @include text-font($helvetica, $text-secondary, 14px, 400, 24px);
          margin-bottom: 7px;
        }

        .vdp-datepicker:deep() input {
          border-radius: 5px;
          font-weight: 300;
          height: 38px !important;
          letter-spacing: normal !important;
          background-position: top 7px right 16px !important;
          border: none;
          padding: 7px 50px 7px 16px !important;
          background-image: url('@/assets/images/spot_exchange/calendar-icon.svg') !important;
          background-size: 24px 24px;
          color: $text-main;
        }
      }
      .group-search {
        display: flex;
        align-items: flex-end;
        gap: 15px;

        .button-search,
        .button-reset {
          padding: 7px 24px;
          border: unset;
          border-radius: 60px;
          font-size: 14px;
          color: $text-main;
          font-weight: 700;
          line-height: 24px;
        }
        .button-search {
          background-color: $bg-main1;
          color: $text-white;
          // &:hover {
          //   background-color: $primary-1;
          // }
        }
        .button-reset {
          padding: 6px 24px;
          background-color: transparent;
          border: 1px solid $bg-main1;
          // &:hover {
          //   color: $color-white;
          //   background-color: $primary-1;
          // }
        }
      }
      .group-pair {
        display: flex;
        gap: 15px;

        .label-text {
          @include text-font($helvetica, $text-secondary, 14px, 400, 24px);
          margin-bottom: 7px;
        }
        .content-search {
          .toolbar {
            display: flex;
            align-items: center;
            gap: 7px;
            .bw-element {
              font-size: 14px;
              font-weight: 300;
              line-height: 22px;
              color: $text-main;
            }
          }
          // Left coin
          .toolbar-element {
            .coin-input {
              position: relative;
              input {
                border: 1px solid $grey-1;
                border-radius: 10px;
                width: 84px;
                height: 32px;
                padding: 0 10px;
              }
              .box-coin-option {
                position: absolute;
                top: calc(100% + 2px);
                width: 100%;
                left: 0;
                z-index: 2;

                .coin-option {
                  display: flex;
                  flex-direction: column;
                  border-radius: 3px;
                  list-style-type: none;
                  padding: 10px 0px;
                  box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
                  background: $color-white;

                  border-radius: 10px;
                  .option {
                    padding: 7px 14px;
                    cursor: pointer;
                    @include text-font($helvetica, $dark-1, 14px, 500, 20px);
                    &:hover {
                      background: $grey-3;
                    }
                  }
                }
              }
            }
          }
          // Right coin
          .toolbar-element2 {
            .group_search_select {
              .button_drop_search {
                padding: 7px 14px;
                background-color: $color-white;
                border: 1px solid $grey-1;
                border-radius: 10px;
                height: 32px;
              }
            }
          }
        }
      }
    }

    .export-file {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      white-space: nowrap;
      @include mobile() {
        justify-content: flex-start;
      }
      .excel_export {
        position: relative;
        @include text-font($helvetica, $text-dark-1, 16px, 300, 24px);
        margin-top: auto;
      }
      a {
        display: flex;
        @include text-font($helvetica, $text-dark-1, 16px, 300, 24px);
      }
      .export-file-tooltip {
        white-space: nowrap;
        position: absolute;
        padding: 6px 8px;
        top: -50px;
        right: 0;
        background: rgba(0, 0, 0, 0.75);
        border-radius: 2px;
        @include text-font($helvetica, $color-white, 14px, 400, 25px);

        &:before {
          content: '';
          position: absolute;
          bottom: -4px;
          right: 42px;
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid rgba(0, 0, 0, 0.75);
        }

        @include mobile() {
          left: 0;
          right: unset;
          &:before {
            right: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
  }

  .data-table {
    min-height: 270px;
    border-radius: 10px;
    background-color: $color-white;
    overflow-x: auto;

    :deep() .empty-data {
      .empty-noti {
        min-height: auto !important;
      }
      .text-wrapper {
        gap: 0;
      }
      td {
        padding-top: 90px !important;
        padding-bottom: 113px !important;
        border-bottom: 0 !important;
      }
    }
    :deep() {
      th,
      td {
        font-family: $helvetica !important;
      }
    }
  }

  :deep() #order-table {
    // padding: 10px 21px;
    min-width: fit-content !important;
    max-width: 100% !important;
    min-height: 270px;

    thead {
      background-color: $bg-main4;
    }

    // table > * > * > * {
    //   &:first-child {
    //     padding-left: 0 !important;
    //   }
    //   &:last-child {
    //     padding-right: 0 !important;
    //     text-align: right;
    //   }
    // }

    thead tr th {
      padding: 8px;
      @include text-font($helvetica, $text-secondary, 14px, 700, 24px);
      white-space: nowrap;
      border-bottom: none;
      &:first-child {
        padding-left: 22px;
      }
      &:last-child {
        padding-right: 22px;
      }
      &.th-status {
        text-align: end;
      }
    }

    // tbody tr:first-child td {
    //   padding-top: 10px;
    // }

    tbody {
      tr.canceled td:nth-child(11) {
        color: $color-red;
      }
      td {
        vertical-align: bottom;
        padding: 12px 8px;
        white-space: nowrap;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        border-bottom: 1px solid $bg-main2;
        color: $text-main;
        &:first-child {
          padding-left: 22px;
        }
        &:last-child {
          padding-right: 22px;
        }
        &.color-red {
          color: $color-red;
        }
        &.color-green {
          color: $color-green;
        }
        &.blue {
          color: $color-green;
        }
        &.red {
          color: $color-red;
        }
        &.blue,
        &.red,
        &.color-green,
        &.color-red {
          font-weight: 700;
        }
      }
    }
  }
}

@include tablet-d {
  #open-history {
    .group-control {
      flex-wrap: wrap;
      margin-bottom: 15px;

      .group_1 {
        flex-wrap: wrap;
        width: 100%;

        // Datepickers
        .group-date-picker {
          width: 100%;
        }

        // Pair & Side dropdowns
        .group-pair,
        .group-type {
          // Pair
          .content-search {
            gap: 10px;
          }
        }
      }

      // Export file
      .export-file {
        justify-content: flex-start;

        .export-file-tooltip {
          left: 0;
          right: auto;
          &::before {
            left: 42px;
            right: auto;
          }
        }
      }
    }

    #order-table {
      > :nth-child(2) {
        // position: sticky;
        // left: 20px;
        // width: fit-content;
      }
    }
  }
}

@include mobile {
  #open-history {
    .group-control {
      .group_1 {
        // Datepickers
        .group-date-picker {
          .group-date {
            flex: 1;

            .vdp-datepicker {
              display: block;
              &.date-to :deep() .vdp-datepicker__calendar {
                right: 0%;
                left: auto;
                &::before {
                  right: 5%;
                  left: auto;
                }
              }
              :deep() input {
                width: 100% !important;
              }
            }
          }
        }

        // Search * Reset buttons
        .group-search {
          gap: 10px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#open-history {
  #order-table {
    border-radius: 10px;
  }
  .group-control.is_single_page {
    display: flex;
    .toolbar-element3 .sc_search_select .group_search_select .button_drop_search {
      width: 140px;
    }
    .sc_search_select {
      .group_search_select {
        .button_drop_search {
          min-width: 100px;
          @include mobile {
            min-width: 90px;
          }
          background-color: $color-white;
          padding: 7px 16px;
          height: 38px;
          border-radius: 5px;
          border: none;
          & span,
          i {
            font-weight: 300;
            line-height: 22px;
          }
          span,
          .icon-arrow1 {
            font-size: 14px;
            color: $text-main;
          }
        }
        .box_list_search_select {
          min-width: 90px;

          width: 100%;

          ul {
            li {
              &:hover {
                background-color: $grey-3;
              }
              span,
              a {
                color: $dark-1;
              }
              span {
                color: $text-main !important;
                font-family: $helvetica;
                font-size: 14px;
                font-weight: 300;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
    .vdp-datepicker {
      input {
        padding: 5px 3px 5px 40px !important;
        background-position: top 8px left 14px !important;
        width: 150px !important;
        background-color: $color-white !important;
        margin-right: 0px !important;
        line-height: 22px;
        color: $text-main !important;
        &:focus {
          border: 1px solid $color-alto !important;
        }
      }
    }
  }
}

@include tablet-d {
  #SpotExchange_page #order-group-block #order-group {
    display: grid;
    #navi-tab {
      min-width: unset;
      width: unset;
    }

    .order-content {
      min-width: unset;
    }
  }
}
</style>
