import BaseModelRequest from "./base/BaseModelRequest";

export default class CurrencyCoinRequest extends BaseModelRequest {
  getModelName() {
    return "currency-coins";
  }
  getDecimalCoin(currency) {
    return this.get(`/get-decimal/${currency}`);
  }
}
