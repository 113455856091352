<template>
  <div class="page_container" id="account">
    <change-password
      ref="changePassComponent"
      :userData="user"
      @disableOtpSuccess="disableOtpSuccess"
    />
    <otp-modal
      name="otp_modal"
      :submit="updateModeWhitelist"
      :params="getParamsUpdateModeWhitelist()"
      :is-disable-button="isDisableButton"
      :contact_email="siteSetting.contact_email"
    />
    <cancel-google-auth-modal
      @CancelGoogleAuthModal:CLOSE="hideCancelGoogleAuthModal"
    ></cancel-google-auth-modal>
    <enable-google-authentication @EnableGoogleAuthentication:CLOSE="hideEnableGoogleAuthenticaion">
    </enable-google-authentication>
    <lost-otp-modal
      :contact_email="siteSetting.contact_email"
      name="lostOtpForm"
      @disableOtpSuccess="disableOtpSuccess"
    />
    <add-bank-modal name="addBankForm" :kyc="kyc" @addBankSuccess="addBankSuccess" />
    <confirmation-modal>
      <template slot="content">
        <div class="checkbox-bank">
          <input type="checkbox" id="c-bank" v-model="isCreateNewBankAccount" />
          <label for="c-bank">{{ $t('refferal.confirm_bank_account_content') }}</label>
        </div>
      </template>
    </confirmation-modal>
    <change-phone-modal @ChangePhoneModal:CLOSE="hideChangePhoneModal"></change-phone-modal>
    <div v-show="overlay" id="overlay"></div>
    <div class="account_container">
      <p class="title-medium-small title-header">{{ $t('menu.account') }}</p>
      <div class="path_acount_container">
        <div class="account_info">
          <div class="left_acc_info">
            <div class="img-user"><img src="/images/default-avatar.svg" /></div>
            <div class="sub_acc_info">
              <div class="email_acc" v-if="kyc.full_name && user.security_level >= 3">
                {{ kyc.full_name }}
              </div>
              <div
                class="email"
                style="margin-bottom: 1vh"
                v-if="typeof kyc.ward == 'object' && user.is_corporate === 'true'"
              >
                {{ kyc.ward[0].tdp }}
              </div>
              <div class="email">{{ user.email }}</div>
              <div class="phone">
                {{ user.phone_no }}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  @click="showChangePhoneModal()"
                >
                  <g clip-path="url(#clip0_605_43199)">
                    <path
                      d="M4.66602 4.66602H3.99935C3.64573 4.66602 3.30659 4.80649 3.05654 5.05654C2.80649 5.30659 2.66602 5.64573 2.66602 5.99935V11.9993C2.66602 12.353 2.80649 12.6921 3.05654 12.9422C3.30659 13.1922 3.64573 13.3327 3.99935 13.3327H9.99935C10.353 13.3327 10.6921 13.1922 10.9422 12.9422C11.1922 12.6921 11.3327 12.353 11.3327 11.9993V11.3327"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M13.59 4.39007C13.8526 4.12751 14.0001 3.77139 14.0001 3.40007C14.0001 3.02875 13.8526 2.67264 13.59 2.41007C13.3274 2.14751 12.9713 2 12.6 2C12.2287 2 11.8726 2.14751 11.61 2.41007L6 8.00007V10.0001H8L13.59 4.39007Z"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.666 3.33398L12.666 5.33398"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_605_43199">
                      <rect width="16" height="16" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div class="lv_content" :class="kyc.status">
                <div class="lv_text">
                  <img src="/images/star.svg" alt="star-level" />
                  <span
                    >{{ $t('account.lv') }}.{{ user.security_level }} {{ $t('account.user') }}</span
                  >
                </div>
                <div
                  v-if="
                    kyc.status != 'pending' && kyc.status != 'verified' && kyc.status != 'rejected'
                  "
                  class="btn_unverified btn_common"
                >
                  <span>{{ $t('account.btn_unverified') }}</span>
                </div>
                <div v-if="kyc.status == 'verified'" class="btn_common btn_verified">
                  <span>{{ $t('account.btn_verified') }}</span>
                </div>
                <div v-if="kyc.status == 'pending'" class="btn_common btn_pending">
                  <span>{{ $t('account.btn_verifying') }}</span>
                </div>
                <div v-if="kyc.status == 'rejected'" class="btn_common btn_rejected">
                  <span>{{ $t('account.btn_rejected') }}</span>
                </div>
              </div>
              <div class="text_account">
                <div class="text_account__left">
                  <span>{{ $t('account.last_login_time') }}: </span>
                  <span v-show="lastLogin?.created_at">{{
                    lastLogin?.created_at | timestampToDate
                  }}</span>
                </div>
                <div class="text_account__right">
                  <span>{{ $t('account.ip') }}: </span>
                  <span>{{ lastLogin?.ip_address }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="right_acc_info">
            <div class="withdraw_limit_map">
              <div class="road_lv">
                <div
                  v-for="(item, index) in withdrawalLimit"
                  :key="index"
                  class="item_road"
                  :class="{
                    step_active: userSecurityLevel >= item.security_level,
                  }"
                >
                  <div class="text_milestone">
                    <div class="title_step">
                      <span>{{ $t('account.daily_limit_withdraw') }}</span>
                      <div class="description">
                        {{ item.description }}
                      </div>
                      <div class="nb_read_item">
                        {{ item?.security_level == 4 ? 'Above' : '' }}
                        {{ item.daily_limit | formatCurrencyAmount('idr', '0') }}
                        IDR
                      </div>
                    </div>
                  </div>
                  <div class="milestone">
                    <div class="level">
                      <div>
                        <img src="/images/star.svg" alt="star-level" />
                        <span
                          :class="{
                            last_step: index == withdrawalLimit.length - 1,
                          }"
                          >{{ $t('account.lv') }}.{{ item.security_level }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="submit_contact_container"
              :class="{
                'single-child': kyc?.status !== 'pending' && kyc?.status !== 'rejected',
              }"
            >
              <div class="submit_contact">
                <span v-if="!kyc || !kyc.status">
                  <img src="/images/link.svg" alt="star-level" class="link" />
                  <a class="submit" @click="onClickedVerifyGoogleAuthen(kycAccountRouteName)">
                    {{ $t('account.submit') }}
                  </a>
                </span>
                <span v-if="kyc.status === 'pending' || kyc.status === 'rejected'">
                  <img src="/images/link.svg" alt="star-level" class="link" />
                  <a class="submit" @click="onClickedVerifyGoogleAuthen(kycAccountRouteName)">
                    <template v-if="kyc.status === 'rejected'">
                      {{ $t('account.change_doc') }}
                    </template>
                    <template v-else-if="kyc.status === 'pending'">
                      {{ $t('account.view_doc') }}
                    </template>
                  </a>
                </span>
              </div>
              <div class="submit_contact">
                <span class="contact_us" @click="openPopupContactUs">
                  <img src="/images/link.svg" alt="star-level" class="link" />
                  <span class="submit">{{ $t('contact.contact_us') }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list_option_acc">
        <div class="left_list_option_acc">
          <div class="login_password mgb20 group_custom group_custom-2">
            <div class="group group-password cursor" @click="showChangePasswordModal">
              <img class="icon-account" src="/images/change-password.svg" alt="change-password" />
              <div class="group_center_option">
                <div class="text_login">
                  <span>{{ $t('change_password_form.title') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="google_authen boder_option mgb20 group_custom group_custom-2">
            <div class="group">
              <img
                class="icon-account"
                src="/images/google-authentication.svg"
                alt="google-authentication"
              />
              <div class="group_center_option">
                <div class="text_login">
                  <span>{{ $t('account.google_auth') }}</span>
                  <div class="check_authen_activation mgb0">
                    {{ $t('account.google_auth_content') }}
                  </div>
                  <label class="check_authen_activation" @click="showModalLostOtp" v-if="enableOtp">
                    <a class="google-authen" href="#">{{
                      $t('account.lost_google_authenticator')
                    }}</a>
                  </label>
                </div>
                <label class="switch fl-left">
                  <input
                    v-model="enableOtp"
                    type="checkbox"
                    @click="enableOtp ? showCancelGoogleAuthModal() : showEnableOtpModal()"
                  />
                  <span><i></i></span>
                </label>
              </div>
            </div>
          </div>
          <div
            @click="gotoPath('/fee-tier-program')"
            class="link"
            :class="{
              disable:
                (feeTierInfo.fee_tier_status === 1 && !isStartFeeTier) ||
                feeTierInfo.fee_tier_status === 0,
            }"
          >
            <div class="fee-tier-program mgb20" :class="{ enableOtp: enableOtp }">
              <img class="icon-account" src="/images/fee-tier-program.svg" alt="" />
              <div class="group-right">
                <div class="text_login">
                  {{ $t('account.fee_tier_program') }}
                </div>
                <div class="link_account">
                  {{ $t('account.fee_tier_program_desc') }}
                  <br />
                  <span
                    class="error-msg"
                    v-if="!isStartFeeTier && feeTierInfo.fee_tier_status === 1"
                  >
                    {{
                      $t('account.fee_tier_program_disable1', {
                        time: periodTimeStart,
                      })
                    }}
                  </span>
                  <span class="error-msg" v-if="feeTierInfo.fee_tier_status === 0">
                    {{ $t('account.fee_tier_program_disable2') }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="link" @click="gotoPath('/referral')">
            <div class="referral_program boder_option mgb20 group_custom group_custom-2">
              <div class="group cursor">
                <img class="icon-account" src="/images/referral-program.svg" alt="referral" />
                <div class="group_center_option">
                  <div class="text_login">
                    <span>{{ $t('account.referral_program') }}</span>
                    <div class="link_referral referral-height">
                      <div class="link_account">
                        {{
                          $t('account.referral_notification', {
                            percent: settingReferral.refund_percent,
                            APP_NAME: app_name,
                          })
                        }}
                      </div>
                      <router-link to="/" class="">{{ appUrl }}</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right_list_option_acc">
          <div class="box_api_account boder_option mgb20 group_custom group_custom-2">
            <div class="group cursor">
              <img class="icon-account" src="/images/api-icon.svg" alt="api" />
              <div class="group_center_option group_center_option_api">
                <div class="text_login">
                  <span>{{ $t('account.api.api') }}</span>
                  <div class="link_account mgb0">
                    {{ $t('account.api_description') }}
                    <a :href="linkAPIPage" target="_blank" class="link_api">{{
                      $t('account.api_view_documentation')
                    }}</a>
                  </div>

                  <button
                    class="btn-address"
                    @click="onClickedVerifyGoogleAuthen(keyAPIAccountRouteName)"
                  >
                    {{ $t('account.create_api') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="line boder_option mgb20 group_custom group_custom-2">
            <div class="group">
              <img class="icon-account" src="/images/line.svg" alt="line" />
              <div class="group_center_option">
                <div class="text_login">
                  <span>Line</span>
                  <div class="check_authen_activation">
                    {{ $t('account.line.desc') }}
                  </div>
                </div>
                <span>
                  <label class="switch fl-left" id="cancel_focus_line">
                    <input
                      v-model="lineNotification"
                      type="checkbox"
                      @click.prevent="
                        lineNotification
                          ? showChannelModal('disable', 'line')
                          : redirectLine(user.id)
                      "
                    />
                    <span><i></i></span>
                  </label>
                </span>
              </div>
            </div>
          </div> -->

          <!-- <div class="referral_program boder_option mgb20 group_custom group_custom-2">
            <div class="group">
              <img class="icon-account" src="/images/telegram.svg" alt="telegram" />
              <div class="group_center_option">
                <div class="text_login">
                  <span>{{ $t('account.telegram.title') }}</span>
                  <div class="check_authen_activation">
                    {{ $t('account.telegram.desc') }}
                  </div>
                </div>
                <span>
                  <label class="switch fl-left" id="cancel_focus_line">
                    <input
                      id="cancel_focus_telegram"
                      v-model="telegramNotification"
                      type="checkbox"
                      @click.prevent="
                        telegramNotification
                          ? showChannelModal('disable', 'telegram')
                          : addChannelTelegram(user.id)
                      "
                    />
                    <span><i></i></span>
                  </label>
                </span>
              </div>
            </div>
          </div> -->

          <div class="address_management boder_option">
            <img class="icon-account" src="/images/withdrawal-address.svg" alt="withdrawal" />
            <div class="right_withdrawal_acc">
              <div class="text_login">
                <span>{{ $t('account.withdrawal_address') }}</span>
              </div>
              <div class="white-list">
                <label class="marr10 switch">
                  <input
                    v-model="enableWhiteList"
                    type="checkbox"
                    @click.prevent="activeWhitelist()"
                  />
                  <span>
                    <i></i>
                  </span>
                </label>
                <span class="white-list-height white-list-title" v-if="enableWhiteList">{{
                  $t('account.white_list_on')
                }}</span>
                <span class="white-list-height white-list-title" v-else>{{
                  $t('account.white_list_off')
                }}</span>
                <div class="tooltip-wrapper">
                  <span class="help-box">
                    <img src="/images/icon/WarningCircle.png" alt="" height="18" width="18" />
                    <div class="box-tooltip">
                      <div class="bubble top" id="still-not-receive">
                        <div>{{ $t('account.tooltip1') }}</div>
                        <br />
                        <div>{{ $t('account.tooltip2') }}</div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
              <div class="check_authen_activation">
                {{ $t('account.address_manager_content') }}
              </div>
              <router-link to="/address" class="btn-address">{{
                $t('account.address_manager')
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Bank account management -->
    <div class="device-management">
      <div class="title-bank">
        <span class="title-medium-small">{{ $t('bank_account_management.title') }}</span>
        <button>
          <img @click="addBank" src="/images/plus.svg" alt="icon" />
        </button>
      </div>
      <div class="datatable scroll-table table-bank-account">
        <data-table
          :fromAccount="true"
          :isMobile="isMobile"
          :getData="getBankAccount"
          :widthTable="'100%'"
          :classHeader="'header-css'"
          @DataTable:finish="onDatatableFinished"
          ref="datatableBankAccount"
          class="table_min"
          :isLoading="isLoadingBank"
        >
          <tr v-if="!isMobile" class="double_tr2">
            <th>{{ $t('bank_account_management.bank') }}</th>
            <th>{{ $t('bank_account_management.account_number') }}</th>
            <th>{{ $t('bank_account_management.owner_name') }}</th>
            <th>{{ $t('bank_account_management.action') }}</th>
          </tr>
          <template v-if="!isMobile" slot="body" slot-scope="props">
            <tr class="bank-manager">
              <td>
                {{ bankCodeToName(props.item.bank) }}
              </td>
              <td>{{ props.item.account_number }}</td>
              <td>{{ props.item.owner_name }}</td>
              <td class="actions">
                <button class="btn-remove" @click="removeAccount(props.item.id)">
                  {{ $t('account.remove') }}
                </button>
              </td>
            </tr>
          </template>
          <template v-if="isMobile" slot="body-card" slot-scope="props">
            <tr>
              <div class="item-bank">
                <div class="name-bank text-left">
                  <div class="text1">{{ bankCodeToName(props.item.bank) }}</div>
                  <div class="text2">
                    {{ $t('bank_account_management.bank') }}
                  </div>
                </div>
                <div class="number-bank text-right">
                  <div class="text1">{{ props.item.account_number }}</div>
                  <div class="text2">
                    {{ $t('bank_account_management.account_number') }}
                  </div>
                </div>
                <div class="owner-bank text-left">
                  <div class="text1">{{ props.item.owner_name }}</div>
                  <div class="text2">
                    {{ $t('bank_account_management.owner_name') }}
                  </div>
                </div>
                <div class="action">
                  <button class="btn-remove ml" @click="removeAccount(props.item.id)">
                    {{ $t('account.remove') }}
                  </button>
                </div>
              </div>
            </tr>
          </template>
        </data-table>
      </div>
    </div>

    <!-- <div class="path_leaderboard">
      <div class="title-medium-small">{{ $t('title.Leaderboard.title') }}</div>
      <div class="table_descripe">{{ $t('title.Leaderboard.des_title') }}</div>
      <div class="content_pick_leaderboard">
        <div class="option-leaderboard">
          <label class="marr10 switch fl-left">
            <input v-model="enableLeaderboard" type="checkbox" @click="activeLeaderboard()" />
            <span>
              <i></i>
            </span>
          </label>
          <span>
            {{
              $t(
                `leaderboard.${enableLeaderboard ? 'off' : 'on'}_to_show_${
                  enableLeaderboard ? 'fake' : 'real'
                }_username`
              )
            }}
          </span>
        </div>
        <template v-if="enableLeaderboard">
          <div
            class="fake-name-contain"
            :class="{ 'fake-name-contain-column': isEditFakeName }"
            v-if="localShowFakeName"
          >
            <span>{{ $t('leaderboard.your_fake_name_is') }}{{ !isEditFakeName ? ':' : '' }}</span>
            <div class="input-wrapper">
              <template v-if="!isEditFakeName">
                <span class="fake-name-span">{{ user.fake_name }}</span>
                <span
                  @click="
                    isEditFakeName = true;
                    fakeNameTemp = user.fake_name;
                  "
                  class="btn btn_edit_network"
                  ><img src="/images/pen.svg" alt="pen"
                /></span>
              </template>
              <template v-else>
                <input v-model="fakeNameTemp" maxlength="190" type="text" />
                <div class="action-button-wrapper">
                  <span class="btn btn_close_network" @click="isEditFakeName = false"
                    ><img src="/images/close.svg"
                  /></span>
                  <span class="btn btn_save_network" @click="onSaveFakeName"
                    ><img src="/images/save-fake-name.svg"
                  /></span>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div> -->

    <div class="device-management device-management-head">
      <div class="title-medium-small">
        {{ $t('account.device_management') }}
      </div>
      <div class="table_descripe">
        {{ $t('account.devices_allowed') }}
      </div>

      <div class="datatable scroll-table table-device">
        <data-table
          :fromAccount="true"
          :isMobile="isMobile"
          :getData="getUserDevice"
          :widthTable="'100%'"
          :classHeader="'header-css'"
          @DataTable:finish="onDatatableFinished"
          ref="datatableDevice"
          class="table_min"
          :autoScrollToClassName="'.device-management.device-management-head'"
          :isLoading="isLoadingDevice"
        >
          <tr v-if="!isMobile" class="double_tr">
            <th>{{ $t('account.device') }}</th>
            <th>{{ $t('account.date') }}</th>
            <!-- <th>{{ $t('account.ip_address') }}</th> -->
            <th></th>
          </tr>
          <template v-if="isMobile" slot="body-card" slot-scope="props">
            <div class="card">
              <div class="card__row">
                <div class="card__row--title">{{ $t('account.device') }}</div>
                <div>
                  {{ props.item.kind | uppercaseFirst }}, {{ props.item.operating_system }},
                  {{ props.item.platform }}
                </div>
              </div>
              <div class="card__row">
                <div class="card__row--title">{{ $t('account.date') }}</div>
                <div>{{ convertDate(props.item.updated_at) }}</div>
              </div>
              <!-- <div class="card__row">
                <div class="card__row--title">
                  {{ $t('account.ip_address') }}
                </div>
                <div>{{ props?.item?.latest_ip_address }}</div>
              </div> -->
              <div class="card__actions">
                <button class="btn-remove" @click="confirmRemoveDevice(props.item.id)">
                  {{ $t('account.remove') }}
                </button>
              </div>
            </div>
            <template v-if="props.item.isActiveRow">
              <div
                class="card__row"
                v-for="(connection, index) in props.item.user_connection_histories"
                :key="index"
              >
                <div class="card__row--title">
                  {{ convertDate(connection?.created_at) }}
                </div>
                <!-- <div>{{ connection?.ip_address }}</div> -->
              </div>
            </template>
          </template>
          <template v-if="!isMobile" slot="body" slot-scope="props">
            <tr class="device-manager">
              <td>
                {{ props.item.kind | uppercaseFirst }}, {{ props.item.operating_system }},
                {{ props.item.platform }}
              </td>
              <td>{{ convertDate(props.item.updated_at) }}</td>
              <!-- <td>{{ props?.item?.latest_ip_address }}</td> -->
              <td class="actions">
                <button class="btn-remove ml" @click="confirmRemoveDevice(props.item.id)">
                  {{ $t('account.remove') }}
                </button>
              </td>
            </tr>
            <template v-if="props.item.isActiveRow">
              <tr
                class="device-manager"
                :class="{ odd: (props.index + 1) % 2 === 0 }"
                v-for="(connection, index) in props.item.user_connection_histories"
                :key="index"
              >
                <td></td>
                <td>{{ convertDate(connection?.created_at) }}</td>
                <!-- <td>{{ connection?.ip_address }}</td> -->
                <td></td>
              </tr>
            </template>
          </template>
        </data-table>
      </div>
    </div>

    <div class="path_last_login">
      <div class="title-medium-small">
        {{ $t('account.last_login') }}
      </div>
      <div class="table_descripe">
        {{ $t('account.devices_allowed') }}
      </div>
      <div class="datatable scroll-table">
        <data-table
          :fromAccount="true"
          :isMobile="isMobile"
          :getData="getHistoryConnection"
          :widthTable="'100%'"
          :classHeader="'header-css'"
          ref="datatable"
          class="table_min"
          :isLoading="isLoadingLogin"
        >
          <tr v-if="!isMobile" class="double_tr">
            <th style="width: 100%">{{ $t('account.date') }}</th>
            <!-- <th>{{ $t('account.ip_address') }}</th> -->
          </tr>
          <template v-if="isMobile" slot="body-card" slot-scope="props">
            <div class="card">
              <div class="card__row">
                <div class="card__row--title">{{ $t('account.date') }}</div>
                <div>{{ props?.item?.created_at | timestampToDate }}</div>
              </div>
              <!-- <div class="card__row">
                <div class="card__row--title">
                  {{ $t('account.ip_address') }}
                </div>
                <div>{{ props?.item?.ip_address }}</div>
              </div> -->
            </div>
          </template>
          <template v-if="!isMobile" slot="body" slot-scope="props">
            <tr
              class="last-login"
              :class="{
                white: (props.index + 1) % 2 === 0,
                gray: (props.index + 1) % 2 !== 0,
              }"
            >
              <td>{{ props?.item?.created_at | timestampToDate }}</td>
              <!-- <td>{{ props?.item?.ip_address }}</td> -->
            </tr>
          </template>
        </data-table>
      </div>
    </div>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory';
import RemoveErrorsMixin from '@/common/RemoveErrorsMixin';
import CancelGoogleAuthModal from '@/components/shared_components/otp/CancelGoogleAuthModal';
import OtpModal from '@/components/shared_components/funds/common/OtpModal';
import EnableGoogleAuthentication from './account/EnableGoogleAuthentication';
import ChangePassword from './account/ChangePassword.vue';
import DeviceManager from './account/DeviceManager.vue';
import LostOtpModal from '@/components/shared_components/otp/LostOtpModal';
import ConfirmationModal from '@/components/shared_components/common/ConfirmationModal';
import SelectBox from '@/components/shared_components/common/SelectBox';
import moment from 'moment';
import AddBankModal from '@/components/shared_components/common/AddBankModal.vue';
// import { getLayoutPath } from '@/routes'
import ChartContracts from './ChartIndex.vue';
import ChangePhoneModal from '@/components/shared_components/common/ChangePhoneModal';

import 'url-search-params-polyfill';
// const KYC_UNVERIFED = 'unverified'
// const KYC_PENDING = 'pending'
const KYC_VERIFIED = 'verified';
// const KYC_REJECTED = 'rejected'
// const BANK_UNVERIFED = 'unverified'
const BANK_CREATING = 'creating';
const BANK_VERIFING = 'verifing';
const BANK_VERIFIED = 'verified';
// const BANK_REJECTED = 'rejected'
var sha1 = require('sha1');
export default {
  components: {
    ChartContracts,
    CancelGoogleAuthModal,
    EnableGoogleAuthentication,
    OtpModal,
    ChangePassword,
    LostOtpModal,
    DeviceManager,
    ConfirmationModal,
    SelectBox,
    AddBankModal,
    ChangePhoneModal,
  },
  mixins: [RemoveErrorsMixin],
  data() {
    return {
      kycAccountRouteName: 'IdentityPersonal',
      keyAPIAccountRouteName: 'ApiCreated',
      linkAPIPage: process.env.VUE_APP_API_URL + '/api/documentation',
      isShowTooltip: false,
      tab: 'main',
      enableWhiteList: undefined,
      enableLeaderboard: 0,
      localShowFakeName: 1,
      lastLogin: {},
      withdrawalLimit: [],
      check: 0,
      settings: {
        email: true,
        phone: false,
        bank: false,
        otp: false,
        password: false,
      },
      kyc: {
        status: '',
        bank_status: '',
      },
      user: {
        security_setting: {
          otp_verified: 0,
        },
      },
      infomation: {},
      otp: undefined,
      userSettingWhitelist: undefined,
      enableOtp: false,
      emailNotification: false,
      telegramNotification: false,
      lineNotification: false,
      isCreateNewBankAccount: false,
      enableAmalFromAdmin: false,
      enableAmlPay: false,
      app_name: process.env.VUE_APP_NAME,
      moment: moment,
      isDisableButton: false,
      siteSetting: {},
      idEncrypt: '',
      perpetual_dividend: false,
      isEditFakeName: false,
      fakeNameTemp: '',
      isMobile: false,
      durationTimeStart: moment.duration(0, 'days'),
      isStartFeeTier: true,
      feeTierInfo: {},
      intervalId: null,
      numberOfBankAccount: 0,
      limitBankAccount: 10,
      overlay: true,
      settingReferral: {
        refund_percent: '',
      },
      isLoadingDevice: false,
      isLoadingBank: false,
      isLoadingLogin: false,
    };
  },
  watch: {
    enableAmalFromAdmin(newValue) {
      if (!newValue) {
        this.enableAmlPay = false;
      }
    },
  },
  mounted() {
    this.checkIsMobile();
    this.getMasterData();
    let url = window.location.href;
    if (url.includes('need-close')) {
      window.close();
    }
  },
  computed: {
    isVerifiableBank() {
      return (
        this.kyc.bank_status === BANK_CREATING ||
        this.kyc.bank_status === BANK_VERIFING ||
        this.kyc.bank_status === BANK_VERIFIED
      );
    },
    appUrl() {
      return window.location.origin;
    },
    submittableKyc() {
      return window._.get(this, 'user.security_setting.otp_verified', 0);
    },
    userSecurityLevel() {
      if (this.user.security_level > this.user.max_security_level) {
        return this.user.security_level;
      }
      return this.user.max_security_level;
    },
    periodTimeStart() {
      const days = this.durationTimeStart.days() || '0';
      const hour = this.durationTimeStart.hours() || '0';
      const minute = this.durationTimeStart.minutes() || '0';
      const sec = this.durationTimeStart.seconds() || '0';
      if (days === '0' && hour === '0' && minute === '0' && sec === '0') {
        return '--';
      }
      return `${days}d ${hour}h ${minute}m ${sec}s`;
    },
  },
  destroyed() {
    clearInterval(this.intervalId);
  },
  methods: {
    bankCodeToName(code) {
      switch (code) {
        case 'bca':
          return 'PT. BANK CENTRAL ASIA TBK';
        case 'mandiri':
          return 'PT. BANK MANDIRI (PERSERO) TBK';
        case 'permata':
          return 'PT. BANK PERMATA TBK';
        case 'cimb':
          return 'PT. BANK CIMB NIAGA TBK';
        case 'bni':
          return 'PT. BANK NEGARA INDONESIA (PERSERO)';
        case 'bri':
          return 'PT. BANK RAKYAT INDONESIA (PERSERO)';
        default:
          return '';
      }
    },
    gotoPath(path) {
      this.$router.push(path);
    },
    openPopupContactUs() {
      window.FreshworksWidget('open');
    },
    checkIsMobile() {
      this.isMobile = window.matchMedia('(max-width: 768px)').matches;
    },
    onSaveFakeName() {
      rf.getRequest('UserRequest')
        .onUpdateFakeName({ fake_name: this.fakeNameTemp })
        .then((res) => {
          if (res && res.data) {
            this.user.fake_name = this.fakeNameTemp;
            this.isEditFakeName = false;
            this.getUser(false);
            this.showSuccess(this.$t('address.updated_success'));
          }
        });
    },
    getLinkPnlChart() {
      rf.getRequest('UserRequest')
        .getLinkPnlChart({ name: 'Pnl-Chart' })
        .then((res) => {
          const path = process.env.VUE_APP_URL + '/pnl-chart?token=' + res.data.id;
          // if (!navigator.clipboard) {
          //   Utils.fallbackCopyTextToClipboard(path);
          //   return;
          // }
          // navigator.clipboard.writeText(path);
          // this.$router.push({ name: 'PNLChart', query: {token: res.data.id} });
          window.open(path, '_blank');
        });
    },
    convertDate(date) {
      var dateFormat = 'YYYY-MM-DD HH:mm:ss';
      var testDateUtc = moment.utc(date);
      var localDate = testDateUtc.local();
      return localDate.format(dateFormat);
    },
    addChannelTelegram: function (userID) {
      document.getElementById('cancel_focus_telegram').blur();
      this.listenForTelegramLine(userID);
      let apiTelegram = process.env.VUE_APP_MIX_API_TELEGRAM + userID;
      window.open(apiTelegram, '_blank');
    },

    getParamsUpdateModeWhitelist() {
      return {
        active: !this.userSettingWhitelist,
      };
    },
    listenForTelegramLine(userId) {
      window.Echo.private('App.User.' + userId).listen('UserNotificationUpdated', (res) => {
        let channel = res.data.channel;
        let is_enable = res.data.is_enable;
        if (channel === 'line') {
          this.lineNotification = is_enable;
        }
        if (channel === 'mail') {
          this.emailNotification = is_enable;
        }
        if (channel === 'telegram') {
          this.telegramNotification = is_enable;
        }
      });
    },
    async redirectLine(userID) {
      await this.encryptId();
      document.getElementById('cancel_focus_line').blur();
      if (userID) {
        let callback_uri = this.$router.resolve({ name: 'Account' }).href + '?need-close';
        let state = userID + '&' + this.idEncrypt + '$' + callback_uri;
        let client_id = process.env.VUE_APP_MIX_LINE_CLIENT_ID || '';
        let redirect_uri = process.env.VUE_APP_API_URL + '/api/v1/get-auth-code';
        let response_type = 'code';
        let url = 'https://notify-bot.line.me/oauth/authorize?';
        let scope = 'notify';
        var searchParams = new URLSearchParams('');
        searchParams.append('client_id', client_id);
        searchParams.append('redirect_uri', redirect_uri);
        searchParams.append('response_type', response_type);
        searchParams.append('scope', scope);
        searchParams.append('state', state);
        url = url + searchParams;
        window.open(url, '_blank');
      }
    },
    changeWalletFee(type) {
      if (this.tab != type && this.enableAmlPay) {
        this.tab = type;
        let param = {
          wallet_name: type,
        };
        rf.getRequest('UserRequest')
          .changeTypeWalletAmalPayFee(param)
          .then((res) => {});
      }
    },

    showChannelModal(type, channel) {
      if (type === 'disable') {
        window.ConfirmationModal.show({
          type:
            channel === 'email'
              ? 'disableEmail'
              : channel === 'telegram'
              ? 'disableTelegram'
              : channel === 'line'
              ? 'disableLine'
              : '',
          title: '',
          content:
            channel === 'email'
              ? this.$t('account.email_disable_confirm')
              : channel === 'telegram'
              ? this.$t('account.email_disable_confirm')
              : channel === 'line'
              ? this.$t('account.email_disable_confirm')
              : '',
          btnCancelLabel: this.$i18n.t('common.action.no'),
          btnConfirmLabel: this.$i18n.t('common.action.yes'),
          onConfirm: () => {
            this.actionNotification(type, channel);
          },
        });
      } else {
        // document.getElementById("cancel_focus_mail").blur();
        this.actionNotification(type, channel);
      }
    },
    actionNotification(type, channel) {
      let param = {
        active: 1,
      };
      if (type === 'disable') {
        param.active = 0;
      } else {
        param.active = 1;
      }
      if (channel === 'email') {
        rf.getRequest('UserRequest')
          .changeEmailNotification(param)
          .then((res) => {
            this.getUserNotification();
          });
      }
      if (channel === 'telegram' && param.active == 0) {
        rf.getRequest('UserRequest')
          .changeTelegramNotification(param)
          .then((res) => {
            this.getUserNotification();
          });
      }
      if (channel === 'line') {
        rf.getRequest('UserRequest')
          .changeLineNotification(param)
          .then((res) => {
            this.getUserNotification();
          });
      }
    },
    showTooltip() {
      this.isShowTooltip = !this.isShowTooltip;
    },
    hideTooltip() {
      this.isShowTooltip = false;
    },
    showCancelGoogleAuthModal() {
      window.CommonModal.show('CancelGoogleAuthModal');
      this.openPageOverflow();
    },
    showChangePasswordModal() {
      window.CommonModal.show('ChangePassword');
      this.$refs.changePassComponent.resetData();
      this.openPageOverflow();
    },
    showChangePhoneModal() {
      window.CommonModal.show('ChangePhoneModal');
      this.openPageOverflow();
    },
    hideCancelGoogleAuthModal() {
      window.CommonModal.hide('CancelGoogleAuthModal');
      this.getSecuritySettings();
      this.getUser(false);
      this.removePageOverflow();
    },
    hideChangePhoneModal() {
      window.CommonModal.hide('ChangePhoneModal');
      this.getUser(false);
      this.removePageOverflow();
    },
    hideEnableGoogleAuthenticaion() {
      window.CommonModal.hide('EnableGoogleAuthentication');
      this.getSecuritySettings();
      this.getUser(false);
      this.removePageOverflow();
    },
    showEnableOtpModal() {
      window.CommonModal.show('EnableGoogleAuthentication');
      this.openPageOverflow();
    },
    showModalLostOtp() {
      window.CommonModal.show('lostOtpForm');
    },
    disableOtpSuccess() {
      this.getSecuritySettings();
      this.getUser(false);
    },
    loadDataImmediately() {
      this.getUserSettings();
      this.getUserNotification();
      this.getSecuritySettings();
      this.getUser(false);
      this.getWithdrawalLimit();
      this.getUserKyc();
      this.getDividendSettings();
    },
    getSecuritySettings() {
      this.overlay = true;
      rf.getRequest('UserRequest')
        .getSecuritySettings()
        .then((res) => {
          this.settings = Object.assign({}, this.settings, res.data);
          this.enableOtp = window._.get(this, 'settings.otp_verified', 0);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    getUserKyc() {
      rf.getRequest('UserRequest')
        .getUserKyc()
        .then((res) => {
          this.kyc = res.data || {};
          this.isCreateNewBankAccount = this.kyc.bank_status === BANK_CREATING;
        });
    },
    async encryptId() {
      await rf
        .getRequest('UserRequest')
        .encryptId()
        .then((res) => {
          this.idEncrypt = sha1(res.toString()) || {};
        });
    },
    getDividendSettings() {
      rf.getRequest('UserRequest')
        .getDividendSetting()
        .then((res) => {
          this.enableAmalFromAdmin = res?.data?.enable_fee_amal;
          this.perpetual_dividend = res?.data?.enable_wallet_pay_fee;
        });
    },
    getHistoryConnection(params) {
      this.isLoadingLogin = true;
      const promise = rf.getRequest('UserRequest').getHistoryConnection(params);

      this.getLatest(promise);

      return promise;
    },
    getLatest(promise) {
      promise.then((res) => {
        this.lastLogin = res.data.data[0];
        this.isLoadingLogin = false;
      });
    },

    getUserNotification() {
      rf.getRequest('UserRequest')
        .getUserNotificationSettings()
        .then((res) => {
          const emailNotification =
            _.find(res.data, (item) => {
              return item.channel === 'mail';
            }) || {};

          this.emailNotification = emailNotification.is_enable === 1;

          const telegramNotification =
            _.find(res.data, (item) => {
              return item.channel === 'telegram';
            }) || {};

          this.telegramNotification = telegramNotification.is_enable === 1;

          const lineNotification =
            _.find(res.data, (item) => {
              return item.channel === 'line';
            }) || {};
          this.lineNotification = lineNotification.is_enable === 1;
        });
    },

    getUserSettings() {
      rf.getRequest('UserRequest')
        .getUserSettings()
        .then((res) => {
          const setting =
            _.find(res.data, (item) => {
              return item.key === 'whitelist';
            }) || {};
          this.userSettingWhitelist = this.enableWhiteList = !!parseInt(setting.value);

          const amlPay =
            _.find(res.data, (item) => {
              return item.key === 'amal_pay';
            }) || {};
          const amlWalletPay =
            _.find(res.data, (item) => {
              return item.key === 'amal_pay_wallet';
            }) || {};
          this.enableAmlPay = amlPay.value === '1';
          if (amlWalletPay && amlWalletPay.value) {
            this.tab = amlWalletPay.value;
          }
        });
    },
    getUser(useCache = true) {
      rf.getRequest('UserRequest')
        .getCurrentUser(useCache)
        .then((res) => {
          this.user = Object.assign({}, this.user, res.data);
          this.listenForTelegramLine(res.data.id);
          this.localShowFakeName = this.user.security_setting.use_fake_name;
          this.enableLeaderboard = this.user.security_setting.use_fake_name;
        });
    },
    getWithdrawalLimit() {
      const descriptions = [
        'account.verify_email',
        'account.google_authenticator',
        'account.kyc',
        'account.add_info',
      ];
      rf.getRequest('UserRequest')
        .getWithdrawalLimit()
        .then((res) => {
          this.withdrawalLimit = res.data.map((item) => {
            return {
              ...item,
              description: this.$t(descriptions[item.security_level - 1]),
            };
          });
        });
    },
    activeWhitelist() {
      const message = this.userSettingWhitelist
        ? this.$i18n.t('account.turn_on_mode_whitelist')
        : this.$i18n.t('account.turn_off_mode_whitelist');
      this.showModal({
        class: 'custom_label_withdrawal_address_management',
        type: 'whiteList',
        title: '',
        content: message,
        onConfirm: () => {
          if (this.enableOtp) {
            window.CommonModal.show('otp_modal');
            this.enableWhiteList = this.userSettingWhitelist;
          } else {
            const params = this.getParamsUpdateModeWhitelist();
            this.updateModeWhitelist(params);
          }
        },
        onCancel: () => {
          this.enableWhiteList = this.userSettingWhitelist;
        },
        widthModal: '414px',
      });
    },
    updateModeWhitelist(params) {
      this.isDisableButton = true;
      rf.getRequest('UserRequest')
        .activeWhitelist(params)
        .then((response) => {
          this.isDisableButton = false;
          window.CommonModal.hide('otp_modal');
          this.userSettingWhitelist = this.enableWhiteList = response.data.whitelist;
          response.data.whitelist
            ? Message.success(this.$i18n.t('address.white_list_on'))
            : Message.success(this.$i18n.t('address.white_list_off'));
        })
        .catch((error) => {
          this.isDisableButton = false;
          if (!error.response) {
            Message.error(this.$i18n.t('common.message.network_error'));
          } else {
            this.convertRemoteErrors(error);
          }
        });
    },
    activeLeaderboard() {
      const message = this.enableLeaderboard
        ? this.$i18n.t('leaderboard.show_fake_username')
        : this.$i18n.t('leaderboard.show_real_username');
      this.showModal({
        type: 'primary',
        title: '',
        content: message,
        btnCancelLabel: this.$i18n.t('common.action.no'),
        btnConfirmLabel: this.$i18n.t('common.action.yes'),
        onConfirm: () => {
          this.updateLeaderboard();
        },
        onCancel: () => {
          this.enableLeaderboard = !this.enableLeaderboard ? 1 : 0;
        },
      });
    },
    updateLeaderboard() {
      let params = {
        use_fake_name: !this.enableLeaderboard ? 0 : 1,
      };
      rf.getRequest('UserRequest')
        .activeLeaderBoard(params)
        .then((res) => {
          this.localShowFakeName = res.data;
        })
        .catch((err) => {
          this.localShowFakeName = 0;
        });
    },
    showSuccess(message) {
      Message.success(message, {}, { position: 'bottom_left' });
    },
    getSocketEventHandlers() {
      return {
        OtpUpdated: this.getSecuritySettings,
      };
    },
    verifyBankAccount(status) {
      rf.getRequest('UserRequest')
        .updateBankAccountStatus(status)
        .then((res) => {
          this.getUserKyc();
        });
    },
    verifyBankModal() {
      if (this.kyc.status !== KYC_VERIFIED) {
        this.showModal({
          type: 'warning',
          title: 'Error',
          content: this.$t('refferal.alert_bank_account_content'),
          noAction: true,
        });
        return;
      }
      this.showModal({
        type: 'confirm',
        title: this.$t('refferal.confirm_bank_account_title'),
        customContent: true,
        onConfirm: () => {
          this.verifyBankAccount({
            status: this.isCreateNewBankAccount ? BANK_CREATING : BANK_VERIFING,
          });
        },
      });
    },
    showModal(config) {
      const type = config.type;
      const title = config.title;
      const content = config.content;
      const customContent = config.customContent;
      let btnCancelLabel = config.btnCancelLabel || this.$t('common.action.no');
      let btnConfirmLabel = config.btnConfirmLabel || this.$t('common.action.yes');
      if (config.noAction) {
        btnCancelLabel = null;
        btnConfirmLabel = null;
      }
      const onConfirm = config.onConfirm;
      const onCancel = config.onCancel;
      const widthModal = config.widthModal;
      window.ConfirmationModal.show({
        class: config.class,
        type: type,
        title: title,
        content: content,
        customContent: customContent,
        btnCancelLabel: btnCancelLabel,
        btnConfirmLabel: btnConfirmLabel,
        onConfirm: onConfirm,
        onCancel: onCancel,
        widthModal,
      });
    },
    onClickedVerifyGoogleAuthen(routeName) {
      if (this.user.security_level < 2) {
        Message.error(this.$t('account.otp.should_enable_first'), {}, { position: 'top_left' });
        return;
      }
      this.$router.push({ name: routeName });
    },
    activeAmlPay() {
      if (this.enableAmalFromAdmin) {
        let param = {
          active: !this.enableAmlPay ? 1 : 0,
        };
        rf.getRequest('UserRequest')
          .changeAmlPay(param)
          .then((res) => {});
      }
    },
    getReferralSetting() {
      rf.getRequest('UserRequest')
        .getReferralSetting()
        .then((res) => {
          this.settingReferral = res.data;
          this.settingReferral.refund_percent =
            Number(this.settingReferral.refund_percent_at_level_1) +
            Number(this.settingReferral.refund_percent_at_level_2) +
            Number(this.settingReferral.refund_percent_at_level_3) +
            Number(this.settingReferral.refund_percent_at_level_4) +
            Number(this.settingReferral.refund_percent_at_level_5);
        });
    },
    async getBankAccount() {
      return rf.getRequest('UserRequest').getBankAccount();
    },
    addBankSuccess() {
      this.$refs.datatableBankAccount.refresh();
    },
    removeBankById(id) {
      rf.getRequest('UserRequest')
        .removeBankAccount(id)
        .then((res) => {
          Message.success(
            this.$i18n.t('bank_account_management.remove_bank_success'),
            {},
            { position: 'top_right' }
          );
          this.$refs.datatableBankAccount.refresh();
          this.getBankAccount();
        })
        .catch((err) => {
          Message.error(
            this.$i18n.t('bank_account_management.remove_bank_faild'),
            {},
            { position: 'top_right' }
          );
          this.getBankAccount();
        });
    },
    removeAccount(id) {
      this.showModal({
        type: 'warning-2',
        title: '',
        content: this.$i18n.t('bank_account_management.confirm_remove_bank'),
        btnCancelLabel: this.$i18n.t('common.action.no'),
        btnConfirmLabel: this.$i18n.t('common.action.yes'),
        btnRow: true,
        onConfirm: () => {
          this.removeBankById(id);
        },
        onCancel: function () {},
      });
    },
    async getUserDevice(params) {
      this.isLoadingDevice = true;
      const promise = await rf.getRequest('UserRequest').getUserDevice(params);
      this.isLoadingDevice = false;
      return promise;
    },
    onDatatableFinished() {
      if (!this.$refs.datatableDevice || !this.$refs.datatableDevice.rows) {
        return;
      }
      window._.each(this.$refs.datatableDevice.rows, (item) => {
        item.isActiveRow = false;
      });
    },
    activeRow(item) {
      item.isActiveRow = !item.isActiveRow;
      this.$forceUpdate();
    },
    refreshDevice() {
      this.$refs.datatableDevice.refresh();
    },
    confirmRemoveDevice(deviceId) {
      window.ConfirmationModal.show({
        type: 'warning-2',
        title: '',
        content: this.$t('account.modal.confirm_trusted_device'),
        btnCancelLabel: this.$i18n.t('common.action.no'),
        btnConfirmLabel: this.$i18n.t('common.action.yes'),
        onConfirm: () => {
          this.removeDevice(deviceId);
        },
        onCancel: () => {},
      });
    },
    removeDevice(deviceId) {
      rf.getRequest('UserRequest')
        .deleteDeviceAccount(deviceId)
        .then((res) => {
          this.refreshDevice();
          Message.success(this.$i18n.t('address.deleted_success'));
        });
    },

    getMasterData() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((data) => {
          const masterdata = data;
          window._.each(masterdata.settings, (item) => {
            this.siteSetting[item.key] = item.value;
          });
        });
    },
    getFeeTierStatus() {
      rf.getRequest('UserRequest')
        .getFeeTierStatus()
        .then((res) => {
          this.feeTierInfo = res.data;
          if (this.feeTierInfo.fee_tier_status === 1) {
            if (moment.utc().isBefore(moment.utc(this.feeTierInfo.date_apply))) {
              this.isStartFeeTier = false;
              this.countdownTimeStart();
            } else {
              this.isStartFeeTier = true;
            }
          }
        });
    },
    countdownTimeStart() {
      this.intervalId = setInterval(() => {
        this.updateCountdownPeriodTimeStart();
      }, 1000);
    },
    updateCountdownPeriodTimeStart() {
      const timeStart = moment.utc(this.feeTierInfo.date_apply);
      const now = moment.utc();
      this.durationTimeStart = moment.duration(timeStart.diff(now));
    },
    addBank() {
      if (!this.settings.identity_verified) {
        Message.error(this.$i18n.t('funds.withdrawals.errors.kyc'), {}, { position: 'top_right' });
        return;
      }
      if (this.numberOfBankAccount == this.limitBankAccount) {
        Message.error(
          this.$i18n.t('bank_account_management.max_bank_account', {
            limit: this.limitBankAccount,
          }),
          {},
          { position: 'top_right' }
        );
        return;
      }
      window.CommonModal.show('addBankForm');
    },
  },
  created() {
    this.loadDataImmediately();
    this.getFeeTierStatus();
    this.getReferralSetting();
    document.title = this.$t('menu.account') + ` – ${this.tabTitle}`;
  },
};
</script>

<style lang="scss">
#account {
  max-width: 1370px;
  padding: 40px 40px 80px;
  margin: 0 auto;
  background: $bg-main3;
  position: relative;

  #overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }

  @include mobile {
    padding: 16px;
  }

  .card {
    border-radius: 7px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: $color-white;

    &__row {
      &--title {
        margin-bottom: 8px;
        color: $text-secondary;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;

        @include mobile {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;

          & + div {
            text-align: right;
          }
        }

        & + div {
          color: $text-main;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
        }
      }
    }

    &__actions {
      margin-top: 16px;
    }

    @include mobile {
      display: block;

      &__row {
        display: flex;
        gap: 0 8px;
        justify-content: space-between;
      }
    }
  }

  .btn-groups {
    display: flex;

    .btn-space {
      width: 100%;
    }
  }

  #addBankForm .modal-dialog {
    width: 414px;
  }

  .modal-dialog {
    width: 432px;
  }

  .modal-content {
    box-shadow: none;
    border: none;
    border-radius: 10px;
  }

  #add-bank-modal {
    .modal-body {
      .content_text {
        min-height: unset !important;
      }

      @include mobile {
        padding: 16px 16px 24px;
      }
    }
  }

  #confirmationModal .modal-dialog .modal-content {
    @include mobile {
      border-radius: 10px;
    }
  }

  .group_custom {
    min-height: 405px;

    .group {
      display: flex;
      background-color: $color-white;
      width: 100%;
      margin-bottom: 10px;
      padding-right: 20px;

      .mgt38 {
        margin-top: 47px;
      }
    }
  }

  .group_custom-2 {
    min-height: inherit;
    border-radius: 10px;
    transition: all 0.3s;

    .cursor {
      cursor: pointer;
    }

    .group {
      margin-bottom: 0px;
      border-radius: 10px;
      padding: 16px;
      gap: 16px;
      display: flex;
      align-items: flex-start;
      text-align: left;

      @include mobile {
        gap: 10px;
      }

      @include tablet {
        gap: 10px;
      }
    }

    .group-password {
      align-items: center;
      // height: 95px;
    }

    &:hover {
      box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
    }
  }

  .disabled_item {
    background-color: $color-bright-gray;
    border: none;
    cursor: not-allowed;
    pointer-events: none;
    color: $color_black;
    border: solid 1px $color-alto;
  }

  .break_boder {
    width: calc(100% - 30px - 30px - 15px);
    height: 1px;
    opacity: 0.7;
    border-radius: 1px;
    background-color: $color-alto;
    clear: both;
    margin: auto;
  }

  .checkbox-bank {
    label {
      display: inline;
      font-weight: normal;
    }
  }

  .table-device,
  .table-bank-account {
    .icon-close,
    .glyphicon {
      font-weight: 600;
      font-size: 16px;
      color: $color-green;

      &:hover,
      &:focus {
        color: $color-eden;
      }
    }

    .glyphicon {
      font-weight: normal;
      font-size: $font-medium;
    }

    table {
      tbody {
        height: auto;

        tr:not(:last-child) {
          border-bottom: 1px solid $bg-main2;
        }
      }
    }

    tr {
      height: auto;

      &.double_tr {
        padding: 0 21px;

        th {
          font-size: $font-root;
          color: $color-grey;
          line-height: 20px;
        }
      }

      &.double_tr2 {
        padding: 0 21px;
        display: grid;
        grid-template-columns: 1fr 0.9fr 1fr 87px;

        th {
          width: 100%;
          padding: 10px 0px;
          color: rgba($color: $text-main2, $alpha: 0.6);
          text-align: left;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
        }
      }

      &.bank-manager {
        padding: 0 21px;
        display: grid;
        grid-template-columns: 1fr 0.9fr 1fr 87px;

        th {
          &.active {
            max-height: 300px;
            transition-property: height;
            transition-duration: 0.3s, 0.3s;
            transition-timing-function: ease, ease-in;

            .glyphicon-menu-down {
              transition-duration: 0.5s;
              transform: rotate(180deg);
            }
          }
        }

        td {
          width: 100%;
          text-align: left;
          height: auto;
          color: rgba($color: $text-main2, $alpha: 0.87);
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          margin: auto 0;

          &:last-child {
            text-align: right;
          }
        }

        .actions {
          display: flex;
          justify-content: end;
        }
      }

      &.device-manager {
        padding: 0 21px;
        display: flex;
        align-items: center;

        th {
          &.active {
            max-height: 300px;
            transition-property: height;
            transition-duration: 0.3s, 0.3s;
            transition-timing-function: ease, ease-in;

            .glyphicon-menu-down {
              transition-duration: 0.5s;
              transform: rotate(180deg);
            }
          }
        }

        td {
          text-align: left;
          height: auto;
          color: rgba($color: $text-main2, $alpha: 0.87);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          margin: auto 0;
        }
      }
    }

    .odd {
      background-color: $color-white;
    }
  }

  .datatable {
    border-radius: 10px;

    table {
      border-radius: 10px;
    }

    tr {
      height: auto;

      &:hover {
        background-color: $color-bright-gray;
      }

      &.empty-data td {
        width: 100%;
        background-color: $color-white;

        .empty-noti {
          height: 280px;

          .icon-nodata {
            @include mobile {
              width: 100px;
              height: 100px;
            }
          }
        }
      }

      &.double_tr {
        padding: 0 21px;

        th {
          color: rgba($color: $text-main2, $alpha: 0.6);
          text-align: left;
          font-size: 14px;
          font-weight: 700;
          line-height: 24px;
          padding: 10px 0;
        }
      }

      th {
        width: 33.33%;
        color: rgba($color: $text-main2, $alpha: 0.6);
        text-align: left;
        font-size: 14px;
        font-weight: 700;
        line-height: 24px;
      }

      td {
        padding: 10px 0px;
        line-height: 20px;
        width: 33.33%;
        height: 100%;
      }

      &.last-login {
        padding: 0 21px;

        td {
          text-align: left;
          color: rgba($color: $text-main2, $alpha: 0.87);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }
  }

  .device-management {
    display: inline-block;
    width: 100%;
    margin-top: 24px;

    .datatable tr.empty-data td .empty-noti .msg-empty {
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
    }

    table {
      th,
      td {
        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .path_last_login {
    display: inline-block;
    width: 100%;
    margin-top: 26px;

    table {
      thead {
        tr {
          th {
            font-size: $font-smaller;
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: $font-small;
          }
        }
      }
    }
  }
}

.path_last_login .scroll-table table tbody {
  height: 430px;
  margin-bottom: 10px;
}
</style>

<style lang="scss" scoped>
@import '@/assets/sass/common.scss';

:deep() {
  #confirmationModal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .close {
            display: none;
          }
        }

        .modal-body {
          .content_text {
            margin-top: 32px;
            font-size: 18px;
            font-weight: 700;
            line-height: 32px;
            padding: 0 6px;
          }
        }
      }
    }
  }
}

.txt-pre-line {
  white-space: pre-line;
}

.error-msg {
  display: inline-block;
  margin-top: 5px;
  height: 20px;
  @include text-font($helvetica, $secondary-red, $font-smaller, 500, 20px);
}

#chart_contracts {
  height: calc(100vw * 0.35);
  min-height: 495px;
  border: 1px solid $color-grey;
  margin-top: 15px;
}

.disable-class {
  cursor: not-allowed;
}

.option-leaderboard {
  display: flex;
  align-items: center;

  span {
    color: rgba(2, 0, 13, 0.6);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.fake-name-contain {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 18px;

  @include mobile {
    font-size: 14px;
    line-height: 24px;
  }

  .input-wrapper {
    display: flex;
    align-items: center;
  }

  input {
    border: 1px solid $grey-1;
    border-radius: 12px;
    padding: 10px 16px;
    color: $text-main;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    font-style: normal;
    margin: 0 10px;

    @include mobile {
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px;
    }
  }

  & > span:first-child {
    margin-right: 5px;
    color: $text-main;
  }

  .fake-name-span {
    word-break: break-all;
    margin-right: 10px;
    color: $text-main;
  }

  .action-button-wrapper {
    display: inline-block;
  }

  .btn_edit_network,
  .btn_save_network,
  .btn_close_network {
    color: $color-jungle-green;
    line-height: 23px;
    padding: 0;

    &:active {
      box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0);
    }

    .icon-edit:before,
    .icon-save:before,
    .icon-close2:before {
      color: $color-jungle-green;
    }

    &:hover {
      .icon-edit:before,
      .icon-save:before,
      .icon-close2:before {
        color: $color-blue-custom;
      }
    }
  }
}

.fake-name-contain-column {
  flex-direction: column;
  align-items: flex-start;

  .input-wrapper {
    margin-top: 9px;

    @include tablet {
      width: 100%;
    }

    @include mobile {
      width: 100%;
    }

    input {
      margin-left: 0;

      @include tablet {
        flex: 1;
      }

      @include mobile {
        flex: 1;
      }
    }

    .action-button-wrapper {
      .btn_close_network {
        margin-right: 10px;
      }
    }
  }
}

.fee-wallet {
  flex: 1;
}

.group-flex {
  display: flex;

  .group-wallet {
    border: none;

    .dropdown {
      button {
        border: 1px solid $grey-1;
        display: flex;
        color: $dark-1;
        background: white;
        font-family: $helvetica;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 20px;
        min-width: 201px;
        padding: 10.5px 16px;
        justify-content: space-evenly;
        border-radius: 12px;
        align-items: center;
        height: 41px;

        .paddingt-2 {
          padding-top: 2px;

          span.icon-arrow1 {
            margin-left: 23px;
            color: #2dac91;
          }
        }
      }

      ul.dropdown-menu {
        min-width: 201px;
        box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
        border-radius: 10px;

        .tab-element {
          text-align: left;
          min-height: 35px;
          padding: 10.5px 20px;
          font-size: 16px;
          line-height: 20px;
          font-family: $helvetica;
          color: $dark-1;

          &:hover {
            background: $grey-3;
          }
        }
      }
    }
  }
}

.title-medium-small {
  color: rgba($text-main2, 0.87);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;

  @include mobile {
    font-size: 18px;
    line-height: 32px;
  }
}

.mgb-18 {
  margin-bottom: 18px;
}

.mgb-20 {
  margin-bottom: 20px;
}

.mgb-30 {
  margin-bottom: 30px;
}

.white-list-height {
  line-height: 23px;
}

.white-list {
  margin: 4px 0;
  display: flex;
  align-items: center;

  .white-list-title {
    color: rgba($text-main2, 0.87);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-right: 16px;

    @include mobile {
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: 16px;
    }
  }
}

.account_container {
  display: inline-block;
  width: 100%;
  padding: 0px;

  .title-header {
    margin-bottom: 24px;
  }

  @include mobile {
    .title-header {
      margin-bottom: 15px;
      @include text-font($helvetica, $dark-1, 20px, 700, 25px);
    }
  }

  @include tablet {
    .title-header {
      margin-bottom: 15px;
      @include text-font($helvetica, $dark-1, 20px, 700, 25px);
    }
  }
}

.path_acount_container {
  width: 100%;
}

.path_leaderboard {
  margin-top: 24px;

  .content_pick_leaderboard {
    background-color: $color-white;
    padding: 25px;

    @include mobile {
      padding: 16px;
    }
  }
}

.path_address_container {
  display: flex;
  width: 100%;
  height: 300px;
  margin-top: 15px;
}

.path_device_management {
  width: 100%;
  margin-top: 60px;
}

.account_info {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  gap: 24px;

  .left_acc_info {
    flex: 1;
    display: flex;
    gap: 24px;
    align-items: center;
    background: $text-main1;
    padding: 24px 30px;
    border-radius: 10px;
    min-height: 244px;

    .email-phone {
      margin-bottom: 8px;
    }

    .email_acc {
      color: rgba($text-main2, 0.87);
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 8px;
    }
  }

  .right_acc_info {
    flex: 1;
    background: $text-main1;
    padding: 24px 32px;
    border-radius: 10px;
    max-width: calc(50% - 12px);
    min-height: 244px;
  }

  @include mobile {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;

    .left_acc_info {
      flex-direction: column;
      width: 100%;
      padding: 16px;

      .sub_acc_info {
        align-items: center;
        margin-right: unset;
        text-align: center;

        .text_account {
          flex-direction: column;
          gap: 8px;
        }
      }

      .img-user {
        img {
          width: 81px;
          height: 81px;
        }
      }

      .lv_content {
        justify-content: center;
      }
    }

    .right_acc_info {
      display: flex;
      flex-direction: column-reverse;
      max-width: none;
      min-height: 218px;
      width: 100%;
      padding: 16px;

      .road_lv {
        flex-direction: column;
        border-left: 4px solid $bg-main2;
        margin-left: 16px;
        margin-top: 16px;
        gap: 0;
      }

      .item_road {
        border-bottom: unset;
        display: flex;
        flex-direction: column-reverse;
        text-align: left;
        padding-left: 16px;
        padding-bottom: 13px;

        .text_milestone {
          padding: 0;
          margin-bottom: 0;

          .nb_read_item {
            margin: 0 0 3px;
          }
        }

        .title_step {
          display: flex;
          flex-direction: column-reverse;
        }

        .milestone {
          margin-bottom: 16px;
        }

        .level {
          & > div {
            justify-content: flex-start;
          }

          img {
            width: 18px;
            height: 18px;
          }

          span {
            color: rgba($text-main2, 0.38);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }

      .submit_contact_container {
        flex-direction: column;
        gap: 8px;
        align-items: flex-start;
        margin-top: 0;

        .submit_contact {
          width: auto;
        }

        &.single-child {
          justify-content: center;
          gap: 10px;
        }
      }
    }
  }

  @include tablet {
    flex-direction: column;

    .left_acc_info {
      flex-direction: column;
      width: 100%;

      .sub_acc_info {
        align-items: center;
        margin-right: unset;
        text-align: center;

        .text_account {
          flex-direction: column;
          gap: 5px;

          .text_account__right {
            padding-bottom: 15px;
          }
        }
      }

      .img-user {
        img {
          width: 81px;
          height: 81px;
        }
      }
    }

    .right_acc_info {
      display: flex;
      flex-direction: column-reverse;
      margin-left: unset;
      max-width: none;
      width: 100%;
      border-left: none;

      .submit_contact_container {
        margin-bottom: 15px;
        justify-content: center;

        .submit_contact {
          width: auto;
        }

        &.single-child {
          gap: 0;
        }
      }
    }
  }
}

.login_password {
  width: 100%;
  background-color: transparent;
}

.ni_bank_acc {
  width: 20.5%;
  min-width: 236px;
  height: 100%;
  margin-right: 15px;
  background-color: $color-white;
  text-align: center;

  .icon-bank {
    font-size: 60px;
    width: 60px;
    height: 60px;
    margin-top: 80px;
    margin-bottom: 20px;
    color: $color-grey-sliver;
  }

  .text_bank {
    font-size: $font-big-20;
    line-height: 22px;
    color: $color-black;
    font-weight: 600;
    margin-bottom: 9px;
  }

  .butt_unver_bank {
    background: $color-blue-cerulean-light;
    color: $color-white;
    font-size: $font-small;
    font-weight: 700;
    height: 21px;
    line-height: 21px;
    padding: 0px 11px;
    border-radius: 15px;
    margin-bottom: 25px;
  }

  .butt_verify_bank {
    width: 110px;
    height: 32px;
    border: 1px solid $color-yellow;
    color: $color-yellow;
    font-size: $font-small;
    font-weight: 700px;
    text-align: center;
    padding: 5px 10px;
    line-height: 22px;
    background: transparent;
    border-radius: 20px;
    font-weight: 700;
    margin-bottom: 47px;
  }

  .error_bank_acc {
    color: $color-red-warning;
    font-size: $font-small;
    line-height: 20px;
    width: 165px;
    text-align: center;
  }
}

.google_authen {
  width: 100%;
  height: auto;
  background-color: transparent;
  text-align: center;

  .form-check-label {
    margin-top: 18px;

    a {
      font-size: $font-small;
    }
  }
}

.background_white {
  background-color: transparent;
}

.address_management {
  width: 100%;
  background-color: $color-white;
  display: flex;
  align-items: flex-start;
  padding: 15px;
  gap: 15px;
  border-radius: 10px;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
  }
}

.icon-account {
  height: 50px;
  width: 50px;

  @include mobile {
    width: 40px;
    height: 40px;
  }

  @include tablet {
    width: 40px;
    height: 40px;
  }
}

.fee-tier-program {
  background-color: $color-white;
  padding: 15px;
  border-radius: 10px;
  height: auto;
  display: flex;
  align-items: flex-start;
  gap: 15px;

  @include mobile {
    gap: 12px;
  }

  &.enableOtp {
    min-height: 125px;
  }

  .group-right {
    height: 100%;

    .group-title {
      @include text-font($helvetica, $dark-1, $font-big, 700, 23px);

      @include tablet-d {
        @include text-font($helvetica, $dark-1, $font-root, 700, 18px);
      }
    }

    .group-description {
      margin-top: 5px;
      @include text-font($helvetica, $dark-3, $font-smaller, 500, 20px);

      @include tablet-d {
        @include text-font($helvetica, $dark-3, $font-smaller, 500, 20px);
      }
    }
  }
}

.referral_program {
  width: 100%;
  background-color: transparent;
}

.text_title_table {
  color: $color-eden;
  margin-bottom: 15px;
  font-family: $font-family-roboto-medium;
}

.table_descripe {
  color: rgba($text-main2, 0.6);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  margin-top: 4px;
  margin-bottom: 20px;

  @include mobile {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px;
  }
}

.device_table {
  width: 100%;
  height: 100px;
  background-color: $color-white;
}

.login_table {
  width: 100%;
  height: 250px;
  background-color: $color-white;
}

.fs11 {
  font-size: $font-mini;
}

.fs12 {
  font-size: $font-smaller;
}

.fs13 {
  font-size: $font-small;
}

.fs20 {
  font-size: $font-big-20;
}

.img-user {
  img {
    width: 121px;
    height: 121px;
    border-radius: 50%;
  }
}

.lv_content {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;
}

.btn_common {
  border-radius: 30px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  color: $color-white;
  padding: 3px 12px;
  line-height: 24px;
}

.email,
.phone {
  color: rgba($text-main2, 0.87);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  svg {
    margin-left: 4px;
  }

  @include mobile {
    font-size: 14px;
    line-height: 22px;
  }
}

.lv_text {
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    color: rgba($text-main2, 0.87);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    @include mobile {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.btn_unverified {
  background-color: $bg-main1;
}

.btn_verified {
  background-color: $color-green;
}

.btn_pending {
  background-color: $color-yellow;
}

.btn_rejected {
  background-color: $color-red;
}

.btn_general {
  background-color: $color-grey;
}

.margin_auto {
  margin: auto;
}

.text_account {
  flex-wrap: wrap;
  display: flex;
  gap: 0 24px;

  span {
    color: rgba($text-main2, 0.6);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    white-space: nowrap;
  }
}

.submit_contact_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 7px;
  gap: 10px;

  .submit_contact {
    width: calc(25% - 5px);
  }

  .submit_contact > * {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    text-align: center;
  }
}

.submit {
  color: $text-main2;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  @include mobile {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }
}

.border_bot {
  border-bottom: 1px solid $color-yellow;
}

.contact_us {
  cursor: pointer;
  // float: right;
  // width: 100px;
  color: #ffffff;
  // margin-right: 5px;
}

// .link {
//   margin-right: 7px;
//   margin-bottom: 5px;
// }

// .h-18 {
//   height: 18px;
// }

.icon-login-password {
  font-size: 50px;
  width: 50px;
  height: 50px;
  color: $color-grey-sliver;
}

.icon-referral-program {
  font-size: 50px;
  width: 50px;
  height: 50px;
  color: $color-grey-sliver;
}

.icon-shield {
  font-size: 50px;
  width: 50px;
  height: 50px;
  color: $color-grey-sliver;
}

.text_login {
  color: rgba($color: $text-main2, $alpha: 0.87);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;

  @include mobile {
    font-size: 16px;
    line-height: 24px;
  }
}

.btn_account {
  height: 35px;
  width: 110px;
  text-align: center;
  border-radius: 3px;
  font-weight: 600;
  border: 1px solid $color-jungle-green;
  color: $color-white;
  font-size: $font-smaller;
  text-transform: uppercase;
  display: inline-block;
  padding: 7px 0px;
  line-height: 20px;
  overflow: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: $color-jungle-green;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-blue-custom;
    border-color: $color-blue-custom;
    color: $color-white;
    outline: none;
  }
}

.btn_border {
  background-color: transparent;
  color: $color-jungle-green;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-blue-custom;
    border-color: $color-blue-custom;
    color: $color-white;
  }
}

.btn_info {
  background-color: $color-curious-blue;
  border-color: $color-curious-blue;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-blue-custom-2;
    border-color: $color-blue-custom-2;
    outline: none;
  }
}

.marr10 {
  margin-right: 10px;
}

.referral_program {
  .btn_active {
    width: 150px;
    margin-top: 0;
  }
}

.text_white {
  color: $color-white;
}

.text_yellow {
  color: $color-yellow;
}

.icon-note {
  font-size: $font-big-20;
  width: 20px;
  height: 20px;
}

.tooltip-wrapper {
  display: flex;

  .help-box {
    position: relative;

    img {
      cursor: pointer;
    }
  }

  .box-tooltip {
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 100%;
      translate: -50%;
      border: 5px solid transparent;
      border-bottom-color: $tool-tip-color;

      @include mobile {
        left: 65%;
      }
    }

    width: 320px;
    position: absolute;
    display: none;
    z-index: 999999;
    text-align: left;
    background: $bg-main1;
    left: 50%;
    top: calc(100% + 0.75rem);
    translate: -50%;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 6px;

    color: var(--default-white, #fff);
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    padding: 6px 8px;

    @include mobile {
      translate: -65%;
    }
  }

  &:hover .box-tooltip {
    display: block;
  }
}

.tooltip-order {
  left: 20px;
  top: 60px;
  width: 200px;
  height: 200px;
  background-color: $background-default;
  z-index: 1000;
  border: 1px solid $color-grey-border;
  position: absolute;
  border-radius: 15px;
  color: $color-grey-dark;
  font-weight: normal;
  font-size: $font-small;
  box-shadow: 1px 1px 1px 1px $color-grey-border;
  line-height: 20px;
}

.text-tooltip {
  margin: 10px 10px 10px;
}

.road_lv {
  display: flex;
  gap: 10px;

  .item_road {
    flex: 1;
    text-align: center;
    border-bottom: 4px solid $bg-main2;
    max-width: 25%;

    &.step_active {
      border-bottom: 4px solid $bg-main1;

      @include mobile {
        border-bottom: unset;
        border-left: 4px solid $bg-main1;
        margin-left: -4px;
      }
    }

    .text_milestone {
      margin-bottom: 5px;
      padding: 0 3px;

      span {
        color: rgba($color: $text-main2, $alpha: 0.38);
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }

      .nb_read_item {
        color: rgba($color: $text-main2, $alpha: 0.87);
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        overflow-wrap: break-word;
      }

      .description {
        margin: 4px 0;
        color: $color-yellow;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }

    @include mobile {
      max-width: 100%;

      .text_milestone {
        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
        }

        .nb_read_item {
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }

        .description {
          display: none;
        }
      }
    }
  }
}

.level {
  margin: auto;
  text-align: center;
  position: relative;
  float: left;
  width: 100%;
  color: $text-main-blur;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;

  img {
    width: 18px;
    height: 18px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }

  strong {
    color: rgba($color: $text-main2, $alpha: 0.38);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  strong.step_active {
    color: $color-white;
  }
}

.list_option_acc {
  margin-top: 24px;
  display: flex;
  gap: 24px;
  width: 100%;

  .link {
    cursor: pointer;

    &.disable {
      touch-action: none;
      pointer-events: none;
      opacity: 0.6;
    }
  }

  @include tablet {
    flex-direction: column;
    gap: 0;

    .mgb20 {
      margin-bottom: 21px;
    }

    .left_list_option_acc,
    .right_list_option_acc {
      padding: 0;
    }
  }

  @include mobile {
    margin-top: 0;
    flex-direction: column;
    gap: 0;

    .mgb20 {
      margin-bottom: 12px;
    }

    .left_list_option_acc,
    .right_list_option_acc {
      padding: 0;
    }
  }

  .left_list_option_acc,
  .right_list_option_acc {
    flex: 1;
  }
}

.mgb20 {
  margin-bottom: 20px;
  background-color: $color-white;
}

.mgb-5 {
  margin-bottom: 5px;
}

.mgb20 {
  margin-bottom: 20px;
}

.box_icon_option {
  display: block;
  width: 110px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  float: left;
  font-size: 50px;
  color: $color-eden;

  &.box_icon_option_api {
    height: 140px;
  }
}

.group_center_option {
  display: flex;
  width: 100%;
  position: relative;
  line-height: 20px;
  justify-content: space-between;
  align-items: center;

  @include mobile {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  @include tablet {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  // &.group_center_option_api {
  //    height: 140px;
  // }
}

.center_group {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: left;
}

.link_account,
.check_authen_activation {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  color: $text-main-blur;

  a {
    color: $color-blue;

    &:hover {
      text-decoration: none;
    }
  }

  .google-authen {
    color: $color-blue;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

.link_api {
  color: $color-blue;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
}

.referral-height {
  margin-top: 4px;
  height: 40px;
}

.link_button {
  color: $primary-2;
}

.right_withdrawal_acc {
  display: block;
  width: 100%;
  float: left;
  position: relative;
  line-height: 20px;
  font-size: $font-small;
  color: $color-grey-dusty;
}

:deep(.custom_label_withdrawal_address_management) {
  .content_text {
    font-family: Helvetica;
    font-size: 14px !important;
    font-weight: 300 !important;
    line-height: 22px !important;
  }
}

.icon_if_w {
  width: 18px;
  height: 18px;
  font-size: $font-big;
  color: $color-alizarin-crimson;
}

.btn-address {
  @include btn-common(contained, md);

  @include mobile {
    font-size: 14px;
    line-height: 24px;
  }

  display: inline-block;
  margin-top: 16px;
  width: auto;
}

.btn_active {
  height: 35px;
  max-width: 100%;
  width: 246px;
  margin-right: 15px;
  text-align: center;
  margin-top: 5px;
  background-color: $color-jungle-green;
  border-radius: 3px;
  font-weight: 600;
  color: $color-white;
  font-size: $font-smaller;
  text-transform: uppercase;
  display: inline-block;
  padding: 8px 0px;
  line-height: 20px;
  overflow: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;

  &:hover,
  &:active,
  &:focus {
    background-color: $color-blue-custom;
    border-color: $color-blue-custom;
    outline: none;
  }
}

.no-shadow {
  i {
    box-shadow: none !important;
  }
}

.link_referral {
  a {
    color: $color-blue;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    text-decoration: none !important;
  }
}

.mgb0 {
  margin-bottom: 0px;
}

.title-bank {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  button {
    background-color: $color-light-grey;

    img {
      @include mobile {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.btn-remove {
  @include btn-common(outlined, sm, false);
  background: none;
  border-color: $color-red;
  color: $color-red;
  width: auto;
  height: 32px;
  width: 87px;

  &.ml {
    margin-left: auto;
  }

  @include mobile {
    font-size: 12px;
    line-height: 16px;
    width: 71px;
    height: 32px;
  }
}

.btn-add-bank {
  border: 1px solid $color-black;
  padding: 13px;
  display: flex;
  align-items: center;

  span {
    font-size: 20px;
  }
}

.item-bank {
  display: grid;
  grid-template-columns: auto auto;
  padding: 16px;
  gap: 16px 0;
  background: $text-main1;
  border-radius: 10px;

  .text1 {
    color: $text-main;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;

    @include mobile {
      margin-bottom: 3px;
    }
  }

  .text2 {
    color: $text-main-blur;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
  }
}
</style>
