<template>
  <div id="price_other_exchange">
    <ul class="list_coin_compare clearfix">
      <li v-for="(value, key) in prices" :key="key">
        <span class="name">{{ key | upperFirst }}</span>
        <span class="price">{{
          value | formatCurrencyAmount("idr", "0")
        }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import rf from "@/request/RequestFactory";

export default {
  data() {
    return {
      prices: [],
    };
  },
  methods: {
    getSocketEventHandlers() {
      return {
        CoinCheckBtcUsdExchangesUpdated: this.onBtcUsdExchangesUpdated,
      };
    },

    onBtcUsdExchangesUpdated(data) {
      this.prices = data || [];
    },
  },
  created() {
    rf.getRequest("CoinCheckRequest")
      .getPriceBtcUsdExchanges()
      .then((res) => {
        this.prices = res.data || [];
      });
  },
};
</script>

<style lang="scss" scoped>
#price_other_exchange {
  display: inline-block;
  float: right;
  ul.list_coin_compare {
    border-left: 1px solid $color-alto;
    padding-left: 15px;
    margin-top: 12px;
    margin-bottom: 11px;
    display: block;
    // min-width: 215px;
    // max-width: 410px;
    width: 250px;
    list-style-type: none;
    line-height: 18px;
    li {
      float: left;
      width: 50%;
      font-weight: 600;
      font-size: $font-smaller;
      padding-right: 15px;
      .name {
        min-width: 55px;
        display: inline-block;
        float: left;
        color: $color-grey;
        margin-right: 4px;
      }
      .price {
        display: block;
        overflow: hidden;
        color: $color-grey-dark;
        text-overflow: ellipsis;
        max-width: 150px;
        white-space: nowrap;
      }
    }
  }
}
</style>
