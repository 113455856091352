<template>
  <div class="coin_input">
    <input
      type="text"
      class="form-control input-coin-number"
      :placeholder="placeholder"
      v-bind:class="{ 'border-bottom': component === OrderForm }"
      :value="value"
      :disabled="disabled"
      v-if="inputText"
    />
    <currency-input
      :class="getClasses()"
      :precision="getPrecision()"
      :maxLengthCus="16"
      v-model="internalValue"
      :placeholder="placeholder"
      :disabled="disabled"
      :ref="refCurrencyInput"
      v-else
    />

    <span :class="{ r10i: !visibleButton }" v-if="visibleUnit">{{
      unit | uppercase
    }}</span>
    <button
      tabindex="32766"
      class="plus"
      v-if="visibleButton"
      @click="onIncreaseClicked()"
    >
      <img src="/images/spot_exchange/carret-up.svg" alt="" />
      <!-- <span class="icon-arrow2"></span> -->
    </button>
    <button
      tabindex="32767"
      class="minus"
      v-if="visibleButton"
      @click="onDecreaseClicked()"
    >
      <img src="/images/spot_exchange/carret-down.svg" alt="" />
      <!-- <span class="icon-arrow1"></span> -->
    </button>
  </div>
</template>
<script>
import BigNumber from "bignumber.js";
import CurrencyInput from "./CurrencyInput";

export default {
  components: {
    CurrencyInput,
  },
  props: {
    value: {},
    precision: { type: Number },
    unit: { type: String },
    disabled: { type: Boolean, default: false },
    inputText: { type: Boolean, default: false },
    visibleButton: { type: Boolean, default: false },
    classes: { type: String },
    placeholder: { type: String },
    component: { type: String },
  },
  data() {
    return {
      internalValue: "",
      refCurrencyInput: this.getRefUniqueId(),
      OrderForm: "order-form",
    };
  },
  watch: {
    value(newValue) {
      this.updateValue();
    },
    precision(newPrecision) {
      this.updatePrecision(newPrecision);
    },
    internalValue(val) {
      this.$emit("input", val);
    },
  },
  computed: {
    visibleUnit() {
      return !window._.isEmpty(this.unit);
    },
  },
  methods: {
    onIncreaseClicked() {
      this.internalValue = this.newBigNumber(this.internalValue)
        .plus(`${this.precision}`)
        .toString();
    },

    onDecreaseClicked() {
      const result = this.newBigNumber(this.internalValue).sub(
        `${this.precision}`
      );
      if (result.gte(this.precision)) {
        this.internalValue = result.toString();
      }
    },

    getClasses() {
      if (!this.classes) {
        return "input-coin-number";
      }
      return `input-coin-number ${this.classes}`;
    },

    getPrecision() {
      return this.unit === "idr" ? 0 : 8;
    },

    getRefUniqueId() {
      return window._.uniqueId("input-coin-number-");
    },

    newBigNumber(value) {
      if (window._.isEmpty(value)) {
        value = 0;
      }
      return new BigNumber(value);
    },

    updateValue() {
      if (this.value instanceof BigNumber) {
        this.internalValue = this.value.toString();
        return;
      }
      this.internalValue = this.value;
    },

    updatePrecision(newPrecision) {
      const precision = Math.round(Math.log(1 / newPrecision) / Math.log(10));
      this.$refs[this.refCurrencyInput].setPrecision(precision);
    },
  },
  mounted() {
    this.updateValue();
  },
};
</script>
<style lang="scss" scoped>
.coin_input {
  position: relative;

  input[type="text"] {
    height: 40px;
    border: 1px solid $bg-main2;
    border-radius: 5px;
    width: 100%;
    padding: 8px 70px 8px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: $text-main;
    background-color: $color-white;

    &.input-total {
      padding-right: 60px;
    }

    &:focus {
      border-color: $text-main2;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-size: 16px;
      color: $dark-4;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      font-size: 16px;
      color: $dark-4;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      font-size: 16px;
      color: $dark-4;
    }
  }

  & > span {
    position: absolute;
    top: 0;
    right: 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    top: 50%;
    transform: translateY(-50%);
    color: $dark-1;
    text-transform: uppercase;

    &.r10i {
      right: 15px;
    }
  }

  button {
    position: absolute;
    right: 6px;
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 0;

    &:focus {
      outline: 0;
    }

    &.plus {
      top: 1px;
      border-top: none;

      & > span {
        position: absolute;
        top: 4px;
        right: 7px;
        color: $dark-3;
        line-height: 20px;
        font-size: 10px;
      }

      & img {
        margin-top: 6.5px;
      }
    }

    &.minus {
      bottom: 1px;

      & > span {
        position: absolute;
        bottom: 3px;
        right: 7px;
        color: $dark-3;
        line-height: 20px;
        font-size: 10px;
      }

      & img {
        margin-bottom: 6.5px;
      }
    }
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }
}
</style>
