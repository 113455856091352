<template>
  <div class="term-page">
    <div class="title">{{ $t('terms.title') }}</div>
    <div class="time-revised">
      <p>{{ $t('terms.last_revised') }}</p>
    </div>
    <div class="content-wrap">
      <div class="term-content">
        <div class="content-ac">
          <p v-html="$t('terms.introduce_1', { APP_URL: APP_URL })"></p>
          <p v-html="$t('terms.introduce_2')"></p>
        </div>
      </div>

      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_1.title') }}</p>
        </div>
        <div class="content-ac">
          <p>
            {{ $t('terms.term_1.content_1_1') }}
          </p>
          <p v-html="$t('terms.term_1.content_1_2')"></p>
          <p>
            {{ $t('terms.term_1.content_1_3') }}<br />
            {{ $t('terms.term_1.content_1_3_1') }}
          </p>
          <div>
            <div>
              {{ $t('terms.term_1.content_1_4') }}
            </div>
            <div>
              {{ $t('terms.term_1.content_1_4_1') }}
            </div>
            <div>
              {{ $t('terms.term_1.content_1_4_2') }}
            </div>
            <div>
              {{ $t('terms.term_1.content_1_4_3') }}
            </div>
            <div>
              {{ $t('terms.term_1.content_1_4_4') }}
            </div>
            <div>
              {{ $t('terms.term_1.content_1_4_5') }}
            </div>
            <div>
              {{ $t('terms.term_1.content_1_4_6') }}
            </div>
            <div>
              {{ $t('terms.term_1.content_1_4_7') }}
            </div>
          </div>
        </div>
      </div>

      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_2.title') }}</p>
        </div>
        <div class="content-ac">
          <p>
            {{ $t('terms.term_2.content_2_1') }}
          </p>
        </div>
      </div>

      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_3.title') }}</p>
        </div>
        <div class="content-ac">
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_3.title_1') }}
            </p>
            <p>
              {{ $t('terms.term_3.content_3_1_1') }}
            </p>
            <p>
              {{ $t('terms.term_3.content_3_1_2') }}
            </p>
          </div>
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_3.title_2') }}
            </p>
            <p>
              {{ $t('terms.term_3.content_3_2_1') }}
            </p>
          </div>
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_3.title_3') }}
            </p>
            <p v-html="$t('terms.term_3.content_3_3_1')"></p>
            <p v-html="$t('terms.term_3.content_3_3_2')"></p>
            <p>
              {{ $t('terms.term_3.content_3_3_3') }}
            </p>
            <p>
              {{ $t('terms.term_3.content_3_3_4') }}
            </p>
          </div>
        </div>
      </div>

      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_4.title') }}</p>
        </div>
        <div class="content-ac">
          <p>
            {{ $t('terms.term_4.content_4_0') }}
          </p>
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_4.title_1') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_1_1') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_1_2') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_1_3') }}
            </p>
          </div>
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_4.title_2') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_2_0') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_2_1') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_2_2') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_2_3') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_2_4') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_2_5') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_2_6') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_2_7') }}
            </p>
          </div>
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_4.title_3') }}
            </p>

            <p>
              {{ $t('terms.term_4.content_4_3_1') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_3_1_0') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_3_1_1') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_3_1_1.introduce') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_3_1_2') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_3_1_2.introduce') }}
            </p>

            <p>
              {{ $t('terms.term_4.content_4_3_1_3') }}
            </p>
            <p>
              {{ $t('terms.term_4.content_4_3_1_3.introduce') }}
            </p>

            <p v-html="$t('terms.term_4.content_4_3_1_4')"></p>
          </div>
        </div>
      </div>
      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_5.title') }}</p>
        </div>
        <div class="content-ac">
          <p>
            {{ $t('terms.term_5.content_5_1') }}
          </p>

          <p>
            {{ $t('terms.term_5.content_5_2') }}
          </p>
          <p>
            {{ $t('terms.term_5.content_5_3') }}
          </p>
          <p>
            {{ $t('terms.term_5.content_5_4') }}
          </p>
          <p>
            {{ $t('terms.term_5.content_5_5') }}
          </p>
          <p>
            {{ $t('terms.term_5.content_5_6') }}
          </p>
          <p>
            {{ $t('terms.term_5.content_5_7') }}
          </p>
          <p>
            {{ $t('terms.term_5.content_5_8') }}
          </p>
        </div>
      </div>

      <div class="term-content">
        <div class="title-header">
          <!-- <p>6. Indemnity</p> -->
          <p>{{ $t('terms.term_6.title') }}</p>
        </div>
        <div class="content-ac">
          <p>
            {{ $t('terms.term_6.content_6_1') }}
          </p>
          <p>
            {{ $t('terms.term_6.content_6_2') }}
          </p>
        </div>
      </div>

      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_7.title') }}</p>
        </div>
        <div class="content-ac">
          <p>
            {{ $t('terms.term_7.content_7_0') }}
          </p>
        </div>
      </div>

      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_8.title') }}</p>
        </div>
        <div class="content-ac">
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_8.content_8_1') }}
            </p>
            <p>
              {{ $t('terms.term_8.content_8_1_1') }}<br />
              {{ $t('terms.term_8.content_8_1_2') }}<br />
              {{ $t('terms.term_8.content_8_1_3') }}<br />
              {{ $t('terms.term_8.content_8_1_4') }}<br />
              {{ $t('terms.term_8.content_8_1_5') }}<br />
              {{ $t('terms.term_8.content_8_1_6') }}<br />
              {{ $t('terms.term_8.content_8_1_7') }}<br />
              {{ $t('terms.term_8.content_8_1_8') }}<br />
              {{ $t('terms.term_8.content_8_1_9') }}<br />
              {{ $t('terms.term_8.content_8_1_10') }}
            </p>
          </div>
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_8.content_8_2') }}
            </p>
            <p>
              {{ $t('terms.term_8.content_8_2_1') }}
            </p>
            <p>
              {{ $t('terms.term_8.content_8_2_2') }}
            </p>
            <p>
              {{ $t('terms.term_8.content_8_2_3') }}
            </p>
            <p>
              {{ $t('terms.term_8.content_8_2_4') }}
            </p>
            <p>
              {{ $t('terms.term_8.content_8_2_5') }}
            </p>
          </div>
          <p>
            {{ $t('terms.term_8.content_8_3') }}
          </p>
          <p>
            {{ $t('terms.term_8.content_8_4') }}
          </p>
          <p v-html="$t('terms.term_8.content_8_5')"></p>
          <p>
            {{ $t('terms.term_8.content_8_6') }}
          </p>
          <p>
            {{ $t('terms.term_8.content_8_7') }}
          </p>
          <p>
            {{ $t('terms.term_8.content_8_8') }}
          </p>
          <p>
            {{ $t('terms.term_8.content_8_9') }}
          </p>
        </div>
      </div>

      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_9.title') }}</p>
        </div>
        <div class="content-ac">
          <p>
            {{ $t('terms.term_9.content_9_1') }}
          </p>
          <p>
            {{ $t('terms.term_9.content_9_2') }}
          </p>
          <p>
            {{ $t('terms.term_9.content_9_3') }}
          </p>
          <p>
            {{ $t('terms.term_9.content_9_4') }}
          </p>
        </div>
      </div>

      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_10.title') }}</p>
        </div>
        <div class="content-ac">
          <p>
            {{ $t('terms.term_10.content_10_1') }}
          </p>
          <p>
            {{ $t('terms.term_10.content_10_2') }}
          </p>
          <p>
            {{ $t('terms.term_10.content_10_3') }}
          </p>
          <p>
            {{ $t('terms.term_10.content_10_4') }}
          </p>
          <p>
            {{ $t('terms.term_10.content_10_5') }}
          </p>
        </div>
      </div>
      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_11.title') }}</p>
        </div>
        <div class="content-ac">
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_11.content_11_1') }}
            </p>
            <p>
              {{ $t('terms.term_11.content_11_1_1') }}
            </p>
          </div>
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_11.content_11_2') }}
            </p>
            <p>
              {{ $t('terms.term_11.content_11_2_1') }}
            </p>
          </div>
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_11.content_11_3') }}
            </p>
            <p>
              {{ $t('terms.term_11.content_11_3_1') }}
            </p>
          </div>
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_11.content_11_4') }}
            </p>
            <p>
              {{ $t('terms.term_11.content_11_4_1') }}
            </p>
          </div>
        </div>
      </div>
      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_12.title') }}</p>
        </div>
        <div class="content-ac">
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_12.content_12_1') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_1') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_2') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_3') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_4') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_5') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_6') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_7') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_8') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_9') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_10') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_11') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_12') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_13') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_14') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_15') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_16') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_17') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_18') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_19') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_20') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_21') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_22') }}
            </p>
            <p>
              {{ $t('terms.term_12.content_12_1_23') }}
            </p>
          </div>
          <p>
            {{ $t('terms.term_12.content_12_2') }}
          </p>
          <p>
            {{ $t('terms.term_12.content_12_3') }}
          </p>
        </div>
      </div>

      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_13.title') }}</p>
        </div>
        <div class="content-ac">
          <p>
            {{ $t('terms.term_13.content_13_1') }}
          </p>
          <div class="term__sub-content">
            <p>
              {{ $t('terms.term_13.content_13_2') }}
            </p>
            <p>
              {{ $t('terms.term_13.content_13_2_0_1') }}
              {{ $t('terms.term_13.content_13_2_0_2') }}<br />
            </p>
            <p>
              {{ $t('terms.term_13.content_13_2_1') }}
            </p>
            <p>
              {{ $t('terms.term_13.content_13_2_2') }}
            </p>
            <p>
              {{ $t('terms.term_13.content_13_2_3') }}
            </p>
            <p>
              {{ $t('terms.term_13.content_13_2_4') }}
            </p>
          </div>
        </div>
      </div>

      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_14.title') }}</p>
        </div>
        <div class="content-ac">
          <p>
            {{ $t('terms.term_14.content_14_1') }}
          </p>
          <p>
            {{ $t('terms.term_14.content_14_2') }}
          </p>
          <p>
            {{ $t('terms.term_14.content_14_3') }}
          </p>
          <p>
            {{ $t('terms.term_14.content_14_4') }}
          </p>
          <p>
            {{ $t('terms.term_14.content_14_5') }}
          </p>
          <p>
            {{ $t('terms.term_14.content_14_6') }}
          </p>
          <p v-html="$t('terms.term_14.content_14_7')"></p>
        </div>
      </div>

      <div class="term-content">
        <div class="title-header">
          <p>{{ $t('terms.term_end_note.title') }}</p>
        </div>
        <div class="content-ac">
          <p>
            {{ $t('terms.term_end_note.content_1') }}<br />
            {{ $t('terms.term_end_note.content_2') }}<br />
            {{ $t('terms.term_end_note.content_3') }}<br />
            {{ $t('terms.term_end_note.content_4') }}<br />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      APP_URL: process.env.VUE_APP_URL,
      urlPolicy: `${window.location.origin}/privacy-policy`,
    };
  },
  mounted() {
    document.title = this.$t('menu.terms') + ` – ${this.tabTitle}`;
  },
};
</script>
<style lang="scss" scoped>
.term-page {
  max-width: 1280px;
  margin: 0 auto;
  background-color: #f2f2f2;
  padding: 24px 0;
  font-family: $helvetica;

  .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 40px;
    color: $text-main;
    margin-bottom: 4px;
  }

  .time-revised {
    margin-bottom: 24px;

    p {
      font-size: 16px;
      // font-weight: 300;
      line-height: 24px;
      color: $text-secondary;
      line-height: 24px;
    }
  }

  .content-wrap {
    .content-general {
      margin-bottom: 24px;

      p {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        color: $text-secondary;
        margin: unset;

        :deep a {
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          color: rgba(2, 0, 13, 0.6);
        }
      }
    }

    .title-header {
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;
      color: $text-main;
      margin-bottom: 8px;

      p {
        margin-bottom: 0;
      }
    }

    .term-content {
      margin-bottom: 24px;

      .content-ac {
        div,
        p {
          font-size: 16px;
          line-height: 24px;
          color: $text-secondary;
          margin-bottom: 24px;
        }

        div {
          margin-bottom: 0px;
        }

        .term__sub-content {
          margin-bottom: 24px;

          p {
            margin: unset;
          }

          & > p:first-child {
            color: $text-main;
            font-weight: 400;
          }
        }
      }
    }
  }
}

@media (min-width: #{$desktop-width}) {
  .term-page {
    max-width: 1280px + 16px * 2;
    padding: 24px 16px;
  }
}

@include tablet-d() {
  .term-page {
    padding: 24px 16px;

    .content-wrap {
      .content-general p {
        font-size: 14px;
      }

      .term-content {
        .title-header {
          font-size: 16px;
        }

        .content-ac div,
        .content-ac p {
          font-size: 14px;
        }
      }
    }
  }
}

@include mobile() {
  .term-page {
    padding: 21px 24px;

    .title {
      font-size: 18px;
      margin-bottom: 5px;
    }

    .time-revised {
      p {
        font-size: 14px;
        margin-bottom: 15px;
        line-height: 24px;
        color: $text-secondary;
      }
    }

    .content-wrap {
      // padding: 14px 15px;
      .content-general {
        margin-bottom: 5px;

        p {
          font-size: 14px;
        }
      }

      .title-header {
        font-size: 18px;
        margin-bottom: 10px;

        p {
          margin-bottom: 0;
        }
      }

      .term-content {
        .content-ac {
          div,
          p {
            margin-bottom: 0px;
            font-size: 14px;
          }

          div {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
#footer,
#header {
  &.disable-app {
    display: none;
  }
}

.page-container {
  &.isContentApp {
    min-width: auto;
    min-height: auto;
    width: 100%;
    margin: 0px;
    max-width: 100%;

    .term-page {
      padding: 0px;
    }
  }
}
</style>
