<template>
  <div id="navi-tab">
    <ul>
      <li
        v-for="(item, index) in naviList"
        @click="selectNavi(item)"
        :key="index"
        :class="{ active: item.isActive }"
      >
        {{ $t(item.name) }}
      </li>
    </ul>
    <div class="checkbox-function">
      <label for="checkbox_id">
        <input
          type="checkbox"
          name="checkbox"
          v-model="isHideOtherPair"
          value="check"
          id="checkbox_id"
          @change="hideOtherPair()"
        />
        <span>{{
          isCurrentTab === commonConst.FUNDS
            ? $t("order.open_order.hide_small_assets")
            : $t("order.open_order.hide_other_pairs")
        }}</span>
      </label>
    </div>
  </div>
</template>

<script>
import COMMON_CONST from "@/common/Const";

export default {
  props: { currentTab: Object },
  data() {
    return {
      naviList: COMMON_CONST.OPEN_ORDER_NAVI,
      commonConst: COMMON_CONST,
      isHideOtherPair: false,
      isCurrentTab: null,
    };
  },
  methods: {
    getSocketEventHandlers() {
      return {
        OrderListUpdated: this.onOrderListUpdated,
      };
    },
    onOrderListUpdated(data) {
      this.$broadcast("SpotOrderListUpdated", data, this.isHideOtherPair);
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
      };
    },
    onSpotSelectedPairMarket(pair) {
      this.isHideOtherPair = false;
    },
    selectNavi(item) {
      if (item.isActive) {
        return;
      }
      this.isHideOtherPair = false;
      this.isCurrentTab = item.id;
      this.resetSelectNavi();
      item.isActive = true;
      this.$emit("action-navi-tab", {
        action: this.commonConst.ACTION_CHANGE_ORDER_TAB,
        item,
      });
    },
    onChangeNavi(item) {
      if (item.isActive) {
        return;
      }
      this.isCurrentTab = item.id;
      this.resetSelectNavi();
      item.isActive = true;
    },
    hideOtherPair() {
      this.$emit("action-navi-tab", {
        action: this.commonConst.ACTION_HIDE_OTHER_PAIR,
        isCheck: this.isHideOtherPair,
      });
    },
    resetSelectNavi() {
      this.naviList.forEach((item) => {
        item.isActive = false;
      });
    },
  },
  mounted() {
    this.resetSelectNavi();
    const index =
      this.naviList?.indexOf(this.currentTab) >= 0
        ? this.naviList?.indexOf(this.currentTab)
        : 0;
    this.naviList[index].isActive = true;
  },
};
</script>
<style lang="scss" scoped>
#navi-tab {
  position: relative;
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  ul {
    list-style-type: none;
    padding-top: 16px;
    margin: 0;

    li {
      display: inline-block;
      min-width: 160px;
      padding: 0 30px 10px;
      font-family: $helvetica;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      background-color: $color-white;
      color: $text-main-blur;
      text-align: center;
      user-select: none;

      &:hover {
        cursor: pointer;
      }

      &.active {
        color: $text-main;
        padding-bottom: 6px;
        border-bottom: 4px solid $bg-main1;
      }
    }
  }

  .checkbox-function {
    font-family: $helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $text-main;
    margin-right: 20px;
    cursor: pointer;

    label {
      display: flex;
      position: relative;
      align-items: center;
      min-width: 150px;
      gap: 10px;
      cursor: pointer;
      white-space: nowrap;
      margin-bottom: 12px;

      & input {
        border: 1px solid $bg-main2 !important;
        border-radius: 4px;
        margin: 0;
        width: 24px;
        height: 24px;

        &[type="checkbox"]:checked + span::before {
          content: "";
          display: block;
          position: absolute;
          text-align: center;
          height: 24px;
          width: 24px;
          left: 0;
          background-color: $bg-main1;
          border-radius: 4px;
        }

        &[type="checkbox"]:checked + span::after {
          content: url("@/assets/images/spot_exchange/checkbox-tick.svg");
          display: block;
          position: absolute;
          top: 2px;
          left: 2px;
        }
      }

      span {
        font-family: $helvetica;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 24px;
        color: $text-main;
      }
    }
  }

  @include mobile {
    width: fit-content;
    min-width: 1200px;

    ul {
      display: flex;
    }
  }

  @include tablet {
    width: fit-content;
    min-width: 1200px;

    ul {
      display: flex;
    }
  }
}
</style>
