<template>
  <div :id="containerId" class="trading-view-chart"></div>
</template>
<script>
import rf from '@/request/RequestFactory';
import TimezoneUtils from '@/common/TimezoneUtils';
import LocalStorageUtils from '@/common/LocalStorageUtils';
import Const from '@/common/Const';

export default {
  components: {},
  props: {
    multipleChart: { type: Boolean, default: false },
    symbol: { default: 'BTC/USDT', type: String },
    resolution: { default: '15', type: String },
    minTick: { default: 'default', type: String },
    responsePairCoinSettings: {},
  },
  data() {
    var dataFeed = new Datafeeds.UDFCompatibleDatafeed(
      process.env.VUE_APP_API_URL + '/api/v1/chart'
    );
    dataFeed.getBars = this.getBars.bind(this);
    dataFeed.subscribeBars = this.subscribeBars.bind(this);
    return {
      tradingViewChart: null,
      realtimeDatafeed: dataFeed,
      coin: '',
      currency: '',
      lastBar: undefined,
      onRealtimeCallback: undefined,
      disableByBetaTester: false,
      chartBackground: 'white',
      redColor: '#FF5B5B',
      greenColor: '#77B12B',
      greenColor2: '#77B12B',
      greyColor: '#d9dce1',
      darkColor: '#667186',
    };
  },
  computed: {
    containerId() {
      const id = 'trading-view-chart';
      if (this.multipleChart) {
        return `${id}-${Date.now()}`;
      }
      return id;
    },
  },
  methods: {
    subscribeBars(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback
    ) {
      this.onRealtimeCallback = onRealtimeCallback;
    },
    fillDataBars(bars, disableByBetaTester) {
      bars = window._.forEach(bars, function (bar) {
        bar.time = parseInt(bar.time);
        bar.open = disableByBetaTester == true ? 0 : parseFloat(bar.open);
        bar.close = disableByBetaTester == true ? 0 : parseFloat(bar.close);
        bar.high = disableByBetaTester == true ? 0 : parseFloat(bar.high);
        bar.low = disableByBetaTester == true ? 0 : parseFloat(bar.low);
        bar.volume = disableByBetaTester == true ? 0 : parseFloat(bar.volume);
      });
      return bars;
    },
    getBars(
      symbolInfo,
      resolution,
      from,
      to,
      onHistoryCallback,
      onErrorCallback,
      firstDataRequest
    ) {
      this.$emit('update:resolution', this.resolution);
      let ticker = symbolInfo.ticker.split('/');
      this.coin = ticker[0];
      this.currency = ticker[1];
      let params = {
        coin: this.coin,
        currency: this.currency,
        resolution: this.resolutionToMilliseconds(this.resolution),
        from: from,
        to: to,
      };
      let self = this;
      rf.getRequest('PriceRequest')
        .getChartBars(params)
        .then((bars) => {
          let deniedUser = this.disableByBetaTester;
          bars = this.fillDataBars(bars, deniedUser);

          if (bars.length) {
            let currentTime = Math.floor(Date.now() / resolution) * resolution;
            let lastBar = bars[bars.length - 1];
            if (lastBar.time > currentTime) {
              bars.pop();
            }
          }
          if (bars.length && !self.lastBar) {
            self.lastBar = bars[bars.length - 1];
          }
          onHistoryCallback(bars, { noData: bars.length == 0 });
        });
    },
    getSocketEventHandlers() {
      return {
        OrderTransactionCreated: this.onOrderTransactionCreated,
      };
    },

    onOrderTransactionCreated(data) {
      if (
        this.currency.toLowerCase() != data.buyOrder.currency ||
        this.coin.toLowerCase() != data.buyOrder.coin
      ) {
        return;
      }
      let price = parseFloat(data.orderTransaction.price);
      let createdAt = data.orderTransaction.created_at;
      let volume =
        data.orderTransaction.is_update_volume === 1 ? parseFloat(data.buyOrder.quantity) : 0;
      let resolution = this.resolutionToMilliseconds(this.resolution);
      let time = Math.floor(createdAt / resolution) * resolution;
      let existingBar = window._.cloneDeep(this.lastBar);
      if (this.lastBar) {
        if (time == this.lastBar.time) {
          existingBar.volume += volume;
          if (data.orderTransaction.is_instant === 0) {
            existingBar.high = Math.max(price, existingBar.high);
            existingBar.low = Math.min(price, existingBar.low);
            existingBar.close = price;
          }
        } else {
          if (data.orderTransaction.is_instant === 0) {
            existingBar = {
              time: time,
              high: Math.max(price, existingBar.close),
              low: Math.min(price, existingBar.close),
              open: existingBar.close,
              close: price,
              volume: volume,
            };
          } else {
            existingBar = {
              time: time,
              volume: volume,
            };
          }
        }
      } else {
        if (data.orderTransaction.is_instant === 0) {
          existingBar = {
            time: time,
            high: price,
            low: price,
            open: price,
            close: price,
            volume: volume,
          };
        } else {
          existingBar = {
            time: time,
            volume: volume,
          };
        }
        // return;
      }
      this.lastBar = existingBar;
      if (this.onRealtimeCallback != undefined) {
        this.onRealtimeCallback(existingBar);
      }
    },

    createEmptyBarIfNeeded() {
      let createdAt = Date.now();
      let resolution = this.resolutionToMilliseconds(this.resolution);
      let time = Math.floor(createdAt / resolution) * resolution;

      if (this.lastBar && time > this.lastBar.time) {
        let price = this.lastBar.close;
        this.lastBar = {
          time: time,
          high: price,
          low: price,
          open: price,
          close: price,
          volume: 0,
        };
        if (this.onRealtimeCallback) {
          this.onRealtimeCallback(this.lastBar);
        }
      }
    },
    resolutionToMilliseconds(resolution) {
      var minutes = parseInt(resolution);
      if (isNaN(minutes)) {
        minutes = Const.CHARTING_RESOLUTION_MULTIPLICITY[resolution];
      }
      return minutes * 60 * 1000;
    },
    createChart() {
      let disabled_features = [
        'use_localstorage_for_settings',
        // "left_toolbar",
        'context_menus',
        'display_market_status',
        'timeframes_toolbar',
        'header_compare',
        'header_undo_redo',
        'header_saveload',
        'header_screenshot',
        'header_interval_dialog_button',
        'volume_force_overlay',
        'control_bar',
        'border_around_the_chart',
        'header_widget',
        'header_symbol_search',
        'chart_property_page_scales',
      ];
      this.tradingViewChart = new TradingView.widget({
        symbol: this.symbol.toUpperCase(),
        interval: this.resolution,
        locale: this.$i18n.locale,
        timezone: TimezoneUtils.getTimezone(),
        container_id: this.containerId,
        datafeed: this.realtimeDatafeed,
        library_path:
          window.location.protocol + '//' + window.location.host + '/tradingview/charting_library/',
        autosize: true,
        withdateranges: false,
        client_id: 'vcc',
        user_id: 'public_user_id',
        save_image: false,
        drawings_access: {
          type: 'black',
          tools: [{ name: 'Regression Trend' }],
        },
        disabled_features: disabled_features,
        custom_css_url: 'tradingview.css',
        enabled_features: ['dont_show_boolean_study_arguments', 'hide_last_na_study_output'],
        overrides: {
          'paneProperties.topMargin': 15,
          'scalesProperties.showStudyLastValue': true,
          'scalesProperties.showCountdown': true,
          'scalesProperties.lineColor': this.greyColor,
          'scalesProperties.textColor': this.darkColor,
          'paneProperties.background': this.chartBackground,
          'mainSeriesProperties.ma.upColor': this.greenColor,
          'mainSeriesProperties.ba.upColor': this.greenColor,
          'mainSeriesProperties.candleStyle.upColor': this.greenColor,
          'mainSeriesProperties.candleStyle.downColor': this.redColor,
          'mainSeriesProperties.candleStyle.drawWick': true,
          'mainSeriesProperties.candleStyle.drawBorder': true,
          'mainSeriesProperties.candleStyle.borderColor': this.greenColor,
          'mainSeriesProperties.candleStyle.borderUpColor': this.greenColor2,
          'mainSeriesProperties.candleStyle.borderDownColor': this.redColor,
          'mainSeriesProperties.candleStyle.wickUpColor': this.greenColor,
          'mainSeriesProperties.candleStyle.wickDownColor': this.redColor,
          'mainSeriesProperties.candleStyle.barColorsOnPrevClose': false,
          'mainSeriesProperties.minTick': this.minTick,
          'paneProperties.vertGridProperties.color': this.greyColor,
          'paneProperties.horzGridProperties.color': this.greyColor,
        },
        studies_overrides: {
          'volume.volume.color.0': 'rgba(255, 91, 91, 0.5)',
          'volume.volume.color.1': 'rgba(119, 177, 43, 0.5)',
          'volume.volume.transparency': 15,
          'volume.volume ma.transparency': 0,
          'volume.volume ma.linewidth': 1,
          'volume.volume ma.plottype': 'line',
          'volume.show ma': true,
          'volume.volume ma.color': '#108CDD',
        },
      });
      var self = this;
      this.tradingViewChart.onChartReady(function () {
        if (!self.reloadTrandingChart()) {
          self.tradingViewChart.chart().createStudy('Moving Average', false, false, [7], null, {
            'Plot.linewidth': 1.3,
            'Plot.color': '#108CDD',
          });
          self.tradingViewChart.chart().createStudy('Moving Average', false, false, [40], null, {
            'Plot.linewidth': 1.3,
            'Plot.color': '#77B12B',
          });
        }
        self.$watch('minTick', self.watchMinTick);
      });
    },
    watchMinTick(newMinTick) {
      const overrides = Object.assign({}, this.tradingViewChart.options.overrides);
      overrides['mainSeriesProperties.minTick'] = newMinTick;
      this.tradingViewChart.applyOverrides(overrides);
    },
    showIndicatorDialog() {
      if (!window._.isEmpty(this.tradingViewChart)) {
        this.tradingViewChart.chart().executeActionById('insertIndicator');
      }
    },

    showChartPropertiesDialog() {
      if (!window._.isEmpty(this.tradingViewChart)) {
        this.tradingViewChart.chart().executeActionById('chartProperties');
      }
    },

    saveTradingChartConfiguration() {
      this.tradingViewChart.save((res) => {
        LocalStorageUtils.saveItem(
          Const.TRADING_VIEW_CHART.OBJECT.SPOT_EXCHANGE,
          JSON.stringify(res)
        );
      });
    },

    reloadTrandingChart() {
      try {
        const jsonObject = LocalStorageUtils.getItem(Const.TRADING_VIEW_CHART.OBJECT.SPOT_EXCHANGE);
        if (jsonObject) {
          this.tradingViewChart.load(JSON.parse(jsonObject));
          return true;
        }
        return false;
      } catch (err) {
        return false;
      }
    },

    setBarStyle(type) {
      this.tradingViewChart.chart().setChartType(type);
    },
  },
  watch: {
    symbol(newSymbol) {
      // this.initData();
      let that = this;
      TradingView.onready(function () {
        that.createChart();
      });
    },
    resolution(newResolution) {
      this.tradingViewChart.setSymbol(this.symbol, newResolution, function () {});
    },
    '$route.query.lang'() {
      this.initData();
      let that = this;
      TradingView.onready(function () {
        that.createChart();
      });
    },
    responsePairCoinSettings(newValue) {
      this.disableByBetaTester = false;
      const setting = newValue;
      if (!setting.is_enable && setting.is_show_beta_tester) {
        this.disableByBetaTester = true;
        if (window.isAuthenticated) {
          rf.getRequest('UserRequest')
            .getUserPairTradingSetting({ coin, currency })
            .then((res) => {
              if (res && res.data && res.data.enable_trading == Const.ENABLE_TRADING.ENABLE) {
                this.disableByBetaTester = false;
              }
              this.createChart();
            });
        }
      } else {
        this.createChart();
      }
    },
  },
  mounted() {
    // Create chart for the first time
    let that = this;
    TradingView.onready(function () {
      that.createChart();
    });

    window.setInterval(() => {
      this.createEmptyBarIfNeeded();
    }, 15000);

    // Create chart when route change, re-render
    if (typeof this.tradingViewChart != 'undefined') {
      that.createChart();
    }
  },
};
</script>

<style lang="scss" scoped>
.trading-view-chart {
  height: 100%;
}
</style>
