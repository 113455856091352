<template>
  <div id="landing-page">
    <!-- section-wrapper -->
    <div class="landing-page__hero-section">
      <div class="hero-section__title" v-html="$t('home_page.title')"></div>
      <div class="hero-section__desc">{{ $t('home_page.subtitle') }}</div>
      <div class="hero-section__button" v-if="!Islogin">
        <button
          class="btn-primary"
          @click="
            $router.push({
              path: '/register',
              query: $route.query,
            })
          "
        >
          {{ $t('home_page.get_started') }}
        </button>
      </div>
      <div v-if="!isPwa()" class="hero-section__app-info">
        <div class="hero-section__app-desc" v-html="$t('home_page.download_app')"></div>
        <div class="hero-section__app-qr">
          <img :src="qrCode" alt="" />
        </div>
      </div>
    </div>
    <!-- section-wrapper -->
    <div class="landing-page__possibilities-section">
      <div class="possibilities-section__title">
        <span v-html="$t('home_page.possibilities.txt_1')"></span>
        <span class="text-highlight"> NVX</span>
      </div>
      <div class="possibilities-section__card-list">
        <div class="card-item__wrapper text-right">
          <p>
            {{ $t('home_page.possibilities.txt_2') }}
            <span class="text-bold">NVX</span>
            <br />
            <br />
            <span class="text-bold">{{ $t('home_page.possibilities.txt_3') }}</span>
          </p>
        </div>
        <div class="card-item__wrapper text-left">
          <div class="card-item__title">
            {{ $t('home_page.about_us.title') }}
          </div>
          <div class="card-item__content" v-html="$t('home_page.about_us.content')"></div>
        </div>
      </div>
    </div>
    <!-- section-wrapper -->
    <div class="landing-page__account-section">
      <div class="account-section__title" v-html="$t('home_page.acc_management.title')"></div>
      <div class="account-section__image-list">
        <div v-for="item in AppsImage" class="image-list__item">
          <img :src="item" alt="" />
        </div>
      </div>
      <div class="account-section__account-card-list">
        <div class="account-card__item style-1 color-red">
          <div class="account-card__content">
            <div class="account-card-content__title">
              {{ $t('home_page.acc_management.title_1') }}
            </div>
            <div class="account-card-content__desc">
              {{ $t('home_page.acc_management.desc_1') }}
            </div>
          </div>
          <div class="account-card__icon">
            <img src="/images/landing/hand-shake-icon.svg" alt="" />
          </div>
          <div class="account-card__footer">
            <p>{{ $t('home_page.acc_management.learn_more') }}</p>
            <div class="account-card__footer-btn">
              <img alt="" src="/images/landing/account-card-footer-icon.svg" />
            </div>
          </div>
        </div>
        <div class="account-card__item style-2 color-blue">
          <div class="account-card__content">
            <div class="account-card-content__title">
              {{ $t('home_page.acc_management.title_2') }}
            </div>
            <div class="account-card-content__desc">
              {{ $t('home_page.acc_management.desc_2') }}
            </div>
          </div>
          <div class="account-card__icon">
            <img src="/images/landing/currency-icon.svg" alt="" />
          </div>
          <div class="account-card__footer">
            <p>{{ $t('home_page.acc_management.learn_more') }}</p>
            <div class="account-card__footer-btn">
              <img alt="" src="/images/landing/account-card-footer-icon.svg" />
            </div>
          </div>
        </div>
        <div class="account-card__item style-1 color-green">
          <div class="account-card__content">
            <div class="account-card-content__title">
              {{ $t('home_page.acc_management.title_3') }}
            </div>
            <div class="account-card-content__desc">
              {{ $t('home_page.acc_management.desc_3') }}
            </div>
          </div>
          <div class="account-card__icon">
            <img src="/images/landing/swap-icon.svg" alt="" />
          </div>
          <div class="account-card__footer">
            <p>{{ $t('home_page.acc_management.learn_more') }}</p>
            <div class="account-card__footer-btn">
              <img alt="" src="/images/landing/account-card-footer-icon.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- section-wrapper -->
    <div class="landing-page__partner-section">
      <div class="partner-section__title">
        {{ $t('home_page.our_partners.title') }}
      </div>
      <div class="partner-section__list">
        <div
          v-for="item in listPartner1"
          class="partner-section__list-item"
          @click="openInNewTab(item?.link)"
        >
          <img :src="item?.imgUrl" alt="" :class="item?.name" />
        </div>
      </div>
      <div class="partner-section__list">
        <div
          v-for="item in listPartner2"
          class="partner-section__list-item"
          @click="openInNewTab(item?.link)"
        >
          <img :src="item?.imgUrl" alt="" :class="item?.name" />
        </div>
      </div>
    </div>
    <!-- section-wrapper -->
    <div class="landing-page__news-section">
      <div class="news-section__title">{{ $t('home_page.news.title') }}</div>
      <div class="news-section__desc">
        {{ $t('home_page.news.desc') }}
      </div>
      <div class="news-section__card-list">
        <div
          class="news-section__card-item"
          v-for="item in listNews"
          :key="item?.id"
          @click="onClickNewsItem(item)"
        >
          <div class="card-item__image-wrapper">
            <img :src="item?.banner_image" alt="" />
          </div>
          <div class="news-card-item__content">
            <div class="news-card-content__header">
              <div class="news-card-content__header-title text-twoline" :title="item?.title">
                {{ item?.title }}
              </div>
              <div class="news-card-content__header-view-count">
                <img src="/images/landing/u_eye.svg" alt="" />
                <div>2790</div>
              </div>
              <div class="news-card-content__header-date">
                {{ formatTimeAgo(item?.time_published) }} - by
                {{ formatAuthor(item?.authors) }}
              </div>
            </div>
            <div class="news-card-content__body text-threeline" :title="item?.summary">
              {{ item?.summary }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- section-wrapper -->
    <div class="landing-page__charting-section">
      <div class="charting-section__title">
        {{ $t('home_page.advanced_tools.title') }}
      </div>
      <div class="charting-section__desc">
        {{ $t('home_page.advanced_tools.desc') }}
      </div>
      <div class="charting-section__image-wrapper">
        <img src="/images/landing/charting-tools.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import AccountImage_ from '@/assets/images/landing/landing_new/account-management-img.png'
import AppsImage1 from '@/assets/images/landing/landing_new/apps-1.png'
import AppsImage2 from '@/assets/images/landing/landing_new/apps-2.png'
import AppsImage3 from '@/assets/images/landing/landing_new/apps-3.png'
import qrImg from '@/assets/images/qr-code.png'
import rf from '@/request/RequestFactory'
import moment from 'moment'

export default {
  name: 'LandingNew',
  data() {
    return {
      listPartner1: [
        {
          link: 'https://ojk.go.id/',
          imgUrl: '/images/landing/partners-new/ojk.png',
          name: 'ojk',
        },
        {
          link: 'https://www.bappebti.go.id/',
          imgUrl: '/images/landing/partners-new/bappebti.png',
          name: 'bappebti',
        },
        {
          link: 'https://digisign.id/',
          imgUrl: '/images/landing/partners-new/digisign.png',
          name: 'digisign',
        },
        {
          link: 'https://www.chainalysis.com/',
          imgUrl: '/images/landing/partners-new/chainalysis.png',
          name: 'chainalysis',
        },
      ],
      listPartner2: [
        {
          link: 'https://aspakrindo.org/',
          imgUrl: '/images/landing/partners-new/apai.png',
          name: 'apai',
        },
        {
          link: 'https://midtrans.com/',
          imgUrl: '/images/landing/partners-new/midtrans.png',
          name: 'midtrans',
        },
        {
          link: 'https://www.fireblocks.com/',
          imgUrl: '/images/landing/partners-new/fireblocks.png',
          name: 'fireblocks',
        },
      ],
      Islogin: window.isAuthenticated,
      newsData: {},
    }
  },
  computed: {
    qrCode() {
      return qrImg
    },
    AccountImage() {
      return AccountImage_
    },
    AppsImage() {
      return [AppsImage1, AppsImage2, AppsImage3]
    },
    listNews() {
      return this.newsData?.feed?.slice(0, 3)
    },
  },
  methods: {
    openInNewTab(url) {
      window.open(url, '_blank', 'noreferrer')
    },
    onClickNewsItem(item) {
      window.open(item?.url, '_blank', 'noreferrer')
    },
    formatTimeAgo(date) {
      return moment(date).fromNow()
    },
    formatAuthor(authors) {
      return authors.join(', ')
    },
    setLocal(key, value) {
      const expiryTime = moment().add(1, 'days')
      const item = {
        value: value,
        expiry: expiryTime,
      }
      window.localStorage.setItem(key, JSON.stringify(item))
    },
    async getNewsAndArticle() {
      try {
        const localData = JSON.parse(window.localStorage.getItem('_article'))
        if (moment().isAfter(localData?.expiry) || !localData) {
          const res = await fetch(
            'https://www.alphavantage.co/query?apikey=X5EXH5I7AZZ0KZHP&tickers=AAPL&function=NEWS_SENTIMENT'
          )
          const data = await res.json()
          this.setLocal('_article', data)
          this.newsData = data
        } else {
          this.newsData = localData?.value
        }
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    isPwa() {
      return ['fullscreen', 'standalone', 'minimal-ui'].some(
        (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches
      )
    },
  },
  created() {
    let urlSearch = new URLSearchParams(window.location.search)
    let msgDigisign = urlSearch.get('msg')
    if (msgDigisign) {
      rf.getRequest('UserRequest').handleMsgDigisign({ msg: msgDigisign })
    }
  },
  mounted() {
    this.getNewsAndArticle()
  },
}
</script>

<style lang="scss" scoped>
#landing-page {
  & *::-webkit-scrollbar {
    display: none;
  }

  .text-oneline {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .text-twoline {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-threeline {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  height: 100%;

  .landing-page__hero-section {
    background-image: url('@/assets/images/landing/hero-img-1.png'),
      url('@/assets/images/landing/hero-img-2.png');
    background-position: left 0 top 90%, right 0 top 80%;
    background-repeat: no-repeat, no-repeat;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    min-height: 710px;

    @media (max-width: 1300px) {
      background-size: 33%;
    }

    @media (min-width: 1600px) {
      background-size: 33%;
    }

    .hero-section__title {
      padding-top: 100px;
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      max-width: 585px;
      margin-left: auto;
      margin-right: auto;
      line-height: 72px;
      color: $text-main;
    }

    .hero-section__desc {
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 40px;
      color: $text-secondary;
      margin-top: 16px;
      margin-bottom: 32px;
    }

    button.btn-primary {
      background-color: $bg-main1;
      border-radius: 60px;
      padding: 10px 32px;
      min-width: 204px;
      font-size: 18px;
      line-height: 32px;
      color: $text-white;
    }

    .hero-section__app-info {
      margin-top: 32px;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      padding: 8px;
      background-color: $color-white;
      display: flex;
      gap: 24px;
      align-items: center;
      border-radius: 8px;

      .hero-section__app-qr {
        img {
          width: 82px;
          height: auto;
        }
      }

      .hero-section__app-desc {
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: 32px;
      }
    }
  }

  // ----
  .landing-page__possibilities-section {
    margin-top: 6px;
    text-align: center;
    padding: 0 24px;

    .possibilities-section__title {
      font-size: 56px;
      font-weight: 700;
      line-height: 72px;
      color: $text-main;

      & span.text-highlight {
        color: $color-blue;
      }
    }

    .possibilities-section__card-list {
      margin-top: 60px;
      display: flex;
      justify-content: space-between;
      gap: 60px;
      max-width: 1120px;
      margin-left: auto;
      margin-right: auto;
      flex-direction: column;

      .card-item__wrapper {
        min-height: 466px;
        padding: 65px 60px;
        border-radius: 20px;

        color: $color-white;
        font-size: 36px;
        font-style: normal;
        line-height: 56px;

        &.text-left {
          background: url('@/assets/images/landing/landing_new/possibilities-bg-2.png') no-repeat;
          background-position: center;
          background-size: cover;

          & .card-item__title {
            font-weight: 700;
          }

          & .card-item__content {
            font-size: 16px;
            font-style: normal;
            max-width: 353px;
            font-weight: 300;
            line-height: 24px;
            opacity: 0.6;
          }
        }

        &.text-right {
          text-align: right;
          background: url('@/assets/images/landing/landing_new/possibilities-bg-1.png') no-repeat;
          background-position: center;
          background-size: cover;

          p {
            margin: unset;
            text-align: end;
            max-width: 420px;
            margin-left: auto;
          }

          span.text-bold {
            font-weight: 700;
          }
        }
      }
    }
  }

  // ----
  .landing-page__account-section {
    color: $text-main;
    text-align: center;
    max-width: 1120px;
    margin-left: auto;
    margin-right: auto;
    padding: 100px 24px;

    .account-section__title {
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 72px;
    }

    .account-section__image-list {
      padding: 60px 0;
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(3, 1fr);

      & img {
        max-width: 100%;
        height: auto;
      }
    }

    .account-section__account-card-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      color: rgba(255, 255, 255, 0.87);
      gap: 20px;
      overflow: auto;

      .account-card__item {
        min-width: 344px;
        border-radius: 20px;
        padding: 32px;
        text-align: start;
        display: flex;
        flex-direction: column;
        height: 500px;
        justify-content: space-between;

        .account-card__content {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 16px;
        }

        & .account-card-content__desc {
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
        }

        & .account-card-content__title {
          font-size: 36px;
          font-style: normal;
          font-weight: 700;
          line-height: 56px;
        }

        & .account-card__footer {
          margin-top: 16px;
          height: 40px;
          display: flex;
          justify-content: space-between;

          p {
            font-size: 18px;
            line-height: 32px;
            margin-top: auto;
            margin-bottom: auto;
            font-style: normal;
            font-weight: 700;
          }
        }

        &.color-red {
          background: linear-gradient(180deg, #ff7373 0%, #ff5b5b 100%);
        }

        &.color-blue {
          background: linear-gradient(180deg, #74c8ff 0%, #108cdd 100%);
        }

        &.color-green {
          background: linear-gradient(180deg, #9ddb4b 0%, #77b12b 100%);
        }

        & .account-card__icon {
          img {
            height: 120px;
            width: auto;
            margin-right: auto;
          }
        }

        &.style-1 {
          & .account-card__icon {
            order: -1;
          }

          & .account-card__content {
            justify-content: flex-end;
          }
        }

        &.style-2 {
          .account-card__content {
            flex: none;
          }

          .account-card__icon {
            display: flex;
            flex-direction: column;
            justify-content: center;
          }
        }
      }
    }
  }

  // ----
  .landing-page__partner-section {
    padding: 0 24px 100px;
    text-align: center;

    .partner-section__title {
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 72px;
      color: $text-main;
      padding-bottom: 60px;
    }

    .partner-section__list {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 64px;

      padding: 24px;
      max-width: 1120px;
      margin-left: auto;
      margin-right: auto;
      // overflow: auto;

      .partner-section__list-item {
        cursor: pointer;

        &:first-child {
          margin-left: auto;
          margin-top: -5px;
        }

        &:last-child {
          margin-right: auto;
        }

        img {
          &.ojk,
          &.apai {
            height: 48px !important;
          }
          width: auto;
          height: 28px;
          @media (max-width: 600px) {
            &.ojk,
            &.apai {
              height: 36px !important;
            }
          }
        }
      }

      @media (max-width: 1200px) {
        gap: 32px;
        padding-left: 0;
        padding-right: 0;
      }

      @include tablet {
        gap: 24px;
      }
    }
  }

  .landing-page__news-section {
    padding: 0 24px 100px;
    text-align: center;
    color: $text-main;

    .news-section__title {
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 72px;
    }

    .news-section__desc {
      margin-top: 16px;
      margin-left: auto;
      margin-right: auto;
      max-width: 710px;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 40px;
      color: $text-secondary;
    }

    .news-section__card-list {
      margin-top: 60px;
      display: grid;
      max-width: 1120px;
      margin-left: auto;
      margin-right: auto;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      overflow: auto;

      .news-section__card-item {
        cursor: pointer;
        border-radius: 20px;
        min-width: 360px;
        height: 505px;
        display: flex;
        flex-direction: column;

        .card-item__image-wrapper {
          height: 205px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;

          img {
            width: 100%;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            height: 100%;
          }
        }

        .news-card-item__content {
          padding: 34px;
          display: flex;
          flex-direction: column;
          flex: 1;
          border-bottom-left-radius: 20px;
          border-bottom-right-radius: 20px;
          background-color: $color-white;

          .news-card-content__header {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            text-align: start;

            &-view-count {
              display: flex;
              font-size: 16px;
              font-style: normal;
              font-weight: 300;
              line-height: 24px;

              img {
                margin-right: 8px;
              }
            }

            &-date {
              font-size: 16px;
              font-style: normal;
              font-weight: 300;
              line-height: 24px;
              color: $text-secondary;
            }
          }

          .news-card-content__body {
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            line-height: 24px;
            text-align: start;
            margin-top: 16px;
          }
        }
      }
    }
  }

  .landing-page__charting-section {
    padding: 0 24px 100px;
    text-align: center;
    color: $text-main;

    .charting-section__title {
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 72px;
    }

    .charting-section__desc {
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 40px;
      margin-top: 16px;
      margin-left: auto;
      margin-right: auto;
      max-width: 733px;
      color: $text-secondary;
    }

    .charting-section__image-wrapper {
      margin-top: 60px;
      max-width: 1120px;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  // mobile

  @include mobile {
    .landing-page__hero-section {
      background-image: url('@/assets/images/landing/hero-img-1-SP.png'),
        url('@/assets/images/landing/hero-img-2-SP.png');
      padding-left: 16px;
      padding-right: 16px;
      background-position: left 0 top 66%, right 0 top 91%;
      background-size: auto;

      .hero-section__title {
        padding-top: 32px;
        font-size: 24px;
        line-height: unset;
      }

      .hero-section__desc {
        font-size: 16px;
        line-height: unset;
        margin: 8px 0 24px;
        font-weight: 300;
      }

      .hero-section__app-info {
        margin-top: 24px;

        .hero-section__app-desc {
          font-size: 14px;
          line-height: 22px;
          font-weight: 300;
        }

        .hero-section__app-qr {
          img {
            width: 64px;
            height: 64px;
          }
        }
      }

      .hero-section__button button.btn-primary {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        padding: 9px 32px;
        line-height: 24px;
        min-width: 151px;
      }
    }

    .landing-page__possibilities-section {
      margin-top: unset;
      padding-left: 16px;
      padding-right: 16px;

      .possibilities-section__title {
        font-size: 24px;
        line-height: unset;
      }

      .possibilities-section__card-list {
        margin-top: 32px;
        gap: 16px;

        .card-item__wrapper {
          height: 700px;
          font-size: 18px;
          line-height: 32px;
          text-align: start;
          display: flex;
          flex-direction: column;
          justify-content: end;
        }

        .card-item__wrapper.text-right {
          padding: 24px 24px 82px;
          background-image: url('@/assets/images/landing/landing_new/possibilities-bg-1-SP.png');

          p {
            margin: unset;
            text-align: start;
          }
        }

        .card-item__wrapper.text-left {
          padding: 24px;
          background-image: url('@/assets/images/landing/landing_new/possibilities-bg-2-SP.png');

          .card-item__content {
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
          }
        }
      }
    }

    .landing-page__account-section {
      padding: 50px 16px;

      .account-section__title {
        font-size: 24px;
        line-height: unset;
      }

      .account-section__image-list {
        padding: 32px 0 16px;
        gap: 6px;
      }

      .account-section__account-card-list {
        grid-template-columns: repeat(1, 1fr);
        overflow: unset;
        gap: 16px;

        .account-card__item {
          height: unset;
          min-width: auto;

          &.style-2 .account-card__icon {
            margin-top: 24px;
          }

          &.style-1 .account-card__icon {
            margin-bottom: 24px;
          }

          .account-card__icon {
            img {
              height: 80px;
              width: auto;
            }
          }

          .account-card__content {
            gap: 16px;

            .account-card-content__title {
              font-size: 24px;
              line-height: 40px;
            }

            .account-card-content__desc {
              font-size: 14px;
              line-height: 22px;
              font-weight: 300;
            }
          }
        }
      }
    }

    .landing-page__partner-section {
      padding: 0 16px 50px;

      .partner-section__title {
        font-size: 24px;
        line-height: 40px;
        padding-bottom: 24px;
      }

      .partner-section__list {
        padding: 32px 0;
        gap: 24px;

        .partner-section__list-item img {
          height: 18px;
          width: auto;
        }
      }
    }

    .landing-page__news-section {
      padding: 0 16px 50px;

      .news-section__title {
        font-size: 24px;
        line-height: 40px;
      }

      .news-section__desc {
        font-size: 14px;
        line-height: 22px;
        margin-top: 8px;
        font-weight: 300;
      }

      .news-section__card-list {
        margin-top: 24px;
        gap: 16px;

        .news-section__card-item {
          width: 283px;
          min-width: unset;
          height: unset;

          .card-item__image-wrapper {
            height: 160px;
          }

          .news-card-item__content {
            padding: 24px 16px;

            .news-card-content__header {
              gap: 6px;

              &-title {
                font-size: 16px;
                line-height: 24px;
              }

              &-view-count {
                font-size: 14px;
                line-height: 22px;
                font-weight: 300;

                img {
                  width: 20px;
                  height: 20px;
                }
              }

              &-date {
                font-size: 12px;
                line-height: 16px;
                font-weight: 300;
              }
            }

            .news-card-content__body {
              font-size: 14px;
              line-height: 22px;
              margin-top: 8px;
              font-weight: 300;
            }
          }
        }
      }
    }

    .landing-page__charting-section {
      padding: 0 16px 50px;

      .charting-section__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 40px;
      }

      .charting-section__desc {
        font-size: 14px;
        line-height: 22px;
        margin-top: 8px;
        font-weight: 300;
      }

      .charting-section__image-wrapper {
        margin-top: 24px;
      }
    }
  }
}
</style>
