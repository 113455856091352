<template>
  <div class="OtpModal">
    <Modal
      :name="name"
      :hasModalFooter="false"
      maxWidth="404"
      @onShow="onShowModal"
    >
      <div slot="body">
        <div class="guest-page-form">
          <div class="form-container">
            <div class="guest-page-form-header-opt text-center">
              <!-- <img src="/images/blue-google-icon.svg" alt="google-icon" /> -->
              <h2 class="title-otp">{{ $t("account.google_auth") }}</h2>
            </div>
            <div class="guest-page-form-body">
              <!-- OTP -->
              <div class="input-group last">
                <div class="form-input" :class="{ error: errors.has('otp') }">
                  <CurrencyInputOtp
                    autocomplete="off"
                    :placeholder="$t('common.placeholders.otp')"
                    :data-vv-as="
                      $t('validation.attributes.authentication_code')
                    "
                    type="text"
                    maxlength="6"
                    id="otp-field"
                    name="otp"
                    v-model="otp"
                  />
                </div>
                <div class="invalid-feedback" v-show="errors.has('otp')">
                  {{
                    errors.items[0]?.rule === "length"
                      ? $t("validation.auth.ggauth_length")
                      : $t(errors.first("otp"))
                  }}
                </div>
              </div>

              <!-- Lost Google Authentication Redirect -->
              <div class="input-group final">
                <span class="login_box" @click.prevent="showModalLostOtp">
                  {{
                    $t(
                      "shared_components.google_authentication_modal.lost_code"
                    )
                  }}
                </span>
              </div>

              <!-- Submit Action -->
              <!-- :disabled="isDisableButton" -->
              <button class="btn-submit" @click="submitOtpForm" type="button">
                {{ getSubmitName($t("account.identity.submit")) }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
    <LostOtpModal
      :name="`${this.name}_lostOtpForm`"
      :contact_email="contact_email"
      @onShow="onShowModal"
    />
  </div>
</template>

<script>
import Modal from "@/components/shared_components/common/Modal";
import LostOtpModal from "@/components/shared_components/otp/LostOtpModal";
import CurrencyInputOtp from "@/components/shared_components/common/CurrencyInputOtp.vue";

export default {
  inject: ["$validator"],
  components: {
    Modal,
    LostOtpModal,
    CurrencyInputOtp,
  },
  data() {
    return {
      otp: "",
    };
  },
  props: {
    name: { type: String, default: "OtpModal" },
    submit: { type: Function },
    params: {
      type: Object | FormData,
      default: () => {
        return {};
      },
    },
    errorMessenger: { type: String },
    isDisableButton: { type: Boolean, default: false },
    contact_email: { type: String, default: "" },
  },
  watch: {
    otp(newVal) {
      if (newVal.length === 6) {
        this.submitOtpForm();
      }
    },
    isDisableButton(newVal) {
      newVal === true ? this.startSubmit() : this.endSubmit();
    },
    errorMessenger(newVal) {
      if (newVal) {
        this.$validator.errors.add({
          field: "otp",
          msg: newVal,
        });
      }
    },
  },
  methods: {
    isValid() {
      if (!this.otp.length) {
        this.$validator.errors.add({
          field: "otp",
          msg: this.$i18n.t("funds.withdrawals.errors.otp_required"),
        });
      } else if (this.otp.length !== 6) {
        this.$validator.errors.add({
          field: "otp",
          msg: this.$i18n.t("funds.withdrawals.errors.otp_length_digits"),
        });
      } else if (this.errorMessenger) {
        this.$validator.errors.add({
          field: "otp",
          msg: this.$i18n.t(this.errorMessenger),
        });
      }
      return !this.$validator.errors.has("otp");
    },

    submitOtpForm() {
      this.$validator.errors.remove("otp");
      if (!this.isValid()) {
        return;
      }
      this.otpRequest = this.otp;
      const params = Object.assign({}, this.params, { otp: this.otpRequest });
      this.startSubmit();
      this.submit(params);
      // this.otp = ''
    },

    showModalLostOtp() {
      CommonModal.hide(this.name);
      CommonModal.show(`${this.name}_lostOtpForm`);
    },

    onShowModal(val) {
      this.endSubmit();
      this.$emit("onShowOTP", val);
    },
  },
  mounted() {
    this.$on("hideCommonModal", (name) => {
      if (name === this.name) {
        this.otp = "";
        this.errors.remove("otp");
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.OtpModal {
  width: 550px;
  @include mobile {
    :deep(.modal) {
      .modal-dialog {
        width: calc(100vw);
      }
    }
  }
  :deep .modal .modal-dialog .modal-content .modal-body {
    padding: 0 24px 24px;
  }
  .guest-page-form-header-opt {
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 15px;

    .title-otp {
      margin-top: 0;
      margin-bottom: 24px;
      color: $text-main;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;

      @include mobile {
      }
    }
  }
  .input-group {
    margin-bottom: 16px;
    &.final {
      text-align: center;
      .login_box {
        color: $text-main;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 16px;
        cursor: pointer;
      }
    }
  }
  .btn-submit {
    @include btn-common(contained, md);
  }
}
</style>
