import rf from "@/request/RequestFactory";

const actions = {
  getSettlement({ commit }) {
    return new Promise((resolve, reject) => {
      rf.getRequest("MarginRequest")
        .getSettlement()
        .then((res) => {
          commit("updateSettlement", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getSettlementRequest({}, params) {
    return rf.getRequest("MarginRequest").getSettlement(params);
  },
};

const getters = {
  settlement: (state) => {
    return state[moduleName];
  },
};

const mutations = {
  updateSettlement: (state, payload) => {
    state.settlement = { ...payload };
  },
};

const moduleName = "settlement";
export default {
  actions,
  getters,
  mutations,
  state: {
    [moduleName]: {},
  },
};
