<template>
  <div id="trade-history-screen">
    <div class="box-title">
      {{ $t("menu.trade_history") }}
    </div>
    <div class="trade-history-wrap">
      <trade-history
        class="order-group-screen"
        :options="{ isNoPadding: true }"
      />
    </div>
  </div>
</template>

<script>
import COMMON_CONST from "@/common/Const";
import TradeHistory from "./TradeHistory.vue";

export default {
  components: {
    TradeHistory,
  },
  data() {
    return {
      dateList: COMMON_CONST.OPEN_ORDER_DATE_TAB,
    };
  },
  methods: {
    selectDate(item) {},
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/common.scss";

#trade-history-screen {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 1370px;
  min-height: 30vh;
  padding: 25px 15px;
  margin: 0 auto 50px;

  .box-title {
    @include text-font($helvetica, $dark-1, 24px, 700, 30px);
  }

  .trade-history-wrap {
    padding: 0;
    background: white;
    border-radius: 10px;
  }
}

@include mobile {
  #trade-history-screen {
    .box-title {
      @include text-font($helvetica, $dark-1, 20px, 700, 25px);
    }
  }
}
</style>
