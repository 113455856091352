<template>
  <base-layout>
    <div class="auth-container" id="forgot-password-page">
      <!-- Send Email Form -->
      <div class="guest-page-form" v-show="!isShow">
        <div class="form-container">
          <div class="home">
            <router-link to="/">
              <img src="/images/nvx/nvx-banner-2.png" alt="logo" />
            </router-link>
            <h2>{{ $t("auth.forgot_password") }}</h2>
            <p class="forgot-content">
              {{ $t("reset_password.forgot_content") }}
            </p>
          </div>

          <div class="guest-page-form-body">
            <div class="input-group final">
              <div class="custom-input">
                <div class="form-input" :class="{ error: errors.has('email') }">
                  <img src="/images/mail-login.svg" alt="mail" />
                  <input
                    v-model="email"
                    :placeholder="$t('common.placeholders.email')"
                    type="text"
                    :name="'email'"
                    :maxlength="maxlength"
                  />
                </div>
              </div>
              <div class="invalid-feedback">
                <span v-if="errors.has('email')">
                  {{ errors.first("email") }}
                </span>
                <span v-if="errors.has('error')">
                  {{ errors.first("error") }}
                </span>
              </div>
            </div>

            <div class="form_actions">
              <button
                :class="{ chrome_only: isChrome }"
                tabindex="0"
                :disabled="isSubmitting"
                type="button"
                @click="sendResetPasswordEmail"
              >
                {{ getSubmitName($t("menu.send")) }}
              </button>
            </div>
            <div class="to_login">
              {{ $t("reset_password.back_to") }}
              <router-link to="/login">{{
                $t("unregistered_session.login")
              }}</router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- Reset Password Form -->
      <div class="guest-page-form" v-show="isShow" id="reset-password-page">
        <div class="form-container">
          <div class="home">
            <router-link to="/">
              <img src="/images/nvx/nvx-banner-2.png" alt="logo" />
            </router-link>
            <h2>{{ $t("reset_password.title") }}</h2>
            <p class="reset-content">
              {{ $t("reset_password.reset_content") }}
            </p>
          </div>
          <div class="guest-page-form-body">
            <div class="input-group">
              <div class="form-input">
                <img src="/images/grey-lock.svg" alt="lock" />
                <input
                  :placeholder="$t('change_password_form.new_password')"
                  v-model="password"
                  :type="isEyeOpen ? 'text' : 'password'"
                  :maxlength="maxlength"
                  name="password"
                  v-validate="{
                    required: true,
                    min: 8,
                    max: 72,
                  }"
                  data-vv-validate-on="none"
                  :class="{ error: errors.has('password') }"
                  @focus="resetError"
                />
                <img
                  @click="isEyeOpen = !isEyeOpen"
                  :src="`/images/eye-${isEyeOpen ? 'open' : 'close'}.svg`"
                  alt="eye"
                />
              </div>
              <div class="invalid-feedback">
                <span v-show="errors.has('password')">
                  {{ errors.first("password") }}
                </span>
              </div>
            </div>

            <!-- Password Confirmation -->
            <div class="input-group final">
              <!-- <div class="input-group-prepend"><span class="icon-key2"></span></div> -->
              <div class="form-input">
                <img src="/images/grey-lock.svg" alt="lock" />
                <input
                  :placeholder="$t('common.placeholders.confirm_password')"
                  v-model="passwordConfirmation"
                  :type="isEyeOpenConfirm ? 'text' : 'password'"
                  name="password_confirmation"
                  :maxlength="maxlength"
                  :data-vv-as="
                    $t('change_password_form.confirm_new_password_change')
                  "
                  v-validate="'required|confirmed:password'"
                  :class="{ error: errors.has('password_confirmation') }"
                  data-vv-validate-on="none"
                  @focus="resetError"
                />
                <img
                  @click="isEyeOpenConfirm = !isEyeOpenConfirm"
                  :src="`/images/eye-${
                    isEyeOpenConfirm ? 'open' : 'close'
                  }.svg`"
                  alt="eye"
                />
              </div>
              <div class="invalid-feedback">
                <span v-show="errors.has('password_confirmation')">
                  {{ errors.first("password_confirmation") }}
                </span>
              </div>
              <div class="invalid-feedback">
                {{ errors.first("error") }}
              </div>
            </div>

            <!-- Submit Action -->
            <div class="form_actions">
              <button
                :class="{ chrome_only: isChrome && !errors.any() }"
                :disabled="isSubmitting"
                type="button"
                @click="resetPassword"
              >
                {{ getSubmitName($t("reset_password.title")) }}
              </button>
            </div>

            <!-- Login Redirect -->
            <div class="to_login">
              {{ $t("reset_password.back_to") }}
              <router-link to="/login">{{
                $t("unregistered_session.login")
              }}</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Confirmation Modal -->
    <confirmation-modal
      linkOnCancel="Login"
      v-if="isShow === true"
    ></confirmation-modal>
    <confirmation-modal v-else></confirmation-modal>
  </base-layout>
</template>

<script>
import rf from "@/request/RequestFactory";
import RemoveErrorsMixin from "@/common/RemoveErrorsMixin";
import BaseLayout from "./BaseLayout.vue";
import ConfirmationModal from "@/components/shared_components/common/ConfirmationModal";
import COMMON_CONST from "@/common/Const";
import Utils from "@/common/Utils";

export default {
  data() {
    return {
      email: "",
      password: "",
      passwordConfirmation: "",
      modalMessage: "",
      maxlength: COMMON_CONST.MAX_LENGTH_INPUT,
      host: window.location.origin,
      isConfirming: false,
      isShow: false,
      heightModal: "",
      withModal: "",
      maxLengthInput: COMMON_CONST.MAX_LENGTH_INPUT,
      isChrome: !!window.navigator.userAgent.toLowerCase().indexOf("chrome"),
      locale: window.localStorage.getItem("locale"),
      isEyeOpen: false,
      isEyeOpenConfirm: false,
    };
  },
  mixins: [RemoveErrorsMixin],
  components: {
    BaseLayout,
    ConfirmationModal,
  },
  props: {
    token: { type: String, default: "" },
  },

  methods: {
    confirm() {
      this.heightModal = "";
      this.isConfirming = true;
      if (this.isSubmitting) {
        return;
      }
      this.startSubmit();
      rf.getRequest("UserRequest")
        .confirmResetPassword(this.token)
        .then((response) => {
          this.isConfirming = false;
          this.isShow = true;
          this.endSubmit();
          this.email = response.data;
        })
        .catch((error) => {
          this.isConfirming = false;
          this.endSubmit();
          this.showModalResetPassword("confirm-fail-modal");
        });
    },

    async sendResetPasswordEmail() {
      this.resetError();

      if (this.isSubmitting) {
        return;
      }

      if (Utils.checkHasWhiteSpace(this.email)) {
        this.errors.add(
          "email",
          this.$t("validation.auth.email_format", {
            attribute: this.$t("common.placeholders.email"),
          })
        );
      }

      if (this.errors.any()) {
        return;
      }

      this.startSubmit();

      rf.getRequest("UserRequest")
        .resetPassword(this.email)
        .then((response) => {
          this.endSubmit();
          let message = "resetMail";
          this.showModalEmail(message);
        })
        .catch((e) => {
          this.endSubmit();
          if (e.response && e.response.data.errors) {
            Object.keys(e.response.data.errors).forEach((key) => {
              this.errors.add({
                field: key,
                msg: this.$t(e.response.data.errors[key][0]),
              });
            });
          }
          if (!this.errors.any()) {
            this.errors.add("error", this.$t("server.logs"));
          }
        });
    },

    async resetPassword() {
      this.resetError();

      if (this.isSubmitting) {
        return;
      }

      if (Utils.checkHasWhiteSpace(this.password)) {
        this.errors.add(
          "password",
          this.$t("validation.attribute_white_space", {
            attribute: this.$t("change_password_form.new_password"),
          })
        );
      }
      if (_.isEmpty(this.password)) {
        this.errors.add(
          "password",
          this.$t("validation.auth.new_password_required")
        );
      }
      if (this.password.length > 72) {
        this.errors.add(
          "password",
          this.$t("validation.auth.password_new_max")
        );
      }
      if (_.isEmpty(this.passwordConfirmation)) {
        this.errors.add(
          "password_confirmation",
          this.$t("validation.auth.password_confirm_required")
        );
        return;
      }
      if (
        !this.errors.has("password") &&
        this.password !== this.passwordConfirmation
      ) {
        this.errors.add(
          "password_confirmation",
          this.$t("validation.auth.password_confirmed_not_match")
        );
      }

      if (this.errors.any()) {
        return;
      }

      this.startSubmit();

      const params = {
        email: this.email,
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
        token: this.token,
      };

      rf.getRequest("UserRequest")
        .executeResetPassword(params)
        .then((response) => {
          this.endSubmit();
          const message = "resetPW";
          this.showModalPassword(message);
        })
        .catch((e) => {
          this.endSubmit();
          if (e.response && e.response.data.errors) {
            Object.keys(e.response.data.errors).forEach((key) => {
              this.errors.add({
                field: key,
                msg: this.$t(e.response.data.errors[key][0]),
              });
            });
          }
          if (!this.errors.any()) {
            this.errors.add("error", this.$t("server.logs"));
          }
        });
    },

    showModalResetPassword(message) {
      window.ConfirmationModal.show({
        type: message,
        title: this.$t("login.confirm_failed"),
        content: "",
        btnCancelLabel: null,
        btnConfirmLabel: null,
        onConfirm: () => {},
        onCancel: () => {},
      });
    },

    showResetPasswordError(error) {
      const errors = error.response.data.errors || {};

      for (const field in errors) {
        for (const error of errors[field]) {
          if (field == "email") {
            this.errors.add({
              field: "email",
              msg: this.$t("validation.verified_email"),
            });
          } else if (field == "error") {
            this.errors.add({
              field: field,
              msg: this.$t("login.confirm_email_failed"),
            });
          } else {
            this.errors.add({ field: field, msg: this.$t("") });
          }
        }
      }
      if (!this.errors.any()) {
        this.errors.add("error", this.$t("server.logs"));
      }
    },

    showModalEmail(message) {
      window.ConfirmationModal.show({
        type: message,
        title: this.$t("reset_password.send_mail_title"),
        class: "modal-success-email",
        // content: message,
        btnCancelLabel: null,
        btnConfirmLabel: null,
        onConfirm: () => {},
        onCancel: () => {},
      });
    },

    showModalPassword(message) {
      window.ConfirmationModal.show({
        type: message,
        title: this.$t("reset_password.send_mail_title"),
        class: "modal-success-password",
        // content: message,
        btnCancelLabel: null,
        btnConfirmLabel: null,
        onConfirm: () => {},
        onCancel: () => {},
      });
    },
  },
  mounted() {
    if (this.token) {
      return this.confirm();
    }
    window.addEventListener("keyup", (event) => {
      if (event.key === "Enter" && this.$route.name == "ResetPassword") {
        if (!this.isShow) {
          this.sendResetPasswordEmail();
        } else {
          this.resetPassword();
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.form-container {
  width: 400px;
  max-width: 100vw;
  padding: 0 16px;
}

#forgot-password-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  :deep() {
    ~ #confirmationModal .modal-dialog {
      width: 381px;
    }
  }
}

.to_login {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: $text-main6;
  text-align: center;
  margin-bottom: 16px;

  a {
    font-weight: 700;
    color: $text-main2;
  }
}

#reset-password-page {
  .custom-input {
    > input {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.invalid-feedback {
  padding-top: 2px;
  max-width: 283px;
}

.auth-container {
  .guest-page-form {
    width: 400px;
  }

  @include mobile {
    padding-top: unset;
    padding-bottom: unset;
  }

  .home {
    margin-bottom: 32px;

    a {
      display: flex;
      justify-content: center;

      img {
        width: 98px;
      }
    }

    h2 {
      margin-top: 32px;
      margin-bottom: 8px;
      text-align: center;
      color: $text-main2;
      font-size: 36px;
      font-weight: 700;
      line-height: 56px;

      @include mobile {
        font-size: 24px;
        line-height: 40px;
      }
    }

    p {
      text-align: center;
      font-size: 14px;
      color: rgba($text-main2, 0.6);
      margin: 0;
      font-weight: 400;
      line-height: 24px;

      &.forgot-content {
        padding: 0 50px;

        @include mobile {
          padding: 0 30px;
        }
      }

      &.reset-content {
      }
    }
  }
}

.form_actions {
  margin-bottom: 10px;

  button {
    @include btn-common(contained, lg);
  }
}

.form-input {
  &.error {
    border: 1px solid $color-red;
    background: rgba(255, 91, 91, 0.1);
  }

  input {
    background: transparent;
  }
}

.input-group-prepend {
  span {
    line-height: 50px;
  }
}

.final {
  margin-bottom: 24px;
}

.pt-20 {
  padding-top: 10px;
}

.verify-box {
  border-radius: 3px;
  height: 50px;
  text-align: center;
  border: 1px solid $color-green;
  padding-left: 0 !important;
  background-color: $color-dark-blue;
  line-height: 30px;

  .icon-home2 {
    padding-right: 10px;
  }

  a {
    position: relative;
    top: 9%;
    transform: perspective(1px) translateY(-50%);
  }
}

.title-l {
  margin: 13px 0px 15px 0px;
  height: 1px;
  border-radius: 5px;
  background-color: $color-light-grey;
}

.email-reset {
  font-family: $font-family-roboto;
  font-size: $font-root;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.07;
  letter-spacing: normal;
  color: $text-default;
  font-weight: normal;

  &:focus,
  &:active {
    color: $text-default;
    border-bottom: 1px solid $border-alto !important;
  }
}

.text-homepage-login {
  text-decoration: none;
  background-color: $color-dark-blue;
  border: 1px solid $color-green;
  display: flex;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  height: 50px;

  // margin: 20px;
  .link-text-login {
    font-size: $font-medium;
    line-height: 40px;
    color: $color-jungle-green;
    font-weight: 500;
    align-items: center;
    justify-content: center;
    font-family: $font-family-roboto-medium;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .icon-network {
    color: $color-jungle-green;
    font-size: 24px;
    font-weight: normal;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
}
</style>
