<template>
  <PopupModal :isShow="true">
    <template slot="body">
      <iframe
        :src="url"
        style="position: fixed; top: 0; height: 100%; width: 100%"
      ></iframe>
    </template>
  </PopupModal>
</template>

<script>
import PopupModal from "@/components/PopupModal.vue";
export default {
  name: "ModalVerifyCard",
  components: {
    PopupModal,
  },
  props: {
    url: {
      type: String,
      default: "",
    },
  },
  mounted() {
    const redirect_url = this.url;
    const _this = this;
    const options = {
      performAuthentication: function (redirect_url) {
        // window.open(redirect_url);
      },
      onSuccess: function (response) {
        _this.$emit("responseVerifyCard", response);
      },
      onFailure: function (response) {
        _this.$emit("responseVerifyCard", response);
      },
      onPending: function (response) {
        _this.$emit("responseVerifyCard", response);
      },
    };
    MidtransNew3ds.authenticate(redirect_url, options);
  },
};
</script>
