<template>
  <transition name="modal">
    <div class="modal-mask" @click="close" v-show="isShow">
      <div class="modal-wrapper is-active" @click="handleClickScreen">
        <div class="modal-custom">
          <slot name="body"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    isShow: Boolean,
    staticBackdrop: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      scrollY: 0,
    };
  },
  watch: {
    isShow(val) {
      const body = document.body;
      if (val) {
        body.classList.add("no-scroll");
        this.scrollY = window.scrollY;
        $("html").addClass("modal-open").css({
          marginTop: -this.scrollY,
          overflow: "hidden",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          position: "fixed",
        });
      } else {
        body.classList.remove("no-scroll");
        $("html").removeClass("modal-open").css({
          marginTop: 0,
          overflow: "visible",
          left: "auto",
          right: "auto",
          top: "auto",
          bottom: "auto",
          position: "static",
        });
        window.scrollTo({ left: 0, top: this.scrollY, behavior: "instant" });
        this.scrollY = 0;
      }
    },
  },
  methods: {
    close: function () {
      this.$emit("close");
    },
    handleClickScreen(e) {
      if (this.staticBackdrop) e.stopPropagation();
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  overflow: auto;
}

.modal-mask.modal-popup__noti .modal-wrapper {
  max-width: unset;
  margin: unset;
}

.modal-wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/*
  * The following styles are auto-applied to elements with
  * transition="modal" when their visibility is toggled
  * by Vue.js.
  *
  * You can easily play with the modal transition by editing
  * these styles.
  */

.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.3s linear;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

// .modal-leave-active {
//    opacity: 0;
// }
</style>
