<template>
  <div class="data-list__wrapper">
    <slot
      name="header"
      v-if="this.rows.length !== 0"
      :innerHtmlSort="innerHtmlSort"
      :onSort="onSort"
    />
    <slot
      name="body"
      v-for="(row, index) in rows"
      :item="row"
      :index="index"
      :innerHtmlSort="innerHtmlSort"
      :resetIE="resetIE"
      :onSort="onSort"
      :echoclick="echoclick"
      :isActive="row?.isActive"
      :onClickExpandItem="onClickExpandItem"
      :isHidePaginate="!(lastPage > 1)"
    />
    <template v-if="this.rows.length === 0">
      <table class="table-nodata">
        <slot name="table-empty-header">
          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>
        </slot>
        <tbody>
          <tr class="empty-data">
            <td colspan="50">
              <div class="loader" v-if="fetching || isLoading"></div>
              <div
                v-else
                class="empty-noti"
                :style="[height ? { 'min-height': height + 'vh' } : '']"
              >
                <div class="text-wrapper">
                  <img
                    class="icon-nodata"
                    src="/images/table-nodata.png"
                    alt="no_fund_icon"
                  />
                  <span
                    v-if="tableNoData === false && typeHistory === 'deposit'"
                    >{{ $t("funds.transaction_history.no_deposit_data") }}</span
                  >
                  <span
                    v-else-if="
                      tableNoData === false && typeHistory === 'withdraw'
                    "
                    >{{
                      $t("funds.transaction_history.no_withdraw_data")
                    }}</span
                  >
                  <span v-else class="msg-empty">{{
                    msgEmptyData || $t("common.datatable.data_empty")
                  }}</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-if="lastPage > 1">
      <pagination
        ref="pagination"
        class="text-right"
        :per-page="perPage"
        :records="totalRecord"
        :chunk="chunk"
        @Pagination:page="onPageChange"
        :pageParent="page"
      ></pagination>
    </template>
  </div>
</template>

<script>
import Pagination from "./Pagination";

export default {
  components: {
    Pagination,
  },
  props: {
    isExpand: Boolean,
    getData: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 10,
    },
    chunk: {
      type: Number,
      default: 5,
    },
    widthTable: {
      type: String,
      default: "100%",
    },
    msgEmptyData: {
      type: String,
    },
    typeHistory: {
      type: String,
    },
    defaultSort: {
      type: Boolean,
      default: true,
    },
    tableNoData: {
      type: Boolean,
      default: false,
    },
    innerHtmlSort: {
      type: String,
      default:
        '<img class="icon-sort-tabl3 icon-arrow6" src="/images/spot_exchange/arrow-down.svg" alt=""/><img src="/images/spot_exchange/arrow-up.svg" alt="" class="icon-sort-tabl3 icon-arrow5"/>',
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      maxPageWidth: 10,
      totalRecord: 0,
      lastPage: 0,
      page: 1,
      perPage: 10,
      fetching: false,
      rows: [],
      params: {},
      orderBy: null,
      sortedBy: null,

      emptyData: false,
      resetIE: false,
    };
  },
  computed: {
    emptyRow() {
      let emptyRowCount = Math.max(this.limit - _.size(this.rows), 0);
      return Math.min(emptyRowCount, this.limit);
    },
  },
  methods: {
    onPageChange(page) {
      this.page = page;
      const closestWrapper = this.$refs.pagination?.$el.closest(
        ".data-list__wrapper"
      );
      const offsetY = closestWrapper.getBoundingClientRect().top;
      window.scrollTo({
        top: offsetY - document.body.getBoundingClientRect().top - 200,
        behavior: "smooth",
      });
      // closestWrapper.scrollIntoView({ behavior: "smooth" });
      this.fetch();
    },
    echoclick() {
      this.resetIE = !this.resetIE;
    },
    getTarget(target) {
      let node = target;
      while (node.parentNode.nodeName !== "TR") {
        node = node.parentNode;
      }
      return node;
    },
    getSortOrder(target) {
      let sortOrder = target.dataset.sortOrder;
      switch (sortOrder) {
        case "asc":
          sortOrder = "";
          break;
        case "desc":
          sortOrder = "asc";
          break;
        default:
          sortOrder = "desc";
      }
      return sortOrder;
    },

    setSortOrders(target, sortOrder) {
      const closestWrapper = target.closest(".data-list__wrapper");
      let iterator = closestWrapper.getElementsByClassName(target.className);
      for (let item of iterator) {
        item.dataset.sortOrder = "";
      }
      target.dataset.sortOrder = sortOrder;
    },

    onSort(event) {
      // const target = this.getTarget(event.target);
      const target = event.target;
      const orderBy = target.dataset.sortField;
      if (!orderBy) {
        return;
      }
      this.sortedBy = this.getSortOrder(target);
      this.orderBy = this.sortedBy ? orderBy : "";
      Object.assign(this.params, {
        sort: this.orderBy,
        sort_type: this.sortedBy,
      });
      this.setSortOrders(target, this.sortedBy);
      this.fetch();
    },
    onClickExpandItem(row) {
      this.rows[row.index].isActive = !this.rows[row.index].isActive;
    },
    fetch() {
      try {
        const meta = {
          page: this.page,
          limit: this.limit,
        };

        this.fetching = true;
        this.getData(Object.assign(meta, this.params))
          .then((res) => {
            const data = res.data || res;
            if (!data) {
              return;
            }
            this.column = _.chain(this.$slots.default)
              .filter((el) => {
                return el.tag === "th";
              })
              .value().length;

            if (!data.data) {
              let sortData;
              if (this.sortedBy === "asc") {
                sortData = data.sort(function (a, b) {
                  let x = a.coin && a.coin.toLowerCase();
                  let y = b.coin && b.coin.toLowerCase();
                  if (x < y) {
                    return -1;
                  }
                  if (x > y) {
                    return 1;
                  }
                  return 0;
                });
              } else if (this.sortedBy === "desc") {
                sortData = data.sort(function (a, b) {
                  let x = a.coin && a.coin.toLowerCase();
                  let y = b.coin && b.coin.toLowerCase();
                  if (x > y) {
                    return -1;
                  }
                  if (x < y) {
                    return 1;
                  }
                  return 0;
                });
              } else {
                sortData = data;
              }
              this.rows = sortData;
              this.$emit("DataTable:finish");
              return;
            }
            this.page = parseInt(data.current_page);
            this.totalRecord = parseInt(data.total);
            this.lastPage = parseInt(data.last_page);
            this.perPage = parseInt(data.per_page);
            if (this.isExpand) {
              this.rows = data.data?.map((item) => {
                return { ...item, isActive: false };
              });
            } else {
              this.rows = data.data;
            }
            this.emptyData = window._.isEmpty(this.rows);
            this.$emit("DataTable:finish");
          })
          .then((res) => {
            this.fetching = false;
          });
      } catch (err) {
        this.fetching = false;
      }
    },
    refresh() {
      this.page = 1;
      this.params = Object.keys(this.params).map((k) => {
        if (!["start_date", "end_date", "page", "limit"].includes(k)) {
          delete this.params[k];
        }
      });
      setTimeout(() => {
        this.fetch();
      }, 1000);
    },

    filter(params) {
      this.page = 1;
      this.params = params;

      if (this.orderBy) {
        Object.assign(this.params, {
          sort: this.orderBy,
          sort_type: this.sortedBy,
        });
      }

      this.fetch();
    },

    getRows() {
      return this.rows;
    },

    setRows(rows) {
      this.rows = rows;
    },
  },
  created() {
    this.fetch();
    this.$on("DataTable:filter", (params) => {
      this.filter(params);
    });
  },
  mounted() {
    this.column = _.chain(this.$slots.default)
      .filter((el) => {
        return el.tag === "th";
      })
      .value().length;
  },
  watch: {
    "rows.length": {
      handler(newValue, oldValue) {
        if (newValue !== 0) this.fetching = false;
      },
    },
    deep: true,
  },
};
</script>

<style lang="scss" scoped>
.loader {
  display: block;
  margin: auto;
  border: 5px solid $color-grey-concrete;
  border-radius: 50%;
  border-top: 5px solid $bg-main1;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mobile-title__item,
.item-desc__title {
  .group-sort-tabl3 {
    display: none;
  }
  :deep .icon-sort-tabl3 {
    display: none;
    opacity: 0.5;
    margin-bottom: 2px;
  }
  &[data-sort-order="desc"] {
    .group-sort-tabl3 {
      display: inline-table;
      :deep .icon-arrow6 {
        display: inline-table;
      }
    }
  }
  &[data-sort-order="asc"] {
    .group-sort-tabl3 {
      display: inline-table;
      :deep .icon-arrow5 {
        display: inline-table;
      }
    }
  }
}
.table-nodata {
  width: 100%;
  thead {
    background-color: $bg-main4;
    color: $text-secondary;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    border-radius: 10px 10px 0px 0px;
    overflow: auto;
    tr th {
      white-space: nowrap;
      height: 32px;
      padding: 8px;
      &:first-child {
        padding-left: 22px;
      }
    }
  }
}
.empty-data {
  width: 100%;
  background-color: $text-white;
  text-align: center;
  color: $dark-1;
  font-size: $font-root;
  text-align: center;
  td {
    width: 100%;
    text-align: center !important;
    color: $dark-1 !important;
    padding: 35px 0;
    .empty-noti {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
      min-height: 50vh;
      .text-wrapper {
        user-select: none;
        pointer-events: none;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .msg-empty {
        color: $text-secondary;
        font-size: 14px;
        font-weight: 300;
        line-height: 22px;
      }
      .text-wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: center;
        align-items: center;
      }
      .icon-nodata {
        font-size: 40px;
        color: $dark-3;
        width: 100px;
        height: 100px;
        span {
          font-size: 14px;
          @include mobile {
            font-size: 10px;
          }
        }
      }
      @include tablet-d {
        align-items: unset;
        height: 100px;
        .text-wrapper {
          position: sticky;
          left: 50%;
          transform: translate(-50%);
          width: fit-content;

          img {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  // &:hover {
  //   background-color: $color-white !important;
  // }
  p {
    padding-top: 22px;
    span {
      vertical-align: middle;
    }
  }
  .icon-nodata {
    font-size: 40px;
    color: $dark-3;
    span {
      font-size: 14px;
      @include mobile {
        font-size: 10px;
      }
    }
  }
}
</style>
