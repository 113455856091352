<template>
  <div id="order_trade_form">
    <div class="fl-left tab-container">
      <div @click="clickOrderTab" class="tab-element" :class="{ active: isTabOrderTrade === 1 }">
        <span class="text-size">{{ $t('order.order_trade_form.order') }}</span>
      </div>
      <div @click="clickTradeTab" class="tab-element" :class="{ active: isTabOrderTrade === 0 }">
        <span class="text-size">{{ $t('order.order_trade_form.trades') }}</span>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="tab_content tab_content_ordertrade">
      <!-- order menu -->
      <div class="item" v-show="isTabOrderTrade === 1">
        <order-form @refreshOderGroup="$emit('refreshOderGroup')" :latestPrices="latestPrices" />
      </div>
      <!-- trades menu -->
      <div class="item" v-show="isTabOrderTrade === 0">
        <recent-trades :responsePairCoinSettings="responsePairCoinSettings" />
      </div>
    </div>
  </div>
</template>

<script>
import RecentTrades from './RecentTrades.vue';
import OrderForm from './order-form/OrderForm.vue';

export default {
  components: {
    OrderForm,
    RecentTrades,
  },
  props: ['latestPrices', 'responsePairCoinSettings'],
  data() {
    return {
      isTabOrderTrade: 1,
    };
  },
  methods: {
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        SellOrderBookClicked: this.onSellOrderBookClicked,
        BuyOrderBookClicked: this.onBuyOrderBookClicked,
      };
    },
    onSpotSelectedPairMarket(pair) {},
    onSellOrderBookClicked(price, maxAmount) {
      this.clickOrderTab();
      setTimeout(() => {
        this.$broadcast('FocusOrderForm', 'sell', price, maxAmount);
      }, 100);
    },
    onBuyOrderBookClicked(price, maxAmount) {
      this.clickOrderTab();
      setTimeout(() => {
        this.$broadcast('FocusOrderForm', 'buy', price, maxAmount);
      }, 100);
    },
    clickOrderTab() {
      this.isTabOrderTrade = 1;
    },
    clickTradeTab() {
      this.isTabOrderTrade = 0;
    },
  },
};
</script>

<style scoped lang="scss">
#order_trade_form {
  .tab-container {
    width: 100%;
    height: 56px;
    padding-top: 22px;
    background-color: $color-white;

    @include tablet {
      border-top: none;
    }
    @include mobile {
      border-top: none;
    }

    .tab-element {
      cursor: pointer;
      display: inline;
      float: left;
      line-height: 24px;
      // padding: 0px 40px 6px;
      border: none;
      background-color: transparent;
      font-weight: 700;
      font-size: 16px;
      width: 50%;
      color: $text-main-blur;
      font-family: $helvetica;
      text-align: center;
      &.active {
        border-bottom: 4px solid $bg-main1;
        color: $text-main;
      }
    }
  }

  .tab_content_ordertrade {
    .item:hover {
      background-color: initial;
    }
  }

  @include mobile {
    margin: 20px 0;
    .tab-container {
      height: auto;
      padding: 0;

      .tab-element {
        padding: 7px 20px;
        height: auto;
      }
    }
    .tab_content_ordertrade {
      border-top: unset;
    }

    #order_form {
      border-bottom: 1px solid $grey-2;
      padding: 16px 20px 10px;
    }
  }
  @include tablet {
    margin: 20px 0;
    .tab-container {
      height: auto;
      padding: 0;

      .tab-element {
        padding: 7px 20px;
        height: auto;
      }
    }
    .tab_content_ordertrade {
      border-top: unset;
    }

    #order_form {
      border-bottom: 1px solid $grey-1;
      padding: 16px 20px 10px;
    }
  }
}
</style>
