import rf from "@/request/RequestFactory";

const actions = {
  getLeaderboard({ commit }) {
    return new Promise((resolve, reject) => {
      rf.getRequest("MarginRequest")
        .getLeaderboard()
        .then((res) => {
          commit("updateLeaderboard", res.data);
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  getLeaderboardRequest({}, params) {
    return rf.getRequest("MarginRequest").getLeaderboard(params);
  },
};

const getters = {
  leaderboard: (state) => {
    return state.leaderboard;
  },
};

const mutations = {
  updateLeaderboard: (state, payload) => {
    state.leaderboard = { ...payload };
  },
};

const moduleName = "leaderboard";
export default {
  actions,
  getters,
  mutations,
  state: {
    [moduleName]: {},
  },
};
