import BaseRequest from './base/BaseRequest';

export default class TransactionRequest extends BaseRequest {

  getHistory(params, typeCoin) {
    let url = '/transactions';
    if (typeCoin) {
      url += '/' + typeCoin;
    }
    return this.get(url, params);
  }

  getCoinHistory(params, typeCoin) {
    let url = '/transactions';
    if (typeCoin) {
      url += '/' + typeCoin;
    }
    return this.get(url, params);
  }

  withdraw(params) {
    let url = '/withdraw';
    return this.post(url, params);
  }

  confirmWithdraw(params) {
    let url = '/verify-withdraw/' + params;
    return this.get(url);
  }

  withdrawUsd(params) {
    let url = '/transactions/withdraw-fiat';
    return this.post(url, params);
  }

  depositUsd(params) {
    let url = '/transactions/deposit-fiat';
    return this.post(url, params);
  }

  depositUsdInvoice(params) {
    let url = '/transactions/deposit-fiat-invoices';
    return this.post(url, params);
  }

  getCreditCard() {
    let url = '/credit-card';
    return this.get(url);
  }

  createCreditCard(params) {
    let url = '/credit-card/create';
    return this.post(url, params);
  }
  
  deleteCreditCard(params) {
    let url = `/credit-card/${params?.id}/delete`;
    return this.post(url);
  }

  getUsdTransactions(params) {
    let url = '/transactions/fiat';
    return this.get(url, params);
  }

  cancelUsdDepositTransaction(transactionid) {
    let url = '/transactions/deposit/cancel-fiat/' + transactionid;
    return this.put(url);
  }

  cancelUsdDepositTransactionInvoice(transactionid) {
    let url = '/transactions/deposit/cancel-fiat-invoices/' + transactionid;
    return this.put(url);
  }

  getStats(params) {
    let url = '/transactions/stats';
    return this.get(url, params);
  }

  getTotalPendingWithdraw(params) {
    let url = '/transactions/withdraw/total-pending-withdraw';
    return this.get(url, params);
  }

  getTotalUsdPendingWithdraw(params) {
    let url = '/transactions/withdraw/total-fiat-pending-withdraw';
    return this.get(url, params);
  }

  validateBankAccount(params) {
    let url = '/transactions/validate-bank-account';
    return this.post(url, params);
  }

  downloadTransactions(params, fileName) {
    const url = '/transactions/export';
    this.download(url, params, fileName);
  }

  buyAml(params) {
    const url = '/aml-transactions';
    return this.post(url, params);
  }

  convertAmount2USD(params) {
    const url = '/get-current-price';
    return this.get(url, params);
  }
}
