import { render, staticRenderFns } from "./AddBankModal.vue?vue&type=template&id=2dace773&scoped=true"
import script from "./AddBankModal.vue?vue&type=script&lang=js"
export * from "./AddBankModal.vue?vue&type=script&lang=js"
import style0 from "./AddBankModal.vue?vue&type=style&index=0&id=2dace773&prod&lang=scss&scoped=true"
import style1 from "./AddBankModal.vue?vue&type=style&index=1&id=2dace773&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dace773",
  null
  
)

export default component.exports