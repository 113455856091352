<template>
  <PopupModal :isShow="isShow">
    <template slot="body">
      <div class="banks-modal">
        <img
          src="@/assets/images/close-circle.svg"
          alt=""
          class="close-icon"
          @click="handleCloseModal"
        />
        <div class="modal-tit">{{ $t('intruction_modal.title') }}</div>
        <div class="modal-body">
          <VueFaqAccordion
            :items="myItems"
            v-slot="itemData"
            :initial-question-index="1"
            active-color="#FFFFFF"
          >
          </VueFaqAccordion>
          <!-- <div class="content" v-html="$t('intruction_modal.content')"></div> -->
        </div>
      </div>
    </template>
  </PopupModal>
</template>

<script>
import PopupModal from '@/components/PopupModal.vue'
import VueFaqAccordion from 'vue-faq-accordion'

export default {
  name: 'InstructionModal',
  components: { PopupModal, VueFaqAccordion },
  props: {
    isShow: Boolean,
  },
  data() {
    return {
      myItems: [
        {},
        {
          title: this.$t('intruction_modal.xendit.title'),
          value: this.$t('intruction_modal.xendit.content'),
        },
      ],
    }
  },
  methods: {
    handleCloseModal() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.banks-modal {
  max-width: 448px;
  height: auto;
  margin: 0 auto;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  @include mobile {
    width: calc(100vw - 30px);
    border-radius: 10px;
    // height: 313px;
  }
  img.close-icon {
    // margin-right: 30px;
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 24px;
    right: 12px;
    top: 12px;
  }

  .modal-tit {
    color: $text-main;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-top: 24px;
    text-transform: capitalize;
  }
  .modal-body {
    padding: 10px 24px 24px;
    @include mobile {
      padding: 16px;
    }
    :deep(.content) {
      div {
        font-size: 18px;
        font-weight: 300;
        line-height: 32px;
        color: $text-main;
        margin-top: 24px;
        margin-bottom: 8px;
        @include mobile {
          strong {
            line-height: 24px;
          }
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
        }
        &:first-child {
          margin-top: 0;
        }
      }
      ol {
        padding-left: 24px;
        list-style-position: outside;
        margin-bottom: 0;
        li {
          margin-bottom: 8px;
          color: $text-main;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          &:last-child {
            margin-bottom: 0;
          }
          @include mobile {
            strong {
              line-height: 24px;
            }
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.banks-modal {
  .faq {
    padding: 0;
    font-weight: 300 !important;
  }
  .list-intruction {
    margin: 0 14px !important;
  }
  .accordion {
    border: 1px solid #dddddd !important;
    border-radius: 8px !important;
    margin-top: 0 !important;
  }
  .accordion__value {
    padding: 20px !important;
  }
  .accordion__title {
    padding: 16px !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  .accordion__title {
    background: #f8f8f8 !important;
  }
  .accordion__title_active .accordion__title-text,
  .accordion__title:hover {
    color: #23212c !important;
  }
  .accordion__title-text {
    font-size: 14px;
  }
  .accordion__item:first-child {
    display: none !important;
  }
  .accordion__item {
    border-bottom: 1px solid #dddddd !important;
  }
  .accordion__toggle-button::before,
  .accordion__toggle-button::after {
    display: none;
  }
  .accordion__toggle-button {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M8 13.1l-8-8 2.1-2.2 5.9 5.9 5.9-5.9 2.1 2.2z'/%3E%3C/svg%3E");
    height: 50px;
    width: 50px;
  }
  .accordion__toggle-button_active {
    transform: rotate(180deg) !important;
  }

  .accordion__title:hover .accordion__toggle-button::after,
  .accordion__title:hover .accordion__toggle-button::before {
    background: #23212c !important;
  }
}
</style>
