<template>
  <div id="alertWithButtonModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog size-small" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">{{ configs.title }}</h4>
        </div>
        <div class="modal-body">
          {{ configs.content }}
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn-confirm btn btn-primary btn-modal-ok"
            data-dismiss="modal"
          >
            {{ configs.btnConfirmLabel }}
          </button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
window.AlertWithButtonModal = {
  show: function (configs) {
    window.app.$broadcast("showAlertWithButtonModal", configs);
  },
};
export default {
  data() {
    return {
      configs: {
        title: "",
        content: "",
        btnConfirmLabel: this.$t("common_action.ok"),
        onConfirm: function () {},
        size: "small",
        buttonClasses: "",
      },
    };
  },
  methods: {
    show() {
      const modal = $("#alertWithButtonModal");
      modal
        .find(".modal-dialog")
        .removeClass("size-small size-medium size-large")
        .addClass("size-" + this.configs.size);
      modal.find(".btn-cancel").unbind("click").click(this.configs.onCancel);
      modal.find(".btn-confirm").unbind("click").click(this.configs.onConfirm);
      modal.modal("show");
    },

    hide() {
      $("#alertWithButtonModal").modal("hide");
    },
  },
  created() {
    let self = this;

    this.$on("showAlertWithButtonModal", (userConfigs) => {
      self.configs = Object.assign(self.configs, userConfigs);
      this.show();
    });
  },
};
</script>

<style lang="scss" scoped>
#alertWithButtonModal {
  text-align: center;
  padding: 0 !important;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }

  .modal-dialog {
    vertical-align: middle;
    display: inline-block;
    text-align: left;

    &.size-small {
      width: 385px;
    }

    &.size-medium {
      width: 530px;
    }

    &.size-large {
      width: 660px;
    }

    .modal-content {
      border-radius: 0px;

      .modal-header {
        border: none;
        padding: 15px 15px 0 15px;

        .close {
          font-size: 36px;
          font-weight: 200;
        }
      }

      .modal-title {
        text-align: center;
        color: $color-yellow;
        padding: 7px 0 20px 0;
      }

      .modal-body {
        text-align: center;
        color: $color-grey-dusty;
        padding: 15px 50px 50px 50px;

        .icon_modal_confir {
          font-size: 32px;
        }

        .color-yellow {
          color: $color-yellow;
        }
      }

      .modal-footer {
        text-align: center;

        .btn-modal-ok {
        }
      }
    }
  }
}
</style>
