<template>
  <div
    id="confirmationModal"
    class="modal fade"
    :class="configs.class"
    tabindex="-1"
    role="dialog"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-mask"></div>
    <div
      class="modal-dialog"
      :class="{ 'dialog-warning': configs.type === 'warning-2' }"
      role="document"
      :style="{ height: configs.heightModal, width: configs.widthModal }"
    >
      <div
        class="modal-content"
        :class="{ borderTop: configs.type === 'convertSmallBalance' }"
      >
        <div class="modal-header">
          <span class="symbol message-icon"></span>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="redirectLanding"
            v-if="configs.type === 'confirm-succeed-modal'"
          >
            <img src="/images/close.svg" alt="close" />
          </button>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="redirectLogin"
            v-else-if="configs.type === 'confirm-fail-modal'"
          >
            <img src="/images/close.svg" alt="close" />
          </button>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="redirectLogin"
            v-else-if="configs.type === 'resetPW'"
          >
            <img src="/images/close.svg" alt="close" />
          </button>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="onCancel"
            v-else-if="configs.type === 'success'"
          >
            <img src="/images/close.svg" alt="close" />
          </button>
          <div v-else-if="configs.type === 'convertSmallBalance'"></div>

          <!-- Close Button -->
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            v-else
          >
            <img src="/images/close.svg" alt="close" />
          </button>
        </div>

        <div class="modal-body">
          <div class="t-center">
            <div class="">
              <h4
                class="title_positionsMargin"
                v-if="configs.type === 'positionsMargin'"
              >
                {{ configs.head }}
              </h4>
              <div v-if="configs.type === 'primary'" class="fo_light_blue">
                <img src="/images/ellipse.png" alt="question" />
                <h3
                  class="title-header-modal"
                  v-html="configs.title"
                  v-if="configs.type !== 'primary'"
                ></h3>
              </div>
              <div
                v-else-if="configs.type === 'warning-2'"
                class="fo_light_blue"
              >
                <img
                  src="/images/question-mark-2.png"
                  alt="warning"
                  class="image__warning"
                />
                <h3 class="title-header-modal" v-html="configs.title"></h3>
              </div>
              <div
                v-else-if="configs.type === 'disableEmail'"
                class="fo_light_blue"
              >
                <img src="/images/bell.svg" alt="bell" />
                <h3 class="title-header-modal" v-html="configs.title"></h3>
              </div>
              <div v-else-if="configs.type === 'whiteList'" class="whiteList">
                <img src="/images/question-mark-3.svg" alt="bell" />
              </div>
              <!-- -----------------------Modal confirm convert small balance success ---------------------- -->

              <div
                v-else-if="configs.type === 'convertSmallBalanceSuccess'"
                class="fo_light_blue"
              >
                <img src="/images/email-icon.svg" alt="email" />
                <div class="wrap-notification">
                  <h4 class="title-header-content" v-html="configs.title"></h4>
                </div>
                <div class="btn-groups">
                  <button
                    type="button"
                    data-dismiss="modal"
                    v-on:click="redirectSendConfirmEmail"
                    class="btn-confirm"
                  >
                    {{ $t("common.action.ok") }}
                  </button>
                </div>
              </div>

              <!-------------------------------convert small balance--------------------------------->
              <div
                v-else-if="configs.type === 'convertSmallBalance'"
                class="fo_light_blue"
              >
                <img src="/images/bell.svg" alt="bell" />
                <h2 class="h2-title">{{ $t("balance.convert.btc") }}</h2>
                <h4
                  class="tit_modal_confir_convert_balance"
                  v-html="configs.title"
                ></h4>
              </div>

              <div v-else-if="configs.type === 'resetMail'">
                <div class="fo_green2">
                  <!-- <span class="icon-checked icon-help2"></span> -->
                  <img src="/images/icon-success.svg" alt="success" />
                  <h3
                    class="title-header-modal mt-10px"
                    v-html="configs.title"
                  ></h3>
                </div>
                <h5 class="title-header-content">
                  {{ $t("reset_password.send_mail_title_2") }}
                </h5>
                <p class="modal-content-text">
                  {{ $t("reset_password.sent_mail_2") }}
                </p>
              </div>
              <div v-else-if="configs.type === 'resetPW'" class="fo_green_3">
                <img src="/images/icon-success.svg" alt="success" />
                <h3 class="tit_modal_confirm">
                  {{ $t("reset_passsword_complete") }}
                </h3>
                <div class="mart50">
                  <router-link class="link-to" data-dismiss="modal" to="/login">
                    {{ $t("login.title") }}
                  </router-link>
                </div>
              </div>
              <div
                v-else-if="configs.type === 'login-succeed-modal'"
                class="fo_green_4"
              >
                <img src="/images/new-device.svg" alt="icon" />
                <div class="wrap-notification">
                  <h4 class="title-header-content" v-html="configs.title"></h4>
                </div>
                <div class="btn-groups">
                  <button
                    type="button"
                    data-dismiss="modal"
                    @click="redirectLogin"
                    class="btn-confirm"
                  >
                    {{ $t("common.action.ok") }}
                  </button>
                </div>
              </div>
              <!-- -----------------------Modal confirm Email success ---------------------- -->
              <div
                v-else-if="configs.type === 'confirm-succeed-modal'"
                class="fo_green_4 confirm-new-device"
              >
                <img src="/images/nvx/new-device-success.png" alt="email" />
                <div class="wrap-notification">
                  <h4 class="title-header-modal" v-html="configs.title"></h4>
                </div>
                <!-- <div class="btn-groups">
                  <button
                    type="button"
                    data-dismiss="modal"
                    v-on:click="redirectLanding"
                    class="btn-confirm"
                  >
                    {{ $t('common.action.ok') }}
                  </button>
                </div> -->
              </div>
              <!-- -----------------------Modal confirm resend Email success ---------------------- -->
              <div
                v-else-if="
                  configs.type === 'confirm-succeed-resent-email-modal'
                "
                class="fo_green_4 confirm-succeed-resent-email-modal"
              >
                <img src="/images/email-icon.svg" alt="email" />
                <div class="wrap-notification">
                  <h4 class="title-header-modal" v-html="configs.title"></h4>
                </div>
                <div class="btn-groups">
                  <button
                    type="button"
                    data-dismiss="modal"
                    @click="redirectSendConfirmEmail"
                    class="btn-confirm"
                  >
                    {{ $t("common.action.ok") }}
                  </button>
                </div>
              </div>
              <!-- -----------------------Modal confirm Email success 2 ---------------------- -->
              <div
                v-else-if="configs.type === 'confirm-succeed-modal2'"
                class="fo_green_4"
              >
                <img src="/images/email-icon.svg" alt="email" />
                <div class="wrap-notification">
                  <h4
                    class="title-header-modal link-expired"
                    v-html="configs.title"
                  ></h4>
                </div>
                <div class="btn-groups">
                  <button
                    type="button"
                    data-dismiss="modal"
                    v-on:click="redirectLogin"
                    class="btn-confirm"
                  >
                    {{ $t("common.action.ok") }}
                  </button>
                </div>
              </div>
              <!-- ------------------------Modal confirm Email fail invaild or expired ----------------- -->
              <div
                v-else-if="configs.type === 'confirm-fail-modal'"
                class="fo_green_4"
              >
                <img src="/images/email-icon.svg" alt="email" />
                <div class="wrap-notification">
                  <h4
                    class="title-header-modal link-expired"
                    v-html="configs.title"
                  ></h4>
                </div>
                <div class="btn-groups">
                  <button
                    type="button"
                    data-dismiss="modal"
                    @click="redirectLogin"
                    class="btn-confirm"
                  >
                    {{ $t("common.action.ok") }}
                  </button>
                </div>
              </div>
              <!-- ------------------------Modal display when internet connection is disconnect ----------------- -->
              <div
                v-else-if="configs.type === 'connection-internet-fail'"
                class="fo_green_4"
              >
                <img src="/images/email-icon.svg" alt="email" />
                <div class="wrap-notification">
                  <h4 class="title-header-modal" v-html="configs.title"></h4>
                </div>
                <div class="btn-groups">
                  <button
                    type="button"
                    data-dismiss="modal"
                    v-on:click="redirectLogin"
                    class="btn-confirm"
                  >
                    {{ $t("common.action.ok") }}
                  </button>
                </div>
              </div>

              <div
                v-else-if="configs.type === 'confirm-withdrawal-transaction'"
                class="fo_green_4"
              >
                <img
                  src="/images/confirm-transaction-success.svg"
                  alt="email"
                />
                <div class="wrap-notification">
                  <h4 class="title-header-content" v-html="configs.title"></h4>
                </div>
                <div class="btn-groups">
                  <button
                    type="button"
                    data-dismiss="modal"
                    v-on:click="redirectLogin"
                    class="btn-confirm"
                    :style="{ 'max-width': configs.widthBtnConfirm }"
                  >
                    {{ $t("common.action.ok") }}
                  </button>
                </div>
              </div>
              <div v-else-if="configs.type === 'success'" class="fo_green">
                <span class="icon-checked icon-help2"></span>
                <h3 class="title-header-modal" v-html="configs.title"></h3>
              </div>
              <div v-else-if="configs.type === 'warning'" class="fo_yellow">
                <img src="/images/question-mark.svg" alt="question" />
                <h3 class="title-header-modal" v-html="configs.title"></h3>
              </div>
              <div v-else-if="configs.type === 'danger'" class="fo_red">
                <img
                  src="/images/icon/cancel-gi.svg"
                  width="40px"
                  height="40px"
                />
                <h3 class="title-header-modal" v-html="configs.title"></h3>
              </div>
              <div v-else-if="configs.type === 'confirm'">
                <h3 class="title-header-modal" v-html="configs.title"></h3>
              </div>
              <div v-else-if="configs.type === 'positionsMargin'">
                <h3
                  class="tit_modal_positions_argin"
                  :class="configs.class"
                  v-html="configs.title"
                ></h3>
              </div>
              <div v-else class="fo_light_blue">
                <img src="/images/question-mark.svg" alt="question" />
                <!-- <h3 class="title-header-modal">{{ $t('shared_components.common.confirmation_modal.info') }}</h3> -->
              </div>
            </div>
            <template v-if="configs.customContent">
              <p class="content_text">
                <slot name="content"></slot>
              </p>
            </template>
            <template v-else-if="configs.type === 'whiteList'">
              <p v-html="configs.content" class="content_text_300"></p>
            </template>
            <template v-else-if="!!configs.content">
              <p v-html="configs.content" class="content_text"></p>
            </template>
            <!-- <template v-else-if="configs.type === 'confirm-succeed-modal2'">
              <p class="padd-bottom-15"></p>
            </template> -->
            <template
              v-else-if="
                ![
                  'resetMail',
                  'confirm-fail-modal',
                  'resetPW',
                  'confirm-succeed-modal',
                  'confirm-succeed-modal2',
                  'confirm-withdrawal-transaction',
                ].includes(configs.type)
              "
            >
              <!-- <p class="padd-bottom-20"></p> -->
            </template>
            <!-- <template v-else>
              <p class="padd-bottom-10"></p>
            </template> -->
            <template v-if="configs.type === 'positionsMargin'">
              <p class="des_modal">{{ configs.bottom }}</p>
            </template>

            <div
              class="mart50 btn-groups"
              :class="configs.btnRow ? 'btn-groups__row' : ''"
              v-if="configs.btnCancelLabel || configs.btnConfirmLabel"
            >
              <button type="button" data-dismiss="modal" class="btn-cancel">
                {{ configs.btnCancelLabel }}
              </button>
              <button type="button" data-dismiss="modal" class="btn-confirm">
                {{ configs.btnConfirmLabel }}
              </button>
              <!-- <button type="button" data-dismiss="modal" class="btn btn-confirm btn-space btn-primary">{{ configs.btnConfirmLabel }}</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
window.ConfirmationModal = {
  show: function (configs) {
    window.app.$broadcast("showModal", configs);
  },
};
export default {
  data() {
    return {
      scrollY: 0,
      configs: {
        type: "",
        title: "",
        content: "",
        btnCancelLabel: this.$t("common_action.cancel"),
        btnConfirmLabel: this.$t("common_action.confirm"),
        onConfirm: () => {},
        onCancel: () => {},
        size: "small",
        class: "",
        heightModal: "",
        withModal: "",
        displayCloseButton: false,
        btnRow: false,
        widthBtnConfirm: "",
      },
    };
  },
  props: {
    showRePass: { type: Boolean, default: false },
    linkOnCancel: { type: String },
  },
  methods: {
    onCancel() {
      if (typeof this.linkOnCancel !== "undefined") {
        let path = this.$router.options.routes.find(
          (el) => el.name == this.linkOnCancel
        );
        if (!path) {
          console.error("Cannot find path by name!", this.linkOnCancel);
        }
        this.$router.push(path ? path : "/");
      }
    },
    show() {
      const modal = $("#confirmationModal");
      modal.find(".close").unbind("click").click(this.configs.onCancel);
      modal.find(".btn-cancel").unbind("click").click(this.configs.onCancel);
      modal.find(".btn-confirm").unbind("click").click(this.configs.onConfirm);
      modal.modal("show");
      const body = document.body;
      body.classList.add("no-scroll");
      this.scrollY = window.scrollY;
      $("html").addClass("modal-open").css({
        marginTop: -this.scrollY,
        overflow: "hidden",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: "fixed",
      });
    },
    hide() {
      $("#confirmationModal").modal("hide");
    },
    redirectLogin() {
      this.$router.push({ name: "Login" });
    },
    redirectLanding() {
      this.$router.push({ name: "Landing Page" });
    },
    redirectForgotPassword() {
      this.$router.push({ name: "Landing Page" });
    },
    redirectResetPassword() {
      this.showRePass = true;
      this.$emit("showRePass", this.showRePass);
    },
    redirectSendConfirmEmail() {
      this.$emit("exit", true);
    },
  },
  created() {
    let self = this;
    var compatibleBrowser = typeof Object["__defineSetter__"] === "function";
    this.$on("showModal", (userConfigs) => {
      self.configs = Object.assign(self.configs, userConfigs);
      this.show();
    });
  },
  mounted() {
    const modal = $("#confirmationModal");
    modal.on("hide.bs.modal", () => {
      // put your default event here
      const body = document.body;
      body.classList.remove("no-scroll");
      $("html").removeClass("modal-open").css({
        marginTop: 0,
        overflow: "visible",
        left: "auto",
        right: "auto",
        top: "auto",
        bottom: "auto",
        position: "static",
      });
      window.scrollTo({ left: 0, top: this.scrollY, behavior: "instant" });
      this.scrollY = 0;
    });
  },
};
</script>

<style lang="scss" scoped>
.btn-groups {
  margin-top: 16px;
  display: flex;
  gap: 20px;
  justify-content: center;

  button {
    &.btn-confirm {
      @include btn-common(contained, lg);
    }

    &.btn-cancel {
      @include btn-common(contained, lg);
      background: $bg-main3;
      color: $text-main2;
    }
  }

  @include mobile {
    &__row {
      flex-direction: row;

      > * {
        flex: 1;
      }
    }
  }
}

.dialog22 {
  margin: 9% auto 0px auto !important;
}

#confirmationModal {
  .email-user {
    display: block;
    color: $primary-2;
    font-size: 16px;
    margin-top: 10px;
    line-height: 20px;
  }

  .close {
    &:hover {
      .icon-close {
        background-color: $color-light-grey;
        color: $color-grey;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
#confirmationModal {
  position: fixed;
  z-index: 9998;
  text-align: center;
  padding: 0 !important;
  transition-duration: 0.3s;

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    display: block;
    transition: opacity 0.3s ease;
  }

  .btn-secondary {
    font-family: $helvetica;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: $primary-0 !important;
    background-color: $color-white !important;
    border: 1px solid $primary-0 !important;
    border-radius: 30px;
  }

  .email-user {
    display: block;
    color: $primary-2;
    font-size: 16px;
    margin-top: 10px;
    line-height: 20px;
  }

  .modal {
    text-align: center;
    padding: 0 !important;

    &::before {
      content: "";
      display: inline-block;
      height: 100%;
      vertical-align: middle;
      margin-right: -4px;
    }
  }

  img[alt="email"] {
    margin-bottom: 32px;
  }

  .icon-help2,
  .icon-email3,
  .icon-money {
    font-size: 70px;
    color: $color-jungle-green;
    margin-top: 0px;
  }

  .h2-title {
    font-family: Roboto-Regular;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #999999;
  }

  .tit_modal_confir_convert_balance {
    color: $color-eden;
    font-size: 30px;
    font-family: $font-family-roboto-medium;
    line-height: 29px;
    margin-bottom: -55px;
    margin-top: -5px;
    max-width: 350px;
    margin-left: 90px;

    @include mobile {
      max-width: unset;
    }
  }

  .tit_modal_confirm {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 18px;
    color: rgba($text-main2, 0.87);
    font-weight: 700;
    line-height: 32px;
  }

  .modal-dialog {
    z-index: 10000;
    vertical-align: middle;
    display: inline-block;
    text-align: left;
    margin: 6% auto 0px auto;

    .modal-content {
      &.borderTop {
        border-top: 10px solid #2dac91;
      }

      border-radius: 16px;
      position: unset;
      border: 0;

      .modal-title {
        color: $color_grey_text;
        display: inline-block;
        font-size: $font-big-20;
      }

      .close {
        height: 24px;
        width: 24px;
        z-index: 100;
        opacity: 0.9;
        position: absolute;
        top: 12px;
        right: 12px;
        outline: none;
        background: transparent !important;

        &:hover {
          opacity: 1;

          .icon-close {
            background-color: $color-light-grey;
            color: $color-grey;
          }
        }

        .icon-close {
          border-radius: 50%;
          // background-color: $color-grey-white;
          color: $color-grey;
          line-height: 32px;
          overflow: hidden;
          text-align: center;
          display: inline-block;
          font-size: $font-smaller;
          width: 30px;
          height: 30px;
          margin: 0px 0px 0 0;
          cursor: pointer;

          &:hover {
            background-color: $color-light-grey;
            color: $color-grey;
          }
        }
      }

      .modal-header {
        position: relative;
        border-bottom: none;

        &.success {
          padding: 10px;
          padding-bottom: 0;
        }
      }

      .modal-body {
        border: 0px;
        color: rgb(51, 51, 51);
        position: unset;
        padding: 0 24px 24px;
        font-size: $font-small;

        .fo_light_blue {
          img {
            width: 137px;
            height: 137px;
            margin-bottom: 30px;
          }
        }

        .whiteList {
          img {
            width: 72px;
            height: 72px;
            margin-bottom: 24px;
          }
        }

        .content_text {
          color: rgba($text-main2, 0.87);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 24px;

          strong {
          }

          @include mobile {
            min-height: unset;
            margin-bottom: 27px;
          }
        }

        .content_text_300 {
          color: $text-secondary;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 24px;
          margin-bottom: 24px;

          @include mobile {
            font-size: 14px;
            line-height: 22px;
            min-height: unset;
          }
        }

        .primary {
          margin-top: 30px;
          margin-bottom: 20px;

          .btn-primary {
            background-color: $color-yellow;
            border-color: $color-yellow;
            color: $color_white;
            -webkit-transition: 0.5s;
            transition: 0.5s;

            &:hover,
            &:active,
            &:focus {
              background: $color-corn;
              border-color: $color-corn;
              color: $color-white;
              -webkit-transition: 0.5s;
              transition: 0.5s;
            }
          }
        }

        .success {
          .btn-primary {
            background-color: $color-green;
            border-color: $color-green;
            color: $color_white;
            -webkit-transition: 0.5s;
            transition: 0.5s;

            &:hover,
            &:active,
            &:focus {
              background: $color-corn;
              border-color: $color-corn;
              color: $color-white;
              -webkit-transition: 0.5s;
              transition: 0.5s;
            }
          }
        }

        .warning {
          .btn-primary {
            background-color: $color-yellow-pale;
            border-color: $color-yellow-pale;
            color: $color_white;
            -webkit-transition: 0.5s;
            transition: 0.5s;

            &:hover,
            &:active,
            &:focus {
              background: $color-corn;
              border-color: $color-corn;
              color: $color-white;
              -webkit-transition: 0.5s;
              transition: 0.5s;
            }
          }
        }

        .danger {
          .btn-primary {
            background-color: $color-red;
            border-color: $color-red;
            color: $color_white;
            -webkit-transition: 0.5s;
            transition: 0.5s;

            &:hover,
            &:active,
            &:focus {
              background: $color-corn;
              border-color: $color-corn;
              color: $color-white;
              -webkit-transition: 0.5s;
              transition: 0.5s;
            }
          }
        }

        .confirm-succeed-resent-email-modal {
          .title-header-modal {
            margin-top: 0;
            margin-bottom: 16px;
          }
        }
      }
    }

    .modal-footer {
      padding: 15px;
      background-color: $color-white;
      border-top: none;

      .btn {
        border-radius: 0px;
        padding: 7px 30px;
        color: $color-white;
        border: none;
        font-size: 13px;
      }

      .btn-cancel {
        background: $color_black_logo;

        &:hover {
          opacity: 0.2;
        }
      }
    }

    .complete-icon::before,
    .login-complete-icon::before {
      color: $color-green;
    }

    .complete-icon {
      font-size: 70px;
    }

    .login-complete-icon {
      font-size: 80px;
    }

    // new css
    .fo_green2 {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;

      @include mobile {
        > img {
          width: 100px;
          height: 100px;
        }
      }

      .title-header-modal {
        margin: 0;
      }
    }

    .fo_green_3 {
      @include mobile {
        > img {
          width: 100px;
          height: 100px;
        }
      }
    }

    .title-l {
      margin: 13px 0px 15px 0px;
      height: 1px;
      width: 10%;
      border-radius: 5px;
      background-color: $color-light-grey;
    }

    .title-header-modal {
      color: rgba($text-main2, 0.87);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;

      &.link-expired {
        margin-top: 0;
        margin-bottom: 16px;
      }
    }

    .title-header-content {
      margin: 0;
      color: $text-main;
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;

      :deep p {
        margin-top: 8px;
        margin-bottom: 0;
        color: $text-secondary;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
      }

      @include mobile {
        text-align: center;
      }
    }

    .modal-content-text {
      color: rgba($text-main2, 0.6);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;

      @include mobile {
        padding: 0 6px;
      }
    }

    .link-to {
      @include btn-common(contained, md);
    }

    .btn-forgotToLogin {
      margin-top: 0;
    }

    @include mobile {
      max-width: calc(100vw - 32px);
      padding: 0 16px;
      width: 100%;
    }
  }

  &.modal-success-email .modal-dialog {
    height: 250px;
  }

  &.modal-success-password .modal-dialog {
    height: 330px;
    width: 480px;
  }

  .icon-save {
    font-size: $font-title-size-big-small-3;

    &::before {
      content: "\E922";
      color: $text-green;
    }
  }
}

.des_modal {
  @include text-font($helvetica, $dark-3, 14px, 500, 25px);
}

.image__warning {
  max-width: 140px;
  height: auto;
}

#confirmationModal .modal-dialog.dialog-warning {
  min-width: 348px;
  width: fit-content;

  .modal-header {
    display: none;
  }

  .modal-content .modal-body {
    padding: 24px;

    .fo_light_blue {
      img {
        width: 137px;
        height: 137px;
        margin-bottom: 0;
      }

      .title-header-modal {
        display: none;
      }
    }

    p.content_text {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-top: 32px;
    }
  }

  .btn-groups {
    justify-content: space-between;
    gap: 16px;

    button {
      &.btn-confirm {
        @include btn-common(contained, md);
      }

      &.btn-cancel {
        @include btn-common(contained, md);
        background: $bg-main3;
        color: $text-main;
      }
    }
  }

  @include mobile {
    width: 100%;

    .modal-content .modal-body {
      padding: 24px;

      .fo_light_blue img {
        width: 100px;
        height: 100px;
      }

      .content_text {
        margin: 32px 0 24px;
      }
    }
  }
}

.confirm-new-device {
  .title-header-modal {
    margin: 0;

    :deep p {
      font-weight: 300;
      margin-top: 8px;
      margin-bottom: 0;
    }
  }
}

@include mobile {
  .fo_green_4 {
    img {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
