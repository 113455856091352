<template>
  <div class="amount-box input-group">
    <span class="amount-input">
      <currency-input
        :disabled="!withdrawLimit.currency"
        v-model="amount"
        :class="{ error: errors.has('amount') }"
        :changePrecision="true"
        :coin="this.coin"
        class="form-input-amount txt-right"
        id="amount"
        @focus="$emit('focus')"
      />
      <span class="max-amount" v-if="withdrawLimit.currency">
        {{ $t("funds.withdrawals.withdrawal_placeholder_available") }}
        {{ calcAmountAvailable() | formatCurrencyAmount(coin, "0") }}
        <span class="color-grey-dark">{{ coin | uppercase }}</span>
      </span>
      <span class="max-amount" v-else>
        {{ $t("funds.withdrawals.withdrawal_placeholder_loading") }}
      </span>
    </span>
    <span class="set-max" @click="setMaxAmount()">{{
      $t("common.max_only")
    }}</span>
    <span class="invalid-feedback" v-if="errors.has('amount')">{{
      $t(errors.first("amount"))
    }}</span>
    <span class="available responsive">
      {{ $t("funds.withdrawals.withdrawal_placeholder_available") }}
      {{ calcAmountAvailable() | formatCurrencyAmount(coin, "0") }}
      {{ coin.toUpperCase() }}</span
    >
  </div>
</template>

<script>
import BigNumber from "bignumber.js";
import Utils from "@/common/Utils";
export default {
  inject: ["$validator"],
  props: {
    value: { required: true },
    coin: { type: String, required: true },
    withdrawLimit: { type: Object, required: true },
    balance: { type: Object, required: true },
    withdrawDaily: { type: String, default: "0" },
    minimumWithdrawFee: { type: String, default: "0" },
  },
  data() {
    return {
      amount: "",
      decimalCoin: null,
    };
  },

  computed: {
    maxWithdrawAvailable: function () {
      const limit = `${this.withdrawLimit.limit || 0}`;
      const fee = `${this.withdrawLimit.fee || 0}`;
      const dailyLimit = `${this.withdrawLimit.daily_limit || 0}`;
      const withdrawDaily = `${this.withdrawDaily || 0}`;
      const availableBalance = this.balance.available_balance || 0;

      const withdrawDailyLimit = BigNumber.max(
        new BigNumber(dailyLimit).minus(withdrawDaily),
        "0"
      );

      let withdrawDailyAvailable = new BigNumber("0");

      const compare = new BigNumber(availableBalance).comparedTo(
        BigNumber.min(withdrawDailyLimit, limit).add(fee)
      );

      if (compare === -1) {
        withdrawDailyAvailable = new BigNumber(availableBalance);
      } else {
        withdrawDailyAvailable = BigNumber.min(withdrawDailyLimit, limit).add(
          fee
        );
      }

      return BigNumber.max(withdrawDailyAvailable, "0").toString();
    },

    externalAmount() {
      return new BigNumber(`${this.amount || 0}`)
        .sub(`${this.withdrawLimit.fee || "0"}`)
        .toString();
    },
  },

  watch: {
    value(val) {
      const externalAmount = new BigNumber(`${this.externalAmount || 0}`);
      if (externalAmount.comparedTo(`${val || 0}`) === 0) {
        return;
      }
      this.amount = val;
    },

    amount: function (newVal) {
      if (window._.isEmpty(newVal)) {
        return this.emitInput("");
      }
      const newAmount = new BigNumber(`${newVal || 0}`);
      if (newAmount.comparedTo("0") < 1) {
        return this.emitInput("0");
      }
      this.emitInput(this.externalAmount);
    },

    coin: function () {
      this.amount = "";
    },
  },

  methods: {
    emitInput(newVal) {
      if (newVal !== this.value) {
        this.$emit("input", newVal);
      }
    },

    calcAmountAvailable() {
      let amount = new BigNumber(`${this.maxWithdrawAvailable || 0}`);

      if (amount.comparedTo(this.withdrawLimit.fee || 0) === 0) {
        return "0";
      }

      return amount.toString();
    },

    validate() {
      if (window._.isEmpty(this.amount)) {
        this.errors.add("amount", this.$t("bank_setting.error.lose_amount"));
      }
      const amount = `${this.amount || 0}`;
      // let minAmount = new BigNumber(`${this.withdrawLimit.minium_withdrawal || 0}`).plus(`${this.withdrawLimit.fee || 0}`);
      // if(!this.isWithdrawAddressInternal) {
      //   const tax = new BigNumber(`${this.withdrawLimit.minium_withdrawal || 0}`).mul(this.tax_rate);
      //   minAmount = minAmount.plus(tax);
      // }

      const minAmount = this.minimumWithdrawFee;
      if (new BigNumber(amount).comparedTo(minAmount) < 0) {
        this.errors.add(
          "amount",
          this.$t("funds.withdrawals.errors.min_amount", {
            min_amount: Utils.formatCurrencyAmount(minAmount, this.coin, "0"),
          })
        );
      }
      if (
        new BigNumber(amount).comparedTo(
          `${this.balance.available_balance || 0}`
        ) > 0
      ) {
        this.errors.add("amount", "funds.withdrawals.errors.max_balance");
      }
      if (
        new BigNumber(amount).comparedTo(`${this.calcAmountAvailable() || 0}`) >
        0
      ) {
        this.errors.add("amount", "funds.withdrawals.errors.max_once_amount");
      }
    },

    setMaxAmount() {
      this.amount = this.calcAmountAvailable();
    },
  },
};
</script>

<style lang="scss" scoped>
.f-left {
  float: left;
}

.form-input-amount {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10.5px 16px;
  gap: 10px;
  height: 41px;
  background: $color-white;
  border: 1px solid $grey-1;
  border-radius: 12px;
  width: 100%;
  &::placeholder {
    @include text-font($helvetica, $dark-4, $font-medium-bigger, 500, 20px);
  }
  &:focus {
    border: 1px solid $bg-main1 !important;
  }
}

.amount-box {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 16px !important;
  row-gap: 0 !important;
  .amount-input {
    width: calc(100% - 97px);
    position: relative;
    float: left;
    height: 100%;
    max-height: 42px;
    input {
      padding-right: 120px;
      height: 42px;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      gap: 10px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      color: $text-main;
    }
    .max-amount {
      color: $text-main2;
      opacity: 0.6;
      position: absolute;
      right: 10px;
      z-index: 9;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
  .set-max {
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px;
    gap: 10px;
    height: 42px;
    background: $bg-main1;
    border: none;
    border-radius: 8px;
    color: $color-white;
  }
  .color-grey-dark {
    color: $text-main;
  }
}
.responsive {
  display: none;
}
.invalid-feedback {
  height: auto;
}
@include mobile {
  .max-amount {
    display: none;
  }
  .input-group {
    margin: 0;
  }
  .amount-box {
    height: auto;
    .amount-input {
      height: 42px;
      width: calc(100% - 70px);
      input {
        padding: 10px 45px 10px 10px;
      }
    }
    .set-max {
      padding: 10.5px 10px;
      width: 57px;
      height: 41px;
    }
  }
  .available {
    width: 100%;
    color: $text-secondary;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    &.responsive {
      display: block;
      font-size: 12px;
      color: $text-secondary;
    }
    @include mobile {
      font-size: 14px;
    }
  }
}
</style>
