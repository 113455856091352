<template>
  <div class="sc_search_select" v-click-outside="turnOffSelect">
    <div class="group_search_select">
      <template v-if="isShow == false">
        <div class="background_fake" @click="toggleSelect()"></div>
        <div
          class="button_drop_search"
          :class="{ active: isShow }"
          @click="toggleSelect()"
        >
          <span class="title-coin" v-html="label"></span>
          <img src="/images/arrow-down.svg" alt="arrow-down" />
        </div>
      </template>
      <template v-else>
        <div class="input-group">
          <div class="form-input">
            <img src="/images/search.svg" alt="search" />
            <input
              type="Search"
              ref="inputSearch"
              :placeholder="$t('funds.action.placeholder_input_coin')"
              @keyup="doSearch($event.target.value)"
            />
            <img src="/images/arrow-down.svg" alt="arrow-down" />
          </div>
        </div>
      </template>
      <div class="box_list_search_select active" v-show="isShow">
        <ul class="list_search_select" v-if="avaiableOptions.length !== 0">
          <li
            :class="{
              'selected-li':
                value && value.shortNameCurrency === option.shortNameCurrency,
            }"
            v-for="option in avaiableOptions"
            @click.stop="select(option)"
            :key="option.shortNameCurrency"
          >
            <span
              v-if="
                option.shortNameCurrency &&
                option.shortNameCurrency.toUpperCase() != 'IDR'
              "
              class="code_coin_slect"
              :class="{ active: isChangeCode }"
              >{{ option.shortNameCurrency }}</span
            >
            <span
              v-if="
                option.shortNameCurrency &&
                option.shortNameCurrency.toUpperCase() != 'IDR'
              "
              class="full_name_coin_select"
              :class="{ active: isChangeFullName }"
              >{{ option.fullNameCurrency }}</span
            >
          </li>
        </ul>
        <div v-else class="no-data">{{ $t("fee_tier.detail.nodata") }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
    },
    value: {
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      avaiableOptions: this.options,
      isShow: false,
      search: "",
      isChangeCode: true,
      isChangeFullName: true,
    };
  },
  watch: {
    search() {
      this.avaiableOptions = this.options.filter(
        (item) =>
          this.includes(item.shortNameCurrency, this.search) ||
          this.includes(item.fullNameCurrency, this.search)
      );
    },
  },
  computed: {
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === "storage_url")
        .value;
    },
    label() {
      const selected = this.value;
      if (selected && selected.shortNameCurrency) {
        //<span class="icon_coin_slect"> <img class="option__image icon-choice" src="${selected.iconCurrency}" alt="${selected.shortNameCurrency}"></span>
        return `
          <span class="code_coin_slect">${selected.shortNameCurrency}</span>
          <span class="full_name_coin_select">${selected.fullNameCurrency}</span>`;
      }
      return `<span>${this.placeholder}</span>`;
    },
  },
  methods: {
    toggleSelect() {
      this.$emit("resetErrors");
      this.isShow = !this.isShow;
      this.$nextTick(() => {
        if (this.isShow) {
          this.search = "";
          this.$refs.inputSearch.focus();
        }
      });
    },
    turnOffSelect() {
      this.isShow = false;
    },
    select(option) {
      this.$emit("input", option);
      this.turnOffSelect();
    },
    includes(str, query) {
      /* istanbul ignore else */
      if (str === undefined) str = "undefined";
      if (str === null) str = "null";
      if (str === false) str = "false";
      const text = str.toString().toLowerCase();
      const value = query.trim().toString().toLowerCase();
      return text.includes(value);
    },
    doSearch(value) {
      this.search = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.background_fake {
  display: block;
  width: 100%;
  height: 45px;
  opacity: 0;
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 0;
  margin-bottom: -45px;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    border-color: $dark-3;
  }
}

.sc_search_select {
  .tit_search_select {
    font-size: $font-title-size-big;
    color: $color-white;
    margin-bottom: 20px;
    line-height: 44px;
  }

  .group_search_select {
    position: relative;
    border-radius: 12px;

    .button_drop_search {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      line-height: 20px;
      padding: 9px 16px;
      border: 1px solid $color-alto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: transparent;
      border-radius: 12px;
      border: 1px solid $grey-1;

      .title-coin {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;

        @include mobile {
          font-size: 14px;
          line-height: 22px;
        }

        :deep() {
          .code_coin_slect {
            color: $text-main;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;

            @include mobile {
              font-size: 14px;
              line-height: 22px;
            }
          }

          .full_name_coin_select {
            color: $text-main-blur;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px;

            @include mobile {
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
      }

      &:hover {
        border-color: $bg-main1;
      }

      span {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: $dark-1;
        font-size: $font-root;
        line-height: 20px;
      }

      i.icon-arrow1 {
        display: inline-block;
        // float: right;
        color: $color-jungle-green;
        line-height: 15px;
        font-size: $font-small;
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
    }

    .button_drop_search.active {
      i.icon_arrow_sl {
        // color: $color-yellow-pale;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        -webkit-transition: 0.5s;
        transition: 0.5s;
      }
    }

    .box_list_search_select {
      margin-top: 3px;
      background: $color-alabaster;
      position: absolute;
      display: none;
      width: auto;
      top: 100%;
      left: 0px;
      z-index: 5;
      float: left;
      padding: 0;
      visibility: hidden;
      opacity: 0;
      max-height: 470px;
      background-color: $color-white;
      overflow: auto;
      box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
      border-radius: 10px;
    }

    .box_list_search_select.active {
      display: block;
      visibility: visible;
      opacity: 1;
      width: 100%;
    }

    .box_input_s {
      align-items: center;
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.07);
      width: 100%;
      border: 1px solid $primary-2;
      display: flex;
      flex-direction: row;
      // padding: 10.5px 16px;
      gap: 10px;
      border-radius: 12px;
      border: 1px solid #c7cbd3;
      padding: 10px 16px;

      label.icon-search {
        font-size: 18px;
      }

      // border: 1px solid $color-alto;
      // border-bottom: 1px solid $color-alto;
      background-color: $color-white;

      .box-input-icon {
        width: 20px;
        height: 20px;
        background-image: url("@/assets/images/wallet/search_icon.png");
        background-size: 20px 20px;
        background-repeat: no-repeat;
        background-position: center;
      }

      .input_search_select {
        width: 100%;
        // float: right;
        // border: 1px solid $color-alto;
        border: none;
        color: $dark-1;
        outline-offset: 0px;
        font-size: 16px;
        line-height: 20px;
        outline-offset: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        background: transparent;

        &::placeholder {
          color: $dark-4;
        }
      }
    }

    .no-data {
      color: $text-secondary;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      text-align: center;
      padding: 10px;
    }

    .list_search_select {
      list-style-type: none;
      max-height: 200px;
      overflow-y: auto;
      margin: 10px 0;

      .selected-li {
        background: $grey-3;
      }

      li {
        display: flex;
        align-items: center;
        width: 100%;
        line-height: 20px;
        cursor: pointer;
        padding: 10px 20px;
        font-size: 16px;
        overflow: hidden;
        color: $dark-1;
        display: flex;
        align-items: center;
        gap: 10px;

        &:hover {
          background: $grey-3;
        }
      }
    }
  }
}

.field-error {
  .group_search_select {
    .button_drop_search {
      border-color: $color-alizarin-crimson;
    }
  }
}

::-webkit-scrollbar-track {
  background: $color-white;
}

::-webkit-scrollbar-thumb {
  background: $color-grey-medium;
}

::-webkit-scrollbar-thumb:hover {
  background: $color-grey-medium;
}
</style>
<style lang="scss" scoped>
.bold {
  font-weight: 700 !important;
}

.icon_coin_slect {
  display: flex;
  width: 20px;
  height: 20px;

  img {
    width: 100%;
    height: 100%;
  }
}

.code_coin_slect {
  display: inline-block;
}

:deep {
  .full_name_coin_select {
    display: inline-block;
    color: $text-secondary;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

// .full_name_coin_select.active {
//   font-weight: normal;
//   color: $color-grey-dusty;
// }
</style>
