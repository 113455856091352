<template>
  <div>
    <template v-if="isMobile && data.data && data.data.length !== 0">
      <div
        class="volume-table__mobile-wrapper"
        :class="{ tableNoPaginate: totalPages <= 1 }"
      >
        <div class="volume-table__mobile-header">
          <div class="volume-table__mobile-title">
            <div class="mobile-title__item">
              {{ $t("fee_tier.volume_history.date") }}
            </div>
            <div class="mobile-title__item">
              {{ $t("fee_tier.volume_history.period") }}
            </div>
          </div>
        </div>
        <div class="volume-table__mobile-body">
          <template v-if="data?.data && data?.data?.length !== 0">
            <div
              class="volume-table__mobile-item"
              v-for="item in data?.data"
              aria-expanded="false"
              :key="item._id"
            >
              <div class="mobile-item__wrapper">
                <div class="item-wrapper__title">
                  {{ formatDate(item.date) }}
                </div>
                <div class="item-wrapper__title">
                  {{ periodDay(item.date) }}
                </div>
                <div
                  class="item-wrapper__dropdown-icon"
                  @click.prevent="onClickExpandItem"
                >
                  <img
                    src="/images/instant-convert/icon-dropdown.svg"
                    alt=""
                    class="dropdown-icon__down"
                  />
                  <img
                    src="/images/instant-convert/icon-dropdown2.svg"
                    class="dropdown-icon__up"
                    alt=""
                  />
                </div>
              </div>
              <div class="mobile-item__expand-content">
                <div class="expand-content__wrapper">
                  <div class="expand-content__title">
                    {{ $t("fee_tier.volume_history.volume") }}
                  </div>
                  <div class="expand-content__desc">
                    {{ fixedNumber(item.total_daily_volume) }} USDT
                  </div>
                </div>
                <div class="expand-content__wrapper">
                  <div class="expand-content__title">
                    {{ $t("fee_tier.volume_history.average_volume") }}
                  </div>
                  <div class="expand-content__desc">
                    {{ fixedNumber(item.average_daily_volume) || "--" }} USDT
                  </div>
                </div>
                <div class="expand-content__wrapper">
                  <div class="expand-content__title">
                    {{ $t("fee_tier.volume_history.estimates_tier") }}
                  </div>
                  <div class="expand-content__desc">
                    {{ item.estimates_tier || "--" }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <template
          v-if="
            !(
              (data.data && data.data.length === 0) ||
              Object.keys(data).length === 0
            )
          "
        >
          <Pagination
            class="text-right"
            :per-page="9"
            :records="Number(data.total)"
            :chunk="5"
            @Pagination:page="onPageChange"
            :pageParent="data.current_page"
          />
        </template>
      </div>
    </template>
    <template v-else>
      <div id="fee-tier-volume-history" :class="{ over5rows: data.total > 5 }">
        <div
          class="table-wrapper"
          :class="{ tableNoPaginate: totalPages <= 1 }"
        >
          <div
            class="table-inner__wrapper"
            :class="{
              noData:
                (data.data && data.data.length === 0) ||
                Object.keys(data).length === 0,
            }"
          >
            <table>
              <thead>
                <tr>
                  <th class="cl-1">{{ $t("fee_tier.volume_history.date") }}</th>
                  <th class="cl-2">
                    {{ $t("fee_tier.volume_history.period") }}
                  </th>
                  <th class="cl-3">
                    {{ $t("fee_tier.volume_history.volume") }}
                  </th>
                  <th class="cl-4">
                    {{ $t("fee_tier.volume_history.average_volume") }}
                  </th>
                  <th class="cl-5">
                    {{ $t("fee_tier.volume_history.estimates_tier") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <div class="wrap-loader" v-if="loadingDailyVolume">
                  <div class="loader"></div>
                </div>
                <template
                  v-else-if="
                    (data.data && data.data.length === 0) ||
                    Object.keys(data).length === 0
                  "
                >
                  <tr class="empty-data">
                    <!-- <td colspan="50" v-if="fetching">
                      <div class="loader"></div>
                    </td> -->
                    <td colspan="50">
                      <div class="empty-noti">
                        <div class="text-wrapper">
                          <img
                            class="icon-nodata"
                            src="/images/table-nodata.png"
                            alt="no_fund_icon"
                          />
                          <span class="msg-empty">
                            {{ $t("fee_tier.volume_history.nodata") }}</span
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>

                <template v-if="data.data && data.data.length !== 0">
                  <tr v-for="item in data.data" :key="item._id">
                    <td>{{ formatDate(item.date) }}</td>
                    <td>{{ periodDay(item.date) }}</td>
                    <td>{{ fixedNumber(item.total_daily_volume) }} USDT</td>
                    <td>
                      {{ fixedNumber(item.average_daily_volume) || "--" }} USDT
                    </td>
                    <td>{{ item.estimates_tier || "--" }}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
        <template
          v-if="
            !(
              (data.data && data.data.length === 0) ||
              Object.keys(data).length === 0
            )
          "
        >
          <Pagination
            class="text-right"
            :per-page="9"
            :records="Number(data.total)"
            :chunk="5"
            @Pagination:page="onPageChange"
            :pageParent="data.current_page"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import Pagination from "@/components/shared_components/common/DataTable/Pagination";
import Utils from "@/common/Utils";

export default {
  name: "VolumeHistoryTable",
  components: {
    Pagination,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    isMobile() {
      if (!this.windowWidth) return;
      return this.windowWidth <= 768;
    },
    totalPages: function () {
      return Number(this.data?.total)
        ? Math.ceil(Number(this.data?.total) / 5)
        : 1;
    },
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    feeTierStatus: {
      type: Object,
      default: null,
    },
    loadingDailyVolume: { default: true },
  },
  methods: {
    onClickExpandItem(e) {
      const closestWrapper = e.currentTarget.closest(
        ".volume-table__mobile-item"
      );
      closestWrapper.ariaExpanded = !JSON.parse(closestWrapper.ariaExpanded);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    periodDay(date) {
      let day = moment.duration(0, "days");
      let periodDay = moment.utc(date);
      let startDay = moment.utc(this.feeTierStatus.date_apply);
      day = periodDay.diff(startDay, "days");
      return `${day + 1}/${this.feeTierStatus.fee_tier_period}`;
    },
    fixedNumber(number) {
      if (!number || number == 0) {
        return "0";
      }
      if (number.split(".").length === 1) {
        return Utils.formatCurrencyAmount(Number(number).toFixed(0), "idr", 0);
      }
      return Utils.formatCurrencyAmount(
        Math.floor((Number(number) + Number.EPSILON) * 100) / 100,
        "idr",
        0
      );
    },
    onPageChange(page) {
      this.$emit("changePage", page);
    },
  },
};
</script>

<style lang="scss" scoped>
.volume-table__mobile-wrapper {
  &.tableNoPaginate {
    .volume-table__mobile-body {
      position: relative;
      min-height: 50px;
      .volume-table__mobile-item:last-child {
        border-bottom: none;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  & .volume-table__mobile-title {
    display: grid;
    border-radius: 10px 10px 0px 0px;
    background-color: $bg-main4;
    padding: 8px 16px;
    grid-template-columns: repeat(2, 1fr);
    .mobile-title__item {
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      color: $text-secondary;
    }
  }
  & .volume-table__mobile-item {
    background-color: $color-white;
    padding: 10px 16px;
    border-bottom: 1px solid $bg-main2;
    &[aria-expanded="true"] {
      .dropdown-icon__down {
        display: none;
      }
      .dropdown-icon__up {
        display: block;
      }
      .mobile-item__expand-content {
        display: flex;
      }
    }
    &[aria-expanded="false"] {
      .dropdown-icon__down {
        display: block;
      }
      .mobile-item__expand-content,
      .dropdown-icon__up {
        display: none;
      }
    }
    .mobile-item__wrapper {
      display: grid;
      position: relative;
      grid-template-columns: repeat(2, 1fr);
      .item-wrapper__title {
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
        color: $text-main;
      }
      .item-wrapper__dropdown-icon {
        position: absolute;
        right: 0px;
      }
    }
    .mobile-item__expand-content {
      margin-top: 12px;
      background-color: $bg-main4;
      padding: 16px;
      flex-direction: column;
      border-radius: 8px;
      gap: 8px;
      .expand-content__wrapper {
        display: flex;
        justify-content: space-between;
        .expand-content__title {
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
          color: $text-main;
        }
        .expand-content__desc {
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          color: $text-main;
        }
      }
    }
  }
}
#fee-tier-volume-history {
  position: relative;
  overflow: auto;
  border-radius: 10px;
  border-top-left-radius: 0;
  @include tablet {
    height: auto;
    // &.over5rows {
    //   max-height: 534px;
    // }
  }
  @include mobile {
    height: auto;
    // &.over5rows {
    //   max-height: 534px;
    // }
  }
  .table-wrapper {
    min-height: 500px;
    width: 100%;
    overflow: auto;
    &.tableNoPaginate {
      min-height: 557px;
    }
    .table-inner__wrapper {
      min-width: fit-content;
      // border-radius: 10px;
      // border-top-left-radius: 0;
      width: 100%;
      background: transparent;
      overflow: hidden;
      &.noData {
        min-width: unset;
      }
    }
    table {
      width: 100%;
      thead {
        position: sticky;
        top: 0;
        tr {
          th {
            font-family: $helvetica;
            font-size: 14px;
            line-height: 25px;
            background: white;
            color: $text-secondary;
            padding: 0 10px 10px 10px;
            border-bottom: solid 1px $grey-1;
            text-align: left;
            white-space: nowrap;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
              text-align: right;
            }
          }
        }
      }
      tbody {
        tr {
          &.empty-data {
            width: 100%;
            text-align: center;
            td {
              width: 100%;
              text-align: center !important;
              padding: 35px 0;
              border-bottom: none !important;
              .empty-noti {
                display: flex;
                flex-direction: column;
                gap: 20px;
                justify-content: center;
                align-items: center;
                min-height: 490px !important;
                .text-wrapper {
                  user-select: none;
                  pointer-events: none;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                }
                .msg-empty {
                  color: $text-secondary;
                  font-family: Helvetica;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 22px;
                  @include mobile {
                    font-family: Helvetica;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 22px;
                  }
                }
                .text-wrapper {
                  display: flex;
                  flex-direction: column;
                  gap: 16px;
                  justify-content: center;
                  align-items: center;
                }
                .icon-nodata {
                  font-size: 40px;
                  color: $dark-3;
                  width: 137px;
                  height: auto;
                  span {
                    font-size: 14px;
                    @include mobile {
                      font-size: 10px;
                    }
                  }
                }
                @include tablet-d {
                  align-items: unset;
                  height: 100px;
                  .text-wrapper {
                    position: sticky;
                    left: 50%;
                    transform: translate(-50%);
                    width: fit-content;

                    img {
                      margin-bottom: 10px;
                    }
                  }
                }
              }
            }
            p {
              padding-top: 22px;
              span {
                vertical-align: middle;
              }
            }
            .icon-nodata {
              font-size: 40px;
              color: $dark-3;
              span {
                font-size: 14px;
                @include mobile {
                  font-size: 10px;
                }
              }
            }
          }
          td {
            font-family: $helvetica;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: 22px;
            color: $text-main;
            padding: 10px 10px 0 10px;
            text-align: left;
            white-space: nowrap;
            &:first-child {
              padding-left: 0;
            }
            &:last-child {
              padding-right: 0;
              text-align: right;
            }
            &.nodata {
              text-align: center;
              padding: 132px 0;
              > div {
                margin-top: 20px;
                font-family: $helvetica;
                font-size: 14px;
                line-height: 18px;
                color: $dark-3;
              }
              @include tablet {
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                width: -moz-fit-content;
                width: fit-content;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
              @include mobile {
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                width: -moz-fit-content;
                width: fit-content;
                display: flex;
                flex-direction: column;
                align-items: center;
              }
            }
          }
        }
      }
    }
  }
  :deep(.VuePagination) {
    bottom: 0;
    right: 0;
    .pagination {
      margin: 10px 16px 10px 0;
    }
  }
}

.cl-1 {
  min-width: 90px;
}
.cl-2 {
  min-width: 100px;
}
.cl-3 {
  min-width: 100px;
}
.cl-4 {
  min-width: 100px;
}
.cl-5 {
  min-width: 110px;
}
.wrap-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .loader {
    display: block;
    margin: auto;
    border: 5px solid $color-grey-concrete;
    border-radius: 50%;
    border-top: 5px solid $bg-main1;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
}
</style>
