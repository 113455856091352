<template>
  <transition name="fade">
    <div class="black-popup" @click="blackPopClick">
      <slot></slot>
    </div>
  </transition>
</template>
<script>
export default {
  methods: {
    blackPopClick(e) {
      if (e.currentTarget === e.target) {
        this.$emit("blackPopClick");
      }
    },
  },
};
</script>
<style lang="scss">
.black-popup {
  position: fixed;
  /* position: absolute; */
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99999;
  width: 100vw;
  height: 100vh;

  /* height: calc(100% + 50px); */
  .pop_con {
    width: 470px;
    /* height: 375px; */
    position: fixed;
    left: 50%;
    top: 50%;
    cursor: default;
    background-color: #fff;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 30px;

    .conimg {
      width: 100%;
      height: 100%;
    }

    .closeimg {
      width: 20px;
      height: 20px;
      position: absolute;
      right: -20px;
      top: -20px;
      cursor: pointer;
    }
  }

  .law_tip {
    .poptit {
      font-size: 18px;
      font-weight: 600;
    }

    .poptip {
      border: 1px solid #c0ced7;
      padding: 10px;
      font-size: 12px;
      box-sizing: border-box;
      overflow: hidden;
      margin: 20px 0px 30px;
      line-height: 20px;
      background-color: #f3f6fa;
    }

    .accept {
      font-size: 12px;
      margin-bottom: 10px;

      input {
        outline: none;
        vertical-align: text-bottom;
        margin-right: 2px;
      }
    }

    .acceptSubmit {
      /* width: auto; */
      /* height: auto; */
      /* padding: 5px 10px; */
      /* font-size: 12px; */
      /* float: right; */
      /* text-transform: none; */
    }
  }
}
</style>
