<template>
  <Modal
    name="DateTimeSelect"
    width="400"
    :title="$t('account.modal.date_time_pick.title')"
  >
    <template slot="body">
      <div class="date_time_picker">
        <p class="note_header">
          {{ $t("account.modal.date_time_pick.text1") }}
        </p>
        <div class="row_form">
          <ul>
            <li>
              <label class="ng-binding"
                ><input type="radio" value="1" v-model="dateRange" />
                {{ $t("account.modal.date_time_pick.text2") }}</label
              >
            </li>
            <li>
              <label class="ng-binding"
                ><input type="radio" value="2" v-model="dateRange" />
                {{ $t("account.modal.date_time_pick.text3") }}</label
              >
            </li>
            <li>
              <label class="ng-binding"
                ><input type="radio" value="3" v-model="dateRange" />
                {{ $t("account.modal.date_time_pick.text4") }}</label
              >
            </li>
            <li>
              <label class="ng-binding"
                ><input type="radio" value="4" v-model="dateRange" />
                {{ $t("account.modal.date_time_pick.text5") }}</label
              >
            </li>
            <li>
              <label class="ng-binding"
                ><input type="radio" value="5" v-model="dateRange" />
                {{ $t("account.modal.date_time_pick.text6") }}</label
              >
            </li>
            <li>
              <label class="ng-binding"
                ><input type="radio" value="6" v-model="dateRange" />
                {{ $t("account.modal.date_time_pick.text7") }}</label
              >
            </li>
            <li style="width: 100%">
              <label class="ng-binding"
                ><input type="radio" value="7" v-model="dateRange" />
                {{ $t("account.modal.date_time_pick.text8") }}</label
              >
            </li>
          </ul>
        </div>
        <div class="toolbar-element">
          <date-picker
            wrapper-class="date-picker"
            format="dd-MM-yyyy"
            style="width: 150px"
            v-model="startDate"
            :input-class="
              dateRange < 7
                ? 'date-picker-input disable-input'
                : 'date-picker-input'
            "
            :disabledPicker="dateRange < 7"
          >
          </date-picker>
          <span class="spec-char"> - </span>
          <date-picker
            wrapper-class="date-picker"
            format="dd-MM-yyyy"
            style="width: 150px"
            v-model="endDate"
            :input-class="
              dateRange < 7
                ? 'date-picker-input disable-input'
                : 'date-picker-input'
            "
            :disabledPicker="dateRange < 7"
          >
          </date-picker>
        </div>
        <div class="export">
          <button class="btn" @click="downloadReportCommission()">
            {{ $t("account.modal.date_time_pick.text9") }}
          </button>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "../common/Modal";
import rf from "@/request/RequestFactory";
import DatePicker from "vuejs-datepicker";
import moment from "moment";
import MasterdataUtils from "@/common/MasterdataUtils";
import Utils from "@/common/Utils";
export default {
  components: {
    Modal,
    DatePicker,
  },
  data() {
    return {
      isActiveDateInput: false,
      dateRange: "7",
      startDate: moment().subtract(1, "week").toDate(),
      endDate: new Date(),
      inputClass: {
        padding: "100px",
      },
    };
  },
  methods: {
    downloadReportCommission() {
      let url = "/referral/commission/export";
      let startDateMilliseconds, endDateMilliseconds;

      switch (this.dateRange) {
        case "1":
          startDateMilliseconds = Date.parse(
            new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
          );
          break;
        case "2":
          startDateMilliseconds = new Date(
            new Date().getTime() - 24 * 60 * 60 * 1000
          ).setHours(0, 0, 0, 0);
          endDateMilliseconds = new Date(new Date().getTime()).setHours(
            0,
            0,
            0,
            0
          );
          break;
        case "3":
          startDateMilliseconds = new Date(
            new Date().getTime() - 24 * 60 * 60 * 1000 * 14
          ).setHours(0, 0, 0, 0);
          break;
        case "4":
          let date = new Date();
          startDateMilliseconds = Date.parse(
            new Date(date.getFullYear(), date.getMonth(), 1)
          );
          break;
        case "5":
          date = new Date();
          startDateMilliseconds = Date.parse(
            new Date(date.getFullYear(), date.getMonth() - 1, 1)
          );
          break;
        case "6":
          date = new Date();
          startDateMilliseconds = Date.parse(
            new Date(date.getFullYear(), date.getMonth() - 3, 1)
          );
          break;
        case "7":
          endDateMilliseconds = new Date(
            new Date(this.endDate).getTime() + 24 * 60 * 60 * 1000
          ).setHours(0, 0, 0, 0);
          startDateMilliseconds = Date.parse(
            new Date(new Date(this.startDate).setHours(0, 0, 0, 0))
          );

          break;

        default:
      }
      if (endDateMilliseconds) {
        window.location.href =
          url +
          "?timezone_offset=" +
          Utils.getTimzoneOffset() +
          "&startDate=" +
          startDateMilliseconds +
          "&endDate=" +
          endDateMilliseconds;
      } else {
        window.location.href =
          url +
          "?timezone_offset=" +
          Utils.getTimzoneOffset() +
          "&startDate=" +
          startDateMilliseconds;
      }
    },
  },
};
</script>
<style lang="scss">
.font-18 {
  font-size: 18px !important;
}
</style>
<style lang="scss">
.date_time_picker {
  .export {
    padding: 0 32px;
  }

  input[type="radio"],
  input[type="checkbox"] {
    margin: 4px !important;
    line-height: normal;
  }

  input[type="text"].disable-input {
    background: #dddddd !important;
  }

  div {
    float: none !important;
  }

  li {
    margin-left: -15px;
    list-style: none;
    display: list-item;
    text-align: -webkit-match-parent;
    float: left;
  }

  li:nth-child(odd) {
    width: 120px;
    margin-right: 85px;
  }

  .row_form {
    width: 380px;
    display: block;
  }

  .btn {
    width: 100%;
    margin-top: 20px;
    background: #0090eb !important;
    border: none !important;
  }

  .toolbar-element {
    text-align: center;
    display: inline-block !important;
    float: left !important;
    width: 100%;

    .date-picker {
      display: inline-block !important;
    }
  }

  .note_header {
    padding-left: 30px;
  }
}
</style>
