var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.turnOffSelect),expression:"turnOffSelect"}],staticClass:"sc_search_select"},[_c('div',{staticClass:"group_search_select clearfix"},[_c('div',{staticClass:"button_drop_search",class:[{ active: _vm.isShow }, _vm.classFocused],on:{"click":function($event){return _vm.toggleSelect()}}},[(_vm.hasCustomLabel)?_vm._t("label_selected",null,{"selected":_vm.value}):_c('span',{class:{ isPlaceholder: _vm.label === _vm.placeholder }},[(_vm.isShowImageChooseLanguage)?_c('img',{attrs:{"src":_vm.optionSelected.flag,"alt":"image"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('img',{attrs:{"src":"/images/icon/CaretDown.svg","alt":""}})],2),(_vm.isMenu)?_c('div',{staticClass:"select-menu-overlay",class:{ active: _vm.isShow },on:{"click":_vm.onClickMenuOverlay}},[_c('div',{staticClass:"box_list_search_select active",on:{"click":function($event){$event.stopPropagation();}}},[(!_vm.isHideCloseBtn)?_c('div',{staticClass:"close-btn",on:{"click":function($event){_vm.isShow = false}}},[_c('img',{attrs:{"src":"/images/close-circle.svg","alt":""}})]):_vm._e(),(_vm.isSearchCountry)?_c('div',{staticClass:"search-country"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.searchCountry),expression:"searchCountry",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":_vm.$t('common.search')},domProps:{"value":(_vm.searchCountry)},on:{"input":function($event){if($event.target.composing)return;_vm.searchCountry=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e(),_c('ul',{staticClass:"list_search_select"},_vm._l((_vm.avaiableOptions),function(option,index){return _c('li',{key:index,class:{
              'selected-li': _vm.value === option[_vm.propertyOption],
              'note-li': option.id === 'maximum_bank',
              selected: index === _vm.indexSelected,
            },on:{"click":function($event){return _vm.select(option, index)}}},[(_vm.hasCustomLabel)?_vm._t("options",null,{"option":option}):(option.id === 'maximum_bank')?_c('span',{domProps:{"innerHTML":_vm._s(
                _vm.$t('bank_account_management.maximum_bank_note', {
                  limit: _vm.limitBankAccount,
                })
              )}}):_c('span',{staticClass:"full_name_coin_select",class:{ 'action-li': option.id === 'add_new_bank' }},[(option.id === 'add_new_bank')?_c('img',{staticStyle:{"margin-right":"8px","transform":"translateY(-1px)"},attrs:{"src":"/images/wallet/fi_plus-circle.svg","alt":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t( option.name ? option.name : `${option[_vm.propertyOption] .charAt(0) .toUpperCase()}${option[_vm.propertyOption].slice(1)}` ))+" "),(_vm.isSelectPhone)?[_vm._v(" +"+_vm._s(option.value)+" ")]:_vm._e()],2)],2)}),0)])]):_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticClass:"box_list_search_select active"},[(_vm.isSearchCountry)?_c('div',{staticClass:"search-country"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.searchCountry),expression:"searchCountry",modifiers:{"trim":true}}],attrs:{"type":"text","placeholder":_vm.$t('common.search')},domProps:{"value":(_vm.searchCountry)},on:{"input":function($event){if($event.target.composing)return;_vm.searchCountry=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e(),_c('ul',{staticClass:"list_search_select"},_vm._l((_vm.avaiableOptions),function(option,index){return _c('li',{key:index,class:{
            'selected-li': _vm.value === option[_vm.propertyOption],
            'note-li': option.id === 'maximum_bank',
          },attrs:{"title":_vm.titleItem ? option[_vm.titleItem] : ''},on:{"click":function($event){return _vm.select(option, index)}}},[(_vm.hasCustomLabel)?_vm._t("options",null,{"option":option}):(option.id === 'maximum_bank')?_c('span',{domProps:{"innerHTML":_vm._s(
              _vm.$t('bank_account_management.maximum_bank_note', {
                limit: _vm.limitBankAccount,
              })
            )}}):_c('span',{staticClass:"full_name_coin_select",class:{ 'action-li': option.id === 'add_new_bank' }},[(option.id === 'add_new_bank')?_c('img',{staticStyle:{"margin-right":"8px","transform":"translateY(-1px)"},attrs:{"src":"/images/wallet/fi_plus-circle.svg","alt":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t( option.name ? option.name : `${option[_vm.propertyOption] .charAt(0) .toUpperCase()}${option[_vm.propertyOption].slice(1)}` ))+" "),(_vm.isSelectPhone)?[_vm._v(" +"+_vm._s(option.value)+" ")]:_vm._e()],2)],2)}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }