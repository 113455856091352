<template>
  <div id="top_mid_table" class="top_mid_table">
    <div class="content_orderbook" :class="{ active: isShowOrderBook }">
      <div class="head_orderbook__mobile-header">
        <div class="head_orderbook__title">
          {{ $t('order.order_book.order_book') }}
        </div>
        <div class="head_orderbook__toggle-btn" @click="isShowOrderBook = !isShowOrderBook">
          <div class="toggle-switch" :class="isShowOrderBook ? 'on' : 'off'">
            <span class="toggle-handle"></span>
          </div>
        </div>
      </div>
      <div class="head_orderbook">
        <div class="group_sort_orderbook">
          <div class="list_decimals_od"></div>
          <ul class="list_sort_od">
            <li>
              <div v-on:click="tab = tabConfig.sell" class="icon_sort_od">
                <img src="/images/spot_exchange/order-book-ic-1.svg" alt="" />
              </div>
            </li>
            <li>
              <div v-on:click="tab = tabConfig.bothSellBuy" class="icon_sort_od">
                <img src="/images/spot_exchange/order-book-ic-2.svg" alt="" />
              </div>
            </li>
            <li>
              <div v-on:click="tab = tabConfig.buy" class="icon_sort_od">
                <img src="/images/spot_exchange/order-book-ic-3.svg" alt="" />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="body_orderbook">
        <div class="top-title">
          <ul class="title-group">
            <li class="od-price">
              {{ $t('order.order_book.price') }}&nbsp;({{ currency.toUpperCase() }})
            </li>
            <li class="od-amount">
              {{ $t('order.order_book.amount') }}&nbsp;({{ coin.toUpperCase() }})
            </li>
            <li class="od-total">
              {{ $t('order.order_book.total') }}&nbsp;({{ coin.toUpperCase() }})
            </li>
          </ul>
        </div>
        <!-- Begin SellOrderBook -->
        <div
          class="order-red-container"
          v-bind:class="{
            active_red_max: tab === tabConfig.sell,
            ' active_red_min': tab === tabConfig.buy,
          }"
        >
          <table id="orderbook_red">
            <tbody class="orderbook_red">
              <tr
                v-for="(item, index) in sellOrderBookDisplay"
                :key="index"
                @click.stop="onSellOrderBookClicked(item, index)"
                :class="{ 'row-clicked': item.isClickedRow }"
              >
                <div class="fix_sell_od" :style="{ width: item.percent + '%' }"></div>
                <td
                  class="sell_color"
                  :title="
                    item.price
                      ? Number(
                          parseFloat(item.price || 0).toFixed(isPairPrecisionIsFour ? 4 : 2)
                        ).toLocaleString('en', {
                          minimumFractionDigits: isPairPrecisionIsFour ? 4 : 2,
                        })
                      : '--'
                  "
                >
                  {{
                    item.price
                      ? Number(
                          parseFloat(item.price || 0).toFixed(isPairPrecisionIsFour ? 4 : 2)
                        ).toLocaleString('en', {
                          minimumFractionDigits: isPairPrecisionIsFour ? 4 : 2,
                        })
                      : '--'
                  }}
                </td>
                <td
                  class="padr10 p-right"
                  :title="
                    item.quantity
                      | toNumber
                      | formatCurrencyAmount(null, '--')
                      | paddingRight(coinSettingByPair.fractionPartQuantity, '00')
                  "
                >
                  {{
                    item.quantity
                      | formatCurrencyAmount(null, '--')
                      | paddingRight(coinSettingByPair.fractionPartQuantity, '00')
                  }}
                </td>
                <td
                  :title="
                    calculateTotalQuantityCoinForDisplay(sellOrderBookDisplay, item, index, 'sell')
                      | toNumber
                      | formatCurrencyAmount(null, '--')
                      | paddingRight(coinSettingByPair.fractionPartQuantity, '00')
                  "
                >
                  {{
                    calculateTotalQuantityCoinForDisplay(sellOrderBookDisplay, item, index, 'sell')
                      | toNumber
                      | formatCurrencyAmount(null, '--')
                      | paddingRight(coinSettingByPair.fractionPartQuantity, '00')
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <!-- End SellOrderBook -->
        </div>

        <!-- Begin Current price -->
        <div class="block_center_od">
          <div class="border_block_center_od" :class="{ sell_buy: tab === tabConfig.bothSellBuy }">
            <div class="od_number__wrapper">
              <span
                class="od_number_fix text-oneline"
                :title="$options.filters?.formatCurrencyAmount(latestPrice, 'idr', '00')"
                :class="{
                  'grown-price': lastChange > 0,
                  'down-price': lastChange < 0,
                }"
              >
                <i
                  class="icon_zhang"
                  size="12"
                  :class="{
                    'icon-arrow5': lastChange > 0,
                    'icon-arrow6': lastChange < 0,
                  }"
                ></i>
                <!-- <span> -->

                {{
                  latestPrice
                    ? Number(
                        parseFloat(latestPrice || 0).toFixed(isPairPrecisionIsFour ? 4 : 2)
                      ).toLocaleString('en', {
                        minimumFractionDigits: isPairPrecisionIsFour ? 4 : 2,
                      })
                    : '--'
                }}
                <!-- </span> -->
              </span>
              <!-- Hide Temporary -->
            </div>
            <img src="/images/icon/u_chart-line.svg" alt="" class="icon_analytics" />
          </div>
        </div>
        <!-- End Current price -->

        <!-- Begin BuyOrderBook -->
        <table
          id="orderbook_blue"
          v-bind:class="{
            ' active_blue_min': tab === tabConfig.sell,
            ' active_blue_max': tab === tabConfig.buy,
          }"
        >
          <tbody
            v-bind:class="{
              ' orderbook_blue_min': tab === 1,
              ' orderbook_blue_max': tab === 3,
            }"
            class="orderbook_blue"
          >
            <tr
              v-for="(item, index) in buyOrderBookDisplay"
              :key="index"
              @click.stop="onBuyOrderBookClicked(item, index)"
              :class="{ 'row-clicked': item.isClickedRow }"
              class="orderbook__row"
            >
              <div class="fix_back_od" :style="{ width: item.percent + '%' }"></div>
              <td
                class="sell_color"
                :title="
                  item.price
                    ? Number(
                        parseFloat(item.price || 0).toFixed(isPairPrecisionIsFour ? 4 : 2)
                      ).toLocaleString('en', {
                        minimumFractionDigits: isPairPrecisionIsFour ? 4 : 2,
                      })
                    : '--'
                "
              >
                {{
                  item.price
                    ? Number(
                        parseFloat(item.price || 0).toFixed(isPairPrecisionIsFour ? 4 : 2)
                      ).toLocaleString('en', {
                        minimumFractionDigits: isPairPrecisionIsFour ? 4 : 2,
                      })
                    : '--'
                }}
              </td>
              <td
                class="padr10 p-right"
                :title="
                  item.quantity
                    | toNumber
                    | formatCurrencyAmount(coin, '--')
                    | paddingRight(coinSettingByPair.fractionPartQuantity, '00')
                "
              >
                {{
                  item.quantity
                    | toNumber
                    | formatCurrencyAmount(null, '--')
                    | paddingRight(coinSettingByPair.fractionPartQuantity, '00')
                }}
              </td>
              <td
                :title="
                  calculateTotalQuantityCoinForDisplay(buyOrderBookDisplay, item, index, 'buy')
                    | toNumber
                    | formatCurrencyAmount(null, '--')
                    | paddingRight(coinSettingByPair.fractionPartQuantity, '00')
                "
              >
                <!-- {{ totalCurrency(item.price, item.quantity) | toNumber | formatCurrencyAmount(coin, '--') | paddingRight(coinSettingByPair.fractionPartAmount, '00') }} -->
                {{
                  calculateTotalQuantityCoinForDisplay(buyOrderBookDisplay, item, index, 'buy')
                    | toNumber
                    | formatCurrencyAmount(null, '--')
                    | paddingRight(coinSettingByPair.fractionPartQuantity, '00')
                }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- End BuyOrderBook -->
      </div>
    </div>
  </div>
</template>
<script>
import rf from '@/request/RequestFactory';
import Const from '@/common/Const';
import Utils from '@/common/Utils';
import BigNumber from 'bignumber.js';
import SelectBox from '@/components/shared_components/common/SelectBox';
import { PriceGroupsStatis } from '@/common/PriceGroups';

const SELL = 'sell';
const BUY = 'buy';
const BLANK = '--';
const TAB_SELL = 1;
const BOTH_SELL_BUY = 2;
const TAB_BUY = 3;

export default {
  components: {
    SelectBox,
  },
  props: ['latestPrices', 'responsePairCoinSettings'],
  data() {
    return {
      isShowOrderBook: true,
      coin: this.$route.query.coin || Const.DEFAULT_COIN,
      currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
      tab: BOTH_SELL_BUY,
      tabConfig: { sell: TAB_SELL, bothSellBuy: BOTH_SELL_BUY, buy: TAB_BUY },

      buyOrderBook: [],
      sellOrderBook: [],

      maxSizeDisp: 14,
      minSizeDisp: 14,

      prices: {},
      latestPrice: undefined,
      changedPrice: undefined, // Price changed compare with previous price.

      coinSettings: [],
      coinSettingByPair: {},

      priceGroups: [],
      priceGroupsByPair: [],
      selectedPriceGroup: {},
      disableByBetaTester: false,
      isAuthencation: window.isAuthenticated,
      ticketSize: undefined,
    };
  },
  watch: {
    selectedPriceGroup(newPriceGroup) {
      if (newPriceGroup) {
        this.tickerSize = newPriceGroup.value;
      }
      this.getOrderBook();
    },

    $route(to, from) {
      this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
      this.coin = this.$route.query.coin || Const.DEFAULT_COIN;

      this.onChangedPairCurrency();
    },

    maxSizeDisp(newValue) {
      this.fillRowBlankIfNeed(this.sellOrderBook, SELL);
      this.fillRowBlankIfNeed(this.buyOrderBook, BUY);
    },

    tab(newValue) {
      this.$nextTick(() => {
        this.reCalculateMinMaxRowDisp();
        this.scrollToEnd();
      });
    },
  },
  computed: {
    tradingPair() {
      return this.currency + '_' + this.coin;
    },

    tradingPairPrice: function () {
      return this.prices[this.tradingPair] || {};
    },

    lastChange() {
      return this.prices[this.tradingPair]?.price > this.prices[this.tradingPair]?.previous_price
        ? 1
        : this.prices[this.tradingPair]?.price < this.prices[this.tradingPair]?.previous_price
        ? -1
        : 0;
    },

    fiatCurrency() {
      const tradingPrice = this.tradingPairPrice || { price: 0 };
      const usdPrice = this.prices[`idr_${this.currency}`] || { price: 1 };
      return new BigNumber(`${tradingPrice.price || 0}`).mul(usdPrice.price || 0).toString();
    },

    sellOrderBookDisplay() {
      // Show all sellOrderBook
      if (this.tab === TAB_SELL) {
        return this.sellOrderBook;
      }
      let currentSellOrderBook = [];
      const length = this.sellOrderBook.length;
      if (!length) {
        this.$broadcast('SpotSellOrderBookDisplay', []);
        return [];
      }
      currentSellOrderBook = window._.slice(this.sellOrderBook, length - this.minSizeDisp, length);
      this.$broadcast('SpotSellOrderBookDisplay', currentSellOrderBook);
      return currentSellOrderBook;
    },

    buyOrderBookDisplay() {
      const length = this.buyOrderBook.length;
      if (this.tab === TAB_BUY || length < this.maxSizeDisp) {
        return this.buyOrderBook;
      }
      return window._.slice(this.buyOrderBook, 0, this.maxSizeDisp);
    },
    priceGroupsByPaiReverse() {
      return window._.reverse(this.priceGroupsByPair);
    },
    isPairPrecisionIsFour() {
      return (
        (this.coin === 'ada' && this.currency === 'usdt') ||
        (this.coin === 'xrp' && this.currency === 'usdt')
      );
    },
  },
  methods: {
    formatDecimal(num) {
      return Utils.scientificToDecimal(1 / Math.pow(10, num));
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
      };
    },

    onSpotSelectedPairMarket(pair) {
      this.coin = pair.coin;
      this.currency = pair.currency;
      // this.getOrderBook();
      this.getPriceGroupsByPairCurrency();
    },

    onSellOrderBookClicked(item, index) {
      if (!item || !item.price) {
        return;
      }
      this.clickedRow(item);

      const subListSellOrderBook = window._.slice(this.sellOrderBookDisplay, index);
      const [price, maxAmount] = this.getPriceAndAmountSubOrderBook(
        item.price,
        subListSellOrderBook
      );

      this.$broadcast('SellOrderBookClicked', price, maxAmount);
    },

    onBuyOrderBookClicked(item, index) {
      if (!item || !item.price) {
        return;
      }
      this.clickedRow(item);

      const subListBuyOrderBook = window._.slice(this.buyOrderBook, 0, index + 1);
      const [price, maxAmount] = this.getPriceAndAmountSubOrderBook(
        item.price,
        subListBuyOrderBook
      );

      this.$broadcast('BuyOrderBookClicked', price, maxAmount);
    },

    calculateTotalQuantityCoinForDisplay(inputList, item, index, type) {
      let list = window._.cloneDeep(inputList);
      if (item.price == undefined) {
        return undefined;
      }

      let subListOrderBook = window._.slice(list, index);
      if (type == 'buy') {
        subListOrderBook = window._.slice(list, 0, index + 1);
      }
      const [price, maxAmount] = this.getPriceAndAmountSubOrderBook(item.price, subListOrderBook);

      return maxAmount;
    },

    calculateTotalQuantityCoin(list, item, index, type) {
      if (item.price == undefined || item.isClickedRow == undefined) {
        return undefined;
      }

      let subListOrderBook = window._.slice(list, index);
      if (type == 'buy') {
        subListOrderBook = window._.slice(list, 0, index + 1);
      }
      const [price, maxAmount] = this.getPriceAndAmountSubOrderBook(item.price, subListOrderBook);

      return maxAmount;
    },

    getPriceAndAmountSubOrderBook(price, subOrderBook) {
      const maxAmount = window._.sumBy(subOrderBook, (row) => row.quantity);
      return [price, maxAmount];
    },

    clickedRow(row) {
      row.isClickedRow = true;
      setTimeout(() => {
        row.isClickedRow = false;
      }, 100);
    },

    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
        OrderBookUpdated: this.onReceiveOrderBook,
      };
    },

    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      if (window._.isEmpty(this.prices)) {
        return;
      }
      this.reCalcLatestPrice();
    },

    onReceiveOrderBook(data) {
      if (
        this.currency != data.currency ||
        this.coin != data.coin
        // || this.tickerSize != data.tickerSize
      ) {
        return;
      }
      if (data.isFullOrderBook) {
        this.rebuildOrderBook(data.orderBook);
      } else {
        this.buyOrderBook = window._.concat(this.buyOrderBook, data.orderBook.buy);
        this.sellOrderBook = window._.concat(this.sellOrderBook, data.orderBook.sell);

        this.onOrderBookUpdated();
      }
    },

    onChangedPrecisionClicked(priceGroup) {
      this.selectedPriceGroup = priceGroup;
    },

    onChangedPairCurrency() {
      // reset price when changed pair currency.
      this.latestPrice = 0;
      this.changedPrice = 0;

      this.getCoinSettingsByPairCurrency();
      this.getPriceGroupsByPairCurrency();
      this.reCalcLatestPrice();
      this.initBetaTesterData();
    },

    reCalcLatestPrice() {
      const coinPrice = this.prices[this.tradingPair];
      if (!coinPrice) {
        return;
      }
      if (this.latestPrice) {
        // calculate change price.
        this.changedPrice = new BigNumber(`${coinPrice.price}`)
          .sub(`${this.latestPrice}`)
          .toString();
      }
      // set latest price.
      this.latestPrice = coinPrice.price;
    },

    rebuildOrderBook(data) {
      if (this.disableByBetaTester) {
        data.buy = [];
        data.sell = [];
        this.buyOrderBook = [];
        this.sellOrderBook = [];
      }
      this.convertOrderBookDataType(data);
      const orderBook = this.transformOrderBook(data);

      this.buyOrderBook = window._.orderBy(data.buy, (row) => parseFloat(row.price), ['desc']);
      this.sellOrderBook = window._.orderBy(data.sell, (row) => parseFloat(row.price), ['desc']);

      this.fillRowBlankIfNeed(this.sellOrderBook, SELL);
      this.fillRowBlankIfNeed(this.buyOrderBook, BUY);

      this.onOrderBookUpdated();
    },

    onOrderBookUpdated() {
      if (
        window._.isEmpty(this.buyOrderBook) ||
        window._.isEmpty(this.sellOrderBook) ||
        !this.tickerSize
      ) {
        return;
      }

      // price of trade Buy is 0 (price after a round by priceGroup) => display random any number.
      this.reCalcBuyOrderBookIfNeed(this.buyOrderBook);

      this.calculateQuantityPercent();
    },

    reCalcBuyOrderBookIfNeed(data) {
      window._.map(data, (item) => {
        if (parseFloat(item.price) === 0) {
          item.price = new BigNumber(1)
            .div(`${Math.pow(10, this.selectedPriceGroup.precision)}`)
            .toString();
        }
        return item;
      });
    },

    calculateQuantityPercent() {
      const maxBuyRow = this.getMaxQuantity(this.buyOrderBook);
      const maxSellRow = this.getMaxQuantity(this.sellOrderBook);

      const maxBuyQuantity = maxBuyRow ? maxBuyRow.quantity : 0;
      const maxSellQuantity = maxSellRow ? maxSellRow.quantity : 0;
      const maxQuantity = Math.max(maxBuyQuantity, maxSellQuantity);

      this.calculatePercent(this.buyOrderBook, maxQuantity);
      this.calculatePercent(this.sellOrderBook, maxQuantity);
    },

    getMaxQuantity(data) {
      return window._.chain(data)
        .filter((item) => item.quantity !== BLANK)
        .map((item) => {
          item.quantity = parseFloat(item.quantity);
          return item;
        })
        .maxBy('quantity')
        .value();
    },

    calculatePercent(data, maxQuantity) {
      for (let i in data) {
        let row = data[i];
        if (maxQuantity) {
          row.percent = (row.quantity * 100) / maxQuantity;
        } else {
          row.percent = 0;
        }
      }
    },

    transformOrderBook(data) {
      let orderBook = {};
      orderBook.buy = window._.values(data.buy);
      orderBook.sell = window._.values(data.sell);
      return orderBook;
    },

    totalCurrency(price, amount) {
      if (price === BLANK || amount === BLANK) {
        return BLANK;
      }
      return new BigNumber(`${price || 0}`).mul(`${amount || 0}`).toFixed(10);
    },

    convertOrderBookDataType(orderBook) {
      this.convertStringToNumber(orderBook.buy);
      this.convertStringToNumber(orderBook.sell);
    },

    convertStringToNumber(data) {
      window._.each(data, (item) => {
        item.price = parseFloat(item.price);
        item.quantity = parseFloat(item.quantity);
        item.count = parseFloat(item.count);
        item.percent = 0;

        // Flag which show border when row is clicked.
        item.isClickedRow = false;
      });
    },

    fillRowBlankIfNeed(subOrderBook, tradeType) {
      // Remove row blank
      this.removeRowBlank(subOrderBook);
      if (subOrderBook.length > this.maxSizeDisp) {
        return;
      }
      const data = [];
      while (data.length < this.maxSizeDisp - subOrderBook.length) {
        const item = this.createRowSubOrderBook();
        data.push(item);
      }
      if (tradeType === SELL) {
        this.sellOrderBook = window._.concat(data, subOrderBook);
      } else {
        this.buyOrderBook = window._.concat(subOrderBook, data);
      }
    },

    removeRowBlank(data) {
      window._.remove(data, (item) => !item.price || !item.quantity);
    },

    createRowSubOrderBook() {
      return {
        price: undefined,
        quantity: 0,
        percent: 0,
      };
    },

    getOrderBook() {
      rf.getRequest('OrderRequest')
        .getOrderBook(this.getDefaultParams())
        .then((res) => {
          this.rebuildOrderBook(res.data);
        });
    },

    getPriceGroups() {
      rf.getRequest('MasterdataRequest')
        .getAll()
        .then((res) => {
          this.coinSettings = window._.map(res.coin_settings, (item) => {
            item.fractionPartQuantity = this.getDecimalPrecision(
              item.coin,
              item.quantity_precision
            );
            item.fractionPartAmount = 8;
            return item;
          });
          this.getCoinSettingsByPairCurrency();

          const data = res.price_groups.length > 0 ? res.price_groups.length : PriceGroupsStatis;

          this.priceGroups = window._.map(data, (item) => {
            item.precision = this.getDecimalPrecision(item.currency, item.value);
            return item;
          });
          this.getPriceGroupsByPairCurrency();
        });
    },

    getPrices() {
      rf.getRequest('PriceRequest')
        .getPrices()
        .then((res) => {
          this.onPricesUpdated(res.data);
        });
    },

    getDefaultParams() {
      return {
        currency: this.currency,
        coin: this.coin,
        tickerSize: this.isPairPrecisionIsFour ? '0.0001' : this.tickerSize,
      };
    },

    getPriceGroupsByPairCurrency() {
      if (window._.isEmpty(this.priceGroups)) {
        this.priceGroupsByPair = [];
        return;
      }
      const priceGroupFilter = window._.filter(this.priceGroups, (item) => {
        return item.currency === this.currency && item.coin === this.coin;
      });

      // Sort by precision asc
      this.priceGroupsByPair = window._.sortBy(priceGroupFilter, 'precision');

      let isSetPrecision = false;
      this.priceGroupsByPair.forEach((item) => {
        if (item.value == this.coinSettingByPair.price_precision) {
          this.selectedPriceGroup = item;
          isSetPrecision = true;
        }
      });
      if (!isSetPrecision) {
        this.selectedPriceGroup = window._.last(this.priceGroupsByPair);
      }
    },

    getCoinSettingsByPairCurrency() {
      if (window._.isEmpty(this.coinSettings)) {
        this.coinSettingByPair = {};
        return;
      }
      const result = window._.filter(this.coinSettings, (item) => {
        return item.currency === this.currency && item.coin === this.coin;
      });
      if (!window._.isEmpty(result)) {
        this.coinSettingByPair = result[0];
      }
    },

    getDecimalPrecision(currency, value) {
      return Math.round(Math.log(1 / value) / Math.log(10));
    },

    handleWindowResize() {
      this.reCalculateMinMaxRowDisp();
    },

    reCalculateMinMaxRowDisp() {
      const isMobile = window.matchMedia('(max-width: 1024px)').matches;
      if (!isMobile) {
        setTimeout(() => {
          let heightRow = 36;
          const $elSell = this.$el.querySelector('#orderbook_red');
          const $elBuy = this.$el.querySelector('#orderbook_blue');

          switch (this.tab) {
            case TAB_SELL:
              // heightRow = $elSell.querySelector("tbody > tr").firstChild.offsetHeight || heightRow;
              this.maxSizeDisp = Math.floor($elSell.offsetHeight / heightRow);
              break;
            case BOTH_SELL_BUY:
              if ($elSell.offsetHeight > $elBuy.offsetHeight) {
                this.maxSizeDisp = Math.floor($elSell.offsetHeight / heightRow);
                this.minSizeDisp = Math.floor($elBuy.offsetHeight / heightRow);
              } else {
                this.maxSizeDisp = Math.floor($elBuy.offsetHeight / heightRow);

                this.minSizeDisp = Math.floor($elSell.offsetHeight / heightRow);
              }
              break;
            case TAB_BUY:
              // heightRow = $elBuy.querySelector("tbody > tr").firstChild.offsetHeight || heightRow;
              this.maxSizeDisp = Math.floor($elBuy.offsetHeight / heightRow);
              break;
            default:
              console.error('Tab selection incorrect');
              break;
          }
        }, 201); // with trasition: 0.2s
      }
    },

    scrollToEnd() {
      let container = this.$el.querySelector('.orderbook_red');
      container.scrollTop = container.scrollHeight;
    },

    initData() {
      this.getPriceGroups();
      this.getPrices();
    },

    initBetaTesterData() {
      this.disableByBetaTester = false;
      let coin = this.$route.query.coin || Const.DEFAULT_COIN;
      let currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;

      rf.getRequest('SettingRequest')
        .getPairCoinSettings({ coin, currency })
        .then((res) => {
          if (!res && !res.data) {
            return;
          }
          const setting = res.data;
          if (!setting.is_enable && setting.is_show_beta_tester) {
            this.disableByBetaTester = true;

            if (window.isAuthenticated) {
              rf.getRequest('UserRequest')
                .getUserPairTradingSetting({ coin, currency })
                .then((res) => {
                  if (res && res.data && res.data.enable_trading == Const.ENABLE_TRADING.ENABLE) {
                    this.disableByBetaTester = false;
                  }
                  this.getOrderBook();
                });
            } else {
              this.getOrderBook();
            }
          } else {
            this.getOrderBook();
          }
        });
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
    this.reCalculateMinMaxRowDisp();
    this.initData();
    this.initBetaTesterData();
  },
};
</script>

<style lang="scss">
#top_mid_table {
  .list_decimals_od {
    .button_drop_search {
      background: transparent;
      padding: 3px 0 !important;
      border: transparent;
      justify-content: center;
      span {
        font-size: 12px;
      }
      i.icon-arrow1 {
        margin-left: 5px;
        color: $color-grey !important;
      }
      i.icon_arrow_sl {
        color: $color-grey !important;
      }

      @include mobile {
        border: unset;
        padding: 3px 0 !important;

        span {
          font-size: 14px !important;
          color: $text-main !important;
          max-width: 90%;
          line-height: 24px;
          margin-right: 5px;
        }
      }
    }
    .full_name_coin_select {
      font-size: 12px;
    }
  }
}
</style>

<style lang="scss" scoped>
$heightOptionsHeader: 50px;
$heightHeaderTitle: 44px;
$heightBlockCenter: 32px;
$transition: all 0.2s;
.toggle-switch {
  cursor: pointer;
  width: 48px;
  height: 24px;
  background: $bg-main1;
  border-radius: 14px;
  .toggle-handle {
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #fff;
    border-radius: 50%;
    margin: 4px;
    transition: all 0.3s ease;
  }
  &.off {
    background: $text-main-blur;
    .toggle-handle {
      margin-left: 4px;
      opacity: 1;
    }
  }
  &.on {
    background: $bg-main1;
    .toggle-handle {
      margin-left: 28px;
      opacity: 1;
    }
  }
}
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 500px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
#top_mid_table {
  display: block;
  width: 100%;
  ul {
    list-style-type: none;
    margin: 0px;
  }
  .content_orderbook {
    .head_orderbook__mobile-header {
      display: none;
    }
    .head_orderbook {
      border-top: 1px solid $grey-1;
      background: $bg-main4;
      border-color: $grey-1;
      width: 100%;
      height: 56px;
      padding: 0 12px;
      display: flex;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      .group_sort_orderbook {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .list_sort_od {
          display: flex;
          align-items: center;
          gap: 16px;
          flex-wrap: wrap;
          list-style-type: none;
          li {
            float: left;
            .icon_sort_od {
              display: inline-block;
              width: 20px;
              height: 20px;
              cursor: pointer;
              text-align: center;
              line-height: 20px;
              border: 1px solid;
              border-color: transparent;
              .icon_up_sort {
                color: $secondary-red;
              }
            }
            .icon_sort_od.active {
              border-color: $grey-2;
            }
          }
        }
        .select_decimals_od {
          background: #efefef;
          font-family: Arial;
          font-size: $font-small;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $color-grey-dusty;
          height: 22px;
          padding: 0px 5px;
          border-color: $color-grey-border;
        }
        .list_decimals_od {
          float: left;
          position: relative;
          :deep .box_list_search_select .full_name_coin_select {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
          :deep .button_drop_search span {
            font-size: 14px;
            font-weight: 300;
            line-height: 22px;
            margin-right: 5px;
          }
          :deep .button_drop_search img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .border-first-line {
        padding: 0;
      }

      @include tablet {
        border-top: unset;
      }
    }

    .body_orderbook {
      $heightFullOrderBook: 380px;
      border-top: 1px solid $bg-main2;
      width: 100%;
      background: $bg-main4;
      .orderbook_red {
        visibility: visible;
        height: 180px;
        transition: 0.1s;
        overflow: hidden;
        tr {
          td {
            &:nth-child(2) {
              color: $color-red;
            }
            &:nth-child(3) {
              padding-left: 2px;
              padding-right: 2px;
            }
          }
        }
      }
      .orderbook_red.orderbook_red_min {
        visibility: hidden;
        transition: 0.1s;
        height: 0px;
        padding-top: 0px;
        margin-top: -1px;
      }
      .orderbook_red.orderbook_red_max {
        visibility: visible;
        transition: 0.1s;
        height: $heightFullOrderBook;
        &::-webkit-scrollbar {
          width: 0px;
        }
      }
      .orderbook_blue {
        visibility: visible;
        transition: 0.1s;
        height: 210px;
        overflow: hidden;
        tr {
          position: relative;
          td {
            position: relative;
            z-index: 10;
            &:nth-child(2) {
              color: $color-green;
            }
          }
        }
      }
      .orderbook_blue.orderbook_blue_min {
        visibility: hidden;
        transition: 0.1s;
        height: 0px;
      }
      .orderbook_blue.orderbook_blue_max {
        visibility: visible;
        transition: 0.1s;
        height: $heightFullOrderBook;
        overflow-y: auto !important;
        &::-webkit-scrollbar {
          width: 0px;
        }
      }
      table {
        width: 100%;
        display: block;
        line-height: 20px;
        transition: all 0.3s;
        tbody {
          display: block;
          width: 100%;
          padding-top: 6px;
          tr {
            position: relative;
            padding: 0px 15px;
            overflow: hidden;
            display: block;
            width: 100%;
            cursor: pointer;
            td {
              position: relative;
              z-index: 0;
              width: 31%;
              text-overflow: ellipsis;
              overflow: hidden;
              display: block;
              float: left;
              text-align: center;
              font-size: 14px;
              color: $text-main;
              font-weight: 300;
              width: 33.33%;
              padding: 6px 0px;
              line-height: 24px;
              letter-spacing: 0.2px;

              @include mobile {
                font-size: 14px;
                line-height: 22px;
                height: auto;
                padding: 2.5px 0;
              }
            }
            td:nth-child(3),
            td:nth-child(2) {
              text-align: left;
            }
            td:nth-child(4) {
              // width: 38% !important;
              text-align: right;
            }
            .fix_back_od {
              position: absolute;
              right: 0;
              top: 0;
              height: 100%;
              background-color: rgba($color-green, 0.1);
              transition: all 0.3s ease-in-out;
              @include tablet;
              @include mobile {
                top: 50%;
                transform: translateY(-50%);
                height: 22px;
              }
            }
            .fix_sell_od {
              position: absolute;
              right: 0;
              top: 0;
              height: 100%;
              background-color: rgba($color-red, 0.1);
              transition: all 0.3s ease-in-out;
              @include tablet;
              @include mobile {
                top: 50%;
                transform: translateY(-50%);
                height: 22px;
              }
            }

            @include mobile {
              padding: 0;
            }
          }
          .row-clicked {
            border: 0px solid #459cd4;
          }
        }
      }
      .block_center_od {
        background: $bg-main4;
        display: block;
        overflow: hidden;
        position: relative;
        z-index: 3;
        .border_block_center_od {
          padding: 7px 15px;
          background-color: $bg-main3;
          display: flex;
          &.sell_buy {
            margin-top: 18px;
            margin-bottom: 18px;
          }
          justify-content: space-between;
          // height: $heightBlockCenter;
          position: relative;
          .od_number__wrapper {
            display: flex;
            gap: 10px;
            padding-right: 8px;
          }
          .od_number_fix {
            color: $text-main;
            display: inline-flex;
            align-items: center;
            margin-top: auto;
            margin-bottom: auto;
            font-size: 12px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0.5px;
            float: left;
            &.grown-price {
              font-weight: 700;
              color: $color-green;
            }
            &.down-price {
              font-weight: 700;
              color: $color-red;
            }
            .icon_zhang {
              height: 19px;
              line-height: 19px;
              margin-right: 2px;
              display: inline;
              float: left;
              width: 15px;
              font-size: $font-small;
              margin-top: 0px;
              overflow: hidden;
            }
            .icon_zhang:before {
              display: block;
            }
            .icon-arrow5 {
              color: $color-green;
            }
            .icon-arrow6 {
              color: $color-red;
            }

            @include mobile {
              font-size: 12px;
              line-height: 16px;
            }
          }
          .od_number_balance {
            display: inline;
            float: left;
            font-size: 12px;
            line-height: 16px;
            margin-top: auto;
            margin-bottom: auto;
            color: $text-main;
            font-weight: 300;
          }
          .icon_analytics {
            width: 24px;
            height: 24px;
            color: $color-green;
          }

          @include mobile {
            height: auto;
            // padding: 13px 0;
            padding: 0;
            background-color: unset;
            display: flex;
            align-items: center;

            .od_number_fix {
              height: fit-content;
              display: flex;
              align-items: center;
            }

            .icon_zhang {
              display: none !important;
            }

            .icon_analytics {
              width: 18px;
              height: 18px;
            }
          }
        }

        @include mobile {
          background: unset;
          border-bottom: 1px solid $grey-1;
          margin-bottom: 13px;
        }
      }
      .border-first-line {
        div {
          padding: 0 !important;
          border: none !important;
          border-top: 1px solid $grey-2 !important;
        }
      }
    }

    @include mobile {
      .head_orderbook {
        border: unset;
        padding: 0;
      }
      .head_orderbook__mobile-header {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

#top_mid_table {
  height: 100%;
  .content_orderbook {
    height: 100%;
    .body_orderbook {
      height: calc(100% - 56px);
      &::-webkit-scrollbar {
        width: 0px;
      }
      .top-title {
        background: transparent;
        position: relative;
        z-index: 0;
        height: 16px;
        margin: 11px 15px 11px;
        .title-group {
          display: inline-block;
          width: 100%;
          li {
            width: 33.33%;
            display: block;
            float: left;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            color: $text-secondary;
            &:nth-child(1) {
              text-align: left;
            }
            &:nth-child(3) {
              text-align: right;
            }
          }
        }

        @include mobile {
          padding: 12px 0 9px;

          .od-price,
          .od-amount,
          .od-total {
            font-size: 12px !important;
            line-height: 16px !important;
          }
        }
      }
      .order-red-container {
        transition: $transition;
        height: calc(50% - 64px);
        position: relative;
        overflow: hidden;
        &.active_red_min {
          height: 0px;
        }
        &.active_red_max {
          transition: $transition;
          height: calc(100% - #{$heightHeaderTitle} - #{$heightBlockCenter});
          overflow: auto;
          &::-webkit-scrollbar {
            width: 0px;
          }
        }
      }
      #orderbook_red {
        width: 100%;
        // min-height: 100%;
        height: 100%;
        transition: $transition;
        tbody {
          tr {
            border-top: 1px solid transparent;
            &:last-child {
              border-bottom: 1px solid transparent;
            }

            @include mobile {
              padding: 0;
            }
          }
        }
        .orderbook_red {
          transition: $transition;
          height: 100%;
          overflow-y: auto;
          padding-top: 0px;
        }
      }
      #orderbook_blue {
        height: calc(50% - 64px);
        transition: $transition;
        overflow: hidden;
        tbody {
          tr {
            border-top: 1px solid transparent;
            &:last-child {
              border-bottom: 1px solid transparent;
            }
          }
        }
        .orderbook_blue {
          transition: $transition;
          height: 100%;
          padding-top: 0;
        }
        &.active_blue_max {
          height: calc(100% - #{$heightHeaderTitle} - #{$heightBlockCenter});
          transition: $transition;
          margin-top: 18px;
          @include mobile {
            margin-top: unset;
          }
          overflow: auto;
        }
        &.active_blue_min {
          height: 0px;
          transition: $transition;
        }
      }
      .p-right {
        text-align: right;
      }
    }
  }
}
.text-oneline {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@include tablet;
@include mobile {
  #top_mid_table .content_orderbook {
    background-color: $color-white;
    padding: 24px 16px 0;
    & .head_orderbook,
    & .body_orderbook {
      display: none;
    }
    &.active {
      & .head_orderbook {
        display: flex;
      }
      & .body_orderbook {
        display: block;
      }
    }
    .head_orderbook__mobile-header {
      color: $text-main;
      .head_orderbook__title {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
    }
    .head_orderbook {
      background-color: $color-white;
      height: unset;
      .group_sort_orderbook {
        padding: 16px 0 13px;
        .list_sort_od {
          gap: 22px;
          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
    .body_orderbook {
      background-color: $color-white;
    }
  }
}
</style>
