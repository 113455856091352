<template>
  <header>
    <div class="container-header">
      <div :class="$route.name === 'Spot Exchange' ? 'wrapper full-width' : 'wrapper'">
        <div class="side-left">
          <div class="logo" @click="handleHome">
            <img src="/images/nvx/nvx-banner-2.png" alt="logo" />
          </div>
          <nav v-if="$route.path !== '/'">
            <div
              v-for="item in navItems"
              :key="item.key"
              class="nav-item"
              :class="{
                invert: $route.path === '/',
                active: parentActive === item.key,
              }"
              @click="
                dropdownActive === item.key
                  ? (dropdownActive = undefined)
                  : (dropdownActive = item.key)
              "
              @mouseenter="dropdownActive = item.key"
              @mouseleave="dropdownActive = undefined"
            >
              <template v-if="item.href">
                <router-link :to="item.href">{{ $t(item.name) }}</router-link>
              </template>
              <template v-else-if="item.key === 'wallet' && !accessToken">
                <router-link to="/login">{{ $t(item.name) }}</router-link>
              </template>
              <template v-else>
                <span>{{ $t(item.name) }}</span>
                <img
                  v-if="item.children"
                  :class="{
                    invert: $route.path === '/',
                    rotate: dropdownActive === item.key,
                  }"
                  src="/images/down-arrow.svg"
                  alt="down-white.png"
                />
                <ul
                  :class="dropdownActive === item.key ? 'nav-dropdown active' : 'nav-dropdown'"
                  :id="item.key"
                  v-if="item.children"
                >
                  <li
                    v-for="child in item.children.filter((i) =>
                      i.key !== 'slippery-note' ? i : accessToken && i
                    )"
                    :key="child.key"
                    @click.stop="activeNav(child.href, child.isEnable, item.key)"
                    :class="{
                      active:
                        $route.path === child.href ||
                        (child.routePath && $route.path.search(child.routePath) !== -1),
                      disabled: child.isEnable === false,
                    }"
                    :id="child.key"
                  >
                    <div class="main-name">
                      <span class="icon">
                        <img :src="child.icon" :alt="child.icon" />
                      </span>
                      <span>
                        {{ $t(child.name) }}
                      </span>
                    </div>
                    <div class="note" v-if="child.isEnable === false">
                      {{ child.note }}
                    </div>
                  </li>
                </ul>
              </template>
            </div>
          </nav>
        </div>
        <div class="side-middle">
          <nav v-if="$route.path == '/'">
            <div
              v-for="item in navItems"
              :key="item.key"
              class="nav-item"
              :class="{
                invert: $route.path === '/',
                active: parentActive === item.key,
              }"
              @click="
                dropdownActive === item.key
                  ? (dropdownActive = undefined)
                  : (dropdownActive = item.key)
              "
              @mouseenter="dropdownActive = item.key"
              @mouseleave="dropdownActive = undefined"
            >
              <template v-if="item.href">
                <router-link :to="item.href">{{ $t(item.name) }}</router-link>
              </template>
              <template v-else-if="item.key === 'wallet' && !accessToken">
                <router-link to="/login">{{ $t(item.name) }}</router-link>
              </template>
              <template v-else>
                <span>{{ $t(item.name) }}</span>
                <img
                  v-if="item.children"
                  :class="{
                    invert: $route.path === '/',
                    rotate: dropdownActive === item.key,
                  }"
                  src="/images/down-arrow.svg"
                  alt="down-white.png"
                />
                <ul
                  :class="dropdownActive === item.key ? 'nav-dropdown active' : 'nav-dropdown'"
                  :id="item.key"
                  v-if="item.children"
                >
                  <li
                    v-for="child in item.children.filter((i) =>
                      i.key !== 'slippery-note' ? i : accessToken && i
                    )"
                    :key="child.key"
                    @click.stop="activeNav(child.href, child.isEnable, item.key)"
                    :class="{
                      active: $route.path === child.href,
                      disabled: child.isEnable === false,
                    }"
                    :id="child.key"
                  >
                    <div class="main-name">
                      <span class="icon">
                        <img :src="child.icon" :alt="child.icon" />
                      </span>
                      <span>
                        {{ $t(child.name) }}
                      </span>
                    </div>
                    <div class="note" v-if="!child.isEnable">
                      {{ child.note }}
                    </div>
                  </li>
                </ul>
              </template>
            </div>
          </nav>
        </div>
        <div class="side-right">
          <NotificationModal v-if="accessToken" :accessToken="accessToken" />
          <div
            class="account"
            @click="handleShowAccountDropdown()"
            @mouseenter="showAccountDropdown = true"
            @mouseleave="showAccountDropdown = false"
          >
            <template v-if="accessToken">
              <img src="@/assets/images/default-user.png" alt="default-user.png" />
              <ul class="account-dropdown" :class="{ active: showAccountDropdown }">
                <li
                  v-for="item in navAccount"
                  :key="item.key"
                  @click.stop="handleNavAccount(item.key)"
                >
                  <div :class="[item.key == 'logout' ? 'logout' : 'title']">
                    <img :src="item.icon" alt="icon" />
                    <span>{{ $t(item.title) }}</span>
                  </div>
                  <div v-if="item.key === 'account'" class="sub-title" :title="account">
                    {{ account }}
                  </div>
                  <div v-if="item.key === 'balance'" class="sub-title">
                    {{ totalBalance.toFixed(2) | formatCurrencyAmount('usdt', '0') }}
                    USDT
                  </div>
                </li>
              </ul>
            </template>
            <template v-else>
              <div class="container-no-account">
                <div class="login">
                  <router-link @click="toLogin">{{ $t('menu.signin') }}</router-link>
                </div>
                <!-- <img src="@/assets/images/user.png" alt="user.png" /> -->
                <div class="register">
                  <router-link :to="{ path: '/register', query: $route.query }">{{
                    $t('menu.get_started')
                  }}</router-link>
                </div>
              </div>
            </template>
          </div>
          <div
            class="switch-lang-wrap"
            @click="handleShowLangDropdown"
            @mouseenter="showLangDropdown = true"
            @mouseleave="showLangDropdown = false"
          >
            <div class="switch-lang" :class="{ invert: $route.path === '/' }">
              <span class="current-lang">{{ curentLang }}</span>
              <img
                :class="{
                  invert: $route.path === '/',
                  rotate: showLangDropdown,
                }"
                src="/images/down-arrow.svg"
                alt="down-white.png"
              />
            </div>
            <ul :class="showLangDropdown ? 'lang-dropdown active' : 'lang-dropdown'">
              <li
                v-for="lang in langs"
                :key="lang.key"
                @click.stop="changeLang(lang.key)"
                :class="lang.key === curentLang ? 'active' : ''"
              >
                <img :src="lang.flag" :alt="lang.key" />
                <span>{{ $t(lang.name) }}</span>
              </li>
            </ul>
          </div>
          <div
            class="sub-menu"
            @click="handleShowSubMenu"
            @mouseenter="showSubMenu = true"
            @mouseleave="showSubMenu = false"
            v-if="
              $route.path.search('/spot-exchange') !== -1 || $route.path.search('convert') !== -1
            "
          >
            <img
              src="/images/submenu.svg"
              alt="sub-menu"
              :class="{ invert: $route.path === '/' }"
            />
            <ul :class="showSubMenu ? 'submenu-dropdown active' : 'submenu-dropdown'">
              <template v-if="$route.path.search('/spot-exchange') !== -1">
                <li
                  v-for="item in spotExSubMenu"
                  :key="item.name"
                  @click.stop="handleSubMenu(item.href)"
                  v-show="!item.requireAuth || (accessToken && item.requireAuth)"
                  :class="{
                    active: $route.path === item.href,
                  }"
                >
                  {{ $t(item.name) }}
                </li>
              </template>
              <template v-else-if="$route.path.search('convert') !== -1">
                <li
                  v-for="item in convertSubMenu"
                  :key="item.name"
                  @click.stop="handleSubMenu(item.href)"
                  v-show="!item.requireAuth || (accessToken && item.requireAuth)"
                  :class="{
                    active: $route.path === item.href,
                  }"
                >
                  {{ $t(item.name) }}
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <div class="menu-mobile" :class="{ invert: $route.path === '/' }">
        <div class="side-left">
          <div class="main-menu-homapage">
            <div
              class="toggle-submenu"
              v-if="
                $route.path.search('/spot-exchange') !== -1 || $route.path.search('convert') !== -1
              "
            >
              <span @click="toggleSubMenu">
                <img
                  v-if="showSubMenuMobile"
                  src="@/assets/images/icon/ci_close-big.png"
                  alt="toggle-menu-close"
                  :class="{ invert: $route.path === '/' }"
                />
                <img
                  v-else
                  src="@/assets/images/icon/ci_menu-alt.png"
                  alt="toggle-menu"
                  :class="{ invert: $route.path === '/' }"
                />
              </span>
              <div class="main-menu-left" :class="{ active: showSubMenuMobile }">
                <div class="overlay" @click="clickOutSubMenu"></div>
                <div class="menu-left-container">
                  <ul>
                    <template v-if="$route.path.search('/spot-exchange') !== -1">
                      <li
                        v-for="item in spotExSubMenu"
                        :key="item.name"
                        class="menu-left-item"
                        v-show="!item.requireAuth || (accessToken && item.requireAuth)"
                      >
                        <div class="name-menu">
                          <router-link :to="item.href" class="text-menu">{{
                            $t(item.name)
                          }}</router-link>
                        </div>
                      </li>
                    </template>
                    <template v-else-if="$route.path.search('convert') !== -1">
                      <li
                        v-for="item in convertSubMenu"
                        :key="item.name"
                        v-show="!item.requireAuth || (accessToken && item.requireAuth)"
                      >
                        <div class="name-menu">
                          <router-link :to="item.href" class="text-menu">{{
                            $t(item.name)
                          }}</router-link>
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="logo">
            <router-link to="/">
              <img src="/images/nvx/nvx-banner-2.png" alt="logo" height="40" />
            </router-link>
          </div>
        </div>
        <div class="side-right">
          <NotificationModal v-if="accessToken" :accessToken="accessToken" />
          <div
            class="account"
            @click="handleShowAccountDropdown()"
            @mouseleave="showAccountDropdown = false"
          >
            <template v-if="accessToken">
              <img src="@/assets/images/default-user.png" alt="default-user.png" />
              <ul class="account-dropdown" :class="{ active: showAccountDropdown }">
                <li
                  v-for="item in navAccount"
                  :key="item.key"
                  @click.stop="handleNavAccount(item.key)"
                >
                  <div class="title">
                    <img :src="item.icon" alt="icon" />
                    <span>{{ $t(item.title) }}</span>
                  </div>
                  <div v-if="item.key === 'account'" class="sub-title">
                    {{ account }}
                  </div>
                  <div v-if="item.key === 'balance'" class="sub-title">
                    {{ totalBalance.toFixed(2) | formatCurrencyAmount('usdt', '0') }}
                    USDT
                  </div>
                </li>
              </ul>
            </template>
            <template v-else>
              <!-- .container-no-account -->
              <div class="register-mobile">
                <button @click="toRegister">
                  {{ $t('menu.get_started') }}
                </button>
              </div>
            </template>
          </div>
          <div class="main-menu-homapage">
            <span class="toggle" @click="toggleMainMenuRight">
              <img
                v-if="showMainMenuRight"
                src="@/assets/images/icon/ci_close-big.png"
                alt="toggle-menu-close"
              />
              <img
                v-else
                src="@/assets/images/icon/ci_menu-alt.png"
                alt="toggle-menu"
                class="right-icon"
              />
            </span>
            <div class="main-menu-right" :class="{ active: showMainMenuRight }">
              <div class="overlay" @click="clickOutMenu"></div>
              <div class="menu-right-container">
                <div>
                  <div
                    v-for="item in mainmenuRight"
                    :key="item.key"
                    class="menu-right-item"
                    :class="{ active: menuMobileItemActive === item.key }"
                  >
                    <div class="name-menu" v-if="item.key === 'wallet' && !accessToken">
                      <div @click="toLogin" class="text-menu-main">
                        {{ $t(item.name) }}
                      </div>
                    </div>
                    <div v-else class="name-menu" @click="clickMenuItem(item.key)">
                      <span class="text-menu-main">{{ $t(item.name) }}</span>
                      <img class="down" src="/images/spot_exchange/icon-dropdown.svg" alt="icon" />
                    </div>
                    <ul class="list-menu">
                      <li
                        v-for="subItem in item.children.filter((i) =>
                          i.key !== 'slippery-note' ? i : accessToken && i
                        )"
                        :key="subItem.href"
                        class="name-submenu"
                        :class="{ disabled: subItem.isEnable === false }"
                      >
                        <img :src="subItem.icon" :alt="subItem.icon" />
                        <router-link :to="subItem.href" class="text-menu">{{
                          $t(subItem.name)
                        }}</router-link>
                        <div class="note" v-if="subItem.isEnable === false">
                          {{ subItem.note }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="menu-right-footer">
                  <div class="signin-mobile" v-if="!accessToken">
                    <button @click="toLogin">{{ $t('menu.signin') }}</button>
                  </div>
                  <div class="language-mobile">
                    <SelectBox
                      :options="langs"
                      :propertyOption="'key'"
                      v-model="curentLang"
                      :placeholder="$t(`menu.lang.${curentLang}`)"
                      :isShowImageChooseLanguage="true"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import AuthenticationUtils from '@/common/AuthenticationUtils';
import Const from '@/common/Const';
import rf from '@/request/RequestFactory';
import { mapState } from 'vuex';
import NotificationModal from '@/components/shared_components/common/NotificationModal.vue';
import SelectBox from '@/components/shared_components/common/SelectBox';
export default {
  name: 'PageHeader',
  components: { NotificationModal, SelectBox },
  props: {
    account: {
      type: String,
      default: '',
    },
    totalBalance: 0,
  },
  data() {
    return {
      windowWidth: window.screen.width,
      accessToken: localStorage.getItem('access_token'),
      dropdownActive: undefined,
      navItems: [
        {
          key: 'trade',
          name: 'menu.trade',
          children: [
            {
              key: 'spot-exchange',
              name: 'common.spot_exchange',
              routePath: '/spot-exchange/basic',
              href:
                '/spot-exchange/basic' +
                `?coin=${Const.DEFAULT_COIN}` +
                `&currency=${Const.DEFAULT_CURRENCY}`,
              icon: '/images/nvx/header/spot-exchange.svg',
              isEnable: true,
            },
            // {
            //   key: 'instant-convert',
            //   name: 'menu.instant_convert',
            //   href: '/instant-convert',
            //   icon: '/images/nvx/header/instant-convert.svg',
            //   isEnable: true,
            //   note: 'The program is currently unavailable',
            // },
            {
              key: 'slippery-note',
              name: 'menu.slippery_note',
              href: '/slippery-note',
              icon: '/images/nvx/header/slippery-note.svg',
              isEnable: true,
            },
          ],
        },
        {
          key: 'wallet',
          name: 'menu.wallet',
          children: [
            {
              key: 'balances',
              name: 'menu.balances',
              href: '/funds/balances',
              icon: '/images/nvx/header/balances.svg',
            },
            {
              key: 'deposit-usd',
              name: 'menu.deposit_usd',
              href: '/funds/deposit-idr',
              icon: '/images/nvx/header/deposit-idr.svg',
            },
            {
              key: 'deposits',
              name: 'menu.deposits',
              href: '/funds/deposits',
              icon: '/images/nvx/header/deposit.svg',
            },
            {
              key: 'withdrawal-usd',
              name: 'menu.withdraw_usd',
              href: '/funds/withdraw-idr',
              icon: '/images/nvx/header/withdrawal-idr.svg',
            },
            {
              key: 'withdrawals',
              name: 'menu.withdrawals',
              href: '/funds/withdrawals',
              icon: '/images/nvx/header/withdrawals.svg',
            },
            {
              key: 'transaction-history',
              name: 'menu.transaction_history',
              href: '/funds/history',
              icon: '/images/nvx/header/transaction-history.svg',
            },
          ],
        },
        // {
        //   key: 'news',
        //   name: 'menu.news',
        //   href: '#',
        // },
      ],
      mainmenuRight: [],
      showSubMenu: false,
      spotExSubMenu: [
        {
          name: 'menu.open_orders',
          href: '/spot-exchange/orders/open',
          requireAuth: false,
        },
        {
          name: 'menu.order_history',
          href: '/spot-exchange/orders/history',
          requireAuth: false,
        },
        {
          name: 'menu.trade_history',
          href: '/spot-exchange/orders/trade-history',
          requireAuth: false,
        },
      ],
      convertSubMenu: [
        {
          name: 'menu.convert_history',
          href: '/convert-history',
          requireAuth: false,
        },
      ],
      curentLang: localStorage.getItem('locale') || 'en',
      showLangDropdown: false,
      langs: [
        {
          id: this.$t('menu.lang.en'),
          key: 'en',
          name: this.$t('menu.lang.en'),
          flag: '/images/flags/us.png',
        },
        {
          id: this.$t('menu.lang.id'),
          key: 'id',
          name: this.$t('menu.lang.id'),
          flag: '/images/flags/id.png',
        },
      ],
      showAccountDropdown: false,
      navAccount: [
        {
          key: 'account',
          icon: '/images/icon/user.png',
          title: 'menu.account',
        },
        {
          key: 'balance',
          icon: '/images/icon/coins.png',
          title: 'menu.estimated_value',
        },
        {
          key: 'logout',
          icon: '/images/icon/round-logout.png',
          title: 'menu.logout',
        },
      ],
      showMainMenu: false,
      parentActive: undefined,
      showSubMenuMobile: false,
      showMainMenuRight: false,
      showNotificationList: false,
      menuMobileItemActive: '',
    };
  },
  created() {
    rf.getRequest('InstantConvert')
      .checkConvertStatus()
      .then((res) => {
        this.navItems[0].children[1].isEnable = res.data.enable ? true : false;
      });
    this.mainmenuRight = this.navItems;
  },
  computed: {
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === 'storage_url').value;
    },
    ...mapState({
      notificationList: (state) => state.notification.filter((item) => item.status),
    }),
  },
  watch: {
    'notificationList.length'(val) {
      if (val > 0) {
        this.showNotificationList = true;
      } else {
        this.showNotificationList = false;
      }
    },
    '$route.path'(val) {
      this.mainmenuRight = this.navItems;
      this.showAccountDropdown = false;
      this.showMainMenu = false;
      this.showSubMenuMobile = false;
      this.showSubMenu = false;
      this.showMainMenuRight = false;
      document.body.classList.remove('overlay');
    },
    curentLang(newVal) {
      localStorage.setItem('locale', newVal);
      window.location.reload();
    },
  },
  methods: {
    splitAmount(amount, currency) {
      let amtStr = amount.toString() || '';
      const decimal =
        currency.toLowerCase() === 'idr' ||
        currency.toLowerCase() === 'usdt' ||
        currency.toLowerCase() === 'busd'
          ? COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS_FIAT
          : COMMON_CONST.NUMBER_OF_DECIMAL_DIGITS;
      if (amtStr.split('.').length === 1) {
        return amtStr;
      } else {
        return amtStr.split('.')[0] + '.' + amtStr.split('.')[1].slice(0, decimal);
      }
    },
    toLogin() {
      this.$router.push({ path: '/login', query: this.$route.query });
      document.body.classList.remove('overlay');
    },
    toRegister() {
      this.$router.push({ path: '/register', query: this.$route.query });
      document.body.classList.remove('overlay');
    },
    clickMenuItem(item) {
      this.menuMobileItemActive = this.menuMobileItemActive === item ? '' : item;
    },
    clickOutMenu() {
      this.showMainMenuRight = !this.showMainMenuRight;
      this.menuMobileItemActive = '';
      document.body.classList.remove('overlay');
    },
    clickOutSubMenu() {
      this.showSubMenuMobile = !this.showSubMenuMobile;
      document.body.classList.remove('overlay');
    },
    gotoTrade(item) {
      this.$router.push(
        {
          path: '/spot-exchange/basic',
          query: {
            coin: item.coin,
            currency: 'idr',
          },
        },
        () => {}
      );
    },
    closeNotification(id) {
      const newList = this.notificationList.filter((item) => item.id !== id);
      this.$store.commit('setNotification', newList);
    },
    activeNav(href, isEnable, parent) {
      this.dropdownActive = undefined;
      this.parentActive = parent;
      if (isEnable === false) return;
      this.$router.push(href, () => {});
    },
    changeLang(key) {
      this.curentLang = key;
    },
    handleNavAccount(key) {
      this.parentActive = key;
      switch (key) {
        case 'account':
          this.$router.push('/account', () => {});
          break;
        case 'balance':
          this.$router.push('/funds/balances', () => {});
          this.parentActive = 'wallet';
          break;
        case 'logout':
          this.$emit('logout', () => {});
          AuthenticationUtils.removeAuthenticationData();
          window.location.href = '/';
          break;
        case 'login':
          this.$router.push('/login', () => {});
          break;
        case 'register':
          this.$router.push({
            path: '/register',
            params: this.$route.params,
            query: this.$route.query,
          });
          break;
        default:
          break;
      }
    },
    handleHome() {
      this.parentActive = '';
      this.$router.push('/', () => {});
    },
    handleShowSubMenu() {
      this.showSubMenu = !this.showSubMenu;
    },
    handleSubMenu(href) {
      this.showSubMenu = false;
      this.showSubMenuMobile = false;
      this.showMainMenuRight = false;
      this.$router.push(href, () => {});
    },
    handleShowNotificationList() {
      this.showLangDropdown = false;
      this.showMainMenu = false;
      this.showSubMenuMobile = false;
      this.showSubMenu = false;
      this.showMainMenuRight = false;
      this.showNotificationList = !this.showNotificationList;
    },
    handleShowLangDropdown() {
      this.showAccountDropdown = false;
      this.showMainMenu = false;
      this.showSubMenuMobile = false;
      this.showSubMenu = false;
      this.showMainMenuRight = false;
      this.showNotificationList = false;
      this.showLangDropdown = !this.showLangDropdown;
    },
    handleShowAccountDropdown() {
      this.showLangDropdown = false;
      this.showMainMenu = false;
      this.showSubMenuMobile = false;
      this.showSubMenu = false;
      this.showMainMenuRight = false;
      this.showNotificationList = false;
      this.showAccountDropdown = !this.showAccountDropdown;
    },
    handleToggle() {
      this.showLangDropdown = false;
      this.showAccountDropdown = false;
      this.showMainMenuRight = false;
    },
    toggleMainMenu() {
      this.showLangDropdown = false;
      this.showAccountDropdown = false;
      this.showSubMenuMobile = false;
      this.parentActive = undefined;
      this.showMainMenuRight = false;
      this.showMainMenu = !this.showMainMenu;
    },
    toggleSubMenu() {
      this.showLangDropdown = false;
      this.showAccountDropdown = false;
      this.showMainMenu = false;
      this.showMainMenuRight = false;
      this.showSubMenuMobile = !this.showSubMenuMobile;
      this.showSubMenuMobile
        ? document.body.classList.add('overlay')
        : document.body.classList.remove('overlay');
    },
    handleNav(item) {
      if (item.isEnable === false) return;
      if (item.children) {
        if (item.key === this.parentActive) {
          this.parentActive = undefined;
        } else {
          this.parentActive = item.key;
        }
      } else {
        this.showMainMenu = false;
        this.$router.push(item.href, () => {});
      }
    },
    toggleMainMenuRight() {
      if (this.showMainMenuRight) {
        this.mainmenuRight = this.navItems;
      }
      this.showMainMenuRight = !this.showMainMenuRight;
      this.showSubMenuMobile = false;
      this.showMainMenuRight
        ? document.body.classList.add('overlay')
        : document.body.classList.remove('overlay');
    },
    handleMenuRight(item) {
      this.showLangDropdown = false;
      this.showAccountDropdown = false;
      this.showMainMenu = false;
      this.showSubMenuMobile = !this.showSubMenuMobile;

      if (item.isEnable === false) return;

      if (item.href) {
        this.showMainMenuRight = false;
        this.$router.push(item.href, () => {});
      } else {
        this.mainmenuRight = item.children;
      }
    },
  },
  beforeDestroy() {
    document.body.classList.remove('overlay');
  },
};
</script>

<style lang="scss" scoped>
$header-height: 60px;
$header-mobile: 60px;

header {
  background: $bg-main1;

  .menu-mobile {
    display: none;
  }

  .container-header {
    padding: 0 50px;
    display: flex;
    justify-content: center;
  }

  .wrapper {
    flex: 1;
    position: relative;
    height: $header-height;
    display: flex;
    align-items: center;
    max-width: 1370px;
    z-index: 21;

    &.full-width {
      max-width: 100%;
      padding: 10px 32px;
    }

    .side-left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 80px;

      .logo {
        cursor: pointer;

        img {
          width: 79.199px;
          height: auto;
        }
      }
    }

    nav {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 60px;

      .nav-item {
        height: $header-height;
        position: relative;
        font-size: 16px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        cursor: pointer;

        span,
        a {
          height: 100%;
          display: flex;
          align-items: center;
          color: $color-white;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 22px;
          cursor: pointer;
        }

        img {
          width: 20px;
          transition: all 0.3s ease;

          &.rotate {
            transform: rotate(180deg);
          }
        }

        ul.nav-dropdown {
          display: none;
          position: absolute;
          border-radius: 10px;
          background: $text-main1;
          box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
          padding: 16px 24px;
          top: calc(100% + 5px);
          left: 0;
          z-index: 2023;
          gap: 16px 60px;

          &::before {
            content: '';
            position: absolute;
            top: -10px;
            left: 0;
            width: 100%;
            height: 10px;
          }

          // &#trade {
          //   left: 30%;
          //   transform: translateX(-30%);
          //   &::before {
          //     left: 30%;
          //     transform: translateX(-30%);
          //   }
          // }
          // &#wallet {
          //   left: 55%;
          //   transform: translateX(-55%);
          //   &::before {
          //     left: 55%;
          //     transform: translateX(-55%);
          //   }
          // }
          li {
            position: relative;

            .main-name {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              gap: 8px;
            }

            .note {
              position: absolute;
              top: calc(100% + 5px);
              left: 0;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 16px;
              color: $color-red;
            }

            span {
              color: $text-main;
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 22px;
              white-space: nowrap;
            }

            &.disabled {
              span {
                opacity: 0.5;
                cursor: initial;
              }
            }

            &#balances {
              grid-area: balances;
            }

            &#deposit-usd {
              grid-area: depositUsd;
            }

            &#deposits {
              grid-area: deposits;
            }

            &#withdrawal-usd {
              grid-area: withdrawalUsd;
            }

            &#withdrawals {
              grid-area: withdrawals;
            }

            &#transaction-history {
              grid-area: transactionHistory;
            }

            &.active {
              .main-name {
                span {
                  font-weight: 700;
                }
              }
            }
          }

          &.active {
            display: grid;
          }

          &#trade {
            grid-template-columns: 1fr 1fr;
          }

          &#wallet {
            grid-template-areas: 'balances withdrawalUsd' 'depositUsd withdrawals' 'deposits transactionHistory';
          }
        }

        &.active {
          span {
            font-weight: 700;
          }
        }
      }
    }

    .side-middle {
      display: flex;
      margin-left: 107px;
    }

    .side-right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      margin-left: auto;

      .notification {
        position: relative;

        .notification-icon {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 25px;
          }
        }

        .notification-box {
          display: none;
          position: absolute;
          right: -8px;
          top: $header-height;
          transform: translateX(8px);
          z-index: 100;
          box-shadow: 0px 24px 104px -20px rgba(0, 0, 0, 0.21);
          backdrop-filter: blur(2px);
          background: $color-white;
          border-radius: 10px;

          &::before {
            content: '';
            position: absolute;
            top: -10px;
            right: 8px;
            transform: translateX(-8px);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $color-white;
          }

          &.active {
            display: flex;
            flex-direction: column;
            gap: 5px;
          }

          ul.notification-list {
            min-width: 400px;
            max-height: 475px;
            overflow: auto;
            list-style-type: none;
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 5px;
            margin: 0;

            li {
              padding: 15px;
              border: 0.2px solid $grey-2;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
              border-radius: 10px;
              font-size: 18px;
              line-height: 1;
              cursor: pointer;
              position: relative;

              .title {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;

                .pair {
                  font-weight: 600;
                  color: $dark-1;
                }

                .price {
                  color: #008000;
                }
              }

              .description {
                margin-top: 13.5px;
                font-size: 16px;
                color: $dark-1;
              }

              .trigger-time {
                margin-top: 10px;
                font-size: 16px;
                color: $dark-1;
              }

              .status {
                margin-top: 12px;
                font-size: 14px;
                color: $dark-1;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;

                .toggle-switch {
                  cursor: pointer;
                  width: 48px;
                  height: 28px;
                  background: $dark-3;
                  border-radius: 14px;

                  .toggle-handle {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: #fff;
                    border-radius: 50%;
                    margin: 4px;
                    transition: all 0.3s ease;
                  }

                  &.off {
                    background: $dark-3;

                    .toggle-handle {
                      margin-left: 4px;
                    }
                  }

                  &.on {
                    background: #91c835;

                    .toggle-handle {
                      margin-left: 24px;
                    }
                  }
                }
              }

              .close {
                position: absolute;
                top: 10px;
                right: 10px;
                cursor: pointer;

                img {
                  height: 14px;
                }
              }
            }
          }
        }
      }

      .sub-menu {
        height: $header-height;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;

        img.invert {
          filter: invert(1);
        }

        ul.submenu-dropdown {
          display: none;
          position: absolute;
          width: auto;
          top: $header-height;
          right: -8px;
          z-index: 100;
          box-shadow: 0px 24px 104px -20px rgba(0, 0, 0, 0.21);
          backdrop-filter: blur(2px);
          background: $color-white;
          border-radius: 10px;
          padding: 10px 0;
          list-style-type: none;

          &::before {
            content: '';
            position: absolute;
            top: -10px;
            right: 10px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $color-white;
          }

          li {
            padding: 14px 20px;
            color: $dark-0;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            white-space: nowrap;

            &:hover {
              background: $grey-3;
            }

            &.active {
              background: $grey-2;
              font-weight: 700;
            }
          }

          &.active {
            display: block;
          }
        }
      }
    }
  }

  .switch-lang-wrap {
    position: relative;
    height: $header-height;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    .switch-lang {
      padding: 7px 8px;
      border: 1px solid $text-main1;
      border-radius: 5px;
      display: flex;
      align-items: center;
      gap: 4px;

      .current-lang {
        font-size: 14px;
        color: $color-white;
        text-transform: uppercase;
      }

      img {
        height: 18px;
        width: 18px;
        transition: all 0.3s ease;

        &.rotate {
          transform: rotate(180deg);
        }
      }

      &:hover {
        cursor: pointer;
      }
    }

    ul.lang-dropdown {
      display: none;
      position: absolute;
      width: auto;
      top: $header-height;
      left: 50%;
      transform: translateX(-50%);
      z-index: 100;
      box-shadow: 0px 24px 104px -20px rgba(0, 0, 0, 0.21);
      backdrop-filter: blur(2px);
      background: $color-white;
      border-radius: 10px;
      padding: 10px 0;
      list-style-type: none;

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $color-white;
      }

      li {
        padding: 14px 20px;
        color: $dark-0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        img {
          height: 20px;
          width: 20px;
          border-radius: 50%;
        }

        span {
          font-weight: 500;
          font-size: 18px;
          color: $dark-0;
          white-space: nowrap;
        }

        &:hover {
          background: $grey-3;
          cursor: pointer;
        }

        &.active {
          background: $grey-2;
          font-weight: 700;
        }
      }

      &.active {
        display: block;
      }
    }
  }

  .account {
    position: relative;
    height: $header-height;
    display: flex;
    align-items: center;

    > img {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      cursor: pointer;
    }

    .container-no-account {
      display: flex;
      justify-content: space-between;
      gap: 16px;

      .login {
        a {
          color: $color-white;
          @include btn-common(no, sm, false);
          color: $text-main1;
        }
      }

      .register {
        a {
          @include btn-common(no, sm);
          color: $text-main1;
          background: $color-blue;
        }
      }
    }

    .account-dropdown {
      display: none;
      position: absolute;
      width: auto;
      top: $header-height;
      right: 0;
      z-index: 3000;
      box-shadow: 0px 24px 104px -20px rgba(0, 0, 0, 0.21);
      backdrop-filter: blur(2px);
      background: $color-white;
      border-radius: 10px;
      padding: 15px 20px;
      list-style-type: none;

      &.active {
        display: block;
      }

      @include mobile {
        right: -5px;
      }

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        right: 15px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $color-white;
      }

      li {
        .title,
        .logout {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;

          img {
            height: 18px;
            width: 18px;
          }

          span {
            font-size: 16px;
            line-height: 27px;
            color: $dark-0;
            white-space: nowrap;
          }
        }

        .logout {
          cursor: pointer;
        }

        .sub-title {
          margin-top: 5px;
          font-size: 16px;
          line-height: 20px;
          color: $dark-0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
          max-width: 360px;
        }

        &:not(:first-child) {
          border-top: 1px solid $grey-2;
          margin-top: 15px;
          padding-top: 12px;
        }
      }

      &.active {
        display: block;
      }
    }

    ul.nav-dropdown {
      display: none;
      position: absolute;
      width: auto;
      min-width: 150px;
      top: $header-height;
      right: 0;
      z-index: 100;
      box-shadow: 0px 24px 104px -20px rgba(0, 0, 0, 0.21);
      backdrop-filter: blur(2px);
      background: $color-white;
      border-radius: 10px;
      padding: 10px 0;
      list-style-type: none;

      @include mobile {
        right: -5px;
      }

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        right: 15px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $color-white;
      }

      li {
        padding: 14px 20px;
        color: $dark-0;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        white-space: nowrap;

        &:hover {
          background: $grey-3;
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      &.active {
        display: block;
      }
    }

    .register-mobile {
      button {
        @include btn-common(no, sm);
        color: $text-main1;
        background: $color-blue;
      }
    }
  }

  .menu-mobile {
    display: none;
  }
}

@include tablet-d {
  header {
    position: relative;

    .wrapper {
      display: none;
    }

    .menu-mobile {
      position: fixed;
      z-index: 9997;
      top: 0;
      width: 100vw;
      height: $header-mobile;
      padding: 0 25px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: $bg-main1;

      .toggle-main-menu {
        img {
          cursor: pointer;
          height: 24px;
          width: 24px;

          &.invert {
            filter: invert(1);
          }
        }

        ul.main-menu {
          position: absolute;
          z-index: 9997;
          top: $header-mobile;
          left: 0;
          width: 100%;
          height: calc(100vh - #{$header-mobile});
          background: $color-white;
          padding: 0 24px;
          list-style-type: none;

          &::before {
            content: '';
            position: absolute;
            z-index: 9997;
            top: -10px;
            left: 28px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid white;
          }

          li {
            cursor: pointer;
            padding: 17px 0;
            border-bottom: 1px solid $grey-2;

            .has-child {
              display: flex;
              align-items: center;
              justify-content: space-between;

              img {
                height: 20px;
                width: 20px;

                &.invert {
                  filter: invert(1);
                }

                &.rotate {
                  transform: rotate(180deg);
                }
              }
            }

            &.switch-lang {
              .langs {
                margin-top: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 25px;

                span {
                  display: flex;
                  align-items: center;
                  gap: 10px;

                  input[type='radio'] {
                    margin: 0;
                    width: 20px;
                    height: 20px;
                  }

                  label {
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 1;
                    color: $dark-0;
                    margin: 0;
                  }
                }
              }
            }

            ul.children {
              list-style-type: none;

              li {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;
                border-top: 1px solid $grey-2;
                border-bottom: none;
                margin-top: 17px;
                padding-top: 17px;
                padding-bottom: 0;
                position: relative;

                img {
                  height: 20px;
                  width: 20px;
                }

                &.disabled {
                  span {
                    opacity: 0.5;
                    cursor: initial;
                  }
                }

                span {
                  // white-space: nowrap;
                }

                .note {
                  position: absolute;
                  top: calc(100% + 5px);
                  left: 0;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px;
                  color: $color-red;
                }
              }
            }
          }
        }
      }

      .logo {
        img {
          z-index: 1;
          position: relative;
          width: 76.162px;
          height: auto;
          cursor: pointer;
        }
      }

      .side-left,
      .side-right {
        display: flex;
        align-items: center;
        gap: 10px;

        .account {
          img {
            height: 40px;
            width: 40px;
          }

          ul.nav-dropdown {
            top: $header-mobile;
          }

          ul.account-dropdown {
            top: $header-mobile;
            display: none;

            &.active {
              display: block;
            }
          }
        }

        .main-menu-homapage {
          img {
            cursor: pointer;
          }

          img.right-icon {
            transform: rotate(180deg);
          }

          .main-menu-left {
            left: -100%;

            &.active {
              left: 0;
              opacity: 1;
            }
          }

          .main-menu-right {
            right: -100%;

            &.active {
              right: 0;
              opacity: 1;
            }
          }

          .main-menu-left,
          .main-menu-right {
            position: fixed;
            z-index: 9997;
            top: $header-mobile;
            bottom: 0;
            width: 75%;
            background: $color-white;
            list-style-type: none;
            opacity: 0;
            transition: all 0.3s linear;

            &.active {
              right: 0;
              opacity: 1;
            }

            .overlay {
              position: absolute;
              top: 0;
              width: 100vw;
              bottom: 0;
              z-index: -1;
            }

            .menu-left-container,
            .menu-right-container {
              padding: 16px 0;
              height: 100%;
              display: flex;
              justify-content: space-between;
              flex-direction: column;

              .menu-left-item,
              .menu-right-item {
                &.active {
                  .name-menu {
                    background: $bg-main2;
                  }

                  .list-menu {
                    height: 100%;
                    opacity: 1;
                    display: block;
                  }

                  img.down {
                    transform: rotate(180deg);
                  }

                  .text-menu-main,
                  .text-menu.router-link-exact-active {
                    color: $text-main;
                    font-weight: 700;
                    line-height: 24px;
                  }
                }
              }
            }

            .text-menu-main,
            .text-menu {
              color: $text-secondary;
              font-size: 14px;
              font-style: normal;
              font-weight: 300;
              line-height: 22px;
              width: 100%;
              cursor: pointer;
            }

            .name-menu {
              padding: 10px 16px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
            }

            .name-submenu {
              padding: 8px 24px;

              .note {
                color: $color-red;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
              }

              &.disabled {
                opacity: 0.6;
              }

              img {
                margin-right: 8px;
                margin-left: 0;
              }
            }

            .list-menu {
              height: 0;
              opacity: 0;
              display: none;
            }

            img.down {
              transform: rotate(0);
              transition: all 0.3s linear;
            }
          }

          .main-menu-left .overlay {
            left: 0;
          }

          .main-menu-right .overlay {
            right: 0;
          }

          .menu-right-footer {
            padding: 0 16px;

            .signin-mobile {
              margin-bottom: 16px;

              button {
                @include btn-common(contained, md);
              }
            }

            .language-mobile {
              :deep {
                .box_list_search_select {
                  top: unset;
                  bottom: 100%;
                  width: 100%;
                }

                .button_drop_search {
                  padding: 8px 16px;
                  border-radius: 5px;
                  border: 1px solid $bg-main2;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
