<template>
  <div>
    <modal :name="modalName" :hasModalFooter="false" :enableClose="false" width="414">
      <template slot="body">
        <div slot="body" class="popup_body_cancel_google_auth_form">
          <div class="header">
            <img src="/images/close.svg" @click="closeModal" alt="close" />
          </div>
          <div class="icon">
            <!-- <span class="icon-moon icon-google"></span> -->
            <img src="/images/change-phone.svg" alt="google-authen" />
            <div class="box-title">
              <h3>{{ $t('account.modal.change_phone.title') }}</h3>
            </div>
          </div>
          <div class="input-group">
            <div class="custom-input">
              <div class="form-input phone-number" :class="{ error: errors.has('phone_no') }">
                <img src="@/assets/images/phone.svg" alt="" />
                <select-box
                  v-model="code"
                  :options="countryCode"
                  propertyOption="value"
                  :placeholder="`+${code}`"
                  isSearchCountry
                  isSelectPhone
                />
                <input
                  ref="number"
                  name="phone_no"
                  @keypress="isNumber(e)"
                  data-vv-validate-on="none"
                  v-model.trim="number"
                  @focus="resetError"
                  autocomplete="one-time-code"
                />
              </div>
            </div>
            <div class="invalid-feedback">
              <span v-show="errors.has('phone_no')">
                {{ errors.first('phone_no') }}
              </span>
            </div>
          </div>
          <div class="input-group">
            <div class="form-input" :class="{ error: errors.has('password') }">
              <input
                type="password"
                @focus="resetErrors"
                name="password"
                :placeholder="$t('common.placeholders.login_password')"
                v-model="password"
                autocomplete="one-time-code"
              />
            </div>
            <div class="invalid-feedback">
              <span v-show="errors.has('password')">
                {{ errors.first('password') }}
              </span>
            </div>
          </div>
          <div class="form-group btn-group mb-0">
            <button :disabled="isSubmitting" class="btn-submit" @click="changePhoneNumber">
              {{ getSubmitName($t('modal_trading_pair_information.btn_submit')) }}
            </button>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import Modal from '../common/Modal'
import rf from '@/request/RequestFactory'
import SelectBox from '@/components/shared_components/common/SelectBox'
import countryCode from '@/assets/country.json'

export default {
  components: {
    Modal,
    SelectBox,
  },
  data() {
    return {
      modalName: 'ChangePhoneModal',
      password: '',
      countryCode: countryCode,
      code: '62',
      number: '',
      isSubmitting: false,
    }
  },
  computed: {
    phone_no() {
      if (this.number.startsWith('0')) {
        return `+${this.code}${this.number.slice(1)}`
      }
      return `+${this.code}${this.number}`
    },
    phoneNumberLength() {
      return this.code.length + this.number.length
    },
  },
  methods: {
    closeModal() {
      this.clearInput()
      this.$emit('ChangePhoneModal:CLOSE')
    },
    resetErrors() {
      this.errors.clear()
    },
    async changePhoneNumber() {
      if (this.isSubmitting) {
        return
      }
      if (this.errors.any()) {
        return
      }
      try {
        const res = await rf
          .getRequest('UserRequest')
          .changePhone({ password: this.password, phone_no: this.phone_no })

        this.$emit('ChangePhoneModal:CLOSE')
        this.showSuccess(this.$t('account.modal.change_phone.success'))
        this.clearInput()
      } catch (error) {
        this.resetErrors()
        const errors = error.response.data.errors
        Object.keys(errors).forEach((key) => {
          this.errors.add({
            field: key,
            msg: this.$t(error.response.data.errors[key][0]),
          })
        })
      }
    },
    showSuccess(message) {
      Message.success(message, {}, { position: 'top_right' })
    },
    clearInput() {
      this.password = ''
      this.code = '62'
      this.number = ''
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    resetError() {
      this.errors.clear()
    },
  },
  created() {
    this.clearInput()
    this.$on('hideCommonModal', (modalName) => {
      if (modalName !== this.modalName) {
        return
      }
      this.clearInput()
    })
  },
}
</script>
<style lang="scss">
#ChangePhoneModal {
  .modal-dialog {
    max-width: 100%;
  }
  .modal-body {
    padding: 30px;
  }
}
</style>
<style lang="scss" scoped>
@import '@/assets/sass/common.scss';

.popup_body_cancel_google_auth_form {
  .box-title {
    h3 {
      color: rgba($text-main2, 0.87);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      margin-top: 14px;
      margin-bottom: 24px;
    }
    .title-l {
      margin: 10px 0px 10px 0px;
      height: 1px;
      width: 5%;
      border-radius: 5px;
      background-color: $color-light-grey;
    }
  }
  .icon {
    text-align: center;
    span {
      font-size: $font-title-size-big-small-2;
      color: $color-eden;
    }
  }
  label {
    font-weight: normal;
    color: $color-black;
    margin-bottom: 0px;
  }
  .note_header {
    color: $color-black;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .row_form {
    margin-top: 20px;
    position: relative;
  }

  .input-group {
    &.last {
      margin-bottom: 24px;
    }
  }

  .btn-group {
    width: 100%;
    .btn-submit {
      @include btn-common(contained, md);
      line-height: 32px;
      font-size: 18px;
      padding: 10px 32px;
    }
  }
}
.is-danger {
  color: $color-alizarin-crimson;
}
.form-input.phone-number {
  padding: 0 16px !important;
}
</style>
