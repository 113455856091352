<template>
  <div id="order-group" :class="{ 'order-group-1': currentTab?.id === commonConst.OPEN_ORDER }">
    <template v-if="isMobile">
      <div class="order-group__mobile-wrapper">
        <div class="dropdown style-focus" tabindex="0">
          <button class="btn form-control btn-order-group" type="button" data-toggle="dropdown">
            <span
              class="left order-type-selected"
              v-for="tab in commonConst.OPEN_ORDER_NAVI"
              :key="tab?.id"
            >
              <template v-if="currentTab && currentTab?.id === tab?.id">
                {{ $t(tab?.name) }}
              </template>
            </span>
            <img src="/images/spot_exchange/icon-dropdown.svg" alt="" class="icon" />
            <img src="/images/spot_exchange/icon-dropdown-up.svg" alt="" class="icon icon-active" />
            <!-- <span class="icon icon-arrow1"></span> -->
          </button>
          <ul class="dropdown-menu">
            <div
              v-for="tab in commonConst.OPEN_ORDER_NAVI"
              @click="openOrderNaviChange(tab)"
              class="tab-element"
              :class="{ active: currentTab?.id === tab?.id }"
            >
              <span class="text-size">{{ $t(tab?.name) }}</span>
            </div>
          </ul>
        </div>
      </div>
      <div class="order-content">
        <open-order
          v-if="currentTab && currentTab?.id === commonConst.OPEN_ORDER"
          :ref="commonConst.OPEN_ORDER"
          :isSpotMobile="isMobile"
          :coinCode="coin"
          :currencyCode="currency"
        />
        <order-history
          v-if="currentTab && currentTab?.id === commonConst.ORDER_HISTORY"
          :ref="commonConst.ORDER_HISTORY"
          :isSpotMobile="isMobile"
          :coinCode="coin"
          :currencyCode="currency"
        />
        <trade-history
          v-if="currentTab && currentTab?.id === commonConst.TRADE_HISTORY"
          :isSpotMobile="isMobile"
          :ref="commonConst.TRADE_HISTORY"
          :coinCode="coin"
          :currencyCode="currency"
        />
        <funds
          v-if="currentTab && currentTab?.id === commonConst.FUNDS"
          :isSpotMobile="isMobile"
          :ref="commonConst.FUNDS"
        />
      </div>
    </template>
    <template v-else>
      <navi-tab @action-navi-tab="actionFromNaviTab" :currentTab="currentTab" />
      <div class="order-content">
        <open-order
          v-if="currentTab && currentTab?.id === commonConst.OPEN_ORDER"
          :ref="commonConst.OPEN_ORDER"
          :coinCode="coin"
          :currencyCode="currency"
        />
        <order-history
          v-if="currentTab && currentTab?.id === commonConst.ORDER_HISTORY"
          :ref="commonConst.ORDER_HISTORY"
          :coinCode="coin"
          :currencyCode="currency"
        />
        <trade-history
          v-if="currentTab && currentTab?.id === commonConst.TRADE_HISTORY"
          :ref="commonConst.TRADE_HISTORY"
          :coinCode="coin"
          :currencyCode="currency"
        />
        <funds v-if="currentTab && currentTab?.id === commonConst.FUNDS" :ref="commonConst.FUNDS" />
        <sub-accounts
          v-if="currentTab && currentTab?.id === commonConst.SUB_ACCOUNTS"
          :ref="commonConst.SUB_ACCOUNTS"
        />
      </div>
    </template>
  </div>
</template>

<script>
import NaviTab from '../common/NaviTab.vue';
import OpenOrder from './OpenOrder.vue';
import OrderHistory from './OrderHistory.vue';
import TradeHistory from './TradeHistory.vue';
import SubAccounts from '../exchange/basic/sub-accounts/SubAccounts.vue';
import Funds from './Funds.vue';
import COMMON_CONST from '@/common/Const';
export default {
  components: {
    NaviTab,
    OpenOrder,
    OrderHistory,
    TradeHistory,
    SubAccounts,
    Funds,
  },
  props: { windowWidth: String | Number },
  data() {
    return {
      currentTab: null,
      commonConst: COMMON_CONST,
      coin: this.$route.query.coin || COMMON_CONST.DEFAULT_COIN,
      currency: this.$route.query.currency || COMMON_CONST.DEFAULT_CURRENCY,
      isHide: false,
    };
  },
  watch: {
    $route(to, from) {
      this.currency = this.$route.query.currency || this.commonConst.USD;
      this.coin = this.$route.query.coin || this.commonConst.BTC;
      this.hideOtherPair();
    },
  },
  methods: {
    refresh() {
      switch (this.currentTab?.id) {
        case this.commonConst.OPEN_ORDER:
          this.$refs[this.commonConst.OPEN_ORDER].refreshTable();
          break;
        case this.commonConst.ORDER_HISTORY:
          this.$refs[this.commonConst.ORDER_HISTORY].refreshTable();
          break;
        case this.commonConst.TRADE_HISTORY:
          this.$refs[this.commonConst.TRADE_HISTORY].refreshTable();
          break;
        case this.commonConst.FUNDS:
          this.$refs[this.commonConst.FUNDS].refreshTable();
          break;
      }
    },
    getSocketEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
      };
    },
    onSpotSelectedPairMarket(pair) {
      this.coin = pair.coin;
      this.currency = pair.currency;
      this.isHide = false;
      if (this.$refs) {
        this.hideOtherPair();
      }
    },
    actionFromNaviTab(data) {
      if (data.action === this.commonConst.ACTION_CHANGE_ORDER_TAB) {
        this.openOrderNaviChange(data.item);
      } else {
        this.hideOtherPair(data.isCheck);
      }
    },
    openOrderNaviChange(objectTab) {
      this.currentTab = objectTab;
    },
    hideOtherPair(isCheck) {
      this.isHide = isCheck === undefined ? this.isHide : isCheck;

      switch (this.currentTab?.id) {
        case this.commonConst.OPEN_ORDER:
        case this.commonConst.ORDER_HISTORY:
        case this.commonConst.TRADE_HISTORY:
          if (
            !(
              this.$refs &&
              this.$refs[this.currentTab?.id] &&
              this.$refs[this.currentTab?.id].$refs &&
              this.$refs[this.currentTab?.id].$refs.datatable
            )
          ) {
            return;
          }

          if (!this.isHide) {
            this.$refs[this.currentTab?.id].$refs.datatable.filter({});
            return;
          }
          const params = {
            coin: this.coin,
            currency: this.currency,
          };
          this.$refs[this.currentTab?.id].$refs.datatable.filter(params);
          break;
        default:
          this.$refs[this.commonConst.FUNDS].hideSmallPair(isCheck);
          break;
      }
    },
  },
  computed: {
    isMobile() {
      if (!this.windowWidth) return;
      return this.windowWidth <= 768;
    },
  },
  mounted() {
    this.currentTab = COMMON_CONST.OPEN_ORDER_NAVI[0];
  },
};
</script>
<style lang="scss" scoped>
#order-group {
  height: 100%;
  .dropdown {
    &.open {
      .icon-arrow1 {
        rotate: 180deg;
      }
    }
    .btn-order-group {
      border-radius: 10px;
      border: solid 1px $bg-main2;
      color: $text-main;
      font-family: $helvetica;
      font-size: 14px;
      line-height: 22px;
      padding: 7px 16px;
      background-color: $color-white;
      text-align: left;
      width: 100%;
      height: auto;
      overflow: hidden;
      &:focus,
      &:active {
        outline: none !important;
      }
      &[aria-expanded='true'] {
        .icon {
          display: none;
          &.icon-active {
            display: block;
          }
        }
      }
      .icon {
        &.icon-active {
          display: none;
        }
        width: 18px;
        height: 18px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 12px;
      }
    }
    .dropdown-menu {
      width: 100%;
      border-radius: 10px;
      // box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.16);
      // border: 1px solid $bg-main2;
      border: unset;
      background-color: $color-white;
      padding: 10px 0px;
      .tab-element {
        cursor: pointer;
        display: block;
        width: 100%;
        line-height: 22px;
        padding: 7px 20px;
        font-size: 14px;
        color: $text-main;
        &:hover {
          background: $grey-3;
        }
      }
    }
  }
  .order-group__mobile-wrapper {
    padding: 0 16px 16px;
  }
  .order-content {
    height: calc(100% - 40px);
    padding: 0 0 20px;
    border-top: 1px solid $bg-main2;
    @include tablet {
      padding: unset;
    }
    @include mobile {
      border-top: unset;
      padding: unset;
    }

    div {
      div {
        height: 100%;
      }
    }
  }
}
.order-content header {
  min-width: 100% !important;
}
:deep() {
  #navi-tab {
    ul {
      li {
        background-color: transparent;
      }
    }
  }
  .data-table {
    min-height: 260px;
  }
  #tbl-open-orders,
  #order-table,
  #tbl-trade-history,
  #funds .table-history {
    padding: 0 !important;
    min-height: 270px;
  }
}

@include tablet-d() {
  #SpotExchange_page {
    #order-group.order-group-1 {
      display: grid;

      #navi-tab {
        min-width: unset;
        width: unset;
        border-radius: 10px 10px 0 0;
      }
      .order-content {
        min-width: unset;
      }
    }
  }
}
@include mobile {
  #SpotExchange_page #order-group-block {
    & #order-group.order-group-1,
    & #order-group {
      display: unset;
    }
  }
}
</style>
