import Numeral from "../lib/numeral";
import BigNumber from "bignumber.js";
import Utils from "./Utils";

export default {
  validateOrderInput: function (data, coinSetting) {
    const minPrice = coinSetting.price_precision || 0.0001;
    const minTotal = coinSetting.minimum_amount || 0.0001;
    const minQuantity = coinSetting.minimum_quantity || 0.0001;

    this.validateBalance(data);
    switch (data.type) {
      case "limit":
        this.validatePrice(data.price, data.trade_type, minPrice);
        this.validateAmount(data.quantity, data.trade_type, minQuantity);
        this.validateTotal(data.price, data.quantity, minTotal, data.total);
        break;
      case "market":
        this.validateAmount(data.quantity, data.trade_type, minQuantity);
        break;
      case "stop_limit":
        this.validateStopPrice(data.base_price, data.trade_type, minPrice);
        this.validateLimit(data.price, data.trade_type, minPrice);
        this.validateAmount(data.quantity, data.trade_type, minQuantity);
        this.validateTotal(data.price, data.quantity, minTotal, data.total);
        break;
      case "stop_market":
        this.validateStopPrice(data.base_price, data.trade_type, minPrice);
        this.validateAmount(data.quantity, data.trade_type, minQuantity);
        break;
      default: {
        throw { field: null, message: "Order type is invalid." };
      }
    }
  },

  validateBalance(data) {
    const balance = parseFloat(data.balance);
    if (window._.isEmpty(data.balance) || balance <= 0) {
      this.validateEmpty(null, null, "messages.error.balance_insufficient1");
    }

    if (data.trade_type === "buy") {
      if (data.type === "limit") {
        if (
          new BigNumber(`${data.quantity || 0}`)
            .mul(`${data.price || 0}`)
            .comparedTo(data.balance) > 0
        ) {
          this.validateEmpty(null, null, "messages.error.balance_insufficient");
        }
      }
    }

    if (data.trade_type === "sell") {
      if (new BigNumber(`${data.quantity || 0}`).comparedTo(data.balance) > 0) {
        this.validateEmpty(null, null, "messages.error.balance_insufficient");
      }
    }
  },

  validatePrice(value, trade_type, minValue) {
    const fieldName = "price";
    if (trade_type === "buy") {
      this.validateEmpty(fieldName, value, "messages.error.buy.empty_price");
    } else {
      this.validateEmpty(fieldName, value, "messages.error.sell.empty_price");
    }
    this.validateMinimumValue(
      fieldName,
      value,
      minValue,
      "messages.error.minimum_price"
    );
  },

  validateLimit(value, trade_type, minValue) {
    const fieldName = "price";
    if (trade_type === "buy") {
      this.validateEmpty(fieldName, value, "messages.error.buy.empty_limit");
    } else {
      this.validateEmpty(fieldName, value, "messages.error.sell.empty_limit");
    }
    this.validateMinimumValue(
      fieldName,
      value,
      minValue,
      "messages.error.minimum_limit"
    );
  },

  validateAmount(value, trade_type, minValue) {
    const fieldName = "amount";
    if (trade_type === "buy") {
      this.validateEmpty(fieldName, value, "messages.error.buy.empty_quantity");
    } else {
      this.validateEmpty(
        fieldName,
        value,
        "messages.error.sell.empty_quantity"
      );
    }
    this.validateMinimumValue(
      fieldName,
      value,
      minValue,
      "messages.error.minimum_quantity"
    );
  },

  validateTotal(price, amount, minValue, total) {
    const fieldName = "total";
    this.validateEmpty(fieldName, total, "messages.error.empty_total");
    total = new BigNumber(`${price}`).mul(`${amount}`);
    const bgMinValue = new BigNumber(`${minValue}`);

    if (total.lt(bgMinValue)) {
      const value = Utils.trimEndZero(new BigNumber(`${minValue}`).toFixed(20));
      throw {
        field: fieldName,
        message: "messages.error.minimum_total",
        value: value,
      };
    }
  },

  validateStopPrice(value, trade_type, minValue) {
    const fieldName = "stop_price";
    if (trade_type === "buy") {
      this.validateEmpty(
        fieldName,
        value,
        "messages.error.buy.empty_base_price"
      );
    } else {
      this.validateEmpty(
        fieldName,
        value,
        "messages.error.sell.empty_base_price"
      );
    }
    this.validateMinimumValue(
      fieldName,
      value,
      minValue,
      "messages.error.minimum_base_price"
    );
  },

  validateEmpty(field, value, msgKey) {
    if (!value) {
      throw { field: field, message: msgKey };
    }
  },

  validateMinimumValue(field, value, minValue, msgKey) {
    const bgValue = new BigNumber(`${value || 0}`);
    const bgMinValue = new BigNumber(`${minValue || 0}`);

    if (bgValue.lt(bgMinValue)) {
      const value = Utils.trimEndZero(new BigNumber(`${minValue}`).toFixed(20));
      throw { field: field, message: msgKey, value: value };
    }
  },
};
