<template>
  <span v-bind="$attrs">{{ countDownText }}</span>
</template>

<script>
export default {
  name: "CountDown",
  props: {
    duration: {
      type: Number,
      default: 60,
    },
    autoCountDown: {
      type: Boolean,
      default: false,
    },
    timeHour: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      countDownText: "",
      countDown: null,
    };
  },
  methods: {
    setCountDown() {
      clearInterval(this.countDown);
      let timer = this.duration;
      let minutes, seconds;
      const vm = this;
      if (this.timeHour) {
        this.countDown = setInterval(function () {
          const hours = Math.floor(timer / 3600);
          const minutes = Math.floor((timer % 3600) / 60);
          const seconds = timer % 60;
          const formattedTime =
            hours.toString().padStart(2, "0") +
            ":" +
            minutes.toString().padStart(2, "0") +
            ":" +
            seconds.toString().padStart(2, "0");
          vm.countDownText = formattedTime;
          if (--timer < 0) {
            clearInterval(vm.countDown);
            vm.$emit("end");
          }
        }, 1000);
        return;
      }
      this.countDown = setInterval(function () {
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        vm.countDownText = minutes + ":" + seconds;

        if (--timer < 0) {
          clearInterval(vm.countDown);
          vm.$emit("end");
        }
      }, 1000);
    },
    resetCountDown() {
      clearInterval(this.countDown);
    },
  },
  beforeDestroy() {
    clearInterval(this.countDown);
  },
  mounted() {
    if (this.autoCountDown) {
      this.setCountDown();
    }
  },
};
</script>
