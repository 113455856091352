<template>
  <PopupModal :is-show="isShow">
    <div slot="body">
      <div class="withdrawal-modal">
        <div class="guest-page-form">
          <div class="modal-header">
            <button type="button" id="btn_close_modal" class="close" @click="handleCloseModal">
              <img src="/images/icon/close-circle.svg" alt="icon" />
            </button>
          </div>
          <div class="form-container">
            <div class="guest-page__form-header">
              {{
                $t('withdraw_fiat_modal.title', {
                  travelRuleAmount: formatMoney(travelRuleAmount),
                })
              }}
            </div>
            <div class="guest-page__form-body">
              <div class="form-body__section">
                <div class="input-group__header">
                  {{ $t('withdraw_fiat_modal.form.title') }}
                </div>
                <div class="input-group">
                  <p class="input-group__label">
                    {{ $t('withdraw_fiat_modal.form.full_name') }}
                  </p>
                  <input
                    autocomplete="off"
                    placeholder="--"
                    :class="{ 'input-error': errors.has('name') }"
                    class="form-input"
                    disabled
                    type="text"
                    v-validate="'required'"
                    name="name"
                    v-model="kyc.full_name"
                  />

                  <div class="error-msg">
                    <span v-show="errors.has('name')">
                      {{ $t('withdraw_fiat_modal.form.field_required') }}
                    </span>
                  </div>
                </div>

                <div class="input-group">
                  <p class="input-group__label">
                    {{ $t('withdraw_fiat_modal.form.address') }}
                  </p>
                  <input
                    autocomplete="off"
                    placeholder="--"
                    v-validate="'required'"
                    :class="{ 'input-error': errors.has('address') }"
                    class="form-input"
                    disabled
                    type="text"
                    name="address"
                    v-model="kyc.address"
                  />

                  <div class="error-msg">
                    <span v-show="errors.has('address')">
                      {{ $t('withdraw_fiat_modal.form.field_required') }}
                    </span>
                  </div>
                </div>
                <div class="input-group">
                  <p class="input-group__label">
                    {{ $t('withdraw_fiat_modal.form.excution_date') }}
                  </p>
                  <datepicker
                    :language="datePickerLang"
                    autocomplete="off"
                    placeholder="--"
                    v-validate="'required'"
                    class="withdrawal__custom-datepicker"
                    :class="{ 'input-error': errors.has('execution_date') }"
                    format="dd-MM-yyyy"
                    name="execution_date"
                    v-model="form.execution_date"
                  ></datepicker>
                  <div class="error-msg">
                    <span v-show="errors.has('execution_date')">
                      {{ $t('withdraw_fiat_modal.form.field_required') }}
                    </span>
                  </div>
                </div>
                <div class="input-group">
                  <p class="input-group__label">
                    {{ $t('withdraw_fiat_modal.form.source_of_funds') }}
                    <span>*</span>
                  </p>
                  <div @click="resetErrors">
                    <select-box
                      v-model="form.source_of_funds"
                      :options="sourceOfFundsOptions"
                      v-validate="'required'"
                      :placeholder="$t('source_of_funds.choose')"
                      :class="{ 'input-error': errors.has('source_of_funds') }"
                      name="source_of_funds"
                      class="withdrawal__custom-selectbox"
                    />
                  </div>
                  <div class="error-msg">
                    <span v-show="errors.has('source_of_funds')">
                      {{ $t('withdraw_fiat_modal.form.field_required') }}
                    </span>
                  </div>
                </div>
                <div
                  class="input-group"
                  v-if="isShowSpecificSourceOfFunds"
                  style="margin-top: 24px"
                >
                  <input
                    autocomplete="off"
                    :placeholder="$t('withdraw_fiat_modal.form.source_of_funds_placeholder')"
                    maxlength="100"
                    v-validate="'required'"
                    :class="{
                      'input-error': errors.has('specify_source_of_funds'),
                    }"
                    class="form-input"
                    type="text"
                    name="specify_source_of_funds"
                    v-model="form.specify_source_of_funds"
                  />

                  <div class="error-msg">
                    <span v-show="errors.has('specify_source_of_funds')">
                      {{ $t('withdraw_fiat_modal.form.field_required') }}
                    </span>
                  </div>
                </div>
                <div class="input-group">
                  <p class="input-group__label">
                    {{ $t('withdraw_fiat_modal.form.upload_supporting_document') }}
                    <span>*</span>
                  </p>
                  <input
                    type="file"
                    id="supporting_documents"
                    class="file-selector__img"
                    accept="image/png,image/jpeg,image/jpg,image/heic,image/heif,application/pdf"
                    ref="file"
                    @change="previewImage"
                  />
                  <label
                    for="supporting_documents"
                    class="input-group__file-selector"
                    :class="{
                      'input-error': errors.has('supporting_documents'),
                    }"
                  >
                    <div
                      class="input-group__image-preview"
                      v-if="previewImageSrc || (isPdfFile && form.supporting_documents)"
                    >
                      <img v-if="!isPdfFile" class="preview" :src="previewImageSrc" />
                      <vue-pdf-embed
                        v-else
                        class="pdf-preview"
                        height="200"
                        :page="1"
                        :source="form.supporting_documents"
                      />
                      <img
                        @click.prevent="removeImg($refs.file)"
                        src="/images/icon/close-circle.svg"
                        alt="close-icon"
                        class="close-icon"
                      />
                    </div>
                    <div class="img-wrapper" v-else>
                      <img class="cloud-upload" src="/images/Upload.svg" alt="cloud-upload" />
                    </div>
                  </label>
                  <div class="error-msg">
                    <span v-show="errors.has('supporting_documents')">
                      {{ errors.first('supporting_documents') }}
                    </span>
                  </div>
                </div>
              </div>
              <!-- Submit Action -->
              <div class="submit-btn__wrapper">
                <button
                  class="btn-primary withdrawal__submit-btn"
                  @click="submitWithdrawal"
                  type="button"
                >
                  {{ getSubmitName($t('withdraw_fiat_modal.form.submit')) }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </PopupModal>
</template>

<script>
import PopupModal from '@/components/PopupModal.vue'
import { en, id } from 'vuejs-datepicker/dist/locale'
import Datepicker from 'vuejs-datepicker'
import SelectBox from '@/components/shared_components/common/SelectBox'
import moment from 'moment'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import heic2any from 'heic2any'

export default {
  inject: ['$validator'],
  components: {
    PopupModal,
    Datepicker,
    SelectBox,
    VuePdfEmbed,
  },
  data() {
    return {
      datePickerLang: this.$i18n.locale === 'id' ? id : en,
      isPdfFile: false,
      form: {
        execution_date: new Date(),
        source_of_funds: null,
      },
      sourceOfFundsOptions: [
        { id: 'Salary', name: this.$i18n.t('source_of_funds.salary') },
        {
          id: 'Personal savings accounts',
          name: this.$i18n.t('source_of_funds.personal_saving'),
        },
        {
          id: 'Employment income',
          name: this.$i18n.t('source_of_funds.employment_income'),
        },
        {
          id: 'Property sales',
          name: this.$i18n.t('source_of_funds.property_sale'),
        },
        {
          id: 'Inheritances and gifts',
          name: this.$i18n.t('source_of_funds.inheritances'),
        },
        {
          id: 'Legal settlements',
          name: this.$i18n.t('source_of_funds.legal_settlements'),
        },
        { id: 'Others', name: this.$i18n.t('source_of_funds.others') },
      ],
      previewImageSrc: '',
    }
  },
  props: {
    name: { type: String, default: 'withdrawal-modal' },
    isShow: { type: Boolean, default: false },
    submit: { type: Function },
    kyc: {
      type: Object,
      default: {},
    },
    travelRuleAmount: { String, default: '' },
    errorMessenger: { type: String },
  },
  computed: {
    isShowSpecificSourceOfFunds() {
      return this.form.source_of_funds === 6
    },
    isValid() {
      if (
        !this.form.name ||
        !this.form.address ||
        !this.form.source_of_funds ||
        !this.$refs.file ||
        !this.form.execution_date
      )
        return false
      return true
    },
  },
  watch: {
    errorMessenger(newVal) {
      if (newVal) {
        this.$validator.errors.add({
          field: 'otp',
          msg: newVal,
        })
      }
    },
    'form.specify_source_of_funds'(val) {
      if (val.length > 100) {
        this.form.specify_source_of_funds = val.substring(0, 100)
      }
    },
  },
  methods: {
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear()
      }
    },
    validateImage(file) {
      const fileSize = file?.size
      const name = file?.name.split('.') || ['']
      const typeByNameFile = name[name.length - 1].toLowerCase()
      if (!file) {
        this.errors.add('supporting_documents', this.$i18n.t('validation_field_required'))
        return false
      } else if (
        typeByNameFile !== 'jpeg' &&
        typeByNameFile !== 'png' &&
        typeByNameFile !== 'jpg' &&
        typeByNameFile !== 'heic' &&
        typeByNameFile !== 'heif' &&
        typeByNameFile !== 'pdf'
      ) {
        this.errors.add(
          'supporting_documents',
          this.$i18n.t('withdraw_fiat_modal.form.invalid_document_type')
        )
        return false
      } else if (typeof file !== 'string' && fileSize > 10485760) {
        this.errors.add(
          'supporting_documents',
          this.$i18n.t('withdraw_fiat_modal.form.document_size_over_limit')
        )
        return false
      }
      return true
    },
    formatMoney(val) {
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    removeImg(input) {
      this.previewImageSrc = ''
      this.form.supporting_documents = ''
      if (input.value) {
        input.value = null
      }
      this.resetErrors()
    },
    convertUrl(url, name = '') {
      this.previewImageSrc = url
      if (
        name.includes('heic') ||
        name.includes('heif') ||
        name.includes('HEIC') ||
        name.includes('HEIF')
      ) {
        fetch(url)
          .then((res) => res.blob())
          .then((blob) =>
            heic2any({
              blob,
              toType: 'image/png',
              quality: 1,
            })
          )
          .then((conversionResult) => {
            this.previewImageSrc = URL.createObjectURL(conversionResult)
          })
      }
    },
    previewImage: function (event) {
      this.resetErrors()
      // Reference to the DOM input element
      var input = event.target
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        this.isPdfFile = input.files[0].type === 'application/pdf'
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader()
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          const name = input.files[0].name
          this.convertUrl(e.target.result, name)
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.form = { ...this.form, supporting_documents: e.target.result }
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },

    handleCloseModal() {
      this.form = {
        execution_date: new Date(),
        source_of_funds: null,
        specify_source_of_funds: '',
      }
      this.previewImageSrc = ''
      this.resetErrors()
      this.$emit('close')
    },

    getSourceOfFunds(val) {
      if (val === 6) return this.form.specify_source_of_funds
      else {
        return this.sourceOfFundsOptions.find((item) => item.id === val)?.id
      }
    },

    async submitWithdrawal() {
      const isValid = await this.$validator.validate()
      if (!this.validateImage(this.$refs.file.files[0]) || !isValid) return
      const document = {
        name: this.kyc.full_name,
        address: this.kyc.address,
        execution_date: moment(this.form.execution_date).format('DD-MM-YYYY'),
        source_of_funds: this.getSourceOfFunds(this.form.source_of_funds),
      }
      this.$emit('submitWithdrawal', document, this.$refs.file.files[0])
      setTimeout(() => {
        this.form = {
          execution_date: new Date(),
          source_of_funds: null,
          specify_source_of_funds: '',
        }
        this.removeImg(this.$refs.file)
        this.resetErrors()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.vdp-datepicker.withdrawal__custom-datepicker {
  & input {
    border: 1px solid #ddd;
    font-weight: 300;
    font-size: 14px;
    border-radius: 8px;
    line-height: 22px;
    padding: 10px 16px;
    height: auto;
    outline: none;
    width: 100%;
    background: #f2f2f2;
    font-family: $helvetica;
    color: $text-main;
  }
  & .vdp-datepicker__calendar {
    display: none !important;
  }
}
.withdrawal__custom-selectbox.sc_search_select {
  &.input-error div.button_drop_search {
    border: 1px solid red !important;
  }
  & .group_search_select div.button_drop_search {
    border: 1px solid #ddd;
    font-weight: 400;
    font-size: 16px;
    border-radius: 8px;
    line-height: 24px;
    padding: 8px 16px;
    color: $text-main;
    height: auto;
    outline: none;
    width: 100%;
    margin-bottom: 5px;

    > span {
      font-size: 16px;
      line-height: 24px;
      @include mobile {
        font-size: 14px;
        line-height: 22px;
        font-weight: 300;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.close-icon {
  width: 21px;
  height: 21px;
  min-height: unset;
  background: transparent;
  position: absolute;
  right: 9px;
  top: 9px;
  cursor: pointer;
  z-index: 9999;
  transition: all 0.3s ease-in-out;
}
.withdrawal-modal {
  width: 100%;
  max-width: 512px;
  margin: 24px auto;
  @include mobile {
    width: calc(100dvw - 30px);
    margin: 15px auto;
  }
  .modal-header {
    padding: 12px;
  }
  .guest-page-form {
    background-color: white;
    border-radius: 10px;
    .form-container {
      color: black;
      padding: 24px;
      @include mobile {
        padding: 24px 16px;
      }
      .guest-page__form-header {
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
        color: $text-main;
        text-align: center;
      }
      .guest-page__form-body {
        .form-body__section {
          .input-group__header {
            margin-top: 24px;
            margin-bottom: 16px;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: $text-main;
          }
          .input-group {
            margin-bottom: unset;
            .input-group__label {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              margin-bottom: 8px;
              color: $text-main2;
              opacity: 0.6;
              @include mobile {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
              }
              span {
                color: red;
              }
            }

            input {
              border: 1px solid #ddd;
              border-radius: 8px;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: $text-main;
              @include mobile {
                font-size: 14px;
                font-weight: 300;
                line-height: 22px;
              }
              &.input-error {
                border-color: red;
              }
              &:disabled {
                background: #f2f2f2;
                color: $text-main;
              }
              &:focus {
                border-color: $bg-main1;
              }
            }
            & + .input-group {
              margin-top: 10px;
            }
            input.file-selector__img {
              display: none;
            }
            .input-group__file-selector {
              height: 190px;
              border: 1px solid #ddd;
              border-radius: 8px;
              position: relative;
              display: flex;
              justify-content: center;
              cursor: pointer;
              &.input-error {
                border-color: red;
              }
              .img-wrapper {
                margin-top: auto;
                margin-bottom: auto;
              }
              .input-group__image-preview {
                margin: 0 auto;
                text-align: center;
                img.preview {
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                }
                div.pdf-preview {
                  height: 100%;
                  overflow: hidden;
                }
              }
            }
          }
        }

        .submit-btn__wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          .withdrawal__submit-btn {
            width: 100%;
            margin-top: 24px;
            background: $bg-main6;
            border-radius: 60px;
            padding: 10px 32px;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            color: white;
          }
        }
      }
    }
  }
}

.error-msg {
  color: red;
  font-size: 12px;
}
.guest-page-form {
  position: relative;
  .modal-header {
    position: absolute;
    border: 0;
    top: 0;
    right: 0;

    .close {
      opacity: 0.9;
      z-index: 100;
      position: relative;
      outline: none;
      background: transparent !important;
      &:hover {
        opacity: 1;
      }
      .icon-close {
        border-radius: 50%;
        color: $color-grey;
        line-height: 32px;
        overflow: hidden;
        text-align: center;
        display: inline-block;
        font-size: $font-smaller;
        width: 30px;
        height: 30px;
        margin: 0px 0px 0 0;
        cursor: pointer;
        padding-left: 2px;
        &:hover {
          background-color: $color-light-grey;
          color: $color-grey;
        }
      }
    }
  }
}
</style>
