<template>
  <div id="order-history-screen">
    <div class="box-title">
      {{ $t("menu.order_history") }}
    </div>
    <div class="order-history-wrap">
      <order-history
        class="order-group-screen"
        :isSpotMobile="isMobile"
        hiddenLogoCrypto
        :options="{ isNoPadding: true }"
      />
    </div>
  </div>
</template>

<script>
import COMMON_CONST from "@/common/Const";
import OrderHistory from "./OrderHistory.vue";

export default {
  components: {
    OrderHistory,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      dateList: COMMON_CONST.OPEN_ORDER_DATE_TAB,
    };
  },
  methods: {
    selectDate(item) {},
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  computed: {
    isMobile() {
      if (!this.windowWidth) return;
      return this.windowWidth <= 768;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    document.title = this.$t("menu.order_history") + ` – ${this.tabTitle}`;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/sass/common.scss";
#order-history-screen {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto 50px;
  padding: 25px 80px;
  min-height: 30vh;
  max-width: 100%;
  @media (max-width: 1399px) {
    padding: 25px 32px;
  }
  @include tablet-d {
    padding: 25px 15px;
  }
  .box-title {
    @include text-font($helvetica, $text-main, 24px, 700, 40px);
  }
  .order-history-wrap {
    border-radius: 10px;
  }
}

@include mobile {
  #order-history-screen {
    padding: 16px;
    .box-title {
      @include text-font($helvetica, $text-main, 18px, 700, 32px);
    }
    :deep .order-history__mobile-wrapper {
      padding: 0;
      .group-date-picker {
        .group-date {
          .label-text {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: $text-secondary;
          }
        }
      }
      .export-file .excel_export a {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
      }
      .group-pair {
        justify-content: space-between;
        flex: 1;
        .toolbar-element3 {
          width: -webkit-fill-available;
          .label-text {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: $text-secondary;
          }
          .sc_search_select .button_drop_search {
            width: 100%;
          }
        }
        .content-search {
          .label-text {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            color: $text-secondary;
          }
          .toolbar .toolbar-element2 .button_drop_search {
            min-width: 90px;
          }
        }
      }
      .group-search {
        justify-content: space-between;
        flex: 1;
        .button-reset,
        .button-search {
          width: -webkit-fill-available;
        }
      }
      .order-history__mobile-body {
        margin-top: 16px;
        .item-title__wrapper {
          white-space: nowrap;
          & div:first-child {
            width: 70px;
            font-size: 14px;
            font-style: normal;
            font-weight: 700 !important;
            line-height: 24px;
            color: $text-main;
          }
          & .item-title__date {
            width: unset !important;
            margin-left: 4px !important;
            color: $text-main-blur !important;
            font-size: 14px !important;
            font-weight: 300;
            line-height: 22px !important;
          }
        }
        .order-history__item-desc {
          grid-template-columns: repeat(3, 1fr);
          & > .item-desc__wrapper:nth-child(3n + 3) {
            text-align: end;
          }
          & .item-desc__wrapper {
            .item-desc__value {
              line-height: 24px !important;
              font-weight: 400 !important;
              min-height: 24px !important;
              & div.blue {
                color: $color-green;
                font-weight: 700;
              }
              & div.red {
                color: $color-red;
                font-weight: 700;
              }
            }
            .item-desc__title {
              font-weight: 300 !important;
            }
          }
        }
      }
    }
  }
}
</style>
