import AuthenticationUtils from "@/common/AuthenticationUtils";
import MasterdataUtils from "@/common/MasterdataUtils";
import LocalStorageUtils from "@/common/LocalStorageUtils";
import Const from "@/common/Const";
import CsvUtils from "@/common/CsvUtils";
import axios from "axios";

export default class BaseRequest {
  getUrlPrefix() {
    return process.env.VUE_APP_API_URL + "/api/v1";
  }

  getCurrentLocale() {
    // if (window.i18n) {
    //   return window.i18n.locale;
    // }
    return "en";
  }

  appendLocale(data) {
    const lang = this.getCurrentLocale();
    return Object.assign(data, { lang });
  }

  async get(url, params = {}, cancelToken) {
    try {
      const config = {
        params: params,
        cancelToken: cancelToken ? cancelToken.token : undefined,
      };
      const response = await axios.get(this.getUrlPrefix("GET") + url, config);
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async put(url, data = {}) {
    try {
      data = this.appendLocale(data);
      const response = await axios.put(this.getUrlPrefix() + url, data);
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async post(url, data = {}) {
    try {
      data = this.appendLocale(data);
      const response = await axios.post(this.getUrlPrefix() + url, data);
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async del(url, data = {}) {
    try {
      data = this.appendLocale(data);
      const response = await axios.delete(this.getUrlPrefix() + url, { data });
      return this._responseHandler(response);
    } catch (error) {
      this._errorHandler(error);
    }
  }

  async _responseHandler(response, url) {
    const data = response.data;
    await this._checkMasterdataVersion(data);
    return data;
  }

  async _checkMasterdataVersion(data) {
    const existedVersion = MasterdataUtils.getCachedMasterdataVersion();
    if (existedVersion && MasterdataUtils.isDataChanged(data.dataVersion)) {
      await MasterdataUtils.clearMasterdata();
      MasterdataUtils.fireEventReloadPage(data.dataVersion);
    }
  }

  async download(url, params, fileName) {
    const data = this.appendLocale(params);
    const response = await this.get(url, data);
    CsvUtils.export(response, fileName);
  }

  _errorHandler(err) {
    window.app.$broadcast("EVENT_COMMON_ERROR", err);
    if (err.response && err.response.status === 401) {
      // Unauthorized (session timeout)
      AuthenticationUtils.removeAuthenticationData();
      window.app.$broadcast("UserSessionRegistered");
    }
    if (err.response && err.response.status === 503) {
      // maintenance
      window.location.reload();
    }
    throw err;
  }
}
