<template>
  <div id="open-order">
    <template v-if="isSpotMobile">
      <div class="open-order__mobile-wrapper">
        <div class="open-order__mobile-header">
          <div class="checkbox-function">
            <label for="checkbox_open_order_id">
              <input
                type="checkbox"
                name="checkbox"
                v-model="isHideOtherPair"
                value="check"
                id="checkbox_open_order_id"
                @change="onOrderListUpdated(null, isHideOtherPair)"
              />
              <div>{{ $t("order.open_order.hide_other_pairs") }}</div>
            </label>
          </div>
          <cancel-box @action-cancel-box="actionCancelOpenOrder" />
        </div>
        <div class="open-order__mobile-body">
          <data-list-mobile
            ref="datatable"
            :msg-empty-data="$t('order.open_order.no_data')"
            :get-data="getData"
            :limit="10"
            :height="30"
          >
            <template slot="table-empty-header">
              <thead>
                <tr>
                  <th
                    v-for="(item, idx) in headerTable"
                    :key="item.id"
                    :title="$t(item && item.name)"
                    :class="`cl-${idx + 1}`"
                  >
                    <span
                      >{{ $t(item.name)
                      }}{{
                        item.name === "order.open_order.filled" ? "%" : null
                      }}</span
                    >
                  </th>
                </tr>
              </thead>
            </template>
            <template slot="body" slot-scope="props">
              <div class="open-order__list-item">
                <div class="open-oder__item-title">
                  <div class="item-title__wrapper">
                    <div
                      v-if="!hiddenLogoCrypto"
                      class="item-title__logo-wrapper"
                    >
                      <img
                        class="coin"
                        width="20"
                        height="20"
                        :src="
                          STORAGE_URL + '/icons/' + props.item.coin + '.png'
                        "
                      />
                      <img
                        class="currency"
                        width="20"
                        height="20"
                        :src="
                          STORAGE_URL + '/icons/' + props.item.currency + '.png'
                        "
                      />
                    </div>
                    <div>
                      {{ props.item.coin | uppercase }}/{{
                        props.item.currency | uppercase
                      }}
                    </div>
                    <div
                      v-if="!hiddenLogoCrypto"
                      :class="{
                        blue: props.item.trade_type === 'buy',
                        red: props.item.trade_type === 'sell',
                      }"
                    >
                      {{
                        props.item.trade_type === "buy"
                          ? $t("common.trade_type.buy")
                          : $t("common.trade_type.sell")
                      }}
                    </div>
                    <div class="item-title__date">
                      {{ props.item.created_at | timestampToDate }}
                    </div>
                  </div>
                  <div
                    class="item-title__close"
                    @click="actionCancelOpenOrder('one', props.item.id)"
                  >
                    <img src="/images/close-circle.svg" />
                  </div>
                </div>
                <div class="open-oder__item-desc">
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{ localizeType(props.item.type) | uppercaseFirst }}
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="type"
                      @click="props.onSort"
                    >
                      {{ $t("order.open_order.type")
                      }}<i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper" v-if="hiddenLogoCrypto">
                    <div class="item-desc__value">
                      <div
                        :class="{
                          blue: props.item.trade_type === 'buy',
                          red: props.item.trade_type === 'sell',
                        }"
                      >
                        {{
                          props.item.trade_type === "buy"
                            ? $t("common.trade_type.buy")
                            : $t("common.trade_type.sell")
                        }}
                      </div>
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="trade_type"
                      @click="props.onSort"
                    >
                      {{ $t("order.open_order.side")
                      }}<i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      <div
                        v-if="
                          props.item.type == 'market' ||
                          props.item.type == 'stop_market'
                        "
                        :title="$t('order.open_order.market')"
                      >
                        {{ $t("order.open_order.market") }}
                      </div>
                      <div
                        v-else
                        :title="
                          props.item.price
                            | formatCurrencyAmount(props.item.currency, '0')
                        "
                      >
                        {{
                          props.item.price
                            | formatCurrencyAmount(props.item.currency, "0")
                        }}
                      </div>
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="price"
                      @click="props.onSort"
                    >
                      {{ $t("order.open_order.price")
                      }}<i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{
                        props.item.quantity
                          | toNumber
                          | formatCurrencyAmount("btc", "0")
                      }}
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="quantity"
                      @click="props.onSort"
                    >
                      {{ $t("order.open_order.amount")
                      }}<i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{
                        props.item.executed_quantity
                          | toNumber
                          | divBigNumber(props.item.quantity)
                          | floatToPercent
                      }}
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="executed_quantity"
                      @click="props.onSort"
                    >
                      {{ $t("order.open_order.filled") }}%<i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper">
                    <div class="item-desc__value">
                      {{
                        mulBigNumber(props.item.quantity, props.item.price)
                          | formatCurrencyAmount(props.item.currency, "0")
                      }}
                    </div>
                    <div
                      class="item-desc__title"
                      data-sort-field="total"
                      @click="props.onSort"
                    >
                      {{ $t("order.open_order.total")
                      }}<i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>
                  <div class="item-desc__wrapper single-page-2-col">
                    <div class="item-desc__value">
                      {{ props.item.stop_condition | convertConditionStatus }}
                      {{
                        props.item.base_price
                          | formatCurrencyAmount(props.item.currency, "")
                      }}
                    </div>
                    <div class="item-desc__title">
                      {{ $t("order.open_order.trigger_conditions")
                      }}<i
                        class="group-sort-tabl3"
                        v-html="props.innerHtmlSort"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </data-list-mobile>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="data-table">
        <data-table3
          ref="datatable"
          :get-data="getData"
          :msg-empty-data="$t('order.open_order.no_data')"
          id="tbl-open-orders"
          :limit="10"
          :height="30"
        >
          <template slot-scope="props">
            <th
              v-for="(item, idx) in headerTable"
              :key="item.id"
              :title="$t(item && item.name)"
              :data-sort-field="item && item.id ? item.id : null"
              @click="props.echoclick"
              :class="`cl-${idx + 1}`"
            >
              <span
                >{{ $t(item.name)
                }}{{
                  item.name === "order.open_order.filled" ? "%" : null
                }}</span
              >
              <i class="group-sort-tabl3" v-html="props.innerHtmlSort"></i>
              <span class="iconFixSorIE" v-if="props.resetIE === true"></span>
            </th>
            <th class="cancel-box text-right cl-10">
              <cancel-box @action-cancel-box="actionCancelOpenOrder" />
            </th>
          </template>
          <template slot="body" slot-scope="props">
            <tr>
              <td
                class="cl_date cl-1"
                :title="props.item.created_at | timestampToDate"
              >
                {{ props.item.created_at | timestampToDate }}
              </td>
              <td
                :title="
                  `${props.item.coin} / ${props.item.currency}` | uppercase
                "
                class="cl-2"
              >
                <span v-if="!hiddenLogoCrypto">
                  <img
                    class="coin"
                    width="20"
                    height="20"
                    :src="STORAGE_URL + '/icons/' + props.item.coin + '.png'"
                  />
                  <img
                    class="currency"
                    width="20"
                    height="20"
                    :src="
                      STORAGE_URL + '/icons/' + props.item.currency + '.png'
                    "
                  />
                </span>
                <span>
                  {{ props.item.coin | uppercase }}/{{
                    props.item.currency | uppercase
                  }}
                </span>
              </td>
              <td
                :title="localizeType(props.item.type) | uppercaseFirst"
                class="cl-3"
              >
                {{ localizeType(props.item.type) | uppercaseFirst }}
              </td>
              <td
                :class="{
                  blue: props.item.trade_type === 'buy',
                  red: props.item.trade_type === 'sell',
                }"
                class="cl-4"
              >
                {{
                  props.item.trade_type === "buy"
                    ? $t("common.trade_type.buy")
                    : $t("common.trade_type.sell")
                }}
              </td>
              <td class="cl-5">
                <span
                  v-if="
                    props.item.type == 'market' ||
                    props.item.type == 'stop_market'
                  "
                  :title="$t('order.open_order.market')"
                >
                  {{ $t("order.open_order.market") }}</span
                >
                <span
                  v-else
                  :title="
                    props.item.price
                      | formatCurrencyAmount(props.item.currency, '0')
                  "
                >
                  {{
                    props.item.price
                      | formatCurrencyAmount(props.item.currency, "0")
                  }}
                </span>
              </td>
              <td
                :title="
                  props.item.quantity
                    | toNumber
                    | formatCurrencyAmount('btc', '0')
                "
                class="cl-6"
              >
                {{
                  props.item.quantity
                    | toNumber
                    | formatCurrencyAmount("btc", "0")
                }}
              </td>
              <td
                :title="
                  props.item.executed_quantity
                    | toNumber
                    | divBigNumber(props.item.quantity)
                    | floatToPercent
                "
                class="cl-7"
              >
                {{
                  props.item.executed_quantity
                    | toNumber
                    | divBigNumber(props.item.quantity)
                    | floatToPercent
                }}
              </td>
              <td
                :title="
                  mulBigNumber(props.item.quantity, props.item.price)
                    | formatCurrencyAmount(props.item.currency, '0')
                "
                class="cl-8"
              >
                {{
                  mulBigNumber(props.item.quantity, props.item.price)
                    | formatCurrencyAmount(props.item.currency, "0")
                }}
              </td>
              <td class="cl-9">
                {{ props.item.stop_condition | convertConditionStatus }}
                {{
                  props.item.base_price
                    | formatCurrencyAmount(props.item.currency, "")
                }}
              </td>
              <td class="t-right cl-10">
                <span
                  @click="actionCancelOpenOrder('one', props.item.id)"
                  class="btn-close-order"
                  ><img src="/images/close-circle.svg"
                /></span>
              </td>
            </tr>
          </template>
        </data-table3>
      </div>
    </template>
  </div>
</template>

<script>
import OPEN_ORDER_HEADER_CONST from "./DataTableInit.js";
import COMMON_CONST from "@/common/Const";
import rf from "@/request/RequestFactory";
import CancelBox from "@/components/shared_components/common/CancelBox";
import BigNumber from "bignumber.js";
import { openOrders } from "./mockupData";
const ORDER_TYPE_LIMIT = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_LIMIT;
const ORDER_TYPE_MARKET = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_MARKET;
const ORDER_TYPE_STOP_LIMIT = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_STOP_LIMIT;
const ORDER_TYPE_STOP_MARKET =
  COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_STOP_MARKET;
const ORDER_TYPE_ALL = COMMON_CONST.SPOT_EXCHANGE.ORDER_TYPE_ALL;

export default {
  components: {
    CancelBox,
  },
  props: {
    options: Object,
    isSinglePage: Boolean,
    isSpotMobile: Boolean,
    coinCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_COIN,
    },
    currencyCode: {
      type: String,
      default: COMMON_CONST.DEFAULT_CURRENCY,
    },
    hiddenLogoCrypto: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHideOtherPair: false,
      headerTable: OPEN_ORDER_HEADER_CONST.OPEN_ORDER_HEADER,
      commonConst: COMMON_CONST,
      isHide: false,
      deleteType: COMMON_CONST.DEFAULT,
      coin: this.coinCode,
      currency: this.currencyCode,
      fetchingOpenOrder: false,
    };
  },
  computed: {
    STORAGE_URL() {
      const masterdata = this.$store.state.masterdata;
      return masterdata.settings.find((item) => item.key === "storage_url")
        .value;
    },
  },
  methods: {
    localizeType(type) {
      if (type == "limit") return this.$t("order.order_form.limit");
      if (type == "market") return this.$t("orders.open_order.market");
      if (type == "stop_market") return this.$t("order.order_form.stop_market");
      if (type == "stop_limit") return this.$t("order.order_form.stop_limit");
    },
    mulBigNumber(number1, number2) {
      if (!number1 || !number2) return "0";
      return new BigNumber(number1).mul(number2).toFixed(15).toString();
    },
    isIE() {
      let ua = navigator.userAgent;
      var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
      if (is_ie && $("table").length > 0) {
        $("#open-order table").addClass("fixieCancelbox-full");
      }
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
        SpotOrderListUpdated: this.onOrderListUpdated,
      };
    },
    onSpotSelectedPairMarket(pair) {
      this.isHideOtherPair = false;
      this.coin = pair.coin;
      this.currency = pair.currency;
    },
    onOrderListUpdated(data, isHideOtherPair) {
      if (!this.fetchingOpenOrder) {
        this.fetchingOpenOrder = true;
        if (isHideOtherPair) {
          this.$refs.datatable.filter({
            coin: this.coin,
            currency: this.currency,
          });
        } else {
          this.refreshTable();
        }
      }
    },
    refreshTable() {
      this.$refs && this.$refs.datatable && this.$refs.datatable.refresh();
    },
    async getData(params) {
      if (!window.isAuthenticated) {
        return await new Promise((resolve) => {
          return resolve({});
        });
      }
      return await rf
        .getRequest("OrderRequest")
        .getOrdersPending(params)
        .then((res) => {
          this.fetchingOpenOrder = false;
          // return openOrders;
          return res;
        });
    },
    actionCancelOpenOrder(deleteType = null, id = null) {
      const type = deleteType ? deleteType : this.deleteType;
      if (this.$refs.datatable.rows.length === 0) {
        this.showErrorToastMessageWithType(type);
        return;
      }

      if (
        (!deleteType && this.deleteType === this.commonConst.DEFAULT) ||
        this.$refs.datatable.rows.length === 0
      ) {
        return;
      }

      this.confirmCancelOrder(deleteType, id);
    },

    showErrorToastMessageWithType(type, msg) {
      let message = "";
      switch (type) {
        case ORDER_TYPE_LIMIT:
          message = this.$i18n.t("order.open_order.no_data.limit");
          break;
        case ORDER_TYPE_MARKET:
          message = this.$i18n.t("order.open_order.no_data.market");
          break;
        case ORDER_TYPE_STOP_LIMIT:
          message = this.$i18n.t("order.open_order.no_data.stop_limit");
          break;
        case ORDER_TYPE_STOP_MARKET:
          message = this.$i18n.t("order.open_order.no_data.stop_market");
          break;
        case ORDER_TYPE_ALL:
        default:
          message =
            this.$i18n.t(msg) || this.$i18n.t("order.open_order.no_data.all");
          break;
      }
      this.showErrorMessage(message);
    },

    confirmCancelOrder(type, id = "") {
      let confirmationMessage = "";
      switch (type) {
        case "one":
          confirmationMessage = this.$i18n.t(
            "order.open_order.cancel_one_message"
          );
          break;
        case ORDER_TYPE_ALL: {
          confirmationMessage = this.$i18n.t(
            "order.open_order.cancel_all_message"
          );
          break;
        }
        case ORDER_TYPE_LIMIT: {
          confirmationMessage = this.$i18n.t(
            "order.open_order.cancel_limit_price_message"
          );
          break;
        }
        case ORDER_TYPE_MARKET: {
          confirmationMessage = this.$i18n.t(
            "order.open_order.cancel_market_price_message"
          );
          break;
        }
        case ORDER_TYPE_STOP_LIMIT: {
          confirmationMessage = this.$i18n.t(
            "order.open_order.cancel_stop_limit_price_message"
          );
          break;
        }
        case ORDER_TYPE_STOP_MARKET: {
          confirmationMessage = this.$i18n.t(
            "order.open_order.cancel_stop_market_price_message"
          );
          break;
        }
        default:
          return;
      }
      ConfirmationModal.show({
        type: "warning-2",
        title: "",
        content: confirmationMessage,
        btnCancelLabel: this.$i18n.t("common.action.no"),
        btnConfirmLabel: this.$i18n.t("common.action.yes"),
        btnRow: true,
        onConfirm: () => {
          this.cancelOrder(type, id);
        },
        onCancel: function () {},
      });
    },
    cancelOrder(type, id = "") {
      this.isShowConfirmDialog = false;
      let message = this.$i18n.t("order.open_order.cancel_orders_success");
      switch (type) {
        case "one":
          message = this.$i18n.t("order.open_order.cancel_order_success");
          this.requestHandler(
            rf.getRequest("OrderRequest").cancel(id),
            message,
            type
          );
          break;
        case "all":
          this.requestHandler(
            rf.getRequest("OrderRequest").cancelAll(),
            message,
            type
          );
          break;
        default:
          this.requestHandler(
            rf.getRequest("OrderRequest").cancelByType({ type: type }),
            message,
            type
          );
          break;
      }
    },
    requestHandler(promise, message, type) {
      promise
        .then((res) => {
          this.showSuccessMessage(message);
        })
        .catch((error) => {
          if (!error.response) {
            this.showErrorMessage(this.$i18n.t("common.message.network_error"));
          } else {
            // this.showErrorMessage(error.response.data.message);
            this.showErrorToastMessageWithType(
              type,
              error.response.data.message
            );
          }
        });
    },
    showSuccessMessage(message) {
      this.$refs.datatable.refresh();
      Message.success(message, {}, { position: "top_right" });
    },
    showErrorMessage(message) {
      Message.error(message, {}, { position: "top_right" });
    },
  },
  mounted() {
    this.isIE();
  },
};
</script>

<style lang="scss" scoped>
@include tablet;
@include mobile {
  .open-order__mobile-wrapper {
    padding: 0 16px 24px;
    .open-order__mobile-header {
      display: flex;
      justify-content: space-between;
      .checkbox-function {
        display: flex;
        label {
          display: flex;
          position: relative;
          margin: unset;
          margin-top: auto;
          margin-bottom: auto;
          & input {
            margin: unset;
            width: 24px;
            height: 24px;
            border: 1px solid $bg-main2 !important;
            border-radius: 4px;
            &[type="checkbox"]:checked + div::before {
              content: "";
              display: block;
              position: absolute;
              text-align: center;
              height: 24px;
              width: 24px;
              left: 0;
              background-color: $bg-main1;
              border-radius: 4px;
            }
            &[type="checkbox"]:checked + div::after {
              content: url("@/assets/images/spot_exchange/checkbox-tick.svg");
              display: block;
              position: absolute;
              top: 2px;
              left: 2px;
            }
          }
          & div {
            color: $text-dark-1;
            margin-left: 16px;
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }
      :deep #cancel-box .btn_container {
        border: unset;
        padding: unset;
        .btn-cancel {
          padding: 6px 8px;
          border-radius: 5px;
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: $text-main;
          border: 1px solid $bg-main2;
          &:focus {
            outline: none;
          }
        }
        .dropdown {
          border: none;
          padding: 0;
          & .dropdown-toggle {
            line-height: normal;
            width: auto;
            height: auto;
            border: 1px solid $bg-main2;
            border-radius: 5px;
            padding: 6px 8px !important;
            margin-left: 6px;
            img {
              display: block;
            }
            span {
              display: none;
            }
          }
          & .dropdown-menu {
            position: absolute;
            z-index: 9999;
            padding: 10px 0;
            border: none;
            border-radius: 10px;
            top: 32px;
            li {
              a {
                padding: 6px 16px;
                color: $text-main;
                @include text-font($helvetica, $text-main, 12px, 300, 16px);
              }
              &:hover {
                background-color: $bg-main3;
              }
            }
          }
        }
        .divide {
          display: none;
        }
      }
    }
    .open-order__mobile-body {
      margin-top: 32px;
      & .data-list__wrapper {
        display: flex;
        overflow: auto;
        flex-direction: column;
        gap: 8px;
      }
      .open-order__list-item {
        padding: 16px;
        background-color: $bg-main4;
        border-radius: 10px;
        .open-oder__item-title {
          display: flex;
          justify-content: space-between;
          .item-title__wrapper {
            .item-title__logo-wrapper {
              img:nth-child(2) {
                margin-left: -8px;
              }
            }
            & > div {
              font-size: 14px;
              font-weight: 300;
              line-height: 24px;
            }
            display: flex;
            & > div:nth-child(2) {
              width: 70px;
              color: $text-main;
              margin-left: 6px;
            }
            & > div:nth-child(3) {
              margin-left: 4px;
            }
            & > div:nth-child(4) {
              margin-left: 11px;
            }
            & .item-title__date {
              margin-top: auto;
              margin-bottom: auto;
              font-size: 12px;
              font-style: normal;
              font-weight: 300;
              line-height: 16px;
              color: $text-main-blur;
            }
            & div.blue {
              color: $color-green;
              font-weight: 700;
            }
            & div.red {
              color: $color-red;
              font-weight: 700;
            }
          }
          .item-title__close {
            img {
              width: 18px;
              height: 18px;
            }
          }
        }
        .open-oder__item-desc {
          margin-top: 16px;
          display: grid;
          gap: 8px;
          row-gap: 16px;
          grid-template-columns: repeat(3, 1fr);
          .item-desc__wrapper {
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            &:nth-child(3n + 3) {
              text-align: end;
            }
            .item-desc__title {
              margin-top: 3px;
              font-size: 12px;
              font-style: normal;
              font-weight: 300;
              line-height: 16px;
              color: $text-main-blur;
            }
            .item-desc__value {
              font-size: 14px;
              min-height: 22px;
              font-style: normal;
              font-weight: 300;
              line-height: 22px;
              color: $text-main;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              & div {
                text-overflow: ellipsis;
                overflow: hidden;
              }
              & div.blue {
                color: $color-green;
                font-weight: 700;
              }
              & div.red {
                color: $color-red;
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }
}
#tbl-open-orders {
  margin: 0 auto;
  // padding: 10px 21px;
  min-width: fit-content !important;
  max-width: 100% !important;
  min-height: 270px;

  :deep() table {
    overflow-y: auto;

    thead {
      background-color: transparent;
    }
  }

  table {
    min-height: 175px;
    thead {
      background: $bg-main4;
      th {
        padding: 8px;
        @include text-font($helvetica, $text-secondary, 14px, 700, 24px);
        white-space: nowrap;
        border-bottom: none;
        background-color: $bg-main4;
        &:first-child {
          padding-left: 22px;
        }
        &:last-child {
          padding-right: 22px;
        }

        :deep() #cancel-box {
          .btn_container {
            align-items: flex-end;
            gap: 5px;
            padding: 0;
            border: none;
          }
          .btn-cancel {
            padding: 6px 8px;
            @include text-font($helvetica, $text-main, 12px, 300, 16px);
            border: 1px solid $bg-main2;
            border-radius: 5px;
            white-space: nowrap;
            background-color: white;
            &:focus {
              outline: none;
            }
          }
          .divide {
            display: none;
          }
          .dropdown {
            position: relative;

            button {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 6px 8px !important;
              height: 30px;
              width: 30px;
              border: 1px solid $bg-main2 !important;
              border-radius: 5px;
              background-color: white;
              span {
                width: 12px;
                font-size: 12px;
                color: $text-main5;
                &.icon-arrow1::before {
                  color: $text-main5;
                }
              }
            }
            .dropdown-menu {
              position: absolute;
              z-index: 9999;
              padding: 10px 0;
              border: none;
              border-radius: 10px;
              top: 32px;
              li {
                a {
                  padding: 6px 16px;
                  color: $text-main;
                  @include text-font($helvetica, $text-main, 14px, 300, 22px);
                }
                &:hover {
                  background-color: $bg-main3;
                }
              }
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 12px 8px;
          white-space: nowrap;
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          border-bottom: 1px solid $bg-main2;
          font-family: $helvetica;
          color: $text-main;
          &:first-child {
            padding-left: 22px;
          }
          &:last-child {
            padding-right: 22px;
          }
          &:nth-child(2) {
            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
              margin-top: -5px;
              &.currency {
                margin-left: -5px;
              }
            }
          }
          // &:first-child {
          //   padding-left: 0 !important;
          // }
          &:last-child {
            text-align: right;
          }
          &.blue,
          &.red,
          &.color-red {
            font-weight: 700;
          }
          &.blue {
            color: $color-green;
          }
          &.red {
            color: $color-red;
          }
          &.color-red {
            color: $color-red;
          }
          .btn-close-order {
            cursor: pointer;
          }
        }
        // &.empty-data {
        //   td {
        //     &:last-child {
        //       text-align: center !important;
        //     }
        //   }
        // }
      }
    }
  }

  @include tablet-d {
    & > :nth-child(2) {
      // position: sticky;
      // left: 20px;
      // width: fit-content;
    }
  }

  .cl-1 {
    min-width: 179px;
  }
  .cl-2 {
    min-width: 118px;
  }
  .cl-3 {
    // min-width: 69px;
    min-width: 130px;
  }
  .cl-4 {
    // min-width: 69px;
    min-width: 80px;
  }
  .cl-5 {
    min-width: 130px;
  }
  .cl-6 {
    // min-width: 99px;
    min-width: 130px;
  }
  .cl-7 {
    // min-width: 119px;
    min-width: 130px;
  }
  .cl-8 {
    // min-width: 97px;
    min-width: 130px;
  }
  .cl-9 {
    // min-width: 202px;
    min-width: 150px;
  }
  .cl-10 {
    min-width: 128px;
  }

  // .data-table {
  //   @include tablet-d {
  //     min-height: 50vh;
  //   }
  // }

  // & > :nth-child(2) {
  //   position: sticky;
  //   left: 20px;
  //   width: fit-content;
  // }
}
#open-order {
  .data-table {
    position: relative;
    min-height: 270px;
    overflow-x: auto;

    :deep() .empty-data {
      .empty-noti {
        min-height: auto !important;
      }
      .text-wrapper {
        gap: 0;
      }
      td {
        padding-top: 90px !important;
        padding-bottom: 113px !important;
        border-bottom: 0 !important;
      }
    }
  }
}
</style>
