<template>
  <div id="message" v-if="messages.length" :class="configs.position">
    <div
      id="message_content"
      v-for="message in messages"
      :key="message.index"
      :class="setClass(String(message.type))"
    >
      <div><img :src="setIcon(message.type)" alt="no image" /></div>
      <div class="message-text">
        <template v-if="typeof message.head !== 'undefined'">
          <div class="message-head">{{ message.head }}</div>
        </template>
        <template v-else>
          <div class="message-head">
            {{ message.type.charAt(0).toUpperCase() + message.type.slice(1) }}
          </div>
        </template>
        <div
          v-if="typeof message.content === 'string'"
          v-html="$options.filters.upperFirst(message.content)"
          class="message-content"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messages: [],
      showTime: 5000,
      configs: {},
      typeSuccess: this.$i18n.t("messages.success"),
      typeInfo: this.$i18n.t("messages.info"),
      typeError: this.$i18n.t("messages.error.error"),
      typeWarning: this.$i18n.t("messages.warning"),
      typePrimary: this.$i18n.t("messages.primary"),
      lastStatusIcon: "icon-close",
    };
  },
  methods: {
    setMessage() {
      this.typeSuccess = this.$i18n.t("messages.success");
      this.typeInfo = this.$i18n.t("messages.info");
      this.typeError = this.$i18n.t("messages.error.error");
      (this.typeWarning = this.$i18n.t("messages.warning")),
        (this.typePrimary = this.$i18n.t("messages.primary"));
    },
    setClass(classStyle) {
      let _self = this;
      switch (classStyle) {
        case _self.typeSuccess:
          return "success";
          break;
        case _self.typeInfo:
          return "info";
          break;
        case _self.typeError:
          return "error";
          break;
        case _self.typeWarning:
          return "warning";
          break;
        case _self.typeWarning:
          return "warning";
          break;
      }
    },
    setIcon(type) {
      let _self = this;
      switch (type) {
        case _self.typeSuccess:
        case _self.typeInfo:
          return "/images/icon/icon-check-circle.svg";
          break;
        case _self.typeError:
          return "/images/icon/icon-error-outline.svg";
          break;
        case _self.typePrimary:
          return "/images/icon/icon-check-circle.svg";
          break;
        case _self.typeWarning:
          return "/images/icon/icon-error-outline.svg";
          break;
      }
    },
  },
  created() {
    var self = this;

    window.Message = {
      primary: function (content, params, configs) {
        self.setMessage();
        window.app.$broadcast(
          "showMessage",
          "primary",
          content,
          params,
          configs
        );
      },
      warning: function (content, params, configs) {
        self.setMessage();
        window.app.$broadcast(
          "showMessage",
          self.$i18n.t("messages.warning"),
          content,
          params,
          configs
        );
      },
      success: function (content, params, configs) {
        self.setMessage();
        window.app.$broadcast(
          "showMessage",
          self.$i18n.t("messages.success"),
          content,
          params,
          configs
        );
      },
      error: function (content, params, configs) {
        self.setMessage();
        window.app.$broadcast(
          "showMessage",
          self.$i18n.t("messages.error.error"),
          content,
          params,
          configs
        );
      },
      info: function (content, params, configs) {
        window.app.$broadcast(
          "showMessage",
          self.$i18n.t("messages.info"),
          content,
          params,
          configs
        );
      },
    };

    this.$on("showMessage", (type, content, params, configs) => {
      var newMessage = {
        type: type,
        content: content,
        params: params,
        timer: null,
        head: configs?.head,
      };
      if (!configs) {
        configs = {
          position: "top_right",
        };
      }
      this.configs = Object.assign(this.configs, configs);

      newMessage.timer = setTimeout(function () {
        self.messages.splice(self.messages.indexOf(newMessage), 1);
      }, self.showTime);
      this.messages.push(newMessage);
    });
  },
};
</script>
<style lang="scss" scoped>
#message {
  position: fixed;
  min-height: 40px;
  min-width: 350px;
  line-height: 9px;
  z-index: 9999999;
  padding: 15px;

  &.center {
    margin-left: -143.5px;
    left: 50%;
    text-align: 50%;
    color: red;
    margin-top: -37.5px;
    top: 50%;
  }

  &.bottom_right {
    bottom: 10px;
    right: 20px;
  }

  &.bottom_left {
    bottom: 10px;
    right: 20px;
  }

  &.top_left {
    top: 65px;
    right: 0px;
  }

  &.top_right {
    top: 51px;
    right: 10px;
  }

  #message_content {
    padding: 16px;
    display: flex;
    max-width: 320px;
    gap: 8px;

    &.success {
      background-color: $color-green;
      border-radius: 8px;
      margin-bottom: 10px;

      .icon-save {
        padding-right: 8px;
        font-size: $font-big-20;
        font-weight: bold;
        line-height: 2;

        &:before {
          color: #ffffff;
        }
      }

      .message-text {
        display: flex;
        flex-direction: column;

        .message-head {
          color: $color-white;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
        }

        .message-content {
          color: $color-white;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
        }
      }
    }

    &.error {
      background-color: $color-denim;
      border: 1px solid $color-denim;
      border-radius: 8px;
      margin-top: 10px;

      .icon-close {
        padding-right: 13px;
        font-size: $font-big-20;
        font-weight: bold;
        line-height: 2;

        &:before {
          color: #ffffff;
        }
      }

      .message-head {
        color: $color-white;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }

      .message-content {
        color: $color-white;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
      }
    }

    &.info {
      background-color: $color_green_text;
      border: 1px solid $color_green_text;
      border-radius: 8px;
      margin-top: 10px;

      .icon-save {
        padding-right: 13px;
        font-size: $font-big-20;
        font-weight: bold;
        line-height: 2;

        &:before {
          color: #ffffff;
        }
      }

      .message-text {
        align-self: center;

        // text-align: justify;
        strong,
        span {
          color: $color-white;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
