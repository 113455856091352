<template>
  <div>
    <modal
      width="404"
      ref="modalPassword"
      :name="modalName"
      :hasModalFooter="false"
      :enableClose="false"
    >
      <template slot="body">
        <div slot="body" class="change-password-modal">
          <div class="icon">
            <img src="/images/lock.svg" alt="lock" />
            <div class="modal-title">
              <h3>{{ $t("change_password_form.title") }}</h3>
            </div>
          </div>

          <div>
            <div class="input-group">
              <div class="custom-input">
                <div
                  class="form-input"
                  :class="{ error: errors.has('password') }"
                >
                  <img
                    class="icon-first"
                    src="/images/grey-lock.svg"
                    alt="grey-lock"
                  />
                  <input
                    :maxlength="maxLengthInput"
                    :type="isEyeOpenOld ? 'string' : 'password'"
                    name="password"
                    :data-vv-as="$t('change_password_form.old_password_change')"
                    :placeholder="$t('change_password_form.old_password')"
                    v-model="params.password"
                    data-vv-validate-on="none"
                    @focus="resetErrors"
                    @keydown="blockSpace"
                  />
                  <img
                    @click="isEyeOpenOld = !isEyeOpenOld"
                    class="icon-last"
                    :src="`/images/eye-${isEyeOpenOld ? 'open' : 'close'}.svg`"
                    alt="eye"
                  />
                </div>
              </div>
              <span class="error-form">
                <span v-show="errors.has('password')">
                  {{ errors.first("password") }}
                </span>
              </span>
            </div>
            <div class="input-group">
              <div class="custom-input">
                <div
                  class="form-input"
                  :class="{ error: errors.has('new_password') }"
                >
                  <img
                    class="icon-first"
                    src="/images/grey-lock.svg"
                    alt="grey-lock"
                  />
                  <input
                    :maxlength="maxLengthInput"
                    :type="isEyeOpenNew ? 'string' : 'password'"
                    name="new_password"
                    :data-vv-as="$t('change_password_form.new_password_change')"
                    :placeholder="$t('change_password_form.new_password')"
                    v-model="params.new_password"
                    data-vv-validate-on="none"
                    @focus="resetErrors"
                    @keydown="blockSpace"
                  />
                  <img
                    @click="isEyeOpenNew = !isEyeOpenNew"
                    class="icon-last"
                    :src="`/images/eye-${isEyeOpenNew ? 'open' : 'close'}.svg`"
                    alt="eye"
                  />
                </div>
              </div>
              <span class="error-form">
                <span v-show="errors.has('new_password')">
                  {{ errors.first("new_password") }}
                </span>
              </span>
            </div>
            <div class="input-group">
              <div class="custom-input">
                <div
                  class="form-input"
                  :class="{ error: errors.has('new_password_confirm') }"
                >
                  <img
                    class="icon-first"
                    src="/images/grey-lock.svg"
                    alt="grey-lock"
                  />
                  <input
                    :maxlength="maxLengthInput"
                    :type="isEyeOpenConfirm ? 'string' : 'password'"
                    name="new_password_confirm"
                    :data-vv-as="
                      $t('change_password_form.confirm_new_password_change')
                    "
                    :placeholder="
                      $t('change_password_form.confirm_new_password')
                    "
                    v-model="params.new_password_confirm"
                    data-vv-validate-on="none"
                    @focus="resetErrors"
                    @keydown="blockSpace"
                  />
                  <img
                    @click="isEyeOpenConfirm = !isEyeOpenConfirm"
                    class="icon-last"
                    :src="`/images/eye-${
                      isEyeOpenConfirm ? 'open' : 'close'
                    }.svg`"
                    alt="eye"
                  />
                </div>
              </div>
              <span class="error-form">
                <span v-show="errors.has('new_password_confirm')">
                  {{ errors.first("new_password_confirm") }}
                </span>
              </span>
            </div>

            <div
              class="input-group"
              style="margin-bottom: 0"
              v-if="userData.security_setting.otp_verified"
            >
              <!-- <CurrencyInputOtp
                id="change-password-otp-field"
                autocomplete="off"
                :placeholder="$t('common.placeholders.otp')"
                v-validate="'required|length:6'"
                :data-vv-as="$t('validation.attributes.authentication_code')"
                :class="{ 'input-error': errors.has('otp') }"
                class="form-input"
                type="text"
                maxlength="6"
                name="otp"
                v-model="params.otp"
              /> -->
              <span class="error-form">
                <span v-show="errors.has('otp')">
                  {{ errors.first("otp") }}
                </span>
              </span>
            </div>
          </div>

          <div class="form-group btn-group">
            <button
              name="btnCancel"
              :disabled="isSubmitting"
              class="btn-cancel"
              @click="cancelModal"
            >
              {{ $t("change_password_cancel") }}
            </button>
            <button
              name="btnSubmit"
              :disabled="isSubmitting"
              class="btn-confirm"
              @click="submit"
            >
              {{ $t("change_password_form.save") }}
            </button>
          </div>
        </div>
      </template>
    </modal>

    <otp-modal
      name="OtpModalChangePassword"
      :submit="callbackOtp"
      :params="dataOtp"
      :is-disable-button="isDisableButtonOTP"
      :contact_email="userData.email"
      @onShowOTP="(val) => (isShowOPT = val)"
    />
    <lost-otp-modal
      name="OtpModalChangePassword_lostOtpForm"
      @DISABLE_OTP_SUCCESS="$emit('disableOtpSuccess')"
    />
  </div>
</template>

<script>
import OtpModal from "@/components/shared_components/funds/common/OtpModal";
import Modal from "@/components/shared_components/common/Modal";
import LostOtpModal from "@/components/shared_components/otp/LostOtpModal";
import rf from "@/request/RequestFactory";
import AuthenticationUtils from "@/common/AuthenticationUtils";
import COMMON_CONST from "@/common/Const";
import CurrencyInputOtp from "@/components/shared_components/common/CurrencyInputOtp.vue";
import Utils from "@/common/Utils";

export default {
  name: "ChangePassword",
  components: {
    Modal,
    CurrencyInputOtp,
    OtpModal,
    LostOtpModal,
  },
  props: {
    userData: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      isShowing: false,
      isSubmitting: false,
      params: {
        otp: "",
      },
      modalName: "ChangePassword",
      maxLengthInput: COMMON_CONST.MAX_LENGTH_INPUT,
      otp_request: "",
      password_request: "",
      new_password_request: "",
      new_password_confirm_request: "",
      isChangedPassword: false,
      isEyeOpenOld: false,
      isEyeOpenNew: false,
      isEyeOpenConfirm: false,

      dataOtp: {},
      isShowOPT: false,
      isDisableButtonOTP: false,
    };
  },
  watch: {
    // 'params.otp'(newVal) {
    //   if (window._.size(newVal) === 6) {
    //     this.submit()
    //   }
    // },
    isShowOPT(val) {
      const isOpenModalPassword = this.$refs.modalPassword.show;
      if (isOpenModalPassword && val) {
        this.$refs.modalPassword.show = false;
      } else if (!isOpenModalPassword && !val) {
        this.$refs.modalPassword.show = true;
      }
    },
  },
  methods: {
    resetErrors() {
      if (this.errors.any()) {
        this.errors.clear();
      }
    },

    getParams() {
      return {
        password: this.password_request,
        new_password: this.new_password_request,
        new_password_confirm: this.new_password_confirm_request,
        otp: this.otp_request,
      };
    },

    blockSpace(eve) {
      // if (eve.which === 32) {
      //   eve.preventDefault()
      //   eve.stopPropagation()
      //   return
      // }
    },

    updatePassword() {
      this.password_request = this.params.password;
      this.new_password_request = this.params.new_password;
      this.new_password_confirm_request = this.params.new_password_confirm;
      this.otp_request = this.params.otp;

      this.changePassword(this.getParams());
    },

    callbackOtp(data) {
      this.isDisableButtonOTP = true;
      this.params.otp = data.otp;
      this.updatePassword();
    },

    async submit() {
      this.resetErrors();

      if (_.isEmpty(this.params.password)) {
        this.errors.add(
          "password",
          this.$t("change_password_form.require_old")
        );
      }
      if (_.isEmpty(this.params.new_password)) {
        this.errors.add(
          "new_password",
          this.$t("change_password_form.require_new")
        );
      }
      if (_.isEmpty(this.params.new_password_confirm)) {
        this.errors.add(
          "new_password_confirm",
          this.$t("change_password_form.require_confirm")
        );
      }

      if (Utils.checkHasWhiteSpace(this.params.new_password)) {
        this.errors.add(
          "new_password",
          this.$t("validation.attribute_white_space", {
            attribute: this.$t("change_password_form.new_password"),
          })
        );
      }

      if (this.params.password.length > 72) {
        this.errors.add("password", this.$t("validation.auth.password_max"));
      }

      if (this.params.new_password.length > 72) {
        this.errors.add(
          "new_password",
          this.$t("validation.auth.password_new_max")
        );
      }

      if (this.params.password === this.params.new_password) {
        this.errors.add(
          "new_password",
          this.$t("change_password_form.error_duplicated_password")
        );
      }

      if (
        !this.errors.has("new_password") &&
        this.params.new_password !== this.params.new_password_confirm
      ) {
        this.errors.add(
          "new_password_confirm",
          this.$t("change_password_form.error_confirm")
        );
      }

      if (this.errors.any()) {
        $('button[name="btnSubmit"]').blur();

        if (this.userData.security_setting.otp_verified) {
          this.password_request = "";
          this.new_password_request = "";
          this.new_password_confirm_request = "";
          this.otp_request = "";
        }
        return;
      }

      if (this.userData.security_setting.otp_verified) {
        CommonModal.show("OtpModalChangePassword");
        return;
      }

      this.updatePassword();
    },

    changePassword(params) {
      this.isSubmitting = true;
      rf.getRequest("UserRequest")
        .changePassword(params)
        .then((res) => {
          this.onChangePassword();
          this.isDisableButtonOTP = false;
        })
        .catch((error) => {
          this.isSubmitting = false;
          this.isDisableButtonOTP = false;
          if (error.response.status === 422) {
            if (error.response && error.response.data.errors) {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.errors.add({
                  field: key,
                  msg: this.$t(error.response.data.errors[key][0]),
                });
              });
              if (!this.errors.has("otp"))
                CommonModal.hide("OtpModalChangePassword");
            } else {
              this.errors.add("error", this.$t("server.logs"));
            }
          } else {
            Message.error(this.$i18n.t("common.message.network_error"));
          }
        })
        .finnaly(() => {
          this.removePageOverflow();
        });
    },
    showModalLostOtp() {
      CommonModal.hide("OtpModalChangePassword");
      CommonModal.show("OtpModalChangePassword_lostOtpForm");
    },

    onChangePassword() {
      this.params = {};
      this.isSubmitting = false;
      window.CommonModal.hide(this.modalName);
      Message.success(
        this.$i18n.t("change_password_form.successful_message"),
        {},
        { position: "top_right" }
      );
      setTimeout(() => {
        this.logout();
      }, 2000);
    },
    cancelModal() {
      this.params.password = "";
      this.params.new_password = "";
      this.params.new_password_confirm = "";
      this.params.otp = "";
      this.isEyeOpenOld = false;
      this.isEyeOpenNew = false;
      this.isEyeOpenConfirm = false;
      window.CommonModal.hide(this.modalName);
      this.resetErrors();
      this.removePageOverflow();
    },

    logout() {
      this.isChangedPassword = true;
      AuthenticationUtils.removeAuthenticationData();
      window.location.href = "/login";
    },

    clearInput() {
      Object.assign(this.params, {
        otp: "",
        password: "",
        new_password: "",
        new_password_confirm: "",
      });
    },

    resetData() {
      this.isSubmitting = false;
      this.isShowOtpField = false;
      this.params = {
        otp: "",
      };
      this.isChangedPassword = false;
    },
    getEventHandlers() {
      return {
        showCommonModal: this.onModalShowing,
      };
    },
    onModalShowing(modalName) {
      if (modalName == "ChangePassword") {
        this.isShowing = true;
      }
    },
  },
  created() {
    this.$on("hideCommonModal", (modalName) => {
      if (modalName !== this.modalName) {
        return;
      }
      this.isShowing = false;
      this.clearInput();
    });
  },
  mounted() {
    window.addEventListener("keyup", (event) => {
      if (
        event.key === "Enter" &&
        this.modalName == "ChangePassword" &&
        this.isShowing
      ) {
        this.submit();
      }
    });
  },
};
</script>

<style lang="scss">
#ChangePassword {
  .input-group {
    #change-password-otp-field {
      &:focus,
      &:hover {
        border-color: $primary-2;
      }
    }
  }

  .modal-header {
    position: absolute;
    right: 0;
  }

  .modal-body {
    padding: 30px;
    box-shadow: 0px 4px 15px rgba(0, 19, 54, 0.15);
    border-radius: 10px;
  }

  .modal-footer {
    display: none;
  }

  .modal-dialog {
    @include mobile {
      width: 100% !important;
      max-width: 500px;
      padding: 0 5%;
    }
  }

  .input-group .form-input.error {
    border: 1px solid #ff5b5b;
    background: rgba(255, 91, 91, 0.1);
  }

  .input-group .form-input input {
    background: transparent;
  }

  .icon-close {
    &:hover {
      background-color: $color-light-grey;
      color: $color-grey;
    }
  }
}
</style>

<style lang="scss" scoped>
.group_center_option {
  padding-top: 5px;
  text-align: right;

  a {
    font-size: $font-small;
    color: $color-blue-custom;
    font-family: $font-family-roboto;
  }
}

.modal-title {
  h3 {
    color: rgba($text-main2, 0.87);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-top: 14px;
    margin-bottom: 24px;
  }

  .title-l {
    margin: 10px 0px 10px 0px;
    height: 1px;
    width: 20%;
    border-radius: 5px;
    background-color: $color-light-grey;
  }

  .title-l2 {
    width: 23%;
    margin: 10px 0px 10px 0px;
    height: 1px;
    border-radius: 5px;
    background-color: $color-light-grey;
  }
}

.guest-page-form-header-opt {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  .title-otp {
    margin: 0;
    margin-bottom: 28px;
    padding-bottom: 0px;
    font-family: $helvetica;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    text-align: center;
    color: $dark-1;

    @include mobile {
      font-size: 20px;
      line-height: 25px;
    }
  }
}

.change-password-modal {
  .icon {
    text-align: center;

    span {
      font-size: 50px;
      color: $color-eden;
    }
  }

  label {
    font-weight: normal;
    color: $color-black;
    margin-bottom: 0px;
  }

  .note_header {
    color: $color-black;
    line-height: 24px;
    margin-bottom: 16px;
  }

  .input-group {
    margin-bottom: 16px;

    input {
      font-weight: 300;

      @include mobile {
        font-size: 14px;
        font-style: normal;
        line-height: 22px;
      }

      &::placeholder {
        font-family: $helvetica;
        color: $text-main-blur;
        // font-size: 18px;
        // font-weight: 300;
        // line-height: 32px;
      }
    }
  }

  .btn-group {
    text-align: center;
    margin-bottom: 0px;
    width: 100%;
    display: flex;
    gap: 20px;
    margin-top: 24px;

    button {
      &.btn-confirm {
        @include btn-common(contained, md);
        font-size: 18px;
        line-height: 32px;
      }

      &.btn-cancel {
        @include btn-common(contained, md);
        background: $bg-main3;
        color: $text-main2;
        font-size: 18px;
        line-height: 32px;
      }
    }
  }

  .invalid-feedback {
    padding-top: 2px;
    font-size: $font-small;
  }
}

.input-group-prepend {
  height: 100%;

  span {
    line-height: 44px;
  }
}

.error-form {
  color: $color-red;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
</style>
