const getters = {
  user: (state) => {
    return state.user;
  },
  instrument: (state) => {
    return state.instrument;
  },
  settlement: (state) => {
    return state.settlement;
  },
  funding: (state) => {
    return state.funding;
  },
  insurance: (state) => {
    return state.insurance;
  },
  compositeIndex: (state) => {
    return state.compositeIndex;
  },
  localLang: (state) => {
    return state.localLang;
  },
};

export default getters;
