import BaseRequest from "./base/BaseRequest";

export default class HistoryRequest extends BaseRequest {
  getOrderTransactionHistory(params) {
    let url = "/orders/transactions";
    return this.get(url, params);
  }

  getOrderBuyHistory(params) {
    let url = "/aml-transactions";
    return this.get(url, params);
  }

  getCashBackHistory(params) {
    let url = "/aml-cash-back";
    return this.get(url, params);
  }

  downloadBuyHistory(params, fileName) {
    let url = "/aml-export";
    return this.get(url, params, fileName);
  }
}
