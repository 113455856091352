export const PriceGroupsStatis = [
  {
    id: 2001,
    currency: 'btc',
    coin: 'eth',
    group: 0,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2002,
    currency: 'btc',
    coin: 'eth',
    group: 1,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2003,
    currency: 'btc',
    coin: 'eth',
    group: 2,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2004,
    currency: 'btc',
    coin: 'eth',
    group: 3,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2009,
    currency: 'btc',
    coin: 'bch',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2010,
    currency: 'btc',
    coin: 'bch',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2011,
    currency: 'btc',
    coin: 'bch',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2012,
    currency: 'btc',
    coin: 'bch',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2013,
    currency: 'btc',
    coin: 'ltc',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2014,
    currency: 'btc',
    coin: 'ltc',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2015,
    currency: 'btc',
    coin: 'ltc',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2016,
    currency: 'btc',
    coin: 'ltc',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2017,
    currency: 'btc',
    coin: 'xrp',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2018,
    currency: 'btc',
    coin: 'xrp',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2019,
    currency: 'btc',
    coin: 'xrp',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2020,
    currency: 'btc',
    coin: 'xrp',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2025,
    currency: 'btc',
    coin: 'ada',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2026,
    currency: 'btc',
    coin: 'ada',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2027,
    currency: 'btc',
    coin: 'ada',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 2028,
    currency: 'btc',
    coin: 'ada',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3005,
    currency: 'eth',
    coin: 'bch',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3006,
    currency: 'eth',
    coin: 'bch',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3007,
    currency: 'eth',
    coin: 'bch',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3008,
    currency: 'eth',
    coin: 'bch',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3009,
    currency: 'eth',
    coin: 'ltc',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3010,
    currency: 'eth',
    coin: 'ltc',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3011,
    currency: 'eth',
    coin: 'ltc',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3012,
    currency: 'eth',
    coin: 'ltc',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3013,
    currency: 'eth',
    coin: 'xrp',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3014,
    currency: 'eth',
    coin: 'xrp',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3015,
    currency: 'eth',
    coin: 'xrp',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3016,
    currency: 'eth',
    coin: 'xrp',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3021,
    currency: 'eth',
    coin: 'ada',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3022,
    currency: 'eth',
    coin: 'ada',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3023,
    currency: 'eth',
    coin: 'ada',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 3024,
    currency: 'eth',
    coin: 'ada',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4001,
    currency: 'usdt',
    coin: 'btc',
    group: 0,
    value: '1.0000000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4002,
    currency: 'usdt',
    coin: 'btc',
    group: 1,
    value: '0.1000000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4003,
    currency: 'usdt',
    coin: 'btc',
    group: 2,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4004,
    currency: 'usdt',
    coin: 'btc',
    group: 3,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4005,
    currency: 'usdt',
    coin: 'eth',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4006,
    currency: 'usdt',
    coin: 'eth',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4007,
    currency: 'usdt',
    coin: 'eth',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4008,
    currency: 'usdt',
    coin: 'eth',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4013,
    currency: 'usdt',
    coin: 'bch',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4014,
    currency: 'usdt',
    coin: 'bch',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4015,
    currency: 'usdt',
    coin: 'bch',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4016,
    currency: 'usdt',
    coin: 'bch',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4017,
    currency: 'usdt',
    coin: 'ltc',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4018,
    currency: 'usdt',
    coin: 'ltc',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4019,
    currency: 'usdt',
    coin: 'ltc',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4020,
    currency: 'usdt',
    coin: 'ltc',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4021,
    currency: 'usdt',
    coin: 'xrp',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4022,
    currency: 'usdt',
    coin: 'xrp',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4023,
    currency: 'usdt',
    coin: 'xrp',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4024,
    currency: 'usdt',
    coin: 'xrp',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4029,
    currency: 'usdt',
    coin: 'ada',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4030,
    currency: 'usdt',
    coin: 'ada',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4031,
    currency: 'usdt',
    coin: 'ada',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4032,
    currency: 'usdt',
    coin: 'ada',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4086,
    currency: 'bnb',
    coin: 'ada',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4087,
    currency: 'bnb',
    coin: 'ada',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4088,
    currency: 'bnb',
    coin: 'ada',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4089,
    currency: 'bnb',
    coin: 'ada',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4090,
    currency: 'bnb',
    coin: 'btc',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4091,
    currency: 'bnb',
    coin: 'btc',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4092,
    currency: 'bnb',
    coin: 'btc',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4093,
    currency: 'bnb',
    coin: 'btc',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4098,
    currency: 'bnb',
    coin: 'eth',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4099,
    currency: 'bnb',
    coin: 'eth',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4100,
    currency: 'bnb',
    coin: 'eth',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4101,
    currency: 'bnb',
    coin: 'eth',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4102,
    currency: 'bnb',
    coin: 'bch',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4103,
    currency: 'bnb',
    coin: 'bch',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4104,
    currency: 'bnb',
    coin: 'bch',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4105,
    currency: 'bnb',
    coin: 'bch',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4106,
    currency: 'bnb',
    coin: 'xrp',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4107,
    currency: 'bnb',
    coin: 'xrp',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4108,
    currency: 'bnb',
    coin: 'xrp',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4109,
    currency: 'bnb',
    coin: 'xrp',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4110,
    currency: 'bnb',
    coin: 'ltc',
    group: 0,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4111,
    currency: 'bnb',
    coin: 'ltc',
    group: 1,
    value: '0.0000010000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4112,
    currency: 'bnb',
    coin: 'ltc',
    group: 2,
    value: '0.0000001000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4113,
    currency: 'bnb',
    coin: 'ltc',
    group: 3,
    value: '0.0000000100',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4114,
    currency: 'busd',
    coin: 'btc',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4115,
    currency: 'busd',
    coin: 'btc',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4116,
    currency: 'busd',
    coin: 'btc',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4117,
    currency: 'busd',
    coin: 'btc',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4122,
    currency: 'busd',
    coin: 'eth',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4123,
    currency: 'busd',
    coin: 'eth',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4124,
    currency: 'busd',
    coin: 'eth',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4125,
    currency: 'busd',
    coin: 'eth',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4126,
    currency: 'busd',
    coin: 'xrp',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4127,
    currency: 'busd',
    coin: 'xrp',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4128,
    currency: 'busd',
    coin: 'xrp',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4129,
    currency: 'busd',
    coin: 'xrp',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4130,
    currency: 'busd',
    coin: 'xrp',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4131,
    currency: 'busd',
    coin: 'bch',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4132,
    currency: 'busd',
    coin: 'bch',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4133,
    currency: 'busd',
    coin: 'bch',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4134,
    currency: 'busd',
    coin: 'ada',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4135,
    currency: 'busd',
    coin: 'ada',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4136,
    currency: 'busd',
    coin: 'ada',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4137,
    currency: 'busd',
    coin: 'ada',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4138,
    currency: 'busd',
    coin: 'ltc',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4139,
    currency: 'busd',
    coin: 'ltc',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4140,
    currency: 'busd',
    coin: 'ltc',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4141,
    currency: 'busd',
    coin: 'ltc',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4142,
    currency: 'busd',
    coin: 'bnb',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4143,
    currency: 'busd',
    coin: 'bnb',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4144,
    currency: 'busd',
    coin: 'bnb',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4145,
    currency: 'busd',
    coin: 'bnb',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4146,
    currency: 'usdt',
    coin: 'bnb',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4147,
    currency: 'usdt',
    coin: 'bnb',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4148,
    currency: 'usdt',
    coin: 'bnb',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4149,
    currency: 'usdt',
    coin: 'bnb',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4150,
    currency: 'usdt',
    coin: 'busd',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4151,
    currency: 'usdt',
    coin: 'busd',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4152,
    currency: 'usdt',
    coin: 'busd',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4153,
    currency: 'usdt',
    coin: 'busd',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4162,
    currency: 'idr',
    coin: 'btc',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4163,
    currency: 'idr',
    coin: 'btc',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4164,
    currency: 'idr',
    coin: 'btc',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4165,
    currency: 'idr',
    coin: 'btc',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4166,
    currency: 'idr',
    coin: 'eth',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4167,
    currency: 'idr',
    coin: 'eth',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4168,
    currency: 'idr',
    coin: 'eth',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4169,
    currency: 'idr',
    coin: 'eth',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4170,
    currency: 'idr',
    coin: 'bch',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4171,
    currency: 'idr',
    coin: 'bch',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4172,
    currency: 'idr',
    coin: 'bch',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4173,
    currency: 'idr',
    coin: 'bch',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4174,
    currency: 'idr',
    coin: 'ltc',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4175,
    currency: 'idr',
    coin: 'ltc',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4176,
    currency: 'idr',
    coin: 'ltc',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4177,
    currency: 'idr',
    coin: 'ltc',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4178,
    currency: 'idr',
    coin: 'xrp',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4179,
    currency: 'idr',
    coin: 'xrp',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4180,
    currency: 'idr',
    coin: 'xrp',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4181,
    currency: 'idr',
    coin: 'xrp',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4182,
    currency: 'idr',
    coin: 'ada',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4183,
    currency: 'idr',
    coin: 'ada',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4184,
    currency: 'idr',
    coin: 'ada',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4185,
    currency: 'idr',
    coin: 'ada',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4186,
    currency: 'idr',
    coin: 'usdt',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4187,
    currency: 'idr',
    coin: 'usdt',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4188,
    currency: 'idr',
    coin: 'usdt',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4189,
    currency: 'idr',
    coin: 'usdt',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4190,
    currency: 'idr',
    coin: 'bnb',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4191,
    currency: 'idr',
    coin: 'bnb',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4192,
    currency: 'idr',
    coin: 'bnb',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4193,
    currency: 'idr',
    coin: 'bnb',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4194,
    currency: 'idr',
    coin: 'busd',
    group: 0,
    value: '0.0100000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4195,
    currency: 'idr',
    coin: 'busd',
    group: 1,
    value: '0.0010000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4196,
    currency: 'idr',
    coin: 'busd',
    group: 2,
    value: '0.0001000000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
  {
    id: 4197,
    currency: 'idr',
    coin: 'busd',
    group: 3,
    value: '0.0000100000',
    unit: 'N/A',
    created_at: null,
    updated_at: null,
  },
]
