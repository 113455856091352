<template>
  <div id="top-info">
    <table class="detail-top">
      <tbody>
        <tr :class="{ idr__grid: currency === 'idr' }">
          <td>
            <span class="title">{{ $t('exchange.basic.trading_page.current_price') }}</span>
            <br class="visible_rp" />
            <span class="nb_basic_tn">
              <!-- {{ currentPrice | formatCurrencyAmount(currency, '0') }} -->
              {{
                currentPrice
                  ? Number(
                      parseFloat(currentPrice || 0).toFixed(isPairPrecisionIsFour ? 4 : 2)
                    ).toLocaleString('en', {
                      minimumFractionDigits: isPairPrecisionIsFour ? 4 : 2,
                    })
                  : '--'
              }}
            </span>
            <!-- <fiat-currency
              class="nb_basic_10"
              :value="currentPriceFiat"
              v-if="!isUsdCurrency"
            ></fiat-currency> -->
          </td>
          <td>
            <span class="title">{{ $t('exchange.basic.trading_page.last_price') }}</span>
            <br class="visible_rp" />
            <span
              class="nb_basic_tn"
              :class="{
                grown: lastChange > 0,
                down: lastChange < 0,
              }"
            >
              <!-- {{ lastPrice | formatCurrencyAmount(currency, '0') }} -->
              {{
                lastPrice
                  ? Number(
                      parseFloat(lastPrice || 0).toFixed(isPairPrecisionIsFour ? 4 : 2)
                    ).toLocaleString('en', {
                      minimumFractionDigits: isPairPrecisionIsFour ? 4 : 2,
                    })
                  : '--'
              }}
            </span>
            <!-- <fiat-currency
              class="nb_basic_10"
              :value="fiatPrice"
              v-if="!isUsdCurrency"
            ></fiat-currency> -->
          </td>
          <td>
            <span class="title">{{ $t('exchange.basic.trading_page.change_24h') }}</span>
            <br class="visible_rp" />
            <span
              class="nb_basic_tn"
              :class="{ grown: percentChange > 0, down: percentChange < 0 }"
            >
              <!-- {{ get24hPriceChanged() | formatCurrencyAmount(currency, '0') }} -->
              {{
                get24hPriceChanged()
                  ? Number(
                      parseFloat(get24hPriceChanged() || 0).toFixed(isPairPrecisionIsFour ? 4 : 2)
                    ).toLocaleString('en', {
                      minimumFractionDigits: isPairPrecisionIsFour ? 4 : 2,
                    })
                  : '--'
              }}
            </span>
            <span class="nb_basic_tn" :class="{ grown: percentChange > 0, down: percentChange < 0 }"
              >{{ characterIncrease }}{{ percentChange | to2Precision }}%</span
            >
          </td>
          <td>
            <span class="title">{{ $t('exchange.basic.trading_page.high_24h') }}</span>
            <br class="visible_rp" />
            <span class="nb_basic_tn">
              <!-- {{ maxPrice | formatCurrencyAmount(currency, '0') }} -->
              {{
                maxPrice
                  ? Number(
                      parseFloat(maxPrice || 0).toFixed(isPairPrecisionIsFour ? 4 : 2)
                    ).toLocaleString('en', {
                      minimumFractionDigits: isPairPrecisionIsFour ? 4 : 2,
                    })
                  : '--'
              }}
            </span>
          </td>
          <td>
            <span class="title">{{ $t('exchange.basic.trading_page.low_24h') }}</span>
            <br class="visible_rp" />
            <span class="nb_basic_tn">
              <!-- {{ minPrice | formatCurrencyAmount(currency, '0') }} -->
              {{
                minPrice
                  ? Number(
                      parseFloat(minPrice || 0).toFixed(isPairPrecisionIsFour ? 4 : 2)
                    ).toLocaleString('en', {
                      minimumFractionDigits: isPairPrecisionIsFour ? 4 : 2,
                    })
                  : '--'
              }}
            </span>
          </td>
          <td>
            <span class="title">{{ $t('exchange.basic.trading_page.volume_24h') }}</span>
            <br class="visible_rp" />
            <span class="nb_basic_tn">
              {{ volume | formatCurrencyAmount(coin, '0') }}
              {{ coin | uppercase }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import rf from '@/request/RequestFactory';
import Utils from '@/common/Utils';
import Const from '@/common/Const';
import BigNumber from 'bignumber.js';

export default {
  props: ['latestPrices'],
  data() {
    return {
      currency: this.$route.query.currency || Const.DEFAULT_CURRENCY,
      coin: this.$route.query.coin || Const.DEFAULT_COIN,
      prices: {},
      lastPriceGrownStatus: 0,
      previousPrice: 0,
      currentPrice: 0,
      currentPriceFiat: 0,
      lastPrice: 0,
      changePrice: 0, // change price with latest price
      fiatPrice: 0,
      minPrice: 0,
      maxPrice: 0,
      price24h: 0,
      volume: 0,
      percentChange: 0,
    };
  },

  computed: {
    tradingPair: function () {
      return this.currency + '_' + this.coin;
    },

    tradingPairPrice: function () {
      return this.prices[this.tradingPair] || {};
    },

    isUsdCurrency() {
      return this.currency === 'idr';
    },

    characterIncrease(percent) {
      if (this.percentChange <= 0) {
        return '';
      }
      return '+';
    },
    lastChange() {
      return this.prices[this.tradingPair]?.price > this.prices[this.tradingPair]?.previous_price
        ? 1
        : this.prices[this.tradingPair]?.price < this.prices[this.tradingPair]?.previous_price
        ? -1
        : 0;
    },
    isPairPrecisionIsFour() {
      return (
        (this.coin === 'ada' && this.currency === 'usdt') ||
        (this.coin === 'xrp' && this.currency === 'usdt')
      );
    },
  },

  watch: {
    $route(to, from) {
      this.currency = this.$route.query.currency || Const.DEFAULT_CURRENCY;
      this.coin = this.$route.query.coin || Const.DEFAULT_COIN;

      this.initData();
    },
  },

  methods: {
    getSocketEventHandlers() {
      return {
        PricesUpdated: this.onPricesUpdated,
        SendPrice24h: this.onPrices24hUpdated,
        OrderTransactionCreated: this.onOrderTransactionCreated,
      };
    },
    getEventHandlers() {
      return {
        SpotSelectedPairMarket: this.onSpotSelectedPairMarket,
      };
    },

    onPricesUpdated(newPrices) {
      this.prices = Object.assign({}, this.prices, newPrices);
      this.currentPrice = this.prices[this.currency + '_' + this.coin].current_price;

      if (window._.isEmpty(this.prices)) {
        return;
      }
      let coinPrice = newPrices[this.tradingPair];
      if (!coinPrice) {
        return;
      }
      if (this.lastPrice) {
        this.changePrice = new BigNumber(coinPrice.price).sub(this.lastPrice).toString();
      }

      this.price24h = coinPrice.last_24h_price;
      this.lastPrice = coinPrice.price;
      this.previousPrice = coinPrice.previous_price;
      this.lastPriceGrownStatus = new BigNumber(this.lastPrice).comparedTo(this.previousPrice);

      if (new BigNumber(this.lastPrice).gt(this.maxPrice)) {
        this.maxPrice = this.lastPrice;
      }
      if (new BigNumber(this.lastPrice).lt(this.minPrice)) {
        this.minPrice = this.lastPrice;
      }

      this.percentChange = this.getPercentChanged();
      this.fiatPrice = this.getFiatPrice();

      let titlePrice = this.tradingPairPrice.price;
      titlePrice = Utils.formatCurrencyAmount(titlePrice, this.currency);
      titlePrice = titlePrice + ' ' + this.coin + '/' + this.currency;
      document.title = titlePrice.toUpperCase() + ` – ${process.env.VUE_APP_SHORT_NAME || 'NVX'}`;

      // if (!this.isUsdCurrency) {
      //   this.currentPriceFiat = new BigNumber(this.currentPrice)
      //     .mul(this.prices[`idr_${this.currency}`].price)
      //     .toString()
      // }
    },
    onPrices24hUpdated(newPrices) {
      let coinPrice = newPrices[this.tradingPair];
      if (!coinPrice) {
        return;
      }
      this.lastPrice = coinPrice.last_price;
      this.previousPrice = coinPrice.previous_price;
      this.lastPriceGrownStatus = new BigNumber(this.lastPrice).comparedTo(this.previousPrice);

      this.maxPrice = coinPrice.max_price;
      this.minPrice = coinPrice.min_price;
    },

    onOrderTransactionCreated(data) {
      if (
        data.orderTransaction.coin === this.coin &&
        data.orderTransaction.currency === this.currency &&
        data.orderTransaction.is_update_volume === 1
      ) {
        this.volume = new BigNumber(this.volume).add(data.orderTransaction.quantity);
      }
    },

    onSpotSelectedPairMarket(pair) {
      this.currency = pair.currency;
      this.coin = pair.coin;

      this.getPriceScope().then(() => {
        this.onPricesUpdated(this.prices);
      });
    },

    getFiatPrice() {
      if (this.currency === 'idr') {
        return this.lastPrice;
      }
      let fiatPrice = this.prices['idr_' + this.currency];
      if (!fiatPrice) {
        return 0;
      }
      const price = fiatPrice.price;
      return new BigNumber(this.lastPrice).mul(price).toString();
    },

    get24hPriceChanged() {
      return new BigNumber(this.lastPrice).sub(this.price24h).toString();
    },

    getPercentChanged() {
      var change = this.get24hPriceChanged();
      if (change === '0') return '0';

      if (this.price24h == 0) return '100'; //division to zero

      return new BigNumber(change).div(this.price24h).mul(100);
    },

    getPriceScope() {
      let params = {
        currency: this.currency,
        coin: this.coin,
      };
      return rf
        .getRequest('PriceRequest')
        .getPriceScope(params)
        .then((res) => {
          this.maxPrice = res.data.max_price;
          this.minPrice = res.data.min_price;
          this.volume = res.data.volume;
          this.$broadcast('SpotUpdateVolumeMarket', res.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    initData() {
      this.changePrice = 0;
      this.lastPrice = 0;

      rf.getRequest('PriceRequest')
        .getPrices()
        .then((res) => {
          this.onPricesUpdated(res.data);
        });
      this.getPriceScope();
    },
  },

  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
#top-info {
  margin-left: 24px;
  overflow: auto;
  .detail-top {
    font-family: $helvetica;
    font-size: 12px;
    line-height: 15px;
    color: $dark-3;
    tbody {
      tr {
        display: flex;
        gap: 16px;
        td {
          white-space: nowrap;
          .title {
            white-space: nowrap;
            @include text-font($helvetica, $text-secondary, 12px, 700, 16px);
          }
        }
      }
    }

    @include mobile {
      tr {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        td {
          .title {
            display: inline-block;
            margin-bottom: 2px;
            @include text-font($helvetica, $text-secondary, 12px, 700, 16px);
          }
        }
      }
    }
    @include tablet {
      tr {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        td {
          .title {
            display: inline-block;
            margin-bottom: 2px;
            @include text-font($helvetica, $text-secondary, 12px, 700, 16px);
          }
        }
      }
    }
  }
  .nb_basic_tn {
    font-family: $helvetica;
    color: $text-main;
    margin-right: 3px;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    &.grown {
      color: $color-green;
    }
    &.down {
      color: $color-red;
    }
  }
  .nb_basic_10 {
    color: $text-main;
    font-size: 12px;
    font-family: $helvetica;
    line-height: 16px;
    font-weight: 300;
  }
}

@media only screen and (max-width: 1400px) {
  #top-info {
    .detail-top {
      tbody {
        tr {
          td {
            padding-right: 2px;
          }
        }
      }
    }
  }
}

@include mobile {
  #top-info table.detail-top {
    width: 100%;
    tbody tr {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      row-gap: 12px;
      & td {
        &:nth-child(1) {
          grid-column: span 2;
        }
        &:nth-child(2) {
          grid-column: span 2;
        }
      }
      &.idr__grid {
        grid-template-columns: repeat(3, 1fr);
        & td {
          grid-column: span 1;
        }
      }
    }
  }
}
</style>
