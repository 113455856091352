<template>
  <div id="help-center">
    <div class="content">
      <div>
        <h2 class="head">Download</h2>
        <a
          href="https://nvx.co.id/docs/NVX_User_Manual_Book.pdf"
          download="NVX User Manual Book.pdf"
          target="_blank"
        >
          <button class="btn-download download-manual" type="button">User Manual</button>
        </a>
        <a
          href="https://nvx.co.id/docs/NVX_KYC_User_Manual_Book.pdf"
          download="KYC User Manual Book"
          target="_blank"
        >
          <button class="btn-download download-kyc-doc" type="button">KYC Manual</button>
        </a>
        <a
          href="https://nvx.co.id/docs/NVX_KYC_Tutorial.mp4"
          download="KYC User Manual Video"
          target="_blank"
        >
          <button class="btn-download download-kyc-vid" type="button">KYC Video Guide</button>
        </a>
      </div>

      <h2 class="head">{{ $t('menu_bottom.faqs') }}</h2>
      <ul>
        <li v-for="n of 39" :key="n">
          <p v-if="n !== 29 || n !== 39" class="question">
            {{ $t(`help_center_question_${n}`) }}
          </p>
          <p v-if="n !== 29 || n !== 39" class="answer">
            {{ $t(`help_center_answer_${n}`) }}
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpCenter',
  data() {
    return {}
  },
  mounted() {
    document.title = 'FAQs' + ` – ${this.tabTitle}`
  },
}
</script>

<style lang="scss" scoped>
#help-center {
  max-width: 1370px;
  margin: 0 auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $text-main;

  .content {
    padding: 24px 16px;
    background-color: #f2f2f2;

    .head {
      font-size: 24px;
      line-height: 40px;
      margin-bottom: 16px;
      font-weight: 700;
      color: $text-main;
      margin-top: 0;
    }

    .answer {
      margin-bottom: 16px;
      color: $text-secondary;

      @include tablet-d {
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
      }
    }

    .question {
      margin-bottom: 12px;
      color: $text-main;
      font-weight: 700;
    }
  }
  .btn-download {
    background: $bg-main6;
    color: white;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    border-radius: 60px;
    display: inline-block;
    width: max-content;
    padding: 9px 18px;
    margin-bottom: 24px;

    @include tablet-d {
      font-size: 14px;
      padding: 9px 12px;
    }

    &.download-kyc-doc {
      border-radius: 0 !important;
      border-right: 1px solid;
      border-left: 1px solid;
    }
    &.download-kyc-vid {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
    &.download-manual {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    &:hover,
    &:active,
    &:focus {
      background: #ddd;
      color: $text-main;
    }
  }
}
</style>
