<template>
  <div id="order-history-screen" class="page_container container_history">
    <div class="box-title title-medium-small">
      {{ $t("menu.order_history") }}
    </div>
    <div class="order-history-content">
      <order-history
        class="order-group-screen"
        :options="{ isNoPadding: true }"
      />
    </div>
  </div>
</template>

<script>
import COMMON_CONST from "@/common/Const";
import OrderHistory from "./OrderHistory.vue";

export default {
  components: {
    OrderHistory,
  },
  data() {
    return {
      dateList: COMMON_CONST.OPEN_ORDER_DATE_TAB,
    };
  },
  methods: {
    selectDate(item) {},
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.container_history {
  width: 100%;
  margin: 0px auto;

  &after {
    display: block;
    clear: both;
    content: "";
  }
}

#order-history-screen {
  margin-bottom: 50px;

  .box-title {
    margin-bottom: $title-bottom-margin;
  }

  .order-history-content {
    // background-color: $color_white;
  }
}
</style>
